import { request } from '@libs';

const getData = async (urls, params) => {
  try {
    const response = await request.call_auth('post', urls, params);
    const { data } = response;

    if (!Object.values(data).length) {
      throw new Error('server');
    }

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    if (!data.success && data.errorText) {
      throw new Error(data.errorText);
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',
  team_id: '',

  setSettings({ locale, team_id }) {
    this.locale = locale;
    this.team_id = team_id;
  },

  getGallery({ params }) {
    const urls = { local: '/json/gallery/gallery.json', prod: `/${this.locale}/projects/getScreenshots/` };

    return getData(urls, params);
  },

  getProfile() {
    const urls = { local: '/json/gallery/profile.json', prod: `/${this.locale}/users/profile/` };

    return request.call_auth('get', urls);
  },

  getPdfData({ params }) {
    const urls = { local: '/json/gallery/pdf_data.json', prod: `/${this.locale}/projects/getPdfData/` };

    return request.call_auth('get', urls, params);
  },

  setPdfData({ projectId, folderId, params }) {
    const getParam = folderId ? `folder_id=${folderId}` : `id=${projectId}`;
    const urls = { local: '/json/gallery/pdf_data.json', prod: `/${this.locale}/projects/setPdfData/?${getParam}` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },
  savePdf(params) {
    const urls = { local: '/json/gallery/savePdf.json', prod: `/${this.locale}/projects/designprojectfilesave/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },
  uploadImages(formData) {
    const urls = { local: '/json/gallery/upload-images.json', prod: `/${this.locale}/projects/uploadscreenshot/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, formData, settings);
  },
  removeImage({ params }) {
    const urls = { local: '/json/gallery/remove.json', prod: `/${this.locale}/projects/deleteScreenshots/` };

    return request.call_auth('post', urls, params);
  },
};
