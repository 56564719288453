import { translations } from '@libs';

export const extraFields = {
  qiwi: {
    type: 'number',
    title: 'Телефон пользователя в qiwi',
    placeholder: '79210000000',
    regexp: RegExp(/^[7](\d{10})$/),
    errorValid: 'В формате 79210000000',
    errorEmpty: translations.t('store.cart.form.not_empty'),
  },
  alfabank: {
    type: 'text',
    title: 'Логин пользователя в Альфа-Клике',
    placeholder: '',
    regexp: '',
    errorEmpty: translations.t('store.cart.form.not_empty'),
  },
};
