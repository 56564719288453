import React from 'react';
import { tablet_landscape } from '../../modules/breakpoints';
import MediaQuery from 'react-responsive';
import { Input, InputView as InputFieldView } from 'planoplan-ui-kit/core/input';

export const InputField = ({ children, ...restProps }) => (
  <MediaQuery query={tablet_landscape}>
    {(matches) => (
      <Input theme="primary" size={matches ? 'M' : 'S'} {...restProps}>
        {children}
      </Input>
    )}
  </MediaQuery>
);

export { InputFieldView };
