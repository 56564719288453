import React from 'react';

export const ICardSber = ({ width = 74, height = 20, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 74 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.1609 5.99064L60.8499 3.98096H51.8447V16.0207H60.8499V14.0093H54.4169V10.9364H59.9152V8.92671H54.4169V5.99064H58.1609Z" fill="#9E9E9E"/>
      <path d="M45.041 8.66966H41.9213V5.99064H46.9121L49.6028 3.98096H39.3491V16.0207H44.7088C47.7117 16.0207 49.4459 14.6403 49.4459 12.2367C49.4459 9.93155 47.8869 8.66966 45.041 8.66966ZM44.612 14.0093H41.9213V10.6793H44.612C46.2495 10.6793 47.0089 11.2318 47.0089 12.3552C47.0089 13.4785 46.1911 14.0093 44.612 14.0093Z" fill="#9E9E9E"/>
      <path d="M68.1409 3.98096H63.2485V16.0207H65.8207V12.6106H68.1609C71.2789 12.6106 73.2285 10.9564 73.2285 8.29576C73.2285 5.63511 71.2789 3.98096 68.1409 3.98096ZM68.0825 10.6009H65.8024V5.99064H68.0825C69.7399 5.99064 70.6363 6.81855 70.6363 8.29576C70.6363 9.77298 69.7399 10.6009 68.0825 10.6009Z" fill="#9E9E9E"/>
      <path d="M34.9429 13.4183C34.2618 13.7939 33.5007 13.9909 32.7212 13.9909C30.401 13.9909 28.7252 12.315 28.7252 10.0099C28.7252 7.70476 30.401 6.02891 32.7212 6.02891C33.5591 6.01055 34.3787 6.28596 35.0597 6.77837L36.9125 5.39963L36.7956 5.28112C35.724 4.31467 34.2802 3.82227 32.6427 3.82227C30.8701 3.82227 29.251 4.43318 28.0826 5.5365C26.9125 6.67989 26.2498 8.27561 26.2882 9.93143C26.2682 11.6056 26.9125 13.2214 28.0826 14.4031C29.3094 15.5666 30.9469 16.2175 32.6244 16.1775C34.4755 16.1775 36.0929 15.5265 37.1846 14.3447L35.5287 13.1029L34.9429 13.4183Z" fill="#9E9E9E"/>
      <path d="M18.2377 3.90234C18.7051 4.51326 19.0957 5.1826 19.4262 5.892L10.421 12.6104L6.63867 10.2068V7.33082L10.401 9.7144L18.2377 3.90234Z" fill="#9E9E9E"/>
      <path d="M2.79767 10.01C2.79767 9.87145 2.79767 9.75461 2.8177 9.61607L0.537608 9.49756C0.537608 9.65613 0.517578 9.83306 0.517578 9.98996C0.517578 12.7491 1.62925 15.2512 3.42194 17.0639L5.03936 15.4281C3.65562 14.0494 2.79767 12.1382 2.79767 10.01Z" fill="#9E9E9E"/>
      <path d="M10.4007 2.32515C10.5376 2.32515 10.6544 2.32515 10.7913 2.34518L10.9081 0.0400492C10.7512 0.0400492 10.5759 0.0200195 10.4207 0.0200195C7.69162 0.0200195 5.21623 1.14337 3.42188 2.95609L5.0393 4.59188C6.40468 3.19311 8.31422 2.32515 10.4007 2.32515Z" fill="#9E9E9E"/>
      <path d="M10.4005 17.695C10.2636 17.695 10.1468 17.695 10.0099 17.6749L9.89307 19.9801C10.05 19.9801 10.2252 20.0001 10.3805 20.0001C13.1096 20.0001 15.5866 18.8767 17.3793 17.064L15.7619 15.4282C14.3965 16.847 12.5053 17.695 10.4005 17.695Z" fill="#9E9E9E"/>
      <path d="M14.689 3.6655L16.6185 2.22667C14.9227 0.847939 12.7594 0 10.4009 0V2.30513C11.9999 2.32516 13.4805 2.81756 14.689 3.6655Z" fill="#9E9E9E"/>
      <path d="M20.3038 10.01C20.3038 9.39906 20.2454 8.80817 20.1469 8.21729L18.0221 9.81301C18.0221 9.87143 18.0221 9.95155 18.0221 10.01C18.0221 12.2767 17.0473 14.3064 15.5083 15.7052L17.0473 17.4194C19.0369 15.6067 20.3038 12.946 20.3038 10.01Z" fill="#9E9E9E"/>
      <path d="M10.4006 17.695C8.15892 17.695 6.15091 16.7102 4.76717 15.1528L3.07129 16.7102C4.88401 18.7399 7.49626 20.0001 10.4006 20.0001V17.695Z" fill="#9E9E9E"/>
      <path d="M5.31311 4.31482L3.77246 2.60059C1.76445 4.43334 0.517578 7.07396 0.517578 10.01H2.79767C2.79767 7.76333 3.77246 5.71526 5.31311 4.31482Z" fill="#9E9E9E"/>
    </svg>
  );
};
