import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { TooltipEditor } from 'planoplan-ui-kit/core/tooltipEditor';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import * as effects from '../../modules/effects';
import { NumberList } from '../NumberList';
import { IconTrash, IconPdfFooterOff, IconPdfFooterOn } from '../../icons';

export const TableItem = ({ width, margin, item, offsetCountPage = 0 }) => {
  const dispatch = useDispatch();

  const onClickDeleteItem = () => {
    dispatch(effects.deleteDesignProjectImage({ id: item.id }));
  };
  const onClickSetPdfFooter = () => {
    dispatch(effects.setPdfFooterOnImage({ id: item.id }));
  };

  return (
    <View width={width} margin={margin}>
      <Render width={width}>
        <Image src={item.preview} alt=""/>

        <Actions>
          <Remove onClick={onClickDeleteItem}><IconTrash /></Remove>

          <TooltipEditor render={translations.t('gallery.project.frame.tooltip')}>
            <Footer hideFrame={item.hideFrame} onClick={onClickSetPdfFooter}>{item.hideFrame ? <IconPdfFooterOff /> : <IconPdfFooterOn />}</Footer>
          </TooltipEditor>
        </Actions>
      </Render>

      <Bottom>
        <NumberList number={item.index + offsetCountPage} name={item.name} id={item.id} input={true} />
      </Bottom>
    </View>
  );
};
const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${Colors.white};
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s;
`;
const Remove = styled(Icon)``;
const Footer = styled(Icon)`
  opacity: ${({ hideFrame }) => hideFrame ? 1 : 0};
`;
const Actions = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: background-color 0.1s;
`;
const View = styled.div`
  width: ${({ width }) => `${width}px`};
  margin-bottom: 20px;
  margin-right: ${({ margin }) => `${margin}px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  transition: border 0.1s;
  position: relative;
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid ${Colors.dark_cream};
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
  }
  
  &:hover {
    &:after{
      opacity: 1;    
    }
    ${Actions} {
      background-color: ${rgba(Colors.coal, 0.5)};
    }
    ${Remove} {
      opacity: 1;
    }
    ${Footer} {
      opacity: 1;
    }
  }
`;
const Render = styled.div`
  height: ${({ width }) => `${width / 1.777}px`};
  background-color: ${Colors.nobel};
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Bottom = styled.div`
  padding: 5px;
  background-color: ${Colors.coal};
`;
