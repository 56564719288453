// Screens
import { Colors } from 'planoplan-ui-kit/core/colors';

// Events
export const QUICK_STORE_OPEN = 'QUICK_STORE_OPEN';

// Actions
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_SCREEN = 'SET_SCREEN';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PRICING = 'SET_PRICING';
export const SET_GOODS = 'SET_GOODS';
export const SET_BINDING_CARD = 'SET_BINDING_CARD';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_BANNER = 'SET_BANNER';
export const SET_SELECTED = 'SET_SELECTED';
export const SET_APPLIED_PROMO = 'SET_APPLIED_PROMO';
export const SET_PAY_DATA = 'SET_PAY_DATA';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PRICING = 'GET_PRICING';
export const GET_GOODS = 'GET_GOODS';
export const GET_BINDING_CARD = 'GET_BINDING_CARD';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_BANNERS = 'GET_BANNERS';
export const GET_BINDING_DATA = 'GET_BINDING_DATA';

// Screens
export const SCREENS = {
  main: 'main',
  stripe: 'stripe',
  stripeRequire: 'stripeRequire',
  yookassa: 'yookassa',
  paypal: 'paypal',
  success: 'success',
  pending: 'pending',
  error: 'error',
};

// Pay
export const PAY_STATUSES = {
  succeeded: 'succeeded', // зачислен на баланс (/store/pay)
  pending: 'pending', // ожидает действия от стороннего сервиса (/store/pay)
  processing: 'processing', // еще не завершился (/store/pay)
  unpaid: 'unpaid', // создан, но не оплачен (/store/checkStatus)
  paid: 'paid', // оплачен (/store/checkStatus)
  wrong: 'wrong', // не вверно (/store/checkStatus)
  error: 'error', // ошибка (/store/checkStatus)
  requires_payment_method: 'requires_payment_method', // запрос платежного метода (для 3D-secure)
};


// Errors categories
export const ERROR_MESSAGE_CATEGORIES = {
    YOOKASSA: 'yookassa',
    DEFAULT: 'default',
};

// Errors
export const ERROR_MESSAGES = {
    [ERROR_MESSAGE_CATEGORIES.YOOKASSA]: {
        'default': 'store.cart.wrong',
        '3d_secure_failed': 'store.cart.wrong.3d_secure_failed',
        'call_issuer': 'store.cart.wrong.call_issuer',
        'canceled_by_merchant': 'store.cart.wrong.canceled_by_merchant',
        'card_expired': 'store.cart.wrong.card_expired',
        'country_forbidden': 'store.cart.wrong.country_forbidden',
        'expired_on_capture': 'store.cart.wrong.expired_on_capture',
        'expired_on_confirmation': 'store.cart.wrong.expired_on_confirmation',
        'fraud_suspected': 'store.cart.wrong.fraud_suspected',
        'general_decline': 'store.cart.wrong.general_decline',
        'identification_required': 'store.cart.wrong.identification_required',
        'insufficient_funds': 'store.cart.wrong.insufficient_funds',
        'internal_timeout': 'store.cart.wrong.internal_timeout',
        'invalid_card_number': 'store.cart.wrong.invalid_card_number',
        'invalid_csc': 'store.cart.wrong.invalid_csc',
        'issuer_unavailable': 'store.cart.wrong.issuer_unavailable',
        'payment_method_limit_exceeded': 'store.cart.wrong.payment_method_limit_exceeded',
        'payment_method_restricted': 'store.cart.wrong.payment_method_restricted',
        'permission_revoked': 'store.cart.wrong.permission_revoked',
        'unsupported_mobile_operator': 'store.cart.wrong.unsupported_mobile_operator',
    },
    [ERROR_MESSAGE_CATEGORIES.DEFAULT]: {
        default: 'site.notification.tryLater',
    },
};

// PayMethods
export const PAY_METHODS = {
  CARD: 'card',
  SEPA_DEBIT: 'sepa_debit',
  ALIPAY: 'alipay',
  BANCONTACT: 'bancontact',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  P24: 'p24',
  SOFORT: 'sofort',
};

// Currency
export const CURRENCY = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
};


export const ALIAS_RESOLUTIONS = {
  PREVIEW: 'PREVIEW',
  HD: 'HD',
  FULL_HD: 'FULL_HD',
  QHD: 'QHD',
  '4K': '4K',
};

export const RENDER_RESOLUTIONS = {
  [ALIAS_RESOLUTIONS.PREVIEW]: '0',
  [ALIAS_RESOLUTIONS.HD]: '1280x720',
  [ALIAS_RESOLUTIONS.FULL_HD]: '1920x1080',
  [ALIAS_RESOLUTIONS.QHD]: '2560x1440',
  [ALIAS_RESOLUTIONS['4K']]: '3840x2160',
};

export const VIEW_ALIAS_RESOLUTIONS = {
  PREVIEW: 'Preview',
  HD: 'HD',
  FULL_HD: 'Full HD',
  QHD: 'QHD',
  '4K': '4K',
};

export const RESOLUTION_PANORAMA_ALIAS = {
  EIGHT_K: '8k',
  FOUR_K: '4k',
};
export const RESOLUTION_PANORAMA = {
  "1800": RESOLUTION_PANORAMA_ALIAS.EIGHT_K,
  "900": RESOLUTION_PANORAMA_ALIAS.FOUR_K,
};

// Tariff
export const GOODS_TARIFF_ALIAS = ['tariff', 'businessvr'];
export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  TRIAL_PROPLUS: 'trialproplus',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
};
export const TARIFF_NAME_ALIAS = {
  [TARIFF_ALIAS.START] : 'start',
  [TARIFF_ALIAS.PRO] : 'basic',
  [TARIFF_ALIAS.PROPLUS] : 'pro+',
  [TARIFF_ALIAS.BUSINESS] : 'team',
  [TARIFF_ALIAS.EDUCATION] : 'education', // тут не точно
  [TARIFF_ALIAS.PROFESSIONAL] : 'professional', // тут не точно
};
export const TARIFF_PERIODS = {
  year: 'year',
  month: 'month'
};
export const PANORAMA_ALIAS = 'panorama';
export const URLS = {
  tariffComparison: (locale) => `https://planoplan.com/${locale}/pricing`,
  renderComparison: 'https://files.planoplan.com/public/blog/20230627/planoplan-render.jpg',
};
export const GOODS_CATEGORY_ALIAS = {
  panorama : 'render',
  render : 'render',
  businessvr : 'tariff',
  tariff : 'tariff',
};

export const ID_PAY_METHOD_PAYPAL = 34000000;
