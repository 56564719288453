import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IErrorCircle } from 'planoplan-ui-kit/core/icons/i-error-circle';
import { translations } from '@libs';

export const Error = ({ error }) => {
  useEffect(() => {
    console.error(error);
  });

  return (
    <View>
      <IErrorCircle width={146} height={146} stroke={Colors.colar}/>
      <Title dangerouslySetInnerHTML={{ __html: translations.t('tour_editor.error') }} />
    </View>
  );
};

const View = styled.div`
  ${Mixins.transition('background-color')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  pointer-events: none;
`;

const Title = styled.div`
  font-size: 32px;
  line-height: 36px;
  margin-top: 24px;
  color: ${Colors.colar};
  text-align: center;
`;
