import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { ON_GALLERY_SHOW } from '@observer/constants';
import { App } from './app';
import { createAppStore, initialState } from './modules/Store';
import { ApiCall } from './modules/api-call';
import ErrorBoundary from './ErrorBoundary';

const observer = new window.POPObserver();

export default class UIDesignProject {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения галереи не указан html элемент');
    this.locale = this.settings.locale || 'ru';
  }

  open(params = {}) {
    this.root = params.root || console.error('Для подключения галереи не указан html элемент');
    this.locale = params.locale || 'ru';
    this.projectId = params.projectId || ''; // id проекта, который будет открыт
    this.folderId = params.folderId || ''; // id папки, которая будет открыта
    this.zoom = params.zoom || 1;

    window.designProjectIsOpen = true;
    ApiCall.setSettings({ locale: this.locale });

    this._mount();
    this._editorInvoke();
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    this.store = store;

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      settings: {
        root: this.root,
        locale: this.locale,
        projectId: `${this.projectId}`,
        folderId: `${this.folderId}`,
        close: this.close.bind(this),
        zoom: this.zoom,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  /**
   * Удаление модуля из дом-дерева, и триггер событий для юнити
   * @param mode {string} - режимы закрытия
   */
  close(mode = '') {
    if (!window.cabinetIsOpen && !window.galleryIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }

    observer.postMessage(ON_GALLERY_SHOW);

    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
    window.designProjectIsOpen = false;
  }
}

if (!window.UIDesignProject) {
  window.UIDesignProject = UIDesignProject;
}
