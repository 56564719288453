import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ButtonEditor } from 'planoplan-ui-kit/core/buttonEditor';
import { IDownload } from 'planoplan-ui-kit/core/icons/i-download';
import { ITrash2 } from 'planoplan-ui-kit/core/icons/i-trash-2';
import { ICameraSmall } from 'planoplan-ui-kit/core/icons/i-camera-small';
import { IMinusRound } from 'planoplan-ui-kit/core/icons/i-minus-round';
import { IPlusRound } from 'planoplan-ui-kit/core/icons/i-plus-round';
import { IFit } from 'planoplan-ui-kit/core/icons/i-fit';
import { IFill } from 'planoplan-ui-kit/core/icons/i-fill';
import { IShare } from 'planoplan-ui-kit/core/icons/i-share';
import { IGlobe } from 'planoplan-ui-kit/core/icons/i-globe';
import { getImage } from 'planoplan-ui-kit/core/get_image';
import { translations } from '@libs';
import { MODE_ALIAS, UNITY } from '@globalConstants';
import { ZOOM_PARAMS, TARIFF_ALIAS, TARIFF_PASS_FEATURES } from '../../constants';
import * as effects from '../../modules/effects';
import { tablet_landscape, laptop, laptop_wide } from '../../modules/breakpoints';
import { IImprove, IEdit, IMagic, ISetting, ICode, IProPlus } from '../../icons';

export const Bar = ({
  current_image,
  mode,
  sharedUser,
  isPreviewMode,
  isFullScreen,
  onDownload,
  onRemove,
  onCreateRender,
  onImproveRender,
  onShare,
  onCopyTourCode,
  onEdit,
  onHomePage,
  onOpenTourEditor,
  onEditTourEditor,
  zoomIn,
  zoomOut,
  scale,
  resetTransform,
  setTransform,
  transformWrapperEl,
  positionX,
  positionY
}) => {
  const dispatch = useDispatch();
  const {
    id,
    krpano_url,
    tour,
    publishId,
    roomCaption,
    roomArea,
    captionSize,
    editor,
    isAvailableCheck,
    isProhibitedDelete,
    original,
    preview,
    isPreview,
    isComplete,
  } = current_image;

  const checkIsMakeUpdateRender = (image) => {
    try {
      return image.editor.task_data.search(/camPos=/) !== -1;
    } catch (e) {
      return false;
    }
  };

  const tooltipDirection = isFullScreen || isPreviewMode ? 'top' : 'bottom';
  const state = useSelector((state) => state);
  const { profile, profileGoods, settings: { team_id }} = state;
  const virtualTourCreator = JSON.parse(profileGoods?.tariff?.options?.['editor.virtualTourCreator'] || false);
  const tariff = profile.tariff ? profile.tariff.alias : null;
  const isPassFeatures = TARIFF_PASS_FEATURES.indexOf(tariff) !== -1;
  const krpano = krpano_url || tour;
  const disabledEdit = Boolean(krpano || !id || (!isPassFeatures && !team_id) || !isComplete);
  const disabledTour = !isPassFeatures;
  const isMakeUpdateRender = checkIsMakeUpdateRender(current_image);
  const is4k = current_image.size && current_image.size.height >= 2160;
  const { goods4K = {} } = dispatch(effects.getFullHDAnd4K());
  const availableImproveTeam = isComplete && !is4k && tariff === TARIFF_ALIAS.TEAM && Boolean(goods4K.count);
  const availableImproveUser = isComplete && !is4k && tariff !== TARIFF_ALIAS.START;
  const availableImprove = team_id ? availableImproveTeam : availableImproveUser;
  const percentage = useMemo(() => {
    if (!transformWrapperEl.current) {
      return (scale * 100).toFixed(0);
    }

    try {
      const containerRect = transformWrapperEl.current.getBoundingClientRect();
      const imageSize = current_image.size;
      let innerScale = 1; // в сколько раз изображение больше контейнера

      if (imageSize.width > containerRect.width) {
        innerScale = imageSize.width / containerRect.width;
      }

      if (imageSize.height > containerRect.height) {
        if (innerScale < imageSize.height / containerRect.height) {
          innerScale = imageSize.height / containerRect.height;
        }
      }

      return ((scale * 100) / innerScale).toFixed(0);
    } catch (error) {
      return (scale * 100).toFixed(0);
    }
  }, [scale, current_image]);

  const setOriginalSize = () => {
    if (!transformWrapperEl.current) {
      console.error('Ref container not found!');

      return;
    }

    try {
      const containerRect = transformWrapperEl.current.getBoundingClientRect();
      const imageSize = current_image.size;
      let innerScale = 1;

      if (imageSize.width > containerRect.width) {
        innerScale = imageSize.width / containerRect.width;
      }

      if (imageSize.height > containerRect.height) {
        if (innerScale < imageSize.height / containerRect.height) {
          innerScale = imageSize.height / containerRect.height;
        }
      }

      /* Координаты центра экрана даже при зуме */
      const center = {
        x: (positionX - (containerRect.width / 2)) / scale,
        y: (positionY - (containerRect.height / 2)) / scale,
      };

      const x = innerScale === 1 ? 0 : (center.x * innerScale) + (containerRect.width / 2);
      const y = innerScale === 1 ? 0 : (center.y * innerScale) + (containerRect.height / 2);

      setTransform(x, y, innerScale, 300, 'easeOut');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <View>
      <Description>
        {sharedUser && (
          <>
            <Avatar cover={sharedUser.avatar || getImage('icons/ava-user.png')} />
            <UserName>{sharedUser.name}</UserName>
          </>
        )}

        <Name>
          {roomCaption}
          {roomArea && (
            <>
              &nbsp;{roomArea}m<sup>2</sup>
            </>
          )}
        </Name>

        {!isPreviewMode && <Size>{captionSize}</Size>}
      </Description>

      <Center>
        {isMakeUpdateRender && (
          <ButtonEditorCream
            theme="dark"
            subTheme="text_icon"
            svgNoneFill
            tooltip={{
              render: translations.t('gallery.render.improve.text'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            disabled={!availableImprove}
            onClick={onImproveRender}>
            <IImprove width="17px" height="16px" fill={Colors.coal}/>
            <ActionText showLaptop={true}>{translations.t('gallery.render.improve')}</ActionText>
          </ButtonEditorCream>
        )}

        {(MODE_ALIAS[mode] === UNITY && isMakeUpdateRender) && (
          <ButtonEditor
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.render.text'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={onCreateRender}
            disabled={editor && !editor.task_data || !id}>
            <ICameraSmall width="16px" height="16px" />
            <ActionText>{translations.t('gallery.render')}</ActionText>
          </ButtonEditor>
        )}

        {!isPreviewMode && krpano_url && virtualTourCreator && (
          <ButtonEditorCream
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.tour'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={() => onOpenTourEditor({ disabled: disabledTour, isPreview })}>
            {disabledTour ? <IProPlus /> : <IMagic width="16px" height="16px" />}
            <ActionText>{translations.t('gallery.tour')}</ActionText>
          </ButtonEditorCream>
        )}

        {!isPreviewMode && tour && virtualTourCreator && (
          <ButtonEditorCream
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.tour.edit'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={() => onEditTourEditor(id, tour, { disabled: disabledTour, isPreview })}>
            {disabledTour ? <IProPlus /> : <ISetting width="16px" height="16px" />}
            <ActionText>{translations.t('gallery.tour.edit')}</ActionText>
          </ButtonEditorCream>
        )}

        {!isPreviewMode && publishId && (
          <ButtonEditor
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.copy'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={() => onShare(publishId, Boolean(tour))}>
            <IShare width="16px" height="16px" />
            <ActionText>{translations.t('gallery.share')}</ActionText>
          </ButtonEditor>
        )}

        {tour && (
          <ButtonEditorWrap
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.code'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={() => onCopyTourCode(publishId)}>
            <ICode width="16px" height="16px" />
            <ActionText>{translations.t('gallery.code')}</ActionText>
          </ButtonEditorWrap>
        )}

        {!isPreviewMode && publishId && !krpano && !tour && (
          <ButtonEditor
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.editor'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={() => onEdit({ id, original, preview })}
            disabled={disabledEdit}>
            <IEdit width={16} height={16} fill={Colors.light_gray} />
            <ActionText>{translations.t('gallery.edit')}</ActionText>
          </ButtonEditor>
        )}

        {isPreviewMode && (
          <ButtonEditor
            theme="dark"
            subTheme="text_icon"
            tooltip={{
              render: translations.t('gallery.home'),
              direction: tooltipDirection,
              theme: 'editor_white',
            }}
            onClick={onHomePage}>
            <IGlobe width="16px" height="16px" />
            <ActionText>Planoplan.com</ActionText>
          </ButtonEditor>
        )}
      </Center>


        <Controls>
          {!(isPreviewMode && (tour || krpano_url)) && (
            <>
              <Percentage>{percentage}%</Percentage>

              <DesktopButtons>
                <ButtonEditor
                  theme="dark"
                  subTheme="icon"
                  tooltip={{
                    render: translations.t('gallery.zoom.fill'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  disabled={krpano || !id}
                  onClick={setOriginalSize}>
                  <IFill width="16px" height="16px" />
                </ButtonEditor>

                <ButtonEditor
                  theme="dark"
                  subTheme="icon"
                  tooltip={{
                    render: translations.t('gallery.zoom.fit'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  onClick={resetTransform}
                  disabled={krpano || !id}>
                  <IFit width="16px" height="16px" />
                </ButtonEditor>

                <ButtonEditor
                  theme="dark"
                  subTheme="icon"
                  tooltip={{
                    render: translations.t('gallery.zoom.decrease'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  onClick={zoomOut}
                  disabled={krpano || !id || scale <= ZOOM_PARAMS.MIN}>
                  <IMinusRound width="16px" height="16px" />
                </ButtonEditor>

                <ButtonEditor
                  theme="dark"
                  subTheme="icon"
                  tooltip={{
                    render: translations.t('gallery.zoom.increase'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  onClick={zoomIn}
                  disabled={krpano || !id || scale >= ZOOM_PARAMS.MAX}>
                  <IPlusRound width="16px" height="16px" />
                </ButtonEditor>

                <ButtonEditor
                  theme="dark"
                  subTheme="icon"
                  tooltip={{
                    render: translations.t('gallery.download'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  onClick={() => onDownload(current_image)}
                  disabled={krpano || !isAvailableCheck}>
                  <IDownload width="16px" height="16px" />
                </ButtonEditor>

                {!isPreviewMode && (
                  <ButtonEditor
                    theme="dark"
                    subTheme="icon"
                    tooltip={{
                      render: translations.t('gallery.remove'),
                      direction: tooltipDirection,
                      theme: 'editor_white',
                    }}
                    onClick={() => onRemove([current_image])}
                    disabled={!isAvailableCheck || isProhibitedDelete}>
                    <ITrash2 width="16px" height="16px" />
                  </ButtonEditor>
                )}
              </DesktopButtons>

              <MobileButtons>
                <ButtonEditor
                  theme="dark"
                  subTheme="text_icon"
                  tooltip={{
                    render: translations.t('gallery.download'),
                    direction: tooltipDirection,
                    theme: 'editor_white',
                  }}
                  onClick={() => onDownload(current_image)}
                  disabled={krpano || !isAvailableCheck || isPreview}>
                  <IDownload width="16px" height="16px" />
                  {translations.t('gallery.download')}
                </ButtonEditor>
              </MobileButtons>
            </>
          )}
        </Controls>
    </View>
  );
};

const View = styled.div`
  width: 100%;
  background-color: ${rgba(Colors.night, 0.6)};
  position: relative;
  z-index: 3;
  color: ${Colors.light_gray};
  font-size: 12px;
  line-height: 14px;
  padding: 10px 10px 0 10px;
  display: flex;
  flex-wrap: wrap;

  @media ${tablet_landscape} {
    justify-content: space-between;
  }
`;

const Description = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 10px;
  align-items: center;
`;

const Avatar = styled.span`
  background-image: ${({ cover }) => `url(${cover})`};
  background-size: cover;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
`;

const UserName = styled.span`
  margin-right: 8px;
`;

const Name = styled.span`
  position: relative;

  sup {
    font-size: 8px;
    position: absolute;
    top: -3px;
  }
`;

const Size = styled.span`
  margin-left: 10px;

  @media ${tablet_landscape} {
    margin-left: 15px;
  }
`;

const Center = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  
  svg {
    margin-right: 0;
    flex-shrink: 0;
  }

  button:not(:first-child) {
    margin-left: 5px;
  }
`;

const Percentage = styled.div`
  margin-right: 6px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const DesktopButtons = styled.div`
  display: none;

  @media ${tablet_landscape} {
    display: flex;

    button {
      margin-left: 6px;
    }

    button:nth-child(6) {
      margin-left: 10px;
      display: flex;
    }
  }
`;

const MobileButtons = styled.div`
  @media ${tablet_landscape} {
    display: none;
  }
`;

const ActionText = styled.span`
  display: none;
  margin-left: 5px;
  white-space: nowrap;
  
  ${({ showLaptop }) => showLaptop && `
    @media ${laptop} {
      display: block;
    }
  `};
  
  @media ${laptop_wide} {
    display: block;
  }
`;

const ButtonEditorCream = styled(ButtonEditor)`
  background-color: ${Colors.dark_cream};
  color: ${Colors.coal};
  box-shadow: none;
  border: 1px solid ${Colors.coal};
  
  & > svg {
    fill: transparent !important;
  }
  
  &:hover {
    background-color: #FFB540;
    color: ${Colors.coal};
  }
  
  &:disabled {
    background-color: ${Colors.night};
    pointer-events: none;
  }
`;

const ButtonEditorWrap = styled(ButtonEditor)`
  & > svg {
    fill: transparent !important;
  }
`;
