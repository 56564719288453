import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IVr } from 'planoplan-ui-kit/core/icons/i-vr';
import { ISvg } from 'planoplan-ui-kit/core/icons/i-svg';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { STATUSES, TASK_STATUS } from '../../../constants';
import { Checkbox } from '@components/Form/checkbox';
import { IImage } from '../../../icons';

export const Item = ({ item, onSelect, onChecked, isChecked, current, view }) => {
  const isSvg = item.preview.indexOf('.svg') !== -1;
  const isKrpano = Boolean(item.tour || item.krpano_url);

  const statusColor = useMemo(() => {
    switch (item.status) {
      case STATUSES[TASK_STATUS.waiting]: return '#FF894D';
      case STATUSES[TASK_STATUS.inprocess]: return '#FFB540';
      case STATUSES[TASK_STATUS.cancel]: return Colors.colar;
      case STATUSES[TASK_STATUS.fail]: return Colors.colar;
      case STATUSES[TASK_STATUS.reject]: return Colors.colar;
      default: return '#FF894D';
    }
  }, [item.status]);

  return (
    <View active={item.view_id === current.view_id}>
      <ImageWrap isCover={isKrpano} onClick={() => onSelect(item)}>
        {item.preview ? <img src={`${item.preview}?update=${item.updateAt}`} alt={item.roomCaption} /> : <IImage />}
      </ImageWrap>

      {(item.isAvailableCheck && (item.isComplete || item.isError)) && (
        <CheckboxWrapper>
          <Checkbox
            id={item.view_id}
            onChange={(event) => onChecked(event.target, item)}
            checked={isChecked}
          />
        </CheckboxWrapper>
      )}

      {(item.krpano_url && !item.isPreview) && (
        <IconText view={view} bgColor={Colors.white}>{translations.t('gallery.panorama.caption')}</IconText>
      )}

      {(item.krpano_url && item.isPreview) && (
        <IconText view={view} bgColor={Colors.white}>{translations.t('gallery.panorama.preview.caption')}</IconText>
      )}

      {(item.tour) && (
        <IconText view={view} bgColor={Colors.dark_cream}>{translations.t('gallery.tour.caption')}</IconText>
      )}

      {(item.preview && isSvg) && (
        <Icon view={view} bgColor={Colors.white}>
          <ISvg width={24} height={10} fill={Colors.coal} />
        </Icon>
      )}

      {(item.isPreview && !isSvg && !item.krpano_url) && (
        <IconText view={view} bgColor={Colors.white}>{translations.t('gallery.preview.caption')}</IconText>
      )}

      {(item.isQPlus && !isSvg) && (
        <IconText view={view} bgColor={Colors.frog_green}>{translations.t('gallery.qplus.caption')}</IconText>
      )}

      {item.status !== STATUSES[TASK_STATUS.complete] && <Status color={statusColor}>{translations.t(`gallery.render.status.${item.status}`)}</Status>}
    </View>
  )
};

const View = styled.div`
  ${Mixins.transition('border-color')};
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  outline: 2px solid ${({ active }) => (active ? Colors.dark_cream : 'transparent')};
`;
const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.coal};
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: ${({ isCover }) => isCover ? 'cover' : 'contain'};
  }
`;
const CheckboxWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;
const Icon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: ${({ view }) => view === 'column' ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  background-color: ${(p) => (p.bgColor)};
  position: absolute;
  right: 5px;
  top: 5px;
  pointer-events: none;
`;
const IconText = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: ${(p) => (p.bgColor)};
  padding: 1px 6px;
  border-radius: 14px;
  font-size: 12px;
  line-height: 14px;
  pointer-events: none;
  color: ${Colors.night};
  display: ${({ view }) => view === 'column' ? 'block' : 'none'};
`;
const Status = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 1px 6px;
  color: ${Colors.white};
  background-color: ${({ color }) => color};
  border-radius: 10px;
  cursor: pointer;
`;
