import { SIGN_UP_MODE, LOGIN_MODE, CHECKING_MODE } from '../../constants';

export const initialState = {
  step: '',
  prevStep: {
    name: '',
    payload: {},
  },
  updateStatus: '',
  signUpMode: SIGN_UP_MODE.EMAIL,
  loginMode: LOGIN_MODE.EMAIL,
  checkingMode: CHECKING_MODE.CONFIRM_EMAIL_AFTER_SIGNUP,
  isProcessing: false,
  isCanResendCode: false,
  isLogin: null,
  // Для шага Welcome
  socialInfo: {
    name: '',
    avatar: '',
  },
  profile: {},
  domains: [],
  selectedDomain: {
    view_value: '',
    value: '',
    render: '',
  },
  checkingEmail: '',
  error: {
    type: '',
    message: '',
    step: '',
  },
  settings: {},
  downloadedPercent: 0,
  firstLogin: false,
  surveyCompleted: false,
  country: '',
};
