import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from '../../icons';

export const Close = ({ onClose }) => (
  <View onClick={onClose}>
    <IClose />
  </View>
);

const View = styled.div`
  display: block;
  cursor: pointer;
  padding: 8px;

  svg {
    ${Mixins.transition('transform')};
    transition-duration: 150ms;
    display: block;
  }

  &:hover svg {
    transform: scale(0.9);
  }
`;
