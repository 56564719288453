import React, { useContext } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Indent } from '../../modules/indent';
import { laptop } from '../../modules/breakpoints';
import { Navigation } from './organism/navigation';
import { Cards } from './organism/cards';
import { CartButton, CartButtonView } from '../CartButton';
import { Close } from '../Close';
import { StoreContext } from '../../modules/Store/Store';
import * as effects from '../../modules/effects';
import { isMobile } from 'react-device-detect';

export const Sidebar = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { screen, loading, settings, availableScreens } = state;
  const { localeUi } = settings;
  const { processing, firstLoading, error } = loading;
  const setScreen = (screen) => effects.setScreen(dispatch, state, screen);
  const isShowScreen = (!processing || !firstLoading) && !error;

  return (
    <View>
      <Top>
        {isShowScreen && (
          <>
            <Navigation availableScreens={availableScreens} screen={screen} setScreen={setScreen} />
            <Cards locale={localeUi} />
          </>
        )}

        <CloseButton>
          <Close width="20px" height="20px" />
        </CloseButton>
      </Top>
      {isShowScreen && (
        <Bottom>
          <CartButton screen={screen} setScreen={setScreen} />
        </Bottom>
      )}
    </View>
  );
};

const View = styled.div`
  display: flex;
  background-color: ${Colors.night};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  height: 60px;

  @media ${laptop} {
    ${Grid.column(6, 30)};
    height: 652px;
    flex-direction: column;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media ${laptop} {
    ${Indent.padding_top(0.5)};
    ${Indent.padding_bottom(1)};
    width: auto;
    flex-direction: column;
    height: 100%;
  }
`;

const CloseButton = styled.div`
  ${Mixins.transition('background-color')};
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.night};
  margin-left: auto;

  &:hover {
    background-color: ${!isMobile && Colors.planoplan_blue};
  }

  @media ${laptop} {
    display: none;
  }
`;

const Bottom = styled.div`
  ${CartButtonView} {
    display: none;

    @media ${laptop} {
      display: flex;
    }
  }
`;
