import React, { useMemo } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Link } from '@components/link';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { STATUSES, TASK_STATUS } from '../../../constants';

export const Status = ({ status = '' }) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case STATUSES[TASK_STATUS.waiting]: return '#FF894D';
      case STATUSES[TASK_STATUS.inprocess]: return '#FFB540';
      case STATUSES[TASK_STATUS.cancel]: return Colors.colar;
      case STATUSES[TASK_STATUS.fail]: return Colors.colar;
      case STATUSES[TASK_STATUS.reject]: return Colors.colar;
      default: return '#FF894D';
    }
  }, [status]);
  const errorStatus = useMemo(() => [STATUSES[TASK_STATUS.cancel], STATUSES[TASK_STATUS.fail], STATUSES[TASK_STATUS.reject]].includes(status), [status]);
  const openSupport = (event) => {
    event.preventDefault();

    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: event.target.href,
    });
  };

  return (
    <View>
      <Content>
        <Caption color={statusColor}>{translations.t(`gallery.render.status.${status}`)}</Caption>
        <Text>
          <span dangerouslySetInnerHTML={{ __html: translations.t(`gallery.render.status.${status}.text`) }}/>

          {errorStatus && (
            <Link fontSize="inherit" href="mailto:support@planoplan.com" onClick={openSupport}>
              &nbsp;{translations.t('gallery.render.status.support')}
            </Link>
          )}
        </Text>
      </Content>
    </View>
  )
};

const View = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.night, 0.7)};
  z-index: 2;
`;
const Content = styled.div`
  width: 410px;
  padding: 30px;
  background-color: ${Colors.night};
  border-radius: 4px;
  color: ${Colors.white};
  font-size: 12px;
  line-height: 14px;
  border: 1px solid ${Colors.coal};
`;
const Caption = styled.div`
  margin-bottom: 10px;
  background-color: ${({ color }) => color};
  font-size: 12px;
  line-height: 14px;
  padding: 0 6px 1px 6px;
  color: ${Colors.white};
  border-radius: 10px;
  display: inline-block;
`;
const Text = styled.div`
  a {
    color: ${Colors.dark_cream};
    text-decoration: none;
  }
`;
