import { translations } from '@libs';

export const PER_PAGE = 30;

export const HEADER_MODES = {
  default: 'default',
  search: 'search',
  select: 'select'
};

export const VIEWMODE = {
  card: 'card',
  list: 'list'
};

export const ROW_TYPE = {
  project: 'project',
  folder: 'folder',
};

export const MODE_PROJECT = {
  view: 'view',
  edit: 'edit',
};

export const SORTING = {
  type: {
    alias: 'type',
    width: 80,
    items: {
      all: {
        name: () => translations.t('cabinet.projects.sort.all'),
        alias: 'all',
        param: ''
      },
      project: {
        name: () => translations.t('cabinet.projects.sort.projects'),
        alias: 'project',
        param: 'project'

      },
      folder: {
        name: () => translations.t('cabinet.projects.sort.folders'),
        alias: 'folder',
        param: 'folder'
      }
    }
  },
  base: {
    alias: 'base',
    width: 190,
    items: {
      modified: {
        name: () => translations.t('cabinet.projects.sort.modified'),
        alias: 'modified',
        param: 'modified'
      },
      name: {
        name: () => translations.t('cabinet.projects.sort.aphabetical'),
        alias: 'name',
        param: 'name'
      },
      created: {
        name: () => translations.t('cabinet.projects.sort.create'),
        alias: 'created',
        param: 'created'
      }
    }
  }
};
