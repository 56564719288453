import React from 'react';

export const IClose = ({ width = 24, height = 24, fill = '#D1D1D1' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 20L20 3" stroke={fill}/>
      <path d="M3.5 3.5L20.5 19.5" stroke={fill}/>
    </svg>
  );
};
