import { getBreadcrumbs } from '../normalize';
import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_PAGE,
  SET_PROFILE,
  SET_GOODS,
  SET_TEAMS,
  SET_TEAM_ID,
  SET_CURRENT_TEAM,
  SET_TEAM_LOADING,
  SET_TEAM_MEMBERS,
  SET_EXPIRED_TEAM,
  SET_PROJECTS,
  RESET_PROJECTS,
  SET_PROJECTS_ITEMS,
  SET_MORE_PROJECTS_ITEMS,
  SET_RENDER_GOODS,
  SET_WIDGET_GOODS,
  SET_SUBSCRIPTIONS,
  SET_LAST_NOTIFICATION,
  SET_NOTIFICATIONS,
  SET_CHECKSUM,
  SET_HISTORY_PAYMENT_DATA,
  SET_PROCESSING,
  SET_CARDS,
  PUT_TARIFF,
  SET_STEPS_UNSUBSCRIBE,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        currentPage: {
          ...state.currentPage,
          alias: action.payload,
        },
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_GOODS:
      return {
        ...state,
        tariff: action.payload.tariff,
        goods: action.payload.goods,
        currency: action.payload.currency,
      };
    case PUT_TARIFF:
      return {
        ...state,
        tariff: {
          ...state.tariff,
          ...action.payload,
        },
      };
    case SET_TEAMS:
      return {
        ...state,
        team: {
          ...state.team,
          list: action.payload
        },
      };
    case SET_TEAM_ID:
      return {
        ...state,
        team: {
          ...state.team,
          id: action.payload ? action.payload : 0,
        },
      };
    case SET_TEAM_MEMBERS:
      return {
        ...state,
        team: {
          ...state.team,
          members: action.payload,
        },
      };
    case SET_EXPIRED_TEAM:
      return {
        ...state,
        team: {
          ...state.team,
          expired: Boolean(action.payload),
        },
      };
    case SET_CURRENT_TEAM:
      return {
        ...state,
        team: {
          ...state.team,
          current: action.payload,
        }
      };
    case SET_TEAM_LOADING:
      return {
        ...state,
        team: {
          ...state.team,
          loading: action.payload,
        }
      };
    case SET_LAST_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          lastId: action.payload || 0
        },
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          list: action.payload
        },
      };
    case SET_CHECKSUM:
      return {
        ...state,
        checksum: action.payload
      };
    case SET_CARDS:
      return {
        ...state,
        cards: action.payload
      };
    case SET_PROJECTS: {
      return {
        ...state,
        projects: {
          more: action.payload.more,
          items: action.payload.items,
          currentFolder: action.payload.current_folder,
          foldersTree: action.payload.folders_tree,
          breadcrumbs: getBreadcrumbs(action.payload.current_folder, action.payload.folders_tree),
        },
      };
    }
    case RESET_PROJECTS: {
      return {
        ...state,
        projects: {
          more: false,
          items: [],
          currentFolder: null,
          foldersTree: [],
          breadcrumbs: [],
        },
      };
    }
    case SET_PROJECTS_ITEMS: {
      return {
        ...state,
        projects: {
          ...state.projects,
          items: action.payload,
        },
      };
    }
    case SET_MORE_PROJECTS_ITEMS: {
      const items = [...state.projects.items, ...action.payload.items];

      return {
        ...state,
        projects: {
          ...state.projects,
          more: action.payload.more,
          items
        },
      };
    }
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
            },
          },
          processing: true,
          error: '',
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }
    case SET_RENDER_GOODS:
      return {
        ...state,
        balanceGoods: {
          ...state.balanceGoods,
          renderGoods: action.payload,
        },
      };
    case SET_WIDGET_GOODS:
      return {
        ...state,
        balanceGoods: {
          ...state.balanceGoods,
          widgetGoods: action.payload,
        },
      };
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case SET_HISTORY_PAYMENT_DATA:
      return {
        ...state,
        historyPayment: {
          ...state.historyPayment,
          list: action.payload.history,
          pageCount: action.payload.pageCount,
        }
      };
    case SET_STEPS_UNSUBSCRIBE:
      return {
        ...state,
        stepsUnsubscribe: action.payload
      };
    case SET_PROCESSING:
      return {
        ...state,
        historyPayment: {
          ...state.historyPayment,
          processing: action.payload,
        }
      };
    default:
      return state;
  }
};
