import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { LocalStorage } from '@libs';
import { POP_CABINET_LAYOUTS } from '@globalLocalStorage';
import * as action from '../../modules/Store/action';
import { PAGES } from '../../constants';

export const PageWaiting = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    LocalStorage.remove(POP_CABINET_LAYOUTS);
    dispatch(action.setPage(PAGES.layouts));
  }, []);

  return (
      <View>
        <Preloader width="80px" height="80px" fill={Colors.planoplan_blue} />
      </View>
  );
};

const View = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
