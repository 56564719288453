import React from 'react';
import styled from 'styled-components';

export const Promo = () => {
  return (
    <View>
      Promo
    </View>
  );
};

const View = styled.div``;
