import { createBreakpoints } from '@libs';

const points = [
  {
    mobile: 0,
  },
  {
    tablet_landscape: 1024,
  },
];

export const { tablet_landscape } = createBreakpoints(points);
