import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ButtonEditor } from 'planoplan-ui-kit/core/buttonEditor';
import { ITrash2 } from 'planoplan-ui-kit/core/icons/i-trash-2';
import { IDownload } from 'planoplan-ui-kit/core/icons/i-download';
import { translations } from '@libs';
import { Checkbox } from '@components/Form/checkbox';

export const Controls = ({
  onCheckedAll,
  isCheckedAll,
  onDownloadAll,
  onRemove,
  isDisabled,
  isDisabledAll,
  isProhibitedDelete,
}) => (
  <View>
    <CheckboxWrapper>
      <Checkbox
        id="selectall"
        onChange={(event) => onCheckedAll(event.target)}
        checked={isCheckedAll}
        disabled={isDisabledAll}
        postLabel={true}
        label={isCheckedAll ? translations.t('gallery.clearall') : translations.t('gallery.selectall')} />
    </CheckboxWrapper>
    <ButtonsGroup>
      <ButtonEditor
        theme="dark"
        subTheme="icon"
        tooltip={{
          render: translations.t('gallery.download.selected'),
          direction: 'bottom',
          theme: 'editor_white',
        }}
        onClick={onDownloadAll}
        disabled={isDisabled}>
        <IDownload width="14px" height="16px" />
      </ButtonEditor>
      <ButtonEditor
        theme="dark"
        subTheme="icon"
        tooltip={{
          render: translations.t('gallery.remove.selected'),
          direction: 'bottom',
          theme: 'editor_white',
        }}
        onClick={onRemove}
        disabled={isDisabled || isProhibitedDelete}>
        <ITrash2 width="16px" height="16px" />
      </ButtonEditor>
    </ButtonsGroup>
  </View>
);

const View = styled.div`
  padding: 15px 20px 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CheckboxWrapper = styled.div`
  margin-left: 8px; 
`;

const Label = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-left: 6px;
  color: ${Colors.light_gray};
`;

const ButtonsGroup = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: 6px;
  }
`;
