// Actions
export const SET_PROFILE = 'SET_PROFILE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_CREATING_PDF = 'SET_CREATING_PDF';
export const SET_DESIGN_PROJECT = 'SET_DESIGN_PROJECT';
export const SET_DESIGN_PROJECT_NAME = 'SET_DESIGN_PROJECT_NAME';
export const SET_DESIGN_PROJECT_DESC = 'SET_DESIGN_PROJECT_DESC';
export const SET_DELETE_DESIGN_PROJECT_IMAGE = 'SET_DELETE_DESIGN_PROJECT_IMAGE';
export const SET_DESIGN_PROJECT_IMAGES = 'SET_DESIGN_PROJECT_IMAGES';
export const SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME = 'SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME';
export const SET_DESIGN_PROJECT_LOADING = 'SET_DESIGN_PROJECT_LOADING';
export const SET_DESIGN_PROJECT_LOADING_PDF = 'SET_DESIGN_PROJECT_LOADING_PDF';
export const SET_HAS_PDF = 'SET_HAS_PDF';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_GALLERY = 'GET_GALLERY';
export const GET_PROFILE = 'GET_PROFILE';

export const STATUSES = {
  0: 'waiting',
  1: 'inprocess',
  2: 'complete',
  3: 'cancel',
  4: 'fail',
  5: 'reject',
};

export const RENDER_SIZES = {
  450: 'SD',
  800: 'SD',
  720: 'HD',
  1280: 'HD',
  1080: 'FullHD',
  1920: 'FullHD',
  1440: 'QHD',
  2560: 'QHD',
  2160: '4K',
  3840: '4K',
};

// Errors
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';

// Invoke actions
export const BUTTONS = {
  SAVE_PDF: 'SAVE_PDF',
};

export const DESIGN_PROJECT_OPEN = 'DESIGN_PROJECT_OPEN';
export const DESIGN_PROJECT_CLOSE = 'DESIGN_PROJECT_CLOSE';

export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
};
