import React from 'react';
import styled from 'styled-components';

export const Description = ({ children }) => <View>{children}</View>;

const View = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  a {
    line-height: 14px;
  }
`;
