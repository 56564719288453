import React from 'react';

export const IconPdfFooterOn = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="white"/>
    <rect x="5.5" y="8.5" width="13" height="8" stroke="#4A4A4A"/>
    <rect x="5.5" y="12.5" width="13" height="4" stroke="#4A4A4A"/>
    <rect x="5.5" y="12.5" width="4" height="4" stroke="#4A4A4A"/>
  </svg>
);
