import { Colors } from 'planoplan-ui-kit/core/colors';

export const themes = {
  theme: {
    primary: {
      color: Colors.planoplan_blue,
      hover: {
        color: Colors.electric_blue,
      },
      disabled: {
        color: Colors.whisper,
      },
    },
    warning: {
      color: Colors.colar,
      hover: {
        color: Colors.dull_red,
      },
      disabled: {
        color: Colors.whisper,
      },
    },
    orange: {
      color: Colors.orange,
      hover: {
        color: Colors.orange50,
      },
      disabled: {
        color: Colors.orange25,
      },
    },
  },

  size: {
    L: {
      fontSize: '18px',
      lineHeight: '22px',
    },
    M: {
      fontSize: '14px',
      lineHeight: '17px',
    },
    S: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  },
};
