import {
  GOODS_TARIFF_ALIAS,
  RENDER_RESOLUTIONS,
  ALIAS_RESOLUTIONS,
  TARIFF_PERIODS,
  CURRENCY,
  TARIFF_NAME_ALIAS,
  VIEW_ALIAS_RESOLUTIONS
} from '../constants';
import {
  CARD_TYPE_ICONS,
  METHOD_PAY_ICONS,
  CARD_PAY_TYPES,
  MODE_PAY,
} from '../constants/card';
import React from 'react';
import { translations } from '@libs';

export const getSecondGoodsTariff = (goods, totalGoods) => {
  const isTariff = GOODS_TARIFF_ALIAS.includes(goods.alias);

  if (!isTariff) {
    return {};
  }

  try {
    const second = totalGoods.find(item => {
      if (!GOODS_TARIFF_ALIAS.includes(item.alias)) {
        return false;
      }

      return goods.id !== item.id && goods.alias === item.alias && goods.params.tariff_id === item.params.tariff_id;
    });

    if (!second) {
      throw new Error('Second Tariff not found!');
    }

    return second;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getRenderQualityAlias = (goods) => {
  const isTariff = GOODS_TARIFF_ALIAS.includes(goods.alias);

  if (isTariff) {
    return {};
  }

  const getAlias = (resolution) => {
    if (!resolution) {
      return ALIAS_RESOLUTIONS.HD;
    }

    try {
      let alias = ALIAS_RESOLUTIONS.HD;

      for (let key in RENDER_RESOLUTIONS) {
        if (Object.prototype.hasOwnProperty.call(RENDER_RESOLUTIONS, key)) {
          if (resolution === RENDER_RESOLUTIONS[key]) {
            alias = key;
          }
        }
      }

      return alias;
    } catch (error) {
      console.error(error);

      return ALIAS_RESOLUTIONS.HD;
    }
  };
  const getComparisonAlias = (alias) => {
    if (!alias) {
      return ALIAS_RESOLUTIONS.PREVIEW;
    }

    try {
      const arrayAlias = Object.keys(ALIAS_RESOLUTIONS);
      const index = arrayAlias.indexOf(alias);

      if (index === -1 || index === 0) {
        return ALIAS_RESOLUTIONS.PREVIEW;
      }

      return arrayAlias[index - 1];
    } catch (error) {
      console.error(error);

      return ALIAS_RESOLUTIONS.PREVIEW;
    }
  };

  try {
    const alias = getAlias(goods.params.resolution);
    const comparisonAlias = getComparisonAlias(alias);

    return {
      qualityAlias: alias,
      comparisonQualityAlias: comparisonAlias
    };
  } catch (error) {
    console.error(error);

    return {};
  }
};

export const returnGoodsWithParams = (goods) => {
  if (!goods || !goods.params) {
    return goods;
  }

  const getByJSONParse = (params) => {
    try {
      return JSON.parse(params);
    } catch (error) {
      return false
    }
  };

  const getBySplitParse = (params) => {
    const paramsArray = params.split(';');
    const paramsObject = {};

    for (const configItem of paramsArray) {
      const [key, value] = configItem.split('=');
      const normalKey = key.trim().replace(/^(\d+_)/, '');

      paramsObject[normalKey] = value ? value.trim() : value;
    }

    return paramsObject;
  };


  try {
    let params = getByJSONParse(goods.params);

    if (!params) {
      params = getBySplitParse(goods.params);
    }

    if (!params) {
      return goods;
    }

    return {
      ...goods,
      params,
    }
  } catch (error) {
    console.error(error);

    return goods;
  }
};

export const getTariffPeriod = (goods, second) => {
  const isTariff = GOODS_TARIFF_ALIAS.includes(goods.alias);

  if (!isTariff) {
    return {};
  }

  try {
    return {
      main: { period: goods.extra_data.duration === 12 ? TARIFF_PERIODS.year : TARIFF_PERIODS.month },
      second: { period: second.extra_data.duration === 12 ? TARIFF_PERIODS.year : TARIFF_PERIODS.month },
    }
  } catch (error) {
    console.error(error);

    return {
      main: { period: '' },
      second: { period: '' },
    }
  }
};

export const getDefaultPackageCount = (goods) => {
  const isTariff = GOODS_TARIFF_ALIAS.includes(goods.alias);

  if (isTariff) {
    return null;
  }

  try {
    let count = 0;

    goods.in_sets.forEach(item => {
      if (item.count > count) {
        count = item.count;
      }
    });

    if (count === 0) {
      return null;
    }

    return count;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const getOnePackPrice = (goods) => {
  try {
    let count = 500;
    let minSet = {};

    goods.in_sets.forEach((set) => {
      if (set.count < count) {
        count = set.count;
        minSet = set;
      }
    });

    if (!minSet.price) {
      return 0;
    }

    return Number((minSet.price / minSet.count).toFixed(2));
  } catch (error) {
    console.error(error);

    return 0;
  }
};

export const getDefaultMethodPay = (bindingCard, paymentMethods) => {
  if (bindingCard[0]) {
    return bindingCard[0];
  }

  return paymentMethods[0];
};

export const getPaymentMethodWithNewParams = (methods) => {
  return methods.map(method => {
    const alias = method.alias;
    const Icon = METHOD_PAY_ICONS[alias];

    return {
      ...method,
      Icon,
      modePay: MODE_PAY.bank,
    }
  });
};

export const getBankCardWithNewParams = (cards) => {
  return cards.map(card => {
    const type = CARD_PAY_TYPES[card.type];
    const brand = (type === CARD_PAY_TYPES.card ? card.info.brand : card.info.card_type).toLowerCase();
    const Icon = CARD_TYPE_ICONS[brand];

    return {
      ...card,
      Icon,
      brand: brand,
      modePay: MODE_PAY.binding,
    }
  });
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getCurrentGoods = () => (dispatch, getState) => {
  try {
    const { selected: { period, pack }, goods: { main: goods, second, isTariff } } = getState();

    if (isTariff) {
      const monthGoods = goods.period === TARIFF_PERIODS.month ? goods : second;
      const yearGoods = goods.period === TARIFF_PERIODS.year ? goods : second;

      return period === TARIFF_PERIODS.year ? yearGoods : monthGoods;
    }

    return goods.in_sets.find(item => item.count === pack);
  } catch (error) {
    console.error(error);

    return {}
  }
};

export const getCurrentPrices = () => (dispatch, getState) => {
  const {
    profile: { currency },
    goods: { main: goods, second, isTariff },
    selected: { pack, period, country },
    appliedPromo,
  } = getState();
  const digits = currency === CURRENCY.RUB ? 0 : 2;

  if (isTariff) {
    const monthGoods = goods.period === TARIFF_PERIODS.month ? goods : second;
    const yearGoods = goods.period === TARIFF_PERIODS.year ? goods : second;
    const currentGoods = period === TARIFF_PERIODS.year ? yearGoods : monthGoods;
    const currentYearPeriod = period === TARIFF_PERIODS.year;

    const priceWithoutDiscount = currentYearPeriod ? monthGoods.price * 12 : monthGoods.price;
    const discount = currentYearPeriod ? ((monthGoods.price * 12 - yearGoods.price) / (monthGoods.price * 12) * 100).toFixed(0) : 0;
    const discountWithPromo = Number(discount) + Number(appliedPromo.discount || 0);
    const price = appliedPromo.price || currentGoods.price;
    const priceMonth = currentYearPeriod ? (price / 12).toFixed(digits) : price;

    const vat = country ? country.vat : 0;
    const priceVat = (price * vat / 100).toFixed(digits);

    return {
      priceWithoutDiscount: Number(priceWithoutDiscount),
      discountWithPromo,
      price : Number(price),
      pricePath: priceMonth,
      vat,
      priceVat: Number(priceVat),
      totalPrice: Number(price) + Number(priceVat)
    }
  }

  const oneMinPackPrice = getOnePackPrice(goods);
  const currentSet = goods.in_sets.find(item => item.count === pack);
  const bigPrice = oneMinPackPrice * currentSet.count;
  const discount = 100 - ((currentSet.price / bigPrice) * 100).toFixed(0);
  const discountWithPromo = Number(discount) + Number(appliedPromo.discount || 0);
  const price = appliedPromo.price || currentSet.price;
  const onePackPrice = (price / currentSet.count).toFixed(digits);

  const vat = country ? country.vat : 0;
  const priceVat = (price * vat / 100).toFixed(digits);

  return {
    priceWithoutDiscount: Number(bigPrice),
    discountWithPromo,
    price: Number(price),
    pricePath: onePackPrice,
    vat,
    priceVat: Number(priceVat),
    totalPrice: Number(price) + Number(priceVat)
  }
};

export const getPrices = () => (dispatch, getState) => {
  const {
    profile: { currency },
    goods: { main: goods, second, isTariff },
  } = getState();
  const digits = currency === CURRENCY.RUB ? 0 : 2;

  if (isTariff) {
    const monthGoods = goods.period === TARIFF_PERIODS.month ? goods : second;
    const yearGoods = goods.period === TARIFF_PERIODS.year ? goods : second;
    const pricePayMonth = Math.round(monthGoods.price);
    const pricePayYear = Math.round(yearGoods.price / 12);
    const discountPayYear = Math.round((pricePayMonth - pricePayYear) / pricePayMonth * 100);

    return {
      pricePayMonth,
      pricePayYear,
      discountPayYear
    }
  }

  const oneMinPackPrice = getOnePackPrice(goods);
  const sets = goods.in_sets.map((set) => {
    const bigPrice = oneMinPackPrice * set.count;
    const discount = 100 - ((set.price / bigPrice) * 100).toFixed(0);

    return {
      ...set,
      packPrice: (set.price / set.count).toFixed(digits),
      discount
    };
  });

  return { sets };
};

export const getGoodsName = () => (dispatch, getState) => {
  const { goods: { main: goods, isTariff, isPanorama }} = getState();

  const tariffName = (TARIFF_NAME_ALIAS[goods.extra_data.tariff_alias] || '').toUpperCase();
  const renderName = VIEW_ALIAS_RESOLUTIONS[goods.qualityAlias];
  const resolution = !isTariff && goods.params.resolution;

  if (isTariff) {
    return `${translations.t('quickstore.subscription')} ${tariffName}`;
  }

  if (isPanorama) {
    return goods.caption;
  }

  return`${renderName} ${translations.t('quickstore.renderer')} ${resolution}px`;
};
