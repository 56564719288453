import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { ON_OPEN_STORE } from '@observer/constants';
import { TARIFF_ALIAS } from '../../constants';

const observer = new window.POPObserver();

export const UserTariff = () => {
  const state = useSelector(state => state);
  const { tariff } = state;

  if (!tariff.alias) {
    return (<></>);
  }

  const isViewOnly = tariff.alias === TARIFF_ALIAS.VIEW_ONLY;
  const isProPlusTrial = tariff.alias === TARIFF_ALIAS.PROPLUS && tariff.is_trial;
  const isTeam = tariff.alias === TARIFF_ALIAS.TEAM;
  const expire = new Date(tariff.expire * 1000).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });

  const onOpenStore = (params) => {
    const options = {
      cartTariff: isTeam ? tariff.alias : null,
      initialScreen: isTeam ? 'cart' : 'pricing',
      ...params,
    };

    observer.postMessage(ON_OPEN_STORE, options);
  };

  if (isViewOnly) {
    return (
      <View>
        <Info>{translations.t('cabinet.user_tariff.current')} <span>{tariff.name}</span></Info>
        <ButtonWrap>
          <ButtonTariff size="S" onClick={onOpenStore}>{translations.t('cabinet.user_tariff.buy')}</ButtonTariff>
        </ButtonWrap>
      </View>
    )
  }

  if (isProPlusTrial) {
    const daySeconds = 24 * 60 * 60 * 1000;
    const countDay = 5;
    const isLeftDays = (tariff.expire * 1000) <= (Date.now() + countDay * daySeconds);
    const leftDays = Math.max(Number((((tariff.expire * 1000) - Date.now()) / 24 /60 /60 / 1000).toFixed()), 1);

    return (
      <View>
        <Info>{translations.t('cabinet.user_tariff.current')} <span>TRIAL</span></Info>
        {isLeftDays ?
          <Info>{translations.t('cabinet.user_tariff.day_left')} <span>{leftDays}</span></Info> :
          <Info>{translations.t('cabinet.user_tariff.payment')} <span>{expire}</span></Info>
        }

        <ButtonWrap>
          <ButtonTariff size="S" onClick={() => onOpenStore({ promocode: 'TRIAL', cartTariff: TARIFF_ALIAS.PROPLUS, initialScreen: 'cart' })}>
            {translations.t('cabinet.user_tariff.buy_sale')}
          </ButtonTariff>
        </ButtonWrap>
      </View>
    )
  }

  if (isTeam) {
    return (
      <View>
        <Info>{translations.t('cabinet.user_tariff.current')} <span>{tariff.name}</span></Info>
        <Info>{translations.t('cabinet.user_tariff.payment')} <span>{expire}</span></Info>
      </View>
    )
  }

  return (
    <View>
      <Info>{translations.t('cabinet.user_tariff.current')} <span>{tariff.name}</span></Info>
      <Info>{translations.t('cabinet.user_tariff.payment')} <span>{expire}</span></Info>
      <ButtonWrap>
        <ButtonTariff size="S" onClick={onOpenStore}>{translations.t('cabinet.user_tariff.upgrade')}</ButtonTariff>
      </ButtonWrap>
    </View>
  );
};

const View = styled.div``;
const Info = styled.div`
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.black};
  font-weight: 300;
  
  span {
    font-weight: 400;
  }
`;
const ButtonWrap = styled.div`
  margin-top: 9px;
`;
const ButtonTariff = styled(Button)`
  background-color: ${Colors.coal};
  
  &:hover {
    background-color: ${Colors.planoplan_blue};
  }
`;
