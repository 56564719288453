import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import * as actions from '../../modules/Store/action';
import { SCREENS } from '../../constants';
import { StatusLayout } from '../../layouts/status';
import { IconPending } from '@icons';

export const StripeRequireScreen = memo(() => {
  const dispatch = useDispatch();
  const { payData, settings: { locale }} = useSelector(state => state);
  const stripePromise = loadStripe(payData.publicKey, { locale: locale });

  const confirmCardPayment = async () => {
    try {
      const stripe = await stripePromise;
      const response = await stripe.confirmCardPayment(payData.client_secret, { payment_method: payData.payment_method });

      if (response.error) {
        dispatch(actions.setScreen(SCREENS.error));

        return;
      }

      dispatch(actions.setScreen(SCREENS.success));
    } catch (error) {
      console.error(error);

      dispatch(actions.setScreen(SCREENS.error));
    }
  };

  useEffect(() => {
    (async () => {
      await confirmCardPayment();
    })();
  }, []);

  return (
    <StatusLayout title={translations.t('quickstore.pay.pending.title')}>
      <IconPending />
    </StatusLayout>
  );
});
