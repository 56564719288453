import React from 'react';

export const IHelper = ({ width = 36, height = 36 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" fill="#FFF59B"/>
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="black"/>
      <circle cx="18" cy="18" r="13.0781" fill="#292929"/>
      <path d="M17.2293 18.8682C17.2293 18.0077 17.5039 17.5316 18.4926 16.9274L19.4447 16.3232C20.2686 15.8106 20.763 15.3162 20.763 14.2176C20.763 13.0458 19.9574 12.167 18.3644 12.167C16.6617 12.167 15.7645 13.174 15.4899 14.3641L14.135 14.0895C14.4829 12.2768 15.8011 10.9036 18.3644 10.9036C20.9094 10.9036 22.2094 12.2952 22.2094 14.2176C22.2094 15.9021 21.2573 16.7626 20.4151 17.2936L19.5912 17.8063C18.7489 18.3189 18.6025 18.6119 18.6025 19.3626V20.1499H17.2293V18.8682ZM17.0462 23.5371V21.6146H18.8222V23.5371H17.0462Z" fill="#FFF59B"/>
    </svg>
  );
};
