import React from 'react';
import { Link } from '@components/link';
import { translations } from '@libs';
import { Header } from '../../components/Header';
import { Text, ButtonAction, Image } from '../../components';
import { ENTRY_STEPS, SOCIAL_MODE_TEXT } from '../../constants';
import { logout } from '../../modules/effects';

export const Error = ({ setStep = () => {}, onRetryUpdate = () => {}, error = {} }) => {
  const { step = '' } = error;
  const isEmailUsed = error.message === 'Email already used';
  const isEmailWrong = error.message === 'Mail sending error' || error.message.indexOf('Mail sending failed') !== -1;
  const informations = {
    default: {
      title: translations.t('form_entry.error.header'),
      text: translations.t('form_entry.error.text'),
      button: translations.t('form_entry.again'),
    },
    errorEmail: {
      title: translations.t('form_entry.error.social.header'),
      text: translations.tInsert('form_entry.error.social.text', {
        social: SOCIAL_MODE_TEXT[error.data.social],
        email: error.data.email
      }),
      button: translations.t('form_entry.return'),
    },
    wrongEmail: {
      title: translations.t('form_entry.email.wrong.header'),
      text: translations.t('form_entry.email.wrong.text'),
      button: translations.t('form_entry.email.wrong.button'),
    }
  };
  const info = isEmailWrong ? informations.wrongEmail : isEmailUsed ? informations.errorEmail : informations.default;

  const onClick = () => {
    if (isEmailWrong) {
      logout();

      return;
    }

    if (step === ENTRY_STEPS.UPDATE) {
      onRetryUpdate();
    } else {
      setStep(step);
    }
  };

  return (
    <>
      <Header title={info.title} />
      <Image normal="form/something-wrong.png" retina="form/something-wrong@2x.png" alt="Error" />

      <Text>
        <span
          dangerouslySetInnerHTML={{
            __html: info.text,
          }}
        />
        {!isEmailUsed &&
          <Link fontSize="inherit" lineHeight="inherit" href="mailto:support@planoplan.com">
            support@planoplan.com
          </Link>
        }
      </Text>

      <ButtonAction onClick={onClick} data-test="button_try-again">
        {info.button}
      </ButtonAction>
    </>
  );
};
