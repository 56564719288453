import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { StoreContext } from '../../modules/Store/Store';
import { loadStripe } from '@stripe/stripe-js';
import { notifications } from '@features/notifications';
import { clearCart, saveLastBuy } from '../../modules/effects';
import { Analytics } from '../../modules/analytics';
import { translations } from '@libs';
import { Link } from '@components/link';
import { Message } from '../../components/Message';
import { tablet_landscape } from '../../modules/breakpoints';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PAY,
  SET_PAYMENT_STATUS,
  PAY_STATUSES,
} from '../../constants';

export const Require = ({clientSecret, publicKey, paymentMethod}) => {
  const [state, dispatch] = useContext(StoreContext);
  const [confirmationStatus, setConfiramtionStatus] = useState('');
  const { settings: {mode: modeApp, locale}, cart } = state;
  const stripePromise = loadStripe(publicKey, {
    locale: locale
  });

  useEffect(() => {
    confirmCardPayment();
    // eslint-disable-next-line
  }, []);

  const confirmCardPayment = async () => {
    dispatch({ type: SET_LOADING_ON, payload: { name: SET_PAY } });

    try {
      const stripe = await stripePromise;
      const response = await stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethod });
      const status = response.paymentIntent?.status;
      const error = response.error;

      if (status === PAY_STATUSES.succeeded) {
        dispatch({
          type: SET_PAYMENT_STATUS,
          payload: { status: PAY_STATUSES.succeeded, cart },
        });

        let cartCategories = '';

        try {
          const sortingArr = ['tariff', 'render', 'package', 'widget', 'panorama'];

          cartCategories = [...new Set(cart.map(item => item.view_alias))].sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b)).join(',');
        } catch (error) {
          console.error(error);
        }

        setConfiramtionStatus(PAY_STATUSES.succeeded);
        saveLastBuy(Object.keys(state.savedCart), state.checksum, dispatch);
        clearCart(dispatch, state);
        Analytics.statusPaySuccess(modeApp, locale, state, cartCategories);
      }

      if (error) {
        throw new Error(error.message);
      }

      dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PAY } });
    } catch (error) {
      dispatch({ type: SET_LOADING_OFF, payload: { name: SET_PAY } });
      setConfiramtionStatus(PAY_STATUSES.error);
      notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
    }
  };

  return <View>
    {confirmationStatus === PAY_STATUSES.succeeded && (
      <Message type="SUCCESS">
        <span
          dangerouslySetInnerHTML={{
            __html: translations.t('store.cart.success'),
          }}
        />
      </Message>
    )}
    {confirmationStatus === PAY_STATUSES.error && (
      <Message type="ERROR">
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: translations.t('store.cart.error'),
            }}
          />
          <Link fontSize="24px" href="mailto: support@planoplan.com">
            support@planoplan.com
          </Link>
        </>
      </Message>
    )}
  </View>
}

const View = styled.div`
  @media ${tablet_landscape} {
    ${Grid.row()};
  }
`;
