import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.SAVE_PDF]: {
    name: 'GallerySavePDF',
    value: {},
    type: 'object',
    alias: 'Gallery_Save_PDF',
  },
};
