import React from 'react';

export const IconPending = ({ width = 140, height = 140, fill = '#2F2F2F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="69" fill="#FFF59B" stroke="#FFFDEB" strokeWidth="2"/>
      <path d="M70.0002 111.667C93.012 111.667 111.667 93.012 111.667 70.0002C111.667 46.9883 93.012 28.3335 70.0002 28.3335C46.9883 28.3335 28.3335 46.9883 28.3335 70.0002C28.3335 93.012 46.9883 111.667 70.0002 111.667Z" stroke="#2F2F2F" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M70 45V70L86.6667 78.3333" stroke="#2F2F2F" strokeWidth="4" strokeLinecap="square" strokeLinejoin="round"/>
    </svg>
  );
};

