import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { getZIndex } from '@libs';
import { MODAL_ROOT, MODAL_TIMEOUT } from '@globalConstants';

export const Modal = ({ isOpen, onClose, onAfterOpen, label, zIndex, children, styles }) => {
  const customStyles = {
    content: {
      top: '0',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      border: 'none',
      background: 'transparent',
      padding: '0',
      overflow: 'initial',
    },
    overlay: {
      backgroundColor: 'none',
      zIndex: zIndex && zIndex !== 1 ? zIndex : getZIndex('modal'),
    },
  };

  ReactModal.setAppElement(MODAL_ROOT);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={onAfterOpen}
      style={customStyles}
      contentLabel={label}
      closeTimeoutMS={MODAL_TIMEOUT}>
      <Content styles={styles}>
        <Close onClick={onClose}>
          <IClose width="38px" height="38px" fill={Colors.white} />
        </Close>

        {children}
      </Content>
    </ReactModal>
  );
};

const Content = styled.div`
  width: 420px;
  background-color: ${Colors.white};
  border-radius: 10px;
  position: relative;
  padding: 38px 38px 40px 38px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${Colors.coal};
  font-size: 16px;
  line-height: 19px;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  
  ${({styles}) => ({...styles})};
`;

const Close = styled.button`
  ${Mixins.resetButton()};
  position: absolute;
  top: 0;
  right: -60px;

  svg {
    ${Mixins.transition('transform')};
  }

  &:hover {
    svg {
      transform: scale(0.9);
    }
  }
`;
