import React, { useReducer, createContext } from 'react';

export const StoreContext = createContext();

export const Store = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (window.invokeAPI) {
    window.invokeAPI.registerDispatch('STORE', dispatch, state);
  }

  return <StoreContext.Provider value={[state, dispatch]}>{children}</StoreContext.Provider>;
};
