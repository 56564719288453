import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { ON_OPEN_STORE } from '@observer/constants';
import { translations } from '@libs';
import { CardItem } from './card-item';
import { rail_y, thumb_y } from "@features/quick-store/modules/scroll";

const observer = new window.POPObserver();

export const CardsAction = ({ open = true, setOpen = () => {} }) => {
  const { cards } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const isEmpty = !Boolean(cards.length);

  const onClose = () => setOpen(false);
  const openStoreBindingCard = () => {
    observer.postMessage(ON_OPEN_STORE, {
      initialScreen: 'binding',
    });
  };

  if (!open) {
    return <></>;
  }

  return (
    <View>
      <Overlay />
      <Container>
        <Content>
          <Close onClick={onClose}>
            <IClose width="38px" height="38px" fill={Colors.white} />
          </Close>

          {loading && <Loading><Preloader width="80px" height="75px" fill={Colors.planoplan_blue} /></Loading>}

          <Wrap>
            <Top>
              <Title>{translations.t('cabinet.subscribe.card_popup.title')}</Title>
            </Top>

            {isEmpty && <EmptyMessage>{translations.t('cabinet.subscribe.card_popup.empty')}</EmptyMessage>}
            {!isEmpty && (
              <CardsScroll>
                <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
                  <Cards>
                    {cards.map(card => (
                      <CardItem key={card.id} card={card} setLoading={setLoading} />
                    ))}
                  </Cards>
                </ScrollBar>
              </CardsScroll>
              )}
          </Wrap>

          <Bottom>
            <Button theme="primary" size="M" onClick={openStoreBindingCard}>{translations.t('cabinet.subscribe.card_popup.add')}</Button>
          </Bottom>
        </Content>
      </Container>
    </View>
  );
};

const View = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Overlay = styled.div`
  background-color: ${rgba(Colors.black, 0.4)};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  background-color: ${Colors.white};
  padding: 38px;
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  min-height: 590px;
  position: relative;
`;
const Close = styled.button`
  ${Mixins.resetButton()};
  position: absolute;
  top: 0;
  right: -60px;

  svg {
    ${Mixins.transition('transform')};
  }

  &:hover {
    svg {
      transform: scale(0.9);
    }
  }
`;
const Wrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${rgba(Colors.white, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Top = styled.div``;
const EmptyMessage = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.feldgrau50};
`;
const CardsScroll = styled.div`
  height: 463px;
  overflow: hidden;
  margin-right: -10px;
`;
const Cards = styled.div`
  padding-top: 1px;
  padding-right: 10px;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;
const Title = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.coal};
`;
