import React from 'react';

export const IconInfo = ({ width = 10, height = 10, fill = '#4A4A4A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M5 4.54545C5.25104 4.54545 5.45455 4.74896 5.45455 5V6.81818C5.45455 7.06922 5.25104 7.27273 5 7.27273C4.74896 7.27273 4.54545 7.06922 4.54545 6.81818V5C4.54545 4.74896 4.74896 4.54545 5 4.54545Z" />
      <path d="M5 2.72727C4.74896 2.72727 4.54545 2.93078 4.54545 3.18182C4.54545 3.43286 4.74896 3.63636 5 3.63636H5.00455C5.25558 3.63636 5.45909 3.43286 5.45909 3.18182C5.45909 2.93078 5.25558 2.72727 5.00455 2.72727H5Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5ZM5 0.909091C2.74065 0.909091 0.909091 2.74065 0.909091 5C0.909091 7.25935 2.74065 9.09091 5 9.09091C7.25935 9.09091 9.09091 7.25935 9.09091 5C9.09091 2.74065 7.25935 0.909091 5 0.909091Z" />
    </svg>
  )
};
