import React from 'react';
import ReactDOM from 'react-dom';
import { POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { App } from './App';
import ErrorBoundary from './ErrorBoundary';
import { Provider } from 'react-redux';
import { initialState } from './modules/Store/state';
import { ApiCall } from './modules/api-call';
import { TARIFF_ALIAS } from './constants';
import { createAppStore } from './modules/Store/create-store';
import { mappedButtons } from '@features/store/modules/invoke';
import { BUTTONS } from '@features/store/constants';

export default class UIQuickStore {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @param {object} params - объект параметров
   * @public
   */
  emit({ settings, params }) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения магазина не указан html элемент');
    this.unity_clientid = settings.unity_clientid || '';

    this.goods = params.goods;
    this.tariffAlias = params.tariffAlias;
    this.bannerAlias = params.bannerAlias;
    this.team_id = params.team_id || '';
    this.locale = this.settings.locale || 'en';
    this.zoom = this.settings.zoom || 1;
    this.project_id = params.project_id;
    this.promocode = params.promocode;
    this.openProject = params.openProject;

    ApiCall.setSettings({ locale: this.locale, team_id: this.team_id });

    if (!this._checkAvailableTariffAlias()) {
      return;
    }

    this._editorInvoke();
    this._setAnalytics();
    this._mount();
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      goodsId: this.goods,
      tariffAlias: this.tariffAlias,
      bannerAlias: this.bannerAlias || '',
      selected: {
        ...initialState.selected,
        promoCode: this.promocode || ''
      },
      settings: {
        project_id: this.project_id,
        openProject: this.openProject,
        team_id: this.team_id,
        onClose: this._onClose.bind(this),
        ...this.settings,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  /**
   * Устанавливает необходимые поля для аналитики.
   * @private
   */
  _setAnalytics() {

  }

  /**
   * Устанавливает необходимые поля для аналитики.
   * @private
   */
  _checkAvailableTariffAlias() {
    try {
      if (this.bannerAlias.indexOf('_to_') !== -1 && this.bannerAlias.indexOf('from_') !== -1) {
        const [i, fromTariff, y, toTariff] = this.bannerAlias.split('_');
        const indexTariff = [TARIFF_ALIAS.START, TARIFF_ALIAS.PRO, TARIFF_ALIAS.TRIAL_PROPLUS, TARIFF_ALIAS.PROPLUS, TARIFF_ALIAS.EDUCATION, TARIFF_ALIAS.PROFESSIONAL, TARIFF_ALIAS.BUSINESS];
        const normalFromTariff = fromTariff === TARIFF_ALIAS.TRIAL_PROPLUS ? TARIFF_ALIAS.PROPLUS : fromTariff;
        const fromIndex = indexTariff.indexOf(normalFromTariff);
        const toIndex = indexTariff.indexOf(toTariff);

        if (toTariff !== TARIFF_ALIAS.START) return;

        if (fromIndex === -1 || toIndex === -1) return true;

        return fromIndex > toIndex;
      }

      return true;
    } catch (error) {
      return true;
    }
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _onClose() {
    if (!window.cabinetIsOpen && !window.galleryIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.STORE_CLOSE].name,
      value: mappedButtons[BUTTONS.STORE_CLOSE].value,
    });

    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }
}

if (!window.UIQuickStore) {
  window.UIQuickStore = UIQuickStore;
}
