import { request } from '@libs';
import { createFormData, setGetParamsToUrl } from './helpers';

const getData = async (urls, params, method = 'post') => {
  try {
    const response = await request.call_auth(method, urls, params);
    const { data } = response;

    if (!Object.values(data).length) {
      throw new Error('server');
    }

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    if (!data.success && data.errorText) {
      throw new Error(data.errorText);
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',

  setSettings({ locale }) {
    this.locale = locale;
  },

  getProfile() {
    const urls = { local: './json/cabinet/profile.json', prod: `/users/profile/` };

    return getData(urls);
  },

  getGoods({ teamId }) {
    const urls = { local: teamId ? './json/cabinet/getgoods_team.json' : './json/cabinet/getgoods.json', prod: `/${this.locale}/users/getgoods/${teamId ? `?team_id=${teamId}` : ''}` };

    return request.call_auth('get', urls);
  },

  getTeams() {
    const urls = { local: './json/cabinet/teams.json', prod: `/teams/` };

    return getData(urls);
  },

  getTeamMembers({ teamId }) {
    const urls = { local: './json/cabinet/team_members.json', prod: `/teams/members/?team_id=${teamId}` };

    return getData(urls);
  },

  getUserSettings(params) {
    const urls = { local: './json/cabinet/getsettings.json', prod: setGetParamsToUrl(`/users/getsettings/?name[]=lastReadedNotis`, params) };

    return getData(urls);
  },

  getNotifications(params) {
    const urls = { local: './json/cabinet/events.json', prod: setGetParamsToUrl(`/${this.locale}/users/events/`, params) };

    return getData(urls);
  },

  getProjects(params) {
    const urls = { local: './json/cabinet/projects.json', prod: setGetParamsToUrl(`/projects/list/`, params) };

    return getData(urls);
  },

  setProjectName(params) {
    const urls = { local: './json/cabinet/save_name.json', prod: setGetParamsToUrl(`/projects/edit/`, params) };

    return getData(urls);
  },

  setFolderName(params) {
    const urls = { local: './json/cabinet/save_name.json', prod: setGetParamsToUrl(`/api/renamefolder/`, params) };

    return getData(urls);
  },

  onPin(params) {
    const urls = { local: './json/cabinet/pin.json', prod: setGetParamsToUrl(`/projects/pin/`, params) };

    return getData(urls);
  },

  onDelete(params) {
    const urls = { local: './json/cabinet/delete.json', prod: setGetParamsToUrl(`/projects/delete2/`, params) };

    return getData(urls);
  },

  onDuplicate(params) {
    const urls = { local: './json/cabinet/duplicate.json', prod: setGetParamsToUrl(`/projects/copy2/`, params) };

    return getData(urls);
  },

  onMove(params) {
    const urls = { local: './json/cabinet/move.json', prod: setGetParamsToUrl(`/projects/move2/`, params) };

    return getData(urls);
  },

  onCreateFolder(params) {
    const urls = { local: './json/cabinet/create-folder.json', prod: setGetParamsToUrl(`/api/createfolder/`, params) };

    return getData(urls);
  },

  onCreateProject(params) {
    const urls = { local: './json/cabinet/create-project.json', prod: '/projects/add/'};
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },

  getSubscriptions() {
    const urls = { local: './json/cabinet/notifications.json', prod: '/users/notifications/'};

    return getData(urls);
  },

  editSubscriptions(params) {
    const urls = { local: './json/cabinet/notifications.json', prod: params ? `/users/notificationsedit/?notifications[]=${params}` : `/users/notificationsedit/`};

    return getData(urls);
  },

  setPromo(params) {
    const urls = { local: '/json/cabinet/promo.json', prod: '/store/usepromo/'};
    const settings = { useFormData: true };

    return request.call_public('post', urls, params, settings);
  },

  deleteUser() {
    const urls = { local: './json/cabinet/delete-user.json', prod: '/users/delete?confirm=1'};

    return request.call_auth('post', urls);
  },

  editUser(data) {
    const urls = { local: './json/cabinet/default.json', prod: '/users/edit'};
    const settings = { useFormData: true };

    return request.call_public('post', urls, data, settings);
  },

  editTeam(data) {
    const urls = { local: './json/cabinet/default.json', prod: '/teams/edit'};
    const settings = { useFormData: true };

    return request.call_public('post', urls, data, settings);
  },

  setUserSettings(params, data) {
    const urls = { local: './json/cabinet/getsettings.json', prod: setGetParamsToUrl('/users/settings', params)};
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, data, settings);
  },

  socialVk(data) {
    const urls = { local: './json/cabinet/social.json', prod: `/${this.locale}/users/multiAuthVk`};
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, data, settings);
  },

  socialGoogle(data) {
    const urls = { local: './json/cabinet/social.json', prod: `/${this.locale}/users/multiAuthGoogle`};
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, data, settings);
  },

  getHistory(offset, count) {
    const urls = { local: './json/cabinet/history-payment.json', prod: `/users/historypayment/?offset=${offset}&count=${count}`};

    return getData(urls);
  },

  getCards() {
    const urls = { local: './json/cabinet/binding.json', prod: '/users/binding/'};

    return getData(urls);
  },

  setDefaultCard(id) {
    const urls = { local: './json/cabinet/add-card.json', prod: '/users/setdefaultbinding/'};
    const settings = { useFormData: true };

    return request.call_public('post', urls, id, settings);
  },

  removeCard(id) {
    const urls = { local: './json/cabinet/add-card.json', prod: '/users/unbinding/' };
    const settings = { useFormData: true };

    return request.call_public('post', urls, id, settings);
  },

  onChangePassword(params) {
    const urls = { local: './json/cabinet/change-password.json', prod: setGetParamsToUrl('/users/changepassword/', params) };
    const settings = { useFormData: true };

    return request.call_public('post', urls, params, settings);
  },

  onAddUserInTeam(params) {
    const urls = { local: './json/cabinet/add-user-in-team.json', prod: setGetParamsToUrl(`/teams/addmember/`, params) };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },

  onBlockUserInTeam(params) {
    const urls = { local: './json/cabinet/block-user-in-team.json', prod: setGetParamsToUrl(`/teams/blockmember/`, params) };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },

  onRemoveUserFromTeam(params) {
    const urls = { local: './json/cabinet/remove-user-from-team.json', prod: setGetParamsToUrl(`/teams/removemember/`, params) };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },

  onAutoPaymentCancel(teamId) {
    const urls = { local: './json/cabinet/autopaymentcancel.json', prod: `/${teamId ? 'teams' : 'users'}/autopaymentcancel/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, {}, settings);
  },

  getCancelSteps({ tariff = '' } = {}) {
    const urls = { local: './json/cabinet/content-cancel-steps.json', prod: `/${this.locale}/content/?blocks[]=subscription_cancel_${tariff}` };
    const settings = { useFormData: true };

    return request.call_public('get', urls, {}, settings);
  }
};
