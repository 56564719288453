import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { notifications } from '@features/notifications';
import * as getdata from './modules/get-data';
import * as effect from './modules/effects';
import { SCREENS } from './constants';
import { Close } from './components/Close';
import {
  MainScreen,
  StripeScreen,
  StripeRequireScreen,
  YookassaScreen,
  PaypalScreen,
  SuccessScreen,
  PendingScreen,
  ErrorScreen,
} from './screens';
import { Analytics } from './modules/analytics';
import * as actions from "@features/quick-store/modules/Store/action";

export const App = () => {
  const dispatch = useDispatch();
  const { loading, screen } = useSelector(state => state);
  const [loadedData, setLoadedData] = useState(false);

  useEffect(() => {
    setLoadedData(false);

    (async () => {
      await dispatch(getdata.getTranslations());
      await dispatch(getdata.getProfile());
      await dispatch(getdata.getGoods());
      await dispatch(getdata.getBindingCard());
      await dispatch(getdata.getPaymentMethods());
      await dispatch(getdata.getCountries());
      await dispatch(getdata.getBanners());

      dispatch(effect.setDefaultMethodPay());
      dispatch(effect.setDefaultCountry());
      dispatch(actions.setCurrentGoods());
      dispatch(Analytics.buyRender());
      dispatch(Analytics.addToCart());
      dispatch(Analytics.tariffSubscription());
      dispatch(Analytics.viewCart());

      setLoadedData(true);
    })();

    if (!window.cabinetIsOpen) {
      notifications.init('quick-store-notifications');
    }
  }, []);

  return (
    <View>
      <Container>
        <Close />

        {loading.error && <ContentError>{loading.error}</ContentError>}
        {!loadedData && <ContentLoader><Preloader width="80px" height="80px" fill={Colors.planoplan_blue} /></ContentLoader>}

        {loadedData && !loading.error && (
          <Content>
            {{
              [SCREENS.main]: <MainScreen />,
              [SCREENS.stripe]: <StripeScreen />,
              [SCREENS.stripeRequire]: <StripeRequireScreen />,
              [SCREENS.yookassa]: <YookassaScreen />,
              [SCREENS.paypal]: <PaypalScreen />,
              [SCREENS.success]: <SuccessScreen />,
              [SCREENS.pending]: <PendingScreen />,
              [SCREENS.error]: <ErrorScreen />,
            }[screen] }
          </Content>
        )}
      </Container>

      <div id="quick-store-notifications" />
    </View>
  );
};

const View = styled.div`
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 450;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.black, 0.4)};
  user-select: none;
`;
const Container = styled.div`
  position: relative;
`;
const Content = styled.div`
  display: flex;
  height: 100%;
`;
const ContentError = styled.div`
  width: 720px;
  height: 430px;
  background-color: ${Colors.dull_red};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px;
  color: ${Colors.white};
  font-size: 20px;
`;
const ContentLoader = styled.div`
  width: 720px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: ${Colors.night};
  font-size: 20px;
  background-color: ${Colors.night};
`;
