import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ButtonStore } from 'planoplan-ui-kit/core/buttonStore';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { YOUTUBE_VIDEO_1, YOUTUBE_VIDEO_2, MODE_STORE_OPEN } from '../../constants';
import { tablet_landscape } from '../../modules/breakpoints';
import { translations } from '@libs';
import * as getData from '../../modules/getdata';

export const NotRenders = ({ cart_count, onOpenStore }) => {
  const dispatch = useDispatch();
  const { settings: { team_id }} = useSelector((state) => state);

  const openYoutube = (link) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };

  useEffect(() => {
    dispatch(getData.getUserSettings());
    // eslint-disable-next-line
  }, []);

  return (
    <View>
      <Title>{translations.t('gallery.render.not')}</Title>
      <Video>
        <Make>{translations.t('gallery.render.make')}</Make>
        <IframePress onClick={() => openYoutube(YOUTUBE_VIDEO_1)}><Iframe  title={translations.t('gallery.render.make')} src={YOUTUBE_VIDEO_1} frameBorder="0" allowFullScreen /></IframePress>
        <IframePress onClick={() => openYoutube(YOUTUBE_VIDEO_2)}><Iframe  title={translations.t('gallery.render.make')} src={YOUTUBE_VIDEO_2} frameBorder="0" allowFullScreen /></IframePress>
      </Video>

      {!team_id && (
        <Store>
          <BuyText>{translations.t('gallery.render.buy')}</BuyText>
          <ButtonStore
            name={translations.t('gallery.render.store')}
            onClickName={() => onOpenStore(MODE_STORE_OPEN.RENDERS)}
            onClickCart={() => onOpenStore(MODE_STORE_OPEN.CART)}
            count={cart_count}
          />
        </Store>
      )}
    </View>
  );
};

const View = styled.div`
  margin: 38px;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.whisper};
  font-weight: 300;
`;

const Title = styled.p`
  font-size: 24px;
  line-height: 34px;
  color: ${Colors.whisper};
  font-weight: bold;
`;

const Video = styled.div`
  margin-top: 20px;
`;
const IframePress = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 260px;
  margin-bottom: 20px;

  @media ${tablet_landscape} {
    width: 48%;
    max-width: 460px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const Make = styled.p`
  margin-bottom: 10px;
`;

const Store = styled.div`
  margin-top: 28px;

  @media ${tablet_landscape} {
    display: flex;
    align-items: center;

    span {
      margin-right: 30px;
    }
  }
`;

const BuyText = styled.p`
  margin-bottom: 20px;

  @media ${tablet_landscape} {
    margin-bottom: 0;
    margin-right: 30px;
  }
`;
