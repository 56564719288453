import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from '../../../icons';

export const Close = ({ onClose = () => {} }) => {
  return (
    <View onClick={onClose}>
      <IClose />
    </View>
  )
};

const View = styled.div`
  display: block;
  cursor: pointer;
  padding: 8px;
  background-color: ${Colors.night};
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid ${Colors.coal};

  svg {
    ${Mixins.transition('transform')};
    transition-duration: 150ms;
    display: block;
  }

  &:hover svg {
    transform: scale(0.9);
  }
`;
