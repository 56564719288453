import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { StoreContext } from '@features/store/modules/Store';
import * as effects from '../../modules/effects';
import { PlayButton } from '../PlayButton';

export const VideoPopup = () => {
  const [state, dispatch] = useContext(StoreContext);
  const src = state.srcVideoPopup;

  const onClose = () => {
    effects.setSrcVideoPopup(dispatch, null);
  };

  if (!src) {
    return null;
  }
  const openYoutube = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: src,
    });
  };

  return (
    <View>
      <PlayButton text={translations.t('store.common.back')} icon='back' onClick={onClose}/>
      <PlayerPress onClick={openYoutube}>
        <Player src={`${src}?modestbranding=1&rel=0&autoplay=1`}
              frameBorder="0"
              //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              /*allowFullScreen*/ />
      </PlayerPress>
    </View>
  )
};

const View = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.night};
  padding: 48px 20px;
  z-index: 10;
  border-radius: 10px;
  display: flex;
`;
const PlayerPress = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  height: 100%;
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
const Player = styled.iframe`
  flex-grow: 1;
  background-color: #fff;
  height: 100%;
  width: 100%;
  border: none;
`;
