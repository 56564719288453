import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector } from 'react-redux';
import { Canvas } from './Canvas';

const canvas = new Canvas();

export const Render = ({ compare }) => {
  const canvasRef = useRef(null);
  const { adjustment, image, settings: { webgl }} = useSelector(state => state);

  const getAdjustments = (adjustment) => {
    return adjustment.reduce((prev, section) => {
      return Array.isArray(prev) ? [...prev, ...section.list] : [...prev.list, ...section.list];
    });
  };

  useEffect(() => {
    canvas.applyAdjustment(getAdjustments(adjustment));
  }, [adjustment]);

  useEffect(() => {
    canvas.compare(compare);
  }, [compare]);

  useEffect(() => {
    (async () => {
      await canvas.init({
        el: canvasRef.current,
        src: image.src,
        adjustments: getAdjustments(adjustment),
        webgl
      });
    })();
  }, []);

  return (
    <View>
      <Content ref={canvasRef} />
    </View>
  )
};

const View = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const Content = styled.canvas``;

