import { translations } from '@libs';

export const getErrorPromoMessage = (key) => {
  const errorPromoMessages = {
    'Invalid promocode': translations.t('cabinet.notification.promo.invalid'),
    'This promocode already used': translations.t('cabinet.notification.promo.used'),
    'This promocode is expired': translations.t('cabinet.notification.promo.expired'),
    'This promocode not supported': translations.t('cabinet.notification.promo.notsupported'),
    'This promocode will be active later': translations.t('cabinet.notification.promo.activelater'),
    'Nothing to apply': translations.t('cabinet.notification.promo.nothingapply'),
  };

  if (!errorPromoMessages[key]) {
    return translations.t('cabinet.notification.promo.error');
  }

  return errorPromoMessages[key];
};
