// Actions
export const SET_IMAGE = 'SET_IMAGE';
export const SET_CAPTION = 'SET_CAPTION';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_SLIDES = 'SET_SLIDES';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_CONTENT = 'GET_CONTENT';

// Invoke actions
export const BUTTONS = {
  ENTRY_SCREEN_CLOSE: 'ENTRY_SCREEN_CLOSE',
};

export const SCREEN_ENTRY_INSTALLING_PERCENT = 'SCREEN_ENTRY_INSTALLING_PERCENT';
export const SCREEN_ENTRY_INSTALLING_ERROR = 'SCREEN_ENTRY_INSTALLING_ERROR';
export const SCREEN_ENTRY_INSTALLING_NOT_REQUIRED = 'SCREEN_ENTRY_INSTALLING_NOT_REQUIRED';
export const SCREEN_ENTRY_CLOSE = 'SCREEN_ENTRY_CLOSE';

export const TRANSLATIONS_PATTERNS = [
  /^users./,
  /^cabinet./,
  /^modal./,
  /^form_entry./,
  /^store./,
  /^quickstore./,
  /^pricing.shortcontent./,
  /^gallery./,
  /^user_widget./,
  /^modal./,
  /^projects./,
  /^image_editor./,
  /^banners./,
  /^country./,
  /^assistant./,
  /^tour_editor./,
];
