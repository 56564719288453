import { tablet_landscape } from './breakpoints';

const template = (prop, level = 1) => {
  return `
    ${prop}: ${12 * level}px;
    
    @media ${tablet_landscape} {
      ${prop}: ${24 * level}px;
    }`;
};

export const Indent = {
  margin_top: (level) => {
    return template('margin-top', level);
  },

  margin_bottom: (level) => {
    return template('margin-bottom', level);
  },

  padding_top: (level) => {
    return template('padding-top', level);
  },

  padding_bottom: (level) => {
    return template('padding-bottom', level);
  },
};
