import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import * as effects from '../effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconClose } from '../../../components/icons';
import { ROW_TYPE } from '../constants';
import { TARIFF_ALIAS } from '../../../constants';

export const Select = ({
  isOpen,
  onOpen = () => {},
  onClose = () => {},
  onDelete = () => {},
  onDuplicate = () => {},
  moveToTeam = () => {},
  moveToFolder = () => {},
}) => {
  const dispatch = useDispatch();
  const { team, tariff, projects: { items }} = useSelector(state => state);
  const teams = team.list;
  // Выделить все или Очистить все
  const [selectAll, setSelectAll] = useState(true);
  // Все выделенные проекты и папки
  const [selectedEIds, setSelectedEIds] = useState([]);
  const disabledButton = !selectedEIds.length;

  const onSelectAll = () => {
    dispatch(effects.onAllSelected(selectAll));
    setSelectAll(!selectAll);
  };
  const onCloseAndReset = () => {
    dispatch(effects.onAllSelected(false));
    setSelectAll(true);
    onClose();
  };
  const filterAndNormalizeSelected = (items) => {
    return items.filter((item) => item.selected).map((item) => {
      const prefix = item.row_type === ROW_TYPE.project ? 'p' : 'f';

      return `${prefix}_${item.id}`;
    });
  };

  useEffect(() => {
    setSelectedEIds(filterAndNormalizeSelected(items));
  }, [items]);

  return (
    <View>
      {!isOpen && <Button onClick={onOpen}>{translations.t('cabinet.projects.select')}</Button>}

      {isOpen &&
        <SelectActions>
          <ActionsWrap>
            <Button disabled={disabledButton} onClick={() => moveToFolder({ ids: selectedEIds })}>{translations.t('cabinet.projects.moveToFolder')}</Button>
            {(teams && Boolean(teams.length)) &&
              <Button disabled={disabledButton} onClick={() => moveToTeam({ ids: selectedEIds })}>{translations.t('cabinet.projects.moveToTeam')}</Button>
            }
            <Button disabled={disabledButton || tariff.alias === TARIFF_ALIAS.VIEW_ONLY} onClick={() => onDuplicate({ ids: selectedEIds })}>{translations.t('cabinet.projects.duplicate')}</Button>
            <Button disabled={disabledButton} onClick={() => onDelete({ ids: selectedEIds })}>{translations.t('cabinet.projects.delete')}</Button>
          </ActionsWrap>
          <ActionsWrap>
            <Button onClick={onSelectAll}>{selectAll ? translations.t('cabinet.projects.selectAll') : translations.t('cabinet.projects.clearAll')}</Button>
            <ButtonClose onClick={onCloseAndReset}><IconClose /></ButtonClose>
          </ActionsWrap>
        </SelectActions>
      }
    </View>
  )
};

const View = styled.div`
`;
const SelectActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ActionsWrap = styled.div`
  display: flex;
`;
const Button = styled.div`
  ${Mixins.transition('color, background-color, border-color')};
  height: 40px;
  padding: 0 14px;
  border-radius: 5px;
  border: 1px solid ${Colors.light_gray};
  font-size: 16px;
  color: ${Colors.feldgrau};
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: ${Colors.planoplan_blue};
    border-color: ${Colors.planoplan_blue};
    color: ${Colors.white};
  }
  
  &:not(:last-child) {
    margin-right: 10px;
  }
  
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.4;
  `}
`;
const ButtonClose = styled(Button)`
  border-radius: 50%;
  width: 40px;
  justify-content: center;
  padding: 0;
  
  svg {
    ${Mixins.transition('fill')};
  }
  
  &:hover {
    svg {
      fill: ${Colors.white};
    } 
  }
`;
