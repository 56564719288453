import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Skeleton = () => (
  <View>
    <Title />
    <Field>
      <Name />
      <Input />
    </Field>
    <Field>
      <Name />
      <Input />
    </Field>
    <Submit />
    <SocialLine>
      <SocialTitle />
    </SocialLine>
    <SocialGroup>
      <SocialItem />
      <SocialItem />
      <SocialItem />
    </SocialGroup>
    <Footer>
      <Forgot />
      <Recaptha />
      <Agreement />
    </Footer>
  </View>
);

const getAnimation = (start) => {
  return keyframes`
    0% {
      transform: translateX(${-100 + start}px);
    }
    
    100% {
      transform: translateX(100%);
    }
  }`;
};

const getStyle = (start, br = 8) => {
  return css`
    border-radius: ${br}px;
    background-color: ${Colors.white_smoke};
    overflow: hidden;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: ${345 + start}px;
      top: 0;
      background-image: linear-gradient(
        90deg,
        ${Colors.white_smoke} 0px,
        ${Colors.whisper} 40px,
        ${Colors.white_smoke} 80px
      );
      animation: ${getAnimation(start)} 1.6s linear infinite;
    }
  `;
};

const View = styled.div``;

const Title = styled.div`
  ${getStyle(0)};
  width: 140px;
  height: 34px;
}
`;

const Field = styled.div`
  margin-top: 19px;

  &:nth-child(2) {
    margin-top: 24px;
  }
`;

const Name = styled.div`
  ${getStyle(0)};
  width: 90px;
  height: 20px;
`;

const Input = styled.div`
  ${getStyle(0)};
  width: 100%;
  height: 48px;
  margin-top: 3px;
`;

const Submit = styled.div`
  ${getStyle(0)};
  width: 100%;
  height: 48px;
  margin-top: 47px;
`;

const SocialLine = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${Colors.solitude};
  }

  &:before {
    margin-right: 16px;
  }

  &:after {
    margin-left: 16px;
  }
`;

const SocialTitle = styled.div`
  ${getStyle(-86)}; // getBoundingClientRect - paddingLeft
  width: 172px;
  height: 20px;
  flex-shrink: 0;
`;

const SocialGroup = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: center;
`;

const SocialItem = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 42px;

  &:nth-child(1) {
    ${getStyle(-67, 48)};
  }

  &:nth-child(2) {
    ${getStyle(-148, 48)};
  }

  &:nth-child(3) {
    ${getStyle(-230, 48)};
    margin-right: 0;
  }
`;

const Footer = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Forgot = styled.div`
  ${getStyle(-119)};
  width: 106px;
  height: 20px;
`;

const Recaptha = styled.div`
  ${getStyle(-51)};
  margin-top: 15px;
  width: 242px;
  height: 20px;
`;

const Agreement = styled.div`
  ${getStyle(-33)};
  margin-top: 3px;
  width: 278px;
  height: 20px;
`;
