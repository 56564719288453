import React, { useContext, useEffect } from 'react';
import { Products } from '../../components/Products';
import { Analytics } from '../../modules/analytics';
import { StoreContext } from '../../modules/Store';

export const Packages = ({ products, descriptionStore }) => {
  const packages = products.filter((item) => item.view_alias === 'package');
  const content = descriptionStore['packages'];
  const [state] = useContext(StoreContext);
  const { settings: { mode, locale }} = state;

  useEffect(() => {
    Analytics.viewPackages(mode, locale);
  }, []);

  return <Products elements={packages} content={content} />;
};
