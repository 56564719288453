import { DIRECTIONS } from '../constants';

export const setGetParamsToUrl = (url = '', params = {}) => {
  const urlGet = new URLSearchParams();
  const separator = url.indexOf('?') !== -1 ? '&' : '?';

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (typeof params[key] === 'string' || typeof params[key] === 'number') {
        urlGet.append(key, params[key]);
      }

      if (Array.isArray(params[key])) {
        params[key].forEach((item) => {
          urlGet.append(`${key}[]`, item);
        });
      }
    }
  }

  return `${url}${separator}${urlGet.toString()}`;
};

// Возращает направление где элемент будет виден
export const getDirectionElement = (el, zoom) => {
  const { width, height, top, left } = el.getBoundingClientRect();
  let x = window.innerWidth / zoom < left + width ? 'left' : 'right';
  let y = window.innerHeight / zoom < top + height ? 'Top' : 'Bottom';

  return DIRECTIONS[`${x}${y}`];
};

export function createFormData(data) {
  const formData = new FormData();

  for (let i in data) {
    if (data.hasOwnProperty(i) && data[i]) {
      if (typeof data[i] === 'object' && data[i].length > 1) {
        formData.append(i, data[i][0], data[i][1]);
      } else {
        formData.append(i, data[i]);
      }
    }
  }

  return formData;
}

export const filterNotification = (notifications) => {
  return notifications.filter((notify) => {
    if (notify.data.tariff) {
      if (!notify.data.tariff.expire) {
        return false;
      }

      const dataTime = new Date(notify.data.tariff.expire).getTime();
      const timeView = 3 * 24 * 60 * 60 * 1000; // 3 дня в секундах. За сколько сек будет выводиться уведомление

      if (dataTime < Date.now()) {
        return false;
      }

      return dataTime - timeView < Date.now();
    }

    return true;
  });
};

/**
 * Выбирает слова.
 * @param {number} n - количество
 * @param {array} words - массив слов. Например показать еще ['квартиру', 'квартиры', 'квартир']
 * @return {string} - слово в правильном склонении.
 */
export const pluralWord = (n, words) => {
  const $i = n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2;

  return words[$i];
};
