import { throttle } from 'throttle-debounce';
import { translations } from '@libs';
import { jsPDF } from 'jspdf';
import { notifications } from '@features/notifications';
import { mappedButtons } from './invoke';
import { replaceVariables } from './helpers';
import titleLogoRu from '../assets/images/title-logo-ru.png';
import titleLogoEn from '../assets/images/title-logo-en.png';
import pageLogoRu from '../assets/images/page-logo-ru.png';
import pageLogoEn from '../assets/images/page-logo-en.png';
import '../assets/fonts/montserrat-bold.js';
import '../assets/fonts/museoSansCyrl-300.js';
import '../assets/fonts/museoSansCyrl-500.js';
import { ApiCall } from './api-call';
import { Analytics } from '../modules/analytics';
import {
  BUTTONS,
  SET_DESIGN_PROJECT,
  SET_DESIGN_PROJECT_DESC,
  SET_DESIGN_PROJECT_NAME,
  SET_DESIGN_PROJECT_LOADING,
  SET_DESIGN_PROJECT_IMAGES,
  SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME,
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_DESIGN_PROJECT_LOADING_PDF,
  SET_HAS_PDF,
} from '../constants';

const saveProjectWithThrottle = throttle(3000, async (projectId, folderId, params) => {
  const data = { pdfdata: JSON.stringify(params) };

  try {
    const response = await ApiCall.setPdfData({ projectId, folderId, params: data });

    if (!response.data) {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  }
});

export const openDesignProject = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_DESIGN_PROJECT_LOADING } });

  const getFilteredImages = (images, projects) => {
    if (projects && projects.length) {
      if (projects.length === 1) {
        return projects[0].images;
      }

      // когда длина массива = 1 то цикл не срабатывает, и просто возращает currentVal
      return projects.reduce((itemVal, currentVal) => [...(itemVal.images ? itemVal.images : itemVal), ...currentVal.images]);
    }

    return onFiltered(images);
  };
  const onFiltered = (images) => {
    return images.filter(item => {
      return item.editor['task_status'] === 2 && item.preview && item.original
    });
  };

  const { images, projects, settings } = getState();
  const { projectId, folderId } = settings;
  const params = folderId ? { folder_id: folderId } : { id: projectId };
  const filteredImages = getFilteredImages(images, projects);

  try {
    const response = await ApiCall.getPdfData({ params });

    if (!response.data.success) {
      throw new Error();
    }

    const { pdf_data: data, uploaded_images } = response.data.data;
    const serverImages = data ? data.images : []; // Сохраненные рендеры в дизайн проекте
    const formattedImages = []; // рендеры выводимые в DragAndDrop'e
    const uploadedImages = [];

    uploaded_images.forEach(project => {
      const items = project.items.map(item => ({ ...item, projectId: project.projectId }));

      uploadedImages.push(...items);
    });

    for (let i = 0; i < serverImages.length; i++) {
      for (let j = 0; j < filteredImages.length; j++) {
        if (Number(serverImages[i].id) === Number(filteredImages[j].id)) {
          formattedImages.push({
            id: serverImages[i].id,
            order: i + 1,
            original: filteredImages[j].original,
            preview: filteredImages[j].preview,
            name: serverImages[i].name,
            size: serverImages[i].size,
            projectId: filteredImages[j].projectId,
            hideFrame: serverImages[i].hideFrame
          });
        }
      }

      for (let j = 0; j < uploadedImages.length; j++) {
        if (Number(serverImages[i].id) === Number(uploadedImages[j].id)) {
          formattedImages.push({
            id: serverImages[i].id,
            order: i + 1,
            original: uploadedImages[j].original,
            preview: uploadedImages[j].preview,
            name: serverImages[i].name || '',
            size: serverImages[i].size,
            projectId: uploadedImages[j].projectId,
            isUpload: true,
            hideFrame: serverImages[i].hideFrame
          });

          uploadedImages.splice(j, 1);
        }
      }
    }

    const formattedImagesIds = formattedImages.map((image) => image.id);
    const sidebar_images = filteredImages.filter((image) => formattedImagesIds.indexOf(image.id)  === -1);
    const filteredProjects = projects.filter(project => project.images.length).map((project) => {
      const projectImage = onFiltered(project.images);

      return {
        ...project,
        images: projectImage.filter(image => formattedImagesIds.indexOf(image.id)  === -1),
      };
    });

    dispatch({ type: SET_DESIGN_PROJECT, payload: {
      name: data ? data.name : '',
      description: data ? data.description : '',
      images: formattedImages,
      sidebar_images: folderId ? [] : sidebar_images,
      projects: folderId ? filteredProjects : [],
      uploadedImages,
      additional: data && data.additional ? data.additional : {
        displayTitle: true,
        displayList: true
      }
    }});
  } catch (error) {
    console.error(error);
  }

  dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DESIGN_PROJECT_LOADING } });
};

export const createDesignProject = () => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: SET_DESIGN_PROJECT_LOADING } });

  const { project_name, images, settings: { projectId }} = getState();
  const filteredImages = images.filter(item => item.editor['task_status'] === 2 && item.preview && item.original);
  const formattedImages = [];

  const getDataFromPdf = async () => {
    try {
      const { settings: { projectId, folderId }} = getState();
      const params = folderId ? { folder_id: folderId } : { id: projectId };
      const response = await ApiCall.getPdfData({ params });

      if (!response.data.success) {
        throw new Error(response.data.errorText);
      }

      return response.data.data;
    } catch (error) {
      console.error(error);

      return {}
    }
  };

  const formattedImagesIds = formattedImages.map((image) => image.id);
  const sidebar_images = filteredImages.filter((image) => formattedImagesIds.indexOf(image.id)  === -1);
  const { uploaded_images } = await getDataFromPdf();
  const uploadedImages = [];

  uploaded_images.forEach(project => {
    const items = project.items.map(item => ({ ...item, projectId: project.projectId }));

    uploadedImages.push(...items);
  });

  dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DESIGN_PROJECT_LOADING } });
  dispatch({ type: SET_DESIGN_PROJECT, payload: {
      name: project_name,
      description: project_name,
      sidebar_images,
      uploadedImages,
    }});
  dispatch(saveDesignProject({
    name: project_name,
    description: project_name,
    images: formattedImages,
    sidebar_images,
  }));
  Analytics.createDesignProject(projectId);
  dispatch({ type: SET_HAS_PDF, payload: true });
};

export const saveDesignProject = ({ name, description, images }) => async (dispatch, getState) => {
  const { settings } = getState();
  const { projectId, folderId } = settings;
  const params = {
    name,
    description,
    images: images.map(item => ({
      id: item.id,
      name: item.name,
      size: item.size,
    }))
  };

  saveProjectWithThrottle(projectId, folderId, params);
  dispatch({ type: SET_DESIGN_PROJECT_IMAGES, payload: images});
};

export const updateDesignProjectImages = (images) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    images: images,
    additional: design_project.additional,
  });
  dispatch({ type: SET_DESIGN_PROJECT_IMAGES, payload: images});
};

export const updateDesignProjectImageName = ({ id, name }) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    additional: design_project.additional,
    images: design_project.images.map(item => {
      if (item.id === id) {
        return {
          ...item,
          name: name
        }
      } else {
        return item
      }
    }),
  });
  dispatch({type: SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME, payload: { id, name }})
};

export const updateDesignProjectName = (name) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;

  saveProjectWithThrottle(projectId, folderId, {
    name: name,
    description: design_project.description,
    additional: design_project.additional,
    images: design_project.images,
  });
  dispatch({type: SET_DESIGN_PROJECT_NAME, payload: name});
};

export const updateDesignProjectDescription = (description) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: description,
    additional: design_project.additional,
    images: design_project.images,
  });
  dispatch({type: SET_DESIGN_PROJECT_DESC, payload: description})
};

export const addDesignProjectImage = (item) => async (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;
  let { images, sidebar_images, projects, uploadedImages } = design_project;
  const sortedImages = [];

  images.push(item);

  for (let i = 0; i < images.length; i++) {
    sortedImages.push({
      ...images[i],
      order: i + 1
    })
  }

  if (projects && projects.length) {
    for (let i = 0; i < projects.length; i++) { // цикл по проектам
      if (item.projectId === projects[i].id) {
        for (let j = 0; j < projects[i].images.length; j++) { // цикл по изображениям
          for (let k = 0; k < images.length; k++) { // цикл по выбранных изображениям
            if (projects[i].images[j] && projects[i].images[j].id === images[k].id) {
              projects[i].images.splice(j, 1);
            }
          }
        }
      }
    }
  }

  for (let i = 0; i < sidebar_images.length; i++) {
    for (let j = 0; j < images.length; j++) {
      if (sidebar_images[i] && sidebar_images[i].id === images[j].id) {
        sidebar_images.splice(i, 1);
      }
    }
  }

  if (Array.isArray(uploadedImages)) {
    for (let i = 0; i < uploadedImages.length; i++) { // Цикл по загруженным изображениям
      const uploadImg = uploadedImages[i];

      for (let j = 0; j < images.length; j++) {
        if (uploadImg.id === images[j].id) {
          uploadedImages.splice(i, 1);
        }
      }
    }
  }

  dispatch({ type: SET_DESIGN_PROJECT, payload: {
      images: sortedImages,
      sidebar_images: sidebar_images,
      projects: projects,
      uploadedImages
    }});

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    additional: design_project.additional,
    images: images
  });
};

export const deleteDesignProjectImage = ({ id }) => async (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;
  let { images, sidebar_images, projects, uploadedImages } = design_project;
  const deletedItem = images.find((item) => id === item.id);

  if (deletedItem?.isUpload) {
    uploadedImages.unshift(deletedItem);
  } else {
    projects.forEach(project => {
      if (project && project.id === deletedItem.projectId) {
        project.images.unshift(deletedItem);
      }
    });
    sidebar_images.unshift(deletedItem);
  }

  images = images.filter((item) => item.id !== deletedItem.id);

  dispatch({ type: SET_DESIGN_PROJECT, payload: {
      images: images,
      sidebar_images,
      uploadedImages,
    }});

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    additional: design_project.additional,
    images: images
  });
};

export const setPdfFooterOnImage = ({ id }) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;
  const { images } = design_project;

  const editImages = images.map(image => {
    if (image.id === id) {
      return {
        ...image,
        hideFrame: image.hideFrame ? !Boolean(image.hideFrame) : true,
      }
    }

    return image;
  });

  dispatch({ type: SET_DESIGN_PROJECT, payload: {
      images: editImages
    }});

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    additional: design_project.additional,
    images: editImages
  });
};

export const deleteUploadImage = (item) => async (dispatch, getState) => {
  const getProjectId = () => {
    try {
      const { design_project: { projects }, settings: { projectId }} = getState();

      if (projectId) {
        return projectId;
      }

      return projects[0].id;
    } catch (error) {
      return 0;
    }
  };

  try {
    const projectId = getProjectId();
    const params = { id: item.projectId || projectId, images: [{ 'sid[]': item.id }] };

    const response = await ApiCall.removeImage({ params });
    const { data } = response;

    if (!data.success) {
      throw new Error(data.errorText);
    }

    const { design_project: { uploadedImages }} = getState();
    const filterUploadedImages = uploadedImages.filter((image) => image.id !== item.id);

    dispatch({ type: SET_DESIGN_PROJECT, payload: {
        uploadedImages: filterUploadedImages
      }});

    notifications.showSuccess(translations.t('gallery.upload.remove.success'));
  } catch (error) {
    console.error(error);
    notifications.showError(translations.t('gallery.upload.remove.error'));
  }
};

export const uploadImage = ({ files = null, url = '', countErrFiles = 0 }) => async (dispatch, getState) => {
  const getProjectId = () => {
    try {
      const { design_project: { projects }, settings: { projectId }} = getState();

      if (projectId) {
        return projectId;
      }

      return projects[0].id;
    } catch (error) {
      return 0;
    }
  };

  try {
    const { design_project: { uploadedImages }} = getState();

    const formData = new FormData();
    const projectId = getProjectId();

    formData.append('id', `${projectId}`);

    if (files) {
      for (let key in files) {
        if (Object.prototype.hasOwnProperty.call(files, key)) {
          formData.append('file[]', files[key]);
        }
      }
    }

    if (url) {
      formData.append('link[]', url);
    }

    const response = await ApiCall.uploadImages(formData);
    const { data } = response;

    if (!data.success) {
      throw new Error(data.errorText);
    }

    const total = data.data.length + countErrFiles;
    const successImages = data.data.filter((image) => Boolean(image.success)).map(image => image.data);
    const successCount = successImages.length;

    if (total === successCount) {
      notifications.showSuccess(translations.t('gallery.upload.success'));
    } else {
      const notify = translations.t('gallery.upload.success.path');

      notifications.showError(replaceVariables(notify, successCount, total));
    }

    dispatch({ type: SET_DESIGN_PROJECT, payload: {
      uploadedImages: [...uploadedImages, ...successImages]
    }});
  } catch (error) {
    console.error(error);
    notifications.showError(translations.t('gallery.upload.error'));
  }
};

export const updateDesignProjectAdditional = (additional) => (dispatch, getState) => {
  const { design_project, settings } = getState();
  const { projectId, folderId } = settings;

  saveProjectWithThrottle(projectId, folderId, {
    name: design_project.name,
    description: design_project.description,
    additional,
    images: design_project.images,
  });
  dispatch({type: SET_DESIGN_PROJECT, payload: { additional }});
};

export const exportPDF = () =>  async (dispatch, getState) => {
  const { design_project , settings, profile } = getState();
  const { name, description, images, additional } = design_project;
  const { projectId, folderId, locale } = settings;
  const pageWidth = 1754; // ширина страницы док-та
  const pageHeight = 1240; // высота страницы док-та
  const topOffset = 60;
  const verticalOffset = topOffset * 2;
  const rightOffset = 55;
  const leftOffset = 120;
  const horizontalOffset = rightOffset + leftOffset;
  const countListOfPage = 60; // Лучше не трогать! ломается верстка
  const filename = `${name ? name.replace(/[<>|:"\/\\?*]+/gui, '') : 'planoplan'}.pdf`;
  const frameWidth = pageWidth - horizontalOffset;  // максимальная ширина для изображения
  const frameHeight =  pageHeight - verticalOffset; // максимальная высота для изображения
  const logo = profile.logo ? profile.logo : locale === 'ru' ? pageLogoRu : pageLogoEn;
  const logoSize = { width: locale === 'ru' ? 260 : 239, height: 65 };
  const offsetCountPage = (additional.displayTitle && additional.displayList) ? 3 : (additional.displayTitle || additional.displayList) ? 2 : 1;

  dispatch({ type: SET_LOADING_ON, payload: { name: SET_DESIGN_PROJECT_LOADING_PDF, text: 'gallery.pdf.wait.loading' } });

  // генерация титульной страницы
  const doc = new jsPDF({
    format: [pageWidth, pageHeight],
    compress: true,
    orientation: 'l',
    unit: 'px'
  });
  // Возвращает загруженное изображение
  const getLoadImage = async (src) => {
    return new Promise(resolve => {
      const img = new Image();

      img.src = src;
      img.onload = () => {
        resolve(img);
      }
    })
  };
  // Подставляет размеры в путь до изображения
  const getCompressImageSrc = (src, orientation, width = frameWidth, height = frameHeight) => {
    if (orientation === 'width') {
      return src.replace('thumbx', `thumb${width}x`);
    }

    return src.replace('thumbx', `thumbx${height}`);
  };
  // Возвращает объект изображения с размерыми и с новым src
  const getImageWithSize = async (item) => {
    let { width, height } = item.size;
    let { original } = item;

    if (!width || !height) {
      const image = await getLoadImage(item.original);

      width = image.width;
      height = image.height;
    }

    if (width > frameWidth) {
      const rat = width / height;

      height = (height - (height - (frameWidth / rat))).toFixed();
      width = frameWidth;
      original = getCompressImageSrc(item.original, 'width');
    }

    if (height > frameHeight) {
      const rat = width / height;

      width = (width - (width - (frameHeight * rat))).toFixed();
      height = frameHeight;
      original = getCompressImageSrc(item.original, 'height');
    }

    return {
      ...item,
      original,
      size: { height, width }
    };
  };
  // Возращает соотношения по максимальным размерам
  const getCompressSize = (width, height, maxWidth, maxHeight) => {
    let widthImg = width;
    let heightImg = height;

    if (!maxWidth || !maxHeight) {
      return {
        width: widthImg,
        height: heightImg
      }
    }

    if (widthImg > maxWidth) {
      const rat = widthImg / heightImg;

      heightImg = (heightImg - (heightImg - (maxWidth / rat))).toFixed();
      widthImg = maxWidth;
    }

    if (heightImg > maxHeight) {
      const rat = widthImg / heightImg;

      widthImg = (widthImg - (widthImg - (maxHeight * rat))).toFixed();
      heightImg = maxHeight;
    }

    return {
      width: widthImg,
      height: heightImg
    };
  };
  // Возращает размеры изображения в соотношении по указанным размерам
  const getCustomImageSize = async (src, width = 0, height = 0) => {
    const image = await getLoadImage(src);

    return getCompressSize(image.width, image.height, width, height);
  };
  // Создание главной страницы
  const createMainPage = () => {
    doc.rect(100, 40, 1619, 1160);
    doc.setFont('Montserrat-Bold');
    doc.setFontSize(54);
    doc.text(name, 240, 300, { maxWidth: 820});
    doc.setFont('MuseoSansCyrl-300');
    doc.setFontSize(32);
    doc.text(description, 240, 484, { maxWidth: 700 });
    doc.setFontSize(21);
    doc.text(translations.t('gallery.pdf.created_in'), 358, 979, { align: 'right', lineHeightFactor: 0.88, maxWidth: 118 })
    doc.addImage(locale === 'ru' ? titleLogoRu : titleLogoEn, 368, 960, 160, 40);
  };
  // генерация страницы содеражния
  const createPagesList = () => {
    const newPageList = (start, end) => {
      if (additional.displayTitle) {
        // Если есть титульный лист то создаем новую страницу, если нету рисуем на текущей
        doc.addPage([pageWidth, pageHeight]);
      }

      doc.rect(100, 40, 1619, 1160);
      doc.setFont('Montserrat-Bold');
      doc.setFontSize(54);
      doc.text(translations.t('gallery.project.table.table_of_contents'), 240, 260);
      doc.setFont('MuseoSansCyrl-300');
      doc.setFontSize(28);

      for (let i = start, j = 0, k = 0; i < end; i++, j = j + 38, k++) {
        let text = `${i + 1}. ${images[i].name}`;

        if (k > 39) {
          doc.text(text, 1231, 352 + j - 1520);
        } else if (k > 19) {
          doc.text(text, 735, 352 + j - 760)
        } else {
          doc.text(text, 240, 352 + j);
        }
      }
    };

    for (let i = 1; i <= Math.ceil(images.length / countListOfPage); i++) {
      const end = (i * countListOfPage) > images.length ? images.length : i * countListOfPage;
      const start = (i * countListOfPage) - countListOfPage;

      newPageList(start, end);
    }
  };
  // Создание страниц с изображениями
  const addDocPage = async (item, index, length) => {
    const { original, size, name, hideFrame } = item;
    const { height, width} = size;
    let imgSrc = original;
    let imgHeight = Number(height);
    let imgWidth = Number(width);
    const imgRatio = Number(width) > Number(height) ? Number(width) / Number(height) : Number(height) / Number(width);

    if (!original) {
      return;
    }

    const getDataImageBySrc = (src) => {
      const image = new Image();

      image.crossOrigin = 'anonymous';
      image.src = src;

      return new Promise((resolve, reject) => {
        image.onload = () => {
          try {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;

            ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight);

            resolve({
              data: canvas.toDataURL(),
              width: image.naturalWidth,
              height: image.naturalHeight
            });
          } catch (e) {
            console.error(e);

            reject({})
          }
        };
      });
    };
    const createBorder = async () => {
      if (hideFrame) {
        doc.rect(100, 40, 1619, 1160);

        return;
      }

      let logoCompress = getCompressImageSrc(logo, 'width', 760, 140);

      if (logo.includes('.svg')) {
        const { data } = await getDataImageBySrc(logo);

        logoCompress = data;
      }

      const xImage = 1524 - (logoSize.width / 2);
      const yImage = 1162 - (logoSize.height / 2);

      doc.rect(100, 40, 1619, 1160);
      doc.setFillColor(255, 255, 255);
      doc.rect(1179, 1050, 540, 150, "FD");
      doc.line(1179, 1125, 1719, 1125);
      doc.line(1254, 1125, 1254, 1200);
      doc.line(1329, 1125, 1329, 1200);

      doc.addImage(logoCompress, xImage, yImage, Number(logoSize.width), Number(logoSize.height));
      doc.setFont('Montserrat-Bold');
      doc.setFontSize(32);
      doc.text(`${name}`, 1204, name.length > 35 ? 1080 : 1096, { maxWidth: 490 });

      doc.setFont('MuseoSansCyrl-300');
      doc.setFontSize(21);
      doc.text(translations.t('gallery.pdf.page'), 1199, 1158);
      doc.text(translations.t('gallery.pdf.pages'), 1268, 1158);

      doc.setFont('MuseoSansCyrl-500');
      doc.setFontSize(23);
      doc.text(`${index + offsetCountPage}`, 1199, 1180);
      doc.text(`${length + offsetCountPage - 1}`, 1268, 1180);
    };
    const setSizeImage = () => {
      if (imgWidth > frameWidth) {
        imgHeight = imgWidth > imgHeight ? frameWidth / imgRatio : frameWidth * imgRatio;
        imgWidth = frameWidth;
      }

      if (imgHeight > frameHeight) {
        imgWidth = imgWidth > imgHeight ? frameHeight * imgRatio : frameHeight / imgRatio;
        imgHeight = frameHeight;
      }
    };

    if (original.includes('.svg')) {
      const {data , width, height } = await getDataImageBySrc(original);

      imgSrc = data;
      imgWidth = width;
      imgHeight = height;
    }

    setSizeImage();

    if (additional.displayTitle || additional.displayList || index !== 0) {
      // Если есть титульный лист или содержание или лист не перный то создаем новую страницу
      doc.addPage([pageWidth, pageHeight]);
    }

    doc.addImage(imgSrc, 'JPEG', leftOffset, topOffset, imgWidth, imgHeight, '', 'FAST');

    await createBorder();
  };
  // обертка генерация стр и изображением. Задержки что бы не умирал браузер
  const generateDoc = (item, index, length) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(addDocPage(item, index, length));
      }, 10);
    });
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
  }

  const savePdfInServer = async (pdf) => {
    if (!pdf) {
      return;
    }

    try {
      const params = folderId ? { folder_id: folderId } : { id: projectId };
      const file = dataURLtoFile(pdf);

      const { data } = await ApiCall.savePdf({ file, ...params});

      if (!data.success) {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
    }
  };

  try {
    if (additional.displayTitle) {
      createMainPage();
    }

    if (additional.displayList) {
      createPagesList();
    }

    const { width, height } = await getCustomImageSize(logo, Number(logoSize.width), Number(logoSize.height));

    logoSize.width = width;
    logoSize.height = height;

    for (let i = 0; i < images.length; i++) {
      if (!images[i].size || !images[i].size.width || !images[i].size.height) {
        images[i] = await getImageWithSize(images[i]);
      }
    }

    dispatch({ type: SET_LOADING_ON, payload: { name: SET_DESIGN_PROJECT_LOADING_PDF, text: 'gallery.pdf.wait.generate' } });

    for (let i = 0; i < images.length; i++) {
      await generateDoc(images[i], i, images.length);
    }

    dispatch({ type: SET_LOADING_ON, payload: { name: SET_DESIGN_PROJECT_LOADING_PDF, text: 'gallery.pdf.wait.upload' } });

    const pdf = doc.output('datauristring', { filename: filename.replace(/;/g, '_') });

    await savePdfInServer(pdf);

    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DESIGN_PROJECT_LOADING_PDF } });

    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.SAVE_PDF].name,
      value: pdf,
    });

    /* Для локалки */
    doc.save(filename);
  } catch (e) {
    console.error(e); // eslint-disable-line
    notifications.showError(translations.t('gallery.pdf.error'));
    dispatch({ type: SET_LOADING_OFF, payload: { name: SET_DESIGN_PROJECT_LOADING_PDF } });
  }
};
