import React from 'react';
import styled from 'styled-components';
import { tablet_landscape } from '../../modules/breakpoints';
import { getImage } from 'planoplan-ui-kit/core/get_image';

export const Image = ({ normal = '', retina = '', ...restProps }) => (
  <View srcSet={`${getImage(normal)} 1x, ${retina ? getImage(retina) : null} 2x`} {...restProps} />
);

const View = styled.img`
  width: 62px;
  height: 62px;

  @media ${tablet_landscape} {
    width: 90px;
    height: 90px;
  }
`;
