import {
  ON_CHANGE_USER,
  ON_LOGOUT,
  ON_OPEN_STORE,
  ON_OPEN_STORE_CART,
  ON_OPEN_GALLERY,
  ON_CABINET,
} from '@observer/constants';
import { LocalStorage } from '@libs';
import { MODE_ALIAS, UNITY, POINTER_ON_INTERFACE, POINTER_OFF_INTERFACE } from '@globalConstants';
import { POP_CURRENT_TEAM } from '@globalLocalStorage';
import { interfaces } from '@globalInvoke';
import { BUTTONS } from '../constants';
import { ApiCall } from './api-call';
import { mappedButtons } from './invoke';
import * as actions from './Store';

const observer = new window.POPObserver();
let lastShowNotificationPosition = 0;

export const setIsProcessing = (state) => (dispatch) => dispatch(actions.setIsProcessing(state));

export const setView = (view) => (dispatch) => {
  lastShowNotificationPosition = 0;

  dispatch(actions.setView(view));
};

export const setCurrentUser = (id) => async (dispatch, getState) => {
  const { teams = [], settings } = getState();
  const { mode } = settings;

  dispatch(actions.setCurrentTeam(id));

  lastShowNotificationPosition = 0;

  const currentTeam = teams.find((item) => item.id === id) || {};

  if (id) {
    const { cloudSize, count, avatar } = currentTeam;
    const payload = {
      cloudSize,
      count,
      avatar,
    };

    dispatch(actions.setUserTeam(payload));
  }

  const payload = id ? { team_id: id } : {};

  observer.postMessage(ON_CHANGE_USER, payload);
  LocalStorage.set(POP_CURRENT_TEAM, id);

  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.SET_USER].name,
      value: payload,
    });
  }
};

export const setUserSettings = (item) => async (dispatch, getState) => {
  const { userSettings: { checksum = '' }, currentTeam } = getState();
  const { position, id } = item;

  // В случае, если чексумма успела измениться, забираем ее из ответа и делаем запрос снова
  // TODO переписать нормально с промисами
  const request = async (settingsChecksum) => {
    try {
      const params = {
        name: 'lastReadedNotis',
        value: id,
        checksum: settingsChecksum
      };

      const response = await ApiCall.setUserSettings({ params, team_id: currentTeam });
      const { data: { success, errorText, data } } = response;

      if (!success) {
        if (errorText === 'Invalid checksum') {
          await request(data.unitySettingsChecksum);
          return;
        } else {
          throw new Error(errorText);
        }
      }

      const { unitySettingsChecksum = '' } = data;

      dispatch(actions.setUserSettings({ last_notification: id, checksum: unitySettingsChecksum }));
      lastShowNotificationPosition = position;
    } catch (e) {
      console.warn(e);
    }
  };

  if (position > lastShowNotificationPosition) {
    await request(checksum);
  }
};

export const clearAllNotification = () => (dispatch, getState) => {
  const { notifications } = getState();
  const item = { ...notifications[notifications.length - 1], position: 0 };

  dispatch(actions.setNotifications([]));
  dispatch(setUserSettings(item));
};

export const clickItemNotification = (item, mode, tariffAlias = '') => {
  if (item.lotId && MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.LOT_SHOW_INFO].name,
      value: item.lotId,
    });
  }

  if (item.gallery) {
    observer.postMessage(ON_OPEN_GALLERY, item.gallery);
  }

  if (item.type === 'tariff_expire') {
    const options = {
      cartTariff: tariffAlias,
      initialScreen: 'cart',
    };

    observer.postMessage(ON_OPEN_STORE, options);
  }
};

export const clearNotifications = () => (dispatch, getState) => {
  const { notifications, userSettings } = getState();
  const { last_notification } = userSettings;

  const index = notifications.findIndex((item) => item.id === last_notification) + 1;
  const array = index !== 0 ? notifications.slice(index) : [];

  dispatch(actions.setNotifications(array));
};

export const logout = (mode) => {
  LocalStorage.remove(POP_CURRENT_TEAM);

  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.LOGOUT].name,
    });
  } else {
    observer.postMessage(ON_LOGOUT);
  }
};

export const cabinet = (mode) => {
  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.CABINET].name,
    });
  } else {
    observer.postMessage(ON_CABINET);
  }
};

export const openStore = () => {
  observer.postMessage(ON_OPEN_STORE);
};

export const openStoreCart = () => {
  observer.postMessage(ON_OPEN_STORE_CART);
};

export const pointerOnInterface = (mode) => {
  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: interfaces[POINTER_ON_INTERFACE].name,
      value: interfaces[POINTER_ON_INTERFACE].value,
    });
  }
};

export const pointerOffInterface = (mode) => {
  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }
};
