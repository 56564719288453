import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { tablet_landscape } from '../../modules/breakpoints';
import { translations } from '@libs';

export const Additional = ({ onResetAll, onCompare }) => (
  <View>
    <Button onClick={onResetAll} modify={'hover-rotate'}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8C15 6.05544 13.9908 4.34659 12.4677 3.36856C11.9328 3.02641 11.3409 2.77825 10.7174 2.63717C9.81126 2.43214 8.86792 2.46 7.97543 2.71815C7.08294 2.97631 6.27039 3.45634 5.6136 4.11346L5.60235 4.12437L3.42889 6.16667H6.16663C6.44277 6.16667 6.66663 6.39053 6.66663 6.66667C6.66663 6.94281 6.44277 7.16667 6.16663 7.16667H2.16663C2.09176 7.16667 2.02073 7.15022 1.95697 7.12073C1.90708 7.09771 1.86027 7.06608 1.81884 7.0259C1.80325 7.0108 1.78864 6.9947 1.77511 6.9777C1.74582 6.94091 1.72249 6.90103 1.70509 6.85933C1.68103 6.80166 1.6683 6.74051 1.66678 6.67919C1.66668 6.67502 1.66663 6.67084 1.66663 6.66667V2.66667C1.66663 2.39053 1.89048 2.16667 2.16663 2.16667C2.44277 2.16667 2.66663 2.39053 2.66663 2.66667V5.51073L4.91198 3.40088C5.68719 2.62718 6.64537 2.06188 7.69757 1.75753C8.28569 1.58742 8.8925 1.50192 9.49996 1.50157V1.5C10.82 1.5 12.0482 1.89352 13.0736 2.56964C13.3357 2.74191 13.5858 2.93348 13.8218 3.14322C14.6425 3.87265 15.265 4.7981 15.6313 5.8332C15.6354 5.84479 15.6391 5.85643 15.6423 5.8681C15.874 6.53594 16 7.25326 16 8C16 11.5899 13.0898 14.5 9.49996 14.5C7.57483 14.5 5.84466 13.6624 4.65518 12.3335C4.09593 11.7086 3.65549 10.9745 3.36995 10.1666C3.27793 9.90627 3.41439 9.6206 3.67475 9.52858C3.93511 9.43656 4.22077 9.57302 4.31279 9.83338C4.55406 10.516 4.92654 11.1372 5.40031 11.6665C6.40812 12.7925 7.87109 13.5 9.49996 13.5C12.5375 13.5 15 11.0376 15 8Z" fill="#D1D1D1"/>
      </svg>
      <span>{translations.t('image_editor.reset_all')}</span>
    </Button>

    <Button onMouseDown={onCompare.bind(null, true)} onMouseUp={onCompare.bind(null, false)} onMouseOut={onCompare.bind(null, false)}>
      <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="14.5" y="4.5" width="7" height="7" rx="0.5" stroke="#D1D1D1"/>
        <rect x="2" y="4" width="8" height="8" rx="1" fill="#D1D1D1"/>
        <path d="M12 2V14" stroke="#D1D1D1"/>
      </svg>
      <span>{translations.t('image_editor.compare')}</span>
    </Button>
  </View>
);

const View = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.light_gray};
  font-weight: 300;
  border-top: 1px solid ${Colors.coal};
  padding: 15px;
`;

const Button = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('color, border-color')};
  border: 1px solid ${Colors.coal};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  width: 100%;
  justify-content: center;
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 10px;
  }
  
  svg {
    ${Mixins.transition('transform')};
    margin-right: 6px;
    flex-shrink: 0;
  }
  
  &:hover {
    color: ${Colors.white};
    border-color: ${Colors.nobel};
    
    svg {
      ${({ modify }) => modify === 'hover-rotate' && 'transform: rotate(-45deg)'};
    }
  }
`;
