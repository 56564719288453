import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { ISDEVELOPMENT } from '@globalConstants';
import { reducer } from './reducer';

export const createAppStore = (initialState) => {
  return createStore(
    reducer,
    initialState,
    compose(
      applyMiddleware(thunk),
      ISDEVELOPMENT && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );
};
