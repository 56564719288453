import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { TARIFFS } from '../../constants';

export const PriceInfo = ({ currency, tariff, goods, locale }) => {
  const itemTariff = goods.filter((item) => item.caption.includes(TARIFFS[tariff]))[0];
  const { price } = itemTariff;

  return (
    <View>
      <Price>{Math.round(price / 12).toLocaleString('ru')}{getCurrency(currency)}/{locale === 'ru' ? 'мес': 'mo'}</Price>
      <Subscription>{translations.t('banners.subscription')}</Subscription>
    </View>
  );
};

const View = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Price = styled.div`
  color: ${Colors.night};
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
`;

const Subscription = styled.div`
  color: ${Colors.nobel};
  font-weight: 400;
  font-size: 16px;
  text-align: right;
`;
