import React from 'react';
import { translations } from '@libs';
import { COUNTRIES_CODE } from "../../../constants";

const optionsCards = (elements) => {
  return elements.map((item) => (item.info ? {
    value: item.id,
    view_value: `•••• •••• •••• ${item.info.last4}`,
    isDefault: item.is_default,
    type: item.type,
    render: `•••• •••• •••• ${item.info.last4}`,
    info: {
      ...item.info,
      card_type: item.info.card_type || item.info.brand
    },
  } : null)).filter(item => item);
};

const optionsPay = (elements) => {
  return elements.map((item, index) => ({
    value: item.id,
    view_value: item.name,
    type: 'pay',
    render: (
      <span className="text-overflow" key={index} title={item.name}>
        {item.name}
      </span>
    ),
  }));
};

export const getOptions = (bindingCards, data) => {
  return [...optionsCards(bindingCards), ...optionsPay(data)];
};

export const getCountriesOptions = (countries) => {
  if (!countries) {
    return [];
  }

  return Array.from(countries).map((country) => {
    const view_value = COUNTRIES_CODE[country.value] ? translations.t(`country.${COUNTRIES_CODE[country.value]}`) : country.value;

    return {
      ...country,
      view_value,
      render: view_value
    }
  });
}

export const pushCountryOption = (country, countries, locale) => {
  if (!country || !countries) {
    return 0;
  }

  const view_value = country['Names'][locale] || country.IsoCode;
  const key = countries.length;

  countries[key] = {
    value: country.IsoCode,
    vat: 0,
    view_value,
    render: view_value
  };

  return key;
}


// export const
