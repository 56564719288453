import React from 'react';
import { translations } from '@libs';
import { TARIFF_ALIAS, TARIFF_JOIN_GOODS } from '../constants';

export const replaceValueInArray = (array, value, field) => {
  return array.map((item) => {
    if (item[field] === value[field]) {
      return value;
    }

    return item;
  });
};

export const removeValueInArray = (array, value, field) => {
  return array.filter((item) => item[field] !== value[field]);
};

export const textHooks = (string) => {
  const textHooks = string.substr(0, string.indexOf('('));
  const result = {};

  if (textHooks) {
    result.out_hooks = textHooks;
    result.in_hooks = string.substring(string.indexOf('(') + 1, string.indexOf(')')).replace(/ /g, '');
  } else {
    result.out_hooks = string;
    result.in_hooks = '';
  }

  return result;
};

export const toFixed = (num, dec) => {
  const isInteger = (num ^ 0) === num;

  return isInteger ? num : Number(num.toFixed(dec));
};

export const toRound = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

// изменение способа вывода цены взависимости от валюты.
export const getViewPrice = (currency, value) => {
  if (typeof value !== 'number') {
    return value;
  }

  return currency === '₽' ? `${value} ${currency}` : `${currency}${value.toFixed(2)}`;
};

export const getObjViewPrice = (currency, value) => {
  if (typeof value !== 'number') {
    return value;
  }

  return currency === '₽' ? <>{convertToDigit(value)}<span>{currency}</span></> : <><span>{currency}</span>{value.toFixed(2)}</>;
};

export const getAnalyticParamsTariff = (tariff, index = null) => {
  if (!tariff || Object.keys(tariff).length === 0) {
    return {};
  }

  const price = tariff.price ? tariff.price : tariff.product ? tariff.product.price : '';
  const name = (tariff.product && tariff.product.caption) ? tariff.product.caption : tariff.caption;

  return {
    item_name: name || '',
    price: price || 0,
    item_category: 'tariff',
    item_list_name: 'Plans',
    index: typeof index === 'number' ? index + 1 : index,
    quantity: 1,
  }
};

export const getAnalyticParamsRender = (item, index = null) => {
  if (!item || Object.keys(item).length === 0) {
    return {};
  }

  const getPack = (item) => {
    try {
      if (item.in_sets[item.activeCount]) {
        return item.in_sets[item.activeCount];
      }

      throw `package "${item.id}" not found! fun 'getAnalyticParamsRender'`;
    }
    catch (error) {
      console.error(error);

      return {
        count: 0,
        price: 0,
        name: ''
      };
    }
  };

  const { count, price, name } = getPack(item);

  return {
    item_name: name || `${item.caption} (${count} шт)`,
    price: price || 0,
    item_category: item.view_alias,
    item_list_name: 'Renders',
    index: typeof index === 'number' ? index + 1 : index,
    quantity: item.view_count,
  }
};

export const sortAndFilterPricing = (profileGoods, pricing, productTariff, descriptionTariffs) => {
  const alias = profileGoods.tariff_alias;
  const isTrial = profileGoods.tariff_is_trial;

  const orderTariff = {
    [TARIFF_ALIAS.START]: {
      order: 1,
      sort: 1,
    },
    [TARIFF_ALIAS.PRO]: {
      order: 2,
      sort: alias === TARIFF_ALIAS.PRO ? 0 :  isTrial ? 5 : 3,
    },
    [TARIFF_ALIAS.PROPLUS]: {
      order: isTrial ? 2 : 3,
      sort: alias === TARIFF_ALIAS.PROPLUS ? 0 : 2,
    },
    [TARIFF_ALIAS.BUSINESS]: {
      order: 4,
      sort: 4,
    },
  };
  const filterPricing = pricing.filter((item) => {
    const order = orderTariff[alias] ? orderTariff[alias].order : 0;

    return item.options['editor.showInStore'] === 'true' && orderTariff[item.alias] && orderTariff[item.alias].order >= order;
  }).map(item => ({
    ...item,
    product: productTariff[item.alias],
    description: descriptionTariffs[item.alias],
  }));

  filterPricing.sort((a, b) => {
    return orderTariff[a.alias].sort - orderTariff[b.alias].sort
  });

  return filterPricing.map((item, index) => {
    const analyticParams = getAnalyticParamsTariff(item, index);

    return {
      ...item,
      analyticParams,
    }
  });
};

export const getProductTariff = (pricing) => {
  const product = {
    [TARIFF_ALIAS.START]: { monthPrice: translations.t('store.pricing.free') },
    [TARIFF_ALIAS.PRO]: { monthPrice: translations.t('store.pricing.on_request') },
    [TARIFF_ALIAS.PROPLUS]: { monthPrice: translations.t('store.pricing.on_request') },
    [TARIFF_ALIAS.BUSINESS]: { monthPrice: translations.t('store.pricing.on_request') },
  };

  try {
    const startTariff = pricing.find(item => item.alias === TARIFF_ALIAS.START);
    const idsTariffOfYear = Object.values(TARIFF_JOIN_GOODS).map(item => item.year);
    const goodsById = startTariff.goods.filter(item => idsTariffOfYear.includes(item.id));

    Array.from(goodsById).forEach((item) => {
      let alias = '';

      for (const key in TARIFF_JOIN_GOODS) {
        if (TARIFF_JOIN_GOODS[key].year === item.id) {
          alias = key;
        }
      }

      product[alias] = item;
      product[alias].monthPrice = Number((item.price / 12).toFixed(0));
    });
  } catch (error) {
    console.error(error);
  }

  return product;
};

export const setPricingForTariff = (product, products) => {
  const getAliasAndPeriodTariffProduct = (id) => {
    for (const key in TARIFF_JOIN_GOODS) {
      if (Object.prototype.hasOwnProperty.call(TARIFF_JOIN_GOODS, key)) {
        if (`goods_${TARIFF_JOIN_GOODS[key].year}` === id) {
          return {
            alias: key,
            period: 'year'
          };
        }

        if (`goods_${TARIFF_JOIN_GOODS[key].month}` === id) {
          return {
            alias: key,
            period: 'month'
          }
        }
      }
    }
  };

  try {
    const { alias, period } = getAliasAndPeriodTariffProduct(product.store_id);
    let anotherPeriod = period === 'month' ? 'year' : 'month';

    const searchId = TARIFF_JOIN_GOODS[alias][anotherPeriod];
    const searchProduct = products.find(item => item.id === searchId);

    product.pricing = {
      [period]: product.price,
      [anotherPeriod]: searchProduct.price
    }

  } catch (error) {
    console.error(error);
  }
};

export const convertToDigit = (value) => {
  return Number(value) ? Number(value).toLocaleString('ru-Ru') : value;
};

export const getDiscountProduct = (sets, activeSet, count = 1) => {
  if (activeSet === 0) {
    return null;
  }

  const firstPack = sets[0] || {};
  const currentPack = sets[activeSet] || {};
  const onePackPrice = firstPack.price / firstPack.count;

  const sum = Math.round(onePackPrice * currentPack.count);
  const percent = Math.round((sum - currentPack.price) / sum * 100);

  return { sum: sum * count, percent };
};

export const isInViewport = (element, container = null, visibilityThreshold = 100) => {
  const rect = element.getBoundingClientRect();

  if (container) {
    const containerRect = container.getBoundingClientRect();
    const elementHeight = rect.bottom - rect.top;
    const visiblePercentage = (Math.min(containerRect.bottom, rect.bottom) - Math.max(containerRect.top, rect.top)) / elementHeight * 100;

    return visiblePercentage >= visibilityThreshold;
  }

  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const elementHeight = rect.bottom - rect.top;
  const visiblePercentage = (Math.min(windowHeight, rect.bottom) - Math.max(0, rect.top)) / elementHeight * 100;

  return visiblePercentage >= visibilityThreshold;
};
