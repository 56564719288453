import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { Quantity } from '../../Quantity';
import { CART } from '../../../constants';
import { Indent } from '../../../modules/indent';
import { tablet_portrait, tablet_portrait_only, tablet_landscape_only, laptop } from '../../../modules/breakpoints';
import { getObjViewPrice, getDiscountProduct } from '../../../modules/helpers';

export const Card = React.forwardRef(({
  data,
  addToCart,
  addCountGood,
  removeCountGood,
  setScreen,
  inCart,
  team_id,
  setProductScreen,
  changePriceForSet,
  noImage,
  test,
  countMouseEnter,
  countMouseLeave
}, ref) => {
  const {
    activeCount,
    in_sets,
    caption,
    store_id,
    view_name,
    view_price,
    view_size,
    view_count,
    view_currency,
    img_preview,
  } = data;

  const amountOfSets = in_sets?.filter((item) => item.grouping !== null && item.grouping !== '').sort((a, b) => a.count - b.count);
  const discount = getDiscountProduct(amountOfSets, activeCount, view_count);

  useEffect(() => {
    if(amountOfSets?.length > 0) {
      changePriceForSet(store_id, amountOfSets[activeCount]?.price, activeCount, { autoSet: true });
    }
  }, [store_id]);

  return (
    <View data-test={test} ref={ref}>
      {!noImage && (
        <Images onClick={setProductScreen}>
          <img src={img_preview} alt={caption} data-test="card__image" />
        </Images>
      )}
      <Content>
        <Header onClick={setProductScreen}>
          <Caption data-test="card__caption">{view_name}</Caption>
          <Caption data-test="card__size">{view_size}</Caption>
        </Header>
        <div>
          <Controls>
            {view_price > 0 &&
              <Price>
                {discount &&
                  <Discounts>
                    <OldPrice>{getObjViewPrice(view_currency, discount.sum)}</OldPrice>
                    <Discount>-{discount.percent}%</Discount>
                  </Discounts>
                }
                <Pricing data-test="card__pricing">{getObjViewPrice(view_currency, view_price)}</Pricing>
              </Price>
            }
            {amountOfSets?.length > 0 &&
              <NumberGoods>
                <Item>{translations.t('store.common.pcs')}</Item>
                {amountOfSets.map((item, index) => {
                  return (
                    <Item key={index}>
                      <ButtonSets key={index} active={activeCount === index} type='button' onClick={() => {
                        changePriceForSet(store_id, item.price, index, { autoSet: false });
                      }}>{item.count}</ButtonSets>
                    </Item>
                  )})}
              </NumberGoods>
            }
            {/*<CountPack />*/}
          </Controls>
          <CartButton data-id={store_id}>
          {inCart(store_id) ? (
            <ActionCart>
              <ButtonCart theme="primary" size="M" onClick={() => setScreen(CART)} data-test="card__button-go-over">
                {translations.t('store.common.go_over')}
              </ButtonCart>
              <Quantity count={view_count}
                        add={() => addCountGood(data)}
                        remove={() => removeCountGood(data)}
                        onMouseEnter={() => countMouseEnter(data)}
                        onMouseLeave={() => countMouseLeave(data)} />
            </ActionCart>
          ) : (
            <ButtonGradient theme="primary" size="M" onClick={() => addToCart(store_id, data)} data-test="card__button-in-cart">
              {translations.t('store.common.in_cart')}
            </ButtonGradient>
          )}
        </CartButton>
        </div>
      </Content>
    </View>
  );
});

export const View = styled.div`
  ${Indent.margin_bottom(1.3)};
  width: 100%;
  background-color: ${Colors.white};
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid ${Colors.whisper};
  display: flex;
  flex-direction: column;

  @media ${tablet_portrait_only} {
    ${Grid.column(13.5, 28)};

    &:nth-child(2n + 1) {
      ${Grid.post(1, 28)};
    }
  }

  @media ${tablet_landscape_only} {
    ${Grid.column(9, 28)};
    ${Grid.post(0.5, 28)};

    &:nth-child(3n) {
      ${Grid.post(0)};
    }
  }

  @media ${laptop} {
    ${Grid.column(9.6, 20)};
    
     &:nth-child(2n + 1) {
      ${Grid.post(0.75, 20)};
    }
  }
`;

const Images = styled.div`
  width: 100%;
  display: none;

  img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    display: block;
  }

  @media ${tablet_portrait} {
    display: block;
  }
`;

const Content = styled.div`
  color: ${Colors.coal};
  display: flex;
  flex-direction: column;
  min-height: 150px;
  padding: 15px;
  justify-content: space-between;
  flex-grow: 1;

  @media ${tablet_portrait} {
    min-height: 168px;
  }
  
  @media ${laptop} {
    padding: 18px 40px 30px;
  }
`;

const Header = styled.div`
  margin-bottom: 30px;
`;

const Caption = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.night};
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 21px;
  margin-top: auto;
  flex-wrap: wrap;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: flex-start;
  
  @media ${laptop} {
    align-items: center;
    flex-direction: row;
  }
`;

const Pricing = styled.div`
  
  text-align: right;
  font-size: 30px;
  line-height: 37px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  
  span {
    font-size: 24px;
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

const NumberGoods = styled.ul`
  display: flex;
  align-items: flex-end;
  padding-left: 5px;
`;
const Item = styled.li`
    list-style-type: none;
    
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #9E9E9E;
`;

const ButtonSets = styled.button`
    margin-left: 8px;
    padding: 5 10px;
    width: 40px;
    height: 29px;
    border-radius: 5px;
    border: ${(p) => (p.active ? '1px solid #269CFF' : '1px solid #E5EAF1')};
    background: none;
    cursor: pointer;
    &:hover {
      border: 1px solid #269CFF;
    }
`;

const Discounts = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3px;
  position: absolute;
  bottom: 100%;
`;
const OldPrice = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-decoration: line-through;
  color: ${Colors.nobel};
  margin-right: 6px;

  span {
    font-size: 14px;
  }
`;
const Discount = styled.div`
  padding: 0 3px 0 6px;
  background-color: ${Colors.frog_green};
  border-radius: 10px 2px 2px 10px;
  color: ${Colors.white};
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
`;

const CartButton = styled.div``;
const ButtonGradient = styled(Button)`
  ${Mixins.transition('background')};
  background: linear-gradient(90deg, #269CFF, #0057FF, #269CFF);
  background-size: 200%;
  
  &:hover {
    background-position: 50% 0;
  }
`;
const ButtonCart = styled(ButtonGradient)`
  font-size: 16px;
  line-height: 16px;
`;
const ActionCart = styled.div`
  display: flex;
  
  ${ButtonGradient} {
    max-width: 150px;
    margin-right: 20px;
  }
`;
