import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs/translations';
import { STATUSES, TASK_STATUS } from '../../constants';
import { Attention } from '../../components/Attention';
import { Panorama, Tour, Image, Message, Status } from './organism';

export const Canvas = ({ current_image, onSwipe, setTransform, scale, isFill, isFullScreen, isPreviewMode }) => {
  const isDisabledPan = scale === 1;
  const state = useSelector((state) => state);
  const { creatingPdf, settings } = state;
  const { zoom } = settings;

  useEffect(() => {
    if (scale !== 1) {
      setTransform(0, 0, 1, 0);
    }
  }, [current_image, isFullScreen]);

  return (
    <View>
      <Hammer onSwipe={(e) => onSwipe(e, isDisabledPan)}>
        <Wrapper scaleFactor={ 1 / zoom }>
          <TransformComponent>
            {current_image && (
              <>
                {current_image.krpano_url && (
                  <Panorama krpano_url={current_image.krpano_url} />
                )}

                {current_image.tour && (
                  <Tour current={current_image} key={`${current_image.id}_${current_image.updateAt}`} />
                )}

                {current_image.original && (
                  <Image
                    original={current_image.original}
                    preview={current_image.preview}
                    caption={current_image.roomCaption}
                    size={current_image.size}
                    isFull={current_image.isProgress || current_image.isError}
                    isLoaded={current_image.isLoaded}
                    updateAt={current_image.updateAt}
                    isDisabledPan={isDisabledPan}
                    zoom={zoom}
                  />
                )}
              </>
            )}
          </TransformComponent>
        </Wrapper>
      </Hammer>

      {creatingPdf && <Message type="progress">{translations.t('gallery.pdf.work')}</Message>}

      {current_image.isPreview && !current_image.krpano_url && <Attention keyTranslation={'gallery.render.preview'} />}
      {current_image.isPreview && current_image.krpano_url && <Attention keyTranslation={'gallery.panorama.preview'} />}

      {Boolean(current_image.status !== STATUSES[TASK_STATUS.complete] && !isPreviewMode) && (
        <Status status={current_image.status}/>
      )}
    </View>
  );
};

const View = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  zoom: ${(p) => p.scaleFactor};

  .react-transform-component,
  .react-transform-element {
    width: 100%;
    height: 100%;
  }
`;
