import { BUTTONS } from './constants';

export const mappedButtons = {
  [BUTTONS.CLOSE_APP]: {
    name: 'AppClose',
    value: true,
    type: 'string',
    alias: 'AppClose',
  },
};
