import {
  USER_WIDGET_SET_IS_PROCESSING,
  USER_WIDGET_OPEN,
  USER_WIDGET_CLOSE,
  USER_WIDGET_CHANGE_USER,
} from '@features/user-widget/constants';
import {
  SCREEN_ENTRY_INSTALLING_PERCENT,
  SCREEN_ENTRY_INSTALLING_ERROR,
  SCREEN_ENTRY_INSTALLING_NOT_REQUIRED,
  SCREEN_ENTRY_CLOSE,
} from '@features/unity/screen-entry/constants';
import { APP_CLOSE } from '@features/unity/constants';
import { TEAM_CHANGED } from '@features/cabinet/constants';

export const actions = {
  ScreenEntryInstallingPercent: {
    action: SCREEN_ENTRY_INSTALLING_PERCENT,
  },
  ScreenEntryInstallingError: {
    action: SCREEN_ENTRY_INSTALLING_ERROR,
  },
  ScreenEntryInstallingNotRequired: {
    action: SCREEN_ENTRY_INSTALLING_NOT_REQUIRED,
  },
  ScreenEntryClose: {
    action: SCREEN_ENTRY_CLOSE,
  },
  UserWidgetOpen: {
    action: USER_WIDGET_OPEN,
  },
  UserWidgetClose: {
    action: USER_WIDGET_CLOSE,
  },
  UserWidgetSetIsProcessing: {
    action: USER_WIDGET_SET_IS_PROCESSING,
  },
  /*TeamChanged: {
    action: USER_WIDGET_CHANGE_USER,
  },*/
  AppWantsToQuit: {
    action: APP_CLOSE,
  },
};
