import React from 'react';
import { translations } from '@libs';

export const setOptions = (filters) => {
  return filters.map((item) => getOption(item));
};

export const setSelectedOption = (current_filter) => {
  return getOption(current_filter);
};

const getOption = ({ value, roomCaption, roomArea }) => {
  if (value === 'all') {
    return {
      value,
      caption: translations.t('gallery.all'),
    };
  }

  return {
    value,
    caption: `${roomCaption} ${roomArea ? `${roomArea}m<sup>2</sup>` : ''}`
  }
};
