import { request } from '@libs';

export const ApiCall = {
  locale: 'en',
  team_id: '',

  setSettings({ locale, team_id }) {
    this.locale = locale;
    this.team_id = team_id;
  },

  getPanoramas({ id }) {
    const urls = { local: '/json/tour-editor/panoramas.json', prod: `/${this.locale}/projects/getScreenshots/?id=${id}&filter[type][]=panorama` };

    return request.call_auth('get', urls, {});
  },

  saveStructure({ projectId, tourId = null, data = {} }) {
    const idParams = `&id=${tourId}`;
    const urls = { local: '/json/tour-editor/edit.json', prod: `/${this.locale}/projects/toursEdit/` };
    const settings = { useFormData: true };
    const params = { project_id: projectId, data: JSON.stringify(data) };

    if (tourId) {
      params.id = tourId;
    }

    return request.call_auth('post', urls, params, settings);
  },
};
