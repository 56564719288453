import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { isProdHost, getLocaleUi } from '@libs';
import {
  USER_WIDGET_CHANGE_USER,
  USER_WIDGET_CLOSE,
  USER_WIDGET_SET_IS_PROCESSING,
} from '@features/user-widget/constants';
import UserWidget from '@features/user-widget';
import { App } from './app';
import { initialState, createAppStore } from './modules/Store';

export default class Layout {
  constructor() {
    window.invokeAPI.registerDispatch('LAYOUT', {
      // [USER_WIDGET_OPEN]: () => this._userWidgetOpen(),
      [USER_WIDGET_CLOSE]: () => this._userWidgetClose(),
      [USER_WIDGET_CHANGE_USER]: (id) => this._userWidgetChangeUser(id),
      [USER_WIDGET_SET_IS_PROCESSING]: (status) => this._userWidgetSetIsProcessing(status),
    });
  }
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения экрана входа не указан html элемент');
    this.locale = settings.locale || 'en';
  }

  /**
   * Открытие модуля
   */
  open() {
    this._mount();
    this._userWidgetOpen();
  }

  /**
   * Удаление модуля из дом-дерева
   */
  close() {
    const rootElement = document.getElementById(this.root);
    ReactDOM.unmountComponentAtNode(rootElement);
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      settings: {
        locale: this.locale,
        localeUi: getLocaleUi(this.locale), // язык для интерфейса из массива поддерживаемых языков
        close: this.close.bind(this),
      },
    };
  }

  /**
   * Открытие виджета пользоватедя
   */
  _userWidgetOpen() {
    if (isProdHost) {
      return;
    }

    const { locale } = this.settings;

    this.userWidget = new UserWidget();

    this.userWidget.init({
      root: 'user-widget',
      locale,
      mode: 'unity',
    });

    this.userWidget.open();
  }

  /**
   * Закрытие виджета пользоватедя
   */
  _userWidgetClose() {
    if (this.userWidget && !isProdHost) {
      this.userWidget.close();
    }
  }

  /**
   * Изменение статуса процессинга виджета пользователя
   */
  _userWidgetSetIsProcessing(status) {
    if (this.userWidget && !isProdHost) {
      this.userWidget.setIsProcessing(status);
    }
  }

  /**
   * Изменение юзера в виджете пользователя
   */
  _userWidgetChangeUser(id) {
    if (this.userWidget && !isProdHost) {
      this.userWidget.changeUser(id);
    }
  }
}
