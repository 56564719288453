import React from 'react';

export const IProPlus = ({ width = 38, height = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 38 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="38" height="16" rx="8" fill="#2F2F2F"/>
      <path d="M6.8 11V4.2H9.71C11.26 4.2 12.08 4.86 12.08 6.37C12.08 7.83 11.25 8.58 9.7 8.58H8.02V11H6.8ZM8.02 7.51H9.6C10.49 7.51 10.86 7.13 10.86 6.39C10.86 5.62 10.48 5.27 9.6 5.27H8.02V7.51ZM13.2648 11V4.2H16.1748C17.6548 4.2 18.5348 4.86 18.5348 6.38C18.5348 7.43 18.0648 8.1 17.2248 8.39L18.8248 11H17.4348L15.9848 8.56H14.4848V11H13.2648ZM14.4848 7.5H16.0448C16.9448 7.5 17.3048 7.11 17.3048 6.39C17.3048 5.6 16.9448 5.27 16.0448 5.27H14.4848V7.5ZM22.9066 11.1C20.9266 11.1 19.5666 9.9 19.5666 7.6C19.5666 5.3 20.9266 4.1 22.9066 4.1C24.8966 4.1 26.2566 5.3 26.2566 7.6C26.2566 9.9 24.8966 11.1 22.9066 11.1ZM20.8066 7.6C20.8066 9.08 21.5666 10.01 22.9066 10.01C24.2566 10.01 25.0066 9.08 25.0066 7.6C25.0066 6.12 24.2566 5.19 22.9066 5.19C21.5666 5.19 20.8066 6.12 20.8066 7.6ZM29.502 10.35V8.31H27.472V7.33H29.502V5.29H30.502V7.33H32.532V8.31H30.502V10.35H29.502Z" fill="#FFF59B"/>
    </svg>
  );
};
