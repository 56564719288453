import { request } from '@libs';

const getData = async (urls, params) => {
  try {
    const response = await request.call_auth('post', urls, params);
    const { data } = response;

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',
  team_id: '',

  setSettings({ locale, team_id }) {
    this.locale = locale;
    this.team_id = team_id;
  },

  getGoods() {
    const urls = { local: './json/store/getgoods.json', prod: `/${this.locale}/users/getgoods/` };

    return getData(urls, { team_id: this.team_id });
  },

  getProfile() {
    const urls = { local: './json/store/profile.json', prod: `/${this.locale}/users/profile/` };

    return getData(urls);
  },

  getPricing() {
    const urls = { local: './json/store/pricing.json', prod: `/${this.locale}/store/pricing/` };

    return getData(urls);
  },

  getBindingCard() {
    const urls = { local: './json/store/binding.json', prod: `/${this.locale}/users/binding/` };

    return getData(urls);
  },

  getPaymentMethods() {
    const urls = { local: './json/store/paymentmethods.json', prod: `/${this.locale}/store/paymentmethods/` };

    return getData(urls);
  },

  getBanners({ params }) {
    const urls = { local: `./json/store/promo.json`, prod: `/promo/` };

    return getData(urls, params);
  },

  getUserSettings() {
    const prod = this.team_id
      ? `/teams/getsettings/?name[]=store_basket&name[]=last_buy&team_id=${this.team_id}`
      : `/users/getsettings/?name[]=store_basket&name[]=last_buy`;

    const urls = {
      local: `/json/store/user_settings.json?name[]=tariffAlias&team_id=${this.team_id}`,
      prod,
    };

    return getData(urls);
  },

  saveCart({ basket, checksum }) {
    const params = {
      name: 'store_basket',
      value: basket,
      team_id: this.team_id,
      checksum
    };
    const prod = this.team_id ? '/teams/settings/' : '/users/settings/';
    const urls = { local: './json/store/save-cart.json', prod };

    return request.call_auth('post', urls, params);
  },

  saveLastBuy({ goods, checksum }) {
    const params = {
      name: 'last_buy',
      value: goods,
      checksum
    };
    const prod = this.team_id ? '/teams/settings/' : '/users/settings/';
    const urls = { local: './json/store/last-buy.json', prod };

    return request.call_auth('post', urls, params);
  },

  getPriceWithPromo({ params }) {
    const urls = { local: './json/store/promo-code.json', prod: `/${this.locale}/store/pay/` };

    return request.call_auth('post', urls, params);
  },

  getPay({ params }) {
    const urls = { local: './json/store/pay.json', prod: `/${this.locale}/store/pay/` };

    return request.call_auth('post', urls, params);
  },

  getStatusPay({ orderid, redirectStatus, paymentIntent, paymentIntentClientSecret, tx_id  }) {
    const urls = { local: './json/store/check-status.json', prod: `/${this.locale}/store/check/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, { orderid, redirectStatus, paymentIntent, paymentIntentClientSecret, tx_id  }, settings);
  },

  getCountries() {
    const urls = { local: './json/store/countries.json', prod: `/${this.locale}/store/countries/` };

    return getData(urls);
  },

  getBindingData({ params }) {
    const urls = { local: './json/store/binding-data.json', prod: `/${this.locale}/store/binding/` };

    return request.call_auth('post', urls, params);
  },

  getTariffForCardBinding() {
    const params = {
      alias: 'TariffForCardBinding',
      team_id: this.team_id,
    };
    const urls = { local: './json/store/available-gifts.json', prod: `/${this.locale}/gifts/available/` };

    return request.call_auth('post', urls, params);
  },

  getContentStore() {
    const blocks = ['short_banners', 'store_content'];
    const urls = { local: `./json/store/store_content.json`, prod: `/${this.locale}/content/?${blocks.map(block => `blocks[]=${block}`).join('&')}` };

    return getData(urls);
  },
};
