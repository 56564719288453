import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { EmptyMessage } from './emptyMessage';
import { translations } from '@libs';
import { isElementInViewport } from '../../modules/helpers';
import { ListItem } from './list-item';

export const Sidebar = () => {
  const { panoramas, current } = useSelector((state) => state);
  const scrollRef = useRef();
  const elements = useMemo(() => Array(panoramas.length).fill(0).map(() => React.createRef()));
  const isEmpty = !Boolean(panoramas.length);

  const setScrollToCurrent = () => {
    const container = scrollRef.current;

    try {
      if (!container) return;

      const el = elements.find(item => item?.current?.dataset?.id === `${current.id}`);

      if (!el) return;

      if (!isElementInViewport(el.current, container)) {
        el.current.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setScrollToCurrent();
  }, [current]);

  return (
    <View>
      {isEmpty && <EmptyMessage/>}

      {!isEmpty && (
        <TitleWrap>
          <Title>{translations.t('tour_editor.sidebar.title')}</Title>
        </TitleWrap>
      )}

      <ScrollBar ref={scrollRef}>
        <List>
          {isEmpty && <EmptyMessage/>}

          {!isEmpty && (
            <Content>
              {panoramas.map((panorama, index) => (
                <ListItem ref={elements[index]} key={panorama.id} panorama={panorama} active={panorama.id === current.id} />
              ))}
            </Content>
          )}
        </List>
      </ScrollBar>
    </View>
  );
};

const View = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
  width: 260px;
  flex-shrink: 0;
  border-right: 1px solid ${Colors.coal};
  padding-right: 2px;
`;
const TitleWrap = styled.div`
  padding: 15px 20px 0 15px;
`;
const List = styled.div`
  padding: 0 20px 10px 15px;
`;
const Content = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.light_gray};
  font-weight: 400;
  margin-bottom: 10px;
`;
const ScrollBar = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.nobel};
    border-radius: 10px;
    width: 6px;
  }
`;
