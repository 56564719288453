import React from 'react';
import styled, { css } from 'styled-components';

export const Image = ({ preview, original, caption, isLoaded, updateAt, size, isFill, isDisabledPan, zoom }) => {
  return (
    <View key={`${updateAt}${updateAt}`} isLoaded={isLoaded} className={isDisabledPan && 'is-disabled-pan'}>
      {isLoaded ? (
        <Original src={original} alt={caption} isFill={isFill} size={size} zoom={zoom} />
      ) : (
        <Preview src={preview} alt={caption} size={size} />
      )}
    </View>
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  img {
    max-height: 100%;
    max-width: 100%;
    display: block;
  }
`;

const Preview = styled.img`
  width: ${({ size }) => (size.width ? `${size.width}px` : '100%')};
  height: ${({ size }) => (size.height ? `${size.height}px` : 'auto')};
  object-fit: contain;
  filter: blur(5px);
`;

const Original = styled.img`
  object-fit: contain;
  
  ${({ isFill }) =>
    isFill && css` 
      object-fit: cover;`
  }
  
  ${({ isFill, zoom, size }) =>
    (!isFill && zoom !== 1 && size && size.width) && css`
      width: ${size.width * zoom}px;`
  }
`;
