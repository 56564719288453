import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { ON_OPEN_GALLERY, ON_OPEN_STORE } from '@observer/constants';
import * as effects from '../../modules/effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { rail_y, thumb_y } from '../scroll';
import { SET_NOTIFICATIONS } from '../../constants';
import { Notice } from './organism/notice';

const observer = new window.POPObserver();
const debounceFunc = debounce(750, (fun) => fun());

export const Notification = ({ isOpen = false, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const { settings: { locale }, notification: { list }} = useSelector(state => state);
  const listRef = useRef();
  const [scrollRef, setScrollRef] = useState(null);
  const [lastNoticeId, setLastNoticeId] = useState(0);

  const getDate = (date) => {
    return new Date(Number(date)).toLocaleString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timezone: 'UTC'
    });
  };
  const getCaption = (item) => {
    try {
      const caption = item.data.caption;

      if (caption) {
        if (caption[locale]) {
          return caption[locale];
        }

        if (typeof caption === 'string') return caption;

        let findCaption = '';

        for (let key in caption) {
          if (Object.prototype.hasOwnProperty.call(caption, key)) {
            if (caption[key]) {
              findCaption = caption[key];
            }
          }
        }

        return findCaption;
      }

      return item.settings.caption ? item.settings.caption : '';
    } catch (error) {
      console.error(error);

      return '';
    }
  };
  const openLink = (link) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };
  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, {});
  };
  const openGallery = (projectId, openImageId) => {
    observer.postMessage(ON_OPEN_GALLERY, {
      projectId,
      openImageId,
    });
  };
  const onClick = (item) => {
    if (item.data.project_id) {
      return openGallery(item.data.project_id, item.data.shId);
    }

    if (item.data.link) {
      if (typeof item.data.link === 'object' && item.data.link[locale]) {
        return openLink(item.data.link[locale]);
      }

      if (typeof item.data.link === 'string') {
        return openLink(item.data.link);
      }
    }

    if (item.data.tariff) {
      return openStore();
    }
  };
  const onScrollList = (container) => {
    const containerSize = container.getBoundingClientRect();
    const bottomContainer = containerSize.top + containerSize.height;
    const items = container.querySelectorAll('div[data-id]');

    items.forEach((item) => {
      const itemSize = item.getBoundingClientRect();
      const bottomItem = itemSize.y + itemSize.height - 10;

      if (bottomItem <= bottomContainer) {
        if (lastNoticeId < Number(item.dataset.id)) {
          setLastNoticeId(Number(item.dataset.id));
        }
      }
    });
  };
  const getScrollRef = (container) => {
    setScrollRef(container);
  };
  const onRemoveAll = () => {
    const lastNotify = list[list.length-1];

    dispatch(effects.setUserSettings({ lastNoticeId: lastNotify.id }));
    dispatch({ type: SET_NOTIFICATIONS, payload: [] });

    onClose();
  };
  const removeViewedNotification = () => {
    const notViewList = list.filter((notify) => notify.id > lastNoticeId);

    dispatch({ type: SET_NOTIFICATIONS, payload: notViewList });
  };
  const beforeClose = () => {
    removeViewedNotification();
    onClose();
  };

  useEffect(() => {
    if (scrollRef && isOpen) {
      onScrollList(scrollRef);
    }
  }, [scrollRef]);

  useEffect(() => {
    if (lastNoticeId) {
      debounceFunc(() => {dispatch(effects.setUserSettings({ lastNoticeId }))});
    }
  }, [lastNoticeId]);

  return (
    <View>
      {isOpen &&
        <>
          <Overlay onClick={beforeClose} />
          {Boolean(list && list.length) &&
            <Content>
              <List ref={listRef}>
                <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150}} onScrollY={onScrollList} containerRef={getScrollRef} rail_y={rail_y} thumb_y={thumb_y}>
                  {list.map((notice) => (
                    <Notice key={notice.id}
                            notice={notice}
                            getDate={getDate}
                            getCaption={getCaption}
                            onClick={onClick} />
                  ))}
                </ScrollBar>
              </List>
              <DeleteWrap>
                <Delete onClick={onRemoveAll}>{translations.t('cabinet.notifications.clear')}</Delete>
              </DeleteWrap>
            </Content>
          }

          {!Boolean(list && list.length) &&
            <Content>
              <Text>{translations.t('cabinet.notifications.not')}</Text>
            </Content>
          }
        </>
      }
    </View>
  )
};

const View = styled.div`
  position: relative;
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.black, 0.5)};
  z-index: 3;
`;
const Content = styled.div`
  width: 280px;
  background-color: ${Colors.white};
  max-height: 420px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;
const Text = styled.div`
  padding: 28px 10px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
`;
const List = styled.div`
  overflow: hidden;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
`;
const Item = styled.div`
  ${Mixins.transition('border-color')};
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid ${Colors.timberwolf25};
  border-color: transparent transparent ${Colors.timberwolf25};
  display: flex;
  cursor: pointer;
 
  &:hover {
    border-color: ${Colors.blueBell};
    position: relative;
  }
`;
const Image = styled.div`
  width: 96px;
  height: 64px;
  overflow: hidden;
  border-radius: 5px;
  flex-shrink: 0;
  
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
`;
const FakeImg = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.feldgrau25};
`;
const Info = styled.div`
  margin-left: 10px;  
`;
const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
`;
const Time = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.feldgrau50};
  margin-top: 3px;
`;
const DeleteWrap = styled.div`
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  text-align: center;
  color: ${Colors.blueBell};
`;
const Delete = styled.span`
  cursor: pointer;
`;
