import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconAdd } from '../../../components/icons';

export const ButtonAdd = (props) => (
  <Button {...props}>
    <IconAdd disabled={props.view_disabled}/>
  </Button>
);

const Button = styled.a`
  ${Mixins.transition('fill')};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: ${Colors.white};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    fill: ${(p) => (p.disabled ? Colors.solitude : '#4F5B60')};
  } 

  &:hover {
    color: ${Colors.planoplan_blue};
    
    svg {
      fill: ${Colors.planoplan_blue};
    } 
  }
`;
