import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as effects from '../modules/effects';
import { StatusLayout } from '../layouts/status';
import { IconPending } from '@icons';
import { translations } from '@libs';

export const PendingScreen = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(effects.checkStatusPay());
  }, []);

  return (
    <StatusLayout title={translations.t('quickstore.pay.pending.title')}
                  text={translations.t('quickstore.pay.pending.text')}>
      <IconPending />
    </StatusLayout>
  );
});
