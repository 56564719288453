import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { translations, getMediaWithZoom } from '@libs';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import * as effects from '../../modules/effects';
import { NumberList } from '../NumberList';
import { Checkbox } from '@components/Form/checkbox';
import { laptop_wide_blank, full_hd_second_blank } from '../../modules/breakpoints';

export const TitlePage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { design_project, settings: { zoom } } = state;
  const { name, description, additional } = design_project;
  const maxLengthName = 60;
  const maxLengthDescription = 400;
  const [isPasteName, setIsPasteName] = useState(false);
  const [isPasteDescription, setIsPasteDescription] = useState(false);

  const onChangeName = (e) => {
    let { value } = e.target;

    value = value.replace(/\r?\n/g, '');

    if (value.length > maxLengthName) {
      if (isPasteName) {
        value = value.substring(0, maxLengthName);
      } else {
        return;
      }
    }

    dispatch(effects.updateDesignProjectName(value));
    setIsPasteName(false);
  };
  const onChangeDescription = (e) => {
    let { value } = e.target;

    if (value.length > maxLengthDescription) {
      if (isPasteDescription) {
        value = value.substring(0, maxLengthDescription);
      } else {
        return;
      }
    }

    dispatch(effects.updateDesignProjectDescription(value));
    setIsPasteDescription(false);
  };
  const onPasteName = () => setIsPasteName(true);
  const onPasteDescription = () => setIsPasteDescription(true);
  const onChangeCheckbox = (event) => {
    const target = event.target;
    const { additional } = design_project;

    dispatch(effects.updateDesignProjectAdditional({
      ...additional,
      [target.id]: target.checked
    }));
  };

  return (
    <View zoom={zoom}>
      <Container>
        <InputWrap>
          <InputTop>
            <Caption>{translations.t('gallery.project.info.name')}</Caption>
            <Count>{name.length}/{maxLengthName}</Count>
          </InputTop>
          <Input value={name} onChange={onChangeName} onPaste={onPasteName}/>
        </InputWrap>

        <InputWrap>
          <InputTop>
            <Caption>{translations.t('gallery.project.info.desc')}</Caption>
            <Count>{description.length}/{maxLengthDescription}</Count>
          </InputTop>
          <Textarea value={description} onChange={onChangeDescription} onPaste={onPasteDescription}/>
        </InputWrap>

        <Bottom>
          <NumberList number={1} name={translations.t('gallery.pdf.title_page.caption')} hideNumber={!additional.displayTitle} />

          <Checkbox id={'displayTitle'} label={translations.t('gallery.pdf.checkbox.export')} checked={additional.displayTitle} onChange={onChangeCheckbox} />
        </Bottom>
      </Container>
    </View>
  );
};
const View = styled.div`
  ${Grid.column(23.933, 36.4)};
  ${Grid.post(1, 36.4)};
  
  ${({ zoom }) => `
    @media ${getMediaWithZoom(laptop_wide_blank, zoom)} {
      ${Grid.column(23.933, 48.9)};
    
      &:not(:last-child) {
        ${Grid.post(1, 48.9)};
      }
    }
    
    @media ${getMediaWithZoom(full_hd_second_blank, zoom)} {
      ${Grid.column(23.933, 61.4)};
    
      &:not(:last-child) {
        ${Grid.post(1, 61.4)};
      }
    }
  `}
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.coal};
  padding: 10px;
`;
const InputWrap = styled.div`
  margin-bottom: 10px;
`;
const InputTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const Caption = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.light_gray};
`;
const Count = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${Colors.nobel};
`;
const Input = styled.input`
  border: none;
  outline: none;
  width: 100%;
  padding: 0 10px;
  height: 36px;
  color: ${Colors.light_gray};
  background-color: ${Colors.night};
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
`;
const Textarea = styled.textarea`
  border: none;
  outline: none;
  width: 100%;
  padding: 12px 10px;
  color: ${Colors.light_gray};
  background-color: ${Colors.night};
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  height: 72px;
  resize: none;
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray};
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
