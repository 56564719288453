import React from 'react';
import styled from 'styled-components';
import { uiHost, translations } from '@libs';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IVk } from 'planoplan-ui-kit/core/icons/i-vk';
import { IFb } from 'planoplan-ui-kit/core/icons/i-fb';
import { IGoogle } from 'planoplan-ui-kit/core/icons/i-google';
import { ICloseThin } from 'planoplan-ui-kit/core/icons/i-close-thin';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { MODALS } from '@features/unity/constants';
import * as effects from '../effects';
import {
  SocialConfirmVk,
  SocialRemoveVk,
  SocialConfirmGoogle,
  SocialRemoveGoogle,
  useModals
} from '@components/Modal';

export const LoginSocial = () => {
  const dispatch = useDispatch();
  const { profile: { linked_accounts: linkedAccounts = {}, country = {} }} = useSelector(state => state);
  const [modals, openModal, closeAllModals] = useModals([
    MODALS.SOCIAL_CONFIRM_VK,
    MODALS.SOCIAL_REMOVE_VK,
    MODALS.SOCIAL_CONFIRM_GOOGLE,
    MODALS.SOCIAL_REMOVE_GOOGLE,
  ]);
  const onAddVkAuth = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: `${uiHost}/binding/vk.html`,
    });
    closeAllModals();
    dispatch(effects.runCheckBindingAuth());
  };
  const onRemoveVkAuth = () => {
    dispatch(effects.removeVKAuth());
    closeAllModals();
  };
  const onAddGoogleAuth = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: `${uiHost}/binding/google.html`,
    });
    closeAllModals();
    dispatch(effects.runCheckBindingAuth());

  };
  const onRemoveGoogleAuth = () => {
    dispatch(effects.removeGoogleAuth());
    closeAllModals();
  };

  const socials = {
    vk: {
      title: 'users.profile.multiauth.vk.title',
      icon: IVk,
      isActive: Boolean(linkedAccounts.vkontakte),
      name: linkedAccounts ? linkedAccounts.vkontakte : '',
      active: {
        background: '#4680C2',
        fill: 'white',
        fillBg: '#4680C2',
      },
      available: true,
      onClick: Boolean(linkedAccounts.vkontakte) ? () => {} : () => openModal(MODALS.SOCIAL_CONFIRM_VK),
      onRemove: () => openModal(MODALS.SOCIAL_REMOVE_VK),
    },
    google: {
      title: 'users.profile.multiauth.google.title',
      icon: IGoogle,
      isActive: Boolean(linkedAccounts.google),
      name: linkedAccounts ? linkedAccounts.google : '',
      active: {
        background: '#EB4335',
        fill: 'white',
        fillBg: '#4680C2',
      },
      available: country.IsoCode !== 'RU',
      onClick: Boolean(linkedAccounts.google) ? () => {} : () => openModal(MODALS.SOCIAL_CONFIRM_GOOGLE),
      onRemove: () => openModal(MODALS.SOCIAL_REMOVE_GOOGLE),
    }
  };

  return (
    <View>
      <Title>{translations.t('cabinet.login_settings.social_title')}</Title>
      <Body>
      {Object.values(socials).map((social, index) => social.available && (
        <Row key={index}>
          <Social background={social.active.background} active={social.isActive} onClick={social.onClick}>
            <SocialWrap>
              <SocialIcon>
                {social.isActive ? <social.icon fill={social.active.fill} fillBg={social.active.fillBg} /> : <social.icon />}
              </SocialIcon>
              <SocialName>{social.isActive ? social.name : translations.t(social.title)}</SocialName>
            </SocialWrap>

            {social.isActive &&
              <SocialRemove onClick={social.onRemove}><ICloseThin width={16} height={16} fill={Colors.white} /></SocialRemove>
            }
          </Social>
        </Row>
      ))}
      </Body>

      <SocialConfirmVk isOpen={modals[MODALS.SOCIAL_CONFIRM_VK].isOpen}
                       onCancel={closeAllModals}
                       onConfirm={onAddVkAuth}/>
      <SocialRemoveVk isOpen={modals[MODALS.SOCIAL_REMOVE_VK].isOpen}
                      onCancel={closeAllModals}
                      onConfirm={onRemoveVkAuth}/>

      <SocialConfirmGoogle isOpen={modals[MODALS.SOCIAL_CONFIRM_GOOGLE].isOpen}
                           onCancel={closeAllModals}
                           onConfirm={onAddGoogleAuth}/>
      <SocialRemoveGoogle isOpen={modals[MODALS.SOCIAL_REMOVE_GOOGLE].isOpen}
                          onCancel={closeAllModals}
                          onConfirm={onRemoveGoogleAuth}/>
    </View>
  );
};

const View = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  margin-left: -1px;
  padding: 20px;
  width: 300px;
  border-radius: 0 5px 5px 0;
  
  svg {
    display: block;
  }
`;

const Title = styled.div`
  color: ${Colors.feldgrau50};
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
`;
const Body = styled.div`
`;
const Row = styled.div`
  margin-bottom: 20px;
`;
const Social = styled.div`
  background-color: ${({ active, background }) => (active ? background : 'transparent')};
  color: ${({ active }) => (active ? Colors.white : Colors.coal)};
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  padding: 3px 6px 3px 3px;
  border-radius: 4px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;
const SocialWrap = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 6px;
`;
const SocialIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 4px;
`;
const SocialName = styled.div`
  font-size: 14px;
`;
const SocialRemove = styled.div`
  padding-left: 6px;
  position: relative;
  cursor: pointer;
  
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${Colors.white};
    opacity: 0.3;
    height: 100%;
    width: 1px;
  }
`;
