import { ON_SUCCESS_LOGIN, ON_SUCCESS_SIGNUP } from '@observer/constants';
import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.ENTRY_SCREEN_CLOSE]: {
    name: 'ScreenEntryClose',
    value: true,
    type: 'string',
    alias: 'ScreenEntryClose',
  },
};

export const events = {
  [ON_SUCCESS_LOGIN]: {
    name: 'ScreenEntrySuccess',
    payload: {},
  },
  [ON_SUCCESS_SIGNUP]: {
    name: 'ScreenEntrySuccess',
    payload: {},
  },
};
