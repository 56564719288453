import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { translations } from '@libs';
import { unsubscribe } from '../effects';
import { Analytics } from '../../../modules/analytics';

export const SubscribeCancel = ({ open = false, setOpen = () => {} }) => {
  const dispatch = useDispatch();
  const { tariff, stepsUnsubscribe } = useSelector((state) => state);
  const [stepIndex, setStepIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const expireDate = new Date(tariff.expire * 1000).toLocaleDateString('ru-RU');
  const variables = {
    expire: expireDate,
  };

  const onUnsubscribe = async () => {
    setLoading(true);
    Analytics.clickCancelSubscribeStepEnd(tariff.alias);
    const result = await dispatch(unsubscribe());

    setLoading(false);

    if (result) {
      setStepIndex(stepIndex + 1);
    } else {
      setError(true);
    }
  };
  const onNextStep = async () => {
    Analytics.clickCancelSubscribeStep(tariff.alias, stepIndex + 1);
    setStepIndex(stepIndex + 1);
  };
  const onClose = () => setOpen(false);

  const steps = stepsUnsubscribe.map(step => {
    const text = step.text.replace(/\${(.*?)}/g, (match, p1) => variables[p1]);

    const newStep = {
      ...step,
      text,
      button: {
        text: step.textButton,
        onClick: onClose,
      }
    };

    if (step.textButtonCancel) {
      newStep.spyButton = {
        text: step.textButtonCancel,
        onClick: step.hasOwnProperty('cancelSubscribe') ? onUnsubscribe : onNextStep,
      }
    }

    return newStep;
  });

  const step = steps[stepIndex];

  useEffect(() => {
    setLoading(false);
  }, [stepIndex]);

  useEffect(() => {
    setStepIndex(0);
    setError(false);
    setLoading(false);
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <View>
      <Overlay />
      <Container>
        <Content>
          <Close onClick={onClose}>
            <IClose width="38px" height="38px" fill={Colors.white} />
          </Close>

          {(loading && !error) && <Loading><Preloader width="80px" height="75px" fill={Colors.planoplan_blue} /></Loading>}
          {error && !loading && <Error dangerouslySetInnerHTML={{ __html: translations.t('cabinet.subscribe.cancel.error') }}/>}

          <Top>
            <Title>{step.title}</Title>
            {step.img && <Image src={step.img} />}
            {step.text && <Text>{step.text}</Text>}
          </Top>

          <Bottom>
            {step.spyButton && (
              <SpyButton onClick={step.spyButton.onClick}>{step.spyButton.text}</SpyButton>
            )}

            {step.button && (
              <Button theme="primary" size="M" onClick={step.button.onClick}>{step.button.text}</Button>
            )}
          </Bottom>
        </Content>
      </Container>
    </View>
  );
};

const View = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Overlay = styled.div`
  background-color: ${rgba(Colors.black, 0.4)};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  background-color: ${Colors.white};
  padding: 38px;
  border-radius: 10px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  min-height: 590px;
  position: relative;
`;
const Close = styled.button`
  ${Mixins.resetButton()};
  position: absolute;
  top: 0;
  right: -60px;

  svg {
    ${Mixins.transition('transform')};
  }

  &:hover {
    svg {
      transform: scale(0.9);
    }
  }
`;
const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${rgba(Colors.white, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Error = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: ${Colors.colar};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white};
  text-align: center;
`;
const Top = styled.div``;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;
const Title = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.coal};
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 500px;
  margin-bottom: 25px;
`;
const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.coal};
`;
const SpyButton = styled.span`
  ${Mixins.transition('color')};
  color: ${Colors.planoplan_blue};
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 15px;
  align-self: center;
  cursor: pointer;
  
  &:hover {
    color: ${Colors.electric_blue};
  }
`;
