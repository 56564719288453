import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const CloseModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Header>{translations.t('modal.close.header')}</Header>
      <Text>{translations.t('modal.close.text')}</Text>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onClose}>
          {translations.t('modal.cancel')}
        </Button>
        <Button theme="primary" size="M" onClick={onSubmit}>
          {translations.t('modal.close')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Text = styled.p`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
