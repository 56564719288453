import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { isProdHost } from '@libs';
import { reducers } from './reducers';

export const createAppStore = (initialState) => {
  return createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(thunk),
      !isProdHost && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )
  );
};
