import React, { useEffect, useContext } from 'react';
import { Colors } from 'planoplan-ui-kit/core/colors';
import styled from 'styled-components';
import { actions } from '@globalInvoke';
import { PAUSE_TRACK, RESUME_TRACK } from '@globalConstants';
import * as effects from '@features/store/modules/effects';
import { StoreContext } from '../../modules/Store';
import { PAY_STATUSES, SET_PAYMENT_STATUS, SET_SCREEN, SUCCESS_BINDING } from '@features/store/constants';
import { notifications } from '@features/notifications';
import { ON_BINDING_CARD_SUCCESS } from '@observer/constants';
import { translations } from '@libs';
import { getErrorMessage } from '../../modules/get-error-message';

const observer = new window.POPObserver();

export const Yookassa = ({ token, method, orderid, scaleFactor, returnUrl }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { settings: { locale } } = state;
  const url = new URL(window.location.href);
  const returnURL = returnUrl ? new URL(returnUrl) : '';
  const isBinging = returnURL ? returnURL.searchParams.get('store') === 'binding' : false;

  url.searchParams.append('orderid', orderid);
  url.searchParams.append('store', '1');
  url.searchParams.append('locale', locale);

  const init = () => {
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
      customization: {
        //Выбор способа оплаты для отображения
        payment_methods: [method],
        colors: {
          //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
          controlPrimary: Colors.planoplan_blue, //Значение цвета в HEX,
          controlPrimaryContent: Colors.white
        }
      },
      error_callback: function (error) {
        console.error(error);
      }
    });

    checkout.on('complete', () => {
      if (!isBinging) {
        effects.checkPayStatus(dispatch, state);
      }
    });

    checkout.on('success', () => {
      if (isBinging) {
        observer.postMessage(ON_BINDING_CARD_SUCCESS);

        dispatch({ type: SET_SCREEN, payload: SUCCESS_BINDING });
      }
    });

    checkout.on('fail', () => {
      if (isBinging) {
        dispatch({
          type: SET_PAYMENT_STATUS,
          payload: {
            status: PAY_STATUSES.wrong,
            value : getErrorMessage('', 'yookassa')
          },
        });
        notifications.showError(translations.t('store.common.payment_wrong'));
      }
    });

    checkout.render('payment-form');
  };

  useEffect(() => {
    if (!window.YooMoneyCheckoutWidget) {
      const script = document.createElement('script');

      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      script.onload = () => init();

      document.body.appendChild(script);
    } else {
      init();
    }

    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
    }
    // eslint-disable-next-line
  }, []);

  return <Wrapper id="payment-form" scaleFactor={scaleFactor}/>;
};

const Wrapper = styled.div`
  padding-top: 20px;
  width: ${(p) => 100 / p.scaleFactor}%;
  transform: scale(${(p) => p.scaleFactor});
  transform-origin: 0 0;
`;
