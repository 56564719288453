import React from 'react';
import styled from 'styled-components';

export const IconInfinity = ({ background = '#000000' }) => {
  return (
    <Icon background={background}>∞</Icon>
  )
};

const Icon = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  background: ${({ background }) => background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  pointer-events: none;
`;
