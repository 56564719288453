import React from 'react';

export const IZoom = ({ width = 24, height = 24, fill = '#D1D1D1' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.48966 17.0104L18.5105 4.98959M18.5105 4.98959H10.4897M18.5105 4.98959L18.5105 13.0104" stroke={fill}/>
      <path d="M16.5103 6.98959L4.48953 19.0104M4.48953 19.0104H12.5103M4.48953 19.0104L4.48953 10.9896" stroke={fill}/>
    </svg>
  );
};
