import { Colors } from 'planoplan-ui-kit/core/colors';

export const themes = {
    theme: {
      primary: {
        color: Colors.coal,
        input: {
          backgroundColor: Colors.white,
          borderColor: Colors.solitude,
        },
        focus: {
          borderColor: Colors.electric_blue,
          boxShadow: Colors.electric_blue,
        },
        hover: {
          borderColor: Colors.planoplan_blue,
        },
      },
      secondary: {
        color: Colors.coal,
        input: {
          backgroundColor: Colors.mint,
          borderColor: Colors.solitude,
        },
        focus: {
          borderColor: Colors.electric_blue,
          boxShadow: Colors.electric_blue,
        },
        hover: {
          borderColor: Colors.planoplan_blue,
        },
      },
    },

    size: {
      M: {
        view: {
          fontSize: '16px',
          lineHeight: '19px',
        },
        label: {
          marginBottom: '4px',
        },
        input: {
          height: '48px',
          paddingRight: '16px',
          paddingRightEye: '40px',
          paddingLeft: '16px',
        },
        message: {
          fontSize: '13px',
          lineHeight: '16px',
          marginTop: '2px',
        },
      },

      S: {
        view: {
          fontSize: '13px',
          lineHeight: '16px',
        },
        label: {
          marginBottom: '2px',
        },
        input: {
          height: '36px',
          paddingRight: '10px',
          paddingRightEye: '36px',
          paddingLeft: '10px',
        },
        message: {
          fontSize: '12px',
          lineHeight: '14px',
          marginTop: '2px',
        },
      },
    },
  };
