import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrent, setStartScene, setNameScene } from '../../modules/effects';
import { ButtonEditor } from 'planoplan-ui-kit/core/buttonEditor';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { NumberList } from '../NumberList';
import { IFlag } from '../../icons';

export const ListItem = React.forwardRef(({ panorama = {}, active = false }, ref) => {
  const dispatch = useDispatch();
  const { structure } = useSelector(state => state);
  const isStartScene = structure.startScene === panorama.id;
  const scene = structure.scenes[panorama.id];

  const handleClick = () => dispatch(setCurrent(panorama.id));
  const handleClickFlag = () => dispatch(setStartScene(panorama.id));
  const handleChangeName = (name) => dispatch(setNameScene(panorama.id, name));

  return (
    <View ref={ref} data-id={panorama.id} active={active}>
      <Preview>
        <Img src={panorama.preview} onClick={handleClick}/>

        <Flag isStart={isStartScene}>

          <ButtonEditorFlat
            onClick={handleClickFlag}
            tooltip={{
              render: translations.t('tour_editor.flag.tooltip'),
              direction: 'top',
              theme: 'editor_white',
            }}>
            <FlagIcon>
              <IFlag fill={isStartScene ? Colors.colar : Colors.nobel} />
            </FlagIcon>
          </ButtonEditorFlat>
        </Flag>
      </Preview>

      <Bottom>
        <NumberList number={panorama.index} name={scene.title} onChange={handleChangeName} />
      </Bottom>
    </View>
  );
});

const View = styled.div`
  ${Mixins.transition('border-color')};
  margin-bottom: 6px; 
  overflow: hidden;
  border: 2px solid ${({ active }) => active ? Colors.dark_cream : 'transparent'};
  
  &:hover {
    border: 2px solid ${Colors.dark_cream};
  }
`;
const Img = styled.img`
  width: 224px;
  height: ${224 / 1.75}px;
  object-fit: cover;
  display: block;
  cursor: pointer;
`;
const Flag = styled.div`
  ${Mixins.transition('opacity')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.coal, 0.5)};
  padding: 4px;
  opacity: ${({ isStart }) => isStart ? 1 : 0};
`;
const FlagIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  cursor: pointer;
`;
const Preview = styled.div`
  position: relative;
  
  &:hover {
    ${Flag} {
      opacity: 1;
    }
  }  
`;
const Bottom = styled.div`
  background: ${Colors.coal};
  padding: 5px;
`;
const ButtonEditorFlat = styled(ButtonEditor)`
  padding: 0;
  
  svg {
    fill: none;
    stroke: none;
  }
`;
