import React from 'react';

export const IconBalance = ({ width = 24, height = 24, fill = '#4F5B60' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="7" width="16" height="13" stroke={fill}/>
    <path d="M20 5H5V7H18V18H20V5Z" stroke={fill}/>
    <path d="M22 3H7V5H20V16H22V3Z" stroke={fill}/>
    <path d="M7 13C7.55228 13 8 12.5523 8 12C8 11.4477 7.55228 11 7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13Z" fill={fill} stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18 17.5455L13.625 13L7.5 20" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);
