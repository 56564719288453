import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IArrowLeftThin } from 'planoplan-ui-kit/core/icons/i-arrow-left-thin';
import { tablet_landscape } from '../../modules/breakpoints';
import { TABS } from '../../constants';
import { translations } from '@libs';

export const Tabs = ({ currentTab, onSelectTab }) => {
  return (
    <View>
      <Tab isActive={currentTab === TABS.adjustment} coloredStroke={false} onClick={onSelectTab.bind(null, TABS.adjustment)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#D1D1D1" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M2 20C2 19.4477 2.44771 19 3 19L10 19C10.5523 19 11 19.4477 11 20C11 20.5523 10.5523 21 10 21L3 21C2.44771 21 2 20.5523 2 20Z"/>
            <path d="M15 19L15 17C15 16.4477 14.5523 16 14 16C13.4477 16 13 16.4477 13 17L13 23C13 23.5523 13.4477 24 14 24C14.5523 24 15 23.5523 15 23L15 21L21 21C21.5523 21 22 20.5523 22 20C22 19.4477 21.5523 19 21 19L15 19Z"/>
            <path d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13L21 13C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11L12 11Z"/>
            <path d="M2 12C2 11.4477 2.44771 11 3 11L7 11L7 9C7 8.44772 7.44771 8 8 8C8.55228 8 9 8.44772 9 9L9 15C9 15.5523 8.55228 16 8 16C7.44771 16 7 15.5523 7 15L7 13L3 13C2.44771 13 2 12.5523 2 12Z"/>
            <path d="M15 1C15 0.447718 15.4477 2.34469e-06 16 2.44784e-06C16.5523 2.55099e-06 17 0.447718 17 1L17 3L21 3C21.5523 3 22 3.44772 22 4C22 4.55229 21.5523 5 21 5L17 5L17 7C17 7.55229 16.5523 8 16 8C15.4477 8 15 7.55229 15 7L15 1Z"/>
            <path d="M2 4C2 3.44772 2.44771 3 3 3L12 3C12.5523 3 13 3.44772 13 4C13 4.55229 12.5523 5 12 5L3 5C2.44771 5 2 4.55228 2 4Z"/>
          </g>
        </svg>
        <span>{translations.t('image_editor.tab.adjustment')}</span>
      </Tab>
    </View>
  )
};

const View = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  padding: 15px 5px;
  border-bottom: 1px solid ${Colors.coal};
`;

const Tab = styled.div`
  ${Mixins.transition('color')};
  display: flex;
  align-items: center;
  color: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.light_gray};
  cursor: pointer;
  
  svg {
    ${Mixins.transition('fill')};
    ${({ coloredStroke }) => coloredStroke ? 'stroke' : 'fill'}: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.light_gray};
    margin-right: 6px;
  }
  
  &:not(:first-child) {
    margin-left: 20px;
  }
  
  &:hover {
    color: ${Colors.dark_cream};
    
    svg {
      ${({ coloredStroke }) => coloredStroke ? 'stroke' : 'fill'}: ${Colors.dark_cream};
    }
  }
`;
