import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button, View as ButtonView } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import * as effects from '../../../modules/effects';
import { StoreContext } from '../../../modules/Store';
import { Indent } from '../../../modules/indent';
import { CART } from '../../../constants';
import { tablet_portrait, tablet_portrait_only, tablet_landscape, laptop } from '../../../modules/breakpoints';
import { getLayoutScreenCss } from '../../../modules/styles';


export const Preview = ({ proplus, team_id }) => {
  const [state, dispatch] = useContext(StoreContext);
  const init = () => {
    window.Planoplan.init({
      width: '100%',
      height: '100%',
      uid: '94b725e8807b98d8bf38afa43e8b5eea726a9ffc06a3d06754530d658a010372',
      el: 'pop-widget',
    });
  };

  const addTariffCart = () => {
    try {
      const id = proplus.product.store_id;

      effects.addToCart(dispatch, state, id);
      effects.setScreen(dispatch, state, CART);
    } catch (error) {
      console.error(new Error(error));
    }
  };

  useEffect(() => {
    if (window.Planoplan) {
      init();
      return;
    }

    const script = document.createElement('script');

    script.src = 'https://widget.planoplan.com/etc/multiwidget/release/v4/static/js/main.js';
    script.async = true;
    script.onload = () => init();

    document.body.appendChild(script);
  }, []);
  return (
    <View data-test="widget">
      <Widget id="pop-widget" />
      <Info>
        <Description>
          <Title>{translations.t('store.widget.title')}</Title>
          <Text
            dangerouslySetInnerHTML={{ __html: translations.t('store.widget.description') }}
            data-test="widget__description"
          />
        </Description>
        <Banner>
          <span>
            {translations.t('store.widget.available')} <br /> {proplus.caption}
          </span>
          <Button
            theme="primary"
            size="S"
            onClick={addTariffCart}
            data-test="widget__go-over">
            {translations.t('store.common.go_over')}
          </Button>
        </Banner>
      </Info>
    </View>
  );
};

const View = styled.div`
  ${getLayoutScreenCss(2, 1)};
  flex-direction: column;

  @media ${tablet_landscape} {
    flex-direction: row;
  }
`;

const Widget = styled.div`
  background-color: ${Colors.white};
  height: 400px;

  @media ${tablet_portrait} {
    height: 500px;
  }

  @media ${tablet_landscape} {
    ${Grid.column(16, 30)};
    height: 580px;
  }

  @media ${laptop} {
    ${Grid.column(13, 22)};
  }
`;

const Info = styled.div`
  ${Indent.padding_top(2)};
  color: ${Colors.coal};

  @media ${tablet_portrait_only} {
    ${Grid.row};
    align-items: flex-start;
  }

  @media ${tablet_landscape} {
    ${Grid.column(13, 30)};
    ${Grid.push(1, 30)};
    ${Indent.padding_top(1)};
  }

  @media ${laptop} {
    ${Grid.column(8, 22)};
    ${Grid.push(1, 22)};
  }
`;

const Description = styled.div`
  @media ${tablet_portrait_only} {
    ${Grid.column(15.5, 30)};
    ${Grid.post(1, 30)};
  }
`;

const Title = styled.p`
  ${Indent.margin_bottom(2)};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

const Text = styled.div`
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const Banner = styled.div`
  ${Indent.margin_top(2)};
  padding: 24px;
  background-color: ${Colors.white};
  border-radius: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  @media ${tablet_portrait_only} {
    ${Grid.column(13.5, 30)};
    ${Indent.margin_top(0)};
  }

  @media ${tablet_landscape} {
    ${Indent.margin_top(2)};
  }

  ${ButtonView} {
    ${Indent.margin_top(1)};
  }
`;
