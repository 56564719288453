import React from 'react';
import styled from 'styled-components';
import { IErrorCircle } from 'planoplan-ui-kit/core/icons/i-error-circle';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Message = ({ children }) => (
  <View>
    <Text>{children}</Text>

   <IErrorCircle width="228px" height="228px" stroke={Colors.colar} />
  </View>
);

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  font-size: 22px;
  line-height: 26px;
  color: ${Colors.coal};
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;
