import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Checkbox } from 'planoplan-ui-kit/core/checkbox';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { translations } from '@libs';
import { Analytics } from '../../modules/analytics';
import { notifications } from '@features/notifications';
import * as effects from './effects';
import { SET_SUBSCRIPTIONS } from '../../constants';
import { rail_y, thumb_y } from '../../components/scroll';
import { Input } from '../../components/Input';
import { Textarea } from '../../components/TextArea';
import { Avatar } from './organisms/avatar';
import { FileInput } from './organisms/file-input';
import { Confirm } from '../balance/organism/confirm';

export const PageProfile = () => {
  const dispatch = useDispatch();
  const {
    profile: { avatar, logo, id, name, email, about },
    subscriptions,
    loading,
  } = useSelector((state) => state);

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [fields, setFields] = useState({ name: name, email: email, about: about });
  const [file, setFile] = useState([]);
  const [fileLogo, setFileLogo] = useState([]);
  const [tempAvatar, setTempAvatar] = useState('');
  const [tempLogo, setTempLogo] = useState('');
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);

  const onAvatarSelect = (e) => {
    const { files, accept } = e.target;
    dispatch(effects.checkFileByFormat(files[0], accept));

    const reader = new FileReader();

    reader.onload = (e) => setTempAvatar(e.target.result);
    reader.readAsDataURL(files[0]);

    setFile([files[0], files[0].name]);
    setRemoveAvatar(false);
  };
  const onAvatarDelete = (event) => {
    event.preventDefault();

    setTempAvatar('DELETED');
    setFile([]);
    setRemoveAvatar(true);
  };
  const onLogoSelect = (e) => {
    const { files, accept } = e.target;
    dispatch(effects.checkFileByFormat(files[0], accept));

    const reader = new FileReader();

    reader.onload = (e) => setTempLogo(e.target.result);
    reader.readAsDataURL(files[0]);

    setFileLogo([files[0], files[0].name]);
    setRemoveLogo(false);
  };
  const onLogoDelete = (event) => {
    event.preventDefault();

    setTempLogo('DELETED');
    setFileLogo([]);
    setRemoveLogo(true);
  };
  const onChangeField = ({ name, value }) => {
    setFields((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const toggle = ({ event }) => {
    subscriptions[event.target.id].active = event.target.checked;
    dispatch({ type: SET_SUBSCRIPTIONS, payload: subscriptions });
  };
  const activeSubscriptions = Object.values(subscriptions).reduce((acc, item) => {
    if (item.active) {
      acc.push(item.alias);
    }
    return acc;
  }, []);
  const deleteUserHandler = (e) => {
    e.preventDefault();
    dispatch(effects.deleteUser());
    setConfirmIsOpen(false);
  };
  const onDataSave = (e) => {
    e.preventDefault();

    const { name, email, about } = fields;
    const request = {
      name,
      email,
      about,
      avatar: file[0],
      logo: fileLogo[0],
    };

    if (!name) {
      notifications.showError(translations.t('cabinet.notification.validation.name'));

      return;
    }

    if (removeAvatar) {
      request['remove_avatar'] = 1;
      request['avatar'] = '';
    }

    if (removeLogo) {
      request['remove_logo'] = 1;
      request['logo'] = '';
    }

    dispatch(effects.editSubscriptions(activeSubscriptions.join('&notifications[]=')));
    dispatch(effects.editUser(request));
  };
  const onCopyUserId = () => {
    copy(id);
    notifications.showSuccess(translations.t('cabinet.notification.copyUserId'));
  };

  useEffect(() => {
    Analytics.viewProfile();
  }, []);

  useEffect(() => {
    setFields({ name, email, about });
  }, [name, email, about]);

  if (loading && loading.processing) {
    return (
      <Loader>
        <Preloader width="80px" height="80px" fill={Colors.planoplan_blue} />
      </Loader>
    );
  }

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <Content>
          <Column>
            <ProfileForm>
              <Row>
                <AvatarContainer>
                  <Label>{translations.t('cabinet.profile.avatar')}</Label>
                  <FlexWrapper>
                    <Avatar onDelete={onAvatarDelete} src={tempAvatar || avatar} cover={true} />
                    <UserIdContainer>
                      <UserId>
                        User ID:
                        <br />
                        {id}
                      </UserId>
                      <CopyBtn onClick={onCopyUserId}>copy</CopyBtn>
                    </UserIdContainer>
                  </FlexWrapper>
                  <FileInput
                    type="file"
                    id="avatar"
                    accept=".jpg, .jpeg, .png, .svg, .webp"
                    onChange={onAvatarSelect}
                    desc={translations.t('cabinet.avatar.recommended_size') + ' 400х400px'}
                  />
                </AvatarContainer>
              </Row>
              <Row>
                <Input onChange={onChangeField} id="name" name="name" value={fields.name} isProfile={true}>
                  {translations.t('cabinet.profile.name')}
                </Input>
              </Row>
              <Row>
                <Input onChange={onChangeField} id="email" name="email" value={fields.email} isProfile={true} disabled={true}>
                  {translations.t('cabinet.email')}
                </Input>
              </Row>
              <Row>
                <Textarea
                  theme="primary"
                  name="about"
                  minRows={11}
                  maxRows={11}
                  onChange={onChangeField}
                  value={fields.about}>
                  {translations.t('cabinet.yourself')}
                </Textarea>
              </Row>
            </ProfileForm>
            <SaveButton>

              <Button theme="primary" size="M" onClick={onDataSave}>
                <span>{translations.t('cabinet.save_button')}</span>
              </Button>
            </SaveButton>
          </Column>
          <Column>
            <LogoProject>
              <Label>{translations.t('cabinet.profile.mybranding')}</Label>
              <FlexWrapper>
                <Avatar src={tempLogo || logo} isLogo={true} cover={false} width={380} height={80} onDelete={onLogoDelete} />
              </FlexWrapper>
              <FileInput
                type="file"
                id="logo"
                accept=".jpg, .jpeg, .png, .svg, .webp"
                onChange={onLogoSelect}
                desc={translations.t('cabinet.avatar.recommended_size') + ' 760х140px'}
              />
            </LogoProject>
            <Subscriptions>
              <Label>{translations.t('cabinet.subscriptions.title')}</Label>
              {subscriptions &&
                Object.values(subscriptions).map((item, index) => {
                  if (index <= 1) {
                    return (
                      <CheckboxWrap key={item.alias}>
                        <Checkbox
                          id={item.alias}
                          theme="primary_editor"
                          size="S"
                          onChange={toggle}
                          checked={item.active}>
                          <CheckboxLabel>{item.name}</CheckboxLabel>
                        </Checkbox>
                        <Text>{item.description}</Text>
                      </CheckboxWrap>
                    );
                  }
                })}
            </Subscriptions>
            <DeleteProfileSection>
              <DeleteProfileButton>
                <Link onClick={() => setConfirmIsOpen(true)}>{translations.t('cabinet.profile.delete')}</Link>
              </DeleteProfileButton>
              {translations.t('cabinet.profile.remove.text')}
            </DeleteProfileSection>
          </Column>
        </Content>
        {confirmIsOpen && (
          <Confirm
            onAgree={deleteUserHandler}
            onCancel={() => {
              setConfirmIsOpen(false);
            }}
            title={translations.t('cabinet.profile.delete.confirm.text')}
            cancelText={translations.t('cabinet.balance.confirm.cancel')}
            aggreeText={translations.t('cabinet.balance.confirm.agree')}
          />
        )}
      </View>
    </ScrollBar>
  );
};

const View = styled.div`
  position: relative;
`;
const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.timberwolf25};
  opacity: 0.8;
  z-index: 3;
  display:flex; 
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  display: flex;
  overflow: hidden;
  padding: 20px;
`;
const Column = styled.div``;
const Row = styled.div`
  margin-bottom: 20px;
`;
const ProfileForm = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-width: 1px 1px 0 1px;
  border-radius: 5px 0 0 0;
  padding: 20px;
  width: 360px;
  height: 715px;
`;
const AvatarContainer = styled.div`
  width: 320px;
  height: 217px;
`;
const Label = styled.div`
  margin: 0 0 4px 4px;
`;
const CheckboxWrap = styled.div`
  margin: 0 0 8px 0;
`;
const CheckboxLabel = styled.div`
  margin: 0 0 0 4px;
`;
const FlexWrapper = styled.div`
  display: flex;
`;
const UserIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 150px;
  background: ${Colors.timberwolf50};
  border-radius: 0 5px 5px 0;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: auto;
  text-align: center;
`;
const UserId = styled.div`
  margin-top: 14px;
`;
const CopyBtn = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: ${Colors.blueBell};
  cursor: pointer;
  margin: 8px 0 10px 0;

  &:hover {
    color: ${Colors.planoplan_blue};
  }
`;
const SaveButton = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-radius: 0 0 5px 5px;
  padding: 20px;
  width: 360px;
  height: 80px;
`;
const LogoProject = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-width: 1px 1px 0 0;
  border-radius: 0 5px 0 0;
  padding: 20px;
  width: 420px;
  height: 186px;
`;
const Subscriptions = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  padding: 20px;
  border-width: 1px 1px 0 0;
  width: 420px;
`;
const Text = styled.div`
  font-size: 12px;
  color: ${Colors.feldgrau50};
  margin-top: 4px;
`;
const DeleteProfileSection = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-width: 1px 1px 1px 0;
  border-radius: 0 0 5px 0;
  font-size: 16px;
  color: ${Colors.feldgrau};
  padding: 20px;
  width: 420px;
  height: 136px;
`;
const DeleteProfileButton = styled.div`
  margin-bottom: 20px;
`;
const Link = styled.a`
  font-size: 16px;
  color: ${Colors.planoplan_blue};
  cursor: pointer;

  &:hover {
    color: ${Colors.electric_blue};
  }
`;
