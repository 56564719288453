import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Link } from '@components/link';
import { UrlButton } from '@components';
import { translations } from '@libs';
import { setLastElementNotification } from '../modules/utils';
import { ERROR_TYPES } from '../constants';
import { rail_y, thumb_y } from './scroll';

export const Notification = ({
  notifications = [],
  onSave = () => {},
  onDeleteAll = () => {},
  onClick = () => {},
  errors = {},
  mode = '',
}) => {
  const scrollBarEl = useRef(null);
  // тротл не работает внутри реакт компонента
  const setLastElement = () => setLastElementNotification(scrollBarEl, notifications, onSave);

  const onScrollY = () => setLastElement();

  useEffect(() => {
    setLastElement();
    // eslint-disable-next-line
  }, []);

  if (errors[ERROR_TYPES.NOTIFICATION]) {
    return (
      <View data-test="notification_error">
        <Error>{translations.t('user_widget.notification.error')}</Error>
      </View>
    );
  }

  if (!notifications.length) {
    return (
      <View data-test="notification_not-new">
        <Not>{translations.t('user_widget.notification.not')}</Not>
      </View>
    );
  }

  return (
    <View>
      <ScrollBar
        options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }}
        rail_y={rail_y}
        thumb_y={thumb_y}
        onScrollY={onScrollY}
        getRef={(ref) => (scrollBarEl.current = ref)}>
        <List>
          {notifications.map((item) => (
            <UrlButton key={item.id || item.time} href={item.href} onClick={() => onClick(item)} mode={mode} target="_blank">
              <Item>
                <Image src={item.image} />
                <Content>
                  <Header>{item.caption}</Header>
                  <Time>{item.time}</Time>
                </Content>
              </Item>
            </UrlButton>
          ))}
        </List>
      </ScrollBar>

      <Bottom>
        <Link fontSize="inherit" onClick={onDeleteAll}>
          {translations.t('user_widget.notification.deleteAll')}
        </Link>
      </Bottom>
    </View>
  );
};

const View = styled.div`
  position: absolute;
  right: 0;
  margin-top: 45px;
  color: ${Colors.coal};
  overflow: hidden;
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 0 34px ${rgba(Colors.coal, 0.13)}, -1px 1px 2px ${rgba(Colors.coal, 0.2)};
  width: 270px;
`;

const List = styled.ul`
  ${Mixins.resetList()};
  max-height: 380px;
  padding: 5px 0;
`;

const Item = styled.a`
  ${Mixins.resetLink()};
  ${Mixins.resetButton()};
  ${Mixins.transition('border-color')};
  display: flex;
  width: 100%;
  padding: 5px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-bottom-color: ${Colors.whisper};

  &:hover {
    border-color: ${Colors.planoplan_blue};
  }
`;

const Image = styled.div`
  width: 96px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${Colors.solitude};
  background-image: ${(p) => (p.src ? `url(${p.src})` : 'none')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  margin-left: 10px;
`;

const Header = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-top: 3px;
`;

const Time = styled.time`
  font-size: 11px;
  line-height: 13px;
  color: ${Colors.nobel};
  font-weight: 300;
`;

const Bottom = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Not = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.nobel};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 28px;
`;

const Error = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.colar};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 28px;
  border: 1px solid ${Colors.colar};
  border-radius: 4px;
`;
