import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { UrlButton } from '@components';
import { Modal } from '@components/Modal';
import { Header } from '@components/Modal/atoms';
import { translations } from '@libs';
import { TARIFF_ALIAS } from '@globalConstants';
import { routeInVideo } from '../../modules/effects';
import { normalizeDescriptionTariffs } from '../../modules/normalize';
import { BACKGROUND_TARIFFS, TARIFFS } from '../../constants';
import { PriceInfo } from '../Price-Info';
import { ON_CLOSE_CABINET } from '@observer/constants';

const observer = new window.POPObserver();

export const BannerModal = ({ locale, banner, tariffsItems, pricing, mode, zIndex, isOpen, onClose, onClick, action = {} }) => {
  const { url = '', image, caption, description, button_title, video } = banner;
  const { arg, name } = action;
  const urlObj = url && new URL(url);
  const linkToCabinet = url ? urlObj.pathname.search('/cabinet') !== -1 : false;
  const tariff = url ? urlObj.searchParams.get('tariff') : '';
  const {
    form_data: { raw_description },
  } = tariffsItems[tariff ? tariff : TARIFF_ALIAS.PROPLUS];
  const data = normalizeDescriptionTariffs(raw_description);

  const { currency } = pricing;
  const goods = (pricing && pricing[0]) ? pricing[0].goods.filter((item) => item.duration === '12 months') : null;
  let theme = 'primary';

  switch (tariff) {
    case TARIFF_ALIAS.PROPLUS: theme = 'violet'; break;
    case TARIFF_ALIAS.PRO: theme = 'orange'; break;
    case TARIFF_ALIAS.BUSINESS: theme = 'red'; break;
    default: theme = 'primary';
  }

  const onRouteInVideo = () => {
    routeInVideo(video);
  };

  const doAction = () => {
    window.invokeEditorAction({
      name: name,
      value: arg,
    });

    if (name === 'OpenProject') {
      observer.postMessage(ON_CLOSE_CABINET);
    }

    onClose('banners');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} zIndex={zIndex}>
      <Header>{caption}</Header>

      <Image onClick={onRouteInVideo} video={video}>
        <img src={image} alt={description}/>
      </Image>

      <Text>{description}</Text>

      {tariff && (
        <>
          <BannerTitle>
            {translations.t('banners.title.start')}{' '}
            <Tariff color={BACKGROUND_TARIFFS[tariff].color}>{TARIFFS[tariff]}</Tariff>
            {translations.t('banners.title.end')}
          </BannerTitle>

          <BannerSubscriptionList>
            {data.map((description, index) => {
              const Icon = description.icon;

              return (
                <SubscriptionItem key={index}>
                  <SubscriptionIcon>
                    <Icon
                      width={24}
                      height={24}
                      fill={BACKGROUND_TARIFFS[tariff].color}
                      background={BACKGROUND_TARIFFS[tariff].background}
                    />
                  </SubscriptionIcon>
                  {description.text}
                </SubscriptionItem>
              );
            })}
          </BannerSubscriptionList>
          {goods &&
            <PriceInfo goods={goods} tariff={tariff} currency={currency} locale={locale}/>
          }
        </>
      )}

      <UrlButton href={linkToCabinet ? '' : url} onClick={() => onClick(url)} mode={mode}>
        <Button theme={theme} size="M">
          {button_title}
        </Button>
      </UrlButton>

      {arg && (
        <UrlButton
          href={''}
          onClick={doAction}
          mode={mode}>
          <ButtonOpenProject>{action.caption}</ButtonOpenProject>
        </UrlButton>
      )}
    </Modal>
  );
};

const Image = styled.div`
  margin-top: 25px;
  filter: brightness(1);
  &:hover {
    filter: ${(p) => (p.video ? 'brightness(1.2)' : 'brightness(1)')};
  }
`;

const Text = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BannerTitle = styled.div`
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  font-size: 16px;
`;

const Tariff = styled.span`
  color: ${(p) => p.color};
`;

const BannerSubscriptionList = styled.ul`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.night};
  font-family: 'Museo Sans Cyrl';
  list-style: none;
  margin: 25px 0 25px 0;
  padding: 0;
`;

const SubscriptionItem = styled.li`
  font-size: 16px;
  line-height: 17px;
  color: ${Colors.night};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;

const SubscriptionIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonOpenProject = styled.div`
  margin-top: 0;
  padding: 0 15px;
  overflow: visible;
  background-color: transparent;
  font: inherit;
  height: 48px;
  font-size: 18px;
  line-height: 22px;
  color: #7B31F6;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 500;
`;
