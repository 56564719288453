import { IS_TEST } from '@globalConstants';
import {
  SET_GOODS,
  SET_TARIFF,
  SET_USER,
  SET_TEAMS,
  SET_USER_SETTINGS,
  SET_IS_PROCESSING,
  SET_ERROR,
  SET_CURRENT_TEAM,
  SET_NOTIFICATIONS,
  SET_VIEW,
} from '../../constants';

export const startProcessing = () => ({ type: SET_IS_PROCESSING, payload: true });
export const endProcessing = () => ({ type: SET_IS_PROCESSING, payload: false });
export const setIsProcessing = (state) => ({ type: SET_IS_PROCESSING, payload: state });

export const setView = (view = '') => ({ type: SET_VIEW, payload: view });

export const setTariff = (tariff = {}) => ({ type: SET_TARIFF, payload: tariff });

export const setGoods = (goods = []) => ({ type: SET_GOODS, payload: goods });

export const setUser = (user) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SET_USER, payload: { ...state.user, ...user } });
};

export const setUserTeam = (team) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SET_USER, payload: { ...state.user, team } });
};

export const setTeams = (teams = []) => ({ type: SET_TEAMS, payload: teams });

export const setUserSettings = (settings) => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: SET_USER_SETTINGS, payload: { ...state.userSettings, ...settings } });
};

export const setCurrentTeam = (team) => ({ type: SET_CURRENT_TEAM, payload: team });

export const setNotifications = (notifications = []) => ({ type: SET_NOTIFICATIONS, payload: notifications });

export const setError = ({ type = '', error = '' }) => (dispatch, getState) => {
  const state = getState();

  const getErrorText = () => (typeof error === 'string' && error ? error : 'Error in user widget');
  const getErrorObjectMessage = () => (typeof error === 'object' && error.message ? error.message : '');

  const message = getErrorObjectMessage() || getErrorText();

  dispatch({ type: SET_ERROR, payload: { ...state.errors, [type]: message } });

  if (!IS_TEST) {
    console.error('User widget error:', message);
  }
};
