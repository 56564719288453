import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Link } from '@components/link';
import { declOfNum, translations } from '@libs';
import { UrlButton } from '@components';
import { ERROR_TYPES } from '../constants';

export const License = ({ tariff = {}, user = {}, errors = {}, mode = 'site', locale = 'en', isTeam = false }) => {
  const showCloudSize = (user.user.cloudSize && !isTeam) || (user.team.cloudSize && isTeam);
  const showLicenseCount = user.team.count && isTeam;
  const cloudValue = isTeam ? user.team.cloudSize : user.user.cloudSize;
  const licenseCount = user.team.count;

  if (errors[ERROR_TYPES.GOODS]) {
    return (
      <View>
        <Error>
          {translations.t('user_widget.wrong')}
          <div>
            {translations.t('user_widget.license.error')}&nbsp;
            <Link href="mailto:support@planoplan.com" fontSize="inherit">
              support@planoplan.com
            </Link>
          </div>
        </Error>
      </View>
    );
  }

  return (
    <View>
      <TariffName>
        {tariff.isBlocked ? (
          <span data-test="tariff-blocked">{translations.t('user_widget.blocked')}</span>
        ) : (
          <span data-test="tariff-name">{tariff.name}</span>
        )}
        &nbsp;
        {showLicenseCount && (
          <span data-test="tariff-count">
            {licenseCount}{' '}
            {declOfNum(licenseCount, [
              translations.t('user_widget.license.one'),
              translations.t('user_widget.license.many'),
              translations.t('user_widget.license.not'),
            ])}
          </span>
        )}
      </TariffName>

      {tariff.alias === 'start' && (
        <Upgrade data-test="upgrade-tariff">
          <UrlButton mode={mode} locale={locale} href="/cabinet/tariff-managment">
            <Button theme="yellow_black" size="S">
              {translations.t('user_widget.upgrade')}
            </Button>
          </UrlButton>
        </Upgrade>
      )}

      {tariff.expire && (
        <Info data-test="info">
          <Item>
            <Heading>{translations.t('user_widget.active')}</Heading>
            <Value>{tariff.expire}</Value>
          </Item>
          {showCloudSize && (
            <Item data-test="cloud">
              <Heading>{translations.t('user_widget.free')}</Heading>
              <Value data-test="cloud-value">{cloudValue}</Value>
            </Item>
          )}
        </Info>
      )}
    </View>
  );
};

const View = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${Colors.dark_cream};
  box-shadow: 0 0 34px ${rgba(Colors.coal, 0.13)}, -1px 1px 2px ${rgba(Colors.coal, 0.2)};
  padding: 14px 18px 18px 18px;
  width: 270px;
  margin-bottom: 5px;
`;

const TariffName = styled.div``;

const Info = styled.div`
  margin-top: 10px;
  display: flex;
`;

const Upgrade = styled.div`
  margin-top: 10px;
`;

const Item = styled.div`
  flex: 0 0 50%;
`;

const Heading = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 2px;
  color: ${Colors.nobel};
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 17px;
`;

const Error = styled.div`
  margin-right: -15px;

  div {
    margin-top: 15px;
    font-size: 12px;
    line-height: 14px;
    color: ${Colors.nobel};
  }
`;

Error.displayName = 'Error';
