import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Link } from '@components/link';
import { translations } from '@libs';
import { ENTRY_STEPS, YOUTUBE_VIDEO } from '../../constants';
import { ButtonAction, Header } from '../../components';

export const Download = ({ setStep, onDownloadStandalone, standaloneLink = '' }) => {
  return (
    <>
      <Header title={translations.t('form_entry.download')} />

      <Video
        src={YOUTUBE_VIDEO}
        loading="lazy"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />

      <List
        dangerouslySetInnerHTML={{
          __html: translations.t('form_entry.download.list'),
        }}
      />

      <ButtonHave>
        <Link onClick={() => setStep(ENTRY_STEPS.WELCOME)}>{translations.t('form_entry.haveEditor')}</Link>
      </ButtonHave>

      <ButtonAction href={standaloneLink} top="20px" onClick={onDownloadStandalone}>
        {translations.t('form_entry.download.button')}
      </ButtonAction>
    </>
  );
};

const Video = styled.iframe`
  width: 100%;
  height: 190px;
`;
const List = styled.ul`
  ${Mixins.resetList()};
  margin-top: 20px;
  font-size: 16px;
  line-height: 19px;
`;
const ButtonHave = styled.div`
  margin-top: auto;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;
