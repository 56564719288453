import React from 'react';
import styled from 'styled-components';
import { Preloader, PreloaderView } from 'planoplan-ui-kit/core/preloader';
import MediaQuery from 'react-responsive';
import { tablet_landscape } from '../../modules/breakpoints';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { rgba } from 'polished';

export const Loader = ({ overlay }) => (
  <View overlay={overlay}>
    <MediaQuery query={tablet_landscape}>
      {(matches) => (
        <Preloader
          width={matches ? '100px' : '70px'}
          height={matches ? '100px' : '70px'}
          fill={Colors.planoplan_blue}
        />
      )}
    </MediaQuery>
  </View>
);

const View = styled.div`
  ${Mixins.transition('background-color')};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${(p) => (p.overlay ? rgba(Colors.light_gray, 0.6) : 'transparent')};
  z-index: 100;
  pointer-events: none;

  ${PreloaderView} {
    position: relative;
    top: -20px;
  }
`;
