import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const ExpiredTeamModal = ({ isOpen, onCancel = () => {}, onWriteEmail = () => {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('cabinet.team.expired.title')}</Header>
      <Text dangerouslySetInnerHTML={{__html: translations.t('cabinet.team.expired')}} />
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={onWriteEmail}>
          {translations.t('modal.write')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Text = styled.p`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
