export const initialState = {
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  structure: {},
  krpano: null,
  panoramas: [],
  current: {},
  progressAutoSave: false,
  settings: {},
  hotspotIcons: [],
  lastHotspotIcon: ''
};
