import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Radio, View as RadioView } from 'planoplan-ui-kit/core/radio';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { translations } from '@libs';
import { ButtonAction, Field, Form, Header, InputField, InputFieldView } from '../../components';
import { analytics } from '../../modules/analytics';
import { WELCOME_SUB_STEPS, NAME_CODE_REGEXP, SIGN_UP_MODE, LOGIN_MODE } from '../../constants';
import { tablet_landscape } from '../../modules/breakpoints';
import { useSelector } from 'react-redux';
import { shuffle } from '../../modules/helpers';
import { rail_y, thumb_y } from '../../components/scroll';

export const Welcome = ({ isProcessing, onWelcome, signUpMode, loginMode }) => {
  const { profile: { name }} = useSelector((state) => state);
  const [subStep, setSubStep] = useState(WELCOME_SUB_STEPS.first);
  const [fields, setFields] = useState({
    name: {
      value: '',
      error: '',
    },
  });
  const [inputChanged, setInputChanged] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({
    id: 0,
    title: '',
    checkbox: [],
  });
  // показываем инпут только после регистрации на первом шаге и если нет имени.
  const isShowInputField = subStep === WELCOME_SUB_STEPS.second && !name;
  const isDisableButton = subStep === WELCOME_SUB_STEPS.first ? !inputChanged : !inputChanged || (!name && (fields.name.error || !fields.name.value));

  const onChange = (item, groupId) => {
    setInputChanged(!item.isInput);
    setFields({ ...fields, [groupId]: { ...item, value: item.title } });
  };
  const onChangeOther = (value, groupId) => {
    const field = {
      ...fields,
      [groupId]: {
        ...fields[groupId], // берем все параметры поля, и добавляем к нему value
        value,
      },
    };
    setFields(field);
    setInputChanged(Boolean(value));
  };
  const onChangeName = ({ value, error }) => setFields({ ...fields, name: { value, error } });
  const onSubmit = (e) => {
    e.preventDefault();

    if (subStep === WELCOME_SUB_STEPS.first) {
      let mode = signUpMode;

      if (signUpMode !== SIGN_UP_MODE.EMAIL) {
        mode = signUpMode;
      } else if (loginMode !== LOGIN_MODE.EMAIL) {
        mode = loginMode;
      }

      analytics.pushEvent('sign-up-welcome', 'sent', mode);
      setInputChanged(false);
      setSubStep(WELCOME_SUB_STEPS.second);
    } else {
      onWelcome(fields);
    }
  };

  useEffect(() => {
    try {
      const questions = translations.json('form_entry.welcome.questions');
      const anotherQuestion = questions[subStep].checkbox.find(question => question.name === 'other');
      const randomQuestions = shuffle(questions[subStep].checkbox.filter(question => question.name !== 'other' && question.display === true));

      setCurrentGroup({
        ...questions[subStep],
        checkbox: [...randomQuestions, anotherQuestion]
      });
    } catch (error) {
      console.error(error);
    }
  }, [subStep]);

  useEffect(() => {
    let mode = signUpMode;

    if (signUpMode !== SIGN_UP_MODE.EMAIL) {
      mode = signUpMode;
    } else if (loginMode !== LOGIN_MODE.EMAIL) {
      mode = loginMode;
    }

    analytics.pushView(`Форма регистрации - ${mode} - welcome`, 'sign-up-welcome', 'view', mode);
  }, []);

  return (
    <View>
      <Header title={currentGroup.title} />
      <FormWrap>
        <Form style={{ overflow: 'hidden', height: '100%' }}>
          {isShowInputField && (
            <Field>
              <InputField
                name="name"
                placeholder={translations.t('form_entry.welcome.placeholder')}
                value={fields.name.value}
                hasError={fields.name.error}
                type="text"
                onChange={onChangeName}
                onBlur={onChangeName}
                validation={{
                  regexp: RegExp(NAME_CODE_REGEXP),
                  errorValid: translations.t('form_entry.check.name'),
                  errorEmpty: translations.t('form_entry.email.empty'),
                }}
                required>
                {translations.t('form_entry.welcome.name')}
              </InputField>
            </Field>
          )}

          <Scroll>
            <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
              <Field>
                <Group>
                  {currentGroup.checkbox.map((item) => (
                    <Radio
                      key={`${item.name}_${item.id}`}
                      id={`${item.name}_${item.id}`}
                      name={currentGroup.id}
                      theme="primary"
                      size="S"
                      onChange={() => onChange(item, currentGroup.id)}>
                      <span>{item.title}</span>
                    </Radio>
                  ))}
                  {fields[currentGroup.id] && fields[currentGroup.id].isInput && (
                    <InputField
                      placeholder={translations.t('form_entry.welcome.option')}
                      type="text"
                      onChange={({ value }) => onChangeOther(value, currentGroup.id)}
                    />
                  )}
                </Group>
              </Field>
            </ScrollBar>
          </Scroll>

        </Form>
      </FormWrap>

      <ButtonAction
        type="submit"
        loading={isProcessing}
        disabled={isDisableButton}
        useButtonLoader
        onClick={onSubmit}>
        {translations.t('form_entry.continue')}
      </ButtonAction>
    </View>
  );
};

const View = styled.div`
  height: 545px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const FormWrap = styled.div`
  overflow: hidden;
`;
const Scroll = styled.div`
  overflow: hidden;
  height: 100%;
`;
const Title = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 5px;

  @media ${tablet_landscape} {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  span {
    font-size: 14px;
    line-height: 17px;
    margin-left: 8px;
    margin-right: 2px;
  }

  ${RadioView} {
    margin-top: 16px;
    flex: 0 0 50%;
  }

  ${InputFieldView} {
    margin-top: -27px;
    width: 100%;
  }
`;
