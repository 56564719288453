import React from 'react';
import { useSelector } from 'react-redux';
import { CURRENCY } from '../../constants';

export const DisplayPrice = ({ children, wrapCurrency = false, }) => {
  const { profile: { currency, viewCurrency }} = useSelector(state => state);
  const isFirstCurrency = currency !== CURRENCY.RUB;

  return (
    <>
      {isFirstCurrency && (wrapCurrency ? <span>{viewCurrency}</span> : viewCurrency)}
      {children}
      {!isFirstCurrency && (wrapCurrency ? <span>{viewCurrency}</span> : viewCurrency)}
    </>
  )
};
