import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { GlobalStyles } from './modules/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as getData from './modules/getdata';
import { onNextSlide, onPrevSlide } from './modules/effects';
import { Close } from './components/Close';
import { Arrow } from './components/Arrow';
import { Slide } from './components/Slide';
import { Loader } from './components/Loader';
import { Message } from './components/Message';

export default () => {
  const dispatch = useDispatch();
  const { slides, current, loading: { processing, error, firstLoading }} = useSelector(store => store);
  const isShowScreen = (!processing || !firstLoading) && !error;
  const [isVisibleArrow, setIsVisibleArrow] = useState({
    next: false,
    prev: false,
  });

  const onNext = () => dispatch(onNextSlide());
  const onPrev = () => dispatch(onPrevSlide());

  useEffect(() => {
    const max = slides.length - 1;
    const min = 0;
    const index = slides.indexOf(current);

    setIsVisibleArrow({
      next: index < max,
      prev: index > min,
    })
  }, [current]);

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());
      await dispatch(getData.getSlides());
    })();
  }, []);

  return (
      <>
        <GlobalStyles />
        <Container id="store_container">
          <Wrapper>

            <Content>
              {isVisibleArrow.prev && <SideLeft><Arrow onClick={onPrev} direction="prev" disabled={false} /></SideLeft>}
              {isVisibleArrow.next && <SideRight><Arrow onClick={onNext} direction="next" disabled={false} /></SideRight>}

              <Controls>
                <Close />
              </Controls>

              {processing && !error && <Loader overlay={!firstLoading} />}
              {error && <Message>{error.message}</Message>}
              {isShowScreen && <Slide />}
            </Content>

          </Wrapper>
        </Container>
      </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  background-color: ${rgba(Colors.black, 0.4)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 998;
  user-select: none;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1024px;
  max-height: 720px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 5px;
  position: relative;
  background-color: ${Colors.night};
  color: ${Colors.white};
`;
const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  right: auto;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
`;
const Side = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  display: flex; 
  align-items: center;
`;
const SideLeft = styled(Side)`
  right: 100%;
  margin-right: 10px;
`;
const SideRight = styled(Side)`
  left: 100%;
  margin-left: 10px;
`;
