import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { StatusLayout } from '../layouts/status';
import { IconError } from '@icons';
import { translations } from '@libs';
import { ID_PAY_METHOD_PAYPAL } from '../constants';

export const ErrorScreen = memo(({
  title = translations.t('quickstore.pay.error.title'),
  text = translations.t('quickstore.pay.error.text')
}) => {
  const { selected: { paymentMethod }, settings: { onClose }} = useSelector(state => state);

  useEffect(() => {
    if (paymentMethod && `${paymentMethod.id}` === `${ID_PAY_METHOD_PAYPAL}`) {
      setTimeout(() => {
        onClose();
      }, 10000);
    }
  }, []);


  return (
    <StatusLayout title={title}
                  text={text}>
      <IconError />
    </StatusLayout>
  );
});
