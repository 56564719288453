import React from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getImage } from 'planoplan-ui-kit/core/get_image';
import { translations } from '@libs';
import { laptop, laptop_wide } from '../../../modules/breakpoints';

export const Cards = ({ locale }) => (
  <View>
    <Title>{translations.t('store.navigation.payment')}</Title>
    <List>
      {locale === 'ru' ? (
        <>
          <img src={getImage('payment/mastercard_white.svg')} alt="Mastercard" />
          <img src={getImage('payment/visa_white.svg')} alt="Visa" />
          <img src={getImage('payment/mir_white.svg')} alt="Мир" />
          <img src={getImage('payment/yoomoney_white.svg')} alt="ЮMoney" />
          <img src={getImage('payment/sbp.svg')} alt="СБП" />
          {/*<img src={getImage('payment/webmoney.png')} alt="WebMoney" />*/}
        </>
      ) : (
        <>
          <img src={getImage('payment/mastercard_white.svg')} alt="Mastercard" />
          <img src={getImage('payment/visa_white.svg')} alt="Visa" />
          <img src={getImage('payment/maestro_white.svg')} alt="Maestro" />
          <img src={getImage('payment/JCB.svg')} alt="JCB" />
          <img src={getImage('payment/pay-pal_white.svg')} alt="Paypal" />
        </>
      )}
    </List>
  </View>
);

const View = styled.div`
  display: none;

  @media ${laptop} {
    ${Grid.suffix(0.5, 6)};
    ${Grid.prefix(0.5, 6)};
    display: block;
  }
`;

const Title = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.nobel};
  font-weight: 300;
  margin-bottom: 3px;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media ${laptop} {
    margin: 0 -2px;

    img {
      height: 34px;
      margin: 2px 2px;
    }
  }

  @media ${laptop_wide} {
    margin: 0 -3px;

    img {
      height: 36px;
      margin: 3px 3px;
    }
  }
`;
