import React from 'react';

export const IArrowTop = ({ width = 16, height = 16, fill = '#EAEAEA' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 13V2" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 7L8 2L13 7" stroke={fill} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
