import React, { useEffect } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { laptop } from './modules/breakpoints';
import { Indent } from './modules/indent';
import { GlobalStyles } from './modules/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as getData from './modules/getdata';
import { Close } from './components/Close';
import { Sidebar } from './components/Sidebar';
import { Creater } from './components/Creater';
import { Loader } from './components/Loader';
import { Message } from './components/Message';
import { Analytics } from './modules/analytics';

export default () => {
  const dispatch = useDispatch();
  const store = useSelector(store => store);
  const { loading: { processing, error, firstLoading }} = store;
  const { settings: { close }} = store;
  const isShowScreen = (!processing || !firstLoading) && !error;

  const onClose = () => {
    close();

    if (window.dataLayer) {
      Analytics.close();
    }
  };

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());
      dispatch(getData.getEducations());
      dispatch(getData.getProjects());
    })();
  }, []);

  return (
      <>
        <GlobalStyles />
        <Container id="store_container">
          <Wrapper>
            <Content>
              <CloseIcon>
                <Close onClick={onClose} width="38px" height="38px" />
              </CloseIcon>
              {/*<Loader overlay={!firstLoading}/>*/}
              {processing && !error && <Loader overlay={!firstLoading} />}
              {error && <Message>{error.message}</Message>}
              {isShowScreen && (
                <>
                  <Sidebar />
                  <Creater />
                </>
              )}

            </Content>
          </Wrapper>
          <div id="store_notifications" />
        </Container>
      </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  background-color: ${rgba(Colors.black, 0.4)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 998;
`;

const Wrapper = styled.div`
  width: 100%;
  max-height: 100%;
`;

const Content = styled.div`
  ${Indent.margin_top(2)};
  ${Indent.margin_bottom(2)};
  width: ${Grid.col(32)};
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
  overflow: hidden;
  background-color: ${Colors.night};
  color: ${Colors.white};
  height: 660px;

  @media ${laptop} {
    ${Grid.row()};
    width: ${Grid.col(30)};
    overflow: visible;
  }
`;

const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: -20px;
  transform: translateX(100%);

  @media ${laptop} {
    display: block;
  }
`;
