import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { VIEWMODE } from '../constants';
import { IconViewCard, IconViewLine } from '../../../components/icons';

export const Viewmode = ({ current = '', setViewmode = () => {} }) => {
  return (
    <View>
      <Item isActive={current === VIEWMODE.card} onClick={() => setViewmode(VIEWMODE.card)}><IconViewCard/></Item>
      <Item isActive={current === VIEWMODE.list} onClick={() => setViewmode(VIEWMODE.list)}><IconViewLine/></Item>
    </View>
  )
};

const View = styled.ul`
  ${Mixins.resetList()};
  margin: 0 10px;
  display: flex;
  align-items: center;
`;
const Item = styled.li`
  cursor: pointer;  

  &:not(:last-child) {
    margin-right: 10px;
  }
  
  svg {
    ${Mixins.transition('fill')};
    display: block;
    fill: ${({ isActive }) => isActive ? Colors.feldgrau : Colors.feldgrau50}
  }
  
  &:hover {
    svg {
      fill: ${Colors.feldgrau};
    }
  }
`;
