import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { IArrowLeft } from 'planoplan-ui-kit/core/icons/i-arrow-left';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { tablet_landscape } from '../../modules/breakpoints';

export const BackButton = ({ onClick, ...restProps }) => (
  <View onClick={onClick} {...restProps}>
    <IArrowLeft fill={Colors.white} />
  </View>
);

export const View = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color')};
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.night};
  position: relative;
  z-index: 10;

  @media ${tablet_landscape} {
    width: 34px;
    height: 34px;
    background-color: ${Colors.coal};
  }

  &:hover {
    background-color: ${!isMobile && Colors.night};
  }
`;
