import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Link } from '@components/link';
import { translations, useCountDown } from '@libs';
import { RESEND_CODE_TIMEOUT } from '../../constants';

export const Resend = ({ checkTimerId, isCanResendCode, onResend, onCountDownStop }) => {
  const timeout = isCanResendCode ? 0 : RESEND_CODE_TIMEOUT;
  const countDownTime = useCountDown({ id: checkTimerId, value: timeout });
  const isShowTimer = countDownTime !== '0:00';

  useEffect(() => {
    if (countDownTime === '0:00') {
      onCountDownStop();
    }

    // eslint-disable-next-line
  }, [countDownTime]);

  return (
    <View>
      {isShowTimer ? (
        <Timer>
          {translations.t('form_entry.resendCodePossible')} <Time>{countDownTime}</Time>
        </Timer>
      ) : (
        <Link type="button" onClick={onResend} size="S">
          {translations.t('form_entry.check.respond')}
        </Link>
      )}
    </View>
  );
};

const View = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: ${Colors.planoplan_blue};
`;

const Timer = styled.div``;

const Time = styled.time`
  width: 35px;
  display: inline-block;
  text-align: left;
`;
