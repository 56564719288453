import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const Input = ({
  caption = '',
  value = '',
  onChange = () => {},
  children,
  ...props
}) => {
  const onChangeInput = (event) => onChange(event);

  return (
    <View>
      {children && <Caption>{children}</Caption>}

      <InputText value={value} onChange={onChangeInput} {...props} />
    </View>
  );
};

const View = styled.label``;
const Caption = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
  color: ${Colors.light_gray};
`;
const InputText = styled.input`
  ${Mixins.transition('border-color, box-shadow')};
  border: 1px solid ${Colors.solitude};
  width: 100%;
  height: 36px;
  padding: 12px 15px;
  border-radius: 4px;
  outline: none;
  background-color: ${Colors.mint};
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.coal};
  font-weight: 400;
  
  &::placeholder {
    color: ${Colors.nobel};
  }
  
  &:hover {
    border-color: ${Colors.planoplan_blue};
  }
  
  &:focus {
    border-color: ${Colors.planoplan_blue};
    box-shadow: inset 0 0 0 1px ${Colors.planoplan_blue};
  }
`;
