import React from 'react';

export const IFlag = ({ width = 16, height = 16, fill = '#FF473F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 2.5H2.5V9.5H14.5L11 6L14.5 2.5Z" fill={fill}/>
      <path d="M2.5 15.5V9.5M2.5 9.5V2.5H14.5L11 6L14.5 9.5H2.5Z" stroke={fill}/>
    </svg>

  );
};
