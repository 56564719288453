import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Link } from '@components/link';
import { Header } from './header';
import { AppBanner } from './app-banner';
import { Indent } from '../../../modules/indent';
import { tablet_landscape, laptop } from '../../../modules/breakpoints';
import { UrlButton } from '../../UrlButton';

export const Detail = ({ addToCart, addCountGood, removeCountGood, inCart, setScreen, productScreen, locale }) => {
  const { data } = productScreen;
  const { caption, description_site, img_description, link, link_title } = data;

  const IfWidget = () => (
    <Widget>
      <Content row>
        <ImageWidget>
          <img src={img_description} alt={caption} data-test="detail__image" />
        </ImageWidget>
        <Text dangerouslySetInnerHTML={{ __html: description_site }} data-test="detail__description" />
      </Content>
    </Widget>
  );

  const IfRenders = () => (
    <>
      <Image>
        <img src={img_description} alt={caption} data-test="detail__image" />
      </Image>
      <Content>
        <WrapperLink>
          <UrlButton component={Link} href={link} blank data-test="detail__link">
            {link_title}
          </UrlButton>
        </WrapperLink>
        <Text dangerouslySetInnerHTML={{ __html: description_site }} data-test="detail__description" />
      </Content>
    </>
  );

  const IfPanorama = () => (
    <>
      <Image>
        <img src={img_description} alt={caption} />
      </Image>
      <Content>
        {link && (
          <WrapperLink>
            <UrlButton component={Link} href={link} blank data-test="detail__link">
              {link_title}
            </UrlButton>
          </WrapperLink>
        )}
        <Text dangerouslySetInnerHTML={{ __html: description_site }} data-test="detail__description" />
        <AppBanner locale={locale} />
      </Content>
    </>
  );
  return (
    <View>
      <Header
        data={data}
        addToCart={addToCart}
        addCountGood={addCountGood}
        removeCountGood={removeCountGood}
        inCart={inCart}
        setScreen={setScreen}
      />
      <Wrapper data-test={`detail-${data.view_alias}`}>
        {data.view_alias === 'widget' && <IfWidget />}
        {data.view_alias === 'render' && <IfRenders />}
        {data.view_alias === 'package' && <IfRenders />}
        {data.view_alias === 'panorama' && <IfPanorama />}
      </Wrapper>
    </View>
  );
};

const View = styled.div``;

const Wrapper = styled.div`
  padding-bottom: 64px;

  @media ${tablet_landscape} {
    padding-bottom: 24px;
    padding-top: 24px;
  }
`;

const Content = styled.div`
  ${Grid.suffix(2, 32)};
  ${Grid.prefix(2, 32)};
  width: 100%;
  min-height: 40px;

  @media ${tablet_landscape} {
    display: ${(p) => p.row && 'flex'};
  }

  @media ${laptop} {
    ${Grid.suffix(2, 24)};
    ${Grid.prefix(2, 24)};
  }
`;

const Image = styled.div`
  height: 220px;

  img {
    width: 100%;
    display: block;
    height: 100%;
    max-height: 220px;
    object-fit: cover;
  }

  @media ${tablet_landscape} {
    ${Grid.suffix(2, 32)};
    ${Grid.prefix(2, 32)};
  }

  @media ${laptop} {
    ${Grid.suffix(2, 24)};
    ${Grid.prefix(2, 24)};
  }
`;

const WrapperLink = styled.div`
  ${Indent.margin_top(1)};
`;

const Text = styled.div`
  ${Indent.margin_top(1)};
  font-size: 14px;
  line-height: 20px;
  color: ${Colors.coal};
  font-weight: 300;

  p {
    ${Indent.margin_bottom(1)};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Widget = styled.div`
  ${Grid.row()};
  padding-top: 20px;

  @media ${tablet_landscape} {
    padding-top: 0;

    ${Text} {
      ${Grid.column(14, 30)};
    }
  }

  @media ${laptop} {
    ${Text} {
      ${Grid.column(9, 20)};
    }
  }
`;

const ImageWidget = styled.div`
  text-align: center;

  @media ${tablet_landscape} {
    ${Grid.column(15, 30)};
    ${Grid.post(1, 30)};
  }

  @media ${laptop} {
    ${Grid.column(10, 20)};
    ${Grid.post(1, 20)};
  }
`;
