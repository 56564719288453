export const IMAGE_EDITOR_OPEN = 'IMAGE_EDITOR_OPEN';
export const IMAGE_EDITOR_CLOSE = 'IMAGE_EDITOR_CLOSE';

// Actions
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_IMAGE = 'SET_IMAGE';
export const SET_ADJUSTMENT = 'SET_ADJUSTMENT';

// Getters
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_IMAGE_SIZE = 'GET_IMAGE_SIZE';
export const GET_IMAGE_BASE64 = 'GET_IMAGE_BASE64';

// Invoke actions
export const BUTTONS = {
  IMAGE_EDITOR_CLOSE: 'IMAGE_EDITOR_CLOSE',
  IMAGE_EDITOR_SAVE: 'IMAGE_EDITOR_SAVE',
};

export const ERROR = 'ERROR';

// Tabs
export const TABS = {
  adjustment: 'adjustment',
};

// Save mods
export const SAVE_MODE = {
  new: 'new',
  current: 'save'
};
