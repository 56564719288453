import React from 'react';
import { translations } from '@libs';
import { Message } from "../../components/Message";
import styled from "styled-components";
import { tablet_landscape } from "../../modules/breakpoints";
import { Grid } from "planoplan-ui-kit/core/helpers/grid";

export const SuccessBinding = () => {
  return (
    <View>
        <Message type="SUCCESS">
          <div
            dangerouslySetInnerHTML={{
              __html: translations.t('store.binding.success'),
            }}
          />
        </Message>
    </View>
  );
};

const View = styled.div`
  height: 100%;
  
  @media ${tablet_landscape} {
    ${Grid.row()};
  }
`;
