import { request } from '@libs';

const getData = async (urls, params) => {
  try {
    const response = await request.call_auth('post', urls, params);
    const { data } = response;

    if (!Object.values(data).length) {
      throw new Error('server');
    }

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    if (!data.success && data.errorText) {
      throw new Error(data.errorText);
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',

  setSettings({ locale }) {
    this.locale = locale;
  },

  getProjects({ params }) {
    const urls = { local: '/json/projects/projects.json', prod: `/${this.locale}/content/?blocks[]=projects` };

    return getData(urls, params);
  }
};
