export const initialState = {
  images: [],
  projects: [],
  filters: [],
  current_image: {},
  lazy_images: {},
  current_filter: { value: 'all' },
  checked_images: [],
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  settings: {},
  user_settings: {},
  project_name: '',
  has_pdf: false,
  profile: {},
  profileGoods: {},
};
