import React from 'react';
import styled from 'styled-components';
import { Colors } from "planoplan-ui-kit/core/colors";

export const Checkbox = ({ id = Date.now(), label = '', postLabel = false, checked = true, onChange = () => {}, disabled = false }) => {
  return (
    <View>
      <Label htmlFor={id} disabled={disabled}>
        {(label && !postLabel) && <Caption>{label}</Caption>}

        <FakeInput checked={checked} disabled={disabled}>
          {Boolean(checked) && (
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.5303 0.96967C13.8232 1.26256 13.8232 1.73744 13.5303 2.03033L5.28033 10.2803C4.98744 10.5732 4.51256 10.5732 4.21967 10.2803L0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967C0.762563 5.17678 1.23744 5.17678 1.53033 5.46967L4.75 8.68934L12.4697 0.96967C12.7626 0.676777 13.2374 0.676777 13.5303 0.96967Z" fill="#2F2F2F"/>
            </svg>
          )}
        </FakeInput>

        {(label && postLabel) && <Caption>{label}</Caption>}
      </Label>

      <Input id={id} name={id} type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.label`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  display: flex;
  align-items: center;
`;
const Caption = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.light_gray};
  margin-right: 5px;
  margin-left: 5px;
`;
const FakeInput = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: ${({ checked }) => checked ? Colors.dark_cream : Colors.white};
  border: 1px solid ${({ checked }) => checked ? Colors.dark_cream : Colors.light_gray};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  
  svg {
    display: block;
    margin-top: -1px;
  }
`;
const Input = styled.input`
  display: none;
`;
