import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.LOT_SHOW_INFO]: {
    name: 'LotShowInfo',
    value: 0,
    type: 'number',
    alias: 'LotShowInfo',
  },

  [BUTTONS.SET_USER]: {
    name: 'SetUser',
    value: { id: 0 },
    type: 'object',
    alias: 'SetUser',
  },

  [BUTTONS.LOGOUT]: {
    name: 'Logout',
    alias: 'Logout',
  },

  [BUTTONS.CABINET]: {
    name: 'CabinetOpen',
    alias: 'CabinetOpen',
  },
};
