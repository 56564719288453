import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from 'planoplan-ui-kit/core/button';
import { Select } from 'planoplan-ui-kit/core/select';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const MoveProjectModal = ({ isOpen, params = {}, onCancel = () => {}, onMove = () => {} }) => {
  const { projects: { currentFolder, foldersTree } } = useSelector(state => state);
  const options = [{
    view_value: translations.t('modal.move.myProjects'),
    value: '0',
    render: <Option>{translations.t('modal.move.myProjects')}</Option>,
    render_selected: <div>{translations.t('modal.move.myProjects')}</div>,
    disabled: !currentFolder
  }];
  const [selected, setSelected] = useState(options[0]);
  const pushFolder = (folders = [], isDisabledParent = false) => {
    if (!Array.isArray(folders)) {
      return;
    }

    folders.forEach((folder) => {
      const data = folder.node;
      const sp = [];

      for (let i = 0; i < folder.level; i++) {
        sp.push(<Sp key={i} />);
      }

      const foldersIds = params.ids ? params.ids.filter((id) => id[0] === 'f').map(id => id.slice(2)) : [];
      const disabled = isDisabledParent || foldersIds.includes(`${data.id}`);

      const value = {
        view_value: data.name,
        value: `${data.id}`,
        render:
          <Option>
            {sp}
            {Boolean(folder.level) && <Nes />}
            {data.name}
          </Option>,
        render_selected: <div>{data.name}</div>,
        disabled: disabled || (currentFolder ? currentFolder.id === data.id : false)
      };

      options.push(value);

      if (folder.children) {
        pushFolder(folder.children, disabled);
      }
    });
  };

  pushFolder(foldersTree);

  const onSelected = (val) => setSelected(val);

  useEffect(() => {
    setSelected(options[0]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.move.title')}</Header>
      <Content>
        <Select
          options={options}
          selected={selected}
          onSelect={onSelected}
          theme="secondary"
          size={'M'}>
          {translations.t('modal.move.choose')}
        </Select>
      </Content>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={() => onMove({ folderId: Number(selected.value) })}>
          {translations.t('modal.move')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
const Option = styled.div`
  display: flex;
`;
const Nes = styled.div`
  width: 8px;
  height: 6px;
  border-left: 1px solid ${Colors.light_gray};
  border-bottom: 1px solid ${Colors.light_gray};
  margin-right: 5px;
  margin-top: 6px;
`;
const Sp = styled.div`
  width: 8px;
`;
