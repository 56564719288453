import React, { useState } from 'react';
import { Link } from '@components/link';
import { translations } from '@libs';
import { ButtonAction, Field, Form, Header, Text, Image, InputField, Error } from '../../components';
import { ENTRY_STEPS, ERROR_TYPES, EMAIL_REGEXP } from '../../constants';

export const RecoveryPassword = ({
  setStep,
  isProcessing,
  onRecoveryPassword,
  error = {},
  onClearError = () => {},
}) => {
  const [field, setField] = useState({
    value: '',
    error: '',
  });
  const isError = error.type === ERROR_TYPES.LOCAL;

  const onChange = ({ value, error: fieldError }) => {
    setField({ ...field, value, error: fieldError });

    if (isError) {
      onClearError();
    }
  };
  const onSubmit = (event) => {
    event.preventDefault();

    if (!field.error) {
      onRecoveryPassword(field.value);
    }
  };

  return (
    <>
      <Header title={translations.t('form_entry.recovery.header')}>
        <Link size="S" onClick={() => setStep(ENTRY_STEPS.LOGIN)} data-test="button_back-login">
          {translations.t('form_entry.back.login')}
        </Link>
      </Header>

      <Image normal="form/password-recovery.jpg" retina="form/password-recovery@2x.jpg" alt="Check your email" />
      <Text>{translations.t('form_entry.recovery.text')}</Text>

      <Form onSubmit={onSubmit} isMarginTop>
        <Field isError={isError}>
          <InputField
            placeholder={translations.t('form_entry.email.placeholder')}
            type="email"
            name="username"
            value={field.value}
            hasError={field.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              regexp: RegExp(EMAIL_REGEXP),
              errorValid: translations.t('form_entry.email.notvalid'),
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            autoComplete="username"
            required>
            Email
          </InputField>
        </Field>

        {isError && <Error>{error.message}</Error>}

        <ButtonAction type="submit" loading={isProcessing} disabled={field.error || !field.value} useButtonLoader>
          {translations.t('form_entry.continue')}
        </ButtonAction>
      </Form>
    </>
  );
};
