import { RENDER_SIZES } from '../constants';

// Проверяем доступен ли следуюший, если нет, то ищем от него до конца, если и там нет, то ищем с самого начала
export const getNextImage = (images, current) => {
  const index_image = images.findIndex((item) => item.view_id === current.view_id);
  const next_image = images[index_image + 1];

  const inNextAvailable = !!next_image && next_image.isFilter;

  if (inNextAvailable) {
    return next_image;
  }

  const searchNext = images.find((item, index) => index > index_image && item.isFilter);

  if (searchNext) {
    return searchNext;
  }

  const searchPrev = images.find((item) => item.isFilter);

  if (searchPrev) {
    return searchPrev;
  }

  return null;
};

// Проверяем доступен ли предыдущий, если нет то клонируем и переварачиваем массив, и ищем аналогично getNextImage
export const getPrevImage = (images, current) => {
  const index_image = images.findIndex((item) => item.view_id === current.view_id);
  const prev_image = images[index_image - 1];

  const isPrevAvailable = !!prev_image && prev_image.isFilter;

  if (isPrevAvailable) {
    return prev_image;
  }

  const reverse_images = [...images].reverse();
  const index_image_reverse = reverse_images.findIndex((item) => item.view_id === current.view_id);

  const searchPrev = reverse_images.find((item, index) => index > index_image_reverse && item.isFilter);

  if (searchPrev) {
    return searchPrev;
  }

  const searchNext = reverse_images.find((item) => item.isFilter);

  if (searchNext) {
    return searchNext;
  }

  return null;
};

export const getInitialImage = (images, openImageId = null) => {
  // если существует такой id и он доступен, то показываем его. если нет, то первый
  if (openImageId) {
    return images.find((item) => Number(item.id) === Number(openImageId)) || images[0];
  }

  return images[0];
};

export const loadingImage = (src) => {
  return new Promise((resolve, reject) => {
    const downloading_image = new Image();

    downloading_image.src = src;

    downloading_image.onload = resolve;
    downloading_image.onerror = reject;
  });
};

export const isNeedUpdate = (images) => {
  return !!images.find((item) => item.isProgress);
};

export const isUpdatedImages = (old_images, new_data) => {
  const result = [];

  new_data.forEach((item) => {
    const old = old_images.find((old_item) => JSON.stringify(old_item.editor) === JSON.stringify(item.editor));

    if (!old) {
      result.push(item);
    }
  });

  return result;
};

export const getCompressUrl = (url, width, height) => {
  const rg = new RegExp(/thumb.*?_/g);

  return url.replace(rg, `thumb${width || ''}x${height || ''}_`);
};

export const getGoodsPackage = (goods) => {
  return (goods.in_sets && goods.in_sets.length) ? goods.in_sets.reduce((item, current) => item.count < current.count ? current : item) : goods;
};

/**
 * Заменяет переменные в строке на значения, переданные в аргументах. ${variable} => значение переменной
 *
 * @param {string} string - Строка, в которой нужно произвести замену переменных.
 * @param {...*} variables - Переменное количество аргументов, представляющих значения для замены переменных в строке.
 * @return {string} - Строка с замененными значениями переменных.
 */
export const replaceVariables = (string, ...variables) => {
  let index = 0;

  return string.replace(/\$\{(.*?)\}/g, function() {
    return variables[index++];
  });
};

export const getResolutionByGoodsParams = (goods) => {
  try {
    const [width, height] = goods.params.split(';')?.[0].split('=')[1].split('x');

    return { width, height };
  } catch (error) {
    return {};
  }
};

export const getNameResolutionByWidth= (width) => {
  return RENDER_SIZES[width];
};