import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { translations, LocalStorage } from '@libs';
import { POP_SCREEN_ENTRY_LOGIN } from '@globalLocalStorage';
import { UNAUTH_URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { Header, ProgressBar, InputField, Loader } from '../../components';

export const Input = () => {
  const { isLogin, settings: { locale }} = useSelector((state) => state);

  const onOpenPage = () => {
    if (isLogin === false) {
      const ruUrl = 'https://planoplan.com/ru/blog/articles/nachalo-raboty-v-planoplane/';
      const otherUrl = 'https://planoplan.com/en/blog/articles/start-your-work-in-planoplan/';
      const href = locale === 'ru' ? ruUrl : otherUrl;

      window.invokeEditorAction({
        name: url[UNAUTH_URL_OPEN].name,
        value: href,
      });
    }
  };

  const onSendInvoke = () => {
    window.UIInvokeFunction('ScreenEntryClose');
  };

  useEffect(() => {
    onOpenPage();
  }, []);

  return (
      <>
        <Header title={translations.t('form_entry.login.title')} />
        <ProgressBar />
        <InputWrapper>
          <InputField
              placeholder={translations.t('form_entry.email.placeholder')}
              type="email"
              name="username"
              value={LocalStorage.get(POP_SCREEN_ENTRY_LOGIN)}
              disabled>
            Email
          </InputField>
        </InputWrapper>
        <Loader onClick={onSendInvoke} />
      </>
  )
};

const InputWrapper = styled.div`
  margin-top: 10px;
`;
