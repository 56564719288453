import { createGlobalStyle, css } from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { tablet_landscape, laptop } from './breakpoints';
import { globalStyles } from '../../../globalStyles';

export const GlobalStyles = createGlobalStyle`
  ${globalStyles};
`;

export const getLayoutScreenCss = (columnMobile = 1, columnLaptop = 1) => {
  return css`
    ${Grid.suffix(columnMobile, 32)};
    ${Grid.prefix(columnMobile, 32)};
    ${Grid.row()};
    padding-top: 20px;
    padding-bottom: 20px;
    color: ${Colors.coal};

    @media ${tablet_landscape} {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @media ${laptop} {
      ${Grid.suffix(columnLaptop, 24)};
      ${Grid.prefix(columnLaptop, 24)};
    }
  `;
};
