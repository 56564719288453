export const getFeaturesNewWindow = (width, height) => {
  const screenX = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft;
  const screenY = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop;
  const outerWidth = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth;
  const outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : document.body.clientHeight - 22;
  const left = parseInt(screenX + (outerWidth - width) / 2, 10);
  const top = parseInt(screenY + (outerHeight - height) / 2.5, 10);

  return 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;
};

export function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}
