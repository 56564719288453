import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Button } from 'planoplan-ui-kit/core/button';

export const Confirm = ({ onAgree = () => {}, onCancel = () => {}, title = '', text = '', cancelText = 'cancel', aggreeText = 'OK' }) => {
  return (
    <View>
      <Wrapper>
        <Close onClick={onCancel}>
          <IClose width="38" height="38" fill={Colors.white} />
        </Close>
        <Form>
          <Title>{title}</Title>
          <Text>{text}</Text>

          <Actions>
            <Action>
              <Button theme="secondary" size="M" onClick={onCancel}>
                {cancelText}
              </Button>
            </Action>

            <Action>
              <Button theme="primary" size="M" onClick={onAgree}>
                {aggreeText}
              </Button>
            </Action>
          </Actions>
        </Form>
      </Wrapper>
    </View>
  );
};

const View = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 560px;
  padding: ${isMobile ? '0 60px 0 10px' : '0 70px'};
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 10px;
`;
const Form = styled.div`
  width: 100%;
  min-height: 280px;
  background-color: #fff;
  border-radius: 10px;
  padding: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.h3`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 14px;
  color: ${Colors.coal};
  font-family: 'Montserrat', sans-serif;
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 14px;
  color: ${Colors.coal};
  flex-grow: 1;
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px -10px;
`;
const Action = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 0 10px;
`;

const ButtonRoute = styled.div`
  ${Mixins.transition('background-color')};
  display: flex;
  border-radius: 6px;
  border: 1px solid ${Colors.feldgrau};
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 40px;
  right: 20px;
  top: 65px;
  cursor: pointer;
  overflow: hidden;
  padding: 10px 14.5px;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
  }

  &:hover {
    background-color: ${Colors.feldgrau};
    color: ${Colors.feldgrau25};
  }
`;
