import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import * as effects from '../../../modules/effects';
import * as actions from '../../../modules/Store/action';
import { IconCheck, IconClose } from '@icons';

export const PromoCode = () => {
  const dispatch = useDispatch();
  const { selected: { promoCode }} = useSelector(state => state);
  const [promo, setPromo] = useState(promoCode);
  const [loading, setLoading] = useState('');
  const minLengthPromo = 3;

  const onChangeInput = ({ target }) => setPromo(target.value);
  const checkPromoCode = async () => {
    if (promo.length <= minLengthPromo) {
      notifications.showError(translations.t('cabinet.notification.promo.invalid'));

      return;
    }

    setLoading(true);

    await dispatch(effects.checkPromoAndGetNewPrice({ promo }));

    setLoading(false);
  };
  const closePromoCode = () => {
    setPromo('');
    dispatch(actions.setPromoCode(''));
    dispatch(actions.setAppliedPromo({}));
  };

  useEffect(() => {
    (async () => {
      if (promoCode) {
        await checkPromoCode();
      }
    })();
  }, []);

  return (
    <View>
      <Input value={promo} placeholder={translations.t('quickstore.promocode.placeholder')} disabled={loading || promoCode} onChange={onChangeInput} />
      {!loading && (promoCode ?
        <Icon onClick={closePromoCode}><IconClose fill={Colors.dark_cream}/></Icon> :
        <IconApply promo={promo} onClick={checkPromoCode}><IconCheck fill={promoCode ? Colors.dark_cream : Colors.nobel}/></IconApply>
      )}
      {loading && <Icon><Loading /></Icon>}
    </View>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
`;
const View = styled.div`
  position: relative;
`;
const Input = styled.input`
  ${Mixins.transition('border-color')};
  border-radius: 4px;
  border: 1px solid ${Colors.nobel};
  background-color: ${Colors.night};
  color: ${Colors.light_gray};
  height: 36px;
  width: 100%;
  padding-left: 15px;
  padding-right: 30px;

  &::placeholder {
    color: ${Colors.coal};
  }

  &:focus,
  &:hover {
    outline: none;
    border-color: ${Colors.dark_cream};
  }
`;
const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const IconApply = styled(Icon)`
  cursor: ${({ promo }) => promo ? 'pointer' : 'default'};
  pointer-events: ${({ promo }) => promo ? 'default' : 'none'};
`;
const Loading = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 2px;
  border-radius: 12px;
  border: 1px solid ${Colors.dark_cream};
  border-right-color: transparent;
  border-left-color: transparent;
  animation: ${rotate} 0.3s linear infinite;
`;
