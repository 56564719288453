import React from 'react';

export const ICardVisa = ({ width = 32, height = 20, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15899_61004)">
        <path d="M29.7714 0.227051H2.22852C1.12395 0.227051 0.228516 1.11739 0.228516 2.21569V17.7839C0.228516 18.8822 1.12395 19.7725 2.22852 19.7725H29.7714C30.8759 19.7725 31.7714 18.8822 31.7714 17.7839V2.21569C31.7714 1.11739 30.8759 0.227051 29.7714 0.227051Z" fill="#4A4A4A"/>
        <path d="M16.0791 8.68926C16.0631 9.98472 17.1968 10.7074 18.0512 11.1374C18.9289 11.5784 19.2238 11.8615 19.2201 12.2556C19.2137 12.8593 18.5203 13.1256 17.8716 13.1361C16.7388 13.1543 16.0809 12.8202 15.5575 12.5679L15.1497 14.5388C15.6745 14.7884 16.6469 15.0065 17.6549 15.0156C20.0215 15.0156 21.5699 13.8093 21.5785 11.9384C21.5877 9.56471 18.3987 9.43335 18.4206 8.37199C18.4279 8.05062 18.7255 7.70699 19.3769 7.61971C19.6992 7.57562 20.5893 7.54199 21.5987 8.02199L21.9945 6.11517C21.4519 5.91062 20.7543 5.71517 19.8862 5.71517C17.6585 5.71517 16.0919 6.93835 16.0791 8.68881V8.68926ZM25.8007 5.87971C25.3687 5.87971 25.0044 6.14017 24.8421 6.53971L21.4611 14.8761H23.8263L24.2972 13.5329H27.1863L27.4597 14.8761H29.5443L27.7248 5.88062H25.8007V5.87971ZM26.1317 8.31017L26.8142 11.6884H24.9445L26.1317 8.31017ZM13.2119 5.88017L11.3477 14.8756H13.6014L15.4643 5.88017H13.2119ZM9.87797 5.88017L7.53237 12.0029L6.58334 6.79699C6.47225 6.21562 6.03248 5.88017 5.54425 5.88017H1.70974L1.65625 6.14153C2.44345 6.3179 3.33762 6.60244 3.87934 6.90699C4.21122 7.0929 4.30585 7.25517 4.41419 7.69744L6.21168 14.8761H8.59339L12.2446 5.88062H9.87797" fill="white"/>
      </g>

      <clipPath id="clip0_15899_61004">
        <rect width="32" height="20" fill="white"/>
      </clipPath>
    </svg>
  );
};
