import React from 'react';

export const IWarning = ({ width = 16, height = 16, fill = '#FFF59B', stroke = '#4A4A4A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.29904 2.25L8.86603 2.5L9.29904 2.25C8.72169 1.25 7.27831 1.25 6.70096 2.25L1.50481 11.25C0.92746 12.25 1.64915 13.5 2.80385 13.5H13.1962C14.3509 13.5 15.0725 12.25 14.4952 11.25L9.29904 2.25Z" fill={fill} stroke={fill} />
      <path d="M8 4.79999V8.19999" stroke={stroke} strokeWidth="1.6" strokeLinecap="round"/>
      <circle cx="8" cy="11" r="1" fill={stroke}/>
    </svg>
  );
};

