import React from 'react';

export const IImprove = ({ width = 17, height = 16, fill = 'black' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.707107" width="1.76469" height="14.1317" rx="0.882344" transform="matrix(-0.707107 0.707107 0.707107 0.707107 4.64172 2.89387)" stroke={fill}/>
      <path d="M5.12219 3.35701L3.85704 4.62217L6.80621 7.57134L8.07137 6.30618L5.12219 3.35701Z" fill={fill} stroke={fill}/>
      <path d="M3.5 1.5H0.5" stroke={fill}/>
      <path d="M7.5 12.5H2.5" stroke={fill}/>
      <path d="M2 0L2 3" stroke={fill}/>
      <path d="M5 10L5 15" stroke={fill}/>
    </svg>

  );
};

