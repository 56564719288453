// import from libs не работает
let instance = null;

class Observer {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.messages = {};

    return instance;
  }

  addEventListener(message, fn) {
    if (!this.messages[message]) {
      this.messages[message] = [];
    }

    this.messages[message].push({
      context: this,
      callback: fn,
    });
  }

  removeEventListener(message) {
    if (!this.messages[message]) {
      return false;
    }

    delete this.messages[message];

    return this;
  }

  postMessage(message, payload = {}) {
    // для тестирования
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
      window.POPObserverScope = {
        ...window.POPObserverScope,
        [message]: payload,
      };
    }

    // для дебага
    if (window.location.host.indexOf('planoplan') === -1 && process.env.NODE_ENV !== 'test') {
      console.info('%cOBSERVER MESSAGE', 'color: #ffa500', { [message]: payload });
    }

    if (!this.messages[message]) {
      return false;
    }

    const firstArgument = 1;
    const args = Array.prototype.slice.call(arguments, firstArgument);

    this.messages[message].forEach((subscription) => {
      subscription.callback.apply(subscription.context, args);
    });

    return this;
  }
}

if (!window.POPObserver) {
  window.POPObserver = Observer;
}
