import { translations, LocalStorage } from '@libs';
import { GET_TRANSLATES,
  GET_ARTICLE,
  SET_ARTICLE,
  LOCAL_STORE_ASSISTANT_LIBRARY,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  DEFAULT_ARTICLE_ALIAS,
} from '../constants';
import { ApiCall } from './api-call';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, '^assistant.']);
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: error.message },
      });
    }
  }
};

export const getLibrary = (alias) => async (dispatch, getState) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_ARTICLE } });

  const getArticle = (alias, library) => {
    let article = null;

    Object.values(library).some((group) => {
      Object.values(group.blocks).some((block) => {
        const data = Object.values(block.items).find(item => item.alias === alias);

        article = data ? {
          ...data,
          category: group.alias.replace('library_', ''),
          subcategory: block.alias,
        } : null;

        return Boolean(article);
      });

      return Boolean(article);
    });

    return article;
  };
  const getArticleWithRequest = async (alias) => {
    try {
      const { data } = await ApiCall.getLibrary();

      LocalStorage.set(LOCAL_STORE_ASSISTANT_LIBRARY, data);

      return getArticle(alias, data);
    } catch (error) {
      console.error(error);

      return null
    }
  };

  try {
    /* Пока все не заполненно не стоит сохранять т.к. будет выводиться старый контент */
    // const localLibrary = LocalStorage.get(LOCAL_STORE_ASSISTANT_LIBRARY);
    // const localArticle = (localLibrary && isProdHost) ? getArticle(alias, localLibrary) : null;
    const localArticle = null;

    if (localArticle) {
      dispatch({ type: SET_ARTICLE, payload: localArticle });
    } else {
      const reqArticle = await getArticleWithRequest(alias);

      if (!reqArticle) {
        const defaultArticle = await getArticleWithRequest(DEFAULT_ARTICLE_ALIAS);

        dispatch({ type: SET_ARTICLE, payload: defaultArticle });

        throw new Error(`The assistant's article was not found! Show default article ${DEFAULT_ARTICLE_ALIAS}`);
      }

      dispatch({ type: SET_ARTICLE, payload: reqArticle });
    }

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_ARTICLE } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_ARTICLE, error: error },
    });
  }
};
