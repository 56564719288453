import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const AddUserInTeamModal = ({ isOpen, onCancel = () => {}, onAddUser = () => {} }) => {
  const [value, setValue] = useState('');
  const InputEl = useRef();

  useEffect(() => {
    setValue('');

    setTimeout(() => {
      if (InputEl && InputEl.current) {
        InputEl.current.focus();
      }
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>
        {translations.t('cabinet.members.add.user')}
      </Header>
      <Content>
        <Input
          type="text"
          theme="secondary"
          placeholder="e-mail"
          onChange={(event) => setValue(event.value)}
          value={value}
          required
          ref={InputEl}>
          {translations.t('modal.user.email')}
        </Input>
      </Content>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" disabled={!value} onClick={() => onAddUser(value)}>
          {translations.t('cabinet.members.add.button')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
