import { getHost, isProdHost, uiHost, translations } from '@libs';
import { getFeaturesNewWindow, isJSON } from './organism/heplers';
import { ISDEVELOPMENT } from '@globalConstants';
import { VK_KEY_DEV, VK_KEY_MASTER } from '@features/form-entry/constants';
import { notifications } from '@features/notifications';
import { ApiCall } from '../../modules/api-call';
import { getProfile } from '../../modules/getdata';
import { SET_PROFILE } from '../../constants';

export const onChangePassword = ({ current, newPass, repeat }) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.onChangePassword({
      password: current,
      new_password: newPass,
      new_password_repeat: repeat
    });

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.changePassword.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.changePassword.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.changePassword.error'));
  }
};

export const vkCall = () => {
  const redirectUri = `${uiHost}/auth.html`;
  const features = getFeaturesNewWindow(665, 370);
  const key = isProdHost ? VK_KEY_MASTER : VK_KEY_DEV;

  const openedWindow = window.open(
    `https://oauth.vk.com/authorize?client_id=${key}&display=page&redirect_uri=${redirectUri}&response_type=code&scope=4194304`,
    'vk',
    features
  );

  return new Promise((resolve, reject) => {
    const closedInterval = setInterval(() => {
      if (openedWindow.closed) {
        clearInterval(closedInterval);

        reject('popup_closed_by_user');
      }
    }, 500);

    window.addEventListener('message', (event) => {
      const currentOrigin = getHost({ short: false });
      const { origin, data } = event;

      if (origin !== currentOrigin || !isJSON(data)) {
        return;
      }

      try {
        const location = JSON.parse(data);

        if (location && location.search) {
          const params = new URLSearchParams(location.search.substring(1));
          const code = params.get('code');

          clearInterval(closedInterval);
          resolve({ code });
        }
      } catch (error) {
        reject(error);
      }
    });
  });
};

export const addVKAuth = (errorCallback, errorCallback2) => async (dispatch, getState) => {
  const sendRequest = async ({ code }) => {
    const response = await ApiCall.socialVk({ code });
    const data = response.data;

    if (data && data.success) {
      notifications.showSuccess(translations.t('users.profile.multiauth.fb.popup.body.success'));
    } else {
      if (data && data.errorText === 'Account used') {
        errorCallback2();
      } else {
        errorCallback();
      }
    }
  };

  if (ISDEVELOPMENT) {
    await sendRequest({ code: 123 });

    return;
  }

  const auth = await vkCall();

  if (auth.code) {
    await sendRequest({ code: auth.code });
  } else {
    errorCallback();
  }
};

export const removeVKAuth = () => async (dispatch, getState) => {
  const response = await ApiCall.socialVk({ remove: true });
  const data = response.data;

  if (data && data.success) {
    const { profile } = getState();

    dispatch({ type: SET_PROFILE, payload: { ...profile, linked_accounts: { ...profile.linked_accounts, vkontakte: false }}});
    notifications.showSuccess(translations.t('users.profile.multiauth.vk.popup.body.remove.success'));
  } else {
    notifications.showError(translations.t('users.profile.multiauth.error'));
  }
};

export const addGoogleAuth = (code, errorCallback, errorCallback2) => async (dispatch, getState) => {
  const sendRequest = async ({ code }) => {
    const response = await ApiCall.socialGoogle({ code });
    const data = response.data;

    if (data && data.success) {
      notifications.showSuccess(translations.t('users.profile.multiauth.fb.popup.body.success'));
    } else {
      if (data && data.errorText === 'Account used') {
        errorCallback2();
      } else {
        errorCallback();
      }
    }
  };

  if (ISDEVELOPMENT) {
    await sendRequest({ code: 123 });

    return;
  }

  if (code) {
    await sendRequest({ code });
  }
};

export const removeGoogleAuth = () => async (dispatch, getState) => {
  const response = await ApiCall.socialGoogle({ remove: true });
  const data = response.data;

  if (data && data.success) {
    const { profile } = getState();

    dispatch({ type: SET_PROFILE, payload: { ...profile, linked_accounts: { ...profile.linked_accounts, google: false }}});
    notifications.showSuccess(translations.t('users.profile.multiauth.google.popup.body.remove.success'));
  } else {
    notifications.showError(translations.t('users.profile.multiauth.error'));
  }
};

/* После привязки обновляем несколько раз профиль что бы узанть когда соцсеть привязалась */
export const runCheckBindingAuth = (callbackStop = () => {}) => async (dispatch, getState) => {
  const time = 10000;
  let attempt = 12;

  const interval = setInterval(() => {
    if (attempt <= 0) {
      callbackStop();
      return clearInterval(interval);
    }

    dispatch(getProfile());

    attempt -= 1;
  }, time);
};
