import React from 'react';
import { MODE_ALIAS, UNITY, URL_OPEN, DOMAINS } from '@globalConstants';
import { url } from '@globalInvoke';
import { isProdHost } from '@libs';

export const UrlButton = ({
  locale = 'en',
  mode = 'site',
  href = '',
  target = null,
  tag = '',
  onClick = () => {},
  children,
  ...restProps
}) => {
  const domain = isProdHost ? DOMAINS.PROD : DOMAINS.DEV;
  const unityHref = href.includes('https://') ? href : `${domain}/${locale}${href}`;
  const siteHref = href.includes('https://') ? href : `/${locale}${href}`;
  const hrefTo = MODE_ALIAS[mode] !== UNITY && href ? siteHref : null;

  const onClickWithInvoke = (event) => {
    if (MODE_ALIAS[mode] === UNITY && href) {
      event.preventDefault();

      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: unityHref,
      });
    } else {
      onClick();
    }
  };

  return (
    <>
      {React.cloneElement(children, {
        href: hrefTo,
        target: hrefTo && target,
        as: tag ? tag : hrefTo ? 'a' : 'button', // по дефолту заависит от наличия урла, но можно передать свой
        onClick: onClickWithInvoke,
        ...restProps,
      })}
    </>
  );
};
