const breakpoints = {};
const defaultPoints = [
  {
    tablet_portrait: 670,
  },
  {
    tablet_landscape: 960,
  },
  {
    laptop: 1280,
  },
];

export const createBreakpoints = (points = defaultPoints) => {
  const zoom = new URLSearchParams(window.location.search).get('zoom') || 1;
  const keys = points.reduce((a, b) => a.concat(Object.keys(b)), []);

  points.forEach((item, index) => {
    for (let key in item) {
      const nextPoint = points[index + 1];
      const nextValuePoint = nextPoint ? nextPoint[keys[index + 1]] : 10000;
      const val = item[key];

      if (Object.prototype.hasOwnProperty.call(item, key)) {
        breakpoints[key] = `only screen and (min-width: ${val * zoom}px)`;
        breakpoints[`${key}_only`] = `only screen and (min-width: ${val * zoom}px) and (max-width: ${
          nextValuePoint * zoom - 1
        }px)`;

        breakpoints[`${key}_blank`] = `only screen and (min-width: ${val}px)`;
        breakpoints[`${key}_only_blank`] = `only screen and (min-width: ${val}px) and (max-width: ${
        nextValuePoint - 1
          }px)`;
      }
    }
  });

  return breakpoints;
};
