export const textHooks = (string) => {
  const textHooks = string.substr(0, string.indexOf('('));
  const result = {};

  if (textHooks) {
    result.out_hooks = textHooks;
    result.in_hooks = string.substring(string.indexOf('(') + 1, string.indexOf(')')).replace(/ /g, '');
  } else {
    result.out_hooks = string;
    result.in_hooks = '';
  }

  return result;
};
