import React from 'react';
import styled from 'styled-components';
import { tablet_landscape } from '../../modules/breakpoints';

export const Text = ({ children, ...restProps }) => <View {...restProps}>{children}</View>;

const View = styled.p`
  margin: 24px 0;
  font-size: 13px;
  line-height: 16px;

  @media ${tablet_landscape} {
    margin: 20px 0;
    font-size: 16px;
    line-height: 19px;
  }
`;
