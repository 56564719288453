import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const SocialRemoveGoogle = ({ isOpen, onCancel = () => {}, onConfirm = () => {} }) => (
  <Modal isOpen={isOpen} onClose={onCancel}>
    <Text>{translations.t('users.profile.multiauth.google.popup.body.remove.confirm')}</Text>
    <Buttons>
      <Button theme="warning" size="M" onClick={onConfirm}>
        {translations.t('users.profile.account.delete.popup.button.delete.title')}
      </Button>
    </Buttons>
  </Modal>
);

const Text = styled.p`
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 160px;
  }
`;
