import React from 'react';
import styled from 'styled-components';


export const List = ({ children, ...restProps }) => (
  <ListView {...restProps}>
    <tbody>{children}</tbody>
  </ListView>
);

const ListView = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;
