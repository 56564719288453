import React, { useEffect, useRef, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { rgba } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useFullScreen } from 'planoplan-ui-kit/core/hooks/useFullScreen';
import { notifications } from '@features/notifications';
import {
  ON_CHANGE_PAYMENT_STATUS_STORE,
  ON_GALLERY_SHOW,
  ON_GALLERY_HIDE,
  ON_OPEN_TOUR_EDITOR,
  ON_OPEN_BANNER
} from '@observer/constants';
import { GlobalStyles } from './modules/styles';
import { Loader } from './components/Loader';
import { Sidebar } from './components/Sidebar';
import { Canvas } from './components/Canvas';
import { Bar } from './components/Bar';
import { Close } from './components/Close';
import { Fullscreen } from './components/Fullscreen';
import { Arrow } from './components/Arrow';
import { Message } from './components/Message';
import { NotRenders } from './components/NotRenders';
import { Confirm } from './components/Confirm';
import { ImproveModal } from './components/Improve';
import { ZOOM_PARAMS } from './constants';
import * as effects from './modules/effects';
import * as getData from './modules/getdata';
import { Analytics } from './modules/analytics';

const observer = new window.POPObserver();

export const App = () => {
  const state = useSelector((state) => state);
  const fullScreenEl = useRef(null);
  const transformWrapperEl = useRef(null);
  const [confirmation, setConfirmation] = useState({
    open: false,
    data: null,
    title: 'gallery.confirm.title',
    agree: 'gallery.confirm.agree',
  });
  const [improveOpen, setImproveOpen] = useState(false);
  const [hiddenGallery, setHiddenGallery] = useState(false); // Прячем галереи когда открывается поверх дизайн проект или редактирование изображения
  const { images, current_image, loading, settings, user_settings, lazy_images, isFill } = state;
  const { processing, error, firstLoading } = loading;
  const { mode, isPreviewMode, imageUrl, previewUrl, panoramaUrl, tourUrl, sharedUser, zoom, projectId, team_id } = settings;
  const { cart_count } = user_settings;
  const isEmpty = (!processing || !firstLoading) && !error && images.length === 0;
  const isShowScreen = (!processing || !firstLoading) && !error && images.length !== 0;

  const [isFullScreen, setFullScreen] = useFullScreen(fullScreenEl);
  const dispatch = useDispatch();

  const onNext = () => dispatch(effects.setNext());
  const onPrev = () => dispatch(effects.setPrev());
  const onDownload = (item) => {
    Analytics.onDownload(projectId);
    dispatch(effects.download(item));
  };
  const onRemoveWithConfirm = (items) => {
    const isMany = (items || []).length > 1;

    setConfirmation({
      open: true,
      data: items,
      title: isMany ? 'gallery.confirm.titleMany' : 'gallery.confirm.title',
      agree: isMany ? 'gallery.confirm.agreeMany' : 'gallery.confirm.agree',
    });
  };
  const onAgreeRemove = () => {
    onRemove(confirmation.data);
    setConfirmation({open: false, data: null});
  };
  const onCancelRemove = () => {
    setConfirmation({open: false, data: null});
  };
  const onRemove = (items) => {
    Analytics.onRemove(projectId);
    dispatch(effects.remove(items));
  };
  const onCreateRender = () => {
    Analytics.createRender(projectId);
    dispatch(effects.createRender());
  };
  const onImproveRender = () => {
    Analytics.onImproveRender(projectId);
    setImproveOpen(true);
  };
  const onChangeFullScreen = () => setFullScreen();
  const onKeyDown = (event) => {
    event.code === 'ArrowLeft' && dispatch(effects.setPrev());
    event.code === 'ArrowRight' && dispatch(effects.setNext());
  };
  const onOpenStore = (initialScreen) => dispatch(effects.openStore(initialScreen));
  const onSwipe = (e, isDisabledPan) => dispatch(effects.swipe(e, isDisabledPan));
  const onShare = (id, isTour = false) => {
    Analytics.onShare(projectId);
    effects.share(id, settings.locale, isTour);
  };
  const onCopyTourCode = (id) => effects.copeTourCode(id);
  const onAddComments = () => {};
  const onHomePage = () => effects.routeToHomePage();
  const onEdit = (params) => {
    if (isFullScreen) {
      onChangeFullScreen();
    }

    Analytics.onEdit(projectId);
    dispatch(effects.edit(params));
  };
  const onOpenTourEditor = (options = {}) => {
    const { disabled, isPreview } = options;

    if (isPreview) {
      observer.postMessage(ON_OPEN_BANNER, {
        alias: 'tourOnly4KAnd8K',
      });

      return;
    }

    if (disabled) {
      dispatch(effects.onOpenQuickStoreWithProplus());
    } else {
      observer.postMessage(ON_OPEN_TOUR_EDITOR, {
        locale: settings.locale,
        projectId,
        team_id,
        id: null
      });
    }
  };
  const onEditTourEditor = (id = null, structure = {}, options = {}) => {
    const { disabled } = options;

    if (disabled) {
      dispatch(effects.onOpenQuickStoreWithProplus());
    }  else {
      observer.postMessage(ON_OPEN_TOUR_EDITOR, {
        locale: settings.locale,
        projectId,
        team_id,
        id,
        structure
      });
    }
  };

  const isDisabledArrows = images.filter((item) => item.isFilter).length <= 1;
  const isDisabledFullscreen = !images.length;

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());

      if (imageUrl) {
        dispatch(getData.getOneImage(imageUrl, previewUrl));
      } else if (panoramaUrl || tourUrl) {
        dispatch(getData.getOnePanorama(panoramaUrl || tourUrl));
      } else {
        await dispatch(getData.getProfile());
        dispatch(getData.getGallery());
        dispatch(getData.getGoods());
      }
    })();

    document.addEventListener('keydown', onKeyDown);

    if (!window.cabinetIsOpen) {
      notifications.init('gallery-notifications');
    }

    observer.addEventListener(ON_CHANGE_PAYMENT_STATUS_STORE, async (status) => {
      if (status === 'succeeded') {
        dispatch(getData.getProfile());
        dispatch(getData.getGallery({ reload: true }));
        dispatch(getData.getGoods());
      }
    });

    observer.addEventListener(ON_GALLERY_SHOW, async () => setHiddenGallery(false));
    observer.addEventListener(ON_GALLERY_HIDE, async () => setHiddenGallery(true));

    return () => {
      observer.removeEventListener(ON_GALLERY_SHOW);
      observer.removeEventListener(ON_GALLERY_HIDE);
      observer.removeEventListener(ON_CHANGE_PAYMENT_STATUS_STORE);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={{ fullScreen: isFullScreen, previewMode: isPreviewMode }}>
        <Container id="gallery_container" ref={fullScreenEl} hiddenGallery={hiddenGallery} onClick={() => hiddenGallery && setHiddenGallery(false)}>
          <Wrapper scaleFactor={zoom}>
            <InnerWrapper>
              <SideLeft><Arrow onClick={onPrev} direction="prev" disabled={isDisabledArrows} /></SideLeft>

              <Inner>
                <Gallery>
                  <SidebarWrapper>
                    {isShowScreen && <Sidebar images={images} current_image={current_image} onRemove={onRemoveWithConfirm} />}
                  </SidebarWrapper>

                  <Content>
                    {processing && !error && <Loader overlay={!firstLoading} />}

                    {error && <Message>{error.render}</Message>}

                    {isShowScreen && (
                      <TransformWrapper
                        positionX={0}
                        positionY={0}
                        defaultPositionX="0"
                        defaultPositionY="0"
                        wheel={{
                          step: isFullScreen || isPreviewMode ? ZOOM_PARAMS.STEP_WHEEL_FULLSCREEN : ZOOM_PARAMS.STEP_WHEEL,
                        }}
                        zoomIn={{ step: ZOOM_PARAMS.STEP_BUTTON }}
                        zoomOut={{ step: ZOOM_PARAMS.STEP_BUTTON }}
                        pan={{ disableOnTarget: ['is-disabled-pan'] }}
                        options={{
                          limitToBounds: true,
                          transformEnabled: true,
                          disabled: !!current_image.krpano_url || !current_image.isComplete || !!current_image.tour,
                          limitToWrapper: true,
                          minScale: ZOOM_PARAMS.MIN,
                          maxScale: ZOOM_PARAMS.MAX,
                        }}>
                        {({ zoomIn, zoomOut, scale, resetTransform, setTransform, positionX, positionY }) => (
                          <>
                            <CanvasWrap ref={transformWrapperEl}>
                              <Canvas
                                images={images}
                                lazy_images={lazy_images}
                                current_image={current_image}
                                scale={scale}
                                setTransform={setTransform}
                                onSwipe={onSwipe}
                                isFill={isFill}
                                isFullScreen={isFullScreen}
                                isPreviewMode={isPreviewMode}
                              />
                            </CanvasWrap>
                            <Bar
                              current_image={current_image}
                              mode={mode}
                              sharedUser={sharedUser}
                              isPreviewMode={isPreviewMode}
                              isFullScreen={isFullScreen}
                              onDownload={onDownload}
                              onRemove={onRemoveWithConfirm}
                              onCreateRender={onCreateRender}
                              onImproveRender={onImproveRender}
                              onShare={onShare}
                              onCopyTourCode={onCopyTourCode}
                              onEdit={onEdit}
                              onAddComments={onAddComments}
                              onHomePage={onHomePage}
                              onOpenTourEditor={onOpenTourEditor}
                              onEditTourEditor={onEditTourEditor}
                              zoomIn={zoomIn}
                              zoomOut={zoomOut}
                              scale={scale}
                              resetTransform={resetTransform}
                              setTransform={setTransform}
                              transformWrapperEl={transformWrapperEl}
                              positionX={positionX}
                              positionY={positionY}
                            />
                          </>
                        )}
                      </TransformWrapper>
                    )}
                    {(isEmpty && !processing) && <NotRenders cart_count={cart_count} onOpenStore={onOpenStore} />}
                  </Content>
                </Gallery>

                <Controls>
                  <Close />
                  <Fullscreen onChangeFullScreen={onChangeFullScreen} disabled={isDisabledFullscreen} />
                </Controls>
              </Inner>

              <SideRight><Arrow onClick={onNext} direction="next" disabled={isDisabledArrows} /></SideRight>
            </InnerWrapper>

            {/* Модальное окно для удаления рендеров */}
            {confirmation.open && <Confirm title={confirmation.title} agree={confirmation.agree} onAgree={onAgreeRemove} onCancel={onCancelRemove}/>}

            {/* Модальное окно для улучшения рендера */}
            <ImproveModal isOpen={improveOpen} onClose={() => setImproveOpen(false)}  zoom={zoom}/>
          </Wrapper>

          <div id="gallery-notifications" />
        </Container>
      </ThemeProvider>
    </>
  );
};

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 442;
  background-color: ${rgba(Colors.black, 0.4)};
  opacity: ${({ hiddenGallery }) => hiddenGallery ? 0 : 1};
`;

const SidebarWrapper = styled.div`
  width: 263px;
  height: 100%;
  border-right: 1px solid ${Colors.coal};
  flex: 0 0 263px;
  display: ${({ theme }) => (theme.fullScreen || theme.previewMode ? 'none' : 'block')};
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => (theme.fullScreen || theme.previewMode ? '0' : '60px 80px')};
  display: flex;
`;
const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;
const Side = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  display: ${({ theme }) => theme.previewMode ? 'none' : 'flex'};
  opacity: ${({ theme }) => theme.fullScreen ? 0.8 : 1};
  align-items: center;
`;
const SideLeft = styled(Side)`
  right: ${({ theme }) => theme.fullScreen ? 'auto' : '100%'};
  left: ${({ theme }) => theme.fullScreen ? '10px' : 'auto'};
  margin-right: 10px;
`;
const SideRight = styled(Side)`
  left: ${({ theme }) => theme.fullScreen ? 'auto' : '100%'};
  right: ${({ theme }) => theme.fullScreen ? '10px' : 'auto'};
  margin-left: 10px;
`;
const Gallery = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.night};
  display: flex;
  border-radius: ${({ theme }) => (theme.fullScreen || theme.previewMode ? '0' : '5px')};
  overflow: hidden;
  border: 1px solid ${Colors.coal};
`;
const Controls = styled.div`
  display: ${({ theme }) => (theme.previewMode ? 'none' : 'block')};
  position: absolute;
  top: ${({ theme }) => theme.fullScreen ? '10px' : '0'};
  left: ${({ theme }) => theme.fullScreen ? 'auto' : '100%'};
  right: ${({ theme }) => theme.fullScreen ? '10px' : 'auto'};
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const CanvasWrap = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;
