import React, { useContext } from 'react';
import { MODE_ALIAS, UNITY, SITE, URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { StoreContext } from '../../modules/Store';
import { ON_OPEN_CABINET } from '@observer/constants';
import * as effects from '../../../store/modules/effects';

const observer = new window.POPObserver();

export const UrlButton = ({ component: Component, href, children, blank, useUtm = false, afterClick = () => {}, ...restProps }) => {
  const [state] = useContext(StoreContext);
  const { mode, locale, user_id } = state.settings;

  const separate = href.includes('?') ? '&' : '?';
  const utm = useUtm ? `${separate}utm_source=(direct)&utm_medium=Standalone&utm_campaign=${user_id}` : '';

  const unityHref = href.includes('https://')
    ? `${href}${utm}`
    : `https://${window.location.host}/${locale}${href}${utm}`;
  const siteHref = href.includes('https://') ? href : `/${locale}${href}`;

  const onClick = (event) => {
    if (MODE_ALIAS[mode] === UNITY) {
      event.preventDefault();

      if (href.search('/cabinet') === 0) {
        if (observer) {
          observer.postMessage(ON_OPEN_CABINET, {
            path: href.replace('/cabinet', ''),
          });

          effects.closeStore(state);
        }
      } else {
        window.invokeEditorAction({
          name: url[URL_OPEN].name,
          value: unityHref,
        });
      }
    }

    afterClick();
  };

  const hrefTo = () => {
    return MODE_ALIAS[mode] === SITE ? siteHref : null;
  };

  return (
    <Component blank={hrefTo() ? blank : null} onClick={onClick} href={hrefTo()} {...restProps}>
      {children}
    </Component>
  );
};
