import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_IMAGES,
  SET_PROJECTS,
  SET_CREATING_PDF,
  SET_DESIGN_PROJECT,
  SET_DESIGN_PROJECT_NAME,
  SET_DESIGN_PROJECT_DESC,
  SET_DESIGN_PROJECT_IMAGES,
  SET_DELETE_DESIGN_PROJECT_IMAGE,
  SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME,
  SET_HAS_PDF,
  SET_PROJECT_NAME,
  SET_PROFILE,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_IMAGES:
      return { ...state, images: action.payload };
    case SET_PROJECTS:
      return { ...state, projects: action.payload };
    case SET_PROFILE:
      return { ...state, profile: action.payload };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
              text: action.payload.text || '',
            },
          },
          processing: true,
          error: '',
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }
    case SET_CREATING_PDF:
      return { ...state, creatingPdf: action.payload };
    case SET_HAS_PDF:
      return { ...state, has_pdf: action.payload };
    case SET_PROJECT_NAME:
      return { ...state, project_name: action.payload };
    case SET_DESIGN_PROJECT:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          ...action.payload,
        }
      };
    case SET_DESIGN_PROJECT_NAME:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          name: action.payload,
        }
      };
    case SET_DESIGN_PROJECT_DESC:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          description: action.payload,
        }
      };
    case SET_UPDATE_DESIGN_PROJECT_IMAGE_NAME:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          images: state.design_project.images.map(item => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                name: action.payload.name
              }
            } else {
              return item
            }
          })
        }
      };
    case SET_DESIGN_PROJECT_IMAGES:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          images: action.payload,
        }
      };
    case SET_DELETE_DESIGN_PROJECT_IMAGE:
      return {
        ...state,
        design_project: {
          ...state.design_project,
          images: state.design_project.images.unshift(action.payload),
        }
      };
    default:
      return state;
  }
};
