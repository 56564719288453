export const formatDate = (timeStamp) => {
  if (!timeStamp) return null;

  const withZero = (number) => {
    return ('0' + number).slice(-2);
  };

  const date = new Date(parseInt(timeStamp + '000'));

  if (isNaN(date.getDate())) return timeStamp;

  const day = `${withZero(date.getDate())}`;
  const month = `${withZero(date.getMonth() + 1)}`;
  const year = `${date.getFullYear()}`;

  return `${day}.${month}.${year}`;
};

export const formatDateWithTime = (timeStamp) => {
  const result = {
    time: null,
    date: null,
  };

  if (!timeStamp) return result;

  const withZero = (number) => {
    return ('0' + number).slice(-2);
  };

  const date = new Date(parseInt(timeStamp + '000'));

  if (isNaN(date.getDate())) return result;

  const hour = `${withZero(date.getHours())}`;
  const minutes = `${withZero(date.getMinutes())}`;
  const day = `${withZero(date.getDate())}`;
  const month = `${withZero(date.getMonth() + 1)}`;
  const year = `${date.getFullYear()}`;

  result.time = `${hour}:${minutes}`;
  result.date = `${day}.${month}.${year}`;

  return result;
};
