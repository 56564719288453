import { URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { translations } from '@libs';
import { ApiCall } from '../../modules/api-call';
import { notifications } from '@features/notifications';
import {
  GET_BINDING_DATA,
  SET_LOADING_ON,
  SET_LOADING_OFF,
} from '../../constants';

export const getBindingData = async (dispatch, state, { country, email, team_id }) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_BINDING_DATA } });

  const getAnalyticParams = () => {
    try {
      const sessionCookie = '_ga_4TZBNGEL7D';

      return {
        client_id: window.gaGlobal && window.gaGlobal.vid,
        session_id: document.cookie.match(`(^|;)\\s*${sessionCookie}\\s*=\\s*([^;]+)`).pop().split('.')[2],
        session_number: document.cookie.match(`(^|;)\\s*_ga_4TZBNGEL7D\\s*=\\s*([^;]+)`).pop().split('.')[3],
      }
    }
    catch (error) {
      return {
        client_id: '',
        session_id: '' ,
      }
    }
  };

  try {
    const analyticParams = getAnalyticParams();
    const params = { country, email, team_id, ...analyticParams };
    const bindingResponse = await ApiCall.getBindingData({ params });
    const { redirect } = bindingResponse.data.data;

    if (!redirect) throw new Error('Redirect url error');

    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: redirect,
    });

    dispatch({type: SET_LOADING_OFF, payload: {name: GET_BINDING_DATA}});

    return { success: true };
  } catch (error) {
    dispatch({type: SET_LOADING_OFF, payload: {name: GET_BINDING_DATA}});
    notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);

    return { success: false };
  }
};

export const getBindingCard = async (dispatch, state) => {
  try {
    const data = await ApiCall.getBindingCard();

    if (!data.success) {
      throw new Error('server');
    }

    return data;
  } catch (error) {
    return { success: false };
  }
};
