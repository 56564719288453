import React from 'react';
import { ERROR } from '../constants';
import { translations } from '@libs';
import { Link } from '@components/link';

// т.к запросы за переводами выполняются асинхронно
const criticalErrorMessages = {
  ru: {
    server: {
      type: ERROR,
      render: 'Произошла ошибка сервера',
    },
    login: {
      type: ERROR,
      render: (
        <>
          Пожалуйста, авторизируйтесь на сайте&nbsp;
          <Link fontSize="inherit" href="https://planoplan.com">
            https://planoplan.com
          </Link>
        </>
      ),
    },
  },
  en: {
    server: {
      type: ERROR,
      render: 'Server error occurred',
    },
    login: {
      type: ERROR,
      render: (
        <>
          Please login at&nbsp;
          <Link fontSize="inherit" href="https://planoplan.com">
            https://planoplan.com
          </Link>
        </>
      ),
    },
  },
};

export const getErrorMessages = (localeUi, key) => {
  const errorMessages = {
    'Project not found': translations.t('gallery.error.projectnotfound'),
  };

  const critical = criticalErrorMessages[localeUi][key];

  if (critical) {
    return critical;
  }

  return {
    type: ERROR,
    render: errorMessages[key] || key,
  };
};
