import React, { useState } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconChevron } from '../../../components/icons';
import { SORTING } from '../constants';

export const Sorting = ({ currents = {}, setSorting = {} }) => {
  const [openSorting, setOpenSorting] = useState({
    [SORTING.type.alias]: false,
    [SORTING.base.alias]: false,
  });

  const onChange = (type, item) => {
    if (item.alias === currents[type].alias) {
      return;
    }

    setSorting({
      ...currents,
      [type]: item
    });

    isCloseList(type);
  };

  const isToggleList = (type) => {
    setOpenSorting({
      ...openSorting,
      [type]: !openSorting[type],
    });
  };

  const isCloseList = (type) => {
    setOpenSorting({
      ...openSorting,
      [type]: false,
    })
  };

  return (
    <View>
      {Object.values(SORTING).map(sorting => currents[sorting.alias] && (
        <SortingItem key={sorting.alias} style={{minWidth: `${sorting.width}px`}}>
          <Current isOpen={openSorting[sorting.alias]} onClick={() => isToggleList(sorting.alias)}>
            <Text>{currents[sorting.alias].name()}</Text>
            <IconChevron />
          </Current>

          {openSorting[sorting.alias] && <Overlay onClick={() => isCloseList(sorting.alias)} />}

          {openSorting[sorting.alias] &&
            <List>
              {Object.values(sorting.items).map(item => (
                <Item key={item.alias} isActive={item.alias === currents[sorting.alias].alias} onClick={() => onChange(sorting.alias, item)}>{item.name()}</Item>
              ))}
            </List>
          }
        </SortingItem>
      ))}
    </View>
  )
};

const View = styled.div`
  display: flex;
`;
const SortingItem = styled.div`
  margin-right: 10px;
  position: relative;
`;
const Current = styled.div`
  ${Mixins.transition('color')};
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ isOpen }) => isOpen ? Colors.planoplan_blue : Colors.feldgrau};
  
  svg {
    ${Mixins.transition('fill')};
    margin-bottom: -2px;
    transform: rotate(90deg);
    fill: ${({ isOpen }) => isOpen ? Colors.planoplan_blue : Colors.feldgrau};
  }
  
  &:hover {
    color: ${Colors.planoplan_blue};
    
    svg {
      fill: ${Colors.planoplan_blue}
    }
  }
`;
const Text = styled.div`
  margin-right: 2px;
  font-size: 16px;
  line-height: 19px;
`;
const List = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${Colors.columbia25};
  border: 1px solid ${Colors.columbia75};
  border-radius: 5px;
  box-shadow: 2px 2px 0 ${Colors.columbia75};
  padding: 10px 0;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
  z-index: 4;
`;
const Item = styled.div`
  white-space: nowrap;
  cursor: pointer;
  padding: 5px 20px;
  color: ${({ isActive }) => isActive ? Colors.white : Colors.feldgrau};
  background-color: ${({ isActive }) => isActive ? Colors.blueBell : 'transparent'};

  &:hover {
    color: ${Colors.white};
    background-color: ${Colors.blueBell};
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
`;
