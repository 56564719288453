// Если изображение не попадает в текущую высоту сайдбара, то прокручиваем на его offsetTop
export const changeScroll = (scrollBar, image) => {
  if (!image) {
    return;
  }

  const scrollBarHeight = scrollBar.offsetHeight;
  const scrollPosition = scrollBar.scrollTop;
  const topImage = image.offsetTop;
  const heightImage = image.offsetHeight;

  const isImageNotViewport = scrollBarHeight < topImage - scrollPosition + heightImage / 2;
  const isReverseScroll = topImage - scrollPosition < 0;

  if (isImageNotViewport || isReverseScroll) {
    scrollBar.scrollTop = topImage;
  }
};
