import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { IconChevron } from '@icons';

export const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

export const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};

export const Select = ({ options, value, onChange = () => {} }) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef();

  const onClickOutside = (event) => {
    if (!selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  const onSelect = (option) => {
    onChange(option);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', onClickOutside);

    return () => document.removeEventListener('click', onClickOutside);
  });

  return (
    <View>
      <Selected open={open} onClick={() => setOpen(!open)} ref={selectRef}>
        <Value dangerouslySetInnerHTML={{ __html: value.caption }} />

        <Icon open={open}>
          <IconChevron />
        </Icon>
      </Selected>

      {open &&
        <Options>
          <ScrollBar
            options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }}
            rail_y={rail_y}
            thumb_y={thumb_y}>
            {options.map(option => (
              <Option key={option.value} onClick={() => onSelect(option)} active={option.value === value.value} dangerouslySetInnerHTML={{ __html: option.caption }} />
            ))}
          </ScrollBar>
        </Options>
      }
    </View>
  );
};

const View = styled.div`
  position: relative;
  width: 100%;
`;
const Selected = styled.div`
  ${Mixins.transition('border-color')};
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${({ open }) => open ? Colors.dark_cream : Colors.nobel};
  background-color: ${Colors.night};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  padding-left: 15px;
  padding-right: 5px;
  cursor: pointer;
  color: ${Colors.light_gray};
  
  &:hover {
    border-color: ${Colors.dark_cream};
  }
`;
const Value = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Icon = styled.div`
  ${Mixins.transition('transform')};
  flex-shrink: 0;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0)'};
  
  svg {
    display: block;
  }
`;
const Options = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-top: 5px;
  width: 100%;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 4px;
  height: 140px;
`;
const Option = styled.div`
  padding: 12px 5px 12px 15px;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${Colors.light_gray};
  border: 1px solid ${({ active }) => active ? Colors.dark_cream : 'transparent'};
  cursor: pointer;
  
  &:hover {
    border: 1px solid ${Colors.dark_cream};
  }
  
  &:not(:first-child) {
    margin-top: -1px;
  }
`;
