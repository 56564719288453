import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const FileInput = ({ id, desc, accept, ...restProps }) => (
  <Wrapper>
    <Input type="file" id={id} accept={accept} {...restProps} />
    <Label htmlFor={id}>
      {translations.t('cabinet.misc.upload')}
    </Label>
    <Desc>{desc}</Desc>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 4px;
`;
const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
`;
const Label = styled.label`
  width: 150px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid ${Colors.light_gray};
  margin-bottom: ${({ modify }) => (modify === 'between' ? '0' : '11px')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${Colors.feldgrau};
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    border-color: 1px solid ${Colors.planoplan_blue};
    background-color: ${Colors.planoplan_blue};
    color: ${Colors.white};
  }
`;
const Desc = styled.div`
  width: 145px;
  height: 34px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${Colors.feldgrau50};
  margin: 0 0 7px 10px;
`;
