import React from 'react';

export const IImage = ({ width = 48, height = 48, fill = '#ffffff' }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="8" width="40" height="32" stroke={fill} strokeDasharray="1 1"/>
      <path d="M14.4688 23.6965C14.7865 23.8282 15.1248 23.9202 15.4776 23.9662L15.4129 24.462C15.6053 24.4871 15.8012 24.5 16 24.5C16.1988 24.5 16.3947 24.4871 16.5871 24.462L16.5224 23.9662C16.8752 23.9202 17.2135 23.8282 17.5312 23.6965L17.7227 24.1583C18.0888 24.0065 18.4303 23.8079 18.7398 23.5701L18.4351 23.1736C18.7122 22.9607 18.9607 22.7122 19.1736 22.4351L19.5701 22.7398C19.8079 22.4303 20.0065 22.0888 20.1583 21.7227L19.6965 21.5312C19.8282 21.2135 19.9202 20.8752 19.9662 20.5224L20.462 20.5871C20.4871 20.3947 20.5 20.1988 20.5 20C20.5 19.8012 20.4871 19.6053 20.462 19.4129L19.9662 19.4776C19.9202 19.1248 19.8282 18.7865 19.6965 18.4688L20.1583 18.2773C20.0065 17.9112 19.8079 17.5697 19.5701 17.2602L19.1736 17.5649C18.9607 17.2878 18.7122 17.0393 18.4351 16.8264L18.7398 16.4299C18.4303 16.1921 18.0888 15.9935 17.7227 15.8417L17.5312 16.3035C17.2135 16.1718 16.8752 16.0798 16.5224 16.0338L16.5871 15.538C16.3947 15.5129 16.1988 15.5 16 15.5C15.8012 15.5 15.6053 15.5129 15.4129 15.538L15.4776 16.0338C15.1248 16.0798 14.7865 16.1718 14.4688 16.3035L14.2773 15.8417C13.9112 15.9935 13.5697 16.1921 13.2602 16.4299L13.5649 16.8264C13.2878 17.0393 13.0393 17.2878 12.8264 17.5649L12.4299 17.2602C12.1921 17.5697 11.9935 17.9112 11.8417 18.2773L12.3035 18.4688C12.1718 18.7865 12.0798 19.1248 12.0338 19.4776L11.538 19.4129C11.5129 19.6053 11.5 19.8012 11.5 20C11.5 20.1988 11.5129 20.3947 11.538 20.5871L12.0338 20.5224C12.0798 20.8752 12.1718 21.2135 12.3035 21.5312L11.8417 21.7227C11.9935 22.0888 12.1921 22.4303 12.4299 22.7398L12.8264 22.4351C13.0393 22.7122 13.2878 22.9607 13.5649 23.1736L13.2602 23.5701C13.5697 23.8079 13.9112 24.0065 14.2773 24.1583L14.4688 23.6965Z" stroke={fill} strokeDasharray="1 1"/>
      <path d="M44 33.6883L33.1667 22L18 40" stroke={fill} strokeDasharray="1 1"/>
    </svg>
  );
};
