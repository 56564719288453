import React from 'react';

export const IViewRow = ({ width = 24, height = 24, fill = '#4A4A4A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 3H2V11H11V3ZM22 3H13V11H22V3ZM2 13H11V21H2V13ZM22 13H13V21H22V13Z" fill={fill}/>
    </svg>

  );
};
