import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Input } from '@components/Form/Input';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const StripeForm = ({ form = [], setForm = () => {} }) => {
  const inputs = [
    {
      name: 'email',
      caption: translations.t('quickstore.stripe.email.caption'),
      placeholder: translations.t('quickstore.stripe.email.placeholder')
    },
    {
      name: 'line1',
      caption: translations.t('quickstore.stripe.line1.caption'),
      placeholder: translations.t('quickstore.stripe.line1.placeholder')
    },
    {
      name: 'line2',
      caption: translations.t('quickstore.stripe.line1.caption'),
      placeholder: translations.t('quickstore.stripe.line2.placeholder')
    },
    {
      name: 'postal_code',
      caption: translations.t('quickstore.stripe.postal.caption'),
      placeholder: translations.t('quickstore.stripe.postal.placeholder')
    },
    {
      name: 'city',
      caption: translations.t('quickstore.stripe.city.caption'),
      placeholder: translations.t('quickstore.stripe.city.placeholder')
    }
  ];

  const onChange = (event) => setForm({...form, [event.target.name]: event.target.value});

  return (
    <Form>
      {inputs.map((input, index) => (
        <InputWrap key={index}>
          <Input
            name={input.name}
            placeholder={input.placeholder}
            value={form[input.name]}
            onChange={onChange}>
            {input.caption}
          </Input>
        </InputWrap>
      ))}
    </Form>
  );
};

const Form = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding: 10px 30px 0 10px;
  border-right: 1px solid ${Colors.coal};
`;
const InputWrap = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`;
