/**
 * Заменяет переменные в строке на значения, переданные в аргументах. ${variable} => значение переменной
 *
 * @param {string} string - Строка, в которой нужно произвести замену переменных.
 * @param {...*} variables - Переменное количество аргументов, представляющих значения для замены переменных в строке.
 * @return {string} - Строка с замененными значениями переменных.
 */
export const replaceVariables = (string, ...variables) => {
  let index = 0;

  return string.replace(/\$\{(.*?)\}/g, function() {
    return variables[index++];
  });
};
