import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Slider } from '../Slider';
import * as effects from '../../modules/effects';
import { translations } from '@libs';

export const Adjustment = () => {
  const dispatch = useDispatch();
  const { adjustment } = useSelector(state => state);
  const onResetSection = (alias) => {
    dispatch(effects.resetAdjustmentSection(alias));
  };
  const onChangeAdjustment = (name, val) => {
    dispatch(effects.setAdjustment(name, val));
  };

  return (
    <View>
      {adjustment.map(({ alias, name, list }) => (
        <Section key={name}>
          <Top>
            <Title>{translations.t(name)}</Title>

            <Reset onClick={onResetSection.bind(null, alias)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="#D1D1D1" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.5001 12C18.5001 10.0554 17.4909 8.34659 15.9678 7.36856C15.433 7.02641 14.841 6.77825 14.2175 6.63717C13.3114 6.43214 12.368 6.46 11.4756 6.71815C10.5831 6.97631 9.77051 7.45634 9.11372 8.11346L9.10247 8.12437L6.92901 10.1667H9.66675C9.94289 10.1667 10.1667 10.3905 10.1667 10.6667C10.1667 10.9428 9.94289 11.1667 9.66675 11.1667H5.66675C5.59188 11.1667 5.52085 11.1502 5.45709 11.1207C5.4072 11.0977 5.3604 11.0661 5.31896 11.0259C5.30337 11.0108 5.28876 10.9947 5.27523 10.9777C5.24594 10.9409 5.22262 10.901 5.20521 10.8593C5.18115 10.8017 5.16842 10.7405 5.1669 10.6792C5.1668 10.675 5.16675 10.6708 5.16675 10.6667V6.66667C5.16675 6.39053 5.39061 6.16667 5.66675 6.16667C5.94289 6.16667 6.16675 6.39053 6.16675 6.66667V9.51073L8.4121 7.40088C9.18731 6.62718 10.1455 6.06188 11.1977 5.75753C11.7858 5.58742 12.3926 5.50192 13.0001 5.50157V5.5C14.3202 5.5 15.5483 5.89352 16.5737 6.56964C16.8358 6.74191 17.086 6.93348 17.3219 7.14322C18.1426 7.87265 18.7652 8.7981 19.1314 9.8332C19.1355 9.84479 19.1392 9.85643 19.1424 9.8681C19.3742 10.5359 19.5001 11.2533 19.5001 12C19.5001 15.5899 16.5899 18.5 13.0001 18.5C11.075 18.5 9.34478 17.6624 8.1553 16.3335C7.59605 15.7086 7.15561 14.9745 6.87007 14.1666C6.77805 13.9063 6.91451 13.6206 7.17487 13.5286C7.43523 13.4366 7.72089 13.573 7.81291 13.8334C8.05418 14.516 8.42666 15.1372 8.90044 15.6665C9.90824 16.7925 11.3712 17.5 13.0001 17.5C16.0376 17.5 18.5001 15.0376 18.5001 12Z"/>
              </svg>
            </Reset>
          </Top>

          {list.map((item) => (
            <SliderWrap key={item.name}>
              <Slider alias={`${alias}-${item.alias}`}
                      name={item.name}
                      value={item.value}
                      min={item.min}
                      max={item.max}
                      centerAt={item.centerAt}
                      onlyPositive={item.onlyPositive}
                      onChangeSlider={onChangeAdjustment}
                      onChangeInput={onChangeAdjustment} />
            </SliderWrap>
          ))}
        </Section>
      ))}
    </View>
  )
};

const View = styled.div`
  width: 100%;
  color: ${Colors.light_gray};
  padding: 0 15px 15px;
`;

const Section = styled.div`
  padding-top: 15px;
  
  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${Colors.coal};
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 17px;
`;

const Reset = styled.button`
  ${Mixins.resetButton()};
  
  svg {
    ${Mixins.transition('fill, transform')};
    display: block;
  }
  
  &:hover {
    svg {
      transform: rotate(-45deg);
      fill: ${Colors.white};
    }
  }
`;

const SliderWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
