import React  from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { IArrowLeft } from 'planoplan-ui-kit/core/icons/i-arrow-left';
import { IHelpCircle } from 'planoplan-ui-kit/core/icons/i-help-circle';
import { IArrowTop } from 'planoplan-ui-kit/core/icons/i-arrow-top';
import { UrlButton } from '@components';
import { Link } from '@components/link';
import { laptop } from '../../modules/breakpoints';
import { Indent } from '../../modules/indent';
import { isProdHost, translations } from '@libs';
import { DISPLAY_INTERFACE, DOMAINS, MODE_ALIAS, UNITY, URL_OPEN } from '@globalConstants';
import { actions, url } from '@globalInvoke';
import { Analytics } from '../../modules/analytics';

export const Sidebar = () => {
  const state = useSelector(state => state);
  const {
    educations: { alias, items },
    settings: { locale, mode }
  } = state;
  const educationsSlice = items ? Object.values(items).slice(0, 6) : [];
  const clickInterface = () => {
    Analytics.openLink(translations.t('projects.ui.interface'));

    if (MODE_ALIAS[mode] === UNITY) {
      window.invokeEditorAction({
        name: actions[DISPLAY_INTERFACE].name,
        value: true,
      });
    }
  };
  const clickLink = (item) => {
    const domain = isProdHost ? DOMAINS.PROD : DOMAINS.DEV;
    const href = `/education/videos/${alias}/#${item.id}`;
    const unityHref = href.includes('https://') ? href : `${domain}/${locale}${href}`;

    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: unityHref,
    });

    if (window.dataLayer) {
      Analytics.openLink(item.form_data.title);
    }
  };

  const openLink = (link, title) => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });

    if (window.dataLayer) {
      Analytics.openLink(title);
    }
  };

  return (
      <View>
        <Wrapper>
          <Top>
            <Title>{translations.t('projects.ui.educations')}</Title>

            <List>
              {educationsSlice.map((item) => (
                  <Item key={item.id}>
                    <UrlButton onClick={() => clickLink(item)} locale={locale} mode={mode} target="_blank" tag="a">
                      <ItemLink>{item.form_data.title}</ItemLink>
                    </UrlButton>
                  </Item>
              ))}
            </List>

            <UrlButton locale={locale} mode={mode} target="_blank" tag="a" onClick={() => openLink(`/education/videos/`, translations.t('projects.ui.allVideos'))}>
              <LinkArrow>{translations.t('projects.ui.allVideos')}<IArrowLeft fill={Colors.white} /></LinkArrow>
            </UrlButton>
          </Top>

          <Bottom>
            <UrlButton locale={locale} mode={mode} target="_blank" tag="a" onClick={() => openLink(`/education/buttons/`, translations.t('projects.ui.hotKey'))}>
              <LinkIcon><IArrowTop fill={Colors.white} width={16} height={16}/>{translations.t('projects.ui.hotKey')}</LinkIcon>
            </UrlButton>

            <LinkIcon onClick={clickInterface}><IHelpCircle fill={Colors.white} width={14} height={14}/>{translations.t('projects.ui.interface')}</LinkIcon>
          </Bottom>
        </Wrapper>
      </View>
  );
};

const View = styled.div`
  display: none;
  overflow: hidden;

  @media ${laptop} {
    display: flex;
    ${Grid.column(8, 30)};
    height: 660px;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media ${laptop} {
    ${Indent.padding_top(2.25)};
    ${Indent.padding_bottom(1.3)};
    ${Grid.suffix(0.25, 6)};
    ${Grid.prefix(1, 6)};
    width: auto;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`;

const Top = styled.div`
  width: 100%;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
`;

const List = styled.ul`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  margin-bottom: 16px;
  position: relative;
  
  &:after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Colors.nobel};
    position: absolute;
    top: 7px;
    left: 0px;
  }
`;

const mixinLink = `
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.white};
  padding-left: 14px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  
  &:hover {
    color: ${Colors.dark_cream};
  }
`

const ItemLink = styled(Link)`
  ${mixinLink};
`;

const LinkArrow = styled(Link)`
  ${mixinLink};
  
  svg {
    ${Mixins.transition('fill, transform')};
    transform: rotateY(180deg) translate(0, 2px);
    margin-left: 7px;
  }
  
  &:hover {
    svg {
      transform: rotateY(180deg) translate(-5px, 2px);
      fill: ${Colors.dark_cream};
    }
  }
`;

const LinkIcon = styled(Link)`
  ${mixinLink};
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.white};
  position: relative;
  margin-left: 14px;
  margin-top: 16px;
  padding: 0;
    
  svg {
    position: absolute;
    top: 0;
    right: 100%;
    transform: translate(-6px, 2px);
    margin-left: 7px;
  }
  
  &:hover {
    svg path {
      fill: ${Colors.dark_cream};
    }
  }
`;
