import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Render } from './render';
import * as effects from '../../../modules/effects';

export const Renders = ({ handleClick = () => {} }) => {
  const dispatch = useDispatch();
  const renders = dispatch(effects.getRendersGoods());

  return (
    <View>
      {renders.map(render => (
        <Item key={render.id}><Render render={render} handleClick={handleClick} /></Item>
      ))}
    </View>
  );
};

const View = styled.div``;
const Item = styled.div`
  margin-bottom: 10px;
`;