import { translations } from '@libs';
import { GET_TRANSLATES,
  GET_SLIDES,
  SET_SLIDES,
  SET_CURRENT,
  SET_LOADING_OFF,
  SET_LOADING_ON } from '../constants';
import { ApiCall } from './api-call';
import { preloadImages } from '../modules/effects';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, /^assistant./]);
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: error.message },
      });
    }
  }
};

export const getSlides = () => async (dispatch, getState) => {
  const { loading } = getState();

  if (loading.error) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_SLIDES } });

  try {
    const { data } = await ApiCall.getSlides();
    const { items } = data['interface-getting-started'];


    const slides = Object.values(items).map((item) => ({ id: item.id, alias: item.alias, ...item.form_data }));
    const sources = slides.map(slide => slide.image);
    const images = await preloadImages(sources.slice(0,1));
    const slidesWithLoadImg = slides.map((slide, index) => ({...slide, img: images[index]}));

    /*
      Загрузка остальных изображения кроме первого слайда.
      Первый слайд ждем пока загрузится.
      Свойство img и обновление SET_SLIDES нужно для того что бы работало кеширование изображения
    */
    preloadImages(sources).then((images) => {
      const slidesWithLoadImg = slides.map((slide, index) => ({...slide, img: images[index]}));

      dispatch({ type: SET_SLIDES, payload: slidesWithLoadImg});
    });

    dispatch({ type: SET_SLIDES, payload: slidesWithLoadImg});
    dispatch({ type: SET_CURRENT, payload: slidesWithLoadImg[0]});
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_SLIDES } });

  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_SLIDES, error: error },
    });
  }
};
