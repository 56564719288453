import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { ButtonStore, View as ButtonStoreView } from 'planoplan-ui-kit/core/buttonStore';
import { ButtonUser } from 'planoplan-ui-kit/core/buttonUser';
import { Toggle } from 'planoplan-ui-kit/core/toggle';
import { translations, ScrollLock } from '@libs';
import { User, Notifications } from './organism';
import { VIEW_MODE, ERROR_MESSAGES } from './constants';
import * as getData from './modules/get-data';
import * as effects from './modules/effects';

export const App = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { view, user, notifications, userSettings, currentTeam, isProcessing, settings, errors, tariff } = state;
  const { mode, rootElement } = settings;

  const toggleRef = useRef(null);

  const onClickAvatar = () => {
    toggleRef.current.toggle();
    ScrollLock.lock(rootElement);
    dispatch(effects.setView(VIEW_MODE.USER));
  };

  const onClickNotification = () => {
    toggleRef.current.toggle();
    ScrollLock.lock(rootElement);
    dispatch(effects.setView(VIEW_MODE.NOTIFICATION));
  };

  const onCloseToggle = () => {
    if (view === VIEW_MODE.NOTIFICATION) {
      dispatch(effects.clearNotifications());
    }
    setTimeout(() => {
      dispatch(effects.setView(VIEW_MODE.CLOSE));

      ScrollLock.unlock(rootElement);
    }, 300);
  };

  const onCabinet = () => effects.cabinet(mode);
  const onOpenStore = () => effects.openStore();
  const onOpenStoreCart = () => effects.openStoreCart();
  const onLogout = () => {
    toggleRef.current.close();
    ScrollLock.unlock(rootElement);
    effects.logout(mode);
  };
  const onChangeUser = (id = '') => {
    toggleRef.current.close();
    ScrollLock.unlock(rootElement);
    dispatch(effects.setCurrentUser(id));
  };
  const onOpenNotification = (item) => {
    toggleRef.current.close();
    ScrollLock.unlock(rootElement);
    effects.clickItemNotification(item, mode, tariff.alias);
  };
  const onMouseEnter = () => effects.pointerOffInterface(mode);
  const onMouseLeave = () => effects.pointerOnInterface(mode);

  const isTeam = !!currentTeam;

  useEffect(() => {
    (async () => {
      dispatch(getData.getGoods());
      dispatch(getData.getUserData());
      dispatch(getData.getTeams());
      await dispatch(getData.getUserSettings());
      dispatch(getData.getNotification());
      dispatch(getData.startAutoUpdateNotification());
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isProcessing) {
      (async () => {
        dispatch(getData.getGoods());
        await dispatch(getData.getUserSettings());
        dispatch(getData.getNotification());
      })();
    }
    // eslint-disable-next-line
  }, [currentTeam]);

  // Разлогин, если пришли ошибки
  useEffect(() => {
    let logout = true;

    for (const type in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, type) && errors[type] === ERROR_MESSAGES.ABORTED) {
        logout = false;
      }
    }

    if (Object.keys(errors).length && logout) {
      onLogout();
    }
  }, [errors]);

  return (
    <View onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ButtonStore
        name={translations.t('user_widget.button.store')}
        count={userSettings.cart_count}
        onClickName={onOpenStore}
        onClickCart={onOpenStoreCart}
      />
      <Toggle
        ref={toggleRef}
        onCloseToggle={onCloseToggle}
        target={
          <ButtonUser
            name={translations.t('user_widget.button.cabinet')}
            count={notifications.length}
            isLoading={isProcessing}
            avatarSrc={isTeam ? user.team.avatar : user.user.avatar}
            onClickAvatar={onClickAvatar}
            onClickName={onCabinet}
            onClickNotification={onClickNotification}
          />
        }>
        {view === VIEW_MODE.USER && <User onChangeUser={onChangeUser} onLogout={onLogout} />}

        {view === VIEW_MODE.NOTIFICATION && <Notifications onOpenNotification={onOpenNotification} />}
      </Toggle>
    </View>
  );
};

const View = styled.div`
  display: flex;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  -webkit-font-smoothing: antialiased;

  ${ButtonStoreView} {
    margin-right: 4px;
  }
`;
