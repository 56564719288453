import { SET_CURRENT } from '../constants';

export const onNextSlide = () => (dispatch, getState) => {
  try {
    const { slides, current } = getState();
    const index = slides.map(slide => slide.id).indexOf(current.id);
    const nextSlide = slides[index + 1];

    if (!nextSlide) return;

    dispatch({ type: SET_CURRENT, payload: nextSlide});
  } catch (error) {
    console.error(error);
  }
};

export const onPrevSlide = () => (dispatch, getState) => {
  try {
    const { slides, current } = getState();
    const index = slides.map(slide => slide.id).indexOf(current.id);
    const prevSlide = slides[index - 1];

    if (!prevSlide) return;

    dispatch({ type: SET_CURRENT, payload: prevSlide});
  } catch (error) {
    console.error(error);
  }
};

export const preloadImages = (imageUrls = []) => {
  return new Promise((resolve, reject) => {
    let loadedImagesCount = 0;
    const totalImages = imageUrls.length;
    const images = [];
    const errors = [];

    if (totalImages === 0) {
      resolve(images);

      return;
    }

    imageUrls.forEach((url, index) => {
      images[index] = new Image();

      // Проверяем, не загружено ли изображение уже в кэше
      images[index].onload = () => {
        loadedImagesCount++;

        if (loadedImagesCount === totalImages) {
          if (errors.length === 0) {
            resolve(images);
          } else {
            reject(errors);
          }
        }
      };

      images[index].onerror = () => {
        errors.push(`Failed to load image: ${url}`);
        loadedImagesCount++;

        if (loadedImagesCount === totalImages) {
          reject(errors);
        }
      };

      // Устанавливаем src после определения обработчиков, чтобы избежать гонок
      images[index].src = url;
    });
  });
};
