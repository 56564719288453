import { Colors } from 'planoplan-ui-kit/core/colors';

export const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.timberwolf25,
  opacity_hover: '1',
};

export const thumb_y = {
  background_color: Colors.feldgrau25,
  opacity: '1',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.feldgrau50,
  opacity_hover: '1',
  width_hover: '6px',
};
