import React from 'react';
import { tablet_landscape } from '../../../modules/breakpoints';
import MediaQuery from 'react-responsive';
import { Input } from 'planoplan-ui-kit/core/input';

export const FieldInput = ({ type, name, value, hasError, title, required, onChange, ...restProps }) => (
  <MediaQuery query={tablet_landscape}>
    {(matches) => (
      <Input
        type={type}
        theme="secondary"
        size={matches ? 'M' : 'S'}
        name={name}
        required={required}
        value={value}
        hasError={hasError}
        onChange={onChange}
        data-test={`cart__input-${name}`}
        {...restProps}>
        {title}
      </Input>
    )}
  </MediaQuery>
);
