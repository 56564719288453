import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';
import { analytics } from '@features/form-entry/modules/analytics';

export const LogoutAnotherDevice = ({ isOpen, onCancel = () => {}, onAnotherAuthReset = () => {} }) => {
  useEffect(() => {
    if (isOpen && analytics) {
      analytics.pushEventGA4('standalone', 'login_double_device');
    }
  }, [isOpen]);

  const handleContinue = () => {
    if (analytics) {
      analytics.pushEventGA4('standalone', 'login_double_device_confirm');
    }

    onAnotherAuthReset();
  };
  const handleCancel = () => {
    if (analytics) {
      analytics.pushEventGA4('standalone', 'login_double_device_cаncel');
    }

    onCancel();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <Header>
        {translations.t('form_entry.authConflict')}
      </Header>
      <Content>
        {translations.t('form_entry.authConflict.text')}
      </Content>
      <Buttons>
        <Button theme="secondary" size="M" onClick={handleCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={handleContinue}>
          {translations.t('modal.continue')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
