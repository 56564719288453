import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { TextWithLinks } from '@components/TextWithLinks';

export const SocialUsedGoogle = ({ isOpen, onCancel = () => {} }) => (
  <Modal isOpen={isOpen} onClose={onCancel}>
    <TextWithLinks tag={Text} text={translations.t('users.profile.multiauth.google.popup.body.error.used')} />
    <Buttons>
      <Button theme="warning" size="M" onClick={onCancel}>
        {translations.t('users.profile.multiauth.google.popup.button.close')}
      </Button>
    </Buttons>
  </Modal>
);

const Text = styled.p`
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 160px;
  }
`;
