import React, { useState, useContext, useEffect } from 'react';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import styled from 'styled-components';
import { StoreContext } from '../../modules/Store';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import { Loader } from '../../components/Loader';
import * as effects from '../../modules/effects';

const rail_y = {
  width: '15px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '11px',
};

export const Paypal = () => {
  const [state, dispatch] = useContext(StoreContext);
  const [loading, seLoading] = useState(true);
  const { payment: { orderid, amount, client_id, currency_code }} = state;

  const checkOrder = (id) => effects.checkPayStatus(dispatch, state, id);
  const errorPay = (error) => {
    if (error) {
      notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
    } else {
      notifications.showError(`${translations.t('store.common.payment_error')}`);
    }
  };
  const initPaypal = () => {
    seLoading(false);

    if (!window.paypal) {
      return;
    }

    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: `${amount}`
            },
            custom_id: orderid,
          }]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        const { id, status } = order;

        if (status === 'COMPLETED') {
          checkOrder(id);
        } else {
          errorPay();
        }
      },
      onError:  (error) => {
        console.error(error); // eslint-disable-line

        // Closed pay popup
        if (error.message.includes('close')) {
          return;
        }

        errorPay(error);
      }
    }).render('#paypal-button-container');
  };

  useEffect(() => {
    if (!window.paypal) {
      const script = document.createElement('script');

      script.src = `https://www.paypal.com/sdk/js?client-id=${client_id}&currency=${currency_code}`;
      script.async = true;
      script.onload = () => initPaypal();

      document.body.appendChild(script);
    } else {
      initPaypal();
    }
  }, []);

  return (
    <View>
      {loading &&
        <Loader overlay={true} />
      }

      <Wrapper>
        <Buttons id="paypal-button-container" />
      </Wrapper>
    </View>
  );
};

const View = styled.div`
  transform: scale(${(p) => p.scaleFactor});
  transform-origin: 0 0;
  height: 100%;
  width: 100%;
  display:flex;
  justify-content: center;
`;
const Wrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 460px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
