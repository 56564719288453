import { translations } from '@libs';
import { getInitialImage, isUpdatedImages, loadingImage } from '../modules/helpers';
import {
  GET_GALLERY,
  GET_TRANSLATES,
  GET_USER_SETTINGS,
  GET_PROFILE,
  GET_GOODS,
  SET_FILTERS,
  SET_IMAGES,
  SET_PROJECTS,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_USER_SETTINGS,
  SET_LAZY_IMAGES,
  SET_HAS_PDF,
  SET_PROJECT_NAME,
  SET_PROFILE,
  SET_PROFILE_GOODS,
  AUTO_UPDATE_TIMEOUT,
} from '../constants';
import { getErrorMessages } from './error-messages';
import { normalizeData, normalizeOneImage, normalizeLazyImages } from './normalize';
import { setCurrentImage } from './effects';
import { isNeedUpdate } from './helpers';
import { ApiCall } from './api-call';
import { Analytics } from './analytics';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { localeUi } = getState().settings;

    try {
      await translations.load(localeUi, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, /^image_editor./, /^assistant./]);
    } catch (error) {
      console.error(error);

      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: getErrorMessages(localeUi, error.message) },
      });
    }
  }
};

export const getGallery = ({ reload = false } = {}) => async (dispatch, getState) => {
  const { settings, loading, current_image } = getState();
  const openParamImageId = reload ? current_image.id : null;

  if (loading.error) {
    return;
  }

  const { locale, localeUi, projectId, openImageId } = settings;
  const params = { id: projectId };

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GALLERY } });

  try {
    const { data } = await ApiCall.getGallery({ params });

    const { images, filters, projects } = normalizeData(data);
    const lazy_images = normalizeLazyImages(images);
    const current_image = getInitialImage(images, openParamImageId || openImageId);

    dispatch({ type: SET_PROJECTS, payload: projects });
    dispatch({ type: SET_IMAGES, payload: images });
    dispatch({ type: SET_FILTERS, payload: filters });
    dispatch({ type: SET_LAZY_IMAGES, payload: lazy_images });
    dispatch(setCurrentImage(current_image));
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });
    dispatch({ type: SET_HAS_PDF, payload: data.hasPdfData });
    dispatch({ type: SET_PROJECT_NAME, payload: data.projectName });

    if (isNeedUpdate(images)) {
      dispatch(autoUpdate());
    }

    Analytics.openGallery(locale, data.projectId, data.projectName, Array.isArray(images) ? images.length : 0);
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_GALLERY, error: getErrorMessages(localeUi, error.message) },
    });
  }
};

export const getGoods = () => async (dispatch, getState) => {
  const { localeUi, team_id } = getState().settings;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GOODS } });

  try {
    const { data } = await ApiCall.getGoods({ team_id });

    dispatch({ type: SET_PROFILE_GOODS, payload: data.data });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS } });

    return data.data
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS, error: getErrorMessages(localeUi, error.message) } });

    return [];
  }
};

export const getGalleryImages = ({ selectNew = true } = {}) => async (dispatch, getState) => {
  const { current_image, settings: { localeUi, projectId }} = getState();
  const params = { id: projectId };

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GALLERY } });

  try {
    const { data } = await ApiCall.getGallery({ params });
    const { images } = normalizeData(data);
    const idCurrent = selectNew ? images[0].id : current_image.id;
    const selectedImage = getInitialImage(images, idCurrent);

    dispatch({ type: SET_IMAGES, payload: images });
    dispatch(setCurrentImage(selectedImage));
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_GALLERY, error: getErrorMessages(localeUi, error.message) },
    });
  }
};

export const getUserSettings = () => async (dispatch, getState) => {
  const { settings, loading } = getState();
  const { localeUi } = settings;

  if (loading.error) {
    return;
  }

  try {
    const data = await ApiCall.getUserSettings();

    const unitySettings = data.data.unitySettings || {};
    const unitySettingsVal = {};
    const { store_basket } = unitySettings;
    const cart = store_basket ? JSON.parse(store_basket) : {};
    const cart_values = Object.values(cart);
    const cart_count = cart_values.length ? cart_values.reduce((acc, currentValue) => acc + currentValue) : 0;

    for (const key in unitySettings) {
      if (unitySettings.hasOwnProperty(key)) {
        try {
          unitySettingsVal[key] = JSON.parse(unitySettings[key]);
        } catch (e) {
          // If JSON.parse throws an error, just ignore this property
        }
      }
    }

    dispatch({ type: SET_USER_SETTINGS, payload: { cart_count, ...unitySettingsVal } });
  } catch (error) {
    console.error(error);
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_USER_SETTINGS, error: getErrorMessages(localeUi, error.message) },
    });
  }
};

export const getProfile = () => async (dispatch, getState) => {
  const { settings: { localeUi }, loading } = getState();

  if (loading.error) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROFILE } });

  try {
    const { data } = await ApiCall.getProfile();

    dispatch({ type: SET_PROFILE, payload: data });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE } });

  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PROFILE, error: getErrorMessages(localeUi, error.message) },
    });
  }
};

// Установка одной картинки, если она передана в параметрах инициализации
export const getOneImage = (imageUrl, previewUrl) => async (dispatch) => {
  const loaded_image = await loadingImage(imageUrl);
  const { width, height } = loaded_image.target;

  const { image, filters } = normalizeOneImage({ imageUrl, previewUrl, width, height });
  const lazy_images = normalizeLazyImages([image]);

  dispatch({ type: SET_IMAGES, payload: [image] });
  dispatch({ type: SET_LAZY_IMAGES, payload: lazy_images });
  dispatch({ type: SET_FILTERS, payload: filters });
  dispatch(setCurrentImage(image));
  dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });
};

// Установка одной панорамы, если она передана в параметрах инициализации
export const getOnePanorama = (panoramaUrl) => async (dispatch) => {
  const image = { krpano_url: panoramaUrl };

  dispatch({ type: SET_IMAGES, payload: [image] });
  dispatch(setCurrentImage(image));
  dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });
};

// Установка одного тура, если передан в параметрах инициализации
export const getOneTour = (tourUrl) => async (dispatch) => {
  const image = {
    id: 1,
    updateAt: Date.now(),
    tour: tourUrl,
    publishId: new URL('https://pp.ksdev.ru/view/tour/?id=8a0482a11d3b93cc1c16dc45c1e654a0').searchParams.get('id'),
  };

  dispatch({ type: SET_IMAGES, payload: [image] });
  dispatch(setCurrentImage(image));
  dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });
};

export const autoUpdate = () => async (dispatch, getState) => {
  const { settings } = getState();
  const { projectId } = settings;

  const timeout = setTimeout(async () => {
    const { images: old_images, lazy_images } = getState();
    const params = { id: projectId };
    const { data } = await ApiCall.getGalleryUpdate({ params });

    // нужно сначала нормализовать, иначе не будет нужных полей
    const { images, filters, projects } = normalizeData(data);
    const updated_images = isUpdatedImages(old_images, images);

    dispatch({ type: SET_PROJECTS, payload: projects });

    if (updated_images.length) {
      const new_current_image = getInitialImage(images);
      const new_lazy_images = normalizeLazyImages(updated_images);

      dispatch({ type: SET_LAZY_IMAGES, payload: { ...lazy_images, ...new_lazy_images } });
      dispatch(setCurrentImage(new_current_image));
      dispatch({ type: SET_IMAGES, payload: images });
      dispatch({ type: SET_FILTERS, payload: filters });
    }

    if (isNeedUpdate(images)) {
      dispatch(autoUpdate());
    }
  }, AUTO_UPDATE_TIMEOUT);

  settings.setAutoUpdateTimeout(timeout);
};
