import { ApiCall } from './api-call';
import { getDefaultMethodPay, getGoodsName } from './helpers';
import { MODE_PAY } from '../constants/card';
import * as actions from './Store/action';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { SET_COUNTRIES, PAY_STATUSES, SCREENS } from '../constants';
import { getErrorPromoMessage } from './error-messages';
import { Analytics } from './analytics';

const getAnalyticParams = () => {
  try {
    const sessionCookie = '_ga_4TZBNGEL7D';

    return {
      client_id: window.gaGlobal && window.gaGlobal.vid,
      session_id: document.cookie.match(`(^|;)\\s*${sessionCookie}\\s*=\\s*([^;]+)`).pop().split('.')[2],
      session_number: document.cookie.match(`(^|;)\\s*_ga_4TZBNGEL7D\\s*=\\s*([^;]+)`).pop().split('.')[3],
    }
  }
  catch (error) {
    return {
      client_id: '',
      session_id: '' ,
    }
  }
};

export const setDefaultMethodPay = () => async (dispatch, getState) => {
  const { bindingCard, paymentMethods } = getState();
  const method = getDefaultMethodPay(bindingCard, paymentMethods);

  dispatch(actions.setPaymentMethod(method));
};

export const setDefaultCountry = () => async (dispatch, getState) => {
  const { countries, profile, settings: { locale }} = getState();
  const country = countries.find(item => item.value === profile.country.IsoCode);

  if (!country) {
    const currentCountry = {
      value: profile.country.IsoCode,
      caption: profile.country.Names[locale],
      vat: 0
    };
    const countriesWithMy = [
      currentCountry,
      ...countries,
    ];

    dispatch({ type: SET_COUNTRIES, payload: countriesWithMy });
    dispatch(actions.setCountry(currentCountry));

    return;
  }

  dispatch(actions.setCountry(country));
};

export const checkPromoAndGetNewPrice = ({ promo = '', update = false } = {}) => async (dispatch, getState) => {
  if (!promo) {
    notifications.showError(translations.t('cabinet.notification.promo.invalid'));

    return;
  }

  try {
    const { selected, profile } = getState();
    const { paymentMethod, currentGoods } = selected;

    const params = {
      preview: 1,
      mode: paymentMethod.modePay === MODE_PAY.bank ? paymentMethod.alias : paymentMethod.type,
      binding_id: paymentMethod.modePay === MODE_PAY.binding ? paymentMethod.id : '',
      receipt_email: profile.email,
      'type[]': currentGoods.store_id,
      'amount[]': 1,
      promo,
    };
    const response = await ApiCall.getPriceWithPromo({ params });
    const { data } = response.data;

    if (!response.data.success) {
      throw new Error(response.data.errorText);
    }

    dispatch(actions.setAppliedPromo(data));
    dispatch(actions.setPromoCode(promo));
    dispatch(Analytics.applyPromoCode());
    if (!update) {
      notifications.showSuccess(translations.t('cabinet.notification.promo.success'));
    }
  } catch (error) {
    console.error(error);

    notifications.showError(getErrorPromoMessage(error.message));
    dispatch(actions.setAppliedPromo({}));
    dispatch(actions.setPromoCode(''));
  }
};

export const onPay = ({ offLoading = () => {} }) => async (dispatch, getState) => {
  try {
    const { selected, profile, bannerAlias, goods: { isTariff }, settings: { team_id, unity_clientID }} = getState();
    const { paymentMethod, currentGoods, promoCode, country } = selected;
    const goodsName = dispatch(getGoodsName());
    const alias = isTariff ? `locked_feature_${bannerAlias}` : `buy_render_${goodsName}`;
    const params = {
      mode: paymentMethod.modePay === MODE_PAY.bank ? paymentMethod.id : paymentMethod.type,
      binding_id: paymentMethod.modePay === MODE_PAY.binding ? paymentMethod.id : '',
      receipt_email: profile.email,
      'type[]': currentGoods.store_id,
      'amount[]': 1,
      'list_position[]': 0,
      promo: promoCode,
      team_id: team_id,
      unity_clientid: unity_clientID,
      country: country.value,
      return_url: 'https://pp.ksdev.ru/ui/editor/?orderid=%ORDER_ID%&store=1&locale=ru',
      banner_alias: alias,
      ...getAnalyticParams(),
    };

    const response = await ApiCall.getPay({ params });
    const { data } = response.data;

    if (!response.data.success) {
      throw new Error(response.data.errorText);
    }

    await dispatch(actions.setPayData(data));
    offLoading();

    if (data.status === PAY_STATUSES.pending || data.status === PAY_STATUSES.processing) {
      dispatch(actions.setScreen(SCREENS.pending));
    }

    if (data.status === PAY_STATUSES.succeeded || data.status === PAY_STATUSES.paid) {
      dispatch(actions.setScreen(SCREENS.success));
    }

    /* Yookassa */
    if (data.confirmation_token) {
      dispatch(actions.setScreen(SCREENS.yookassa));
    }

    /* Stripe */
    if (data.client_secret && data.publicKey) {
      if (data.status === PAY_STATUSES.requires_payment_method) {
        dispatch(actions.setScreen(SCREENS.stripeRequire));
      } else {
        dispatch(actions.setScreen(SCREENS.stripe));
      }
    }

    /* Paypal */
    if (data.client_id && data.amount) {
      dispatch(actions.setScreen(SCREENS.paypal));
    }

    /* Redirect */
    if (data.redirect) {
      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: data.redirect,
      });

      dispatch(actions.setScreen(SCREENS.pending));
    }
  } catch (error) {
    console.error(error);
    offLoading();

    notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
    dispatch(actions.setScreen(SCREENS.error));
  }
};

export const checkStatusPay = (counter = 0) => async (dispatch, getState) => {
  const { payData: { orderid, tx_id } } = getState();
  const maxDelay = 15000;
  const delay = 2000;
  const attempts = 17;

  try {
    if (counter >= attempts) {
      throw new Error('Attempts have dried up');
    }

    const response = await ApiCall.getStatusPay({ orderid, tx_id });
    const { data } = response.data;

    if (data.status === PAY_STATUSES.error) {
      throw new Error(data.errorText);
    }

    if (data.status === PAY_STATUSES.wrong) {
      throw new Error(translations.t('store.common.payment_wrong'));
    }

    if (data.status === PAY_STATUSES.unpaid) {
      setTimeout(() => {
        dispatch(checkStatusPay(counter + 1));
      }, delay * (counter + 1) > maxDelay ? maxDelay : delay * (counter + 1));

      return;
    }

    if (data.status === PAY_STATUSES.paid) {
      notifications.showSuccess(translations.t('store.common.payment_success'));

      dispatch(actions.setScreen(SCREENS.success));
    }

  } catch (error) {
    console.error(error);

    notifications.showError(`${translations.t('store.common.payment_error')} - ${error.message}`);
    dispatch(actions.setScreen(SCREENS.error));
  }
};
