import React, { useContext } from 'react';
import styled from 'styled-components';
import { IStrokeCart } from 'planoplan-ui-kit/core/icons/i-stroke-cart';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { translations } from '@libs';
import { CART } from '../../constants';
import { StoreContext } from '../../modules/Store/Store';
import { laptop } from '../../modules/breakpoints';

export const CartButton = ({ screen, setScreen }) => {
  const [{ cart }] = useContext(StoreContext);
  const count = cart.reduce((acc, currentValue) => {
    return acc + currentValue.view_count;
  }, 0);

  return (
    <View active={screen === CART} onClick={() => setScreen(CART)} count={count} data-test="sidebar__cart-button">
      <IStrokeCart fill={screen === CART ? Colors.coal : Colors.white} />
      <Title>{translations.t('store.navigation.cart')}</Title>
      <Count active={screen === CART} hidden={count < 1} small={count > 99} data-test="sidebar__cart-count">
        {count}
      </Count>
    </View>
  );
};

export const View = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color, transform, color')};
  position: relative;
  z-index: 10;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: ${Colors.night};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.white_smoke};
  transform: ${(p) => (!p.count || p.active ? 'translateX(120%)' : 'translateX(0)')};
  border-top: 1px solid ${Colors.coal};

  @media ${laptop} {
    ${Grid.suffix(0.5, 6)};
    ${Grid.prefix(0.5, 6)};
    position: relative;
    height: auto;
    border-radius: 0;
    padding-top: 22px;
    padding-bottom: 22px;
    background-color: ${(p) => (p.active ? Colors.dark_cream : Colors.night)};
    color: ${(p) => (p.active ? Colors.night : Colors.white_smoke)};
    width: 100%;
    border-bottom-left-radius: 10px;
    font-size: 16px;
    line-height: 1;
    transform: translateX(0);

    svg {
      ${Mixins.transition('fill')};
      margin-right: 12px;
    }
  }
`;

const Title = styled.span`
  display: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;

  @media ${laptop} {
    display: inline;
  }
`;

const Count = styled.div`
  ${Mixins.transition('background-color')};
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  font-size: ${(p) => (p.small ? '12px' : '15px')};
  background-color: ${Colors.colar};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media ${laptop} {
    position: relative;
    margin-left: auto;
    width: 30px;
    height: 30px;
    font-size: ${(p) => (p.small ? '13px' : '16px')};
    background-color: ${(p) => (p.active ? Colors.night : Colors.dark_cream)};
    color: ${(p) => (p.active ? Colors.dark_cream : Colors.night)};
    opacity: ${(p) => (p.hidden ? 0 : 1)};
    font-weight: 700;
  }
`;
