import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { isMobile } from 'react-device-detect';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IArrowPrev } from 'planoplan-ui-kit/core/icons/i-arrow-prev';
import { IArrowNext } from 'planoplan-ui-kit/core/icons/i-arrow-next';

export const Arrow = ({ onClick, direction, disabled }) => (
  <View onClick={onClick} direction={direction} disabled={disabled}>
    {direction === 'prev' ? <IArrowPrev width="38" height="38" /> : <IArrowNext width="38" height="38" />}
  </View>
);

const View = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 284px;
  right: ${(p) => (p.direction === 'next' ? '0px' : '608px')};

  &:hover {
    ${!isMobile &&
    css`
      background-color: ${({ theme }) => theme.fullScreen && rgba(Colors.night, 0.5)};

      svg {
        transform: ${(p) => (p.direction === 'prev' ? 'translateX(-5px)' : 'translateX(5px)')};
        stroke: ${Colors.white};
      }
    `}
  }

  &:disabled {
    opacity: 0;
  }


  svg {
    ${Mixins.transition('transform stroke')};
    display: block;
    stroke: ${rgba(Colors.white, 0.5)};
  }
`;
