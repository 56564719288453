import { throttle } from 'throttle-debounce';
import { SAVE_LAST_NOTIFICATION_THROTTLE } from '../constants';

export const setLastElementNotification = throttle(
  SAVE_LAST_NOTIFICATION_THROTTLE,
  (scrollBar, notifications, onSave) => {
    if (!scrollBar.current || !notifications.length) {
      return;
    }

    const heightItem = 76; // высота 1 элемента
    const { clientHeight, scrollTop } = scrollBar.current._container;

    const position = Math.round((clientHeight + scrollTop) / heightItem);
    let indexN = 1;
    let item = notifications[position - indexN];

    while(item && !item.id) {
      indexN = indexN + 1;
      item = notifications[position - indexN];
    }

    onSave({ ...item, position });
  }
);
