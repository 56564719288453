import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const GoogleShutdownNotification = ({ isOpen, onCancel = () => {}, goOver = () => {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.google.title')}</Header>
      <Content dangerouslySetInnerHTML={{__html: translations.t('modal.google.text')}} />
      <Buttons>
        <Button theme="primary" size="M" onClick={goOver}>
          {translations.t('modal.google.button')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
  }
`;
