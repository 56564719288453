import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import FormEntry from '@features/form-entry';
import { getLocaleUi } from '@libs';
import { POINTER_ON_INTERFACE, POINTER_OFF_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { App } from './app';
import { mappedButtons } from './modules/invoke';
import {
  BUTTONS,
  SCREEN_ENTRY_INSTALLING_PERCENT,
  SCREEN_ENTRY_INSTALLING_ERROR,
  SCREEN_ENTRY_INSTALLING_NOT_REQUIRED,
  SCREEN_ENTRY_CLOSE,
} from './constants';
import { initialState, createAppStore } from './modules/Store';

export default class ScreenEntry {
  constructor() {
    this.formEntry = null;

    window.invokeAPI.registerDispatch('LAYOUT', {
      [SCREEN_ENTRY_INSTALLING_PERCENT]: (value) => this.formEntry.setInstallingPercent(value),
      [SCREEN_ENTRY_INSTALLING_ERROR]: () => this.formEntry.setUpdateError(),
      [SCREEN_ENTRY_INSTALLING_NOT_REQUIRED]: () => this.formEntry.setUpdateNotRequired(),
      [SCREEN_ENTRY_CLOSE]: () => this.close(),
    });
  }
  /**
   * Метод содержит коллбэки на события других модулей
   * @private
   */
  _subscribes() {}

  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения экрана входа не указан html элемент');
    this.locale = settings.locale || 'en';
  }

  /**
   * Открытие модуля
   */
  open() {
    this._subscribes();
    this._mount();
    this._editorInvoke();
    this._formEntryOpen();
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  close() {
    window.invokeEditorAction({
      name: mappedButtons[BUTTONS.ENTRY_SCREEN_CLOSE].name,
      value: mappedButtons[BUTTONS.ENTRY_SCREEN_CLOSE].value,
    });

    if (!window.cabinetIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }

    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    const { ver } = this.settings;

    return {
      ...initialState,
      ver,
      settings: {
        locale: this.locale,
        localeUi: getLocaleUi(this.locale), // язык для интерфейса из массива поддерживаемых языков
        close: this.close.bind(this),
      },
    };
  }

  /**
   * Открытие формы входа
   * @private
   */
  _formEntryOpen() {
    this.formEntry = new FormEntry();
    const { locale, platform, unity_clientID, updateStatus, autoInput, social, firstEntry } = this.settings;

    this.formEntry.init({
      root: 'form-entry',
      mode: 'unity', // unity | site
      updateStatus,
      locale,
      platform,
      unity_clientID,
      autoInput,
      social,
      firstEntry
    });

    this.formEntry.open({
      initialStep: 'skeleton'
    });
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }
}
