import { TABS } from '../../constants';

export const initialState = {
  tab: TABS.adjustment,
  image: {
    src: '',
    width: null,
    height: null,
  },
  adjustment: {},
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  settings: {},
};
