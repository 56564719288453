import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { url } from '@globalInvoke';
import { UNAUTH_URL_OPEN } from '@globalConstants';
import { URLS, CURRENCY } from '../../constants';
import { Select } from '@components/Form/Select';
import * as actions from '../../modules/Store/action';
import { translations } from '@libs';
import { getGoodsName } from '../../modules/helpers';
import { Analytics } from '../../modules/analytics';

import { ChoosePackage } from './organizm/choosePackage';
import { ChoosePeriod } from './organizm/choosePeriod';
import { PriceLine } from './organizm/price-line';
import { PromoCode } from './organizm/promocode';
import { PaymentMethod } from './organizm/paymentMethod';
import * as effects from "@features/quick-store/modules/effects";

export const Shop = memo(() => {
  const [isInitialRender, setIsInitialRender] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    goods: { isTariff, isPanorama },
    countries,
    selected,
    profile: { currency },
    settings: { locale },
  } = useSelector(state => state);
  const { country, pack, period, promoCode } = selected;

  const displaySelectCountry = country && currency !== CURRENCY.RUB;

  const title = dispatch(getGoodsName());

  const openLink = () => {
    const link = isTariff ? URLS.tariffComparison(locale) : URLS.renderComparison;

    window.invokeEditorAction({
      name: url[UNAUTH_URL_OPEN].name,
      value: link,
    });
  };
  const onChangeCountry = (country) => dispatch(actions.setCountry(country));
  const offLoading = () => setIsLoading(false);

  const onPay = async () => {
    setIsLoading(true);
    dispatch(Analytics.getPay());
    dispatch(effects.onPay({ offLoading }));
  };

  useEffect(() => {
    dispatch(actions.setCurrentGoods());

    (async () => {
      /* Если первой отрисовки еще не было то делаем return */
      if (!isInitialRender) {
        return;
      }

      if (promoCode) {
        setLoadingPrice(true);
        await dispatch(effects.checkPromoAndGetNewPrice({ promo: promoCode, update: true }));
        setLoadingPrice(false);
      }
    })()
  }, [pack, period]);

  useEffect(() => {
    setIsInitialRender(true);
  }, []);

  return (
    <View>
      {isLoading && <Loader><Preloader width="80px" height="80px" fill={Colors.planoplan_blue} /></Loader>}

      <Top>
        <TopRow isTariff={isTariff}>
          <Title>{title}</Title>
          <Caption onClick={openLink} isTariff={isTariff}>
            {isTariff ? translations.t('quickstore.comparison.tariffs') : isPanorama ? '' : translations.t('quickstore.comparison.renders')}
          </Caption>
        </TopRow>

        <PriceWrap>
          {isTariff ? <ChoosePeriod /> : <ChoosePackage/>}
        </PriceWrap>
      </Top>

      <Line />

      <Bottom>
        <BottomInfo>
          {displaySelectCountry &&
            <Region>
              <Label>{translations.t('quickstore.region')}:</Label>
              <Select options={countries} value={country} onChange={onChangeCountry} />
            </Region>
          }

          <PromoCodeWrap>
            <Label>{translations.t('quickstore.promocode')}</Label>
            <PromoCode />
          </PromoCodeWrap>
        </BottomInfo>

        <Methods>
          <Label>{translations.t('quickstore.methodPay')}</Label>
          <PaymentMethod />
        </Methods>

        <Pay>
          <Button size="M" theme="yellow_black" onClick={onPay} disabled={isLoading}>
            {isTariff ? translations.t('quickstore.pay.tariff') : isPanorama ? translations.t('quickstore.pay.panorama') : translations.t('quickstore.pay.render')}
          </Button>
        </Pay>

        <PriceLine loadingPrice={loadingPrice} />
      </Bottom>
    </View>
  );
});

const View = styled.div`
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  color: ${Colors.light_gray};
  flex-shrink: 0;
  width: 360px;
  min-height: 430px;
  border-radius: 0 10px 10px 0;
  padding: 20px 20px 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;
const Top = styled.div``;
const TopRow = styled.div`
  display: ${({ isTariff }) => isTariff ? 'flex' : 'block'};
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
  white-space: nowrap;
`;
const Caption = styled.div`
  color: ${Colors.dark_cream};
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 300;
  cursor: pointer;
  display: inline-block;
  width: ${({ isTariff }) => isTariff ? '70px' : 'auto'};
  text-align: right;
`;
const PriceWrap = styled.div`
  width: 100%;  
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${Colors.coal};
`;
const Bottom = styled.div``;
const BottomInfo = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const Region = styled.div`
  width: 120px;
  margin-right: 10px;
  flex-shrink: 0;
`;
const PromoCodeWrap = styled.div`
  flex-grow: 1;
`;
const Methods = styled.div`
`;
const Pay = styled.div`
  margin-top: 10px;
  
  button {
    border: none;
    box-shadow: none;
  }
`;
const Label = styled.div`
  color: ${Colors.light_gray};
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
`;
const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${Colors.night};
  opacity: 0.8;
  z-index: 1;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
