import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Top } from './organism/top';
import { Controls } from './organism/controls';
import { List } from './organism/list';
import { changeScroll } from './utils';
import * as effects from '../../modules/effects';
import { translations } from '@libs/translations';
import { Analytics } from '../../modules/analytics';
import { TARIFF_PASS_FEATURES } from '../../constants';
import { ON_OPEN_DESIGN_PROJECT } from '@observer/constants';

const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};

const observer = new window.POPObserver();

export const Sidebar = ({ images, current_image, onRemove = () => {} }) => {
  const state = useSelector((state) => state);
  const { filters, current_filter, checked_images, has_pdf, profile, settings } = state;
  const [view, setView] = useState('column');
  const scrollBarEl = useRef(null);
  const currentImageEl = useRef(null);
  const dispatch = useDispatch();

  const tariff = profile.tariff ? profile.tariff.alias : null;
  const isPassDesignProject = TARIFF_PASS_FEATURES.indexOf(tariff) !== -1;
  const { team_id, projectId } = settings;

  const onSetView = (view) => setView(view);
  const onChangeFilter = (item) => dispatch(effects.setCurrentFilter(item));
  const onSelect = (image) => dispatch(effects.setCurrent(image));
  const onChecked = ({ checked }, image) => dispatch(effects.checked(checked, image));
  const onCheckedAll = ({ checked }) => dispatch(effects.checkedAll(checked));
  const onDownloadAll = () => {
    Analytics.onDownload(projectId);
    dispatch(effects.downloadAll());
  };
  const onOpenDesignProject = () => observer.postMessage(ON_OPEN_DESIGN_PROJECT, { projectId });

  const isCheckedAll =
    checked_images.length &&
    checked_images.length === images.filter((item) => item.isFilter && item.isAvailableCheck).length;
  const isDisabled = !checked_images.length;
  const isTeamsMember = team_id;
  const isDisabledAll = !images.some((item) => item.isFilter && item.isAvailableCheck);
  const isProhibitedDelete = current_image.isProhibitedDelete;
  const disabled = !(isPassDesignProject || isTeamsMember);

  useEffect(() => {
    if (Object.values(current_image).length && scrollBarEl.current) {
      changeScroll(scrollBarEl.current._container, currentImageEl.current);
    }
  }, [current_image]);

  return (
    <Container>
      <Top
        view={view}
        filters={filters}
        current_filter={current_filter}
        onChangeFilter={onChangeFilter}
        onSetView={onSetView}
      />
      <Controls
        onCheckedAll={onCheckedAll}
        isCheckedAll={isCheckedAll}
        onDownloadAll={onDownloadAll}
        isDisabled={isDisabled}
        isDisabledAll={isDisabledAll}
        isProhibitedDelete={isProhibitedDelete}
        onRemove={() => {onRemove(checked_images)}}
      />
      <ScrollBarContainer>
        <ScrollBarWrapper>
          <ScrollBar
            options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }}
            rail_y={rail_y}
            thumb_y={thumb_y}
            getRef={(ref) => (scrollBarEl.current = ref)}>
            <List
              images={images}
              checked_images={checked_images}
              view={view}
              onSelect={onSelect}
              onChecked={onChecked}
              current={current_image}
              currentImageEl={currentImageEl}
            />
          </ScrollBar>
        </ScrollBarWrapper>
      </ScrollBarContainer>

      <DesignProjectWrapper>
        <DesignProjectButton onClick={onOpenDesignProject} disabled={disabled}>
          {translations.t(has_pdf ? 'gallery.project.open' : 'gallery.project.create')}
        </DesignProjectButton>
      </DesignProjectWrapper>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollBarContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

const ScrollBarWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DesignProjectWrapper = styled.div`
  border-top: 1px solid ${Colors.coal};
  padding: 10px 20px 10px 15px;
`;

const DesignProjectButton = styled.button`
  ${Mixins.transition('background-color')};
  border-radius: 4px;
  border: none;
  color: ${Colors.night};
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  font-size: 12px;
  padding: 4px 0;
  width: 100%;
  background-color: ${Colors.dark_cream};

  &:disabled {
    background-color: ${Colors.nobel};
  }

  &:hover:not(:disabled) {
    background-color: #FFB540;
    cursor: pointer;
  } 
`;
