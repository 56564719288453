import {
  SET_ADJUSTMENT,
  SET_CURRENT_TAB,
  SAVE_MODE,
  SET_LOADING_ON,
  GET_IMAGE_BASE64
} from '../constants';
import { Canvas } from '../components/Render/Canvas';

const canvas = new Canvas();

export const onSelectTab = (tab) => (dispatch) => {
  dispatch({ type: SET_CURRENT_TAB, payload: tab });
};

export const onResetAll = () => (dispatch, getState) => {
  dispatch(resetAdjustment());
};

export const setAdjustment = (alias, value) => (dispatch, getState) => {
  const [aliasSection, aliasFilter] = alias.split('-');
  const { adjustment } = getState(state => state);
  const newAdjustment = adjustment.map((section) => {
    if (section.alias === aliasSection) {
      return {
        ...section,
        list: section.list.map((filter) => {
          if (filter.alias === aliasFilter) return {...filter, value};

          return filter;
        })
      }
    }

    return section;
  });

  dispatch({ type: SET_ADJUSTMENT, payload: newAdjustment });
};

export const resetAdjustmentSection = (alias) => (dispatch, getState) => {
  const { adjustment } = getState(state => state);
  const newAdjustment = adjustment.map((section) => {
    if (section.alias === alias) {
      return {
        ...section,
        list: section.list.map((filter) => ({
          ...filter,
          value: filter.centerAt || 0
        }))
      }
    }

    return section;
  });

  dispatch({ type: SET_ADJUSTMENT, payload: newAdjustment });
};

export const resetAdjustment = () => (dispatch, getState) => {
  const { adjustment } = getState(state => state);
  const newAdjustment = adjustment.map((section) => ({
    ...section,
    list: section.list.map((filter) => ({
      ...filter,
      value: filter.centerAt || 0
    }))
  }));

  dispatch({ type: SET_ADJUSTMENT, payload: newAdjustment });
};

export const closeImageEditor = () => (dispatch, getState) => {
  const { settings: { close, mode, params }} = getState();

  close(mode, { ...params });
  canvas.destroy();
};

export const saveImage = (modeSave = SAVE_MODE.new) => async (dispatch, getState) => {
  const { settings: { close, mode, params }} = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_IMAGE_BASE64 } });

  close(mode, {
    ...params,
    image: await canvas.getImageBase64(),
    mode: modeSave, // Mode сохранения
  });
  canvas.destroy();
};
