import { request } from '@libs/request';

export const ApiCall = {
  locale: 'en',

  setSettings({ locale }) {
    this.locale = locale;
  },

  getDomains({ username }) {
    const urls = { local: './json/form-entry/domains.json', prod: '/v2/auth/check/' };
    const settings = { subDomain: 'api' };

    return request.call_public('get', urls, { username }, settings);
  },

  getAuth({ body, g_recaptcha_response, domain, endAllSessions }) {
    return request.getTokens(body, g_recaptcha_response, domain, endAllSessions);
  },

  getSocialCode({ params }) {
    const urls = { local: './json/form-entry/social-code.json', prod: '/v2/auth/check/' };
    const settings = { subDomain: 'api' };

    return request.call_public('get', urls, params, settings);
  },

  getSocialAuth({ code, domain, endAllSessions }) {
    return request.getSocialTokens(code, domain, endAllSessions);
  },

  getUserProfile() {
    const urls = { local: './json/form-entry/profile.json', prod: `/${this.locale}/users/profile/` };
    const settings = { withAlwaysTokens: true };

    return request.call_auth('get', urls, '', settings);
  },

  getAnalyticsData() {
    const urls = { local: './json/store/getgoods.json', prod: `/users/getgoods/` };

    return request.call_auth('get', urls);
  },

  setSignUp({ params }) {
    const urls = { local: './json/form-entry/signup.json', prod: `/${this.locale}/auth/register/` };
    const settings = { useFormData: true };

    return request.call_public('post', urls, params, settings);
  },

  checkEmail({ code }) {
    const urls = { local: './json/form-entry/check.json', prod: `/${this.locale}/users/confirmation/` };
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, { code }, settings);
  },

  resendCheckEmail({ code }) {
    const urls = { local: './json/form-entry/check-resend.json', prod: `/${this.locale}/users/confirm/` };
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, { code }, settings);
  },

  getSignRestorePassword({ email }) {
    const urls = { local: './json/form-entry/restore-sign.json', prod: `/auth/restore` };
    const settings = { useFormData: true };

    return request.call_public('post', urls, { email }, settings);
  },

  confirmRestorePassword({ sign, code }) {
    const urls = { local: './json/form-entry/confirm-restore-password.json', prod: `/auth/reset` };
    const settings = { useFormData: true };

    return request.call_public('post', urls, { sign, code }, settings);
  },

  newPassword(params) {
    const urls = { local: './json/form-entry/new-password.json', prod: `/auth/resetpassword` };
    const settings = { useFormData: true };

    return request.call_public('post', urls, params, settings);
  },

  setName({ name }) {
    const urls = { local: './json/form-entry/edit.json', prod: `/${this.locale}/users/edit` };
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, { name }, settings);
  },

  setProfileFields(fields) {
    const urls = { local: './json/form-entry/edit.json', prod: `/${this.locale}/users/edit` };
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, fields, settings);
  },

  // todo возможно объединить в 1
  setEmail({ email }) {
    const urls = { local: './json/form-entry/edit.json', prod: `/${this.locale}/users/edit` };
    const settings = { useFormData: true, withAlwaysTokens: true };

    return request.call_auth('post', urls, { email }, settings);
  },

  getSeamlessAuthToken() {
    const urls = {
      local: './json/form-entry/seamlesstoken.json',
      prod: `/${this.locale}/users/getseamlessauthenticationtoken/`
    };
    const settings = { withAlwaysTokens: true };

    return request.call_auth('get', urls, '', settings);
  },

  getCountryCode() {
    const urls = {local: '/json/form-entry/country.json', prod: `/${this.locale}/api/init`};

    return request.call_public('get', urls);
  },

  getUserSettings() {
    const urls = { local: `/json/form-entry/getsettings.json`, prod: '/users/getsettings/', };

    return request.call_auth('get', urls);
  },
};
