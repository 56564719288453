import React from 'react';
import styled from 'styled-components';
import { getImage } from 'planoplan-ui-kit/core/get_image';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { tablet_landscape } from '../../modules/breakpoints';
import { MODE_ALIAS, UNITY } from '@globalConstants';

export const Social = ({ title, onVK, onGoogle, country, locale, mode }) => {
  const isDisplayGoogle = country !== 'RU';

  return (
    <View>
      <Title>{title}</Title>
      <ButtonsGroup>
        {MODE_ALIAS[mode] === UNITY && isDisplayGoogle &&
          <Button onClick={onGoogle}>
            <img src={getImage('icons/google.svg')} alt="Google"/>
          </Button>
        }

        {MODE_ALIAS[mode] !== UNITY && isDisplayGoogle &&
          <ButtonGoogle id={'google-login-button'} />
        }

        <Button onClick={onVK}>
          <img src={getImage('icons/vk.svg')} alt="Вконтакте" />
        </Button>
      </ButtonsGroup>
    </View>
  );
};

const View = styled.div`
  margin-top: 18px;

  @media ${tablet_landscape} {
    margin-top: 30px;
  }
`;
const Title = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @media ${tablet_landscape} {
    font-size: 13px;
    line-height: 16px;

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: ${Colors.solitude};
    }

    &:before {
      margin-right: 16px;
    }

    &:after {
      margin-left: 16px;
    }
  }
`;
const ButtonsGroup = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;

  @media ${tablet_landscape} {
    margin-top: 24px;
  }
`;
const Button = styled.button`
  ${Mixins.resetButton()};
  margin-right: 24px;
  width: 30px;
  flex-shrink: 0;

  @media ${tablet_landscape} {
    margin-right: 42px;
    width: 40px;
  }

  img {
    ${Mixins.transition('opacity')};
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover img {
    opacity: 0.8;
  }
`;

const ButtonGoogle = styled.div`
  margin-right: 24px;
  flex-shrink: 0;
  height: 40px;
  
  @media ${tablet_landscape} {
    margin-right: 42px;
  }
`;
