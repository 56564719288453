import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { translations } from 'libs';
import { getGoodsPackage } from '../../../modules/helpers';

export const Render = ({ render = {}, handleClick = () => {} }) => {
  const disabled = render.disabled;
  const prime = render.prime;
  const themeBorder = disabled ? Colors.coal : prime ? Colors.dark_cream : Colors.nobel;
  const themeBg = disabled ? Colors.night : prime ? Colors.dark_cream : Colors.night;
  const themeColor = disabled ? Colors.coal : prime ? Colors.coal : Colors.nobel;
  const theme = {
    border: themeBorder,
    color: themeColor,
    bg: themeBg,
    disabled,
    prime,
  };

  const { profileGoods } = useSelector(state => state);
  const goodsPackage = getGoodsPackage(render);
  const currency = getCurrency(profileGoods.currency);
  const price = goodsPackage.price / goodsPackage.count;
  const priceView = profileGoods.currency === 'RUB' ? `${price}${currency}` : `${currency}${price}`;
  const countPrice = render.count || priceView;
  const caption = render.count ? translations.t('gallery.improve.improveTo') : translations.t('gallery.improve.saleImproveTo');

  const onHandleClick = () => {
    if (disabled) return;

    handleClick({
      ...render,
      additional: {
        price,
        priceView,
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <View onClick={onHandleClick}>
        <Left>
          <Caption>{caption}</Caption>
          <Name>{render.name}</Name>
        </Left>

        <CountPrice>{countPrice}</CountPrice>
      </View>
    </ThemeProvider>
  );
};

const View = styled.div`
  ${Mixins.transition('color, background-color, border-color')};
  border: 1px solid ${({ theme }) => theme.border};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  border-radius: 5px;
  color: ${({ theme }) => theme.color};
  cursor: ${({ theme }) => theme.disabled ? 'default' : 'pointer'};
  background-color: ${({ theme }) => theme.bg};

  ${({ theme }) => !theme.disabled && theme.prime && `
    &:hover {
      border-color: ${Colors.coal};
      background-color: #FFB540;
    }
  `};

${({ theme }) => !theme.disabled && !theme.prime && `
    &:hover {
      border-color: ${Colors.nobel};
      background-color: ${Colors.nobel};
      color: ${Colors.night};
    }
  `};
`;
const Left = styled.div``;
const Caption = styled.div`
  font-size: 10px;
  line-height: 12px;
`;
const Name = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
`;
const CountPrice = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-family: 'Montserrat', sans-serif;
`;