import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { rgba } from 'polished';
import { TextWithLinks } from '@components/TextWithLinks';
import { laptop } from '../../modules/breakpoints';
import { Close } from '../../components/Close';

export const ErrorScreen = () => {
  const title = translations.t('store.error.title');
  const text = translations.t('store.error.text');

  return (
    <View>
      <CloseIcon>
        <Close width="38px" height="38px" />
      </CloseIcon>
      <Sidebar />
      <Content>
        <Title>{title}</Title>
        <TextWithLinks tag={Text} text={text} />
      </Content>
    </View>
  );
};

const View = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  max-width: 1140px;
  width: ${Grid.col(32)};
  height: 652px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.mint};
  border-radius: 10px;
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
`;
const Sidebar = styled.div`
  ${Grid.column(6, 30)};
  background-color: ${Colors.night};
  flex-shrink: 0;
  height: 100%;
  border-radius: 10px 0 0 10px;
`;
const Content = styled.div`
  ${Grid.prefix(3, 32)};
  ${Grid.suffix(3, 32)};
  flex-grow: 1;
`;
const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 12px;
`;
const Text = styled.div`
  max-width: 900px;
  font-weight: 300;
`;
const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: -20px;
  top: 0;
  transform: translateX(100%);

  @media ${laptop} {
    display: block;
  }
`;
