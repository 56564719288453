import React from 'react';

export const ICardMir = ({ width = 32, height = 20, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15899_61001)">
        <path d="M29.7714 0.227051H2.22852C1.12395 0.227051 0.228516 1.11739 0.228516 2.21569V17.7839C0.228516 18.8822 1.12395 19.7725 2.22852 19.7725H29.7714C30.8759 19.7725 31.7714 18.8822 31.7714 17.7839V2.21569C31.7714 1.11739 30.8759 0.227051 29.7714 0.227051Z" fill="#4A4A4A" stroke="#4A4A4A" strokeWidth="0.588235"/>
        <path d="M12.2342 6.6574V13.7964H10.3767V9.52374V9.22962H10.0826H9.90888H9.69128L9.62763 9.4377L8.29425 13.7964H6.98071L5.64378 9.43749L5.58002 9.22962H5.36259H5.18888H4.89476V9.52374V13.7964H3.03728V6.6574H5.18529C5.42636 6.65807 5.66048 6.73674 5.85228 6.88127C6.04415 7.02584 6.18317 7.22837 6.24867 7.45804L6.2488 7.45848L7.26731 11.0085L7.32842 11.2215H7.55002H7.72419H7.94579L8.00691 11.0085L8.98382 7.60347L9.02564 7.45931C9.16374 6.9832 9.59637 6.6574 10.0858 6.6574H12.2342ZM24.7432 11.5114H24.449V11.8056V13.7964H22.5769V10.3279H29.5529C29.0825 11.0301 28.2209 11.5114 27.2186 11.5114H24.7432ZM15.6579 13.7964H13.8766V6.6574H15.7341V10.9301V11.2242H16.0282H16.2019H16.3906L16.4692 11.0528L18.1905 7.30158C18.1906 7.30143 18.1907 7.30127 18.1907 7.30111C18.3717 6.90966 18.7634 6.6574 19.1944 6.6574H20.9761V13.7964H19.1191V9.52646V9.23235H18.825H18.6504H18.4618L18.3831 9.40373L16.6616 13.1525C16.4813 13.5439 16.0896 13.7964 15.6579 13.7964Z" fill="#EAEAEA" stroke="#EAEAEA" strokeWidth="0.588235"/>
        <path d="M29.0923 7.2977L29.0931 7.29843C29.3413 7.52556 29.5391 7.80145 29.6741 8.1085C29.8091 8.41555 29.8784 8.7471 29.8775 9.08209V9.08283C29.8775 9.14455 29.8745 9.2064 29.869 9.26871H25.5842C24.1786 9.26871 22.9857 8.3421 22.5845 7.06041L22.5805 7.04788L22.5798 7.04621L22.5793 7.04406L22.5768 7.03445C22.5752 7.0284 22.573 7.02052 22.5702 7.01156C22.5667 6.99919 22.5632 6.98486 22.558 6.96336L22.5575 6.96102C22.5531 6.94272 22.5473 6.91844 22.5402 6.89339C22.5223 6.81525 22.5072 6.73654 22.495 6.6574H27.4617C27.4619 6.6574 27.462 6.6574 27.4622 6.6574C28.0907 6.65752 28.6598 6.89895 29.0923 7.2977Z" fill="#EAEAEA" stroke="#EAEAEA" strokeWidth="0.588235"/>
      </g>
      <defs>
        <clipPath id="clip0_15899_61001">
          <rect width="32" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
