import {
  ON_SUCCESS_SIGNUP,
  ON_SUCCESS_LOGIN,
  ON_CABINET,
  ON_RUN_STANDALONE,
  ON_RECEIVED_TOKENS,
} from '@observer/constants';
import { MODE_ALIAS, UNITY, DEMO, DOWNLOAD_EXTENSION, TOKENS_UPDATE, PROFILE_STATUS, LOGOUT } from '@globalConstants';
import {
  POP_SCREEN_ENTRY_DOMAIN,
  POP_CURRENT_SUB_DOMAIN,
  POP_SCREEN_ENTRY_LOGIN,
  POP_USER_ID_FROM_TOKEN,
  POP_USER_SETTINGS
} from '@globalLocalStorage';
import { actions as invokeActions } from '@globalInvoke';
import { getErrorMessage } from '@globalErrorMessages';
import { LocalStorage, SessionStorage, isProdHost, uiHost, Cookie } from '@libs';
import {
  ENTRY_STEPS,
  ERROR_TYPES,
  UPDATE_STATUSES,
  TRY_AGAIN_UPDATE,
  CHECKING_MODE,
  ENTRY_SUCCESS, ENTER_METHOD,
  ENTER_ROUTE_PATH,
  SIGN_UP_MODE,
  LOGIN_MODE,
} from '../constants';
import { analytics } from './analytics';
import { ApiCall } from './api-call';
import { events } from './invoke';
import { setCheckingEmail, setSocialInfo } from './Store';
import * as actions from './Store';
import * as login from './login';
import * as signup from './signup';
import { POP_SESSION } from '@globalCookie';

const observer = new window.POPObserver();

// подпись для смены пароля
let passwordRestoreSign = '';
let passwordRestoreHash = '';

// Смена шага
export const setStep = (step, payload = {}) => (dispatch, getState) => {
  const state = getState();
  const { step: prevStep, error = {} } = state;

  dispatch(actions.setStep(step));
  dispatch(actions.setPrevStep(prevStep, payload));

  if (error.message && error.type === ERROR_TYPES.LOCAL) {
    dispatch(actions.clearError());
  }
};

// Выбор текущего домена
export const setSelectedDomain = (domain) => (dispatch) => {
  const subDomain = domain.value !== 'planoplan' ? domain.value : '';

  dispatch(actions.setSelectedDomain(domain));

  LocalStorage.set(POP_SCREEN_ENTRY_DOMAIN, domain);
  SessionStorage.set(POP_CURRENT_SUB_DOMAIN, subDomain);
};

// Очистка ошибки, не action т.к вызывается внутри app
export const clearError = () => (dispatch) => dispatch(actions.clearError());

// Проверка email при регистрации или авторизации если требуется
export const checkEmail = (code) => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  const state = getState();
  const { signUpMode, loginMode, checkingMode, prevStep = {}, settings = {}, surveyCompleted } = state;
  const { mode } = settings;
  const { name, payload } = prevStep;
  const isAfterLogin = name === ENTRY_STEPS.DOMAINS || name === ENTRY_STEPS.LOGIN || name === ENTRY_STEPS.UPDATE;
  let modeEnter = signUpMode;

  if (signUpMode !== SIGN_UP_MODE.EMAIL) {
    modeEnter = signUpMode;
  } else if (loginMode !== LOGIN_MODE.EMAIL) {
    modeEnter = loginMode;
  }

  try {
    const response = await ApiCall.checkEmail({ code });
    const { data } = response;
    const { success, errorText } = data;

    if (!success && errorText) {
      return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage(errorText) }));
    }

    if (!success) {
      throw new Error('Check email ended with an error');
    }

    // если при логине почта не подтверждена
    if (isAfterLogin && !surveyCompleted) {
      dispatch(setStep(ENTRY_STEPS.WELCOME));
      return;
    }

    if (isAfterLogin) {
      dispatch(setInitAnalytics(payload.id));
      analytics.pushEvent('login', 'sent', 'email');
      dispatch(successLogin());
      return;
    }

    if (!isAfterLogin) {
      analytics.pushEvent('sign-up-conf', 'sent', modeEnter);

      // для сайта перекидываем на шаг загрузки поп
      if (MODE_ALIAS[mode] !== UNITY) {
        dispatch(setStep(ENTRY_STEPS.DOWNLOAD));
      } else {
        dispatch(setStep(ENTRY_STEPS.WELCOME));
      }
    }
  } catch (error) {
    dispatch(actions.setCheckingMode(checkingMode));
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.CHECK, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Повторная оптравка кода подверждения email на почту
export const resendCheckEmailCode = (code) => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  const state = getState();
  const { checkingMode } = state;

  try {
    const response = await ApiCall.resendCheckEmail({ code });
    const { data } = response;
    const { success, errorText } = data;

    if (!success) {
      throw new Error(errorText);
    }

    dispatch(setCanResendCode(false));
  } catch (error) {
    dispatch(actions.setCheckingMode(checkingMode));
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.CHECK, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Установка имени и отправка аналитики с опросом
export const welcome = (fields) => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  const state = getState();
  const { signUpMode, loginMode, settings = {}, isLogin } = state;
  const { mode, autoInput } = settings;
  const name = fields.name.value;
  const survey = {};

  try {
    for (let key in fields) {
      if (Number(key)) {
        survey[`survey[${key}]`] = fields[key].value;
      }
    }

    const response = await ApiCall.setProfileFields({ name, ...survey });
    const { data } = response;
    const { success, errorText } = data;

    if (!success) {
      throw new Error(errorText);
    }

    let modeEntry = signUpMode;

    if (signUpMode !== SIGN_UP_MODE.EMAIL) {
      modeEntry = signUpMode;
    } else if (loginMode !== LOGIN_MODE.EMAIL) {
      modeEntry = loginMode;
    }

    analytics.pushWelcome(fields);
    analytics.pushEvent('sign-up-welcome', 'close', modeEntry);

    if (MODE_ALIAS[mode] !== UNITY) {
      dispatch(setStep(ENTRY_STEPS.THANK, {text: "hello"}));
      return;
    }

    if (isLogin || autoInput) {
      dispatch(successLogin());
    } else {
      dispatch(successSignup());
    }
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.WELCOME, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Отправка запроса за восстановлением пароля
export const recoveryPassword = (email) => async (dispatch) => {
  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.getSignRestorePassword({ email });

    const { data } = response;
    const { success, errorText } = data;

    if (!success && errorText) {
      const text = errorText === 'Too many attempts' ? 'Too many attempts reset password' : errorText;
      return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage(text) }));
    }

    if (!success || !data.data || !data.data.sign) {
      throw new Error('Recovery password is not success or not data sign');
    }

    passwordRestoreSign = data.data.sign;

    dispatch(setCanResendCode(false));
    dispatch(actions.setFirstLogin(true));
    dispatch(actions.setCheckingEmail(email));
    dispatch(actions.setCheckingMode(CHECKING_MODE.PASSWORD_RECOVERY));
    dispatch(setStep(ENTRY_STEPS.CHECK));
    analytics.pushEvent('sign-up', 'sent', 'recovery');
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.RECOVERY_PASSWORD, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Подверждение кода при сбросе пароля
export const confirmRecoveryPassword = (code) => async (dispatch) => {
  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.confirmRestorePassword({ sign: passwordRestoreSign, code });
    const { data } = response;
    const { success, errorText } = data;

    if (!success && errorText) {
      return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage(errorText) }));
    }

    if (!success || !data.data || !data.data.hash) {
      throw new Error('Confirm recovery password is not success or not data hash');
    }

    passwordRestoreHash = data.data.hash;

    dispatch(setStep(ENTRY_STEPS.NEW_PASSWORD));
  } catch (error) {
    dispatch(actions.setCheckingMode(CHECKING_MODE.PASSWORD_RECOVERY));
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.CHECK, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Повторная отправка кода при сбросе пароля
export const resendRecoveryPasswordCode = () => (dispatch, getState) => {
  const state = getState();
  const { checkingEmail } = state;

  dispatch(recoveryPassword(checkingEmail));
};

// Сохранение нового пароля
export const newPassword = (password) => async (dispatch) => {
  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.newPassword({
      hash: passwordRestoreHash,
      pwd: password,
      pwd_repeat: password,
    });

    const { data } = response;
    const { success, errorText } = data;

    if (!success && errorText) {
      return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: getErrorMessage(errorText) }));
    }

    if (!success) {
      throw new Error('New password is not success');
    }

    dispatch(setStep(ENTRY_STEPS.SUCCESS));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.NEW_PASSWORD, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Получение инфы о пользователе для аналитики.
export const setInitAnalytics = (user_id) => async (dispatch, getState) => {
  const state = getState();
  const { mode } = state.settings;

  // т.к на старом сайте своя аналитика
  if (MODE_ALIAS[mode] !== UNITY) {
    return;
  }

  try {
    const response = await ApiCall.getAnalyticsData();
    const { data } = response.data;

    analytics.load(user_id, data.tariff);
  } catch (e) {
    console.error('setInitAnalytics', e);
  }
};

// установка возможности повторной отправки кода (не чисто actions т.к вызывается в app.js)
export const setCanResendCode = (status = true) => (dispatch) => {
  dispatch(actions.setCanResendCode(status));
};

// установка процентов завершения обновления
export const setInstallingPercent = (value) => (dispatch) => {
  dispatch(actions.setDownloadedPercent(value));
  dispatch(actions.setUpdateStatus(UPDATE_STATUSES.INSTALLING));
};

// установка отсутствия необходимости обновления
export const setUpdateNotRequired = () => (dispatch) => {
  dispatch(actions.setUpdateStatus(UPDATE_STATUSES.NOT_REQUIRED));
};

// установка ошибки во время обновления
export const setUpdateError = (error = '') => (dispatch) => {
  dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.UPDATE, error }));
  dispatch(setStep(ENTRY_STEPS.ERROR));
};

// проверка возможности автовхода, только для unity
export const checkAutoInput = () => async (dispatch, getState) => {
  const state = getState();
  const { updateStatus, settings = {} } = state;
  const { autoInput, firstEntry } = settings;

  const autoLoginInApp = async () => {
    try {
      const response = await ApiCall.getUserProfile();
      const { data } = response;

      if (!data) {
        throw new Error('Check auto input have not login data');
      }

      const { id, email, status, name, avatar, surveyed } = data;

      dispatch(actions.setProfile(data));
      dispatch(getUserSettings());
      LocalStorage.set(POP_SCREEN_ENTRY_LOGIN, email);

      // если нет email то считаем что это социалка
      if (!email) {
        dispatch(setSocialInfo(name, avatar));
        dispatch(setStep(ENTRY_STEPS.SOCIAL));
      }

      if (email && status === PROFILE_STATUS.NOT_CONFIRM) {
        dispatch(moveOnCheckingEmail({ id, email }));
        return;
      }

      if (!Boolean(surveyed)) {
        dispatch(moveOnSurveyScreen(surveyed));
        return;
      }

      if (email && status === PROFILE_STATUS.CONFIRM) {
        dispatch(successLogin());
        dispatch(setInitAnalytics(id));
      }
    } catch (error) {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  };

  if (autoInput && updateStatus === UPDATE_STATUSES.NOT_REQUIRED) {
    await autoLoginInApp();

    return;
  }

  if (!autoInput && updateStatus === UPDATE_STATUSES.NOT_REQUIRED) {
    dispatch(setStep(firstEntry ? ENTRY_STEPS.SIGNUP : ENTRY_STEPS.LOGIN));

    return;
  }

  if (updateStatus === UPDATE_STATUSES.CHECKING || updateStatus === UPDATE_STATUSES.INSTALLING) {
    dispatch(setStep(ENTRY_STEPS.UPDATE));

    return;
  }

  if (updateStatus === UPDATE_STATUSES.ERROR_UPDATE) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error: 'Update status is ERROR_UPDATE' }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  }
};

// событие на повторное установку обновления
export const retryUpdate = () => (dispatch) => {
  dispatch(setStep(ENTRY_STEPS.UPDATE));

  window.invokeEditorAction({
    name: events[TRY_AGAIN_UPDATE].name,
    value: events[TRY_AGAIN_UPDATE].payload,
  });
};

// открытие кабинета
export const openCabinet = () => {
  observer.postMessage(ON_CABINET);
  observer.postMessage(ON_SUCCESS_SIGNUP);
};

// запуск ПОП
export const runStandalone = () => async (dispatch, getState) => {
  const { settings = {}, checkingEmail } = getState();
  const { mode } = settings;

  let openLink = 'planoplan://';

  // на сайте запрашиваем токен для проброса авторизации в юнити
  if (MODE_ALIAS[mode] !== UNITY) {
    dispatch(actions.startProcessing());

    try {
      const response = await ApiCall.getSeamlessAuthToken();
      const { data } = response;
      const { success, data: { token } } = data;

      if (success && token) {
        openLink = `planoplan:open?token=${token}${checkingEmail && '&email=' + checkingEmail}`;
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.endProcessing());
    }
  }

  observer.postMessage(ON_RUN_STANDALONE);
  observer.postMessage(ON_SUCCESS_SIGNUP);

  window.location = openLink;
};

export const runStandaloneWithParams = (params = '') => {
  const separate = (params && !isProdHost) ? '&' : '';

  window.location = `planoplan:open?${params}${separate}${isProdHost ? '': '-usetestserver -allowbrowserconsole'}`;
};

// Перемещание на экран подверждения почты
export const moveOnCheckingEmail = ({ id, email }) => (dispatch) => {
  dispatch(setCheckingEmail(email));
  dispatch(actions.setCheckingMode(CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN));
  dispatch(setStep(ENTRY_STEPS.CHECK, { id }));
};

// Перемещание на экран опроса
export const moveOnSurveyScreen = (surveyed) => (dispatch) => {
  dispatch(actions.setSurveyCompleted(surveyed));
  dispatch(setStep(ENTRY_STEPS.WELCOME));
};

// Пропустить шаг подтверждения электронной почты
export const onSkipCheckEmail = () => (dispatch, getState) => {
  const state = getState();
  const { isLogin, surveyCompleted, settings: { autoInput }} = state;
  const idUser = LocalStorage.get(POP_USER_ID_FROM_TOKEN) || null;

  analytics.pushEvent('sign-up-conf', 'skip' , 'email', idUser);
  analytics.pushEventGA4('sign_up_conf_skip');

  if (!Boolean(surveyCompleted)) {
    dispatch(setStep(ENTRY_STEPS.WELCOME));

    return;
  }

  if (isLogin || autoInput) {
    dispatch(successLogin());
  } else {
    dispatch(successSignup());
  }
};

export const tokensReceived = (payload) => (dispatch, getState) => {
  const { settings = {} } = getState();
  const { mode } = settings;

  observer.postMessage(ON_RECEIVED_TOKENS, payload);

  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: invokeActions[TOKENS_UPDATE].name,
      value: payload,
    });
  }
};

export const successLogin = () => (dispatch, getState) => {
  const { settings = {} } = getState();
  const { mode } = settings;

  observer.postMessage(ON_SUCCESS_LOGIN);

  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: events[ENTRY_SUCCESS].name,
      value: events[ENTRY_SUCCESS].payload,
    });

    dispatch(setStep(ENTRY_STEPS.INPUT));
  }
};

export const successSignup = () => (dispatch, getState) => {
  const { settings = {} } = getState();
  const { mode } = settings;

  observer.postMessage(ON_SUCCESS_SIGNUP);

  if (MODE_ALIAS[mode] === UNITY) {
    window.invokeEditorAction({
      name: events[ENTRY_SUCCESS].name,
      value: events[ENTRY_SUCCESS].payload,
    });

    dispatch(setStep(ENTRY_STEPS.INPUT));
  }
};

// Смена шага и отправка аналитики при скачивании редактора
export const downloadStandalone = () => (dispatch, getState) => {
  const state = getState();
  const { settings = {} } = state;
  const { mode, os } = settings;

  if (MODE_ALIAS[mode] === DEMO) {
    analytics.pushEvent('file-editor', 'download', DOWNLOAD_EXTENSION[os], 'new UserID from CMS');
  }

  dispatch(setStep(ENTRY_STEPS.WELCOME));
};

/**
 * Определение через что производится вход и как дальше логинить через гугул.
 * @param {string} method - вход или регистрация (login / signup)
 * @param {string} locale - Локаль
 * @param {string} code - Код гугла
 */
export const checkGoogle = ({ locale, code = '' }) => async (dispatch, getState) => {
  const state = getState();
  const { settings: { mode }, step } = state;
  const method = step === ENTRY_STEPS.LOGIN ? ENTER_METHOD.login : ENTER_METHOD.signup;

  if (MODE_ALIAS[mode] === UNITY) {
    const url = `${uiHost}/${ENTER_ROUTE_PATH.google}?method=${method}&locale=${locale}`;

    if (window.EditorInvokeFunction) {
      window.EditorInvokeFunction('UrlOpen', url);
    }
  } else if (method === ENTER_METHOD.login) {
    dispatch(login.getGoogleCode({ code }));
  } else if (method === ENTER_METHOD.signup) {
    dispatch(signup.googleSignUp({ code }));
  }
};

/**
 * Определение через что производится вход и как дальше логинить через Vk.
 * @param {string} method - вход или регистрация (login / signup)
 * @param {string} locale - Локаль
 */
export const checkVk = ({ method, locale }) => async (dispatch, getState) => {
  const state = getState();
  const { settings: { mode }} = state;

  if (MODE_ALIAS[mode] === UNITY) {
    const url = `${uiHost}/${ENTER_ROUTE_PATH.vk}?method=${method}&locale=${locale}`;

    if (window.EditorInvokeFunction) {
      window.EditorInvokeFunction('UrlOpen', url);
    }
  } else if (method === ENTER_METHOD.login) {
    dispatch(login.getVKCode({mode: 'vkontakte_popup'}));
  } else if (method === ENTER_METHOD.signup) {
    dispatch(signup.vkSignUp({mode: 'vkontakte_popup'}));
  }
};

/**
 * Возращает параметр из урла хеша. Ex hash: /#id=1&user=ivan
 * @param {string} name - название хеш параметра
 * @return {string} - значение параметра
 */
export const getFromHash = (name) => {
  if (!name) {
    return '';
  }

  try {
    const hash = window.location.hash;
    const hashArray = hash.slice(1).split('&');
    const hashObject = {};

    hashArray.forEach((hashLine) => {
      const [key, value] = hashLine.split('=');

      hashObject[key] = value;
    });

    return hashObject[name];
  } catch (error) {
    console.error(error);

    return '';
  }
};

export const getCountryCode = async () => {
  try {
    const response = await ApiCall.getCountryCode();
    const { data } = response;
    const { country } = data;
    if(data) {
      return country;
    }
  } catch (e) {
    console.error('getCountryCode', e);
  }
};

export const logout = () => {
  Cookie.remove(POP_SESSION);

  window.invokeEditorAction({
    name: invokeActions[LOGOUT].name,
  });
};

export const getUserSettings = () => async (dispatch, getState) => {
  try {
    const response = await ApiCall.getUserSettings();
    const { data } = response;

    if (!data.success) {
      throw new Error('Error request getUserSettings');
    }

    LocalStorage.set(POP_USER_SETTINGS, data.data);
  } catch (error) {
    console.error(error);
  }
};
