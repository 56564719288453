import React from 'react';

export const IMagic = ({ width = 16, height = 16, fill = '#4A4A4A' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.707107" width="1.76469" height="14.1317" rx="0.882344" transform="matrix(-0.707107 0.707107 0.707107 0.707107 4.1416 2.89387)" stroke={fill}/>
      <path d="M4.62207 3.35701L3.35691 4.62217L6.30609 7.57134L7.57125 6.30618L4.62207 3.35701Z" fill={fill} stroke={fill}/>
      <path d="M3 1.5H0" stroke={fill}/>
      <path d="M7 12.5H2" stroke={fill}/>
      <path d="M1.5 0L1.5 3" stroke={fill}/>
      <path d="M4.5 10L4.5 15" stroke={fill}/>
    </svg>
  );
};
