import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ICameraOff } from 'planoplan-ui-kit/core/icons/i-camera-off';
import { IWarning } from 'planoplan-ui-kit/core/icons/i-warning';
import { tablet_landscape } from '../../../modules/breakpoints';

export const Message = ({ type = 'progress', zoom = null,  children }) => (
  <View isError={type === 'error'} isWarning={type === 'warning'} zoom={zoom}>

    {type === 'progress' && <Preloader width="80px" height="80px" fill={Colors.planoplan_blue} /> }
    {type === 'error' && <ICameraOff width="74px" height="74px" fill={Colors.colar} />}
    {type === 'warning' && <IWarning stroke={Colors.dark_cream}/>}
    <Text>{children}</Text>
  </View>
);

const View = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  background-color: ${(p) => (p.isWarning ? Colors.night : rgba(Colors.white, 0.8))};
  border-width: 5px;
  border-style: solid;
  border-color: ${(p) => ((p.isError && Colors.colar) || (p.isWarning && Colors.dark_cream) || 'transparent')};
  z-index: 3;
  
  ${({ zoom }) => zoom && `
    transform: scale(${zoom});
  `};

  @media ${tablet_landscape} {
    border-top-right-radius: 10px;
  }
`;

const Text = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${Colors.coal};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`;
