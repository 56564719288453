import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs/translations';
import { useSelector } from 'react-redux';

export const Confirm = ({
  title = 'gallery.confirm.title',
  agree = 'gallery.confirm.agree',
  onSaveNew = () => {},
  onSaveCurrent = () => {},
  onCloseWithoutSave = () => {},
  onClose = () => {}
}) => {
  const { image } = useSelector(state => state);
  const isSvg = image.type === 'svg';

  return (
    <View>
      <Wrapper>
        <Close onClick={onClose}>
          <IClose width="38" height="38" fill={Colors.white} />
        </Close>
        <Form>
          <Title>{translations.t('image_editor.save.title')}</Title>

          <Actions>
            <Action>
              <Button size="M" theme="primary" onClick={onSaveNew}>
                {translations.t('image_editor.save.new')}
              </Button>
            </Action>

            {!isSvg &&
              <Action>
                <Button size="M" theme="primary" onClick={onSaveCurrent}>
                  {translations.t('image_editor.save.current')}
                </Button>
              </Action>
            }

            <Action>
              <Button size="M" theme="secondary" onClick={onCloseWithoutSave}>
                {translations.t('image_editor.save.close')}
              </Button>
            </Action>
          </Actions>
        </Form>
      </Wrapper>
    </View>
  )
};

const View = styled.div`
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: rgba(0, 0, 0, 0.5);
 z-index: 3;
 border-radius: 10px;
 display: flex;
 justify-content: center;
 align-items: center;
`;
const Wrapper = styled.div`
  position: relative;
  margin-top: 24px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 560px;
  padding: ${isMobile ? '0 60px 0 10px' : '0 70px'};
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 10px;
`;
const Form = styled.div`
  width: 100%;
  min-height: 360px;
  background-color: #fff;
  border-radius: 10px;
  padding: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Title = styled.h3`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 14px;
  color: ${Colors.coal};
  font-family: 'Montserrat', sans-serif;
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -10px -10px;
`;
const Action = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
`;
