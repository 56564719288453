import { Colors } from 'planoplan-ui-kit/core/colors';

export const themes = {
  theme: {
    primary: {
      color: Colors.coal,
      textarea: {
        backgroundColor: Colors.white,
        borderColor: Colors.solitude,
      },
      focus: {
        borderColor: Colors.electric_blue,
        boxShadow: Colors.electric_blue,
      },
      hover: {
        borderColor: Colors.planoplan_blue,
      },
    },
    secondary: {
      color: Colors.coal,
      textarea: {
        backgroundColor: Colors.mint,
        borderColor: Colors.solitude,
      },
      focus: {
        borderColor: Colors.electric_blue,
        boxShadow: Colors.electric_blue,
      },
      hover: {
        borderColor: Colors.planoplan_blue,
      },
    },
  },

  size: {
    M: {
      view: {
        fontSize: '16px',
        lineHeight: '19px',
      },
      label: {
        marginBottom: '4px',
      },
      textarea: {
        padding: '16px 16px 16px 16px',
      },
      error: {
        fontSize: '13px',
        lineHeight: '16px',
        marginTop: '2px',
      },
    },

    S: {
      view: {
        fontSize: '13px',
        lineHeight: '16px',
      },
      label: {
        marginBottom: '2px',
      },
      textarea: {
        padding: '10px 10px 10px 10px',
      },
      error: {
        fontSize: '12px',
        lineHeight: '14px',
        marginTop: '2px',
      },
    },
  },
};
