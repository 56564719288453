export const createStringWithGet = (string, params) => {
  for (let i in params) {
    const hasOwnProperty = Object.prototype.hasOwnProperty.call(params, i);

    if (hasOwnProperty && params[i]) {
      const separate = string.includes('?') ? '&' : '?';
      string += separate + i + '=' + params[i];
    }
  }

  return string;
};
