import { ApiCall } from '../../modules/api-call';
import * as getData from '../../modules/getdata';
import { notifications } from '@features/notifications';
import { translations } from '@libs';

export const editSubscriptions = (params) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.editSubscriptions(params);

    if (!response) {
      notifications.showError(translations.t('cabinet.notification.updateSubscribes.error'));
      return;
    }

  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = () => async (dispatch, getState) => {
  try {
    const { data } = await ApiCall.deleteUser();

    if (data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.deleteProfile.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.deleteProfile.error'));
    }
  } catch (error) {
    console.error(error);

    notifications.showError(translations.t('cabinet.notification.deleteProfile.error'));
  }
};

export const checkFileByFormat = (file, accept) => async (dispatch) => {
  const formats = accept.replace(/[.\s]/g, '').split(',');
  const format = file.type.split('+')[0].split('/')[1];
  const res = formats.indexOf(format) !== -1;

  return res;
};

export const editUser = (data) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.editUser(data);

    if (!response || response.status !== 200) {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'));
      return;
    }

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.editProfile.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'))
    }

    dispatch(getData.getProfile());
    dispatch(getData.getSubscriptions());
  } catch(error) {
    console.error()
  }
};
