import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as actions from '../../../modules/Store/action';
import { translations } from '@libs';
import { getPrices } from '../../../modules/helpers';

export const ChoosePackage = () => {
  const dispatch = useDispatch();
  const { selected: { pack }, profile: { viewCurrency }} = useSelector(state => state);

  const { sets } = useMemo(() => dispatch(getPrices()));

  if (!pack) {
    return (<Error>Error!</Error>)
  }

  const setPack = (val) => {
    dispatch(actions.setPack(val));
  };

  return (
    <View>
      <Choose>
        {sets.map(item => (
          <ChooseItem key={item.id}
                      isActive={pack === item.count}
                      onClick={() => setPack(item.count)}>
            <Content>
              <Price>{item.packPrice} {viewCurrency}</Price>
              <Caption>{translations.t('quickstore.per-render')}, <br/>{translations.t('quickstore.package-from')} {item.count} {translations.t('quickstore.per')}</Caption>
              {Boolean(item.discount) && <Discount>-{item.discount}%</Discount>}
            </Content>
          </ChooseItem>
        ))}
      </Choose>
    </View>
  );
};

const View = styled.div``;
const Choose = styled.div`
  display: flex;
`;
const Content = styled.div`
  padding: 12px 4px;
  position: relative;
  width: 100%;
  height: 100%;
`;
const Price = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
`;
const Caption = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
`;
const Discount = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  transform: translateY(-50%);
  border: 1px solid ${Colors.night};
  border-radius: 10px;
  background-color: ${Colors.dark_cream};
  padding: 2px 6px;
  color: ${Colors.night};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`;
const ChooseItem = styled.div`
  border: 1px solid ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  margin-left: -1px;
  position: ${({ isActive }) => isActive ? 'relative' : 'static'};
  cursor: pointer;
  text-align: center;
  flex: 1;
  
  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  
  ${Discount} {
    background-color: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  }
`;
const Error = styled.div`
  color: ${Colors.dull_red};
`;
