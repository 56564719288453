import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { tablet_landscape } from '../../modules/breakpoints';

export const Error = ({ children }) => (
  <View>
    <Text data-test="error-message">{children}</Text>
  </View>
);

const View = styled.div`
  position: relative;
  top: -10px;

  @media ${tablet_landscape} {
    top: -14px;
  }
`;
const Text = styled.div`
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  color: ${Colors.colar};
  text-align: center;
  position: absolute;
`;
