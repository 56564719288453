import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { useDispatch, useSelector } from 'react-redux';
import { translations, isProdHost } from '@libs';
import * as getData from './modules/getdata';
import { DOMAINS, POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE, URL_OPEN } from '@globalConstants';
import { interfaces, url } from '@globalInvoke';
import { Header } from './components/header';
import { rail_y, thumb_y } from './scroll';
import { PanelText } from './components/panel-text';

export default () => {
  const dispatch = useDispatch();
  const [isHidden, setIsHidden] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const { alias, article, hintOpen, settings: { close, locale }} = useSelector(store => store);
  const { title, image, short_text } = article.form_data || {};
  const positionRight = 50;

  /* Передача управления Юнити */
  const pointerOffInterface = () => {
    if (!window.cabinetIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }
  };
  /* Передача управления Интерфейсам */
  const pointerOnInterface = () => {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  };
  const onShow = () => setIsHidden(false);
  const onHide = () => {
    setIsHidden(true);
    pointerOffInterface();
  };
  const onClose = () => {
    if (hintOpen) {
      setIsNone(true);
      setIsOpenPanel(true);
    }

    /* Закрыть через N секунд */
    close(hintOpen ? 8000 : 0);
  };
  const openUrlMore = () => {
    const domain = isProdHost ? DOMAINS.PROD : DOMAINS.DEV;
    const link = `${domain}/${locale}/library/${article.category}/${article.subcategory}/${article.alias}`;

    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: link,
    });
  };

  useEffect(() => {
    (async () => {
      await dispatch(getData.getTranslations());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getData.getLibrary(alias));
    })();
  }, [alias]);

  return (
    <View>
      <Container isNone={isNone} onMouseEnter={pointerOnInterface} onMouseLeave={pointerOffInterface} right={positionRight}>
        <Header title={title} isHidden={isHidden} onHide={onHide} onShow={onShow} onClose={onClose} />
        {!isHidden &&
        <Content>
          <Image>
            <img src={image} alt={title}/>
          </Image>

          <Text>
            <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
              <Title>{title}</Title>
              <TextInner dangerouslySetInnerHTML={{ __html: short_text }} />
            </ScrollBar>
          </Text>

          <More><span onClick={openUrlMore}>{translations.t('assistant.more')} {title}</span></More>
        </Content>
        }
      </Container>

      <PanelText text={translations.t('assistant.infoOpen')} isOpen={isOpenPanel} right={positionRight} />
    </View>
  );
};

const View = styled.div``;
const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 300px;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  right: ${({ right }) => `${right}px`};
  z-index: 1;
  border: 1px solid ${Colors.whisper};
  border-radius: 5px;
  background-color: #F3F9FF;
  display: ${({ isNone }) => isNone ? 'none' : 'flex'};
  flex-direction: column;
`;
const Content = styled.div`
  padding: 11px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  height: 473px;
  border-top: 1px solid ${Colors.whisper};

`;
const Image = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  height: 230px;
  overflow: hidden;
  flex-shrink: 0;
  margin-bottom: 18px;
  border-right: 1px solid ${Colors.light};
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Text = styled.div`
  overflow: hidden;
  height: 100%;
  font-size: 14px;
  line-height: 17px;
  margin-right: -10px;
`;
const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.coal};
`;
const TextInner = styled.div`
  padding-right: 10px;
  
  ul, ol {
    padding-left: 20px;
  }
`;
const More = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  margin-top: 12px;
  color: ${Colors.planoplan_blue};
  cursor: pointer;
`;
