import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '@libs';
import * as effects from '../../modules/effects';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ON_OPEN_STORE } from '@observer/constants';
import { PAGES, TEAM_ROLES } from '../../constants';
import { Teams } from './organism/teams';
import { ILogo } from 'planoplan-ui-kit/core/icons/i-logo';
import { UserTariff } from '../UserTariff';
import { MenuItem } from './organism/MenuItem';
import {
  IconLayouts,
  IconBalance,
  IconProfile,
  IconLogin,
  IconSubscribe,
  IconHistory,
  IconLogout,
  IconStore,
  IconTeam,
} from '../icons';

const observer = new window.POPObserver();

export const Navigation = () => {
  const dispatch = useDispatch();
  const { team, settings: { locale }} = useSelector(state => state);
  const currentTeam = team.current;

  const isMember = currentTeam && currentTeam?.role === TEAM_ROLES.member;
  const isAdmin = currentTeam && (currentTeam.role === TEAM_ROLES.admin || currentTeam.role === TEAM_ROLES.owner);

  const openStore = () => observer.postMessage(ON_OPEN_STORE, { team_id: team.id, });
  const logout = () => dispatch(effects.logout());

  return (
    <View>
      <Content>
        <LogoWrap><ILogo width="163" height="40" fillBrend={Colors.planoplan_blue} fillSeconds={Colors.coal} rebrand={true} locale={locale} /></LogoWrap>
        <TeamsWrap><Teams /></TeamsWrap>

        <List>
          <MenuItem alias={PAGES.layouts} text={translations.t('cabinet.projects.title')} Icon={IconLayouts}/>

          <Separator />

          <MenuItem alias={PAGES.balance} text={translations.t('cabinet.balance.title')} Icon={IconBalance}/>

          <MenuItem alias={PAGES.subscribe} text={translations.t('cabinet.subscribe.title')} Icon={IconSubscribe}/>

          {!currentTeam && <MenuItem alias={PAGES.purchaseHistory} text={translations.t('cabinet.purchase_history.title')} Icon={IconHistory}/>}

          <Separator />

          {!currentTeam && <MenuItem alias={PAGES.profile} text={translations.t('cabinet.profile.title')} Icon={IconProfile}/>}

          {isAdmin && <MenuItem alias={PAGES.profileTeam} text={translations.t('cabinet.team.profile.title')} Icon={IconProfile}/>}

          {!currentTeam && <MenuItem alias={PAGES.loginSettings} text={translations.t('cabinet.login_settings.title')} Icon={IconLogin}/>}

          {!isMember && <Separator />}

          {isAdmin && <MenuItem alias={PAGES.members} text={translations.t('cabinet.members.title')} Icon={IconTeam}/>}

          {!currentTeam && <MenuItem text={translations.t('cabinet.nav.store')} Icon={IconStore} onClick={openStore} />}

          {!currentTeam || isAdmin && <Separator />}

          <MenuItem text={translations.t('cabinet.nav.logout')} Icon={IconLogout} onClick={logout}/>
        </List>
      </Content>

      <Bottom>
        <UserTariff />
      </Bottom>
    </View>
  )
};

const View = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div``;
const Bottom = styled.div`
  margin-top: 24px;
`;
const LogoWrap = styled.div`
  margin-bottom: 28px;
`;
const TeamsWrap = styled.div`
  margin-bottom: 16px;
`;
const List = styled.div``;
const Separator = styled.div`
  margin-bottom: 16px;
  width: 100%;
  height: 1px;
  background-color: ${Colors.timberwolf25};
`;
