import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { List } from './list';
import { Item } from './item';

export const StoreItems = ({ title, items = [] }) => (
  <View>
    <Heading>{title}</Heading>
    <List>
      {items.map((props, index) => Boolean(!props.not_for_sale || props.count) && (
        <Item {...props} key={index} />
      ))}
    </List>
  </View>
);

export const View = styled.div`
  padding: 20px;
`;

const Heading = styled.h2`
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${Colors.blueBell};
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 18px;
  margin-top: 0;
  margin-bottom: -5px;
`;
