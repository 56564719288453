import React from 'react';
import styled from 'styled-components';
import { Notification } from './notification';

export const App = ({ notifications = [] }) => (
  <View>
    {notifications.map(({ id, type, message, lifeTime }) => (
      <Notification key={id} id={id} type={type} message={message} lifeTime={lifeTime} />
    ))}
  </View>
);

const View = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  position: fixed;
  z-index: 9999999;
  right: 0;
  bottom: 0;
`;
