export const initialState = {
  image: '',
  caption: '',
  slides: [],
  loading: {
    getters: {},
    processing: true,
  },
  settings: {},
};
