import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { getLocaleUi, LocalStorage } from '@libs';
import { POP_CURRENT_TEAM } from '@globalLocalStorage';
import { ON_CHANGE_PAYMENT_STATUS_STORE } from '@observer/constants';
import { App } from './app';
import { ApiCall } from './modules/api-call';
import { createAppStore, initialState } from './modules/Store';
import { setIsProcessing, setCurrentUser } from './modules/effects';
import { getGoods, getUserData, getTeams } from './modules/get-data';

export default class POPUserWidget {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения модуля не указан html элемент');
    this.mode = settings.mode || 'site'; // unity, site
    this.locale = settings.locale || 'en';
    this.currentTeam = settings.team_id || LocalStorage.get(POP_CURRENT_TEAM) || '';
    this.localeUi = getLocaleUi(this.locale);
    this.rootElement = null;
    this.autoUpdateInterval = '';
  }

  /**
   * Метод содержит подписки на события из других интерфейсов
   * @private
   */
  _subscribes() {
    const observer = new window.POPObserver();

    observer.addEventListener(ON_CHANGE_PAYMENT_STATUS_STORE, () => this.updateGoods());
  }

  /**
   * Открытие модуля
   * @public
   */
  open() {
    ApiCall.setSettings({ locale: this.locale, team_id: this.currentTeam });

    this._mount();
    this._subscribes();
  }

  /**
   * Удаление модуля из дом-дерева
   * @public
   */
  close() {
    if (this.rootElement) {
      ReactDOM.unmountComponentAtNode(this.rootElement);
    }

    clearInterval(this.autoUpdateInterval);
  }

  /**
   * Переключение статуса анимации прогесса загрузки
   * @param state
   * @public
   */
  setIsProcessing(state) {
    this.store.dispatch(setIsProcessing(state));
  }

  /**
   * Изменение пользователя
   * @param team_id - id команды, если нет то переключили на пользователя
   * @public
   */
  changeUser(team_id = '') {
    this.store.dispatch(setCurrentUser(team_id));
  }

  /**
   * Обновление товаров пользователя
   * @public
   */
  updateGoods() {
    this.store.dispatch(getGoods());
  }

  /**
   * Обновление профиля пользователя
   * @public
   */
  updateUserProfile() {
    this.store.dispatch(getUserData());
  }

  /**
   * Обновление групп пользователя
   * @public
   */
  updateTeams() {
    this.store.dispatch(getTeams());
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    this.rootElement = document.getElementById(this.root);

    const initialState = this._createInitialState();
    this.store = createAppStore(initialState);

    ReactDOM.render(
      <Provider store={this.store}>
        <App />
      </Provider>,
      this.rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      currentTeam: this.currentTeam,
      settings: {
        locale: this.locale,
        localeUi: this.localeUi, // язык для интерфейса из массива поддерживаемых языков
        mode: this.mode,
        setAutoUpdateInterval: this._setAutoUpdateInterval.bind(this),
        rootElement: this.rootElement,
      },
    };
  }

  /**
   * Сохраняет интервал автообнавления галереи
   * @param interval
   * @private
   */
  _setAutoUpdateInterval(interval) {
    this.autoUpdateInterval = interval;
  }
}

if (!window.POPUserWidget) {
  window.POPUserWidget = POPUserWidget;
}
