import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PANORAMAS,
  SET_CURRENT_PANORAMA,
  SET_STRUCTURE,
  SET_KRPANO,
  SET_PROGRESS_AUTO_SAVE,
  SET_TOUR_ID,
  SET_HOTSPOT_ICONS,
  SET_LAST_HOTSPOT_ICON,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_PANORAMAS:
      return {
        ...state,
        panoramas: action.payload,
      };
    case SET_CURRENT_PANORAMA:
      return {
        ...state,
        current: action.payload,
      };
    case SET_STRUCTURE:
      return {
        ...state,
        structure: action.payload,
      };
    case SET_KRPANO:
      return {
        ...state,
        krpano: action.payload,
      };
    case SET_PROGRESS_AUTO_SAVE:
      return {
        ...state,
        progressAutoSave: action.payload,
      };
    case SET_HOTSPOT_ICONS:
      return {
        ...state,
        hotspotIcons: action.payload,
      };
    case SET_LAST_HOTSPOT_ICON:
      return {
        ...state,
        lastHotspotIcon: action.payload,
      };
    case SET_TOUR_ID:
      return {
        ...state,
        settings: {
          ...state.settings,
          tourId: action.payload
        },
      };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
              text: action.payload.text || '',
            },
          },
          processing: true,
          error: '',
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }
    default:
      return state;
  }
};
