import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Range } from 'react-range';
import { getPercentValueSlider, getNumberValueSlider } from '../../modules/helpers';

export const RangeSlider = ({
  alias,
  value,
  min,
  max,
  scale = 1,
  centerAt = 0,
  onlyPositive,
  onChange = () => {},
  onDragging = () => {}
}) => {
  const [direction, setDirection] = useState(value >= centerAt ? 'right' : 'left');
  const [changed, setChanged] = useState(false);

  const onChangeWrap = (values) => {
    onChange(alias, getNumberValueSlider(values[0], min, max, centerAt));
  };
  const wrapOnDragging = (isDragged) => {
    onDragging(isDragged);
  };
  const clickThumb = (event) => {
    if (event.detail === 2) {
      onChange(alias, getNumberValueSlider(0, min, max, centerAt));
    }
  };

  useEffect(() => {
    setDirection(value >= centerAt ? 'right' : 'left');
    setChanged(Number(value) !== Number(centerAt));
  }, [value]);

  return (
    <View scale={scale} onlyPositive={onlyPositive}>
      <Range
        step={1}
        min={onlyPositive ? 0 : -100}
        max={100}
        values={[getPercentValueSlider(value, min, max, centerAt)]}
        onChange={onChangeWrap}
        onFinalChange={wrapOnDragging.bind(null, false)}
        renderTrack={({ props, children }) => (
          <Track scale={scale} {...props} >
            <TrackLine start={onlyPositive ? 0 : 50}
                       direction={direction}
                       percent={getPercentValueSlider(value, min, max, centerAt)}
                       ratio={onlyPositive ? 1 : 2}
                       scale={scale} />
            {children}
          </Track>
        )}
        renderThumb={({ props, isDragged }) => (
          <Thumb onMouseDown={wrapOnDragging.bind(null, true)}
                 onClick={clickThumb}
                 isDragged={isDragged}
                 scale={scale}
                 changed={changed}
                 {...props} />
        )}
      />
    </View>
  )
};

const View = styled.div`
  width: 100%;
  background-color: ${Colors.coal};
  padding: 0 ${({ scale }) => 18 / 2 * scale}px;
  border-radius: ${({ scale }) => 8 * scale}px;
  position: relative;
  
  &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${({ scale }) => 18 / 2 * scale}px;
      height: 100%;
      background-color: ${Colors.dark_cream};
      border-radius: ${({ scale }) => 8 * scale}px 0 0 ${({ scale }) => 8 * scale}px;
      display: ${({ onlyPositive }) => onlyPositive ? 'block' : 'none'};
    }
`;
const Track = styled.div`
  height: ${({ scale }) => 4 * scale}px;
  width: 100%;
  background-color: ${Colors.coal};
  border-radius: ${({ scale }) => 8 * scale}px;
  position: relative;
  z-index: 1;
`;
const TrackLine = styled.span`
  position: absolute;
  top: 0;
  ${({ direction }) => direction === 'right' ? 'left' : 'right'}: ${({ start }) => `${start}%`};
  height: 100%;
  width: ${({ percent, ratio }) => `${Math.abs(percent) / ratio}%`};
  background-color: ${Colors.dark_cream};
`;
const Thumb = styled.span`
  height: ${({ scale }) => 18 * scale}px;
  width: ${({ scale }) => 18 * scale}px;
  border-radius: 50%;
  background-color: ${({ isDragged, changed }) => (isDragged || changed) ? Colors.dark_cream : Colors.light_gray};
  border: ${({ scale }) => 2 * scale}px solid ${Colors.night};
  outline: none;
`;

