import { ApiCall } from '../../modules/api-call';
import { PUT_TARIFF, SET_STEPS_UNSUBSCRIBE } from '../../constants';
import { notifications } from "@features/notifications";
import { translations } from "@libs";
import * as getData from "@features/cabinet/modules/getdata";

export const unsubscribe = () => async (dispatch, getState) => {
  try {
    const { team } = getState();
    const { data } = await ApiCall.onAutoPaymentCancel(team.id);

    if (data.success) {
      dispatch({ type: PUT_TARIFF, payload: { goods_id: null } });
    }

    return data.success;
  } catch (error) {
    console.error(error);
  }
};

export const setDefaultCard = (id) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.setDefaultCard({ id });

    if (!response || !response.data.success) {
      notifications.showError(translations.t('cabinet.notification.defaultCard.error'));
      return;
    }

    if (response.data.success) {
      dispatch(getData.getCards());
      notifications.showSuccess(translations.t('cabinet.notification.defaultCard.success'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeCard = (id) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.removeCard({ id });

    if (!response || response.status !== 200) {
      notifications.showError(translations.t('cabinet.notification.removeCard.error'));
      return;
    }
    if (response.data.success) {
      dispatch(getData.getCards());
      notifications.showSuccess(translations.t('cabinet.notification.removeCard.success'));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getStepCancelSubscribe = () => async (dispatch, getState) => {
  try {
    const { tariff: { alias } } = getState(state => state);
    const { data } = await ApiCall.getCancelSteps({ tariff: alias });
    const stepsObject = data.data[Object.keys(data.data)[0]]?.items;
    const steps = [];

    if (data.success) {
      for (let key in stepsObject) {
        if (Object.prototype.hasOwnProperty.call(stepsObject, key)) {
          steps.push({
            id: stepsObject[key].id,
            alias: stepsObject[key].alias,
            ...stepsObject[key].form_data,
          });
        }
      }

      dispatch({ type: SET_STEPS_UNSUBSCRIBE, payload: steps });
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.subscribe.error'));
  }
};
