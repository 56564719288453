import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  POINTER_ON_INTERFACE,
  POINTER_OFF_INTERFACE,
} from '@globalConstants';
import { POP_CURRENT_TEAM } from '@globalLocalStorage';
import { interfaces } from '@globalInvoke';
import { PAGES } from './constants';
import { createAppStore, initialState } from './modules/Store';
import { LocalStorage } from '@libs';
import { App } from './app';
import { ApiCall } from './modules/api-call';
import { Analytics } from './modules/analytics';
import ErrorBoundary  from './ErrorBoundary';

export default class UICabinet {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings = {}) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения магазина не указан html элемент');
    this.locale = settings.locale || 'ru';
    this.zoom = settings.zoom;
    this.path = settings.path; // Нужно для кабинета Planoplan
    this.teamId = settings.teamId;
    this.projectId = settings.projectId || null;
    this.page = settings.page || null;
    this.isTest = settings.isTest || false; // Только для тестирования
    this.app_id = settings.app_id;

    // Нужно для кабинета Planoplan
    if (settings.teamId) {
      LocalStorage.set(POP_CURRENT_TEAM, settings.teamId)
    } else {
      LocalStorage.remove(POP_CURRENT_TEAM)
    }

    ApiCall.setSettings({ locale: this.locale });
    Analytics.init({ locale: this.locale });
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open() {
    this._mount();
    this._editorInvoke();

    window.cabinetIsOpen = true;
  }

  close() {
    this._unMount();

    window.invokeEditorAction({
      name: interfaces[POINTER_ON_INTERFACE].name,
      value: interfaces[POINTER_ON_INTERFACE].value,
    });

    window.cabinetIsOpen = false;
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const store = createAppStore(this._createInitialState());

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>,
      rootElement
    );
  }

  _createInitialState() {
    return {
      ...initialState,
      currentPage: {
        alias: this.page || PAGES.layouts,
      },
      team: {
        ...initialState.team,
        id: this.teamId
      },
      settings: {
        zoom: this.zoom,
        locale: this.locale,
        app_id: this.app_id,
        teamId: this.teamId, // teamId при запуске кабинета
        path: this.path, // Нужно для кабинета Planoplan
        projectId: this.projectId, // id Открытого проекта
        onClose: this.close.bind(this)
      }
    }
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  /**
   * Удаление компонента из дом-дерева
   * @private
   */
  _unMount() {
    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }
}

if (!window.UICabinet) {
  window.UICabinet = UICabinet;
}
