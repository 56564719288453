import React, { memo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { useSelector } from 'react-redux';
import { InfoTariff } from '../InfoTariff';
import { InfoPanorama } from '../InfoPanorama';
import { InfoRender } from '../InfoRender';

export const Information = memo(() => {
  const { goods: { isTariff, isPanorama }} = useSelector(state => state);

  return (
    <View isTariff={isTariff}>
      {isTariff && <InfoTariff />}
      {isPanorama && <InfoPanorama/>}
      {(!isPanorama && !isTariff) && <InfoRender />}
    </View>
  );
});

const View = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.night};
  border-radius: 10px 0 0 10px;
  width: ${({ isTariff }) => isTariff ? '360px' : '640px'};
  height: 100%;
  overflow: hidden;
`;
