import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Checkbox } from 'planoplan-ui-kit/core/checkbox';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const DuplicateProjectModal = ({ isOpen, onCancel = () => {}, onDuplicate = () => {} }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.duplicate.title')}</Header>
      <Text>
        <Checkbox
          id="withContent"
          theme="secondary"
          size="XS"
          onChange={(event) => {setIsChecked(event.value)}}
          checked={isChecked}>
          <Label>{translations.t('modal.duplicate.with_content')}</Label>
        </Checkbox>
      </Text>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={() => onDuplicate({ withContent: isChecked })}>
          {translations.t('modal.duplicate')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Text = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Label = styled.div`
  margin-left: 6px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
