import { ApiCall } from '../../modules/api-call';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import * as getData from '../../modules/getdata';
import { Analytics } from '../../modules/analytics';

export const setPromo = (promo, setConfirmIsOpen = () => {}, confirm) => async (dispatch, getState) => {
  const {
    team,
  } = getState();

  setConfirmIsOpen(false);

  try {
    let response;

    if (confirm) {
      response = await ApiCall.setPromo({ promo, team_id: team.id, confirm });
    } else {
      response = await ApiCall.setPromo({ promo, team_id: team.id });
    }

    if (!response || response.status !== 200) {
      notifications.showError(translations.t('cabinet.notification.promo.error'));

      return;
    }

    if (response.data.errorText === 'Requires confirmation of tariff change') {
      setConfirmIsOpen(true);

      return;
    }

    if (response.data.errorText) {
      notifications.showError(response.data.errorText);

      return;
    }

    const {
      active_from,
      active_to,
    } = response.data.data;

    Analytics.successUsePromo(promo, active_from, active_to);
    notifications.showSuccess(translations.t('cabinet.notification.promo.success'));
    dispatch(getData.getGoods());

    if (team.id) {
        dispatch(getData.getTeams);
    } else {
        dispatch(getData.getProfile);
    }
  } catch (error) {
    console.error(error);
  }
};
