import { ADD_TO_CART, SET_SCREEN } from '@features/store/constants';

export const actions = {
  SetScreen: {
    action: SET_SCREEN,
  },
  AddToCart: {
    action: ADD_TO_CART,
  },
};
