import { css } from 'styled-components';

export const globalStyles = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  a,
  label {
    -webkit-tap-highlight-color: transparent;
  }

  img {
    max-width: 100%;
  }

  sup {
    vertical-align: super;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin: 0;
  }
`;
