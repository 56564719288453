import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from '@components/link';
import { translations, LocalStorage } from '@libs';
import { MODE_ALIAS, PAUSE_TRACK, RESUME_TRACK, UNITY } from '@globalConstants';
import { POP_SCREEN_ENTRY_LOGIN } from '@globalLocalStorage';
import { UrlButton } from '@components';
import { ENTRY_STEPS, ERROR_TYPES, EMAIL_REGEXP } from '../../constants';
import { Header, Form, Field, ButtonAction, Social, Error, InputField, Description } from '../../components';
import { analytics } from '../../modules/analytics';
import { actions } from "@globalInvoke";

export const Login = ({
  setStep,
  onGetDomains,
  onVK,
  onGoogle,
  onClearError = () => {},
  isProcessing = false,
  error = {},
  settings = {},
  country,
}) => {
  const [fields, setFields] = useState({
    username: {
      value: LocalStorage.get(POP_SCREEN_ENTRY_LOGIN) || '',
      isValid: LocalStorage.has(POP_SCREEN_ENTRY_LOGIN),
      error: '',
    },
    password: {
      value: '',
      isValid: false,
      error: '',
    },
  });

  const [isValid, setIsValid] = useState(false);
  const isError = error.type === ERROR_TYPES.LOCAL;
  const { mode, locale } = settings;

  const onChange = ({ name, value, error }) => setFields({ ...fields, [name]: { ...fields[name], value, error, isValid: !error } });
  const onSubmit = (event) => {
    event.preventDefault();

    if (isValid) {
      onGetDomains(fields.username.value, fields.password.value);
    }
  };

  useEffect(() => {
    if (error.message) {
      onClearError();
    }

    const isError = Object.values(fields).find((item) => !item.isValid);

    return isError ? setIsValid(false) : setIsValid(true);
  }, [fields]);

  useEffect(() => {
    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    analytics.pushView('Форма Login', 'login', 'view', '');

    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
    }
  }, []);

  return (
    <>
      <Header title={translations.t('form_entry.login.title')}>
        {MODE_ALIAS[mode] === UNITY &&
          <Link size="S" onClick={() => setStep(ENTRY_STEPS.SIGNUP)} data-test="button_signup">
            {translations.t('form_entry.signup.title')}
          </Link>
        }
      </Header>

      <Form onSubmit={onSubmit} isInner={false}>
        <Field isError={isError}>
          <InputField
            placeholder={translations.t('form_entry.email.placeholder')}
            type="email"
            name="username"
            value={fields.username.value}
            hasError={fields.username.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              regexp: RegExp(EMAIL_REGEXP),
              errorValid: translations.t('form_entry.email.notvalid'),
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            autoComplete="username"
            required>
            Email
          </InputField>
        </Field>

        <Field isError={isError}>
          <InputField
            placeholder={translations.t('form_entry.password.placeholder')}
            type="password"
            name="password"
            value={fields.password.value}
            hasError={fields.password.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            autoComplete="current-password"
            isEye
            required>
            {translations.t('form_entry.password.title')}
          </InputField>
        </Field>

        {isError && <Error>{error.message}</Error>}

        <ButtonAction type="submit" loading={isProcessing} disabled={!isValid} data-test="login_submit" useButtonLoader>
          {translations.t('form_entry.login.action')}
        </ButtonAction>
      </Form>

      <Social title={translations.t('form_entry.login.actionSocial')}
              onVK={onVK}
              onGoogle={onGoogle}
              country={country}
              locale={locale}
              mode={mode}
              type="login"/>

      <ForgotPass>
        <Link fontSize="inherit" onClick={() => setStep(ENTRY_STEPS.RECOVERY_PASSWORD)} data-test="button_forgot-pass">
          {translations.t('form_entry.askForgot')}
        </Link>
      </ForgotPass>

      <Description>
        {translations.t('form_entry.recaptcha.title')}&nbsp;
        <UrlButton mode={mode} href="https://policies.google.com/privacy" target="_blank" tag="a">
          <Link fontSize="inherit">{translations.t('form_entry.recaptcha.policy')}</Link>
        </UrlButton>
        &nbsp;{translations.t('form_entry.recaptcha.and')}&nbsp;
        <UrlButton mode={mode} href="https://policies.google.com/terms" target="_blank" tag="a">
          <Link fontSize="inherit">{translations.t('form_entry.recaptcha.terms')}.</Link>
        </UrlButton>
      </Description>
    </>
  );
};

const ForgotPass = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
`;
