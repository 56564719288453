import { Colors } from 'planoplan-ui-kit/core/colors';
import { rgba } from 'polished';

export const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: rgba(Colors.whisper, 0.1),
  opacity_hover: '1',
};

export const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};
