import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Quantity } from '../../Quantity';
import { CART } from '../../../constants';
import { BackButton } from '../../BackButton';
import { tablet_landscape, laptop } from '../../../modules/breakpoints';
import { getViewPrice } from '../../../modules/helpers';

export const Header = ({ data, addToCart, addCountGood, removeCountGood, inCart, setScreen }) => {
  const { store_id, view_name, view_count, view_price, view_currency } = data;

  return (
    <View>
      <ButtonBack>
        <BackButton onClick={() => setScreen()} data-test="button-back" />
      </ButtonBack>
      <Title data-test="header__title">{view_name}</Title>

      <Quanty data-test="header__quantity">
        <Quantity count={view_count} add={() => addCountGood(store_id)} remove={() => removeCountGood(store_id)} />
      </Quanty>
      <Price data-test="header__price">{view_price > 0 && getViewPrice(view_currency, view_price)}</Price>
      <CardButton>
        {inCart(store_id) ? (
          <Button theme="secondary" size="S" onClick={() => setScreen(CART)} data-test="header__button-go-over">
            {translations.t('store.common.go_over')}
          </Button>
        ) : (
          <Button theme="primary" size="S" onClick={() => addToCart(store_id)} data-test="header__button-in-cart">
            {translations.t('store.common.in_cart')}
          </Button>
        )}
      </CardButton>
    </View>
  );
};

const View = styled.div`
  ${Grid.suffix(2, 32)};
  ${Grid.prefix(2, 32)};
  ${Grid.row()};
  color: ${Colors.coal};
  align-items: center;
  height: 43px;
  background-color: ${Colors.white};
  position: sticky;
  top: 0;

  @media ${tablet_landscape} {
    ${Grid.suffix(1, 32)};
    ${Grid.prefix(2.5, 32)};
    height: 72px;
  }

  @media ${laptop} {
    ${Grid.suffix(1, 24)};
    ${Grid.prefix(2, 24)};
    border-top-right-radius: 10px;
  }
`;

const ButtonBack = styled.div`
  display: none;

  @media ${tablet_landscape} {
    display: flex;
    position: absolute;
    left: 25px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  line-height: 19px;

  @media ${tablet_landscape} {
    ${Grid.column(12, 21)};
    font-size: 18px;
    line-height: 21px;
    padding-right: 10px;
  }
`;

const Quanty = styled.div`
  display: none;

  @media ${tablet_landscape} {
    display: block;
    ${Grid.column(2, 21)};
    ${Grid.post(0.5, 21)};
  }
`;

const Price = styled.div`
  ${Grid.column(2, 21)};
  ${Grid.post(0.5, 21)};
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  display: none;

  @media ${tablet_landscape} {
    display: block;
  }
`;

const CardButton = styled.div`
  ${Grid.column(4, 21)};
  display: none;

  @media ${tablet_landscape} {
    display: block;
  }
`;
