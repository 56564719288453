import React from 'react';
import styled from 'styled-components';
import { IErrorCircle } from 'planoplan-ui-kit/core/icons/i-error-circle';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Message = ({ children }) => (
  <View>
    <Text>{children}</Text>
    <IErrorCircle width="228px" height="228px" stroke={Colors.colar} />
  </View>
);

const View = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;

  svg {
    margin-top: 72px;
  }
`;

const Text = styled.div`
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.light_gray};
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
`;
