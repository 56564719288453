import React from 'react';

export const ICardIdeal = ({ width = 28, height = 24, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15899_61160)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.574707 0.00146484V23.9883H14.559C23.7839 23.9883 27.7839 18.8225 27.7839 11.9713C27.7839 5.1477 23.7839 0.00146484 14.559 0.00146484H0.574707ZM2.08545 1.51367H14.5602C23.0271 1.51367 26.273 6.13793 26.273 11.9701C26.273 18.9642 21.7405 22.4737 14.5602 22.4737H2.08545V1.51367ZM3.29463 2.72285V21.2645H14.5602C21.3615 21.2645 25.0625 18.0947 25.0625 11.9701C25.0625 5.67498 21.0507 2.72285 14.5602 2.72285H3.29463ZM4.80664 13.334H8.4132V19.7523H4.80664V13.334ZM8.84981 10.0997C8.84981 11.3376 7.84634 12.341 6.6085 12.341C5.37066 12.341 4.36719 11.3376 4.36719 10.0997C4.36719 8.86187 5.37066 7.8584 6.6085 7.8584C7.84634 7.8584 8.84981 8.86187 8.84981 10.0997ZM15.9754 12.1873V11.1486L14.4449 11.1473V10.5768H15.8154V9.53813H14.4449V9.05551H15.8941V8.01682H13.4062V12.1873H15.9754ZM17.6829 8.01551L16.4252 12.1886H17.5059L17.742 11.3821H19.1006L19.3367 12.1886H20.4187L19.161 8.01551H17.6829ZM18.7977 10.3434H18.0462L18.3898 9.16436H18.4528L18.7977 10.3434ZM21.9806 8.01551H20.9406V12.1886H23.5479C23.4665 16.6883 21.1662 19.7558 14.5603 19.7558H10.2285V12.1886H10.8698C12.0567 12.1886 12.7649 11.4031 12.7649 10.0863C12.7649 8.81027 12.0384 8.01814 10.8698 8.01814H10.2285V4.23584H14.5616C18.6259 4.23584 23.0967 5.46469 23.519 11.1486H21.9806V8.01551ZM10.2285 11.1486V9.05682H10.8698C11.2685 9.05682 11.7262 9.17486 11.7262 10.0863C11.7262 11.0201 11.3144 11.1486 10.8698 11.1486H10.2285Z" fill="#D1D1D1"/>
      </g>
      <defs>
        <clipPath id="clip0_15899_61160">
          <rect width="27.2131" height="24" fill="white" transform="translate(0.573242)"/>
        </clipPath>
      </defs>
    </svg>
  );
};
