const pushView = (locale, page, title, additional = {}) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: 'Pageview',
    pagePath: `/#${locale}/${page}`,
    pageTitle: `${title} (standalone)`,
    ...additional,
  });
};
const pushEvent = (event, projectId) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push ({
    'event': 'ga4_Event',
    'evName': 'project_event',
    'evParam1': event,
    'evParam2': projectId
  });
};
const pushEcommerce = (event = '', items = [], additional = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: items,
      ...additional,
    }
  });
};

export const Analytics = {
  openGallery: (locale, projectId, projectName, countRenders) => {
    pushView(locale, `projects/${projectId}/gallery/`, `Галерея «${projectName}» - Мои проекты`, {
      v_render_count: countRenders
    });
  },
  onImproveRender: (projectId) => {
    pushEvent('gallery_4Kquality', projectId);
  },
  createRender: (projectId) => {
    pushEvent('gallery_create_render', projectId);
  },
  onShare: (projectId) => {
    pushEvent('gallery_share_render', projectId);
  },
  onEdit: (projectId) => {
    pushEvent('gallery_edit_render', projectId);
  },
  onRemove: (projectId) => {
    pushEvent('gallery_delete_render', projectId);
  },
  onDownload: (projectId) => {
    pushEvent('gallery_export', projectId);
  },
  showModalImprove: ({ locale, projectId, project_name, imageId, countRender, price }) => {
    pushView(locale, `projects/${projectId}/gallery/4Kquality-${imageId}//`, `Улучшить изображение ${imageId} - Галерея «${project_name}» - Мои проекты`, {
      v_render_count: countRender,
    });
    pushEcommerce('view_item', [{
      item_name: '50 4K Quality+',
      price: price,
      item_category: 'render',
      item_list_name: 'gallery',
      quantity: 1
    }]);
  },
  onRenderPreview: (projectId, price, countPack) => {
    pushEvent('gallery_4Kquality_improve', projectId);

    if (!countPack) {
      pushEcommerce('add_to_cart', [{
        item_name: '50 4K Quality+',
        price: price,
        item_category: 'render',
        item_list_name: 'gallery',
        quantity: 1
      }]);
    }
  },
  onCloseRenderPreview: (projectId) => {
    pushEvent('gallery_4Kquality_close', projectId);
  }
};
