import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { ON_BINDING_CARD_SUCCESS } from '@observer/constants';
import * as effects from './effects';
import { StoreContext } from '../../modules/Store/Store';
import { tablet_landscape } from '../../modules/breakpoints';
import { Message } from '../../components/Message';

const observer = new window.POPObserver();

export const Binding = () => {
  const [state, dispatch] = useContext(StoreContext);
  const { profile, settings } = state;
  const [status, setStatus] = useState('');
  let cards = [];

  const params = {
    country: profile.country.IsoCode,
    email: profile.email,
    team_id: settings.team_id,
  };

  // count - кол-во вызовов, time = интервал вызова
  const runCheckBindingCard = async (count = 120, time = 5) => {
    if (count <= 0) {
      setStatus('timeout');

      return;
    }

    await new Promise((resolve) => setTimeout(resolve, time * 1000));

    const cardsReq = await getBindingCard();

    if (cardsReq) {
      if (cardsReq.length > cards.length) {
        setStatus('success');
        observer.postMessage(ON_BINDING_CARD_SUCCESS);
      } else {
        await runCheckBindingCard(count - 1, count >= 60 ? 5: 10)
      }
    }
  };
  const getBindingData = async () => {
    const response = await effects.getBindingData(dispatch, state, { ...params });

    if (response.success) {
      setStatus('pending');
      await runCheckBindingCard();
    } else {
      setStatus('error');
    }
  };
  const getBindingCard = async () => {
    const response = await effects.getBindingCard(dispatch, state);

    if (response.success) {
      return response.data.items;
    } else {
      setStatus('error');

      return null;
    }
  };

  useEffect(() => {
    (async () => {
      const bindingCards = await getBindingCard();

      if (bindingCards) {
        cards = bindingCards;
      }

      await getBindingData();
    })();
  }, []);

  return (
    <View>
      {status === 'success' && (
        <Message type="SUCCESS">
          <div
            dangerouslySetInnerHTML={{
              __html: translations.t('store.binding.success'),
            }}
          />
        </Message>
      )}

      {status === 'error' && (
        <Message type="WRONG">
          <span dangerouslySetInnerHTML={{ __html: translations.t('store.binding.error') }} />
        </Message>
      )}

      {status === 'pending' && (
        <Message type="WARNING">
          <span dangerouslySetInnerHTML={{ __html: translations.t('store.binding.pending') }} />
          <Text dangerouslySetInnerHTML={{ __html: translations.t('store.binding.pending.text') }} />
        </Message>
      )}

      {status === 'timeout' && (
        <Message type="WRONG">
          <span dangerouslySetInnerHTML={{ __html: translations.t('store.binding.timeout') }} />
          <Text dangerouslySetInnerHTML={{ __html: translations.t('store.binding.timeout.text') }} />
        </Message>
      )}
    </View>
  );
};

const View = styled.div`
  height: 100%;
  
  @media ${tablet_landscape} {
    ${Grid.row()};
  }
`;
const Text = styled.p` 
  color: ${Colors.coal};
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
  font-weight: 400;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;
