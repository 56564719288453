import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { ButtonLoader } from 'planoplan-ui-kit/core/buttonLoader';
import { Button } from 'planoplan-ui-kit/core/button';
import { tablet_landscape } from '../../modules/breakpoints';

export const ButtonAction = ({ useButtonLoader = false, theme = 'primary', top = 'auto', children, ...restProps }) => (
  <MediaQuery query={tablet_landscape}>
    {(matches) => (
      <View top={top}>
        {useButtonLoader ? (
          <ButtonLoader type="submit" theme={theme} size={matches ? 'M' : 'S'} {...restProps}>
            {children}
          </ButtonLoader>
        ) : (
          <Button theme={theme} size={matches ? 'M' : 'S'} {...restProps}>
            {children}
          </Button>
        )}
      </View>
    )}
  </MediaQuery>
);

const View = styled.div`
  margin-top: ${(p) => p.top};
`;
