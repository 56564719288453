import React, { useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { actions } from '@globalInvoke';
import { PAUSE_TRACK, RESUME_TRACK } from '@globalConstants';
import { StripeForm } from './organism/form';
import { Elements } from '@stripe/react-stripe-js';
import { StoreContext } from '../../modules/Store';

export const Stripe = ({clientSecret, publicKey, orderid}) => {
  const [state] = useContext(StoreContext);
  const {settings: {locale}} = state;
  const stripePromise = loadStripe(publicKey, {
    locale: locale
  });

  useEffect(() => {
    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
    }
  }, [])

  return (
      <Elements stripe={stripePromise}>
          <StripeForm clientSecret={clientSecret} orderid={orderid}/>
      </Elements>
  );
};
