import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { POINTER_OFF_INTERFACE, POINTER_ON_INTERFACE } from '@globalConstants';
import { interfaces } from '@globalInvoke';
import { App } from './app';
import { createAppStore, initialState } from './modules/Store';
import { ApiCall } from './modules/api-call';
import ErrorBoundary from './ErrorBoundary';
import { ON_GALLERY_SHOW, ON_CLOSE_TOUR_EDITOR } from "@observer/constants";

const observer = new window.POPObserver();

export default class UITourEditor {
  /**
   * Инициализация модуля
   * @param {object} settings - объект настроек
   * @public
   */
  init(settings) {
    this.settings = settings;
    this.root = settings.root || console.error('Для подключения галереи не указан html элемент');

    this._subscribes();
  }

  /**
   * Открытие модуля
   * @param {object} params - объект с параметрами, вызывается как со страницы сайта, так из интерфейса юнити
   */
  open(params = {}) {
    this.locale = this.settings.locale || 'en';
    this.zoom = this.settings.zoom || 1;
    this.team_id = params.team_id || '';
    this.projectId = params.projectId || '';
    this.tourId = params.id || '';
    this.structure = params.structure || {};

    ApiCall.setSettings({ locale: this.locale, team_id: this.team_id }); // ui доступен только для ru и en языках

    this._mount();
    this._editorInvoke();
  }

  /**
   * Создание модуля в дом-дереве
   * @private
   */
  _mount() {
    const rootElement = document.getElementById(this.root);
    const initialState = this._createInitialState();
    const store = createAppStore(initialState);

    this.store = store;

    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Provider>,
      rootElement
    );
  }

  /**
   * Инициализация стора
   * @private
   */
  _createInitialState() {
    return {
      ...initialState,
      structure: this.structure,
      settings: {
        root: this.root,
        locale: this.locale,
        projectId: `${this.projectId}`,
        tourId: `${this.tourId}`,
        team_id: this.team_id,
        close: this.close.bind(this),
        zoom: this.zoom,
      },
    };
  }

  /**
   * Триггерит события для юнити при загрузке модуля
   * @private
   */
  _editorInvoke() {
    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });
  }

  /**
   * Удаление модуля из дом-дерева, и триггер событий для юнити
   * @param mode {string} - режимы закрытия
   */
  close(mode = '') {
    if (!window.cabinetIsOpen && !window.galleryIsOpen) {
      window.invokeEditorAction({
        name: interfaces[POINTER_ON_INTERFACE].name,
        value: interfaces[POINTER_ON_INTERFACE].value,
      });
    }

    observer.postMessage(ON_GALLERY_SHOW);
    observer.postMessage(ON_CLOSE_TOUR_EDITOR);

    const rootElement = document.getElementById(this.root);

    ReactDOM.unmountComponentAtNode(rootElement);
  }

  _subscribes() {}
}

if (!window.UITourEditor) {
  window.UITourEditor = UITourEditor;
}
