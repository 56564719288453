import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { StatusLayout } from '../layouts/status';
import { ON_CHANGE_PAYMENT_STATUS_STORE } from '@observer/constants';
import { translations } from '@libs';
import { mappedButtons } from '@features/store/modules/invoke';
import { PAYMENT_SUCCESSFUL } from '@features/store/constants';
import { Analytics } from '../modules/analytics';
import { IconSuccess } from '@icons';

const observer = new window.POPObserver();

export const SuccessScreen = memo(() => {
  const dispatch = useDispatch();
  const { goods: { isTariff }, settings: { onClose }} = useSelector(state => state);

  useEffect(() => {
    window.invokeEditorAction({
      name: mappedButtons[PAYMENT_SUCCESSFUL].name,
      value: mappedButtons[PAYMENT_SUCCESSFUL].value,
    });

    observer.postMessage(ON_CHANGE_PAYMENT_STATUS_STORE, 'succeeded');

    dispatch(Analytics.successPay());

    if (!isTariff) {
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  }, []);

  return (
    <StatusLayout title={translations.t('quickstore.pay.success.title')}
                  text={translations.t('quickstore.pay.success.text')}>
      <IconSuccess />
    </StatusLayout>
  );
});
