import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Packages } from './Packages';
import { Renders } from './Renders';
import { Widget } from './Widget';
import { Cart } from './Cart';
import { Pricing } from './Pricing';
import { Yookassa } from './Yookassa';
import { Stripe } from './Stripe';
import { Paypal } from './Paypal';
import { Binding } from './Binding';
import { SuccessBinding } from './SuccessBinding';
import { Require } from './Require';
import { Message } from '../components/Message';
import { Loader } from '../components/Loader';
import { StoreContext } from '../modules/Store/Store';
import { PACKAGES, RENDERS, WIDGET, CART, PRICING, DETAIL, YOOKASSA, STRIPE, PAYPAL, BINDING, SUCCESS_BINDING, REQUIRE } from '../constants';
import { tablet_landscape, laptop } from '../modules/breakpoints';
import { CartButton, CartButtonView } from '../components/CartButton';
import * as effects from '../modules/effects';
import { BackButton, BackButtonView } from '../components/BackButton';

const rail_y = {
  width: '15px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '11px',
};

export const Index = () => {
  const [state, dispatch] = useContext(StoreContext);
  const ref = useRef(null);
  const { products, screen, cart, pricing, profileGoods, payment, loading, productScreen, settings, descriptionTariffs, descriptionStore } = state;
  const { processing, error, firstLoading } = loading;
  const { team_id, zoom } = settings;
  const isShowScreen = (!processing || !firstLoading) && !error;
  const setScreen = (screen) => effects.setScreen(dispatch, state, screen);
  const onScrollY = (target) => effects.setScrollY(dispatch, target.scrollTop);

  useEffect(() => {
    const scrollbar = ref.current.querySelector('.scrollbar-container');
    if(scrollbar) {
      scrollbar.scrollTo({
        top: 0,
      });
    }
  }, [screen]);

  return (
    <View ref={ref} id="scroll-container">
      {processing && !error && <Loader overlay={!firstLoading} />}
      {error && <Message type={error.type}>{error.render}</Message>}
      {isShowScreen && (
        <>
          <ButtonWrapper>
            {productScreen.screen === DETAIL && <BackButton onClick={() => setScreen(productScreen.screenBack)} />}
            <CartButton screen={screen} setScreen={setScreen} />
          </ButtonWrapper>
          <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y} onScrollY={onScrollY}>
            {
              {
                // [REVIEW]: <Review />,
                [PACKAGES]: <Packages products={products} descriptionStore={descriptionStore} />,
                // [PANORAMAS]: <Panoramas products={products} />,
                [PRICING]: <Pricing pricing={pricing} profileGoods={profileGoods} team_id={team_id} descriptionTariffs={descriptionTariffs} />,
                [RENDERS]: <Renders products={products} descriptionStore={descriptionStore} />,
                [WIDGET]: <Widget products={products} pricing={pricing} team_id={team_id} profileGoods={profileGoods} descriptionStore={descriptionStore} />,
                [CART]: <Cart cart={cart} payment={payment} />,
                [YOOKASSA]: <Yookassa token={payment.token} returnUrl={payment.returnUrl} method={payment.method} orderid={payment.orderid} scaleFactor={zoom} />,
                [STRIPE]: <Stripe publicKey={payment.publicKey} clientSecret={payment.clientSecret} orderid={payment.orderid}>Stripe</Stripe>,
                [PAYPAL]: <Paypal />,
                [BINDING]: <Binding/>, // Добавление карты
                [SUCCESS_BINDING] : <SuccessBinding />,
                [REQUIRE]: <Require publicKey={payment.publicKey} clientSecret={payment.clientSecret} paymentMethod={payment.paymentMethod}/>,
              }[screen]
            }
          </ScrollBar>
        </>
      )}
    </View>
  );
};

const View = styled.div`
  background-color: ${Colors.mint};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  height: 80vh;

  .ps__rail-y {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media ${tablet_landscape} {
    height: 652px;
  }

  @media ${laptop} {
    ${Grid.column(24, 30)};
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 10px;
  overflow: hidden;

  ${CartButtonView} {
    margin-left: auto;
  }

  @media ${tablet_landscape} {
    ${BackButtonView} {
      display: none;
    }
  }

  @media ${laptop} {
    display: none;
  }
`;
