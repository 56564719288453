import { API } from './api';

export const UIInvokeFunction = (name, payload) => {
  if (!window.location.host.indexOf('planoplan.com') !== -1 && process.env.NODE_ENV !== 'test') {
    console.info('%cUI INVOKE MESSAGE', 'color: #8500ff', { [name]: payload });
  }

  try {
    return API.dispatchAction(name, payload);
  } catch (error) {
    return error;
  }
};
