import { TRY_AGAIN_UPDATE, ENTRY_SUCCESS } from '../constants';

export const events = {
  [TRY_AGAIN_UPDATE]: {
    name: 'tryAgainUpdate',
    payload: {},
  },
  [ENTRY_SUCCESS]: {
    name: 'ScreenEntrySuccess',
    payload: {},
  },
};
