import React from 'react';

export const ICardYoomoney = ({ width = 90, height = 20, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 90 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15899_61033)">
        <path d="M79.07 10.0576C79.07 7.60449 77.2138 5.62012 74.5919 5.62012C72.0219 5.62012 70.1919 7.52824 70.1919 10.1501C70.1919 12.7851 72.0738 14.7064 74.7738 14.7064C76.4869 14.7064 77.9275 13.9401 78.8232 12.3826L77.0063 11.5389C76.5781 12.3176 75.6431 12.8239 74.7738 12.8239C73.6706 12.8239 72.3988 11.9932 72.3206 10.8382H79.005C79.0476 10.5802 79.0694 10.3191 79.07 10.0576ZM72.3594 9.30762C72.5156 8.13949 73.32 7.37387 74.605 7.37387C75.8769 7.37387 76.6556 8.12387 76.7594 9.30762H72.3594Z" fill="#9E9E9E"/>
        <path d="M54.0492 5.61816C51.4142 5.61816 49.5454 7.51379 49.5454 10.1875C49.5454 12.835 51.4142 14.7044 54.0754 14.7044C56.7492 14.7044 58.6179 12.835 58.6179 10.2263C58.6179 7.53941 56.6973 5.61816 54.0492 5.61816ZM54.0754 12.7182C52.751 12.7182 51.9335 11.7319 51.9335 10.2C51.9335 8.64254 52.7642 7.57816 54.0754 7.57816C55.3992 7.57816 56.2298 8.62941 56.2298 10.2C56.2298 11.745 55.4123 12.7182 54.0754 12.7182Z" fill="#9E9E9E"/>
        <path d="M44.667 5.62076C43.5876 5.62076 42.7745 5.98951 41.9613 6.90388H41.6945C41.1482 6.07826 40.257 5.62076 39.257 5.62076C38.7577 5.61587 38.2644 5.72966 37.8177 5.95274C37.371 6.17582 36.9836 6.50181 36.6876 6.90388H36.4207L36.4076 6.89138V5.79888H34.1738V14.5139H36.5876V10.0289C36.5876 8.58076 37.1976 7.72951 38.252 7.72951C39.2301 7.72951 39.8651 8.45388 39.8651 9.55888V14.5139H42.2913V10.0289C42.2913 8.59138 42.9013 7.72951 43.9688 7.72951C44.9338 7.72951 45.5563 8.45388 45.5563 9.57201V14.5139H47.9832V9.27951C47.9832 7.00576 46.7632 5.62076 44.667 5.62076Z" fill="#9E9E9E"/>
        <path d="M84.4373 11.4913H84.2648C84.2648 11.3362 83.2123 8.57625 82.5223 6.86625L82.091 5.79688H79.6592L83.1092 14.5425L81.7498 17.875H84.1648L89.1329 5.8H86.6998L86.3029 6.71438L84.9573 10.0437C84.5779 10.9737 84.4223 11.405 84.4373 11.4913Z" fill="#9E9E9E"/>
        <path d="M65.3123 5.62061C64.2323 5.62061 63.3048 6.09061 62.6823 6.92936H62.428L62.403 6.91686V5.79873H60.168V14.5137H62.5817V10.0544C62.5817 8.63186 63.2692 7.74186 64.3986 7.74186C65.453 7.74186 66.228 8.52936 66.228 9.77436V14.5131H68.6417V9.25436C68.6417 7.11998 67.2442 5.62061 65.3123 5.62061Z" fill="#9E9E9E"/>
        <path d="M17.7806 0.625C12.5656 0.625 8.375 4.84375 8.375 10C8.375 15.1987 12.6069 19.375 17.7794 19.375C22.9519 19.375 27.1875 15.1562 27.1875 10C27.1875 4.84375 22.9531 0.625 17.7806 0.625ZM17.7806 13.4944C15.8569 13.4944 14.2756 11.9175 14.2756 10C14.2756 8.0825 15.8569 6.50562 17.7806 6.50562C19.7044 6.50562 21.2862 8.0825 21.2862 10C21.2431 11.9175 19.7044 13.4944 17.7806 13.4944Z" fill="#D1D1D1"/>
        <path d="M8.37508 3.34863V16.9849H5.04258L0.767578 3.34863H8.37508Z" fill="#D1D1D1"/>
      </g>
      <defs>
        <clipPath id="clip0_15899_61033">
          <rect width="90" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};
