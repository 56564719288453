import { SET_PROJECTS_ITEMS, TARIFF_ALIAS } from '../../constants';
import { ApiCall } from '../../modules/api-call';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import { ON_OPEN_QUICK_STORE } from '@observer/constants';

const observer = new window.POPObserver();

export const setProjectFolderName = (id, value) => (dispatch, getState) => {
  const { projects: { items }} = getState(state => state);
  const current = items.find(project => project.id === id);

  current.name = value;

  dispatch({ type: SET_PROJECTS_ITEMS, payload: [...items] });
};

export const saveProjectName = (id, value) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.setProjectName({ id, name: value });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.renameProject.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.renameProject.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.renameProject.error'));
  }
};


export const saveFolderName = (id, value) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.setFolderName({ folder_id: id, name: value });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.renameFolder.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.renameFolder.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.renameFolder.error'));
  }
};

/**
 * Прикрепить Проект / Папку
 * @param {string} id - Индификатор сущности
 * @param {string} type - тип сущности [project, folder]
 * @param {number} pin - 1 - закрепить. 0 - открепить
 */
export const onPin = (id = '', type = 'project', pin = 1) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.onPin({ id, type, pin });

    if (response.success) {
      if (pin) {
        notifications.showSuccess(translations.t('cabinet.notification.pin.success'));
      } else {
        notifications.showSuccess(translations.t('cabinet.notification.unpin.success'));
      }
    } else {
      throw new Error('error');
    }
  } catch (error) {
    if (pin) {
      notifications.showError(translations.t('cabinet.notification.pin.error'));
    } else {
      notifications.showError(translations.t('cabinet.notification.unpin.error'));
    }
  }
};

export const onDelete = (ids = []) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.onDelete({ id: ids });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.delete.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.delete.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.delete.error'));
  }
};

export const onDuplicate = (ids = [], withContent = false) => async (dispatch, getState) => {
  const { projects: { currentFolder }, team } = getState();

  try {
    const paramsWithContent = {
      /*content_plan: 1,
      content_top: 1,
      content_three_quarter: 1,
      content_video: 1,
      content_tour: 1,
      content_qrcode: 1,
      content_render: 1,
      widget_settings: 1,
      project_properties: 1,*/
      content_all: 1,
    };
    let params = {
      id: ids,
      folder_id: currentFolder ? currentFolder.id : 0,
      team_id: team.id,
      content_panorama: 1,
      content_bg: 1,
      widget_popgo: 1,
    };

    if (withContent) {
      params = {
        ...params,
        ...paramsWithContent,
      }
    }

    const response = await ApiCall.onDuplicate(params);

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.duplicate.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.duplicate.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.duplicate.error'));
  }
};

export const onMove = (ids = [], folderId = 0) => async (dispatch, getState) => {
  const { team } = getState();

  try {
    const response = await ApiCall.onMove({
      id: ids,
      folder_id: folderId,
      team_id: team.id
    });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.move.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.move.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.move.error'));
  }
};

export const onMoveToTeam = (ids = [], teamId = 0) => async (dispatch, getState) => {
  const { projects: { currentFolder }} = getState();

  try {
    const response = await ApiCall.onMove({
      id: ids,
      folder_id: currentFolder ? currentFolder.id : 0,
      team_id: teamId
    });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.move.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.move.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.move.error'));
  }
};

export const onCreateFolder = ({ name }) => async (dispatch, getState) => {
  const { team, projects: { currentFolder }} = getState();

  try {
    const response = await ApiCall.onCreateFolder({
      team_id: team ? team.id : 0,
      parent_id: currentFolder ? currentFolder.id : 0,
      name,
    });

    if (response.success) {
      notifications.showSuccess(translations.t('cabinet.notification.createFolder.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.createFolder.error'));
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.createFolder.error'));
  }
};

export const onCreateProject = (license = null) => async (dispatch, getState) => {
  const { team, projects: { currentFolder }, settings: { app_id, teamId, projectId }} = getState();

  try {
    const { data } = await ApiCall.onCreateProject({
      team_id: team ? team.id : 0,
      folder_id: currentFolder ? currentFolder.id : 0,
      license,
      app_id,
    });

    if (data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.createProject.success'));

      return data.data;
    } else if (!data.success && data.errorText === 'Invalid license') {
      notifications.showError(translations.t('form_entry.team.noLicense'));

      return {};
    } else {
      notifications.showError(translations.t('cabinet.notification.createProject.error'));

      observer.postMessage(ON_OPEN_QUICK_STORE, {
        tariffAlias: TARIFF_ALIAS.PROPLUS,
        bannerAlias: 'upgradeStartNewProject',
        team_id: teamId,
        project_id: projectId,
      });

      return {};
    }
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.createProject.error'));

    return {};
  }
};

export const onSelect = (id, type = '', value = true) => async (dispatch, getState) => {
  const { projects: { items }} = getState(state => state);
  const current = items.find(item => item.id === id && item.row_type === type);

  if (!current) {
    return;
  }

  current.selected = value;

  dispatch({ type: SET_PROJECTS_ITEMS, payload: [...items] });
};

export const onAllSelected = (value = true) => async (dispatch, getState) => {
  const { projects: { items }} = getState(state => state);
  const newItems = items.map(item => ({
    ...item,
    selected: value
  }));

  dispatch({ type: SET_PROJECTS_ITEMS, payload: newItems });
};
