import React, { useEffect } from 'react';
import styled from 'styled-components';

export const Panorama = ({ krpano_url }) => {
  // https://krpano.com/examples/119/examples/javascript-interface/js-api-examples/index.html
  const init = () => {
    window.embedpano({
      swf: '/etc/krpano/krpano.swf',
      xml: krpano_url,
      target: 'pano',
      onready: (krpano_interface) => (window.krpano_interface = krpano_interface),
      onerror: (error) => console.error(error),
    });

    window.krpano_interface.set('autorotate.accel', 1000);
    window.krpano_interface.call('autorotate.start()');

    window.krpano_interface.onclick = () => {
      window.krpano_interface.call('autorotate.stop');
    };

    window.krpano_interface.ontouchstart = () => {
      window.krpano_interface.call('autorotate.stop');
    };
  };

  useEffect(() => {
    if (!window.embedpano) {
      const script = document.createElement('script');

      script.src = '/etc/krpano/krpano.js';
      script.async = true;
      script.onload = () => init();

      document.body.appendChild(script);
    } else {
      init();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (window.krpano_interface) {
      window.krpano_interface.call('loadpano(' + krpano_url + ', null, MERGE, BLEND(0.5));');
      window.krpano_interface.call('autorotate.start()');
    }
  }, [krpano_url]);

  return <View id="pano"/>;
};

const View = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
