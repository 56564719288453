import React, { memo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Information } from '../components/Information';
import { Shop } from '../components/Shop';

export const MainScreen = memo(() => {
  return (
    <View>
      <Information />
      <Shop />
    </View>
  );
});

const View = styled.div`
  display: flex;
`;
