
const pushView = (locale, page, title) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: 'Pageview',
    pagePath: `/#${locale}/${page}`,
    pageTitle: `${title} (standalone)`,
  });
};

const pushEventGA4 = (evName, evParam1, evParam2, evParam3, evParam4) => {
  window.dataLayer = window.dataLayer || [];

  const params = {
    'event': 'ga4_Event',
    'evName': evName,
    'evParam1': evParam1,
    'evParam2': evParam2,
    'evParam3': evParam3,
    'evParam4': evParam4
  };

  for (let i = 1; i <= 4; i++) {
    if (typeof params[`evParam${i}`] === 'undefined') {
      delete params[`evParam${i}`];
    }
  }

  window.dataLayer.push(params);
};

export const Analytics = {
  project: {},
  setSettings: function ({ project = {} }) {
    this.project = project;
  },
  viewProjects: function (locale) {
    const { id, name } = this.project;

    pushView(locale, `projects/${id}/create-design/`, `Создать проект «${name}» - Мои проекты`);
  },
  createProject: function (alias) {
    const { id } = this.project;

    pushEventGA4('popup-create-project', id, alias);
  },
  openLink: function (title) {
    const { id } = this.project;

    pushEventGA4('popup-create-project', id, title);
  },
  openProject: function (nameProject) {
    const { id } = this.project;

    pushEventGA4('popup-create-project', id, nameProject);
  },
  close: function () {
    const { id } = this.project;

    pushEventGA4('popup-create-project', id, 'close');
  }
};
