import { createBreakpoints } from '@libs';

const points = [
  {
    mobile: 0,
  },
  {
    tablet_portrait: 600,
  },
  {
    tablet_landscape: 1024,
  },
  {
    laptop: 1280,
  },
  {
    laptop_wide: 1440,
  },
  {
    full_hd: 1600,
  },
  {
    full_hd_second: 1690,
  },
];

export const {
  mobile_only,
  tablet_portrait,
  tablet_portrait_only,
  tablet_landscape,
  laptop,
  laptop_blank,
  laptop_wide,
  laptop_wide_blank,
  full_hd,
  full_hd_second,
  full_hd_second_blank,
} = createBreakpoints(points);
