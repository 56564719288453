import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import * as projectEffects from '../../modules/effects';
import { EmptyMessage } from './emptyMessage';
import { UploadImage } from './upload-image';
import { IconPlus, IconTrash } from '../../icons';
import { Q_PLUS_CONDITION } from '@features/gallery/constants';

const rail_y = {
  width: '10px',
  right: '0',
  background_color_hover: Colors.whisper,
  opacity_hover: '0.6',
};

const thumb_y = {
  background_color: Colors.nobel,
  opacity: '0.8',
  width: '6px',
  right: '2px',
  background_color_hover: Colors.nobel,
  opacity_hover: '1',
  width_hover: '6px',
};

export const Sidebar = () => {
  const dispatch = useDispatch();
  const { design_project, images } = useSelector((state) => state);
  const { sidebar_images, projects, uploadedImages, images: designProjectImages } = design_project;
  const sidebarItems = (projects && projects.length) ? projects : sidebar_images;
  const isEmpty = !Boolean(images.length);
  const hasUploadImages = Boolean(designProjectImages.filter(img => img.isUpload).length) || uploadedImages.length;

  const checkIsQPlus = (item) => {
    const height = item.size && item.size.height;

    if (!height) {
      return false;
    }

    return item.updateAt > (Q_PLUS_CONDITION.time / 1000) && height >= Q_PLUS_CONDITION.height && Boolean(item.editor.task_data);
  };
  const getSize = (item) => {
    if (item.size) {
      return item.size;
    }

    if (!item.resolution) {
      console.error(new Error('Size is undefined'));

      return {
        width: 0,
        height: 0
      }
    }

    const [width, height] = item.resolution.split('_');

    return { width: parseInt(width), height: parseInt(height) };
  };
  const onClickItem = (item, projectId = null, params = {}) => {
    const name = item.roomCaption ? `${item.roomCaption} ${item.roomArea ? `${item.roomArea} кв.м` : ''}` : '';

    dispatch(projectEffects.addDesignProjectImage({
      id: item.id,
      name,
      order: 0,
      original: item.original,
      preview: item.preview,
      size: getSize(item),
      projectId,
      ...params
    }));
  };
  const onRemoveItem = (item) => {
    dispatch(projectEffects.deleteUploadImage(item));
  };

  const getImgSidebar = (item, projectId) => {
    const isPreview = item.isPreview;
    const isQPlus = checkIsQPlus(item);

    return (
      <Item key={item.id}>
        <Inner>
          <img src={item.preview} alt={item.name}/>
          <Overlay onClick={() => onClickItem(item, projectId)}>
            <IconPlus fill={Colors.black} />
          </Overlay>
        </Inner>

        {isPreview && (
          <IconText bgColor={Colors.white}>Preview</IconText>
        )}

        {isQPlus && (
          <IconText bgColor={Colors.dark_cream}>Q+</IconText>
        )}
      </Item>
    )
  };
  const getProjectsSidebar = (project) => {
    return (
      <Project key={`project-${project.id}`}>
        <ProjectName>{project.name}</ProjectName>
        {!Boolean(project.images.length) && (
          <ProjectEmpty dangerouslySetInnerHTML={{ __html: translations.t('gallery.pdf.content.added') }} />
        )}
        {project.images.map(item => getImgSidebar(item, project.id))}
      </Project>
    );
  };
  const getUploadedImages = () => {
    return (
      <Content>
        <Title>{translations.t('gallery.pdf.content.upload')}</Title>

        {(uploadedImages && Boolean(uploadedImages.length)) ? (
          <Items>
            {uploadedImages.map(item => (
              <Item key={item.id}>
                <Inner>
                  <img src={item.preview} alt={item.name}/>
                  <Overlay onClick={() => onClickItem(item, item.projectId, { isUpload: true })}>
                    <IconPlus />
                  </Overlay>
                  <DeleteImg onClick={() => onRemoveItem(item)}>
                    <IconTrash />
                  </DeleteImg>
                </Inner>
              </Item>
            ))}
          </Items>
        ) : (
          <ProjectEmpty dangerouslySetInnerHTML={{ __html: translations.t('gallery.pdf.content.added') }} />
        )}
      </Content>
    );
  };

  return (
    <Container>
      <ScrollBar
        options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }}
        rail_y={rail_y}
        thumb_y={thumb_y}>
        <List>
          {isEmpty && <EmptyMessage/>}

          {sidebarItems &&
            <Content>
              <Title>{translations.t('gallery.pdf.content.available')}</Title>

              {Boolean(sidebarItems.length) &&
                <Items>
                  {sidebarItems.map((item, index) => {
                    if (item.images) {
                      return getProjectsSidebar(item, index);
                    }

                    return getImgSidebar(item);
                  })}
                </Items>
              }

              {!Boolean(sidebarItems.length) && (
                <ProjectEmpty dangerouslySetInnerHTML={{ __html: translations.t('gallery.pdf.content.added') }} />
              )}
            </Content>
          }

          {Boolean(hasUploadImages) && getUploadedImages()}
        </List>
      </ScrollBar>

      <UploadImage/>
    </Container>
  )
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
  width: 260px;
  flex-shrink: 0;
  border-right: 1px solid ${Colors.coal};
`;
const List = styled.div`
  padding: 15px 20px 10px 15px;
`;
const Content = styled.div`
  margin-bottom: 10px;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.light_gray};
  font-weight: 400;
  margin-bottom: 10px;
`;
const Items = styled.div``;
const Overlay = styled.div`
  ${Mixins.transition('opacity')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.dark_cream, 0.8)};
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
const DeleteImg = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  opacity: 0;
  transition: all .2s ease;
`;
const Inner = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;

  &:hover {
    ${Overlay} {
      opacity: 1;
    }
    ${DeleteImg} {
      opacity: 1;
    }
  }
`;

const Item = styled.div`
  width: 225px;
  height: ${225 / 1.777}px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const Project = styled.div``;
const ProjectName = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.nobel};
  margin-bottom: 10px;
`;
const ProjectEmpty = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.coal};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${Colors.coal};
  margin-bottom: 10px;
  text-align: center;
`;
const IconText = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: ${(p) => (p.bgColor)};
  padding: 1px 6px;
  border-radius: 14px;
  font-size: 12px;
  line-height: 14px;
  pointer-events: none;
  color: ${Colors.night};
`;
