import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from '../../components';
import * as effects from '../../modules/effects';

export const Notifications = ({ onOpenNotification }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { notifications, errors, settings } = state;
  const { mode } = settings;

  const onDeleteAll = () => dispatch(effects.clearAllNotification());
  const onSaveLast = (last) => dispatch(effects.setUserSettings(last));

  return (
    <Notification
      notifications={notifications}
      onSave={onSaveLast}
      onDeleteAll={onDeleteAll}
      onClick={onOpenNotification}
      mode={mode}
      errors={errors}
    />
  );
};
