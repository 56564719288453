import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { UrlButton } from '@components';
import { translations } from '@libs';
import { ERROR_TYPES } from '../constants';
import { rail_y, thumb_y } from './scroll';

export const Goods = ({ goods = [], errors = {}, mode = 'site', locale = 'en' }) => {
  const [goodsHeight, setGoodsHeight] = useState(0);
  const viewRef = useRef(null);
  const bottomRef = useRef(null);
  const listRef = useRef(null);

  const calcListHeight = () => {
    if (!viewRef.current || !listRef.current || !bottomRef.current) {
      return;
    }

    const listItems = Array.from(listRef.current.querySelectorAll('li'));
    const listItemsHeight = listItems.reduce((acc, current) => acc + current.offsetHeight, 0);
    const offsetTop = viewRef.current.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const bottomHeight = bottomRef.current.offsetHeight;
    const minHeight = 35;
    // высота элементов списка товаров + отступ сверху + высота нижней кнопки;
    const limit = listItemsHeight + offsetTop + bottomHeight;

    if (limit > windowHeight) {
      const result = windowHeight - offsetTop - bottomHeight;

      if (result > minHeight) {
        setGoodsHeight(result);
      } else {
        setGoodsHeight(minHeight);
      }
    } else {
      setGoodsHeight(0);
    }
  };

  useEffect(() => {
    calcListHeight();

    window.addEventListener('resize', calcListHeight);

    return () => window.removeEventListener('resize', calcListHeight);
  }, [viewRef]);

  useEffect(() => {
    calcListHeight();
  }, [goods]);

  if (errors[ERROR_TYPES.GOODS]) {
    return null;
  }

  return (
    <View ref={viewRef}>
      <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
        <List ref={listRef} height={goodsHeight}>
          {goods.map((item) => (
            <Item key={item.id} isDisabled={item.isDisabled}>
              <Caption>{item.caption}</Caption>
              <Count>{item.count}</Count>
            </Item>
          ))}
        </List>
      </ScrollBar>

      <Bottom ref={bottomRef}>
        <UrlButton mode={mode} locale={locale} href="/cabinet/balance">
          <Button size="S" theme="primary">
            {translations.t('user_widget.balance')}
          </Button>
        </UrlButton>
      </Bottom>
    </View>
  );
};

const View = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 0 34px ${rgba(Colors.coal, 0.13)}, -1px 1px 2px ${rgba(Colors.coal, 0.2)};
  width: 270px;
`;

const List = styled.ul`
  ${Mixins.resetList()};
  height: ${(p) => (p.height ? p.height + 'px' : 'auto')};
  padding-right: 18px;
  padding-left: 18px;
`;

const Item = styled.li`
  padding-top: 18px;
  display: flex;
  justify-content: space-between;
  opacity: ${(p) => (p.isDisabled ? 0.4 : 1)};
  font-size: 14px;
  line-height: 17px;
`;

const Caption = styled.div`
  font-weight: 300;
  padding-right: 10px;
`;

const Count = styled.div``;

const Bottom = styled.div`
  padding: 18px;
`;
