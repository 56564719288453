import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Info = ({ slide = {} }) => {

  return (
    <View>
      <Top>{slide.text}</Top>

      <ImageWrap>
        <img src={slide.image} alt={slide.image}/>
      </ImageWrap>
    </View>
  );
};

const View = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
`;
const Top = styled.div`
  padding: 20px;
  color: ${Colors.light_gray};
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;
const ImageWrap = styled.div`
  flex-grow: 1;
  background: ${Colors.light_gray};
  pointer-events: none;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
