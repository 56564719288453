import React, { useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useDispatch, useSelector } from 'react-redux';
import * as timeago from 'timeago.js';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconDots, IconFolder, IconLocked, IconPin } from '../../../components/icons';
import { ListEdit } from './listEdit';
import { HEADER_MODES, MODE_PROJECT, ROW_TYPE } from '../constants';
import { getCorrectEditList } from '../helpers';
import * as effects from '../effects';
import { onOpenQuickStoreWithProplus } from '../../../modules/effects';
import { Analytics } from '../../../modules/analytics';

export const TrLayout = ({
  item = {},
  editList = [],
  currentHeaderMode = '',
  setFolderId = () => {},
  onOpenProject = () => {},
  openGallery = () => {},
  openLink = () => {},
}) => {
  const dispatch = useDispatch();
  const { settings: { locale }} = useSelector(state => state);
  const [isOpenEditList, setIsOpenEditList] = useState(false);
  const [isChangeName, setIsChangeName] = useState(false);
  const setPinUnpin = (list) => list.filter((listItem) => !((item.pinned_at && listItem.alias === 'pin') || (!item.pinned_at && listItem.alias === 'unpin')));
  const editListCorrect = getCorrectEditList(setPinUnpin(editList), item);
  const isLocked = item.mode === MODE_PROJECT.view;
  const type = item.row_type;
  const isPin = item.pinned_at;
  const selectActive = currentHeaderMode === HEADER_MODES.select;

  const onChangeProjectFolderName = (id, value) => {
    dispatch(effects.setProjectFolderName(id, value));
    setIsChangeName(true);
  };
  const onSaveProjectFolderName = (id, value) => {
    if (isChangeName) {
      if (type === ROW_TYPE.project) {
        dispatch(effects.saveProjectName(id, value));
      } else {
        dispatch(effects.saveFolderName(id, value));
      }

      setIsChangeName(false);
    }
  };

  const onSelected = () => {
    dispatch(effects.onSelect(item.id, type, !Boolean(item.selected)));
  };

  const onClick = () => {
    if (type === ROW_TYPE.project) {
      if(isLocked) {
        dispatch(onOpenQuickStoreWithProplus({ openProject: item.id }));
      } else {
        onOpenProject(item.id);
        Analytics.openProject(item.id);
      }
    }

    if (type === ROW_TYPE.folder) {
      setFolderId(item.id);
    }
  };

  return (
    <View>
      {selectActive && <SelectedOverlay isSelected={item.selected} onClick={onSelected} />}

      <Photo onClick={onClick}>
        {isPin &&
          <Pin>
            <IconPin width={16} height={16} />
          </Pin>
        }
        {type === ROW_TYPE.project && (
          <>{item.thumbnail ? <img src={item.thumbnail} alt={item.name}/> : <FakeImg />}</>
        )}
        {type === ROW_TYPE.folder && <IconFolder width={41} height={38} />}

        {isLocked &&
          <Locked>
            <IconLocked />
          </Locked>
        }
      </Photo>
      <Name value={item.name}
            onChange={(event) => onChangeProjectFolderName(item.id, event.target.value)}
            onBlur={(event) => onSaveProjectFolderName(item.id, event.target.value)}/>
      <Id>ID {item.id}</Id>
      <LastEdit>
        {translations.t('cabinet.projects.edited')} {timeago.format(item.saved * 1000, locale)}
      </LastEdit>
      <Actions>
        {type === ROW_TYPE.project &&
          <>
            {Boolean(item.widget_link) &&
              <Action onClick={() => openLink(item.widget_link)}>{translations.t('cabinet.projects.widget')}</Action>
            }
            {Boolean(item.estimate_link) &&
              <Action onClick={() => openLink(item.estimate_link)}>{translations.t('cabinet.projects.estimate')}</Action>
            }
            <Action isDisabled={!item.has_gallery} onClick={() => openGallery(item.id)}>{translations.t('cabinet.projects.gallery')}</Action>
          </>
        }
      </Actions>
      <Edit>
        <EditIcon isOpen={isOpenEditList} onClick={() => setIsOpenEditList(true)}><IconDots /></EditIcon>

        {isOpenEditList &&
          <ListEdit id={item.id} list={editListCorrect} onClose={() => {setIsOpenEditList(false)}}/>
        }
      </Edit>
    </View>
  )
};

const View = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 13px 20px;
`;
const SelectedOverlay = styled.div`
  border: 2px solid ${({ isSelected }) => isSelected ? Colors.planoplan_blue : 'transparent'};
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.timberwolf25, 0.25)};
  z-index: 3;
  cursor: pointer;
`;
const Photo = styled.div`
  position: relative;
  width: 76px;
  height: 46px;
  margin-right: 30px;
  cursor: pointer;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
  }
`;
const FakeImg = styled.div`
  background-color: ${Colors.feldgrau25};
  width: 100%;
  height: 0;
  padding-top: 56%;
`;
const Pin = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 1;
`;
const Locked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
const Name = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 0;
  margin-right: 30px;
  font-size: 16px;
  line-height: 19px;
  border-bottom: 1px solid transparent;
  
  &:hover {
    border-bottom-color: ${Colors.feldgrau};
  }
`;
const Id = styled.div`
  margin-right: 20px;
  width: 120px;
  flex-shrink: 0;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const LastEdit = styled.div`
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 30px;
`;
const Actions = styled.div`
  display: flex;
  margin-right: 30px;
  width: 140px;
  justify-content: flex-end;
`;
const Action = styled.div`
  color: ${Colors.planoplan_blue};
  cursor: pointer;
 
  &:not(:last-child) {
    margin-right: 8px;
  }
  
  &:hover {
    color: ${Colors.electric_blue};
  }
  
  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;
    color: ${Colors.feldgrau50};
  `}
`;
const Edit = styled.div``;

const EditIcon = styled.div`
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ isOpen }) => isOpen ? Colors.columbia75 : 'transparent'};
  width: 24px;
  
  &:hover {
    background-color: ${Colors.columbia75};
  }
  
  svg {
    display: block;
  }
`;
