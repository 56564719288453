import React from 'react';

export const IconError = ({ width = 140, height = 140, fill = '#2F2F2F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="69" fill="#FF8C87" stroke="#FF473F" strokeWidth="2"/>
      <path d="M36.6665 103.333L103.333 32.5" stroke="#2F2F2F" strokeWidth="4.16667"/>
      <path d="M34.5832 34.583L105.417 101.25" stroke="#2F2F2F" strokeWidth="4.16667"/>
    </svg>
  );
};

