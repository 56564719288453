import React from 'react';
import styled from 'styled-components';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const Loader = () => (
  <View>
    <Preloader width="70px" height="70px" fill={Colors.planoplan_blue} />
  </View>
);

const View = styled.div`
  ${Mixins.transition('background-color')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  pointer-events: none;
`;

