import React from 'react';
import styled from 'styled-components';

export const Header = ({ children }) => <View>{children}</View>;

export const View = styled.header`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
`;
