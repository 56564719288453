import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { RESOLUTION_PANORAMA, RESOLUTION_PANORAMA_ALIAS } from '../../constants';

export const InfoPanorama = () => {
  const { goods: { main: panorama } } = useSelector(state => state);
  const resolution = RESOLUTION_PANORAMA[panorama.params.resolution];
  const xml8k = 'https://files.planoplan.com/upload/userdata/1/5754/projects/3714782/panorama/cam_1713873243/data.xml';
  const xml4k = 'https://files.planoplan.com/upload/userdata/1/5754/projects/3714782/panorama/cam_1713942255/data.xml';
  const xml = resolution === RESOLUTION_PANORAMA_ALIAS.EIGHT_K ? xml8k : xml4k;

  const offWebVR = (krpano_interface) => {
    let elapsedTime = 0;

    const intervalId = setInterval(() => {
      krpano_interface.set('layer[webvr_enterbutton].visible', 'false');

      if (elapsedTime >= 1500) {
        clearInterval(intervalId);
      }

      elapsedTime += 50;
    }, 50);
  };

  const init = () => {
    window.embedpano({
      swf: '/etc/krpano/krpano.swf',
      xml,
      target: 'pano',
      onready: (krpano_interface) => {
        window.krpano_interface = krpano_interface;

        krpano_interface.set('autorotate.accel', 1000);
        krpano_interface.call('autorotate.start()');

        offWebVR(krpano_interface);

        krpano_interface.onclick = () => {
          krpano_interface.call('autorotate.stop');
        };
      },
      onerror: (error) => console.error(error),
    });
  };

  useEffect(() => {
    if (!window.embedpano) {
      const script = document.createElement('script');

      script.src = '/etc/krpano/krpano.js';
      script.async = true;
      script.onload = () => init();

      document.body.appendChild(script);
    } else {
      init();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <View id="pano" />
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
