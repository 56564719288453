import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { setKrpano } from '../../modules/Store/actions';
import { ContextMenu } from './organism/context-menu';
import {
  loadScene,
  setCurrent,
  createHotspot,
  addHotspot,
  removeHotspot,
  onEditHotspotStructure,
  onCreateHotspotStructure,
  centerTo,
  focusHotspot,
  blurHotspot,
  saveTourStructure,
  updateTitleInHotspots,
} from '../../modules/effects';
import { IInfo } from '../../icons';

export const Tour = () => {
  const dispatch = useDispatch();
  const { current, structure, lastHotspotIcon, hotspotIcons, settings: { zoom }} = useSelector(state => state);
  const [loading, setLoading] = useState(true);
  const [prevStructure, setPrevStructure] = useState(null);
  const [selectedHotspot, setSelectedHotspot] = useState({});
  const krpanoRef = useRef();
  let startZoom = -1;

  const goToScene = (sceneId) => dispatch(setCurrent(sceneId));

  const openContextMenu = (params) => setSelectedHotspot(params);

  const closeContextMenu = (props = {}) => {
    const { afterRemove  } = props;

    if (selectedHotspot.sceneId && !selectedHotspot.index) {
      dispatch(removeHotspot(selectedHotspot.sceneId, selectedHotspot.index));
    }

    if (!afterRemove) {
      dispatch(blurHotspot(selectedHotspot.sceneId, selectedHotspot.index));
    }

    setSelectedHotspot({});
  };

  const editHotspot = (sceneId, index = '', linkSceneId = '', icon = {}) => {
    if (`${index}`) {
      dispatch(onEditHotspotStructure(sceneId, index, linkSceneId, icon));
      dispatch(removeHotspot(sceneId, index, false));
      dispatch(addHotspot(sceneId, index, icon));
    }
  };

  const handleRemoveHotspot = (sceneId, index = '') => {
    dispatch(removeHotspot(sceneId, index));
    closeContextMenu({ afterRemove: true });
  };

  const handleAddHotspot = (sceneId, linkSceneId = '', icon = {}) => {
    dispatch(onCreateHotspotStructure(sceneId, linkSceneId, selectedHotspot.ath, selectedHotspot.atv, icon));
    dispatch(removeHotspot(sceneId));
    dispatch(createHotspot(sceneId, linkSceneId, selectedHotspot.ath, selectedHotspot.atv, icon));
    closeContextMenu();
  };

  const handleEditHotspot = (sceneId, index = '', linkSceneId = '', icon = {}) => {
    editHotspot(sceneId, index, linkSceneId, icon);
    closeContextMenu();
  };

  const onAddHotspot = (sceneId, ath, atv) => {
    const krpano = krpanoRef.current;
    const lastIcon = hotspotIcons.find(icon => icon.alias === lastHotspotIcon);
    const icon = lastIcon ? lastIcon : hotspotIcons[0];
    const arrow_hotspot = krpano.get('global').addhotspot(`arrow_hotspot_${sceneId}_`);

    krpano.call(`assignstyle(hotspot[${arrow_hotspot.name}], '${icon.style}');`);
    arrow_hotspot.ath = ath;
    arrow_hotspot.atv = parseFloat(atv) + 1.25;

    dispatch(centerTo(ath, atv + 20));
    openContextMenu({ sceneId, ath, atv });
  };

  const onUpdateHotspot = (sceneId, index, icon = {}) => {
    dispatch(removeHotspot(sceneId, index));

    const krpano = krpanoRef.current;
    const arrow_hotspot = krpano.get('global').addhotspot(`arrow_hotspot_${sceneId}_`);
    
    krpano.call(`assignstyle(hotspot[${arrow_hotspot.name}], ${icon.style || 'skin_new_hotspot_arrow_style'});`);
    arrow_hotspot.ath = selectedHotspot.ath;
    arrow_hotspot.atv = parseFloat(selectedHotspot.atv) + 1.25;
  };

  const onClickHotspot = async (index, sceneId) => {
    await dispatch(focusHotspot(sceneId, index));
    openContextMenu({ sceneId, index });
  };

  const handleEditIconHotspot = (sceneId, index = '', icon = {}) => {
    if (Boolean(`${index}`)) {
      editHotspot(sceneId, index, null, icon);
    } else {
      onUpdateHotspot(sceneId, index, icon);
    }
  };

  const onSceneLoaded = (sceneId) => {
    const hotspots = structure.scenes[sceneId].hotspots;

    if (hotspots && hotspots.length) {
      for (let index in hotspots) {
        if (Object.prototype.hasOwnProperty.call(hotspots, index)) {
          const iconAlias = hotspots[index]?.icon;
          const icon = hotspotIcons.find(icon => icon.alias === iconAlias);

          dispatch(addHotspot(sceneId, index, icon));
        }
      }
    }
  };

  const afterLoad = async (krpano) => {
    krpanoRef.current = krpano;
    startZoom = Number( krpano.get('view.fov'));

    dispatch(setKrpano(krpano));
    dispatch(loadScene(structure.startScene));

    setLoading(false);
  };

  const init = () => {
    window.embedpano({
      xml: `/etc/krpano/tour_skin/builder.xml?v=${Date.now()}`,
      target: 'tour-edit',
      passQueryParameters: true,
      consolelog: false,
      onready: afterLoad,
      onerror: (error) => console.error(error),
    });
  };

  const loadScript = () => {
    const script = document.createElement('script');

    script.src = '/etc/krpano/krpano.js';
    script.async = true;
    script.onload = () => init();

    document.body.appendChild(script);
  };


  useEffect(() => {
    if (!loading) {
      closeContextMenu();
      dispatch(loadScene(current.id));
    }
  }, [current]);

  useEffect(() => {
    if (!loading) {
      closeContextMenu();
      dispatch(loadScene(current.id));
    }
  }, [krpanoRef]);

  useEffect(() => {
    window.onAddHotspot = onAddHotspot;
    window.onSceneLoaded = onSceneLoaded;
    window.onClickHotspot = onClickHotspot;

    dispatch(updateTitleInHotspots(prevStructure));

    setPrevStructure(JSON.parse(JSON.stringify(structure)));
    dispatch(saveTourStructure(structure));
  }, [structure]);

  useEffect(() => {
    window.onAddHotspot = onAddHotspot;
  }, [lastHotspotIcon]);

  useEffect(() => {
    if (!window.embedpano) {
      loadScript();
    } else {
      init();
    }
  }, []);

  return (
    <View>
      <TourEdit id={'tour-edit'} zoom={zoom} />
      <Info>
        <Wrap>
          <IInfo fill={Colors.light_gray} />{translations.t('tour_editor.info')}
        </Wrap>

        <SaveInfo>{translations.t('tour_editor.autosave')}</SaveInfo>
      </Info>

      {selectedHotspot.sceneId && (
        <ContextMenu sceneId={selectedHotspot.sceneId}
                    index={selectedHotspot.index}
                    onGoOver={goToScene}
                    onRemove={handleRemoveHotspot}
                    editHotspot={handleEditHotspot}
                    addHotspot={handleAddHotspot}
                    onClose={closeContextMenu} 
                    onEditIcon={handleEditIconHotspot} />
      )}
    </View>
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
const TourEdit = styled.div`
  width: 100%;
  height: auto;
  flex-grow: 1;
  zoom: ${({ zoom }) => 1 / zoom};
  position: relative;
  
  & > div {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  color: ${Colors.light_gray};
  font-size: 12px;
  line-height: 14px;
  
  svg {
    margin-right: 6px;
  }
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
const SaveInfo = styled(Wrap)`
`;
