import { useState, useEffect } from 'react';
import { LocalStorage } from '@libs';
import { POP_COUNT_DOWN } from '@globalLocalStorage';

export const useCountDown = ({ id = '', value = 10 }) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const expiredTimeInLS = LocalStorage.has(POP_COUNT_DOWN) && LocalStorage.get(POP_COUNT_DOWN);
    const expiredTime =
      expiredTimeInLS && expiredTimeInLS[id] ? expiredTimeInLS[id] : Math.round(new Date() / 1000) + value;

    LocalStorage.set(POP_COUNT_DOWN, { ...expiredTimeInLS, [id]: expiredTime });

    let interval;

    const onCalc = () => {
      const timeLeft = expiredTime - Math.round(new Date() / 1000);

      if (timeLeft <= 0) {
        const expiredTimeInLS = LocalStorage.has(POP_COUNT_DOWN) && LocalStorage.get(POP_COUNT_DOWN);
        if (expiredTimeInLS[id]) {
          delete expiredTimeInLS[id];

          LocalStorage.set(POP_COUNT_DOWN, expiredTimeInLS);
        }

        clearInterval(interval);
        return setTime('0:00');
      }

      const minutesLeft = Math.floor(timeLeft / 60);
      const secondsLeft = Math.floor(timeLeft % 60);
      const minutes = minutesLeft;
      const seconds = secondsLeft > 9 ? secondsLeft : '0' + secondsLeft;

      setTime(minutes + ':' + seconds);
    };

    interval = setInterval(onCalc, 1000);

    onCalc();

    return () => {
      clearInterval(interval);
    };
  }, [id, value]);

  return time;
};
