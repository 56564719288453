import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { rgba } from 'polished';
import { useDispatch } from 'react-redux';
import { translations } from '@libs';
import { ITrash, IArrowTop } from '../../../icons';
import { rail_y, thumb_y } from '../../scroll';
import { SET_LAST_HOTSPOT_ICON } from '../../../constants';

export const ContextMenu = ({
  sceneId = '',
  index = '',
  onGoOver = () => {},
  onRemove = () => {},
  editHotspot = () => {},
  addHotspot = () => {},
  onClose = () => {},
  onEditIcon = () => {},
}) => {
  const dispatch = useDispatch();
  const { panoramas, structure, lastHotspotIcon, hotspotIcons, settings: { zoom }} = useSelector(state => state);
  const hasHotspot = Boolean(`${index}`);
  const hotspot = structure?.scenes?.[sceneId]?.hotspots?.[index] || {};
  const title = hasHotspot ? translations.t('tour_editor.hotspot.edit') : translations.t('tour_editor.hotspot.add');
  const anotherPanoramas = panoramas.filter(panorama => panorama.id !== Number(sceneId));
  const currentIcon = hotspotIcons  && hotspotIcons.find(icon => icon.alias === hotspot.icon);
  const lastIcon = hotspotIcons  && hotspotIcons.find(icon => icon.alias === lastHotspotIcon);
  const [chooseIcon, setChooseIcon] = useState(currentIcon || lastIcon || hotspotIcons?.[0]);

  const handleGoOver = () => onGoOver(hotspot.linkedscene);
  const handleRemove = () => onRemove(sceneId, index);
  const handleHotspot = (panorama) => {
    if (hasHotspot) {
      editHotspot(sceneId, index, panorama.id, chooseIcon);
    } else {
      addHotspot(sceneId, panorama.id, chooseIcon);
    }
  };
  const getTitle = (panoramaId) => structure?.scenes?.[panoramaId]?.title || `${panoramaId}`;
  const handleChooseIcon = (icon) => {
    dispatch({ type: SET_LAST_HOTSPOT_ICON, payload: icon.alias });
    setChooseIcon(icon);
    onEditIcon(sceneId, index, icon);
  };

  return (
    <View>
      <Overlay onClick={onClose} />

      <Menu>
        <Title>{title}</Title>

        {hotspotIcons && Boolean(hotspotIcons.length) && (
          <HotspotIcons>
            <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
              <HotspotWrap>
                {hotspotIcons.map((icon) => (
                  <HotspotIcon key={icon.alias} isActive={icon.alias === chooseIcon.alias} onClick={() => handleChooseIcon(icon)}>
                    <HotspotImg src={icon.previewUrl} />
                  </HotspotIcon>
                ))}
              </HotspotWrap>
            </ScrollBar>
          </HotspotIcons>
        )}

        {hasHotspot && (
          <GoOver onClick={handleGoOver}>
            <Item>
              <Icon><IArrowTop /></Icon>
              <Text>{translations.t('tour_editor.hotspot.go')}</Text>
            </Item>
          </GoOver>
        )}

        <List zoom={zoom}>
          <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
            {anotherPanoramas && Boolean(anotherPanoramas.length) && anotherPanoramas.map((panorama) => (
              <Item key={panorama.id} onClick={() => handleHotspot(panorama)}>
                <Icon>{panorama.index}</Icon>
                <Text>{getTitle(panorama.id)}</Text>
              </Item>
            ))}

            {!anotherPanoramas || !Boolean(anotherPanoramas.length) && (
              <Empty>{translations.t('tour_editor.hotspot.empty')}</Empty>
            )}
          </ScrollBar>
        </List>

        {hasHotspot && (
          <Delete onClick={handleRemove}>
            <Item>
              <Icon><ITrash /></Icon>
              <Text>{translations.t('tour_editor.hotspot.delete')}</Text>
            </Item>
          </Delete>
        )}
      </Menu>
    </View>
  );
};

const View = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${rgba(Colors.black, 0.2)};
`;
const Menu = styled.div`
  background-color: ${Colors.coal};
  border-radius: 2px;
  width: 200px;
  color: ${Colors.white};
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
  position: relative;
  top: 41%;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding: 9px 5px;
  border-bottom: 1px solid ${Colors.night};
`;
const HotspotIcons = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.night};
  overflow: hidden;
`;
const HotspotWrap = styled.div`
  display: flex;
  padding: 5px;
`;
const HotspotIcon = styled.div`
  width: 40px;
  flex-shrink: 0;
  height: 28px;
  border-radius: 20px;
  border: 1px solid ${({ isActive }) => isActive ? Colors.dark_cream : Colors.night};
  background-color: ${Colors.night};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 6px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    border-color: ${Colors.dark_cream};
  }
`;
const HotspotImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  min-height: 16px;
  min-width: 16px;
`;
const List = styled.div`
  max-height: ${({ zoom }) => 26 / zoom}vh;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const Empty = styled.div`
  padding: 0 5px;
  font-size: 14px;
  line-height: 36px;
`;
const Item = styled.div`
  ${Mixins.transition('background-color, color')};
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: ${Colors.dark_cream};
    color: ${Colors.night};
  }
`;
const Icon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${Colors.night};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  flex-shrink: 0;
  color: ${Colors.white};
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GoOver = styled.div`
  border-bottom: 1px solid ${Colors.night};
`;
const Delete = styled.div`
  border-top: 1px solid ${Colors.night};
`;
