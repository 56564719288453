import React from 'react';

export const IconPin = ({ width = 32, height = 32, fill = '#4F5B60', primary='#F8F6F4' }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="5" fill={primary}/>
    <g clipPath="url(#clip0_2349_4319)">
      <path d="M13.1499 18.8492L18.0996 23.799L17.7461 17.7886L22.6958 12.8388L26.2313 12.1317L19.8674 5.76777L19.1603 9.3033L14.2105 14.253L8.20011 13.8995L13.1499 18.8492ZM13.1499 18.8492L6.41961 25.5" stroke={fill} strokeWidth="2" strokeLinejoin="bevel"/>
    </g>
    <defs>
      <clipPath id="clip0_2349_4319">
        <rect width="24" height="24" fill="white" transform="translate(4 4)"/>
      </clipPath>
    </defs>
  </svg>
);
