import React from 'react';
import styled from 'styled-components';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Loader = ({ overlay }) => (
  <View overlay={overlay}>
      <Preloader
        width={'80px'}
        height={'80px'}
        fill={Colors.planoplan_blue}
      />
  </View>
);

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
