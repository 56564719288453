import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs/translations';
import { IWarning } from '../../icons/warning';

export const Attention = ({ keyTranslation }) => {
  return (
    <View>
      <IWarning width={16} height={16} fill={Colors.dark_cream} stroke={Colors.coal}/>
      <Text>
        {translations.t(keyTranslation)}
      </Text>
    </View>
  );
};

const View = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${Colors.coal};
  color: ${Colors.dark_cream};
  padding: 10px 20px;
  width: 100%;
  display: flex;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  pointer-events: none;
  z-index: 3;
  
  svg {
    flex-shrink: 0;
  }
`;
const Text = styled.div`
  margin-left: 4px;
`;
