import axios from 'axios';
import { LocalStorage } from '@libs';
import { POP_TRANSLATIONS, POP_LOCALE } from '@globalLocalStorage';
import { TRANSLATIONS_PATTERNS } from '@features/unity/screen-entry/constants';

let instance = null;

class Translations {
  constructor() {
    if (!instance) {
      this.translation = {};
      instance = this;
    }

    return instance;
  }

  connect(translation) {
    if (Object.values(this.translation).length === 0) {
      this.translation = translation || {};
    }
  }

  hasConnect() {
    return Object.values(this.translation).length > 0;
  }

  // patterns массив регулярок, include массив ключей которые можно доп включить.
  async load(locale = 'en', patterns = TRANSLATIONS_PATTERNS, include = []) {
    const host = window.location.host.indexOf('planoplan.com') !== -1 ? 'planoplan.com' : 'pp.ksdev.ru';
    const url = `https://files.${host}/upload/i18n/${locale}.json?${Date.now()}`;
    const tranlsations_ls = LocalStorage.get(POP_TRANSLATIONS);
    const locale_ls = LocalStorage.get(POP_LOCALE);

    const request = async () => {
      try {
        const response = await axios.get(url);
        const { data } = response;

        for (const item in data) {
          patterns.forEach((pattern) => {
            const regex = new RegExp(pattern);

            if (regex.test(item) || include.includes(item)) {
              this.translation[item] = data[item];
            }
          });
        }

        LocalStorage.set(POP_TRANSLATIONS, this.translation);
        LocalStorage.set(POP_LOCALE, locale);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    if (tranlsations_ls && locale === locale_ls) {
      this.translation = tranlsations_ls;
      request();
    } else {
      await request();
    }

    return this.translation;
  }

  t(key) {
    return this.translation[key] || key;
  }

  /* Вставляет перевод в строку. ex: "100% {{ discount }}" => "100% скидка" */
  tString(string) {
    const rg = /(\{\{.+?\}\})/g;

    if (typeof string === 'string' || string instanceof String) {
      return string.replace(rg, (res) => this.t(res.slice(2, -2)));
    }

    return string;
  }

  tObject(object) {
    for (const key in object) {
      if (
          Object.prototype.hasOwnProperty.call(object, key) &&
          (typeof object[key] === 'string' || object[key] instanceof String)
      ) {
        object[key] = this.tString(object[key]);
      }
    }
  }

  tObjectInArray(objects) {
    objects.forEach((object) => {
      this.tObject(object);
    });
  }

  json(key) {
    try {
      return JSON.parse(this.translation[key]);
    } catch (e) {
      return null;
    }
  }

  tInsert(key, values = {}) {
    let text = this.t(key);

    if (!text) {
      return key;
    }

    for (const i in values) {
      if (Object.prototype.hasOwnProperty.call(values, i)) {
        const rg = new RegExp(`{{${i}}}`, 'g');

        text = text.replace(rg, values[i]);
      }
    }

    return text;
  }
}

export const translations = new Translations();

if (!window.POPtranslations) {
  window.POPtranslations = translations;
}
