import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { rgba } from 'polished';
import { TextWithLinks } from '@components/TextWithLinks';
import { laptop } from '../../modules/breakpoints';
import { Close } from '../../components/Close';

export const ErrorScreen = () => {
  return (
    <View>
      <Container>
        <CloseIcon>
          <Close width="38px" height="38px" />
        </CloseIcon>
        <Sidebar />
        <Content>
          <Title>{translations.t('tour_editor.error.title')}</Title>
          <TextWithLinks Tag={Text} text={translations.t('tour_editor.error.text')} />
        </Content>
        <Controls />
      </Container>
    </View>
  );
};

const View = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: ${Grid.col(32)};
  height: 100%;
  padding: 80px 10px;
  min-height: 650px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.night};
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
  border-radius: 10px;
`;
const Sidebar = styled.div`
  width: 240px;
  height: 100%;
  border-right: 1px solid ${Colors.coal};
  flex: 0 0 240px;
`;
const Content = styled.div`
  ${Grid.prefix(3, 32)};
  ${Grid.suffix(3, 32)};
  flex-grow: 1;
  color: ${Colors.light_gray};
  
  a {
    color: ${Colors.planoplan_blue};
    text-decoration: none;
  }
`;
const Controls = styled.div`
  width: 66px;
`;
const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 12px;
`;
const Text = styled.div`
  max-width: 900px;
  font-weight: 300;
`;
const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: -20px;
  top: 0;
  transform: translateX(100%);

  @media ${laptop} {
    display: block;
  }
`;

