import React from 'react';

export const ICardEps = ({ width = 35, height = 24, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.63326 0C8.95166 0 10.8311 1.83332 10.8311 4.09486V5.24165C10.8311 5.38885 10.7014 5.53437 10.5505 5.53437H9.00606C8.85519 5.53437 8.7328 5.38885 8.7328 5.24165V4.09486C8.7328 2.96553 7.79222 2.04672 6.63446 2.04672C5.4767 2.04672 4.53612 2.96553 4.53612 4.09486V5.24165C4.53612 5.38885 4.40382 5.53437 4.25295 5.53437H2.70852C2.55765 5.53437 2.43778 5.38885 2.43778 5.24165V4.01108L2.43763 4.00231C2.43746 3.99585 2.4373 3.98952 2.43799 3.98307C2.4989 1.77336 4.35325 0 6.63326 0Z" fill="#D1D1D1"/>
      <path d="M6.64511 18.649C4.31385 18.649 2.3539 16.9878 1.82354 14.8176C1.82354 14.8176 1.67031 14.1001 1.67031 13.6276C1.67031 13.1551 1.81536 12.4301 1.81536 12.4301C2.34955 10.2653 4.30709 8.65324 6.63451 8.65324C9.37185 8.65324 11.618 10.8784 11.618 13.6157V14.8195H4.34543C4.77491 15.6589 5.64347 16.131 6.64511 16.131H13.1828L13.1918 9.03236C13.1918 7.97401 12.3259 7.10813 11.2676 7.10813H2.00143C0.943081 7.10813 0.0771484 7.94778 0.0771484 9.00613V18.2723C0.0771484 19.3306 0.943081 20.2228 2.00143 20.2228H11.2676C12.2174 20.2228 13.0096 19.5408 13.1627 18.649H6.64511Z" fill="#D1D1D1"/>
      <path d="M6.63446 11.0087C5.6367 11.0087 4.76898 11.6196 4.33778 12.4064H8.93125C8.50004 11.6196 7.63238 11.0087 6.63446 11.0087Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4512 13.6552C14.4512 10.9135 16.6693 8.68213 19.3926 8.68213C22.116 8.68213 24.3211 10.9047 24.3211 13.6465C24.3211 16.3882 22.1108 18.6492 19.3875 18.6492H16.9167V24H14.4512V13.6552ZM16.9167 16.1312H19.3926C20.7631 16.1312 21.8779 15.035 21.8779 13.6465C21.8779 12.258 20.7631 11.1284 19.3926 11.1284C18.0222 11.1284 16.9167 12.258 16.9167 13.6465V16.1312Z" fill="#D1D1D1"/>
      <path d="M28.4968 12.4067H31.71C33.3887 12.4309 34.7618 13.8284 34.7618 15.5164C34.7618 17.2044 33.3887 18.6251 31.71 18.6492H22.5926C23.7316 18.1771 24.2927 17.2329 24.8697 16.1312H31.6664C32.0237 16.1312 32.3146 15.8687 32.3146 15.5123C32.3146 15.1558 32.0238 14.8722 31.6664 14.8722H28.4968C26.7895 14.8722 25.4003 13.4803 25.4003 11.7772C25.4003 10.0741 26.7895 8.68213 28.4968 8.68213H33.3887V11.0952H28.4968C28.1395 11.0952 27.8487 11.409 27.8487 11.7655C27.8487 12.1219 28.1394 12.4067 28.4968 12.4067Z" fill="#D1D1D1"/>
    </svg>
  );
};
