import { request } from '@libs';

export const ApiCall = {
  locale: 'en',
  team_id: '',

  setSettings({ locale, team_id }) {
    this.locale = locale;
    this.team_id = team_id;
  },

  // т.к team_id может меняться динамически
  getGoods({ team_id }) {
    const urls = { local: '/json/store/getgoods.json', prod: `/${this.locale}/users/getgoods/` };

    return request.call_auth('get', urls, { team_id });
  },

  getProfile() {
    const urls = { local: '/json/store/profile.json', prod: `/${this.locale}/users/profile/` };

    return request.call_auth('get', urls);
  },

  getTeams() {
    const urls = { local: '/json/user-widget/teams.json', prod: `/${this.locale}/teams/` };

    return request.call_auth('get', urls);
  },

  getUserSettings({ team_id }) {
    const prod = team_id
      ? `/teams/getsettings/?name[]=store_basket&name[]=lastReadedNotis&team_id=${team_id}`
      : `/users/getsettings/?name[]=store_basket&name[]=lastReadedNotis`;

    const urls = {
      local: `/json/gallery/user_settings.json?name[]=store_basket&name[]=lastReadedNotis&team_id=${team_id}`,
      prod,
    };

    return request.call_auth('get', urls);
  },

  getNotifications({ params, team_id }) {
    const path = team_id ? `teams` : `users`;
    const urls = { local: '/json/user-widget/notification.json', prod: `/${this.locale}/${path}/events/` };

    return request.call_auth('get', urls, { ...params, team_id });
  },

  setUserSettings({ params, team_id }) {
    const path = team_id ? `teams` : `users`;
    const urls = { local: '/json/gallery/user_settings.json', prod: `/${this.locale}/${path}/settings/` };

    return request.call_auth('post', urls, { ...params, team_id });
  },

  getTest(t) {
    return request.call_auth('get', t);
  },
};
