import { actions as storeActions } from './actions/store';
import { actions as globalActions } from './actions/global';
import { actions as layoutActions } from './actions/layout';
import { actions as cabinetActions } from './actions/cabinet';

const combineActions = {
  GLOBAL: globalActions,
  STORE: storeActions,
  LAYOUT: layoutActions,
  CABINET: cabinetActions,
};

class Api {
  dispatch = {
    GLOBAL: {},
    LAYOUT: {},
    STORE: {},
    USER_WIDGET: {},
    CABINET: {},
  };
  state = {
    STORE: {},
  };
  actions = {};

  registerDispatch(type, dispatch, state) {
    this.dispatch = {
      ...this.dispatch,
      [type]: {
        ...this.dispatch[type],
        ...dispatch,
      },
    };

    this.state = {
      ...this.state,
      [type]: state,
    };

    this.actions = {
      ...this.actions,
      [type]: combineActions[type],
    };
  }

  dispatchAction(type, payload) {
    for (let i in this.actions) {
      const currentActions = this.actions[i];

      if (currentActions[type]) {
        const actionName = currentActions[type].action;

        if (i === 'GLOBAL' || i === 'LAYOUT' || i === 'CABINET') {
          return this.dispatch[i][actionName](payload);
        } else {
          return this.dispatch[i]({ type: actionName, payload });
        }
      }
    }

    console.info(`%c${type}`, 'color: #ff0000', ' - данный action не зарегистрирован');
  }
}

export const API = new Api();
