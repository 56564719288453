import { IS_TEST } from '@globalConstants';
import { LocalStorage } from '@libs';
import { POP_PROFILE } from '@globalLocalStorage';
import {
  SET_PROCESSING,
  SET_STEP,
  SET_SOCIAL_INFO,
  SET_ERROR,
  SET_CHECKING_EMAIL,
  SET_SIGN_UP_MODE,
  SET_LOGIN_MODE,
  SET_DOMAINS,
  SET_SELECTED_DOMAIN,
  SET_PREV_STEP,
  SET_UPDATE_STATUS,
  SET_DOWNLOADED_PERCENT,
  SET_CHECKING_MODE,
  SET_CAN_RESEND_CODE,
  SET_FIRST_LOGIN,
  SET_SURVEY_COMPLETED,
  SET_PROFILE,
  SET_IS_LOGIN,
  SET_COUNTRY,
} from '../../constants';

export const setStep = (step = '') => ({ type: SET_STEP, payload: step });
export const setPrevStep = (name = '', payload = {}) => ({ type: SET_PREV_STEP, payload: { name, payload } });

export const setSignUpMode = (mode = '') => ({ type: SET_SIGN_UP_MODE, payload: mode });
export const setLoginMode = (mode = '') => ({ type: SET_LOGIN_MODE, payload: mode });
export const setCheckingMode = (mode = '') => ({ type: SET_CHECKING_MODE, payload: mode });

export const setCheckingEmail = (email = '') => ({ type: SET_CHECKING_EMAIL, payload: email });

export const startProcessing = () => ({ type: SET_PROCESSING, payload: true });
export const endProcessing = () => ({ type: SET_PROCESSING, payload: false });

export const setCanResendCode = (state = false) => ({ type: SET_CAN_RESEND_CODE, payload: state });

export const setDomains = (domains = []) => ({ type: SET_DOMAINS, payload: domains });
export const setSelectedDomain = (domain = {}) => ({ type: SET_SELECTED_DOMAIN, payload: domain });

export const setSocialInfo = (name = '', avatar = '') => ({ type: SET_SOCIAL_INFO, payload: { name, avatar } });
export const setProfile = (value) => (dispatch) => {
  dispatch({ type: SET_PROFILE, payload: value });
  LocalStorage.set(POP_PROFILE, value);
};
export const setIsLogin = (value) => ({ type: SET_IS_LOGIN, payload: value });

export const setUpdateStatus = (status = '') => ({ type: SET_UPDATE_STATUS, payload: status });
export const setDownloadedPercent = (value = 0) => ({ type: SET_DOWNLOADED_PERCENT, payload: Number(value) });

export const setError = ({ type = '', error = {}, step = '', errorData = {} }) => (dispatch) => {
  const getErrorText = () => (typeof error === 'string' && error ? error : 'Error in entry form');
  const getErrorObjectMessage = () => (typeof error === 'object' && error.message ? error.message : '');
  const message = getErrorObjectMessage() || getErrorText();

  dispatch({ type: SET_ERROR, payload: { type, message, step, data: errorData } });

  if (!IS_TEST) {
    console.error('Entry form error:', message);
  }
};
export const clearError = () => ({ type: SET_ERROR, payload: { type: '', message: '', step: '' } });
export const setFirstLogin = (value) => ({ type: SET_FIRST_LOGIN, payload: value });
export const setSurveyCompleted = (value) => ({ type: SET_SURVEY_COMPLETED, payload: value });
export const setCountry = (value) => ({ type: SET_COUNTRY, payload: value });
