import { translations } from './translations';

export const formatKBytes = (kbytes) => {
  let iterCount = 0;
  let res = parseInt(kbytes);

  while (res > 100) {
    res /= 1024;
    iterCount++;
  }

  if (isNaN(res)) {
    return null;
  }

  let r = translations.t('user_widget.kb');

  if (iterCount > 0) r = translations.t('user_widget.mb');
  if (iterCount > 1) r = translations.t('user_widget.gb');
  if (iterCount > 2) r = translations.t('user_widget.tb');

  return `${res.toFixed(1)} ${r}`;
};
