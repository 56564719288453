import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { EMAIL_REGEXP, ERROR_TYPES } from '../../constants';
import { ButtonAction, Field, Form, Header, Text, InputField, Error } from '../../components';
import { analytics } from '../../modules/analytics';
import { tablet_landscape } from '../../modules/breakpoints';
import { useSelector } from 'react-redux';

export const Social = ({
  name,
  avatar,
  isProcessing,
  onSubmitSocial,
  error = {},
  onClearError = () => {},
}) => {
  const { isLogin, signUpMode, loginMode } = useSelector(state => state);
  const [field, setField] = useState({
    value: '',
    error: '',
  });

  const isError = error.type === ERROR_TYPES.LOCAL;
  const onChange = ({ value, error }) => {
    setField({ ...field, value, error });

    if (isError) {
      onClearError();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (!field.error) {
      onSubmitSocial(field.value);
    }
  };

  useEffect(() => {
    analytics.pushView(`Форма регистрации почта из ${isLogin ? loginMode : signUpMode}`, 'sign-up', 'view', isLogin ? loginMode : signUpMode);
  }, []);

  return (
    <>
      <Header title={translations.t('form_entry.signup.title')} />

      <User>
        <Avatar src={avatar} />

        <Info>
          <Entered>{translations.t('form_entry.social.enter')}</Entered>
          {name}
        </Info>
      </User>

      <Text>{translations.t('form_entry.social.email')}</Text>

      <Form onSubmit={onSubmit} isMarginTop>
        <Field isError={isError}>
          <InputField
            placeholder={translations.t('form_entry.email.placeholder')}
            type="email"
            name="username"
            value={field.value}
            hasError={field.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              regexp: RegExp(EMAIL_REGEXP),
              errorValid: translations.t('form_entry.email.notvalid'),
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            autoComplete="username"
            required>
            Email
          </InputField>
        </Field>

        {isError && <Error>{error.message}</Error>}

        <ButtonAction type="submit" loading={isProcessing} disabled={field.error || !field.value} useButtonLoader>
          {translations.t('form_entry.continue')}
        </ButtonAction>
      </Form>
    </>
  );
};

const User = styled.div`
  display: flex;
  align-items: center;
`;
const Avatar = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 10px;
  background-color: ${Colors.whisper};
  background-image: ${(p) => (p.src ? `url(${p.src})` : 'none')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media ${tablet_landscape} {
    width: 90px;
    height: 90px;
  }
`;
const Info = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  margin-left: 15px;
  display: flex;
  flex-direction: column;

  @media ${tablet_landscape} {
    font-size: 16px;
    line-height: 19px;
  }
`;
const Entered = styled.div`
  margin-bottom: 2px;
  font-weight: 500;

  @media ${tablet_landscape} {
    margin-bottom: 10px;
  }
`;
