import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { deviceDetected } from 'planoplan-ui-kit/core/device-detected';
import { url } from '@globalInvoke';
import { URL_OPEN } from '@globalConstants';
import { themes } from './themes';

export const Link = ({
  href,
  onClick,
  blank,
  children,
  size = 'M',
  theme = 'primary',
  disabled,
  fontSize,
  lineHeight,
  device = {},
  ...restProps
}) => {
  const handleClick = (event) => {
    event.preventDefault();

    if (onClick) {
      onClick(event);
    } else {
      const target = event.target;

      if (target.href) {
        window.invokeEditorAction({
          name: url[URL_OPEN].name,
          value: target.href,
        });
      }
    }
  };

  return (
    <ThemeProvider theme={{ theme: themes.theme[theme], size: themes.size[size] }}>
      <View
        href={href}
        onClick={handleClick}
        size={size}
        disabled={disabled}
        as={!href && 'button'}
        target={blank && '_blank'}
        fontSize={fontSize}
        lineHeight={lineHeight}
        isMobile={device.isMobile || deviceDetected.isMobile}
        {...restProps}>
        {children}
      </View>
    </ThemeProvider>
  );
};

export const View = styled.a`
  ${Mixins.transition('color')};
  ${Mixins.resetLink()};
  ${Mixins.resetButton()};
  color: ${(p) => p.theme.theme.color};
  font-size: ${(p) => p.fontSize || p.theme.size.fontSize};
  line-height: ${(p) => p.lineHeight || p.theme.size.lineHeight};
  outline: none;
  font-weight: 500;

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      color: ${(p) => p.theme.theme.disabled.color};
    `}

  &:hover {
    color: ${(p) => !p.isMobile && p.theme.theme.hover.color};
  }
`;
