import { ON_CLOSE_APP } from '@observer/constants';
import { mappedButtons } from '../../invoke';
import { BUTTONS } from '../../constants';

const observer = new window.POPObserver();

export const closeApp = (close) => {
  close();

  observer.postMessage(ON_CLOSE_APP);

  window.invokeEditorAction({
    name: mappedButtons[BUTTONS.CLOSE_APP].name,
    value: mappedButtons[BUTTONS.CLOSE_APP].value,
  });
};
