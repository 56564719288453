import React from 'react';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const IconLocked = ({ width = 32, height = 32, fill = Colors.whisper, primary = Colors.planoplan_blue }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="5" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4142 5C13.8838 5 13.3751 5.21071 13 5.58579L10.5858 8C10.2107 8.37507 10 8.88378 10 9.41421V14H7C6.44772 14 6 14.4477 6 15V26C6 26.5523 6.44772 27 7 27H25C25.5523 27 26 26.5523 26 26V15C26 14.4477 25.5523 14 25 14H22V9.41421C22 8.88378 21.7893 8.37507 21.4142 8L19 5.58579C18.6249 5.21071 18.1162 5 17.5858 5H14.4142ZM20 14V9.41421L17.5858 7H14.4142L12 9.41421V14H20ZM15 18H17V24H15V18Z" fill={primary}/>
  </svg>

);
