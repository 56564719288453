import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { rgba } from 'polished';
import { Transition } from 'react-transition-group';
import { ICheckCircle } from 'planoplan-ui-kit/core/icons/i-check-circle';
import { IErrorCircle } from 'planoplan-ui-kit/core/icons/i-error-circle';
import { NOTIFICATION_TYPES, NOTIFICATION_DELAY } from '@globalConstants';

export const Notification = ({ id, type, lifeTime, message, onClick = () => {} }) => {
  const isShow = lifeTime > Date.now();
  const [isMount, setIsMount] = useState(isShow);

  useEffect(() => {
    let timeoutId = () => {};

    if (isShow) {
      timeoutId = setTimeout(() => setIsMount(false), NOTIFICATION_DELAY);
    }

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);

  return (
    <Transition mountOnEnter unmountOnExit timeout={300} in={isMount}>
      <Container isMounted={isMount} onClick={() => onClick(id)}>
        {
          {
            [NOTIFICATION_TYPES.SUCCESS]: <ICheckCircle width="48px" height="48px" stroke={Colors.frog_green} />,
            [NOTIFICATION_TYPES.ERROR]: <IErrorCircle width="48px" height="48px" stroke={Colors.colar} />,
          }[type]
        }
        <Text>{message}</Text>
      </Container>
    </Transition>
  );
};

const show = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const hide = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const Container = styled.div`
  min-width: 320px;
  max-width: 320px;
  min-height: 72px;
  margin-bottom: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 0 34px 6px ${rgba(Colors.coal, 0.13)}, -1px 1px 2px 0 ${rgba(Colors.coal, 0.2)};
  background-color: ${Colors.white};
  padding-right: 24px;
  padding-left: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${(p) => (p.isMounted ? show : hide)} 0.3s linear forwards;
  
  svg {
    flex-shrink: 0;
  }
`;

const Text = styled.div`
  color: ${Colors.coal};
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: 15px;
`;
