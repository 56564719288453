import { useState } from 'react';

export const useModals = (arr = []) => {
  const [modals, setModals] = useState(
    arr.reduce((acc, modal) => {
      acc[modal] = {
        isOpen: false,
      };
      return acc;
    }, {})
  );

  const openModal = (modal, params) => {
    setModals({
      ...modals,
      [modal]: {
        isOpen: true,
        params
      },
    });
  };

  const closeModal = (modal) => {
    setModals({
      ...modals,
      [modal]: {
        isOpen: false,
      },
    });
  };

  const closeAllModals = () => {
    const openModals = { ...modals };

    for (let i in openModals) {
      openModals[i] = {
        ...openModals[i],
        isOpen: false,
      };
    }

    setModals(openModals);
  };

  return [modals, openModal, closeAllModals, closeModal];
};
