import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IArrow } from '../../icons';

export const Arrow = ({ onClick, direction, disabled }) => (
  <View onClick={onClick} direction={direction} disabled={disabled} withRotate={direction === 'prev'}>
    <IArrow />
  </View>
);

const View = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('opacity')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 40px;
  height: 100px;
  background: ${Colors.night};
  border: 1px solid ${Colors.coal};
  border-radius: 5px;
  transform: ${({ withRotate }) => withRotate ? 'rotate(180deg)' : 'none'};

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }

  &:disabled {
    opacity: 0;
  }
  
  svg {
    ${Mixins.transition('transform')};
    display: block;
  }
`;
