import Icons from '../icons/card';

export const METHOD_PAY_ICONS = {
  card: Icons.ICardNew,
  bank_card: Icons.ICardNew,
  yoo_money: Icons.ICardYoomoney,
  sberbank: Icons.ICardSber,
  sbp: Icons.ICardSbp,
  alfabank: Icons.ICardAlfa,
  qiwi: Icons.ICardQiwi,
  paypal: Icons.ICardPaypal,
  alipay: Icons.ICardAlipay,
  bancontact: Icons.ICardBancontact,
  eps: Icons.ICardEps,
  giropay: Icons.ICardGiropay,
  ideal: Icons.ICardIdeal,
  p24: Icons.ICardPrzelewy,
  sepa_debit: Icons.ICardSepa,
  sofort: Icons.ICardSofort,
};
export const CARD_TYPE_ICONS = {
  mastercard: Icons.ICardMastercard,
  visa: Icons.ICardVisa,
  maestro: Icons.ICardMaestro,
  mir: Icons.ICardMir,
  unionpay: Icons.ICardUnion,
};
export const CARD_PAY_TYPES = {
  bank_card: 'bank_card',
  card: 'card',
};
export const MODE_PAY = {
  binding: 'binding', // оплата через привязанную карту
  bank: 'bank', // оплата через сервис банка
};
