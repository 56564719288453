import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector } from 'react-redux';
import { RangeSlider } from '../Range-Slider';
import { getPercentValueSlider, getNumberValueSlider } from '../../modules/helpers';
import { translations } from '@libs';

export const Slider = ({
  alias,
  name,
  value,
  min,
  max,
  step,
  centerAt,
  onlyPositive,
  onChangeSlider = () => {},
  onChangeInput = () => {}
}) => {
  const {settings: { zoom }} = useSelector((state) => state);
  const [inputValue, setInputValue] = useState(getPercentValueSlider(value, min, max, centerAt));
  const [isDragged, setIsDragged] = useState(false);

  const onDragging = (isDragged) => setIsDragged(isDragged);
  const changeInput = ({ target }) => {
    const regexp = new RegExp('^-?[0-9]*$');

    if (!regexp.test(target.value)) {
      return;
    }

    if (target.value === '-') {
      setInputValue(target.value);
    }

    if (Number(target.value) >= -100 && Number(target.value) <= 100) {
      setInputValue(Number(target.value));

      if (typeof Number(target.value) === 'number' && target.value !== '-') {
        onChangeInput(alias, getNumberValueSlider(target.value, min, max, centerAt))
      }
    }
  };
  const onKeyDownInput = (event) => {
    const step = event.altKey ? 10 : 1;

    if (event.keyCode === 38) {
      onChangeInput(alias, getNumberValueSlider(Number(event.target.value) + step, min, max, centerAt));
      event.preventDefault();
    }

    if (event.keyCode === 40) {
      onChangeInput(alias, getNumberValueSlider(Number(event.target.value) - step, min, max, centerAt));
      event.preventDefault();
    }
  };

  useEffect(() => {
    setInputValue(getPercentValueSlider(value, min, max, centerAt));
  }, [value]);

  return (
    <View>
      <Name>{translations.t(name)}</Name>

      <Label>
        <InputWrap>
          <Input value={inputValue}
                 isActive={isDragged}
                 onChange={changeInput}
                 onKeyDown={onKeyDownInput} />
          <Post>%</Post>
        </InputWrap>

        <Range zoom={zoom}>
          <RangeSlider alias={alias}
                       value={value}
                       min={min}
                       max={max}
                       scale={zoom}
                       onlyPositive={onlyPositive}
                       centerAt={centerAt}
                       onChange={onChangeSlider}
                       onDragging={onDragging}/>
        </Range>
      </Label>
    </View>
  )
};

const View = styled.div`
  width: 100%;
  color: ${Colors.light_gray};
`;

const Name = styled.div`
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 14px;
`;
const Label = styled.div`
  display: flex;
  align-items: center;
`;
const InputWrap = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 10px;
`;
const Input = styled.input`
  ${Mixins.transition('color, border-color')};
  border-radius: 4px;
  background-color: ${Colors.coal};
  width: 60px;
  height: 28px;
  color: ${Colors.light_gray};
  font-size: 14px;
  line-height: 16px;
  padding: 6px 20px 6px 6px;
  border: 1px solid transparent;
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
  outline: none;
  
  &:hover {
    color: ${Colors.dark_cream}
  }
  
  &:focus {
    outline: none;
    color: ${Colors.dark_cream};
    border-color: ${Colors.dark_cream};
  }
  
  ${({ isActive }) => isActive && `
      outline: none;
      color: ${Colors.dark_cream};
      border-color: ${Colors.dark_cream};
  `}
`;
const Post = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.nobel};
  position: absolute;
  right: 6px;
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
`;
const Range = styled.div`
  width: 100%;
  zoom: ${({ zoom }) => 1 / zoom};
`;
