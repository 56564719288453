export const UI2WebglInvokeFunction = (fn, ...params) => {
  try {
    if (!window.location.host.indexOf('planoplan.com') !== -1 && process.env.NODE_ENV !== 'test') {
      console.info('%cUI2WEBGL INVOKE FUNCTION', 'color: #fc46aa', { func: fn } , { params: {...params} });
    }

    // todo: передавать id как аргумент
    const frameElement = document.getElementById('pop-loader-iframe');
    const frame = frameElement ? frameElement.contentWindow : window.frames[0];

    if (typeof frame[fn] === 'function') {
      frame[fn](...params);
    }

  } catch (error) {
    return error;
  }
};

window.UI2WebglInvokeFunction = UI2WebglInvokeFunction;
