import { ApiCall } from '../../modules/api-call';
import { notifications } from '@features/notifications';
import { translations } from '@libs';


export const addUserInTeam = (email) => async (dispatch, getState) => {
  try {
    const { team } = getState();

    const response = await ApiCall.onAddUserInTeam({ email, team_id: team.id });

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.teams.invitation_sent'));
    } else {
      notifications.showError(translations.t('cabinet.notification.addMember.error'));
    }

    return response.data.success;
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.addMember.error'));

    return false;
  }
};

export const removeUserFromTeam = (id) => async (dispatch, getState) => {
  try {
    const { team } = getState();

    const response = await ApiCall.onRemoveUserFromTeam({ id, team_id: team.id });

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.deleteMember.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.deleteMember.error'));
    }

    return response.data.success;
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.deleteMember.error'));

    return false;
  }
};

export const blockUserInTeam = (id) => async (dispatch, getState) => {
  try {
    const { team } = getState();

    const response = await ApiCall.onBlockUserInTeam({ id, team_id: team.id });

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.blockMember.success'));
    } else {
      if (response.data.errorText === 'User already blocked') {
        notifications.showError(translations.t('cabinet.notification.blockMember.already'));
      } else {
        notifications.showError(translations.t('cabinet.notification.blockMember.error'));
      }
    }

    return response.data.success;
  } catch (error) {
    notifications.showError(translations.t('cabinet.notification.blockMember.error'));

    return false;
  }
};
