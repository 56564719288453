import React, { useEffect } from 'react';
import styled from 'styled-components';
import { isLocalHost } from '@libs';

export const Tour = ({ current = {} }) => {
  const init = () => {
    const domain = isLocalHost ? 'https://pp.ksdev.ru' : '';

    window.embedpano({
      xml: `${domain}/view/tourxml/?id=${current.publishId}`,
      target: 'tour-view',
      passQueryParameters: true,
      consolelog: false,
      onready: () => {},
      onerror: (error) => console.error(error),
    });
  };

  const loadScript = () => {
    const script = document.createElement('script');

    script.src = '/etc/krpano/krpano.js';
    script.async = true;
    script.onload = () => init();

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!window.embedpano) {
      loadScript();
    } else {
      init();
    }
  }, []);

  return <View id="tour-view" />;
};

const View = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
