import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const SocialConfirmGoogle = ({ isOpen, onCancel = () => {}, onConfirm = () => {} }) => {
  /*const { settings: { zoom }} = useSelector(state => state);

  useEffect(() => {
    setTimeout(() => { // todo Сделать как нибудь по другому
      if (document.getElementById('google-login-setting') && window.google) {
        window.google.accounts.id.renderButton(document.getElementById('google-login-setting'), {
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          shape: 'rectangular',
          text: 'signin',
        });
      }
    }, 100);
  }, [isOpen]);*/

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('users.profile.multiauth.google.popup.title')}</Header>
      <Text dangerouslySetInnerHTML={{ __html: translations.t('users.profile.multiauth.google.popup.body.comfirm') }} />
      <Buttons>
        <Button theme="warning" size="M" onClick={onConfirm}>
          {/*<Google zoom={zoom} id="google-login-setting" />*/}
          {translations.t('users.profile.multiauth.activate')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Text = styled.p`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 160px;
    margin-right: 10px;
  }
`;

