// Actions
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_ARTICLE = 'SET_ARTICLE';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_ARTICLE = 'GET_ARTICLE';

// Invoke actions
export const BUTTONS = {
  ASSISTANT_CLOSE: 'ASSISTANT_CLOSE',
};

// LocalStore
export const LOCAL_STORE_ASSISTANT_LIBRARY = 'pop_assistant_library';
export const LOCAL_STORE_ASSISTANT_DATA = 'LOCAL_STORE_ASSISTANT_DATA';

export const ASSISTANT_OPEN = 'ASSISTANT_OPEN';
export const ASSISTANT_CLOSE = 'ASSISTANT_CLOSE';

export const DEFAULT_ARTICLE_ALIAS = 'helper.start';
