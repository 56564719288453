import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { MODALS } from '@features/unity/constants';
import { notifications } from '@features/notifications';
import { useModals, UploadImageToDesignProject } from '@components';
import * as projectEffects from '../../modules/effects';
import { IconUpload, IconLink } from '../../icons';

export const UploadImage = () => {
  const dispatch = useDispatch();
  const [isOpenMode, setIsOpenMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modals, openModal, closeAllModals] = useModals([MODALS.UPLOAD_IMAGE_TO_DESIGN_PROJECT]);

  const onSetFile = async (event) => {
    const allFiles = event.target.files;
    const files = [];
    let countErrFiles = 0;

    for (let key in allFiles) {
      if (Object.prototype.hasOwnProperty.call(allFiles, key)) {
        const file = allFiles[key];

        if (['image/png', 'image/jpeg'].includes(file.type)) {
          files.push(file);
        } else {
          countErrFiles = countErrFiles + 1;
        }
      }
    }

    if (countErrFiles) {
      notifications.showError(translations.t('gallery.upload.error.type'));
    }

    runLoading();
    onCloseTooltip();
    await dispatch(projectEffects.uploadImage({ files, countErrFiles }));
    stopLoading();
  };
  const openModalUploadUrl = () => {
    onCloseTooltip();
    openModal(MODALS.UPLOAD_IMAGE_TO_DESIGN_PROJECT);
  };
  const uploadFromUrl = async (url) => {
    runLoading();
    closeAllModals();
    await dispatch(projectEffects.uploadImage({ url }));
    stopLoading();
  };

  const onCloseTooltip = () => setIsOpenMode(false);
  const onToggleTooltip = () => !isLoading && setIsOpenMode(!isOpenMode);
  const runLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  return (
    <View>
      {isOpenMode &&
        <Overflow onClick={onCloseTooltip} />
      }

      <Content>
        <Button onClick={onToggleTooltip} disabled={isLoading}>{translations.t('gallery.pdf.content.button.load')}</Button>

        {(isOpenMode && !isLoading) &&
          <ModeTooltip>
            <Title>{translations.t('gallery.pdf.upload.title')}</Title>
            <ModeItem>
              <IconUpload />{translations.t('gallery.pdf.upload.pc')}
              <input type="file" accept=".jpg, .jpeg, .png" multiple onChange={onSetFile}/>
            </ModeItem>
            <ModeItem onClick={openModalUploadUrl}><IconLink />{translations.t('gallery.pdf.upload.url')}</ModeItem>
          </ModeTooltip>
        }
      </Content>

      <UploadImageToDesignProject isOpen={modals[MODALS.UPLOAD_IMAGE_TO_DESIGN_PROJECT].isOpen}
                                  onCancel={closeAllModals}
                                  onUpload={(url) => uploadFromUrl(url)}/>
    </View>
  );
};

const View = styled.div`
  padding: 10px 20px 10px 15px;
  border-top: 1px solid ${Colors.coal};

`;
const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`;
const Content = styled.div`
  position: relative;
`;
const Button = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color')};
  height: 22px;
  width: 100%;
  background-color: ${Colors.dark_cream};
  color: ${Colors.night};
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  text-align: center;
  
  &:hover {
    background-color: #FFB540;
  }
`;
const ModeTooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${Colors.white};
  margin-bottom: 5px;
  margin-left: 5px;
  border-radius: 4px;
  width: 240px;
  z-index: 3;
`;
const Title = styled.div`
  padding: 5px 12px;
  color: ${Colors.nobel};
  text-transform: uppercase;
  font-size: 10px;
  border-bottom: 1px solid ${Colors.light_gray};
`;
const ModeItem = styled.label`
  padding: 5px 12px;  
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${Colors.coal};
  cursor: pointer;
  border: 1px solid transparent;
  
  &:last-child {
    border-radius: 0 0 4px 4px;
  }
  
  svg {
    margin-right: 14px;
  }
  
  input {
    display: none;
  }
  
  &:hover {
    border-color: ${Colors.planoplan_blue};
  }
`;

