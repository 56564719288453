import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { SET_DESIGN_PROJECT_LOADING_PDF } from '../../constants';

export const Loader = () => {
  const state = useSelector((state) => state);
  const { loading } = state;
  const { getters } = loading;
  const isGenerationPdf = Boolean(getters[SET_DESIGN_PROJECT_LOADING_PDF]);
  const loadingPdf = isGenerationPdf ? getters[SET_DESIGN_PROJECT_LOADING_PDF] : {};
  const title =  loadingPdf.processing ? translations.t('gallery.pdf.loading') : '';
  const text =  loadingPdf.text ? translations.t(`${loadingPdf.text}`) : '';

  if (isGenerationPdf) {
    return (
      <View bg={rgba(Colors.night, 0.9)}>
        <Content>
          <Status>{translations.t('gallery.pdf.status.generation')}</Status>
          <Title>{title}</Title>
          <Text>{text}...</Text>
        </Content>
      </View>
    )
  }

  return (
    <View>
      <Preloader width="70px" height="70px" fill={Colors.planoplan_blue} />
    </View>
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ bg }) => bg || Colors.night};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
const Content = styled.div`
  padding: 30px;
  min-width: 410px;
  background-color: ${Colors.night};
  border: 1px solid ${Colors.coal};
  color: ${Colors.white};
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  border-radius: 4px;
}
`;
const Status = styled.div`
  font-size: 12px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  color: ${Colors.white};
  background-color: #FFB540;
  border-radius: 10px;
  padding: 0 6px;
  margin-bottom: 12px;
`;
const Title = styled.div`
  margin-bottom: 14px;
`;
const Text = styled.div``;


