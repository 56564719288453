import {
  SET_GOODS,
  SET_TARIFF,
  SET_USER,
  SET_TEAMS,
  SET_USER_SETTINGS,
  SET_IS_PROCESSING,
  SET_ERROR,
  SET_CURRENT_TEAM,
  SET_NOTIFICATIONS,
  SET_VIEW,
} from '../../constants';

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_VIEW:
      return { ...state, view: action.payload };
    case SET_TARIFF:
      return { ...state, tariff: action.payload };
    case SET_GOODS:
      return { ...state, goods: action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_TEAMS:
      return { ...state, teams: action.payload };
    case SET_USER_SETTINGS:
      return { ...state, userSettings: action.payload };
    case SET_CURRENT_TEAM:
      return { ...state, currentTeam: action.payload };
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case SET_IS_PROCESSING:
      return { ...state, isProcessing: action.payload };
    case SET_ERROR:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};
