import React from 'react';

export const IconProfile = ({ width = 24, height = 24, fill = '#4F5B60' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10.5" stroke={fill}/>
    <circle cx="12" cy="12" r="10.5" stroke={fill}/>
    <path d="M4 18.8512C5.79179 16.516 8.68276 15 11.9441 15C15.2741 15 18.2181 16.5806 20 19" stroke={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 5.07227C10.3825 5.07227 9.07129 6.38343 9.07129 8.00084C9.07129 9.61824 10.3825 10.9294 11.9999 10.9294C13.6173 10.9294 14.9284 9.61824 14.9284 8.00084C14.9284 6.38343 13.6173 5.07227 11.9999 5.07227ZM8.07129 8.00084C8.07129 5.83115 9.83017 4.07227 11.9999 4.07227C14.1696 4.07227 15.9284 5.83115 15.9284 8.00084C15.9284 10.1705 14.1696 11.9294 11.9999 11.9294C9.83017 11.9294 8.07129 10.1705 8.07129 8.00084Z" fill={fill}/>
  </svg>
);
