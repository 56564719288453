import { ApiCall } from '../../modules/api-call';
import * as getData from '../../modules/getdata';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import * as mainEffects from '../../modules/effects';

export const checkFileByFormat = (file, accept) => async (dispatch) => {
  const formats = accept.replace(/[.\s]/g, '').split(',');
  const format = file.type.split('+')[0].split('/')[1];
  const res = formats.indexOf(format) !== -1;

  return res;
};

export const editUser = (data) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.editUser(data);

    if (!response || response.status !== 200) {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'));
      return;
    }

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.editProfile.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'))
    }

    dispatch(getData.getProfile());
    dispatch(getData.getSubscriptions());
  } catch(error) {
    console.error()
  }
};

export const editTeam = (data) => async (dispatch, getState) => {
  try {
    const response = await ApiCall.editTeam(data);

    if (!response || response.status !== 200) {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'));
      return;
    }

    if (response.data.success) {
      notifications.showSuccess(translations.t('cabinet.notification.editProfile.success'));
    } else {
      notifications.showError(translations.t('cabinet.notification.editProfile.error'))
    }

    await dispatch(getData.getTeams());
    dispatch(mainEffects.setCurrentTeam());
  } catch(error) {
    console.error()
  }
};
