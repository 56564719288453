import React from 'react';

export const IconHistory = ({ width = 24, height = 24, fill = '#4F5B60' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6H21" stroke={fill} strokeLinejoin="round"/>
    <path d="M8 12H21" stroke={fill} strokeLinejoin="round"/>
    <path d="M8 18H21" stroke={fill} strokeLinejoin="round"/>
    <rect x="3" y="5" width="2" height="2" fill={fill}/>
    <rect x="3" y="11" width="2" height="2" fill={fill}/>
    <rect x="3" y="17" width="2" height="2" fill={fill}/>
  </svg>
);
