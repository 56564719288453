import { request } from '@libs';

const getData = async (urls, params) => {
  try {
    const response = await request.call_auth('post', urls, params);
    const { data } = response;

    if (!Object.values(data).length) {
      throw new Error('server');
    }

    if (!data || typeof data !== 'object') {
      throw new Error('login');
    }

    if (!data.success && data.errorText) {
      throw new Error(data.errorText);
    }

    return data;
  } catch (e) {
    if (e === 'NOT_TOKENS_FOR_REQUEST') {
      throw new Error('login');
    } else {
      console.error('getData', e);
      throw new Error('server');
    }
  }
};

export const ApiCall = {
  locale: 'en',
  team_id: '',

  setSettings({ locale, team_id }) {
    this.locale = locale;
    this.team_id = team_id;
  },

  getGallery({ params }) {
    const urls = { local: '/json/gallery/gallery.json', prod: `/${this.locale}/projects/getScreenshots/` };

    return getData(urls, params);
  },

  getGalleryUpdate({ params }) {
    const urls = { local: '/json/gallery/gallery_update.json', prod: `/${this.locale}/projects/getScreenshots/` };

    return getData(urls, params);
  },

  getUserSettings() {
    const params = { 'team_id': this.team_id };
    const path = this.team_id ? `teams` : `users`;
    const urls = { local: '/json/gallery/user_settings.json', prod: `/${this.locale}/${path}/getsettings/` };

    return getData(urls, params);
  },

  getZipImages({ params }) {
    // c locale - возвращает 404
    const settings = {
      responseType: 'arraybuffer',
    };
    const urls = { local: '/', prod: `/projects/exportScreenshots/` };

    return request.call_auth('post', urls, params, settings);
  },

  removeImage({ params }) {
    const urls = { local: '/json/gallery/remove.json', prod: `/${this.locale}/projects/deleteScreenshots/` };

    return request.call_auth('post', urls, params);
  },

  getProfile() {
    const urls = { local: '/json/gallery/profile.json', prod: `/${this.locale}/users/profile/` };

    return request.call_auth('get', urls);
  },
  editImage({ params }) {
    const urls = { local: '/json/gallery/gallery_edit.json', prod: `/${this.locale}/projects/editScreenshot/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },
  getGoods() {
    const urls = { local: '/json/gallery/getgoods.json', prod: `/${this.locale}/users/getgoods/?team_id=${this.team_id}` };
    const settings = { useFormData: true };

    return request.call_auth('get', urls, { team_id: this.team_id }, settings);
  },
  checkProduct({ clientId, appId, goodsId, projectId }) {
    const urls = { local: '/json/gallery/checkProduct.json', prod: `/${this.locale}/projects/checkProduct/` };
    const settings = { useFormData: true };
    const data = {
      act:  'uploadRender',
      app_id:  appId,
      fingerprint:  clientId,
      goodsId: goodsId,
      id: projectId
    };

    return request.call_auth('post', urls, data, settings);
  },
  getBindingCards() {
    const urls = { local: '/json/gallery/binding.json', prod: `/${this.locale}/users/binding/` };
    const settings = { useFormData: true };

    return request.call_auth('get', urls, {}, settings);
  },
  pay(params) {
    const return_url = encodeURIComponent(`${window.location.href.split('?')[0]}?orderid=%ORDER_ID%&store=1&locale=${this.locale}`);
    const urls = { local: '/json/gallery/pay.json', prod: `/${this.locale}/store/pay/` };
    const data = {
      mode: 'card',
      'amount[]': 1,
      team_id: 0,
      platform: 'standalone',
      return_url,
      ...params,
    };

    return request.call_auth('post', urls, data);
  },
  checkPay(params) {
    const urls = { local: '/json/gallery/check-pay.json', prod: `/${this.locale}/store/check/` };
    const settings = { useFormData: true };

    return request.call_auth('post', urls, params, settings);
  },
  payProduct({ appId, clientId, goodsId, projectId, screenshotId, resolution }) {
    const urls = { local: '/json/gallery/pay-product.json', prod: `/${this.locale}/projects/payProduct/` };
    const settings = { useFormData: true };
    const data = {
      act:  'uploadRender',
      app_id:  appId,
      fingerprint:  clientId,
      goodsId: goodsId,
      id: projectId,
      screenshotId: screenshotId,
      'stat[quality]':  'main',
      'stat[resolution]':  resolution,
    };

    return request.call_auth('post', urls, data, settings);
  },
};
