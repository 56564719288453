import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Close } from './components/Close';
import { Layout } from './components/Layout';
import { laptop } from './modules/breakpoints';
import { isProdHost } from '@libs';
import { LOADER_DEV_IFRAME , LOADER_PROD_IFRAME } from './constants';

export const App = ({ onClose, locale }) => (
  <Container id="loader_container">
    <Wrapper>
      <Content>
        <CloseIcon>
          <Close width="38px" height="38px" onClick={() => onClose()} />
        </CloseIcon>
        <Layout
          link={isProdHost ? LOADER_PROD_IFRAME : LOADER_DEV_IFRAME}
          locale={locale}
        />
      </Content>
    </Wrapper>
    <div id="loader_notifications" />
  </Container>
);

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  background-color: ${rgba(Colors.black, 0.4)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10000;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
  overflow: hidden;
  width: 90%;
  height: 90%;

  @media ${laptop} {
    ${Grid.row()};
    overflow: visible;
  }
`;

const CloseIcon = styled.div`
  display: none;
  position: absolute;
  right: -20px;
  transform: translateX(100%);

  @media ${laptop} {
    display: block;
  }
`;
