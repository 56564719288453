import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { ITrash2 } from 'planoplan-ui-kit/core/icons/i-trash-2';
import { IconDisable } from './icon-disable';

export const TableActions = ({ onBlockUser = () => {}, onRemoveUser = () => {}}) => {
  return (
    <View>
      <Action onClick={onBlockUser}>
        <IconDisable />
        <Tooltip>
          {translations.t('cabinet.members.block_user')}
        </Tooltip>
      </Action>

      <Action onClick={onRemoveUser}>
        <ITrash2 width="20" height="20" />
        <Tooltip>
          {translations.t('cabinet.members.remove_from_team')}
        </Tooltip>
      </Action>
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
`;
const Tooltip = styled.div`
  ${Mixins.transition('opacity')};
  padding: 16px;
  border-radius: 4px;
  background-color: ${Colors.white};
  position: absolute;
  top: 100%;
  right: 0;
  white-space: nowrap;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
`;
const Action = styled.div`
  position: relative;
  
  &:not(:last-child) {
    margin-right: 3px;
  }
  
  svg {
    ${Mixins.transition('fill')};
    fill: ${Colors.feldgrau25};
  }
  
  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;    
    }
    
    svg {
      fill: ${Colors.orange};
    }
  }
`;

