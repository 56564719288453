import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IPlay } from 'planoplan-ui-kit/core/icons/i-play';
import { IArrowLeftBack } from 'planoplan-ui-kit/core/icons/i-arrow-left-back';

export const PlayButton = ({ text = '', icon = 'play', onClick = () => {} }) => {
  const getIconByAlias = (alias) => {
    switch (alias) {
      case 'play': return IPlay;
      case 'back': return IArrowLeftBack;
    }
  };
  const IconSvg = getIconByAlias(icon);

  return (
    <View onClick={onClick}>
      <Icon icon={icon}>
        <IconSvg fill={Colors.white} />
      </Icon>
      <Text>{text}</Text>
    </View>
  )
};

const View = styled.div`
  ${Mixins.transition('color')};
  width: 100px;
  height: 120px;
  border-radius: 10px;
  background-color: ${Colors.solitude};
  padding: 20px 12px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${Colors.electric_blue};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  
  &:after {
    ${Mixins.transition('transform')};
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 48px;
    border-radius: 25px;
    background-color: ${Colors.electric_blue};
  }
  
  &:hover {
    color: ${Colors.white};
    
    &:after {
      transform: translateX(-50%) scale(4);
    }
  }
`;
const Icon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${Colors.electric_blue};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  
  svg {
    position: relative;
    left: ${({ icon }) => icon === 'play' ? '2px': '0'};
  }
`;
const Text = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 10px;
  font-size: 10px;
  line-height: 12px;
`;
