import React, { useState, useEffect } from 'react';
import { Link } from '@components/link';
import { actions } from '@globalInvoke';
import { MODE_ALIAS, DEMO, RESUME_TRACK, PAUSE_TRACK } from '@globalConstants';
import { translations } from '@libs';
import { UrlButton } from '@components';
import { EMAIL_REGEXP, ENTRY_STEPS, ERROR_TYPES } from '../../constants';
import { Header, Form, Field, Description, ButtonAction, Social, InputField, Error } from '../../components';
import { analytics } from '../../modules/analytics';

export const SignUp = ({
  setStep,
  onSignUp,
  onVK,
  onGoogle,
  isProcessing = false,
  error = {},
  settings = {},
  onClearError = () => {},
  country,
}) => {
  const [fields, setFields] = useState({
    username: {
      value: '',
      isValid: false,
      error: '',
    },
    password: {
      value: '',
      isValid: false,
      error: '',
    },
  });

  const [isValid, setIsValid] = useState(false);
  const { mode, locale } = settings;
  const isDemo = MODE_ALIAS[mode] === DEMO;
  const isError = error.type === ERROR_TYPES.LOCAL;

  const onChange = ({ name, value, error }) => setFields({ ...fields, [name]: { ...fields[name], value, error, isValid: !error } });
  const onSubmit = (event) => {
    event.preventDefault();

    if (isValid) {
      onSignUp(fields.username.value, fields.password.value);
    }
  };

  useEffect(() => {
    if (error.message) {
      onClearError();
    }

    const isError = Object.values(fields).find((item) => !item.isValid);

    return isError || fields.password.value.length < 5 ? setIsValid(false) : setIsValid(true);
  }, [fields]);

  useEffect(() => {
    window.invokeEditorAction({
      name: actions[PAUSE_TRACK].name,
    });

    analytics.pushView('Форма регистрации', 'sign-up', 'view', '');

    return () => {
      window.invokeEditorAction({
        name: actions[RESUME_TRACK].name,
      });
    }
  }, []);

  return (
    <>
      <Header title={translations.t('form_entry.signup.title')}>
        {!isDemo && (
          <Link size="S" onClick={() => setStep(ENTRY_STEPS.LOGIN)} data-test="button_login">
            {translations.t('form_entry.login.title')}
          </Link>
        )}
      </Header>

      <Form onSubmit={onSubmit} isInner={false}>
        <Field>
          <InputField
            placeholder={translations.t('form_entry.email.placeholder')}
            type="email"
            name="username"
            value={fields.username.value}
            hasError={fields.username.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              regexp: RegExp(EMAIL_REGEXP),
              errorValid: translations.t('form_entry.email.notvalid'),
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            autoComplete="username"
            required>
            Email
          </InputField>
        </Field>

        <Field>
          <InputField
            placeholder={translations.t('form_entry.password.new')}
            type="password"
            name="password"
            value={fields.password.value}
            hasError={fields.password.error}
            onChange={onChange}
            onBlur={onChange}
            validation={{
              errorEmpty: translations.t('form_entry.email.empty'),
            }}
            complexityPass={
              !isError && {
                easy: translations.t('form_entry.password.easy'),
              }
            }
            autoComplete="new-password"
            isEye
            required>
            {translations.t('form_entry.password.title')}
          </InputField>
        </Field>

        {isError && <Error>{error.message}</Error>}

        <ButtonAction
          type="submit"
          loading={isProcessing}
          disabled={!isValid}
          data-test="signup_submit"
          useButtonLoader>
          {translations.t('form_entry.signup.action')}
        </ButtonAction>
      </Form>

      <Social title={translations.t('form_entry.signup.actionSocial')}
              onVK={onVK}
              onGoogle={onGoogle}
              country={country}
              locale={locale}
              mode={mode}
              type="signup"/>

      <Description>
        {country === 'RU' || country === null || country === '' ? (
          <span>{translations.t('form_entry.agreement.title_without_fb')}</span>
        ) : (
          <span>{translations.t('form_entry.agreement.title')}</span>
        )}

        <UrlButton mode={mode} locale={locale} href="/agreements/" target="_blank" tag="a">
          <Link fontSize="inherit"> {translations.t('form_entry.agreement.user')} </Link>
        </UrlButton>

        <span>{translations.t('form_entry.recaptcha.and')}</span>

        <UrlButton mode={mode} locale={locale} href="/privacy-policy/" target="_blank" tag="a">
          <Link fontSize="inherit"> {translations.t('form_entry.agreement.policy')}. </Link>
        </UrlButton>

        <span> {translations.t('form_entry.recaptcha.title')}&nbsp;</span>

        <UrlButton mode={mode} href="https://policies.google.com/privacy" target="_blank" tag="a">
          <Link fontSize="inherit">{translations.t('form_entry.recaptcha.policy')}</Link>
        </UrlButton>

        <span>&nbsp;{translations.t('form_entry.recaptcha.and')}&nbsp;</span>

        <UrlButton mode={mode} href="https://policies.google.com/terms" target="_blank" tag="a">
          <Link fontSize="inherit">{translations.t('form_entry.recaptcha.terms')}.</Link>
        </UrlButton>
      </Description>
    </>
  );
};
