import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ButtonAdd } from './button-add';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ON_OPEN_STORE } from '@observer/constants';

export const Item = ({ caption, view_name, view_size, view_disabled, count, store_id, not_for_sale }) => {
  const observer = new window.POPObserver();
  const state = useSelector(state => state);
  const { team } = state;

  const openStore = () => {
    observer.postMessage(ON_OPEN_STORE, {
      initialScreen: 'cart',
      initialCart: {
        [store_id]: 1,
      },
    });
  };

  return (
    <ItemView disabled={view_disabled}>
      <Title disabled={view_disabled}>
        {view_name ? view_name : caption}
        <Description>{view_size}</Description>
      </Title>

      <Count disabled={view_disabled}>{count}</Count>

      {!not_for_sale && !Boolean(team.id) &&
        <Add>
          <ButtonAdd onClick={openStore} disabled={view_disabled} />
        </Add>
      }
    </ItemView>
  );
};

const ItemView = styled.tr`
  ${Mixins.transition('background-color')};
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.feldgrau)};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
`;
export const TD = styled.td`
  padding-top: 20px;
  vertical-align: middle;
`;
const Title = styled(TD)`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-left: 0px;
  padding-right: 15px;
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.feldgrau)};
  width: 240px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Count = styled(TD)`
  padding: 20px 50px 0 34px;
  color: ${(p) => (p.disabled ? Colors.solitude : Colors.feldgrau)};
  font-size: 20px;
`;
const Add = styled(TD)`
  padding-right: 0;
`;
const Description = styled.div`
  color: ${Colors.feldgrau50};
  font-size: 12px;
`;
