import React from 'react';
import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const Loader = ({ overlay, title, text }) => (
  <View overlay={overlay}>
    {title && <Text>{title}</Text>}
    {
      text && <Text>
        {text}
        <Dots>
          <Dot item={1}>.</Dot>
          <Dot item={2}>.</Dot>
          <Dot item={3}>.</Dot>
        </Dots>
      </Text>
    }
    <Preloader width="70px" height="70px" fill={Colors.planoplan_blue} />
  </View>
);

const View = styled.div`
  ${Mixins.transition('background-color')};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: ${(p) => (p.overlay ? rgba(Colors.black, 0.4) : 'transparent')};
  z-index: 100;
  pointer-events: none;
`;

const Text = styled.div`
  color: ${Colors.dark_cream};
  margin-bottom: 100px;
  margin-top: -100px;
  font-weight: 300;
  max-width: 400px;
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
`;

const Dots = styled.div`
  display: inline-flex;
  margin-left: 2px;
`;

const bounce = keyframes`
  0% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(0px);
  }
  90% {
      transform: translateY(-2px);
  }
  100% {
      transform: translateY(0px);
  }
`;

const Dot = styled.span`
  animation: ${bounce} 1.5s linear infinite;
  animation-delay: ${({item}) => `${item}00ms`};
`;


