import { createBreakpoints } from '@libs';

export const points = {
  mobile: 0,
  tablet_landscape: 1024,
  laptop: 1280,
  laptop_wide: 1440,
  full_hd: 1600,
  full_hd_second: 1690,
};
const pointsArray = [
  { mobile: points.mobile, },
  { tablet_landscape: points.tablet_landscape, },
  { laptop: points.laptop, },
  { laptop_wide: points.laptop_wide, },
  { full_hd: points.full_hd, },
  { full_hd_second: points.full_hd_second, },
];

export const {
  mobile,
  tablet_landscape,
  laptop,
  laptop_wide,
  laptop_wide_blank,
  full_hd,
  full_hd_second,
  full_hd_second_blank,
} = createBreakpoints(pointsArray);

