import React, { useState, useEffect } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { themes } from './themes';

export const Input = React.forwardRef(
  (
    {
      id,
      name,
      type,
      placeholder,
      value,
      required,
      onChange,
      onBlur = () => {},
      onFocus = () => {},
      onKeyPress = () => {},
      disabled,
      children,
      hasError,
      theme = 'primary',
      size = 'M',
      validation = {},
      trigger = 0,
      isEye = false,
      complexityPass,
      device = {},
      isProfile = false,
      ...rest
    },
    ref
  ) => {
    const { maxLength = 0, errorValid, errorEmpty, regexp = '' } = validation;
    const [modifiedType, setModifiedType] = useState(type);
    const [error, setError] = useState(hasError);
    const [firstChange, setFirstChange] = useState(true);
    const [complexityPassword, setComplexityPassword] = useState({ message: '', value: 0 });

    // проверка на пустоту
    const checkRequired = (value) => (required && value.length ? '' : errorEmpty);

    // проверка по регулярке
    const checkRegexp = (value) => {
      if (regexp) {
        return regexp.test(value) ? '' : errorValid;
      } else {
        return '';
      }
    };

    // проверка сложности пароля
    const checkComplexityPassword = (value) => {
      if (!complexityPass) {
        return;
      }

      if (!value) {
        setComplexityPassword({
          message: '',
          value: 0,
        });
      }
    };

    // установка макс длины, если передан такой параметр
    const setMaxLengthValue = (value) => (maxLength ? value.substring(0, maxLength) : value);

    // запуск валидации
    const setValidation = (value) => {
      const error = checkRequired(value) || checkRegexp(value);

      if (!firstChange) {
        setError(error);
      }

      return error;
    };

    const onStartChange = (event) => onFocus(event);

    const onEndChange = (event) => {
      const { name, value } = event.target;

      setFirstChange(false);

      onBlur({
        name,
        value,
        error: setValidation(value),
        isValid: !setValidation(value),
        firstChange,
        event,
      });
    };

    const onInput = (event) => {
      const { name, value } = event.target;
      const maxLengthValue = setMaxLengthValue(value);

      onChange({
        name,
        value: maxLengthValue,
        error: !firstChange ? setValidation(maxLengthValue) : '',
        isValid: !setValidation(maxLengthValue),
        firstChange,
        event,
      });

      checkComplexityPassword(value);
    };

    const onKeyChange = (event) => {
      onKeyPress({
        name,
        value,
        event,
      });
    };

    const onChangeType = () => setModifiedType(modifiedType === 'password' ? 'text' : 'password');

    useEffect(() => {
      if (trigger) {
        onChange({
          name,
          value,
          error: setValidation(value),
          isValid: !setValidation(value),
          firstChange,
          event: 'trigger',
        });
      }
    }, [trigger]);

    useEffect(() => {
      setError(hasError);
    }, [hasError]);

    return (
      <ThemeProvider theme={{ theme: themes.theme[theme], size: themes.size[size] }}>
        <InputView>
          {children && <Label>{children}</Label>}
          <Wrapper>
            <InputHtml
              id={id}
              name={name}
              type={modifiedType}
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              required={required}
              onChange={onInput}
              onFocus={onStartChange}
              onBlur={onEndChange}
              onKeyPress={onKeyChange}
              error={error}
              eye={isEye}
              isProfile={isProfile}
              ref={ref}
              {...rest}
            />
          </Wrapper>

          {(error || complexityPass) && (
            <Message>
              {error && <Error>{error}</Error>}
              {complexityPass && <Notice>{complexityPassword.message}</Notice>}
            </Message>
          )}
        </InputView>
      </ThemeProvider>
    );
  }
);

export const InputView = styled.label`
  color: ${(p) => p.theme.theme.color};
  font-size: ${(p) => p.theme.size.view.fontSize};
  line-height: ${(p) => p.theme.size.view.lineHeight};
`;

const Label = styled.div`
  margin-bottom: ${(p) => p.theme.size.label.marginBottom};
  margin-left: 4px;
  color: ${Colors.feldgrau};
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
`;

export const InputHtml = styled.input`
  ${Mixins.transition('border-color, box-shadow, background-color')};
  padding-right: ${({ isProfile }) => isProfile ? '16px' : '105px'};
  padding-left: ${(p) => p.theme.size.input.paddingLeft};
  background-color: ${(p) => p.theme.theme.input.backgroundColor};
  border-radius: 5px;
  border: none;
  font-weight: 300;
  font-size: inherit;
  line-height: inherit;
  color: ${Colors.coal};
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-family: inherit;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  box-shadow: none;
  height: 40px;
  width: ${(p) => p.isProfile ? '320px' : '400px'};
  background: ${Colors.timberwolf50};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  border: 1px solid ${Colors.light_gray};

  &:focus {
    border: 1px solid ${Colors.planoplan_blue};
    background: ${Colors.timberwolf25};
    box-shadow: none;
  }

  &:not(:disabled):hover {
    border: 1px solid ${Colors.planoplan_blue};
    background: ${Colors.timberwolf25};
  }

  &::placeholder {
    color: ${Colors.feldgrau50};
    font-size: 16px;
  }

  &::placeholder {
    font-size: 16px;
    color: ${Colors.feldgrau50};
  }

  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: ${Colors.nobel};
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  
  &:disabled {
    color: ${Colors.feldgrau50};
  }
`;

const Message = styled.div`
  font-size: ${(p) => p.theme.size.message.fontSize};
  line-height: ${(p) => p.theme.size.message.lineHeight};
  margin-top: ${(p) => p.theme.size.message.marginTop};
  margin-left: 4px;
  font-weight: 300;
  position: ${(p) => !p.theme.isMobile && 'absolute'};
`;

const Error = styled.div`
  color: ${Colors.colar};
`;

const Notice = styled.div`
  color: ${Colors.coal};
`;
