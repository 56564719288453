import React from 'react';
import styled from 'styled-components';
import { IErrorCircle } from 'planoplan-ui-kit/core/icons/i-error-circle';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Indent } from '../../modules/indent';
import { tablet_portrait } from '../../modules/breakpoints';

export const Message = ({ children }) => (
  <View>
    <Text>{children}</Text>

   <IErrorCircle width="228px" height="228px" stroke={Colors.colar} />
  </View>
);

const View = styled.div`
  ${Indent.padding_top(3)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    ${Indent.margin_top(3)};
  }
`;

const Text = styled.div`
  font-size: 22px;
  line-height: 26px;
  color: ${Colors.coal};
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  @media ${tablet_portrait} {
    font-size: 24px;
    line-height: 28px;
  }
`;
