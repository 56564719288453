import React from 'react';

export const IInfo = ({ width = 16, height = 16, fill = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#D1D1D1" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 5H8.00769" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.00391 11.5V7.5" stroke="#D1D1D1" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
