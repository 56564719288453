import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IChevron } from '../../icons/chevron';

export const BeforeAfterSlider = ({
  zoom = 1,
  Before,
  After,
  beforeImage,
  afterImage
}) => {
  const [inset, setInset] = useState(false);
  const [position, setPosition] = useState(50);

  const handleMouseLeave = () => {
    setPosition(50);
    setInset(false);
  };
  const handleMouseEnter = () => {
    setInset(true);
  };

  const handleMouseMove = (e) => {
    const slider = e.target;
    const rect = slider.getBoundingClientRect();
    const x = e.clientX - rect.left * zoom;
    const newPosition = Math.max(0, Math.min(100, (x / (rect.width * zoom)) * 100));

    setPosition(newPosition);
  };

  return (
    <View onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
      <ImageContainer>
        <AfterImage clip={position} inset={inset}>
          {After ? <After /> : <img src={afterImage} alt="After" />}
        </AfterImage>
        <BeforeImage clip={position} inset={inset}>
          {Before ? <Before /> : <img src={beforeImage} alt="Before" />}
        </BeforeImage>
      </ImageContainer>

      <Slider inset={inset} style={{ left: `${position}%` }} >
        <SliderDot>
          <IChevron/>
          <IChevron/>
        </SliderDot>
      </Slider>
    </View>
  );
};

const View = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;
const ImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const AfterImage = styled(ImageWrap)`
`;
const BeforeImage = styled(ImageWrap)`
  transition: ${({ inset }) => inset ? 'none' : 'all .2s linear'};
  clip-path: ${({ clip }) => `inset(0 ${100-clip}% 0 0)`};
`;

const Slider = styled.div`
  transition: ${({ inset }) => inset ? 'none' : 'all .2s linear'};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: ${Colors.colar};
  cursor: pointer;
  pointer-events: none;
`;
const SliderDot = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    background-color: ${Colors.colar};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg:first-child {
      margin-right: 4px;
    }
    
    svg:nth-child(2){
      transform: rotate(180deg);
    }
`;
