import React  from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { laptop } from '../../modules/breakpoints';
import { rail_y, thumb_y } from '../scroll';
import { actionsData } from './actions-data';
import { Action } from '../Action';
import { Flat } from '../Flat';
import { translations } from '@libs';

export const Creater = () => {
  const state = useSelector(state => state);
  const { projects } = state;

  return (
      <View>
        <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
          <Content>
            <Top>
              {actionsData.map((action, index) => (
                  <Item key={index}>
                    <Action Icon={action.icon} title={translations.t(action.title)} background={action.background} alias={action.alias}/>
                  </Item>
              ))}
            </Top>

            <Wrapper>
              {projects.map((project) => (
                  <Item key={project.id}>
                    <Flat id={project.form_data['id_project']} image={project.form_data.image} title={project.form_data.name}/>
                  </Item>
              ))}
            </Wrapper>
          </Content>
        </ScrollBar>
      </View>
  );
};

const View = styled.div`
  display: flex;
  overflow: hidden;

  @media ${laptop} {
    ${Grid.column(22, 30)};
    ${Grid.suffix(0.2, 30)};
    height: 660px;
    flex-direction: column;
  }
`;

const Content = styled.div`
  padding: 39px 0;

  @media ${laptop} {
    ${Grid.prefix(0.5, 22)};
    ${Grid.suffix(0.8, 22)};
  }
`;

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px -8px 8px -8px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
`;

const Item = styled.div`
  ${Grid.column(1, 3)};
  padding: 8px;
`;
