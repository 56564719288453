import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IAddProject } from 'planoplan-ui-kit/core/icons/i-add-project';
import { IUser } from 'planoplan-ui-kit/core/icons/i-user';
import { ILogout } from 'planoplan-ui-kit/core/icons/i-logout';
import { IAlertCircle } from 'planoplan-ui-kit/core/icons/i-alert-circle';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { getImage } from 'planoplan-ui-kit/core/get_image';
import { UrlButton } from '@components';
import { translations } from '@libs';
import { ERROR_TYPES } from '../constants';
import { rail_y, thumb_y } from './scroll';

export const Profile = ({
  user = {},
  teams = [],
  currentTeam = '',
  errors = {},
  mode = 'site',
  locale = 'en',
  onClickButton = () => {},
  onLogout = () => {},
  isTeam = false,
}) => {
  const [teamsHeight, setTeamsHeight] = useState(0);
  const viewRef = useRef(null);
  const userRef = useRef(null);
  const teamsListRef = useRef(null);
  const bottomRef = useRef(null);

  const calcTeamsHeight = () => {
    if (!viewRef.current || !userRef.current || !bottomRef.current) {
      return;
    }

    const teamsListItems = Array.from(teamsListRef.current.querySelectorAll('button'));
    const teamsListItemsHeight = teamsListItems.reduce((acc, current) => acc + current.offsetHeight, 0);
    const windowHeight = window.innerHeight;
    const offsetTop = viewRef.current.getBoundingClientRect().top;
    const bottomHeight = bottomRef.current.offsetHeight;
    const userHeight = userRef.current.offsetHeight;
    const minHeight = 48;

    // высота кнопок команды + отступ сверху + высоста нижнего блока и блока юзера
    const limit = teamsListItemsHeight + offsetTop + bottomHeight + userHeight;

    if (limit > windowHeight) {
      const result = windowHeight - offsetTop - userHeight - bottomHeight;

      if (result > minHeight) {
        setTeamsHeight(result);
      } else {
        setTeamsHeight(minHeight);
      }
    } else {
      setTeamsHeight(0);
    }
  };

  useEffect(() => {
    calcTeamsHeight();

    window.addEventListener('resize', calcTeamsHeight);

    return () => window.removeEventListener('resize', calcTeamsHeight);
  }, [viewRef]);

  return (
    <View ref={viewRef}>
      <User ref={userRef}>
        <Button isActive={!isTeam} onClick={() => onClickButton()}>
          <Text>{user.name}</Text>
          <Icon cover={user.user.avatar || getImage('icons/ava-user.png')} />
          {errors[ERROR_TYPES.USER] && (
            <ErrorUser>
              <IAlertCircle width="22px" height="22px" fill={Colors.colar} />
            </ErrorUser>
          )}
        </Button>
      </User>

      <Teams ref={teamsListRef} isBorder={!!teams.length} height={teamsHeight}>
        <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
          {teams.map((item) => (
            <Button isActive={item.id === currentTeam} key={item.id} onClick={() => onClickButton(item.id)}>
              <Text>{item.name}</Text>
              <Icon cover={item.avatar || getImage('icons/ava-team.png')} />
            </Button>
          ))}

          {errors[ERROR_TYPES.TEAMS] && (
            <ErrorTeams>
              {translations.t('user_widget.teams.error')}
              <IAlertCircle width="22px" height="22px" fill={Colors.colar} />
            </ErrorTeams>
          )}
        </ScrollBar>
      </Teams>

      <Bottom ref={bottomRef}>
        <AddTeam>
          <UrlButton mode={mode} locale={locale} href="/cabinet/tariff-managment/?tariff=4">
            <Button>
              {translations.t('user_widget.addTeam')}
              <IAddProject width="20px" height="20px" fill={Colors.coal} />
            </Button>
          </UrlButton>
        </AddTeam>
        <InCabinet>
          <UrlButton mode={mode} locale={locale} href="/cabinet">
            <Button>
              {translations.t('user_widget.toCabinet')}
              <IUser width="20px" height="20px" fill={Colors.coal} />
            </Button>
          </UrlButton>
        </InCabinet>
        <Logout>
          <Button onClick={onLogout}>
            {translations.t('user_widget.logout')}
            <ILogout width="20px" height="20px" fill={Colors.coal} />
          </Button>
        </Logout>
      </Bottom>
    </View>
  );
};

const View = styled.div`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${Colors.white};
  box-shadow: 0 0 34px ${rgba(Colors.coal, 0.13)}, -1px 1px 2px ${rgba(Colors.coal, 0.2)};
  width: 200px;
`;

const User = styled.div`
  border-bottom: 1px solid ${Colors.solitude};
`;

const Teams = styled.div`
  border-bottom: ${(p) => p.isBorder && `1px solid ${Colors.solitude}`};
  height: ${(p) => (p.height ? p.height + 'px' : 'auto')};
`;

const AddTeam = styled.div``;

const InCabinet = styled.div``;

const Logout = styled.div`
  margin-top: 40px;
`;

const Button = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.resetLink()};
  ${Mixins.transition('border-color')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 18px;
  width: 100%;
  background-color: ${Colors.white};
  position: relative;
  min-height: 46px;
  border: 1px solid ${Colors.white};

  &:hover {
    border-color: ${Colors.planoplan_blue};
  }

  ${(p) =>
    p.isActive &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 6px;
        transform: translateY(-50%);
        top: 50%;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${Colors.planoplan_blue};
      }
    `}
`;

Button.displayName = 'Button';

const Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  background-image: ${(p) => `url(${p.cover})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.div`
  padding-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorUser = styled.div``;

ErrorUser.displayName = 'ErrorUser';

const ErrorTeams = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 8px 11px 18px;
  border: 1px solid ${Colors.colar};
`;

ErrorTeams.displayName = 'ErrorTeams';

const Bottom = styled.div`
  padding-bottom: 10px;
`;
