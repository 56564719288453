import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { IHelper } from '../../../icons';
import { url } from '@globalInvoke';
import { UNAUTH_URL_OPEN } from '@globalConstants';
import { rail_y, thumb_y } from '../../../components/scroll';

export const Last = ({ slide = {} }) => {
  const info = useMemo(() => {
    try {
      return JSON.parse(slide.info);
    } catch (error) {
      console.error(error);

      return {};
    }
  }, []);

  const openLink = (event) => {
    event.preventDefault();

    window.invokeEditorAction({
      name: url[UNAUTH_URL_OPEN].name,
      value: event.target.href,
    });
  };

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
    <View>
        <Top>
          <Title>{info.title}</Title>

          <Links>
            <Wrap>
              <Caption>{info?.left?.title}</Caption>
              {info?.left?.items?.map((item, index) => (
                <LinkWrap key={index}><Link href={item.link} onClick={openLink}>{item.name}</Link></LinkWrap>
              ))}
            </Wrap>

            <Wrap>
              <Caption>{info?.right?.title}</Caption>
              {info?.right?.items?.map((item, index) => (
                <LinkWrap key={index}><Link href={item.link} onClick={openLink}>{item.name}</Link></LinkWrap>
              ))}
            </Wrap>
          </Links>
        </Top>

        <CenterWrap>
          <Caption>{info?.center?.title}</Caption>
          <Image src={slide.image}/>
          <Helper><HelperText>{info?.center?.caption}</HelperText> <IHelper /></Helper>
        </CenterWrap>
    </View>
    </ScrollBar>

  );
};

const View = styled.div`
  color: ${Colors.light_gray};
  padding: 20px 35px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const Top = styled.div`
  margin-bottom: 20px;
`;
const Title = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 40px;
  font-family: 'Montserrat', sans-serif;
`;
const Links = styled.div`
  display: flex;
  justify-content: center;
`;
const Wrap = styled.div`
  &:first-child {
    margin-right: 50px;
  }
`;
const Caption = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 10px;
`;
const LinkWrap = styled.div`
  margin-bottom: 10px;
`;
const Link = styled.a`
  color: ${Colors.light_gray};
  text-decoration: none;
  
  &:hover {
    color: ${Colors.dark_cream};
  }
`;
const CenterWrap = styled.div`
  text-align: center;
`;
const Image = styled.img`
  max-width: 530px;
  max-height: 300px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const Helper = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HelperText = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-right: 10px;
`;
