import { ON_CHANGE_PAYMENT_STATUS_STORE } from '@observer/constants';
import {
  SET_SCREEN,
  SET_PRODUCT_SCREEN,
  SET_AVAILABLE_SCREENS,
  ADD_TO_CART,
  REMOVE_TO_CART,
  SET_PRODUCTS,
  SET_PRICING,
  SET_PROFILE,
  SET_BINDING_CARD,
  SET_PROFILE_GOODS,
  CHANGE_COUNT_PRODUCT,
  CHANGE_PRICE_FOR_SET,
  SET_PAYMENT_STATUS,
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_PAYMENT_METHODS,
  SET_SAVED_CART,
  SET_BANNERS,
  SET_COUNTRIES,
  SET_LAST_BUY,
  SET_PRODUCTS_REVIEW,
  SET_SUM_WITH_PROMO,
  SET_PROMO,
  SET_CHECKSUM,
  SET_WIDGET,
  SET_DESCRIPTION_TARIFF,
  SET_DESCRIPTION_STORE,
  SET_VIDEO_POPUP_SRC,
  SET_SCROLL_Y,
} from '../../constants';

const observer = new window.POPObserver();

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_SCREEN:
      return { ...state,
        screen: action.payload
      };

    case SET_WIDGET:
      return { ...state,
        screen: action.payload.screen,
        payment: {
          token: action.payload.token,
          returnUrl: action.payload.returnUrl,
          publicKey: action.payload.publicKey,
          clientSecret: action.payload.clientSecret,
          orderid: action.payload.orderid,
          price: action.payload.price,
          vat: action.payload.vat,
          vatPercent: action.payload.vatPercent,
          country: action.payload.country,
          sumPromo: action.payload.sumPromo,
          method: action.payload.method,
          paymentMethod: action.payload.paymentMethod,
          ...action.payload
        }
      };

    case SET_PRODUCT_SCREEN: {
      const { screen, screenBack, data } = action.payload;

      return {
        ...state,
        productScreen: { screen, screenBack, data },
      };
    }

    case SET_AVAILABLE_SCREENS: {
      return {
        ...state,
        availableScreens: action.payload,
      };
    }

    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case SET_PROFILE_GOODS:
      return {
        ...state,
        profileGoods: action.payload,
      };

    case SET_PRODUCTS:
      return { ...state, products: action.payload };

    case SET_PRICING:
      return { ...state, pricing: action.payload };

    case SET_BINDING_CARD:
      return { ...state, bindingCards: action.payload };

    case SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };

    case SET_SAVED_CART:
      return { ...state, savedCart: action.payload };

    case SET_CHECKSUM:
      return { ...state, checksum: action.payload };

    case SET_BANNERS:
      return { ...state, banners: action.payload };

    case SET_COUNTRIES:
      return { ...state, countries: action.payload };

    case SET_LAST_BUY:
      return { ...state, lastBuy: action.payload };

    case SET_PRODUCTS_REVIEW:
      return { ...state, productsReview: action.payload };

    case ADD_TO_CART:
      return { ...state, cart: action.payload };

    case REMOVE_TO_CART:
      return { ...state, products: action.payload.products, cart: action.payload.cart };

    case CHANGE_COUNT_PRODUCT:
      return { ...state, products: action.payload.products, sets: action.payload.sets, cart: action.payload.cart };

    case CHANGE_PRICE_FOR_SET:
      return {...state, products: action.payload.products, sets: action.payload.sets, cart: action.payload.cart};

    case SET_PROMO:
      return { ...state, promo: action.payload };

    case SET_SUM_WITH_PROMO:
      return { ...state, sumWithPromo: action.payload };

    case SET_PAYMENT_STATUS: {
      const { status, value = '', orderid = '', token = '', cart = null, method = null } = action.payload;

      if (status) {
        observer.postMessage(ON_CHANGE_PAYMENT_STATUS_STORE, status);
      }

      return {
        ...state,
        payment: {
          ...state.payment,
          status,
          value,
          orderid,
          token,
          cart,
          method,
        },
      };
    }

    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
            },
          },
          processing: true,
          error: '',
        },
      };

    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }

    case SET_DESCRIPTION_TARIFF:
      return { ...state, descriptionTariffs: action.payload };
    case SET_DESCRIPTION_STORE:
      return { ...state, descriptionStore: action.payload };
    case SET_VIDEO_POPUP_SRC:
      return { ...state, srcVideoPopup: action.payload };
    case SET_SCROLL_Y:
      return { ...state, scrollY: action.payload };

    default:
      throw new Error();
  }
};
