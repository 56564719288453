import React, { useContext, useEffect } from 'react';
import { Products } from '../../components/Products';
import { Analytics } from '../../modules/analytics';
import { StoreContext } from '../../modules/Store';
import { getAnalyticParamsRender } from '../../modules/helpers';

export const Renders = ({ products, descriptionStore }) => {
  const renders = products.filter((item) => (item.view_alias === 'render' || item.view_alias === 'panorama') && item.activeCount >= 0)
    .map((item, index) => ({
      ...item,
      analyticParams: getAnalyticParamsRender(item, index)
    }));
  const content = descriptionStore['renders'];
  const [state] = useContext(StoreContext);
  const { settings: { mode, locale }} = state;

  useEffect(() => {
    const analyticItems = renders.map(item => item.analyticParams);

    Analytics.viewRenders(mode, locale);
    Analytics.ecommerceView(analyticItems);
  }, []);

  return <Products elements={renders} content={content} />;
};
