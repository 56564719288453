import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { translations } from '@libs';
import { TextWithLinks } from '@components/TextWithLinks';

export const ErrorScreen = ({ close }) => {
  return (
    <View>
      <Close onClick={close}>
        <IClose width="38px" height="38px" fill={Colors.white} />
      </Close>

      <Content>
        <Title>{translations.t('banners.error.title')}</Title>
        <TextWithLinks tag={Text} text={translations.t('banners.error.text')} />
      </Content>
    </View>
  );
};

const View = styled.div`
  position: relative;
  width: 420px;
  background-color: ${Colors.white};
  border-radius: 10px;
`;
const Close = styled.button`
  ${Mixins.resetButton()};
  position: absolute;
  top: 0;
  right: -60px;

  svg {
    ${Mixins.transition('transform')};
  }

  &:hover {
    svg {
      transform: scale(0.9);
    }
  }
`;
const Content = styled.div`
  color: ${Colors.coal};
  padding: 38px;
  
  a {
    text-decoration: none;
    color: ${Colors.planoplan_blue};    
  }
`;
const Title = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 24px;
  margin-bottom: 24px;
`;
const Text = styled.div`
  font-size: 16px;
  line-height: 19px;
`;
