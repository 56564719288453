import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { setOptions, setSelectedOption } from '../molecules/options';
import { IViewColumn, IViewRow } from '../../../icons';
import { Select } from '@components/Form/Select';

export const Top = ({ view, onSetView, filters, current_filter, onChangeFilter }) => {
  return (
    <View>
      <DropdownWrapper>
        <Select options={setOptions(filters)} value={setSelectedOption(current_filter)} onChange={onChangeFilter} />
      </DropdownWrapper>
      <Views>
        <ColumnButton onClick={() => onSetView('column')}>
          <IViewColumn fill={view === 'column' ? Colors.nobel : Colors.gray} />
        </ColumnButton>
        <RowButton onClick={() => onSetView('row')}>
          <IViewRow fill={view === 'row' ? Colors.light_gray : Colors.gray} />
        </RowButton>
      </Views>
    </View>
  );
};

const View = styled.div`
  padding: 15px 20px 10px 15px;
  border-bottom: 1px solid ${Colors.coal};
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    ${Mixins.transition('fill')};
    display: block;
  }
`;
const DropdownWrapper = styled.div`
  width: 150px;
  margin-right: 12px;
`;
const Views = styled.div`
  display: flex;
`;
const ColumnButton = styled.div`
  margin-right: 6px;
`;
const RowButton = styled.div`
`;
