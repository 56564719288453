import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TARIFF_PERIODS } from '../../../constants';
import * as actions from '../../../modules/Store/action';
import { getPrices } from '../../../modules/helpers';

export const ChoosePeriod = memo (() => {
  const dispatch = useDispatch();
  const { selected: { period }, profile: { viewCurrency }} = useSelector(state => state);

  if (!period) {
    return (<Error>Error!</Error>)
  }

  const { pricePayMonth, pricePayYear, discountPayYear } = useMemo(() => dispatch(getPrices()));

  const setPeriod = (period) => {
    dispatch(actions.setPeriod(period));
  };

  return (
    <View>
      <Choose>
        <ChooseItem isActive={period === TARIFF_PERIODS.month} onClick={() => setPeriod(TARIFF_PERIODS.month)}>
          <Content>
            <Price>{pricePayMonth} {viewCurrency}</Price>
            <Caption>{translations.t('quickstore.per-month')}, <br/>{translations.t('quickstore.payment-month')}</Caption>
          </Content>
        </ChooseItem>

        <ChooseItem isActive={period === TARIFF_PERIODS.year} onClick={() => setPeriod(TARIFF_PERIODS.year)}>
          <Content>
            <Price>{pricePayYear} {viewCurrency}</Price>
            <Caption>{translations.t('quickstore.per-month')}, <br/>{translations.t('quickstore.payment-year')}</Caption>
            <Discount>-{discountPayYear}%</Discount>
          </Content>
        </ChooseItem>
      </Choose>
    </View>
  );
});

const View = styled.div`
`;
const Choose = styled.div`
  display: flex;
`;
const Content = styled.div`
  padding: 14px 12px;
  position: relative;
  width: 100%;
  height: 100%;
`;
const Price = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
`;
const Caption = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
`;
const Discount = styled.div`
  position: absolute;
  top: 0;
  right: 5px;
  transform: translateY(-50%);
  border: 1px solid ${Colors.night};
  border-radius: 10px;
  background-color: ${Colors.dark_cream};
  padding: 2px 6px;
  color: ${Colors.night};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
`;
const ChooseItem = styled.div`
  border: 1px solid ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  font-size: 14px;
  color: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  margin-left: -1px;
  font-weight: 300;
  cursor: pointer;
  position: ${({ isActive }) => isActive ? 'relative' : 'static'};
  text-align: center;
  flex: 1;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  
  &:last-child {
    border-radius: 0 4px 4px 0;
  }
  
  ${Discount} {
    background-color: ${({ isActive }) => isActive ? Colors.dark_cream : Colors.nobel};
  }
`;
const Error = styled.div`
  color: ${Colors.dull_red};
`;
