import { ILove } from 'planoplan-ui-kit/core/icons/i-love';
import { IPlanPaper } from 'planoplan-ui-kit/core/icons/i-plan-paper';
import { IPen } from 'planoplan-ui-kit/core/icons/i-pen';
import { BACKGROUNDS } from '../../constants';

export const actionsData = [
  {
    alias: 'favorite',
    icon: ILove,
    title: 'projects.ui.favorite',
    background: BACKGROUNDS.NONE,
  },
  {
    alias: 'upload',
    icon: IPlanPaper,
    title: 'projects.ui.upload',
    background: BACKGROUNDS.GREEN,
  },
  {
    alias: 'scratch',
    icon: IPen,
    title: 'projects.ui.scratch',
    background: BACKGROUNDS.BLUE,
  }
]
