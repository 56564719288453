import React from 'react';
import styled from 'styled-components';
import { tablet_landscape } from '../../modules/breakpoints';

export const Header = ({ title, children }) => (
  <View>
    <Title>{title}</Title>
    {children}
  </View>
);

const View = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${tablet_landscape} {
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;

  @media ${tablet_landscape} {
    font-size: 24px;
    line-height: 34px;
  }
`;
