import React from 'react';
import styled from 'styled-components';
import { LOADER_IFRAME_ID } from '../../constants';

export const Layout = ({ link, locale }) => (
	<Iframe
		src={`${link}?lang=${locale}`}
		title="Import of models"
		width="100%"
		height="100%"
		id={LOADER_IFRAME_ID}
	/>
);

const Iframe = styled.iframe`
	border-radius: 10px;
	border: 0;
`;
