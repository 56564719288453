import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TrLayout } from './trLayout';
import { ROW_TYPE } from '../constants';

export const ProjectTable = ({
  items = [],
  editListFolder = [],
  editListProject = [],
  currentHeaderMode = '',
  setFolderId = () => {},
  onOpenProject = () => {},
  openGallery = () => {},
  openLink = () => {}
}) => {
  return (
    <View>
      <Table>
        {items && items.map((item) => (
          <Tr key={item.id}>
            <TrLayout item={item}
                      editList={item.row_type === ROW_TYPE.folder ? editListFolder : editListProject}
                      currentHeaderMode={currentHeaderMode}
                      setFolderId={setFolderId}
                      onOpenProject={onOpenProject}
                      openGallery={openGallery}
                      openLink={openLink}/>
          </Tr>
        ))}
      </Table>
    </View>
  )
};

const View = styled.div`
  height: 100%;
  width: 100%;
`;
const Table = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-radius: 5px;
  font-size: 16px;
  line-height: 19px;
`;
const Tr = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.feldgrau25};
  }
`;
