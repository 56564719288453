import ReactDOM from 'react-dom';
import React from 'react';
import { App } from './app';
import { NOTIFICATION_DELAY, NOTIFICATION_TYPES } from '@globalConstants';

let instance = null;

class Notifications {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.notifications = [];

    return instance;
  }

  init(root) {
    this.root = root;
  }

  showSuccess(message) {
    this._render(NOTIFICATION_TYPES.SUCCESS, message);
  }

  showError(message) {
    this._render(NOTIFICATION_TYPES.ERROR, message);
  }

  _render(type, message) {
    const visible = this.notifications.filter((item) => item.lifeTime > Date.now());

    this.notifications = [
      ...visible,
      {
        id: Date.now(),
        lifeTime: Date.now() + NOTIFICATION_DELAY,
        type,
        message,
      },
    ];

    ReactDOM.render(<App notifications={this.notifications} />, document.getElementById(this.root));
  }
}

export const notifications = new Notifications();

if (!window.POPNotifications) {
  window.POPNotifications = Notifications;
}
