import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { DisplayPrice } from '../../../components/DisplayPrice';
import { getCurrentPrices } from '../../../modules/helpers';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';

export const StripePricing = memo(() => {
  const dispatch = useDispatch();

  const {
    priceWithoutDiscount,
    discountWithPromo,
    price,
    vat,
    priceVat
  } = useMemo(() => dispatch(getCurrentPrices()));

  return (
    <Pricing>
      {(!Boolean(discountWithPromo) && Boolean(vat)) && <PriceCaption>{translations.t('quickstore.priceWithoutDiscount')} = <DisplayPrice>{priceWithoutDiscount}</DisplayPrice></PriceCaption>}
      {(Boolean(discountWithPromo) && Boolean(vat)) && <PriceCaption>Цена со скидкой = <DisplayPrice>{price}</DisplayPrice></PriceCaption>}
      {Boolean(vat) && <PriceCaption>{translations.t('quickstore.vat')} ({vat}%) = <DisplayPrice>{priceVat}</DisplayPrice></PriceCaption>}
      <Price>{translations.t('quickstore.total')}: <DisplayPrice wrapCurrency={true}>{price + priceVat}</DisplayPrice></Price>
    </Pricing>
  );
});

const Pricing = styled.div`
  margin-bottom: 20px;
  color: ${Colors.whisper};
`;
const PriceCaption = styled.div`
  font-size: 12px;
  font-weight: 300;
`;
const Price = styled.div`
  margin-top: 6px;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 700;
  
  span {
    font-size: 24px;
  }
`;
