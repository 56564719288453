import { createBreakpoints } from '@libs';

const points = [
  {
    mobile: 0,
  },
  {
    tablet_landscape: 1024,
  },
  {
    laptop: 1280,
  },
  {
    laptop_wide: 1440,
  },
  {
    full_hd: 1600,
  },
];

export const { tablet_landscape, laptop, laptop_wide, full_hd } = createBreakpoints(points);
