import { getSrcResize, formatDate, formatDateWithTime, textHooks } from '@libs';
import { ApiCall } from './api-call';
import {
  ERROR_TYPES,
  NOTIFICATION_TYPES,
  OPEN_GALLERY_NOTIFICATION_TYPES,
  SHOW_LOT_NOTIFICATION_TYPES,
  VIEW_MODE,
  AUTO_UPDATE_INTERVAL,
} from '../constants';
import { setCloudSize } from './normalize';
import * as actions from './Store';
import { translations } from '@libs';

export const getGoods = () => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  try {
    const { currentTeam } = getState();
    const response = await ApiCall.getGoods({ team_id: currentTeam });
    const { data: responseData } = response;
    const { success, errorText, data } = responseData;
    const isExpired = errorText === 'Time expired';
    const tariff = {};

    if (!success && !isExpired) {
      throw new Error(errorText);
    }

    if (isExpired) {
      tariff.expire = null;
      tariff.alias = data.tariff_alias;
      tariff.isBlocked = true;
    }

    if (!isExpired) {
      tariff.expire = formatDate(data.tariff.expire);
      tariff.expireSeconds = data.tariff.expire;
      tariff.name = data.tariff.name;
      tariff.alias = data.tariff.alias;
      tariff.isBlocked = data.tariff.expire === -1;
    }

    const goods = [];

    if (data.goods) {
      data.goods.forEach((item) => {
        const good = {};

        if (item.count > 0) {
          good.id = item.id;
          good.caption = textHooks(item.caption).out_hooks || item.caption;
          good.count = item.count;
          good.isDisabled = item.price === null && item.in_sets.length === 0;

          goods.push(good);
        }
      });
    }

    dispatch(actions.setTariff(tariff));
    dispatch(actions.setGoods(goods));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GOODS, error }));
  } finally {
    dispatch(actions.endProcessing());
  }
};

export const getUserData = () => async (dispatch) => {
  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.getProfile();
    const { data } = response;
    const payload = {
      name: data.name,
      user: {
        avatar: getSrcResize(data.avatar, 100),
        cloudSize: setCloudSize(data.disk_usage.quota_limit, data.disk_usage.quota_usage),
      },
    };

    dispatch(actions.setUser(payload));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.USER, error }));
  } finally {
    dispatch(actions.endProcessing());
  }
};

export const getTeams = () => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  try {
    const { currentTeam } = getState();
    const response = await ApiCall.getTeams();
    const { data: responseData } = response;
    const { success, errorText, data } = responseData;

    if (!success) {
      throw new Error(errorText);
    }

    const teams = data.teams.map((item) => {
      const team = {};

      team.id = item.id;
      team.name = item.caption;
      team.avatar = getSrcResize(item.logo, 100);
      team.count = item.license_count;
      team.expire = formatDate(item.license_expire);
      team.cloudSize = setCloudSize(item.disk_usage.quota_limit, item.disk_usage.quota_usage);

      return team;
    });

    const initialTeam = teams.find((item) => item.id === currentTeam);

    if (initialTeam) {
      const { cloudSize, count, avatar } = initialTeam;
      const payload = {
        cloudSize,
        count,
        avatar,
      };

      dispatch(actions.setUserTeam(payload));
    }

    dispatch(actions.setTeams(teams));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.TEAMS, error }));
  } finally {
    dispatch(actions.endProcessing());
  }
};

export const getUserSettings = () => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  try {
    const { currentTeam } = getState();
    const response = await ApiCall.getUserSettings({ team_id: currentTeam });
    const { data: { success, errorText, data } } = response;

    if (!success) {
      throw new Error(errorText);
    }

    const { unitySettings = {}, unitySettingsChecksum = '' } = data;
    const lastReadedNotis = data.lastReadedNotis || unitySettings.lastReadedNotis || '';
    const store_basket = data.store_basket || unitySettings.store_basket;

    try {
      const cart = store_basket ? JSON.parse(store_basket) : {};
      const cart_values = Object.values(cart);
      const cart_count = cart_values.length ? cart_values.reduce((acc, currentValue) => acc + currentValue) : 0;

      dispatch(actions.setUserSettings({
        cart_count,
        last_notification: lastReadedNotis,
        checksum: unitySettingsChecksum
      }));
    } catch (e) {
      dispatch(actions.setUserSettings({ cart_count: 0, last_notification: '', checksum: '' }));
    }
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.SETTINGS, error }));
  } finally {
    dispatch(actions.endProcessing());
  }
};

export const getNotification = () => async (dispatch, getState) => {
  try {
    const { userSettings, currentTeam, settings, tariff } = getState();
    const { last_notification = '', checksum = '' } = userSettings;
    const { localeUi } = settings;
    const params = {
      count: 30,
      last_id: last_notification,
      checksum
    };

    const response = await ApiCall.getNotifications({ params, team_id: currentTeam });
    const { data: responseData } = response;
    const { success, errorText, data } = responseData;

    if (!success) {
      throw new Error(errorText);
    }

    const { events } = data;
    const payload = [];

    events.forEach((item) => {
      if (NOTIFICATION_TYPES[item.type] && item.type !== 11) {
        const event = {};
        const { time, date } = formatDateWithTime(item.created);
        const { id, type, data, settings = {} } = item;
        const { icon = '', caption = '' } = settings;

        event.id = id;
        event.type = NOTIFICATION_TYPES[type];
        event.time = `${time} ${date}`;
        event.image = data.image || icon;
        event.caption = (typeof data.caption === 'object' ? data.caption[localeUi] : data.caption) || caption;

        if (OPEN_GALLERY_NOTIFICATION_TYPES[type]) {
          if (data.shId && data.project_id) {
            event.gallery = {
              projectId: data.project_id,
              openImageId: data.shId,
            };
          } else if (!data.shId && type === 7) {
            event.gallery = {
              projectId: data.project_id,
            };
          } else {
            event.gallery = {
              previewUrl: data.image,
              imageUrl: data.image.replace('90x60', ''),
            };
          }
        }

        if (SHOW_LOT_NOTIFICATION_TYPES[type]) {
          event.lotId = data.lot_id;
        }

        if (!OPEN_GALLERY_NOTIFICATION_TYPES[type] && !SHOW_LOT_NOTIFICATION_TYPES[type]) {
          event.href = typeof data.link === 'object' ? data.link[localeUi] : data.link;
        }

        payload.push(event);
      }
    });

    if (tariff.expireSeconds) {
      const timeOld = parseInt(tariff.expireSeconds - (Date.now() / 1000), 10);
      const secondTerm = 3 * 24 * 60 * 60;

      if (timeOld < secondTerm) {
        const { time, date } = formatDateWithTime(parseInt(Date.now() / 1000, 10));

        payload.push({
          type: 'tariff_expire',
          time: `${time} ${date}`,
          image: 'https://files.planoplan.com/upload/event/5ab6c80df0f0b8a7cf4de9533c176566_52cf04e5.png',
          caption: translations.t('user_widget.notification.tariff_expire'),
        });
      }
    }

    dispatch(actions.setNotifications(payload));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.NOTIFICATION, error }));
  }
};

export const startAutoUpdateNotification = () => (dispatch, getState) => {
  const { settings } = getState();

  const interval = setInterval(() => {
    const { view } = getState();

    if (view !== VIEW_MODE.NOTIFICATION) {
      dispatch(getNotification());
    }
  }, AUTO_UPDATE_INTERVAL);

  settings.setAutoUpdateInterval(interval);
};
