import React from 'react';

export const IconDots = ({ width = 24, height = 24, fill = '#4F5B60' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" stroke={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.9997 12.6663C12.3679 12.6663 12.6663 12.3679 12.6663 11.9997C12.6663 11.6315 12.3679 11.333 11.9997 11.333C11.6315 11.333 11.333 11.6315 11.333 11.9997C11.333 12.3679 11.6315 12.6663 11.9997 12.6663Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.6667 12.6663C17.0349 12.6663 17.3333 12.3679 17.3333 11.9997C17.3333 11.6315 17.0349 11.333 16.6667 11.333C16.2985 11.333 16 11.6315 16 11.9997C16 12.3679 16.2985 12.6663 16.6667 12.6663Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33366 12.6663C7.70185 12.6663 8.00033 12.3679 8.00033 11.9997C8.00033 11.6315 7.70185 11.333 7.33366 11.333C6.96547 11.333 6.66699 11.6315 6.66699 11.9997C6.66699 12.3679 6.96547 12.6663 7.33366 12.6663Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

);
