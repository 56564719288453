export const initialState = {
  images: [],
  projects: [],
  profile: {},
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  settings: {},
  design_project: {
    step: '',
    images: [],
    uploadedImages: [],
    name: '',
    description: '',
    sidebar_images: [],
    additional: {
      displayTitle: true, // Показывать ли Титульный лист
      displayList: true, // Показывать ли содержание
    }
  },
  project_name: '',
  has_pdf: false,
  useTypeLogo: 'planoplan', // Выбраный тип логотипа для Дизайн-проекта (planoplan || custom)
};
