import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.PROJECTS_CLOSE]: {
    name: 'CloseProjects',
    value: true,
    type: 'string',
    alias: 'Close_Projects',
  },
};
