import React  from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { PROJECT_OPEN } from '@globalConstants';
import { actions } from '@globalInvoke';
import { Analytics } from '../../modules/analytics';

export const Flat = ({id, image, title }) => {
  const click = () => {
    window.invokeEditorAction({
      name: actions[PROJECT_OPEN].name,
      value: id,
    });

    if (window.dataLayer) {
      Analytics.openProject(title);
    }
  };

  return (
      <View onClick={click}>
        <Image>
          <img src={image} alt={title}/>
        </Image>

        <Name>{title}</Name>
      </View>
  );
};

const View = styled.div`
  ${Mixins.transition('background-color')};
  height: 184px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #4A4A4A;
  overflow: hidden;
  
  &:hover {
    background-color: #4A4A4A;
    
    img {
      transform: scale(1.1);
    }
  }
`;

const Image = styled.div`
  height: 140px;
  overflow: hidden;
  position: relative;
  
  img {
    ${Mixins.transition('transform')};
    transform: scale(1);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Name = styled.div`
  cursor: pointer;
  flex-grow: 1;
  padding-top: 14px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 14px;
`;
