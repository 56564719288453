import { actions, interfaces, url } from '@globalInvoke';
import {
  BLOCKING_RECTS_UPDATE,
  POINTER_OFF_INTERFACE,
  POINTER_ON_INTERFACE,
  UNAUTH_URL_OPEN,
} from '@globalConstants';
import { POP_PROFILE } from '@globalLocalStorage';
import { ON_CHANGE_ZOOM, USEDESK_CALLBACK, ON_OPEN_GETTING_STARTED } from '@observer/constants';
import { LocalStorage } from '@libs';

const observer = new window.POPObserver();

export const initUsedesk = async ({ zoom }) => {
  const setRect = (element, zoom) => {
    const { width, height, x, y } = element.getBoundingClientRect();

    window.invokeEditorAction({
      name: actions[BLOCKING_RECTS_UPDATE].name,
      value: { 'rects': [{
        x: x * zoom,
        y: y * zoom,
        w: width * zoom,
        h: height * zoom
      }] },
    });
  };

  const closeUseDesk = () => {
    const overlay = document.getElementById('usedesk-overlay');

    window.invokeEditorAction({
      name: interfaces[POINTER_ON_INTERFACE].name,
      value: interfaces[POINTER_ON_INTERFACE].value,
    });

    if (overlay) overlay.style.display = 'none';

    if (window.usedeskMessenger) {
      window.usedeskMessenger.close();
    }
  };

  const openUseDesk = () => {
    const overlay = document.getElementById('usedesk-overlay');

    window.invokeEditorAction({
      name: interfaces[POINTER_OFF_INTERFACE].name,
      value: interfaces[POINTER_OFF_INTERFACE].value,
    });

    if (overlay) overlay.style.display = 'block';
  };

  const createOverlay = () => {
    const overlay = document.createElement('div');

    overlay.id = 'usedesk-overlay';
    overlay.style.cssText = 'position:fixed;top:0;left:0;width:100%;height:100%;z-index:1;display:none';
    overlay.addEventListener('click', closeUseDesk);
    overlay.addEventListener('touchstart', closeUseDesk);

    document.body.appendChild(overlay);
  };

  const addListenerUseDesk = (index = 120) => {
    const mainButton = document.getElementById('uw-main-button');

    if (!mainButton) {
      if (index === 0) {
        throw new Error('Attempts to get a usedesk element have ended');
      }

      return setTimeout(() => addListenerUseDesk(index - 1), 500);
    }

    mainButton.addEventListener('click', ({ target }) => {
      if (target.id === 'uw-main-button') {
        openUseDesk();
      } else if (target.id === 'uw-main-button-close') {
        // closeUseDesk();
      }
    });

    setRect(mainButton, zoom);
  };

  const addListenerResize = () => {
    window.addEventListener('resize', () => {
      const mainButton = document.getElementById('uw-main-button');
      const closeButton = document.getElementById('uw-main-button-close');

      if (mainButton || closeButton) {
        setRect(mainButton || closeButton, zoom);
      }
    });
  };

  /* При клике на контейнер ищем каждые 500ms в течении 2 секунд и добавляем им event listener */
  const addListenersClickLinks = async () => {
    const container = document.getElementById('usedesk-messenger');

    const setEventListener = (attempts = 10) => {
      if (attempts === 0) return;

      container.querySelectorAll('a').forEach((link) => {
        if (link.getAttribute('init-click')) return;

        link.setAttribute('init-click', '1');
        link.addEventListener('click', (event) => {
          event.preventDefault();

          if (link.href.indexOf('#getting-started') !== -1) {
            observer.postMessage(ON_OPEN_GETTING_STARTED);

            return;
          }

          window.invokeEditorAction({
            name: url[UNAUTH_URL_OPEN].name,
            value: link.href,
          });
        })
      });

      setTimeout(() => setEventListener(attempts - 1), 500);
    };

    container.addEventListener('click', () => setEventListener());
  };

  const addListenerChangeZoom = () => {
    observer.addEventListener(ON_CHANGE_ZOOM, (zoom) => {
      const mainButton = document.getElementById('uw-main-button');
      const closeButton = document.getElementById('uw-main-button-close');

      if (mainButton || closeButton) {
        setRect(mainButton || closeButton, zoom);
      }
    });
  };

  const initUser = () => {
    window.__widgetInitCallback = () => {
      observer.addEventListener(USEDESK_CALLBACK, () => {
        const token = window.usedeskMessenger.getChatToken();
        const profile = LocalStorage.get(POP_PROFILE);

        if (!profile) return;

        window.usedeskMessenger.identify({
          name: `${profile.name || ''}_ID:${profile.id || ''}`,
          email: profile.email || '',
          token,
        });
      });
    }
  };

  createOverlay();
  addListenerUseDesk();
  await addListenersClickLinks();
  addListenerResize();
  addListenerChangeZoom();
  initUser();
};
