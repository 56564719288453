import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  GET_HISTORY,
  SET_HISTORY_PAYMENT_DATA,
  SET_PROCESSING,
} from '../../constants';
import { normalizeHistoryPayment } from '../../modules/normalize';
import { ApiCall } from '../../modules/api-call';
import { notifications } from '@features/notifications';
import { translations } from '@libs';

export const getHistory = (currentPage = 0) => async (dispatch, getState) => {
  const {
    historyPayment: { perPage },
  } = getState();

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_HISTORY } });

  try {
    const response = await ApiCall.getHistory(currentPage * perPage, perPage);

    if (!response || !response.success) {
      notifications.showError(translations.t('cabinet.notification.fetchHistory.error'));

      return;
    }
    const { history, total_count_history } = response.data;
    const historyData = normalizeHistoryPayment(history);

    dispatch({
      type: SET_HISTORY_PAYMENT_DATA,
      payload: { history: historyData, pageCount: Math.ceil(parseInt(total_count_history) / perPage) },
    });
    dispatch({ type: SET_PROCESSING, payload: false });

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_HISTORY } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_HISTORY, error: error },
    });
  }
};
