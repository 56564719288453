import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';

export const EmptyMessage = () => {
  return (
    <View>
      <Icon>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 16V12" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 8H12.01" stroke="#D1D1D1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </Icon>
      <Text>{translations.t('gallery.project.empty')}</Text>
    </View>
  );
};

const View = styled.div`
  background-color: ${Colors.coal};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${Colors.white};
  text-align: center;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
`;
const Icon = styled.div`
  margin-bottom: 6px;
`;
const Text = styled.div`
  font-size: 12px;
  line-height: normal;
  font-weight: 300;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;
