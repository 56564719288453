import React, { useCallback }  from 'react';
import styled from 'styled-components';
import { Link } from '@components/link';

export const TextWithLinks = ({ Tag = 'div', text = '' }) => {
  const splitText = useCallback((inputText = '') => {
    try {
      const regexLink = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*?>([^<]+)<\/a>/i;
      const regexp = /<a\b[^>]*>(.*?)<\/a>/gi;
      const matches = inputText.match(regexp);
      const result = [];

      if (!matches) {
        return [{
          text: inputText
        }];
      }

      for (let i = 0; i < matches.length; i++) {
        const prevMatch = matches[i - 1];
        const match = matches[i];

        const indexStart = prevMatch ? inputText.indexOf(prevMatch) + prevMatch.length : 0;
        const indexEnd = inputText.indexOf(match) + match.length;
        const matchLink = match.match(regexLink);

        result.push({
          text: inputText.slice(indexStart, indexEnd).replace(match, ''),
          href: matchLink ? matchLink[1] : '',
          link: matchLink ? matchLink[2] : ''
        });

        if (!matches[i + 1]) {
          const indexStartLast = indexEnd;
          const indexEndLast = inputText.length;

          if (indexStartLast < indexEndLast) {
            result.push({
              text: inputText.slice(indexStartLast, indexEndLast),
              hre: '',
              link: ''
            });
          }
        }
      }

      return result;
    } catch (error) {
      console.error(error);

      return text;
    }
  }, []);

  const result = splitText(text);

  return (
    <Tag>
      {Array.isArray(result) ? result.map((item, index) => (
        <span key={index}>
          <span dangerouslySetInnerHTML={{ __html: item.text }} />
          {item.href && <Link href={item.href} >{item.link}</Link>}
        </span>
      )) : result}
    </Tag>
  );
};

const View = styled.div``;
