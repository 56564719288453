import React from 'react';
import styled from 'styled-components';
import { Table } from './Table';
import { Pagination } from './Pagination';
import { translations, formatDate } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';

export const TableContainer = ({ history, processing, pageCount, currentPage, onPageChange, email, locale }) => {
  const columns = [
    {
      header: translations.t('cabinet.history.table.date'),
      accessor: 'date',
      style: {
        width: '105px',
        alignItems: 'start',
      },
      sortable: false,
      render: ({ date }) => formatDate(date),
    },
    {
      header: translations.t('cabinet.history.table.order'),
      accessor: 'id',
      style: {
        width: '102px',
      },
      sortable: false,
      render: ({ id }) => <InnerCol><ColBold>{id}</ColBold></InnerCol>,
    },
    {
      header: translations.t('cabinet.history.table.name'),
      accessor: 'name',
      style: {
        width: '292px',
      },
      sortable: false,
      render: ({ items }) => (
        <InnerCol>
          {items.length === 1 ? (
            <ColBold title={locale === 'ru' ? items[0].name.ru : items[0].name.en}>
              {locale === 'ru' ? items[0].name.ru : items[0].name.en}
            </ColBold>
          ) : (
            <ColBold>{translations.t('cabinet.history.table.composition')}</ColBold>
          )}
          {items.length > 1 &&
            items.map((item, index) => (
              <Col title={locale === 'ru' ? item.name.ru : item.name.en} key={index}>
                {locale === 'ru' ? item.name.ru : item.name.en}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.count'),
      accessor: 'count',
      sortable: false,
      style: {
        width: '92px',
        textAlign: 'right',
      },
      render: ({ items }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].count}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.count}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.price'),
      accessor: 'price',
      sortable: false,
      style: {
        width: '92px',
        textAlign: 'right',
      },
      render: ({ items, currency }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].price} ${getCurrency(currency)}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.price} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.vat'),
      accessor: 'vat',
      sortable: false,
      style: {
        width: '92px',
        textAlign: 'right',
      },
      render: ({ items, currency }) => (
        <InnerCol>
          <Col>{items.length === 1 ? `${items[0].vat} ${getCurrency(currency)}` : '—'}</Col>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.vat} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: translations.t('cabinet.history.table.sum'),
      accessor: 'sum',
      sortable: false,
      style: {
        width: '92px',
        paddingRight: '7px',
        textAlign: 'right',
      },
      render: ({ items, currency, sum }) => (
        <InnerCol>
          <ColBold>
            {sum} {getCurrency(currency)}
          </ColBold>
          {items.length > 1 &&
            items.map((item, index) => (
              <Col key={index}>
                {item.total} {getCurrency(currency)}
              </Col>
            ))}
        </InnerCol>
      ),
    },
    {
      header: '',
      accessor: 'account',
      style: {
        color: Colors.feldgrau25,
        width: '92px',
        textAlign: 'center',
      },
      render: () => (
        <InvoiceCol>
          {translations.t('cabinet.history.table.account')}
        </InvoiceCol>
      ),
    },
  ];

  return (
    <Container>
      {!history.length && <NotOptions>{translations.t('cabinet.history.noPayments').replace('\\','')}</NotOptions>}

      {history.length ? <Table data={history} columns={columns} modify={'alignTop'} isSort={false} /> : null}
      {pageCount > 1 && <Pagination pageCount={pageCount} currentPage={currentPage} pageRange={2} onPageChange={onPageChange} />}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const NotOptions = styled.p`
  font-size: 28px;
  color: ${Colors.nobel};
  margin: 0 0 30px;
`;

const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceCol = styled.div`
  color: ${Colors.feldgrau25};
`;

const Col = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const ColBold = styled(Col)`
  font-weight: 400;
`;
