// https://medium.com/@fsufitch/is-javascript-array-sort-stable-46b90822543f
export const stableSort = (array, cmp) => {
  cmp = cmp
    ? cmp
    : (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };
  let stabilizedThis = array.map((el, index) => [el, index]);
  let stableCmp = (a, b) => {
    let order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  };
  stabilizedThis.sort(stableCmp);
  for (let i = 0; i < array.length; i++) {
    array[i] = stabilizedThis[i][0];
  }
  return array;
};

export const sortBy = (array = [], order = [], field = '') => {
  const sortByObject = order.reduce(
    (obj, item, index) => ({
      ...obj,
      [item]: index,
    }),
    {}
  );

  return stableSort(array, (a, b) => {
    return sortByObject[a[field]] - sortByObject[b[field]];
  });
};
