import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const RemoveUserFromTeamModal = ({ isOpen, onCancel = () => {}, onRemoveUser = () => {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>
        {translations.t('modal.user.delete')}
      </Header>
      <Content />
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={onRemoveUser}>
          {translations.t('modal.delete')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
