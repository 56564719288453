import React from 'react';

export const ICardPrzelewy = ({ width = 72, height = 24, fill = '#FFFFFF' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6922 13.8322L21.5659 13.8247L21.3519 15.1617L16.4395 19.8525L20.5632 19.845L20.3228 21.2871L14.25 21.2909L14.5054 19.7999L19.2149 15.2894H15.4556L15.6922 13.8322Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.26631 11.5454C9.13862 11.4102 8.95459 11.305 8.75179 11.2224C8.53772 11.1398 8.30112 11.0797 8.06827 11.0384C7.77533 10.9858 7.4899 10.9633 7.2871 10.9558C7.09932 10.9482 6.97914 10.9482 6.97914 10.9482H4.4103H2.49869L0.786133 21.2912H2.38602L3.01697 17.4605L6.1191 17.4755C6.1191 17.4755 7.32841 17.5243 8.18469 17.0699C9.04097 16.6155 9.27006 15.5827 9.27006 15.5827C9.27006 15.5827 9.36395 15.1996 9.44282 14.7339C9.5292 14.2194 9.61558 13.6035 9.66065 13.2767C9.67943 13.134 9.69069 13.0439 9.69069 13.0439C9.69069 13.0439 9.71323 12.9387 9.71323 12.7735C9.71698 12.5444 9.68318 12.1989 9.50291 11.8684C9.43907 11.7519 9.36395 11.6468 9.26631 11.5454ZM8.03447 13.3706C8.03447 13.3894 7.89175 14.2194 7.70397 15.2034C7.63262 15.5789 7.34343 15.7817 7.01294 15.8531C6.46462 15.9695 5.87499 15.947 5.87499 15.947L3.28362 15.9395L3.85447 12.5031L6.20173 12.5106C6.20173 12.5106 6.35946 12.5068 6.58105 12.5106C6.83267 12.5143 7.16692 12.5256 7.44108 12.5632C7.67768 12.5932 7.86547 12.642 7.92555 12.7171C8.00442 12.8148 8.03447 12.9425 8.04573 13.0551C8.06076 13.2204 8.03447 13.3593 8.03447 13.3706Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5318 10.9482H31.0716L29.3478 21.2912L27.8042 21.2874L29.5318 10.9482Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.749 13.8326L39.225 13.8288L39.747 19.0791L42.0192 13.8213L43.8406 13.825L44.3777 19.0979L46.6461 13.8288H48.1784L44.926 21.2837H43.1083L42.5825 16.0634L40.2878 21.2837L38.5001 21.2912L37.749 13.8326Z" fill="#D1D1D1"/>
      <path d="M26.6886 13.892C26.2568 13.7531 25.5094 13.723 24.8747 13.7306C24.2625 13.7381 23.9808 13.7681 23.7518 13.8169C23.7518 13.8169 22.6626 13.9747 22.043 14.7408C21.4233 15.507 21.2392 17.182 21.2392 17.182C21.2392 17.182 20.8712 19.0335 20.9801 19.6532C21.089 20.2691 21.2806 20.8437 21.9791 21.1103C22.6776 21.3807 23.271 21.3657 23.271 21.3657C23.271 21.3657 24.5179 21.4634 25.4568 21.2418C26.3957 21.0202 26.8914 20.3592 26.8914 20.3592C26.8914 20.3592 27.113 20.0738 27.2708 19.7358C27.4285 19.3978 27.4773 19.1612 27.4848 19.1311L27.5825 18.7293L25.9863 18.733C25.9863 18.733 25.9 19.7884 25.0362 19.886C24.1761 19.9837 23.7142 19.9461 23.5452 19.9386C23.3799 19.9311 22.4561 19.9724 22.5312 19.1987C22.5312 19.1875 22.5312 19.1724 22.5349 19.1499C22.5762 18.2711 22.6739 18.042 22.6739 18.042L27.6876 18.027L27.9017 16.7876C28.1496 15.383 27.9731 14.3127 26.6886 13.892ZM26.3544 16.6111L22.918 16.6074L23.0532 16.0628C23.0532 16.0628 23.1734 15.6347 23.41 15.4544C23.6504 15.2741 23.9546 15.2403 24.24 15.214C24.5254 15.1877 25.2878 15.1314 25.9075 15.2591C26.114 15.3004 26.3093 15.4168 26.3619 15.5896C26.4821 15.9952 26.3544 16.6111 26.3544 16.6111Z" fill="#D1D1D1"/>
      <path d="M22.5424 19.1651C22.5424 19.1764 22.5386 19.1877 22.5386 19.1989C22.5349 19.259 22.5424 19.1614 22.5424 19.1651Z" fill="#D1D1D1"/>
      <path d="M31.9994 19.1695C31.9994 19.1808 31.9956 19.1921 31.9956 19.2033C31.9956 19.2634 31.9994 19.1658 31.9994 19.1695Z" fill="#D1D1D1"/>
      <path d="M36.1491 13.8964C35.7172 13.7575 34.9698 13.7274 34.3351 13.735C33.723 13.7425 33.4413 13.7725 33.2122 13.8213C33.2122 13.8213 32.1231 13.9791 31.5034 14.7452C30.8837 15.5114 30.6997 17.1864 30.6997 17.1864C30.6997 17.1864 30.3316 19.0379 30.4406 19.6576C30.5495 20.2735 30.741 20.8481 31.4396 21.1147C32.1381 21.3851 32.7315 21.3701 32.7315 21.3701C32.7315 21.3701 33.9784 21.4678 34.9173 21.2462C35.8562 21.0246 36.3519 20.3636 36.3519 20.3636C36.3519 20.3636 36.5735 20.0782 36.7312 19.7402C36.889 19.4022 36.9378 19.1656 36.9453 19.1355L37.0429 18.7337L35.4468 18.7374C35.4468 18.7374 35.3604 19.7928 34.4966 19.8904C33.6366 19.988 33.1746 19.9505 33.0056 19.9467C32.8404 19.9392 31.9165 19.9768 31.9916 19.2069C31.9916 19.1956 31.9916 19.1806 31.9954 19.1581C32.0367 18.2792 32.1343 18.0502 32.1343 18.0502L37.1481 18.0351L37.3622 16.7958C37.61 15.3874 37.4335 14.3133 36.1491 13.8964ZM35.7998 16.6118L32.3634 16.608L32.4986 16.0634C32.4986 16.0634 32.6188 15.6353 32.8554 15.455C33.092 15.2748 33.4 15.241 33.6854 15.2147C33.9708 15.1884 34.7332 15.132 35.3529 15.2597C35.5595 15.301 35.7548 15.4175 35.8073 15.5902C35.9275 15.9921 35.7998 16.6118 35.7998 16.6118Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M49.9509 13.8324L50.9387 19.2855L53.7291 13.8286L55.2989 13.8436L51.2767 21.6365C51.2767 21.6365 50.5481 23.0486 50.0974 23.4054C49.6467 23.7622 49.3688 23.9237 49.0008 23.9613C48.6327 23.9988 48.4825 24.0251 48.1295 23.9613L47.7539 23.8937L47.9868 22.5003C47.9868 22.5003 48.6102 22.6168 48.9782 22.4703C49.35 22.3238 49.6467 21.6929 49.6467 21.6929L49.8345 21.3774L48.3811 13.8286L49.9509 13.8324Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M55.8697 14.5723L57.4883 14.576L57.586 13.9489C57.586 13.9489 57.7587 12.8147 58.1456 12.5968C58.2695 12.5255 58.4685 12.4616 58.6976 12.4203C59.122 12.3452 59.6591 12.3377 60.0985 12.3527C60.7707 12.3753 61.0261 12.3828 61.7096 12.4616C62.3932 12.5405 62.2204 13.2015 62.2204 13.2015L62.0852 14.1892C62.0852 14.1892 62.0251 14.6324 61.8674 14.9065C61.7284 15.1507 61.3453 15.3159 61.1238 15.3873C60.5905 15.56 58.7652 16.0257 58.7652 16.0257L57.3306 16.4388C57.3306 16.4388 56.448 16.6942 55.956 17.2388C55.4603 17.7871 55.265 18.4068 55.1974 18.7298C55.1298 19.0527 54.7505 21.2873 54.7505 21.2873L62.4946 21.2911L62.7537 19.7438L56.6283 19.7513L56.7372 19.1203C56.7372 19.1203 56.8086 18.4706 57.0715 18.2565C57.1541 18.1889 57.1954 18.0988 57.6836 17.9261C57.9766 17.8209 58.9756 17.5542 58.9756 17.5542L61.2853 16.9233C61.2853 16.9233 62.5471 16.6003 63.0429 15.9093C63.5386 15.222 63.7302 13.9038 63.7302 13.9038C63.7302 13.9038 63.8654 12.6231 63.7602 12.2213C63.6588 11.8194 63.2795 11.3387 62.8175 11.1321C62.3556 10.9256 61.8749 10.8054 60.4816 10.8242C59.0882 10.843 58.3972 10.9068 57.6911 11.1697C56.9851 11.4288 56.5757 11.902 56.3166 12.5705C56.0349 13.2052 55.8697 14.5723 55.8697 14.5723Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M70.4488 17.4491L71.5342 10.9443H69.6113L63.6249 17.3739L63.3545 19.0039H68.5898L68.2067 21.2873L69.8141 21.2911L70.1935 19.0039L71.6769 19.0076L71.9436 17.4491H70.4488ZM68.849 17.4528L65.4651 17.4491L69.5851 13.0587L68.849 17.4528Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.6616 11.8007H17.5927C17.5927 11.8007 18.6969 10.9068 19.4818 10.3322C20.2667 9.75762 21.6939 8.85251 21.6939 8.85251L18.9072 7.54932C18.9072 7.54932 16.5524 9.00649 15.5534 9.69377C14.5845 10.3285 12.6616 11.8007 12.6616 11.8007Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M23.2598 7.9322L20.9727 6.3924C20.9727 6.3924 23.042 5.21689 25.7949 4.12025C28.544 3.02361 30.0124 2.56543 30.0124 2.56543L30.4781 4.74744C30.4781 4.74744 27.8304 5.63377 26.3131 6.35109C24.7433 7.01959 23.2598 7.9322 23.2598 7.9322Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.1681 4.27048L31.77 2.03589C31.77 2.03589 34.5942 1.28477 37.1781 0.804049C39.7657 0.323331 43.1946 0.0942383 43.1946 0.0942383L42.0604 3.54565C42.0604 3.54565 39.0446 3.13253 36.2129 3.51936C34.0084 3.78225 32.1681 4.27048 32.1681 4.27048Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M43.8555 3.83878L45.7633 0.00429432C45.7633 0.00429432 49.9433 -0.0783292 53.5487 0.481257C57.1541 1.03709 60.4515 1.89337 60.3802 1.93092L51.239 6.71182C51.239 6.71182 49.1021 5.3598 46.4506 4.52981C44.9484 4.08665 43.8555 3.83878 43.8555 3.83878Z" fill="#D1D1D1"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.8765 7.75205L54.8857 9.27683H71.3992C71.3992 9.27683 71.3654 8.74353 70.9259 7.9849C70.6518 7.51169 70.1523 7.00844 69.6303 6.48641C69.4387 6.29863 68.6801 5.70524 68.1092 5.33344C66.6521 4.38702 65.8371 4.02273 64.3236 3.33545L52.8765 7.75205Z" fill="#D1D1D1"/>
      <path d="M13.7316 13.8247C13.1119 13.8247 12.526 14.0688 12.0265 14.343L12.1129 13.8247H10.4717L9.15723 21.2496H10.8022L11.5308 17.1372C11.681 16.3034 12.3044 15.2706 13.5212 15.2706L14.37 15.2669L14.6254 13.8247H13.7316Z" fill="#D1D1D1"/>
    </svg>
  );
};
