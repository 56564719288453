// Invoke
export const TOUR_EDITOR_OPEN = 'TOUR_EDITOR_OPEN';
export const TOUR_EDITOR_CLOSE = 'TOUR_EDITOR_CLOSE';

// Actions
export const GET_PANORAMAS = 'GET_PANORAMAS';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_PANORAMAS = 'SET_PANORAMAS';
export const SET_CURRENT_PANORAMA = 'SET_CURRENT_PANORAMA';
export const SET_STRUCTURE = 'SET_STRUCTURE';
export const SET_KRPANO = 'SET_KRPANO';
export const SET_PROGRESS_AUTO_SAVE = 'SET_PROGRESS_AUTO_SAVE';
export const SET_TOUR_ID = 'SET_TOUR_ID';
export const SET_HOTSPOT_ICONS = 'SET_HOTSPOT_ICONS';
export const SET_LAST_HOTSPOT_ICON = 'SET_LAST_HOTSPOT_ICON';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';

export const ERROR = 'ERROR';
