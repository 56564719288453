// Возращает процент числа от числа
const getPercent = (value, num) => {
  return value * 100 / num;
};

// Найти процент от числа
const getNumber = (percent, num) => {
  return num * (percent / 100)
};

// Отдает процентное соотношение
export const getPercentValueSlider = (value, min, max, centerAt = 0) => {
  const vl = value > max ? max : value < min ? min : value;

  const left = centerAt - min;
  const right = max - centerAt;

  if (value >= centerAt) {
    return getPercent(vl - centerAt, right).toFixed(0);
  } else {
    return -getPercent(centerAt - vl, left).toFixed(0);
  }
};

export const getNumberValueSlider = (percent, min, max, centerAt = 0) => {
  const left = centerAt - min;
  const right = max - centerAt;

  if (percent >= 0) {
    return (getNumber(percent, right) + centerAt).toFixed(3);
  } else {
    return (getNumber(percent, left) + centerAt).toFixed(3);
  }
};
