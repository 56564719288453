import React, { useEffect } from 'react';
import { BannerModal } from './components/BannerModal';
import { useModal } from '@components';
import { LocalStorage } from '@libs';
import { routeInCabinet } from './modules/effects';
import Banners from '@features/banners';
import { ON_OPEN_STORE } from '@observer/constants';
import { POP_PROFILE } from '@globalLocalStorage';
import { SHOW_BANNER } from './constants';
import { Analytics } from '@features/store/modules/analytics';

export const App = ({ locale, banner, settings, tariffsContent, goods, teamId, count, amountBanners }) => {
  const observer = new window.POPObserver();
  const [isOpenModal, openModal, closeModal] = useModal();
  const { mode, zIndex = 1, action } = settings;
  const tariffsItems = tariffsContent.data.tariffs.blocks.short_banners.items;
  const { data: pricing} = goods;

  const onRouteCabinet = (link) => {
    closeModal('banners');

    if (!link) {
      if(!count || count >= amountBanners) return;

      const banner = new Banners;
      const { root, mode, zIndex, team_id, alias, groups, action} = settings;

      banner.init({ root, mode, zIndex, locale, team_id, alias, groups, action, count });
      banner.createPoints({ team_id, alias, groups, action });
      banner.on(SHOW_BANNER);

      return;
    }

    const url = new URL(link);
    const tariff = url.searchParams.get('tariff');
    const promocode = url.searchParams.get('promocode');
    const storeOptions = {
      cartTariff: tariff ? tariff : null,
      initialScreen: tariff ? 'cart' : 'pricing',
      promocode: promocode ? promocode : null,
    };

    if (url.pathname.search('store') !== -1) {
      setTimeout(() => {
        observer.postMessage(ON_OPEN_STORE, storeOptions);
      }, 250);

      return;
    }

    if (url.pathname.search('tariff-managment') !== -1) {
      setTimeout(() => {
        const { cartTariff } = storeOptions;
        const profile = LocalStorage.get(POP_PROFILE) || {};
        const tariffAlias = (profile && profile.tariff) ? profile.tariff.alias : '';

        observer.postMessage(ON_OPEN_STORE, storeOptions);
        Analytics.clickSubscribe(mode, locale, tariffAlias, cartTariff)
      }, 250);

      return;
    }

    routeInCabinet(link);
  };

  useEffect(() => {
    openModal();
  }, [banner]);

  return (
    <BannerModal
      locale={locale}
      banner={banner}
      tariffsItems={tariffsItems}
      pricing={pricing}
      mode={mode}
      zIndex={zIndex}
      isOpen={isOpenModal}
      onClose={closeModal}
      onClick={onRouteCabinet}
      action={action}
    />
  );
};
