import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { IZoom } from '../../icons';

export const Fullscreen = ({ onChangeFullScreen, disabled }) => (
  <View onClick={onChangeFullScreen} disabled={disabled}>
    <IZoom />
  </View>
);

const View = styled.div`
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  padding: 8px;
  opacity: ${({ disabled }) => disabled ? 0.2 : 1};
  
  svg {
    ${Mixins.transition('transform fill')};
    transition-duration: 150ms;
  }

  &:hover svg {
    transform: ${({ disabled }) => disabled ? 'none' : 'scale(0.9)'};
  }
`;
