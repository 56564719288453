import { isProdHost, uiHost, isJSON } from '@libs';
import { DOMAINS } from '@globalConstants';
import {
  VK_KEY_DEV,
  VK_KEY_MASTER,
  ENTER_ROUTE_PATH,
  GOOGLE_KEY_DEV,
  GOOGLE_KEY_MASTER,
} from '../constants';
import { getFeaturesNewWindow } from './helpers';

export const vkCall = () => {
  const redirectUri = `${uiHost}/auth.html`;
  const key = isProdHost ? VK_KEY_MASTER : VK_KEY_DEV;
  const features = getFeaturesNewWindow(665, 370);

  const openedWindow = window.open(
    `https://oauth.vk.com/authorize?client_id=${key}&display=page&redirect_uri=${redirectUri}&response_type=code&scope=4194304`,
    'vk',
    features
  );

  return new Promise((resolve, reject) => {
    const closedInterval = setInterval(() => {
      if (openedWindow.closed) {
        clearInterval(closedInterval);

        reject('popup_closed_by_user');
      }
    }, 500);

    window.addEventListener('message', (event) => {
      const currentOrigin = isProdHost ? 'https://planoplan.com' : 'https://pp.ksdev.ru';
      const { origin, data } = event;

      if (origin !== currentOrigin || !isJSON(data)) {
        return;
      }

      try {
        const location = JSON.parse(data);

        if (location && location.search) {
          const params = new URLSearchParams(location.search.substring(1));
          const code = params.get('code');

          clearInterval(closedInterval);
          resolve({ code });
        }
      } catch (error) {
        reject(error);
      }
    });
  });
};

export const vkCallRedirect = () => {
  const key = isProdHost ? VK_KEY_MASTER : VK_KEY_DEV;
  const host = isProdHost ? DOMAINS.PROD : DOMAINS.DEV;
  const redirectUri = `${host}/en/${ENTER_ROUTE_PATH.vk}`;

  window.location.href = `https://oauth.vk.com/authorize?client_id=${key}&display=page&redirect_uri=${redirectUri}&response_type=code&scope=4194304`;
};

export const googleCall = ({ux_mode = 'popup'}) => {
  return new Promise((resolve, reject) => {
    window.google.accounts.id.initialize({
      client_id: isProdHost ? GOOGLE_KEY_MASTER : GOOGLE_KEY_DEV,
      auto_select: true,
      itp_support: true,
      cancel_on_tap_outside: false,
      callback: (response) => {
        const token = response.credential;

        if (ux_mode === 'redirect') {
          const url = new URL(window.location.href);

          url.searchParams.append('token', token);
          window.location.href = url.toString();

          return;
        }

        resolve(token);
      }
    });

    if (document.getElementById('google-login-button')) {
      window.google.accounts.id.renderButton(
        document.getElementById('google-login-button'),
        {
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          shape: 'rectangular',
          text: 'continue_with'
        }
      );
    }

    window.google.accounts.id.prompt();
  });
};
