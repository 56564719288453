import { SCREENS, TARIFF_PERIODS } from '../../constants';

export const initialState = {
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  screen: SCREENS.main,
  goodsId: 0,
  tariffAlias: '',
  profile: {},
  goods: {},
  bindingCard: [],
  paymentMethods: [],
  countries: [],
  banner: {},
  payData: {},
  selected: {
    period: TARIFF_PERIODS.year,
    pack: '',
    country: {},
    promoCode: '',
    paymentMethod: {},
    currentGoods: {}
  },
  appliedPromo: {},
  settings: {},
};
