import { translations } from '@libs';
import { ApiCall } from './api-call';
import {
  SET_SAVED_CART,
  GET_BINDING_CARD,
  GET_PAYMENT_METHODS,
  GET_PRICING,
  GET_PROFILE,
  GET_GOODS,
  GET_USER_SETTINGS,
  GET_BANNERS,
  GET_COUNTRIES,
  GET_DESCRIPTION_TARIFF,
  SET_BINDING_CARD,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PAYMENT_METHODS,
  SET_PRICING,
  SET_PROFILE,
  SET_PROFILE_GOODS,
  SET_BANNERS,
  SET_COUNTRIES,
  SET_LAST_BUY,
  SET_CHECKSUM,
  SET_DESCRIPTION_TARIFF,
  SET_DESCRIPTION_STORE
} from '../constants';
import {
  normalizeProfile,
  normalizeBanners,
  normalizeDescriptionTariffs,
  normalizeDescriptionStore,
} from './normalize';
import { getErrorMessage } from './error-messages';
import { GET_TRANSLATES } from '../../gallery/constants';
import { clearCart } from '../modules/effects';
import { notifications } from '@features/notifications';

export const getTranslations = async (dispatch, state) => {
  const { settings } = state;
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    dispatch({ type: SET_LOADING_ON, payload: { name: GET_TRANSLATES } });

    try {
      const { localeUi } = settings;

      await translations.load(localeUi, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^country./, /^site.notification/, /^projects./, /^assistant./, /^modal./]);

      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TRANSLATES } });
    } catch (error) {
      dispatch({ type: SET_LOADING_OFF, payload: { name: GET_TRANSLATES, error: error.message } });
    }
  }
};

export const getProfile = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROFILE } });

  try {
    const data = await ApiCall.getProfile();

    dispatch({ type: SET_PROFILE, payload: { email: data.email, name: data.name, country: data.country } });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE, error: getErrorMessage(state, error.message) } });
  }
};

// используется только для получение текущего тарифа пользователя/команды
export const getGoods = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GOODS } });

  try {
    const data = await ApiCall.getGoods();
    let normData = data.data;

    if (!data.success && data.errorText === 'Time expired') {
      // throw new Error('blocked');
      notifications.showError(translations.t('store.error.team.expired'));

      clearCart(dispatch, state);


      normData = {
        ...normData,
        tariff: {
          isExpired: true,
          alias: normData.tariff_alias,
          options: {},
        }
      };
    }

    dispatch({ type: SET_PROFILE_GOODS, payload: normalizeProfile(normData) });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GOODS, error: getErrorMessage(state, error.message) } });
  }
};

export const getPricing = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PRICING } });

  try {
    const data = await ApiCall.getPricing();

    if (!data.success || !data.data.length) {
      throw new Error('server');
    }
    dispatch({ type: SET_PRICING, payload: data.data });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PRICING } });
  } catch (error) {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PRICING, error: getErrorMessage(state, error.message) } });
  }
};

export const getBindingCard = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_BINDING_CARD } });

  try {
    const data = await ApiCall.getBindingCard();

    if (!data.success) {
      throw new Error('server');
    }

    dispatch({ type: SET_BINDING_CARD, payload: data.data.items });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BINDING_CARD } });

  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_BINDING_CARD, error: getErrorMessage(state, error.message) },
    });
  }
};

export const getPaymentMethods = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PAYMENT_METHODS } });

  try {
    const data = await ApiCall.getPaymentMethods();

    if (!data.success) {
      throw new Error('server');
    }

    dispatch({ type: SET_PAYMENT_METHODS, payload: data.data });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PAYMENT_METHODS } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PAYMENT_METHODS, error: getErrorMessage(state, error.message) },
    });
  }
};

export const getBanners = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_BANNERS } });

  try {
    const { settings, profileGoods } = state;
    const { tariff_alias } = profileGoods;
    const { localeUi, team_id } = settings;
    const params = { 'lang': localeUi, 'tags[]': tariff_alias };

    const data = await ApiCall.getBanners({ params });

    dispatch({ type: SET_BANNERS, payload: normalizeBanners(data, team_id) });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_BANNERS } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_BANNERS, error: getErrorMessage(state, error.message) },
    });
  }
};

export const getUserSettings = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_USER_SETTINGS } });

  try {
    const { settings: { onlyInitialCart }} = state;
    const response = await ApiCall.getUserSettings();
    const { data, data: { unitySettings = {}, unitySettingsChecksum = '' } } = response;
    const store_basket = data.store_basket || unitySettings.store_basket;
    const last_buy = data.last_buy || unitySettings.last_buy;

    const cart = (store_basket && !onlyInitialCart) ? JSON.parse(store_basket) : {};
    const lastBuy = last_buy ? JSON.parse(last_buy) : [];

    if (!response.success) {
      throw new Error('server');
    }

    dispatch({ type: SET_SAVED_CART, payload: cart });
    dispatch({ type: SET_CHECKSUM, payload: unitySettingsChecksum });
    dispatch({ type: SET_LAST_BUY, payload: lastBuy });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_USER_SETTINGS } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_USER_SETTINGS, error: getErrorMessage(state, error.message) },
    });
  }
};

export const getCountries = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_COUNTRIES } });

  try {
    const data = await ApiCall.getCountries();

    if (!data.success) {
      throw new Error('server');
    }

    dispatch({ type: SET_COUNTRIES, payload: data.data.countries });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_COUNTRIES } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_COUNTRIES, error: getErrorMessage(state, error.message) },
    });
  }
};

export const getContentStore = async (dispatch, state) => {
  dispatch({ type: SET_LOADING_ON, payload: { name: GET_DESCRIPTION_TARIFF } });

  try {
    const data = await ApiCall.getContentStore();

    dispatch({ type: SET_DESCRIPTION_TARIFF, payload: normalizeDescriptionTariffs(data) });
    dispatch({ type: SET_DESCRIPTION_STORE, payload: normalizeDescriptionStore(data) });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_DESCRIPTION_TARIFF } });
  } catch (error) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_DESCRIPTION_TARIFF, error: getErrorMessage(state, error.message) },
    });
  }
};
