import React  from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TableContainer } from './organism/TableContainer';
import { rail_y, thumb_y } from '../../components/scroll';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';

export const PageMembers = () => {
  const { team: { members }} = useSelector(state => state);

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <TableContainer members={members}/>
      </View>
    </ScrollBar>
  );
};

const View = styled.div`
  width: 100%;
  height: 100%;
`;

const InnerCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const ColBold = styled(Col)`
  font-weight: 400;
`;

