export const isElementInViewport = (el, container) => {
  // Получаем размеры и положение элемента относительно окна
  const rect = el.getBoundingClientRect();

  if (!container) {
    // Если контейнер не найден, проверяем элемент относительно окна
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  } else {
    // Получаем размеры и положение контейнера относительно окна
    const containerRect = container.getBoundingClientRect();

    // Проверяем элемент относительно видимой области контейнера
    return (
      rect.top >= containerRect.top &&
      rect.left >= containerRect.left &&
      rect.bottom <= containerRect.bottom &&
      rect.right <= containerRect.right
    );
  }
};

