import {
  SET_LOADING_ON,
  SET_LOADING_OFF,
  SET_SCREEN,
  SET_PROFILE,
  SET_GOODS,
  SET_BINDING_CARD,
  SET_PAYMENT_METHODS,
  SET_COUNTRIES,
  SET_BANNER,
  SET_SELECTED,
  SET_APPLIED_PROMO,
  SET_PAY_DATA,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_SCREEN:
      return {
        ...state,
        screen: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_GOODS:
      return {
        ...state,
        goods: action.payload,
      };
    case SET_BINDING_CARD:
      return {
        ...state,
        bindingCard: action.payload,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_BANNER:
      return {
        ...state,
        banner: action.payload,
      };
    case SET_SELECTED:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload
        },
      };
    case SET_APPLIED_PROMO:
      return {
        ...state,
        appliedPromo: action.payload,
      };
    case SET_PAY_DATA:
      return {
        ...state,
        payData: action.payload,
      };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
            },
          },
          processing: true,
          error: '',
        },
      };

    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }

    default:
      return state;
  }
};
