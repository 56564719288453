import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';

class ClassErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    const { errorInfo, callback = () => {} } = props;

    this.state = {
      error: null,
      errorInfo: errorInfo || {},
      callback,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const { callback } = this.state;

    callback();
  }

  render() {
    const { error, errorInfo } = this.state;

    if (error) {
      return (

        <View>
          <Title dangerouslySetInnerHTML={{__html: errorInfo.title}} />
          <ErrorMessage>{error.message}</ErrorMessage>
        </View>
      );
    }
    return this.props.children;
  }
}

const View = styled.div`
  padding: 15px;
`;
const Title = styled.div`
  font-size: 22px;
  margin-bottom: 10px;
`;
const ErrorMessage = styled.div`
  color: ${Colors.dull_red};
  font-size: 12px;
`;

export const ErrorBoundary = ClassErrorBoundary;
