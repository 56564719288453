import React, { useContext, useEffect } from 'react';
import { Products } from '../../components/Products';
import { Preview } from './organism/preview';
import { Analytics } from '../../modules/analytics';
import { StoreContext } from '../../modules/Store';

export const Widget = ({ products, pricing, team_id, profileGoods, descriptionStore }) => {
  const widget = products.filter((item) => item.view_alias === 'widget');
  const proplus = pricing.find((item) => item.alias === 'proplus');
  const isTrial = profileGoods.tariff_is_trial;
  const content = descriptionStore.widget;
  const [state] = useContext(StoreContext);
  const { settings: { mode, locale }} = state;

  useEffect(() => {
    Analytics.viewWidget(mode, locale);
  }, []);

  return <>{widget.length && !isTrial ? <Products elements={widget} content={content} /> : <Preview proplus={proplus} team_id={team_id} />}</>;
};
