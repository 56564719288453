export const SET_GOODS = 'SET_GOODS';
export const SET_VIEW = 'SET_VIEW';
export const SET_TARIFF = 'SET_TARIFF';
export const SET_USER = 'SET_USER';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_IS_PROCESSING = 'SET_IS_PROCESSING';
export const SET_ERROR = 'SET_ERROR';
export const SET_CURRENT_TEAM = 'SET_CURRENT_TEAM';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const VIEW_MODE = {
  CLOSE: 'CLOSE',
  USER: 'USER',
  NOTIFICATION: 'NOTIFICATION',
};

export const ERROR_TYPES = {
  USER: 'USER',
  TEAMS: 'TEAMS',
  GOODS: 'GOODS',
  SETTINGS: 'SETTINGS',
  NOTIFICATION: 'NOTIFICATION',
};

export const ERROR_MESSAGES = {
  ABORTED: 'Request aborted'
}

export const NOTIFICATION_TYPES = {
  0: 'info',
  1: 'render',
  3: 'widget_top_view',
  4: 'widget_3d_view',
  5: 'widget_360',
  6: 'widget_tour',
  7: 'panorama',
  8: 'estimate',
  9: 'import_visio',
  10: 'bake_ligtmap',
  11: 'tariff_expire',
  12: 'import_model_ready',
  13: 'top_view_model_ready',
  14: 'preview_model_ready',
  15: 'import_model_ready',
  1027: 'preview_design_ready',
};

export const OPEN_GALLERY_NOTIFICATION_TYPES = {
  1: 'render',
  3: 'widget_top_view',
  4: 'widget_3d_view',
  7: 'panorama',
};

export const SHOW_LOT_NOTIFICATION_TYPES = {
  12: 'import_model_ready',
  13: 'top_view_model_ready',
  14: 'preview_model_ready',
  15: 'import_model_ready',
  1027: 'preview_design_ready',
};

export const AUTO_UPDATE_INTERVAL = 20000;
export const SAVE_LAST_NOTIFICATION_THROTTLE = 1500;

// Invoke actions
export const BUTTONS = {
  SET_USER: 'SET_USER',
  LOT_SHOW_INFO: 'LOT_SHOW_INFO',
  LOGOUT: 'LOGOUT',
};

export const USER_WIDGET_OPEN = 'USER_WIDGET_OPEN';
export const USER_WIDGET_CLOSE = 'USER_WIDGET_CLOSE';
export const USER_WIDGET_SET_IS_PROCESSING = 'USER_WIDGET_SET_IS_PROCESSING';
export const USER_WIDGET_CHANGE_USER = 'USER_WIDGET_CHANGE_USER';
