import { PAGES } from '../../constants';

export const initialState = {
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  currentPage: {
    alias: PAGES.layouts,
  },
  profile: {},
  tariff: {},
  goods: [],
  balanceGoods: {
    renderGoods: [],
    widgetGoods: []
  },
  currency: '',
  team: {
    list: [],
    members: [],
    id: '',
    current: null,
    expired: false,
    loading: false
  },
  projects: {
    more: false,
    items: [],
    currentFolder: null,
    foldersTree: [],
    breadcrumbs: [],
  },
  checksum: '', // from userSettings (unitySettingsChecksum)
  notification: {
    list: [],
    lastId: 0,
  },
  settings: {
    teamId: 0,
    locale: 'ru',
    zoom: 1,
    onClose: () => {}
  },
  subscriptions: {},
  historyPayment: {
    processing: true,
    list: [],
    pageCount: 1,
    perPage: 30,
  },
  cards: [],
  stepsUnsubscribe: [],
};
