import { translations } from '@libs';

const errors = {
  'Invalid code': 'form_entry.invalidCode',
  'Invalid email': 'form_entry.notEmail',
  'This email is already in use': 'form_entry.usedEmail',
  'Too many attempts': 'form_entry.codeManyAttempts',
  'Too many attempts reset password': 'form_entry.resetPasswordManyAttempts',
  'Wrong email': 'form_entry.wrongEmail',
  'New password is less than 5 symbols': 'form_entry.passwordLess',
  'Password must contain at least 5 symbols': 'form_entry.passwordLess',
  'Invalid promocode': 'store.promo.invalid',
  'This promocode already used': 'store.promo.used',
  'This promocode is expired': 'store.promo.expired',
  'This promocode not supported': 'store.promo.notsupported',
  'This promocode will be active later': 'store.promo.activelater',
  'Nothing to apply': 'store.promo.nothingapply',
  'This email address already exists': '',
  'Mail sending error': 'store.error.sending',
  'Mail sending failed: unsubscribed': 'store.error.unsubscribed',
  'Mail sending failed: invalid': 'store.error.invalid',
  'Mail sending failed: duplicate': 'store.error.duplicate',
  'Mail sending failed: temporary_unavailable': 'store.error.temporary_unavailable',
  'Mail sending failed: permanent_unavailable': 'store.error.permanent_unavailable',
  'Mail sending failed: complained': 'store.error.complained',
  'Mail sending failed: blocked': 'store.error.blocked',
};

export const getErrorMessage = (text) => {
  const key = errors[text.trim()];

  return key ? translations.t(key) : text;
};
