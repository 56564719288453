import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { rgba } from 'polished';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { Mixins } from "planoplan-ui-kit/core/mixins";

export const Close = () => {
  const { settings: { onClose } } = useSelector(state => state);

  return (
    <View onClick={onClose}>
      <IClose width={38} height={38} fill={rgba(Colors.night, 0.5)} />
    </View>
  );
};

const View = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  cursor: pointer;
  margin-left: 18px;

  svg {
    ${Mixins.transition('transform fill')};
    fill: ${rgba(Colors.white, 0.5)};
  }

  &:hover svg {
    transform: scale(0.9);
    fill: ${Colors.white};
  }
`;
