import { translations } from '@libs';
import {
  GET_GALLERY,
  GET_PROFILE,
  GET_TRANSLATES,
  SET_IMAGES,
  SET_PROJECTS,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_HAS_PDF,
  SET_PROJECT_NAME,
  SET_PROFILE
} from '../constants';
import { getErrorMessages } from './error-messages';
import { normalizeData } from './normalize';
import { openDesignProject, createDesignProject } from './effects';
import { ApiCall } from './api-call';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, /^image_editor./, /^assistant./]);
    } catch (error) {
      console.error(error);

      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: getErrorMessages(locale, error.message) },
      });
    }
  }
};

export const getGallery = () => async (dispatch, getState) => {
  const { settings } = getState();
  const { locale, projectId, folderId } = settings;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_GALLERY } });

  try {
    const params = folderId ? { folder_id: folderId } : { id: projectId };
    const { data } = await ApiCall.getGallery({ params });

    const { images, projects } = normalizeData(data);

    dispatch({ type: SET_PROJECTS, payload: projects });
    dispatch({ type: SET_IMAGES, payload: images });
    dispatch({ type: SET_HAS_PDF, payload: data.hasPdfData });
    dispatch({ type: SET_PROJECT_NAME, payload: data.projectName });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_GALLERY } });

    // Условие для определения что дизайн проект для одного проекта
    if (typeof data.hasPdfData === 'boolean') {
      data.hasPdfData ? await dispatch(openDesignProject()) : await dispatch(createDesignProject());
    } else {
      // Открываем если дизайн проект в папке
      await dispatch(openDesignProject());
    }
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_GALLERY, error: getErrorMessages(locale, error.message) },
    });
  }
};

export const getProfile = () => async (dispatch, getState) => {
  const { settings: { localeUi }, loading } = getState();

  if (loading.error) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROFILE } });

  try {
    const { data } = await ApiCall.getProfile();

    dispatch({ type: SET_PROFILE, payload: data });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROFILE } });

  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PROFILE, error: getErrorMessages(localeUi, error.message) },
    });
  }
};
