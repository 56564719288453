import { Colors } from 'planoplan-ui-kit/core/colors';

// Actions
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_EDUCATIONS = 'SET_EDUCATIONS';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_EDUCATIONS = 'GET_EDUCATIONS';

// Invoke actions
export const BUTTONS = {
  PROJECTS_CLOSE: 'PROJECTS_CLOSE',
};

// Styles
export const BACKGROUNDS = {
  NONE: {
    fill: Colors.coal,
    hover: Colors.coal
  },
  GREEN: {
    fill: Colors.frog_green,
    hover: Colors.pale_green,
  },
  BLUE: {
    fill: Colors.electric_blue,
    hover: Colors.planoplan_blue
  }
}

export const PROJECTS_OPEN = 'PROJECTS_OPEN';
export const PROJECTS_CLOSE = 'PROJECTS_CLOSE';
