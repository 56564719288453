// Парсит строку вида - 'platform:standalone;version:2.5.9b36;lang:ru;os:Windows 10  (10.0.0) 64bit', в объект
export const parseStringToObject = (string = '', separator = ':') => {
  if (!string) {
    return {};
  }

  const properties = string.split(';');

  return properties.reduce((acc, item) => {
    const prop = item.split(separator);
    acc[prop[0]] = prop[1] || true;

    return acc;
  }, {});
};
