import { translations } from '@libs';
import {
  GET_TRANSLATES,
  GET_IMAGE_SIZE,
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_IMAGE,
  SET_ADJUSTMENT,
} from '../constants';
import { getErrorMessages } from './error-messages';
import filtersData from '../filters.json';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { localeUi } = getState().settings;

    try {
      await translations.load(localeUi, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, /^image_editor./, /^assistant./]);
    } catch (error) {
      console.error(error);

      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: getErrorMessages(localeUi, error.message) },
      });
    }
  }
};

export const getImageSize = () => async (dispatch, getState) => {
  const { settings: { localeUi } ,image: { src }} = getState();
  const image = new Image();
  const currentSrc = src.compressed || src.origin;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_IMAGE_SIZE } });

  if (!currentSrc) {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: SET_IMAGE, error: getErrorMessages(localeUi, 'Error image src') },
    });
  }

  image.src = currentSrc;
  image.crossOrigin = 'Anonymous';
  image.onload = () => {
    const imageData = {
      src: src,
      el: image,
      width: image.width,
      height: image.height,
      type: (src.origin || src.compressed).split('.').pop(),
    };

    dispatch({ type: SET_IMAGE, payload: imageData });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_IMAGE_SIZE }});
  };

  image.onerror = () => {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: SET_IMAGE, error: getErrorMessages(localeUi, 'Image error!') },
    });
  };
};

export const getFilters = () => async (dispatch) => {
  try {
    const adjustment = filtersData.adjustment;

    dispatch({ type: SET_ADJUSTMENT, payload: adjustment });
  } catch (error) {
    console.error((error));
  }
};
