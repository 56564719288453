import { IconInfinity } from '../components/Icons/Infinity';
import { ICheckMark } from 'planoplan-ui-kit/core/icons/i-check-mark';

export const normalizeDescriptionTariffs = (data) => {
    try {
        const raw_description = data.split('\n').map((item) => {
            const firstSymbol = item.slice(0, 1);
            let icon = '';
            let text = item;

            if (firstSymbol === '-') {
                icon = ICheckMark;
                text = item.slice(2);
            }

            if (firstSymbol === '*') {
                icon = IconInfinity;
                text = item.slice(2);
            }

            return {
                icon,
                text,
            }
        });
        return raw_description;
    } catch (error) {
        console.error(new Error(error));

        return {};
    }
}