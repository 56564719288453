import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Button } from 'planoplan-ui-kit/core/button';
import { Select } from 'planoplan-ui-kit/core/select';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const MoveProjectToTeamModal = ({ isOpen, onCancel = () => {}, onMove = () => {} }) => {
  const { team, currentTeam } = useSelector(state => state);
  const teams = team.list;
  const options = [{
    view_value: translations.t('modal.move.myProjects'),
    value: '0',
    render: <Option>{translations.t('modal.move.myProjects')}</Option>,
    render_selected: <div>{translations.t('modal.move.myProjects')}</div>,
    disabled: !currentTeam
  }];

  teams.forEach(team => {
    options.push({
      view_value: `${translations.t('modal.moveTeam.team')} #${team.id}`,
      value: team.id,
      render: <Option>{`${translations.t('modal.moveTeam.team')} #${team.id}`}</Option>,
      render_selected: <div>{`${translations.t('modal.moveTeam.team')} #${team.id}`}</div>,
      disabled: currentTeam ? currentTeam.id === team.id : false
    })
  });
  const [selected, setSelected] = useState(options[0]);
  const onSelected = (val) => setSelected(val);

  useEffect(() => {
    setSelected(options[0]);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('modal.moveTeam.title')}</Header>
      <Content>
        <Select
          options={options}
          selected={selected}
          onSelect={onSelected}
          theme="secondary"
          size={'M'}>
          {translations.t('modal.moveTeam.choose')}
        </Select>
      </Content>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" onClick={() => onMove({ teamId: selected.value })}>
          {translations.t('modal.move')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
const Option = styled.div`
  display: flex;
`;
const Nes = styled.div`
  width: 8px;
  height: 6px;
  border-left: 1px solid ${Colors.light_gray};
  border-bottom: 1px solid ${Colors.light_gray};
  margin-right: 5px;
  margin-top: 6px;
`;
const Sp = styled.div`
  width: 8px;
`;
