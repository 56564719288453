import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';

export const ProgressBar = ({ isInstalling = false, width = 0 }) => <View isInstalling={isInstalling} width={width} />;

const checkAnimation = keyframes` 
  0% {
    width: 0;
  }
  25% {
    width: 30%;
    left: 15%;
  }
  50% {
    width: 70%;
    left: 40%;
  }
  100% {
    width: 15%;
    left: 100%;
  }
`;

const View = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-top: -15px; // компенация оступа у Header
  height: 4px;
  position: relative;
  background-color: ${Colors.whisper};
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 4px;
    border-radius: 4px;
  }

  ${({ isInstalling }) =>
    isInstalling &&
    css`
      &::before {
        ${Mixins.transition('width')};
        background: ${Colors.planoplan_blue};
        width: ${({ width }) => width + '%'};
      }
      &::after {
        display: none;
      }
    `}

  ${({ isInstalling }) =>
    !isInstalling &&
    css`
      &::before {
        background: linear-gradient(90deg, ${Colors.planoplan_blue}, ${Colors.electric_blue});
        animation: ${checkAnimation} 1.2s linear infinite;
      }
      &::after {
        background: linear-gradient(90deg, ${Colors.electric_blue}, ${Colors.planoplan_blue});
        animation: ${checkAnimation} 1.2s 0.5s linear infinite;
      }
    `}
`;
