import React  from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { PROJECT_ACTION } from '@globalConstants';
import { actions } from '@globalInvoke';
import { BACKGROUNDS } from '../../constants';
import { Analytics } from '../../modules/analytics';

export const Action = ({ Icon, title, background, alias }) => {
  const click = () => {
    if (background === BACKGROUNDS.NONE) {
      return;
    }

    window.invokeEditorAction({
      name: actions[PROJECT_ACTION].name,
      value: alias,
    });

    if (window.dataLayer) {
      Analytics.createProject(alias)
    }
  };

  return (
      <View background={background} onClick={click}>
        <IconWrap>
          <Icon fill={'white'}/>
        </IconWrap>

        <Title dangerouslySetInnerHTML={{__html: title}}/>
      </View>
  );
};

const View = styled.div`
  ${Mixins.transition('background-color')};
  height: 180px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background.fill};
  border-radius: 10px;
  cursor: ${props => props.background === BACKGROUNDS.NONE ? 'default' : 'pointer'};
  
  &:hover {
    background-color: ${props => props.background.hover};
  }
`;

const IconWrap = styled.div`
  margin-bottom: 16px;
  
  svg {
    display: block;
  }
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 19px;
`;
