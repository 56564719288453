import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import * as actions from '../../../modules/Store/action';
import { IconArrow } from '@icons'

export const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { paymentMethods, bindingCard, selected: { paymentMethod }} = useSelector(state => state);

  const [left , setLeft] = useState(0);
  const [isMinScroll, setIsMinScroll] = useState(left === 0);
  const [isMaxScroll, setIsMaxScroll] = useState(false);
  const listRef = useRef();
  const stepLeft = 200;

  const refsById = useMemo(() => {
    const refs = {};

    [...bindingCard, ...paymentMethods].forEach((option) => {
      refs[option.id] = React.createRef(null)
    });
    return refs
  }, [bindingCard, paymentMethods]);

  const scrollNext = () => {
    const el = listRef.current;
    const newPosition = left + stepLeft;
    const maxPosition = el.scrollWidth - el.clientWidth;

    setLeft(newPosition > maxPosition ? maxPosition : newPosition);
  };
  const scrollPrev = () => {
    const newPosition = left - stepLeft;

    setLeft(newPosition < 0 ? 0 : newPosition);
  };
  const onClick = (option) => {
    const el = refsById[option.id].current;

    const positionEl = {
      start: el.offsetLeft,
      end: el.offsetLeft + el.offsetWidth,
    };
    const container = {
      start: left,
      end: left + listRef.current.offsetWidth,
    };

    if (positionEl.start < container.start) {
      const pos = (container.start - positionEl.start) + 20; // 20 это отступ

      setLeft(left - pos);
    } else if (positionEl.end > container.end) {
      const pos = (positionEl.end - container.end) + 20; // 20 это отступ

      setLeft(left + pos);
    }

    dispatch(actions.setPaymentMethod(option));
  };

  useEffect(() => {
    const el = listRef.current;
    const maxPosition = el.scrollWidth - el.clientWidth;

    setIsMinScroll(left === 0);
    setIsMaxScroll(left >= maxPosition);
  }, [left]);

  return (
    <View>
      {!isMinScroll && <Prev onClick={scrollPrev}><IconArrow /></Prev>}

      <Wrapper>
        <List ref={listRef} left={left}>
          {bindingCard.map(option => (
            <Item key={option.id} active={paymentMethod.id === option.id} ref={refsById[option.id]} onClick={() => onClick(option)}>
              {option.Icon ? <option.Icon /> : option.brand.toUpperCase()}

              <CardNumber>** {option.info.last4}</CardNumber>
            </Item>
          ))}

          {paymentMethods.map(option => (
            <Item key={option.id} active={paymentMethod.id === option.id} ref={refsById[option.id]} onClick={() => onClick(option)}>
              {option.Icon ? <option.Icon /> : option.name}
            </Item>
          ))}
        </List>
      </Wrapper>
      {!isMaxScroll && <Next onClick={scrollNext}><IconArrow /></Next>}
    </View>
  );
};

const View = styled.div`
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  align-items: center;
`;
const Arrow = styled.div`
  ${Mixins.transition('transform')};
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.coal};
  flex-shrink: 0;
  cursor: pointer;
`;
const Prev = styled(Arrow)`
  left: 0;
  transform: translate(-50%, -50%);
  
  &:hover {
    transform: translate(-60%, -50%);
  }
`;
const Next = styled(Arrow)`
  right: 0;
  transform: translate(50%, -50%);
  
  svg {
    transform: rotate(180deg);
  }
  
  &:hover {
    transform: translate(60%, -50%);
  }
`;
const Wrapper = styled.div`
  overflow: hidden;
`;
const List = styled.div`
  ${Mixins.transition('transform')};
  display: flex;
  align-items: center;
  transform: translateX(${({ left }) => `-${left}px`});
  
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
  }
`;
const Item = styled.div`
  height: 48px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${({ active }) => active ? Colors.dark_cream : Colors.nobel};
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  
  &:not(:last-child) {
    margin-right: 10px;
  }
  
  &:first-child {
    margin-left: 20px;
  }
`;
const CardNumber = styled.div`
  font-size: 14px;
  color: ${Colors.nobel};
  white-space: nowrap;
  margin-left: 10px;
`;
