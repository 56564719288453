import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const UploadImageToDesignProject = ({ isOpen, onCancel = () => {}, onUpload = () => {} }) => {
  const [url, setUrl] = useState('');
  const InputEl = useRef();

  useEffect(() => {
    setUrl('');

    setTimeout(() => {
      if (InputEl && InputEl.current) {
        InputEl.current.focus();
      }
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>{translations.t('gallery.pdf.upload.url.title')}</Header>
      <Content>
        <Input
          type="text"
          theme="secondary"
          placeholder={translations.t('gallery.pdf.upload.url.input')}
          onChange={(event) => setUrl(event.value)}
          value={url}
          required
          ref={InputEl} />
      </Content>
      <Buttons>
        <Button theme="secondary" size="M" onClick={onCancel}>
          {translations.t('modal.cancel')}
        </Button>

        <Button theme="primary" size="M" disabled={!url} onClick={() => onUpload(url)}>
          {translations.t('modal.upload')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: 160px;
  }
`;
