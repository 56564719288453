import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Button } from 'planoplan-ui-kit/core/button';
import { actions, url } from '@globalInvoke';
import { OPEN_PROJECT, URL_OPEN } from '@globalConstants';
import { rail_y, thumb_y } from '../../modules/scroll';
import { translations } from '@libs';
import { ON_CLOSE_CABINET } from "@observer/constants";

const observer = new window.POPObserver();

export const InfoTariff = () => {
  const { banner, settings: { openProject, onClose }} = useSelector(state => state);

  const openLinkVideo = () => {
    window.invokeEditorAction({
      name: url[URL_OPEN].name,
      value: banner.video,
    });
  };

  const onOpenProject = () => {
    if (openProject) {
      window.invokeEditorAction({
        name: actions[OPEN_PROJECT].name,
        value: `${openProject}`,
      });

      onClose();
      observer.postMessage(ON_CLOSE_CABINET);
    }
  };

  return (
      <View>
        <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
          <Content>
            <Title>{banner.caption}</Title>
            <ImageWrap>
              <Image src={banner.image}/>
              <ImageLink onClick={openLinkVideo}/>
            </ImageWrap>
            <Text>{banner.description}</Text>
            {/*<Text>{translations.t('quickstore.information.proplus')}</Text>*/}
          </Content>
        </ScrollBar>

        {Boolean(openProject) && (
          <OpenProject>
            <Button widthText theme="secondary" size="S" onClick={onOpenProject}>
              {translations.t('quickstore.openProject')}
            </Button>
          </OpenProject>
        )}
      </View>
  );
};

const View = styled.div`
  padding: 20px 0 20px 20px;
  height: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Content = styled.div`
  padding-right: 20px;
`;
const Title = styled.div`
  color: ${Colors.night};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
`;
const ImageWrap = styled.div`
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: auto;
`;
const ImageLink = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  cursor: pointer;
`;
const Text = styled.div`
  margin-top: 15px;
  font-size: 14px;
`;
const OpenProject = styled.div`
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;
