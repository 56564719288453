import { sortBy, parseStringToObject } from '@libs';
import { STATUSES, RENDER_SIZES } from '../constants';

export const normalizeData = (data) => {
  const filters = [{ value: 'all' }];
  const getImages = (items, projectId = null) => {
    const images = items.filter(item => typeof item.original === 'string').map((item, index) => {
      const image = {};
      const { roomCaption, roomArea, resolution, orientation } = parseTaskData(item.task_data, item.resolution);
      const normalizeRoomArea = roomArea ? Math.floor(Number(roomArea) * 10) / 10 : '';
      const value = getFilterItem(roomCaption, normalizeRoomArea);

      // устанавливаем фильтры иcходя из roomCaption и roomArea
      if (value && !filters.some((item) => item.value === value)) {
        filters.push({
          value,
          roomCaption,
          roomArea: normalizeRoomArea,
        });
      }

      // если original содержит ссылку на QR code, то считаем что это панорама
      const qr_url = item.original ? new URL(item.original) : '';
      const chl = qr_url && new URLSearchParams(qr_url.search).get('chl');

      if (chl) {
        const chl = new URLSearchParams(qr_url.search).get('chl');
        const chl_url = new URL(chl);
        const panorama_query = new URLSearchParams(chl_url.search);
        const url = panorama_query.get('popurl');

        image.qr_url = qr_url;
        image.krpano_url = url;
      } else {
        image.original = `${item.original}?${item.update_at}` || `${item.preview}?${item.update_at}`; // если нет original, берем preview за основу
      }

      const size = getSizeOriginal(resolution, orientation);

      image.id = item.id;
      image.view_id = index + 1;
      image.preview = `${item.preview}?${item.update_at}`;
      image.publishId = item.publish_id;
      image.roomCaption = roomCaption;
      image.roomArea = normalizeRoomArea;
      image.filter = value;
      image.caption = value;
      image.status = STATUSES[item.task_status];
      image.isComplete = item.task_status === 2;
      image.isProgress = item.task_status === 0 || item.task_status === 1;
      image.isError = item.task_status === 3 || item.task_status === 4 || item.task_status === 5;
      image.isFilter = true;
      image.isChecked = false;
      image.isLoaded = false;
      image.isAvailableCheck = !!item.id && image.isComplete; // можно выбирать элемент если у него есть id
      image.isProhibitedDelete = false; // только когда открытие по урлу
      image.isVertical = orientation === 'vertical';
      image.editor = item; // данные в редактор передаем в оригинальном виде
      image.size = size;
      image.captionSize =
        size.width && size.height ? `${RENDER_SIZES[size.width] || ''} ${size.width}x${size.height}px` : '';
      image.updateAt = item.update_at;
      image.projectId = projectId;
      image.isPreview = item.isPreview || false;

      return image;
    });

    // waiting, inprocess, cancel, fail, reject, complete
    const order = [STATUSES[0], STATUSES[1], STATUSES[3], STATUSES[4], STATUSES[5], STATUSES[2]];
    const sorted_images = sortBy(images, order, 'status');

    return {
      images: sorted_images,
      filters,
      projects: [],
    };
  };

  if (data.items) {
    return getImages(data.items);
  } else if (Array.isArray(data) && data.length) {
    /* Вывод галлереи для папки не реализован, поэтому вывожу только галлерею первого проекта */
    return {
      ...getImages(data[0] ? data[0].items : []),
      projects: data.map((project) => {
        return {
          id: project.projectId,
          name: project.projectName,
          ...getImages(project.items, project.projectId),
        }
      }),
    };
  } else {
    return {
      images: [],
      filters,
      projects: [],
    }
  }
};

// Парсим строку task_data в объект и конвертируем resolution
const parseTaskData = (task_data, resolution = '') => {
  const data = parseStringToObject(task_data, '=');
  const size = resolution.split('_');

  for (let prop in data) {
    if (prop.includes('resolution')) {
      data.resolution = data[prop].split('x');
    }
  }

  return {
    ...data,
    resolution: size.length === 2 ? size : data.resolution,
  };
};

// Устанавливаем поле фильтра исходя из полей roomCaption и roomArea
const getFilterItem = (roomCaption, roomArea) => {
  if (roomCaption && roomArea) {
    return `${roomCaption} ${roomArea}`;
  }

  if (roomCaption && !roomArea) {
    return roomCaption;
  }

  if (!roomCaption && !roomArea) {
    return '';
  }
};

// Устанавливаем размеры оригинального рендера
const getSizeOriginal = (resolution, orientation) => {
  const result = { width: '', height: '' };

  if (resolution) {
    const width = Number(resolution[0]);
    const height = Number(resolution[1]);

    if (orientation === 'vertical') {
      result.width = width < height ? width : height;
      result.height = width < height ? height : width;
    } else if (orientation === 'square') {
      result.width = height;
      result.height = height;
    } else if (orientation === 'horizontal') {
      result.width = width > height ? width : height;
      result.height = width > height ? height : width;
    } else {
      result.width = width;
      result.height = height;
    }
  }

  return result;
};
