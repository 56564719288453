export const initialState = {
  projects: [],
  educations: {},
  settings: {},
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
};
