import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { getCurrentPrices } from '../../../modules/helpers';

export const PriceLine = ({ loadingPrice = false }) => {
  const dispatch = useDispatch();
  const { profile: { viewCurrency }} = useSelector(state => state);

  const {
    priceWithoutDiscount,
    discountWithPromo,
    price,
    vat,
    priceVat
  } = useMemo(() => dispatch(getCurrentPrices()));

  return (
    <View loading={loadingPrice ? 1 : 0}>
      {Boolean(discountWithPromo) && <Through>{priceWithoutDiscount} {viewCurrency}</Through>}
      {price}{viewCurrency}
      {priceVat ? ` + ${priceVat} ${viewCurrency} (${translations.t('quickstore.vat')} ${vat}%)` : ''}
    </View>
  );
};

const View = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: ${Colors.dark_cream};
  text-align: center;
  opacity: ${({ loading }) => loading ? 0.5 : 1};
`;
const Through = styled.span`
  text-decoration-line: line-through;
  padding-right: 5px;
`;
