import React from 'react';
import MediaQuery from 'react-responsive';
import { Select } from 'planoplan-ui-kit/core/select';
import { Link } from '@components/link';
import { translations } from '@libs';
import { ENTRY_STEPS } from '../../constants';
import { Header, ButtonAction } from '../../components';
import { tablet_landscape } from '../../modules/breakpoints';

export const Domains = ({ setStep, domains, selectedDomain, onSelectedDomain, onSuccess, isProcessing }) => {
  return (
    <>
      <Header title={translations.t('form_entry.domains.header')}>
        <Link size="S" onClick={() => setStep(ENTRY_STEPS.LOGIN)} data-test="button_back-login">
          {translations.t('form_entry.back.login')}
        </Link>
      </Header>

      <MediaQuery query={tablet_landscape}>
        {(matches) => (
          <Select
            options={domains}
            selected={selectedDomain}
            onSelect={onSelectedDomain}
            theme="primary"
            maxHeight="100px"
            size={matches ? 'M' : 'S'}>
            {translations.t('form_entry.domains.domain')}
          </Select>
        )}
      </MediaQuery>

      <ButtonAction loading={isProcessing} onClick={onSuccess} data-test="button_try-again" useButtonLoader>
        {translations.t('form_entry.continue')}
      </ButtonAction>
    </>
  );
};
