// Screens
import { Colors } from 'planoplan-ui-kit/core/colors';
import { isProdHost, isLocalHost } from '@libs/is-host';

export const REVIEW = 'REVIEW';
export const PACKAGES = 'PACKAGES';
export const PANORAMAS = 'PANORAMAS';
export const RENDERS = 'RENDERS';
export const WIDGET = 'WIDGET';
export const CLOUD = 'CLOUD';
export const PRICING = 'PRICING';
export const CART = 'CART';
export const LIST = 'LIST';
export const DETAIL = 'DETAIL';
export const YOOKASSA = 'YOOKASSA';
export const STRIPE = 'STRIPE';
export const PAYPAL = 'PAYPAL';
export const BINDING = 'BINDING';
export const SUCCESS_BINDING = 'SUCCESS_BINDING';
export const REQUIRE = 'REQUIRE';

export const SCREEN_ALIAS = {
  review: REVIEW,
  packages: PACKAGES,
  renders: RENDERS,
  widget: WIDGET,
  cloud: CLOUD,
  pricing: PRICING,
  cart: CART,
  binding: BINDING,
  require: REQUIRE,
};

// Errors
export const ERROR = 'ERROR';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';
export const WRONG = 'WRONG';

// Actions
export const SET_SCREEN = 'SET_SCREEN';
export const SET_PRODUCT_SCREEN = 'SET_PRODUCT_SCREEN';
export const SET_AVAILABLE_SCREENS = 'SET_AVAILABLE_SCREENS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRICING = 'SET_PRICING';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_BINDING_CARD = 'SET_BINDING_CARD';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const CHANGE_COUNT_PRODUCT = 'CHANGE_COUNT_PRODUCT';
export const CHANGE_PRICE_FOR_SET = 'CHANGE_PRICE_FOR_SET';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_PAY = 'SET_PAY';
export const SET_PROFILE_GOODS = 'SET_GOODS_PROFILE';
export const SET_SAVED_CART = 'SET_SAVED_CART';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_LAST_BUY = 'SET_LAST_BUY';
export const SET_PRODUCTS_REVIEW = 'SET_PRODUCTS_REVIEW';
export const SET_SUM_WITH_PROMO = 'SET_SUM_WITH_PROMO';
export const SET_PROMO = 'SET_PROMO';
export const SET_CHECKSUM = 'SET_CHECKSUM';
export const SET_WIDGET = 'SET_WIDGET';
export const SET_BINDING_SUCCESS = 'SET_BINDING_SUCCESS';
export const SET_DESCRIPTION_TARIFF = 'SET_DESCRIPTION_TARIFF';
export const SET_DESCRIPTION_STORE = 'SET_DESCRIPTION_STORE';
export const SET_VIDEO_POPUP_SRC = 'SET_VIDEO_POPUP_SRC';
export const SET_SCROLL_Y = 'SET_SCROLL_Y';

// Getters (from backend)
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PRICING = 'GET_PRICING';
export const GET_GOODS = 'GET_GOODS';
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_BINDING_CARD = 'GET_BINDING_CARD';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
export const GET_BANNERS = 'GET_BANNERS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_BINDING_DATA = 'GET_BINDING_DATA';
export const GET_DESCRIPTION_TARIFF = 'GET_DESCRIPTION_TARIFF';

// Invoke actions
export const BUTTONS = {
  STORE_CLOSE: 'STORE_CLOSE',
};
export const STORE_OPEN = 'STORE_OPEN';
export const PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL';

// Pay
export const PAY_STATUSES = {
  succeeded: 'succeeded', // зачислен на баланс (/store/pay)
  pending: 'pending', // ожидает действия от стороннего сервиса (/store/pay)
  processing: 'processing', // еще не завершился (/store/pay)
  unpaid: 'unpaid', // создан, но не оплачен (/store/checkStatus)
  paid: 'paid', // оплачен (/store/checkStatus)
  wrong: 'wrong', // не вверно (/store/checkStatus)
  error: 'error', // ошибка (/store/checkStatus)
  requires_payment_method: 'requires_payment_method', // запрос платежного метода (для 3D-secure)
};

// Banners
export const BANNERS_TAGS = {
  static: 'static',
  rotation: 'rotation',
};

// Errors categories
export const ERROR_MESSAGE_CATEGORIES = {
    YOOKASSA: 'yookassa',
    DEFAULT: 'default',
};

// Errors
export const ERROR_MESSAGES = {
    [ERROR_MESSAGE_CATEGORIES.YOOKASSA]: {
        'default': 'store.cart.wrong',
        '3d_secure_failed': 'store.cart.wrong.3d_secure_failed',
        'call_issuer': 'store.cart.wrong.call_issuer',
        'canceled_by_merchant': 'store.cart.wrong.canceled_by_merchant',
        'card_expired': 'store.cart.wrong.card_expired',
        'country_forbidden': 'store.cart.wrong.country_forbidden',
        'expired_on_capture': 'store.cart.wrong.expired_on_capture',
        'expired_on_confirmation': 'store.cart.wrong.expired_on_confirmation',
        'fraud_suspected': 'store.cart.wrong.fraud_suspected',
        'general_decline': 'store.cart.wrong.general_decline',
        'identification_required': 'store.cart.wrong.identification_required',
        'insufficient_funds': 'store.cart.wrong.insufficient_funds',
        'internal_timeout': 'store.cart.wrong.internal_timeout',
        'invalid_card_number': 'store.cart.wrong.invalid_card_number',
        'invalid_csc': 'store.cart.wrong.invalid_csc',
        'issuer_unavailable': 'store.cart.wrong.issuer_unavailable',
        'payment_method_limit_exceeded': 'store.cart.wrong.payment_method_limit_exceeded',
        'payment_method_restricted': 'store.cart.wrong.payment_method_restricted',
        'permission_revoked': 'store.cart.wrong.permission_revoked',
        'unsupported_mobile_operator': 'store.cart.wrong.unsupported_mobile_operator',
    },
    [ERROR_MESSAGE_CATEGORIES.DEFAULT]: {
        default: 'site.notification.tryLater',
    },
};

// Countries
export const COUNTRIES_CODE = {
  'AT': 'AT',
  'BE': 'BE',
  'BG': 'BG',
  'CY': 'CY',
  'CZ': 'CZ',
  'DE': 'DE',
  'DK': 'DK',
  'EE': 'EE',
  'GR': 'GR',
  'ES': 'ES',
  'FI': 'FI',
  'FR': 'FR',
  'HR': 'HR',
  'HU': 'HU',
  'IE': 'IE',
  'IT': 'IT',
  'LT': 'LT',
  'LU': 'LU',
  'LV': 'LV',
  'MT': 'MT',
  'NL': 'NL',
  'PL': 'PL',
  'PT': 'PT',
  'RO': 'RO',
  'SE': 'SE',
  'SI': 'SI',
  'SK': 'SK'
};

// PayMethods
export const PAY_METHODS = {
  CARD: 'card',
  SEPA_DEBIT: 'sepa_debit',
  ALIPAY: 'alipay',
  BANCONTACT: 'bancontact',
  EPS: 'eps',
  GIROPAY: 'giropay',
  IDEAL: 'ideal',
  P24: 'p24',
  SOFORT: 'sofort',
};

// Currency
export const CURRENCY = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
};

export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
};

export const GOODS_TARIFF_ALIAS = ['tariff', 'businessvr'];

export const BACKGROUND_TARIFFS = {
  [TARIFF_ALIAS.START]: {
    background: Colors.planoplan_blue,
    color: Colors.planoplan_blue,
  },
  [TARIFF_ALIAS.PRO]: {
    background: 'linear-gradient(90deg, #FFB540 0%, #FF894D 50%, #FFB540 100%)',
    color: '#FF894D'
  },
  [TARIFF_ALIAS.PROPLUS]: {
    background: 'linear-gradient(90deg, #C97BE5 0%, #782EF6 50%, #C97BE5 100%)',
    color: '#792FF7'
  },
  [TARIFF_ALIAS.BUSINESS]: {
    background: 'linear-gradient(90deg, #EA4D49 0%, #BF3BC4 50%, #EA4D49 100%)',
    color: '#EA4D49'
  },
};

// id товаров от куда будет браться цена тарифов
export const TARIFF_JOIN_GOODS = {
  [TARIFF_ALIAS.PRO]: {
    year: (isProdHost /*|| isLocalHost*/) ? 113 : 117,
    month: (isProdHost /*|| isLocalHost*/) ? 119 : 115,
  },
  [TARIFF_ALIAS.PROPLUS]: {
    year: (isProdHost /*|| isLocalHost*/) ? 120 : 120,
    month: (isProdHost /*|| isLocalHost*/) ? 115 : 118,
  },
  [TARIFF_ALIAS.BUSINESS]: {
    year: (isProdHost /*|| isLocalHost*/) ? 126 : 122,
    month: (isProdHost /*|| isLocalHost*/) ? 125 : 121,
  }
};

export const TYPES_GOODS = {
  tariff: 'tariff',
  panorama: 'panorama',
  render: 'render'
};

export const ID_PAY_METHOD_PAYPAL = 34000000;
