import { translations } from '@libs';
import { ERROR_MESSAGES, ERROR_MESSAGE_CATEGORIES } from '../constants';

export const getErrorMessage = (error, type = ERROR_MESSAGE_CATEGORIES.DEFAULT) => {
  const getErrorText = () => (typeof error === 'string' && error ? error : 'default');
  const getErrorObjectMessage = () => (typeof error === 'object' && error.message ? error.message : '');
  const text = getErrorObjectMessage() || getErrorText();

  const messages = ERROR_MESSAGES[type] || ERROR_MESSAGES[ERROR_MESSAGE_CATEGORIES.DEFAULT];
  const key = messages[text.trim()];

  return key ? translations.t(key) : translations.t(messages['default']);
};
