import React from 'react';
import styled from 'styled-components';
import { tablet_landscape } from '../../modules/breakpoints';

export const Form = ({ onSubmit, children, isInner = true, isMarginTop = false, style }) => (
  <View isInner={isInner} style={style} isMarginTop={isMarginTop} onSubmit={onSubmit}>
    {children}
  </View>
);

const View = styled.form`
  display: flex;
  flex-direction: column;
  min-height: ${(p) => (p.isInner ? '100%' : '190px')};
  flex-grow: ${(p) => (p.isInner ? 1 : 0)};
  margin-top: ${(p) => p.isMarginTop && '60px'};

  @media ${tablet_landscape} {
    min-height: ${(p) => (p.isInner ? '100%' : '265px')};
  }
`;
