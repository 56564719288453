import { formatKBytes, translations } from '@libs';

export const setCloudSize = (quota_limit, quota_usage) => {
  const freeSpace = quota_limit - quota_usage;

  const result = freeSpace > 0 ? formatKBytes(freeSpace) : 0;

  if (result) {
    return `${result} ${translations.t('user_widget.of')} ${formatKBytes(quota_limit)}`;
  } else {
    return '';
  }
};
