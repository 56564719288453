import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import * as actions from '../modules/Store/action';
import { MODE_PAY } from '../constants/card';
import { SCREENS } from '../constants';
import { rail_y, thumb_y } from '../modules/scroll';
import { Back } from '../components/Back';

export const YookassaScreen = memo(() => {
  const dispatch = useDispatch();
  const { payData, selected: { paymentMethod }, settings: { zoom }} = useSelector(state => state);
  const method = paymentMethod.modePay === MODE_PAY.bank ? paymentMethod.alias : paymentMethod.type;

  const init = () => {
    const checkout = new window.YooMoneyCheckoutWidget({
      confirmation_token: payData.confirmation_token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
      customization: {
        //Выбор способа оплаты для отображения
        payment_methods: [method],
        colors: {
          //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
          controlPrimary: Colors.planoplan_blue, //Значение цвета в HEX,
          controlPrimaryContent: Colors.white
        }
      },
      error_callback: function (error) {
        console.error(error);
      }
    });

    checkout.on('complete', () => {
      dispatch(actions.setScreen(SCREENS.pending));
    });

    checkout.on('fail', () => {
      dispatch(actions.setScreen(SCREENS.error));
      notifications.showError(translations.t('store.common.payment_wrong'));
    });

    checkout.render('payment-form');
  };

  useEffect(() => {
    if (!window.YooMoneyCheckoutWidget) {
      const script = document.createElement('script');

      script.src = 'https://yookassa.ru/checkout-widget/v1/checkout-widget.js';
      script.async = true;
      script.onload = () => init();

      document.body.appendChild(script);
    } else {
      init();
    }
  }, []);

  return (
    <View>
      <Back />

      <Content>
        <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
          <Yookassa id="payment-form" zoom={zoom} />
        </ScrollBar>
      </Content>
    </View>
  );
});

const View = styled.div`
  width: 720px;
  height: 580px;
  background-color: ${Colors.night};
  padding: 18px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
`;
const Content = styled.div`
  width: 460px;
  height: 100%;
  overflow: auto;
  flex-shrink: 0;
`;
const Yookassa = styled.div`
  width: ${({ zoom }) => 100 / zoom}%;
  transform: scale(${({ zoom }) => zoom});
  transform-origin: 0 0;
`;
