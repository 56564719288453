import Cookies from 'js-cookie';

export class Cookie {
  static get(key) {
    return Cookies.get(key);
  }

  static getJSON(key) {
    return Cookies.getJSON(key);
  }

  static set(key, value, params) {
    return Cookies.set(key, value, params);
  }

  static remove(key) {
    Cookies.remove(key);
  }

  static has(key) {
    return !!Cookies.get(key);
  }
}
