import { Colors } from 'planoplan-ui-kit/core/colors';

// Actions
export const SET_LOADING_ON = 'SET_LOADING_ON';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const SET_SLIDES = 'SET_SLIDES';
export const SET_CURRENT = 'SET_CURRENT';

// Getters (from backend)
export const GET_TRANSLATES = 'GET_TRANSLATES';
export const GET_SLIDES = 'GET_SLIDES';

export const GETTING_STARTED_OPEN = 'GETTING_STARTED_OPEN';
export const GETTING_STARTED_CLOSE = 'GETTING_STARTED_CLOSE';
