import { fabric } from 'fabric';

let instance = null;

export class Canvas {
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  async init({ el, src = '', adjustments = {}, webgl = true }) {
    this.ready = false;

    this.canvas = new fabric.Canvas(el);
    this.parentSize = this._getParentSize();
    this.image = await this._loadImage(src);

    if (webgl) {
      fabric.textureSize = 4096;
      fabric.filterBackend = fabric.initFilterBackend();
    } else {
      fabric.filterBackend = new fabric.Canvas2dFilterBackend();
    }

    this.filters = this._getFilters(adjustments);
    this.changed = {filter: false, adjustments: false};
    this.isChanged = false;

    this.canvas.setWidth(this.image.width);
    this.canvas.setHeight(this.image.height);
    this.canvas.add(this.image.cleanObj);
    this.canvas.add(this.image.obj);

    this._addEventsListener();

    this.ready = true;
  }

  _loadImage(src) {
    return new Promise((resolve) => {
      const currentSrc = src.compressed || src.origin;

      fabric.Image.fromURL(currentSrc, (image) => {
        const { width, height } = this._getImageSize(image, this.parentSize);

        image.scaleToWidth(width);
        image.scaleToHeight(height);

        resolve({
          src: src,
          obj: image,
          cleanObj: fabric.util.object.clone(image),
          type: (src.origin || src.compressed).split('.').pop(),
          name: (src.origin || src.compressed).split('/').pop().split('.')[0],
          width,
          height
        });
      }, {
        selectable: false,
        hoverCursor: 'default',
        crossOrigin: 'anonymous',
      });
    });
  }

  _addEventsListener() {
    this.resizeHandler = this._resizeCanvas.bind(this);

    window.addEventListener('resize', this.resizeHandler);
  }

  _getParentSize() {
    return {
      maxWidth: this.canvas.wrapperEl.parentNode.offsetWidth,
      maxHeight: this.canvas.wrapperEl.parentNode.offsetHeight,
    }
  };

  _resizeCanvas() {
    this.parentSize = this._getParentSize();

    const { width, height } = this._getImageSize(this.image.obj, this.parentSize);

    this.image.obj.scaleToWidth(width);
    this.image.obj.scaleToHeight(height);

    this.image.cleanObj.scaleToWidth(width);
    this.image.cleanObj.scaleToHeight(height);

    this.image.width = width;
    this.image.height = height;

    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
  }

  _getImageSize(image, { maxWidth, maxHeight }) {
    let width = image.width;
    let height = image.height;
    const ratio = width / height;

    if (width > maxWidth) {
      width = maxWidth;
      height = width / ratio;
    }

    if (height > maxHeight) {
      height = maxHeight;
      width = height * ratio;
    }

    return {
      width,
      height
    };
  }

  _getFilters(adjustments = []) {
    const filtersObj = {};

    adjustments.forEach((filter, key) => {
      try {
        if (!fabric.Image.filters[filter.class]) {
          throw new Error(`filter Class with alias '${filter.class}' not found!`);
        }

        filtersObj[filter.class] = {
          key,
          filter: new fabric.Image.filters[filter.class],
          ...filter,
        };
      } catch (error) {
        console.error(error);
      }
    });

    return filtersObj;
  }

  compare(val) {
    if (!this.ready) {
      return;
    }

    if (val) {
      this.image.cleanObj.bringToFront();
    } else {
      this.image.cleanObj.sendToBack();
    }
  }

  applyAdjustment(adjustments = []) {
    if (!this.ready) {
      return;
    }

    const isValueDefault = (value, def) => {
      if (Array.isArray(value) && value) {
        return !Boolean(value.filter(vl => Number(vl) !== Number(def)).length);
      }

      return Number(value) === Number(def);
    };

    this.changed.adjustments = false;

    adjustments.forEach((adjustment) => {
      let value = adjustment.valueArr ? adjustment.valueArr.map(() => adjustment.value) : adjustment.onlyIntValue ? parseInt(adjustment.value, 10) : adjustment.value;

      if (!isValueDefault(value, (adjustment.centerAt || 0))) {
        this.changed.adjustments = true;
      }

      this.isChanged = this.changed.adjustments || this.changed.filter;

      if (adjustment.value !== this.filters[adjustment.class].value) {
        const key = this.filters[adjustment.class].key;
        const prop = adjustment.prop;

        if (isValueDefault(value, (adjustment.centerAt || 0))) {
          this.image.obj.filters[key] = null;
          this.filters[adjustment.class].value = null;
        } else {
          if (!this.image.obj.filters[key]) {
            this.image.obj.filters[key] = this.filters[adjustment.class].filter;
          }

          this.image.obj.filters[key][prop] = value;
          this.filters[adjustment.class].value = value;
        }
      }
    });

    this.image.obj.applyFilters();
    this.canvas.renderAll();
  }

  getImageBase64() {
    if (!this.ready) {
      return;
    }

    return new Promise((resolve) => {
      fabric.Image.fromURL(this.image.src.origin, (image) => {
        image.scaleToWidth(this.image.width);
        image.scaleToHeight(this.image.height);

        image.filters = this.image.obj.filters;
        image.applyFilters();

        this.canvas.add(image);
        this.canvas.renderAll();

        const ratio = image.width / this.canvas.getWidth();
        const format = this.image.type === 'jpg' ? 'jpeg' : this.image.type;

        resolve(this.canvas.toDataURL({
          format,
          quality: 1,
          multiplier: ratio
        }));

        this.canvas.remove(image);
      }, {
        selectable: false,
        hoverCursor: 'default',
        crossOrigin: 'anonymous',
      });
    });
  }

  async save() {
    if (!this.ready) {
      return;
    }

    const imageBase64 = await this.getImageBase64();
    const link = document.createElement('a');

    link.download = `${this.image.name}`;
    link.href = imageBase64;
    link.click();
  }

  destroy() {
    this.parentSize = null;
    this.filters = null;
    this.image = null;
    this.canvas = null;
    this.ready = false;

    window.removeEventListener('resize', this.resizeHandler);
  }
}
