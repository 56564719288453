import React, { useEffect } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { MODE_ALIAS, UNITY, URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { RENDER_SIZES, COMPARISON_RENDERS, RENDER_SIZES_ALIAS } from '../../constants';
import * as effects from '../../modules/effects';
import * as getData from '../../modules/getdata';
import { BeforeAfterSlider } from '../BeforeAfterSlider';
import { Analytics } from '../../modules/analytics';
import { Close } from './organizm/close';
import { Renders } from './organizm/renders';

export const ImproveModal = ({ isOpen, zoom = 1, onClose }) => {
  const dispatch = useDispatch();
  const { loading: { processing }, images, current_image, project_name, settings: { mode, locale, projectId }} = useSelector(state => state);
  const urlToComparisonRender = 'https://files.planoplan.com/public/blog/20230627/planoplan-render.jpg';
  const nameCurrentRender = current_image.isPreview ? RENDER_SIZES_ALIAS.PREVIEW : RENDER_SIZES?.[current_image?.size?.width];
  const afterImage = current_image.isPreview ? COMPARISON_RENDERS.PREVIEW : COMPARISON_RENDERS[nameCurrentRender || RENDER_SIZES_ALIAS.PREVIEW];

  const onRenderPreview = async (goods) => {
    if (processing) {
      return;
    }

    Analytics.onRenderPreview(projectId, goods.additional.priceView, goods.count);

    const isHasPay = await dispatch(effects.toRenderPreview(goods, onClose));

    if (isHasPay) {
      dispatch(getData.getGallery({ reload: true }));
      dispatch(getData.getGoods());
    }
  };
  const openComparisonRender = (event) => {
    if (MODE_ALIAS[mode] === UNITY && urlToComparisonRender) {
      event.preventDefault();

      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: urlToComparisonRender,
      });
    }
  };
  const handleOnClose = () => {
    Analytics.onCloseRenderPreview(projectId);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      Analytics.showModalImprove({
        locale,
        projectId,
        project_name,
        imageId: current_image.id,
        countRender: Array.isArray(images) ? images.length : 0,
        price: 0
      });
    }
  }, [isOpen]);

  if (!isOpen) {
    return (<></>);
  }

  return (
    <View>
      <Overlay onClick={onClose} />
      <Container>
        <CloseWrap><Close onClose={onClose}/></CloseWrap>

        <Wrapper>
          <Comparison>
            <BeforeAfterSlider zoom={zoom}
                              beforeImage={COMPARISON_RENDERS['4K']}
                              afterImage={afterImage} />
          </Comparison>

          <Content>
            <Title>{translations.t('gallery.improve.title1')} {nameCurrentRender} {translations.t('gallery.improve.title2')}</Title>
            <Caption>
              <CaptionLink href={urlToComparisonRender} target="_blank" onClick={openComparisonRender}>{translations.t('gallery.improve.caption')}</CaptionLink>
            </Caption>
            <Text>{translations.t('gallery.improve.text')}</Text>
            <TextSelect>{translations.t('gallery.improve.textSelect')}</TextSelect>

            <Renders handleClick={onRenderPreview} />
          </Content>
        </Wrapper>
      </Container>
    </View>
  );
};

const View = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 443;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(Colors.black, 0.5)};
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
const Container = styled.div`
  position: relative;
  z-index: 2;
`;
const CloseWrap = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
`;
const Wrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  background-color: ${Colors.night};
`;
const Comparison = styled.div`
  height: 428px;
  width: 640px;
  background-color: #ddd;
  position: relative;
`;
const Label = styled.div`
  position: absolute;
  bottom: 15px;
  padding: 5px 10px;
  border-radius: 14px;
  color: ${Colors.coal};
  z-index: 1;
`;
const Content = styled.div`
  padding: 20px 40px;
  color: ${Colors.light_gray};
  width: 360px;
  border: 1px solid ${Colors.coal};
  border-radius: 0 10px 10px 0;
`;
const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
`;
const Caption = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 14px;
  font-weight: 400;
  display: block;
`;
const CaptionLink = styled.a`
  color: ${Colors.dark_cream};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #FFB540;
  }
`;
const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`;
const TextSelect = styled(Text)`
  margin-bottom: 15px;
`;
