import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { LinkAppStore } from 'planoplan-ui-kit/core/links/app-store';
import { LinkGooglePlay } from 'planoplan-ui-kit/core/links/google-play';
import { IGo } from 'planoplan-ui-kit/core/icons/i-go';
import { translations } from '@libs';
import { Indent } from '../../../modules/indent';
import { tablet_portrait, tablet_portrait_only, tablet_landscape, laptop } from '../../../modules/breakpoints';
import { UrlButton } from '../../UrlButton';

export const AppBanner = ({ locale }) => (
  <View data-test="detail__app-banner">
    <MediaQuery query={tablet_landscape}>
      {(matches) => (
        <IGo width={matches ? '48px' : '72px'} height={matches ? '48px' : '72px'} size={matches ? '1x' : '2x'} />
      )}
    </MediaQuery>
    <Text dangerouslySetInnerHTML={{ __html: translations.t('store.products.banner') }} />
    <Store>
      <UrlButton
        component={LinkAppStore}
        href={`https://itunes.apple.com/${locale}/app/planoplan-go!/id1049024765?mt=8`}
      />
      <UrlButton
        component={LinkGooglePlay}
        href={`https://play.google.com/store/apps/details?id=com.KS.POPViewer&hl=${locale}`}
      />
    </Store>
  </View>
);

const View = styled.div`
  ${Indent.margin_top(1)};
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${Colors.light_gray};
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${tablet_portrait} {
    flex-direction: row;
  }
`;

const Text = styled.p`
  font-weight: 300;
  color: ${Colors.coal};
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;

  @media ${tablet_portrait} {
    ${Grid.column(12, 30)};
    margin-top: 0;
    margin-left: 20px;
    text-align: left;
  }

  @media ${tablet_landscape} {
    ${Grid.column(11, 30)};
    font-size: 16px;
    line-height: 19px;
    margin-left: 30px;
  }

  @media ${laptop} {
    ${Grid.column(11, 24)};
  }

  b {
    font-weight: 500;
  }
`;

const Store = styled.div`
  margin-top: 20px;
  display: flex;

  a {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${tablet_portrait_only} {
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0;
    margin-left: auto;

    a {
      margin-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${tablet_landscape} {
    margin-top: 0;
    margin-left: auto;
    flex-direction: row;
  }
`;
