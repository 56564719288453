import React, { useEffect } from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { ButtonAction, Header, Image, Text } from '../../components';
import { CHECKING_MODE } from '../../constants';
import { useSelector } from 'react-redux';
import { ON_SUCCESS_LOGIN } from '@observer/constants';

const observer = new window.POPObserver();

export const ThankLogin = ({ onOpenCabinet, onRunStandalone, isProcessing = false, checkingMode }) => {
  const isAfterLogin = checkingMode === CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN;
  const { isAfterOpenProject } = useSelector((state) => state.settings);

  useEffect(() => {
    // Если открываем проект после входа то убираем кнопки и делаем событие на успешный вход. Дальше обрабатывает new.planoplan
    if (isAfterOpenProject) {
      observer.postMessage(ON_SUCCESS_LOGIN);
    }
  }, []);

  return isAfterLogin ? (
      <>
        <Header title={translations.t('form_entry.thank.header')}/>

        <Description>
          <Image normal="form/thank-you.jpg" retina="form/thank-you@2x.jpg" alt="Thank"/>

          <Text>{translations.t('form_entry.thank.text')}</Text>
          <Text>{translations.t('form_entry.thank.text2')}</Text>
        </Description>

        {!isAfterOpenProject && (
          <ButtonsWrap>
            <ButtonAction type="button" loading={isProcessing} onClick={onRunStandalone} useButtonLoader>
              {translations.t('form_entry.thank.continue')}
            </ButtonAction>

            <ButtonAction theme="secondary" top="10px" onClick={onOpenCabinet}>
              {translations.t('form_entry.thank.cabinet')}
            </ButtonAction>
          </ButtonsWrap>
        )}
      </>
  ) : (
      <>
        <Header title={translations.t('form_entry.thank.headerLogin')}/>

        <Description>
          <Image normal="form/smile.jpg" retina="form/smile@2x.jpg" alt="Thank"/>

          <Text>{translations.t('form_entry.thank.textLogin')}</Text>
        </Description>

        {!isAfterOpenProject && (
          <ButtonAction type="button" onClick={onOpenCabinet}>
            {translations.t('form_entry.thank.start')}
          </ButtonAction>
        )}
      </>
  );
};

const Description = styled.div`
  img {
    width: auto;
    height: auto;
    display: block;
    margin-bottom: 16px;
  }

  p {
    margin: 0;
  }
`;
const ButtonsWrap = styled.div`
  margin-top: auto;
`;
