import React from 'react';

export const ICode = ({ width = 16, height = 16, fill = '#D1D1D1' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2L6 14" stroke="#D1D1D1"/>
      <path d="M11.5 5L15.5 7.8L11.5 11" stroke="#D1D1D1" strokeLinejoin="bevel"/>
      <path d="M4.5 11L0.5 8.2L4.5 5" stroke="#D1D1D1" strokeLinejoin="bevel"/>
    </svg>
  );
};
