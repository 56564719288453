import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Item } from '../molecules/item';

export const List = ({ view, images, checked_images, current, onSelect, onChecked, currentImageEl }) => (
  <View view={view}>
    {images.map(
      (item) =>
        item.isFilter && (
          <ItemWrap
            key={`${item.view_id}${item.updateAt}`}
            view={view}
            ref={(ref) => current.view_id === item.view_id && (currentImageEl.current = ref)}>

            <Item
              item={item}
              onSelect={onSelect}
              onChecked={onChecked}
              isChecked={checked_images.some(({ view_id }) => view_id === item.view_id)}
              current={current}
              view={view}
            />
          </ItemWrap>
        )
    )}
  </View>
);

const View = styled.ul`
  ${Mixins.resetList()};
  padding-right: 20px;
  padding-left: 15px;
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(p) => p.view};
`;

const ItemWrap = styled.li`
  width: ${({ view }) => view === 'column' ? '228px' : '110px'};
  height: ${({ view }) => view === 'column' ? '128px' : '62px'};
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.coal};
  text-align: center;
  
  &:nth-child(even) {
    margin-left: ${({ view }) => view === 'column' ? '0' : '6px'};
  }
`;
