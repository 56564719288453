import { LIST } from '../../constants';

export const initialState = {
  screen: '',
  availableScreens: [],
  productScreen: {
    screen: LIST,
    screenBack: '',
    data: {},
  },
  profile: {},
  profileGoods: {},
  products: [],
  productsReview: '',
  pricing: [],
  cart: [],
  bindingCards: [],
  paymentMethods: [],
  banners: '',
  countries: '',
  savedCart: '',
  promo: '',
  sumWithPromo: '',
  lastBuy: '',
  payment: {
    status: '',
    value: '',
    orderid: '',
    token: '',
    returnUrl: '',
    publicKey: '',
    clientSecret: '',
    price: '',
    vat: '',
    vatPercent: '',
    sumPromo: '',
    method: '',
    cart: null
  },
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
  settings: {},
  checksum: '',
  descriptionTariffs: {},
  descriptionStore: {},
  srcVideoPopup: null,
  scrollY: 0,
};
