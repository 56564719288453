import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';
import { IReview } from 'planoplan-ui-kit/core/icons/i-review';
import { IDiscount } from 'planoplan-ui-kit/core/icons/i-discount';
import { IStrokeRender } from 'planoplan-ui-kit/core/icons/i-stroke-render';
import { IVr } from 'planoplan-ui-kit/core/icons/i-vr';
import { IStrokeWidget } from 'planoplan-ui-kit/core/icons/i-stroke-widget';
import { ILove } from 'planoplan-ui-kit/core/icons/i-love';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { PACKAGES, RENDERS, PANORAMAS, WIDGET, PRICING, REVIEW, TARIFF_ALIAS } from '../../../constants';
import { isMobile } from 'react-device-detect';
import { translations } from '@libs';
import { laptop } from '../../../modules/breakpoints';
import { StoreContext } from '@features/store/modules/Store';

export const Navigation = ({ screen, setScreen, availableScreens }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { profileGoods: { tariff_is_expired }} = state;

  const menu = [
    {
      name: translations.t('store.navigation.plans'),
      icon: {
        component: ILove,
        fieldColor: 'fill',
      },
      type: PRICING,
      hide: false,
    },
    {
      name: translations.t('store.navigation.renders'),
      icon: {
        component: IStrokeRender,
        fieldColor: 'fill',
      },
      type: RENDERS,
      hide: tariff_is_expired
    },
  ];

  return (
    <View>
      {menu.map((item) => {
        if (item.hide) {
          return;
        }

        const isActive = screen === item.type;
        const ItemIcon = item.icon.component;
        const IconProps = {
          [item.icon.fieldColor]: isActive ? Colors.dark_cream : Colors.white_smoke,
        };

        return (
          <Fragment key={item.name}>
            {availableScreens.includes(item.type) && (
              <Item>
                <Button
                  onClick={() => setScreen(item.type)}
                  active={isActive}
                  data-test={`sidebar__button-${item.type}`}>
                  <Icon><ItemIcon fill='transparent' stroke='transparent' {...IconProps} /></Icon>
                  {item.name}
                </Button>
              </Item>
            )}
          </Fragment>
        )
      })}
    </View>
  );
};

const View = styled.ul`
  ${Mixins.resetList()};
  ${Grid.prefix(1, 32)};
  width: 100%;
  margin-right: 2px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media ${laptop} {
    ${Grid.prefix(0)};
    width: auto;
    margin-right: 0;
    display: block;
  }
`;

const Item = styled.li``;

const Button = styled.button`
  ${Mixins.resetButton()};
  ${Mixins.transition('background-color')};
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: ${(p) => (p.active ? Colors.dark_cream : Colors.white_smoke)};
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  white-space: nowrap;

  &:hover {
    background-color: ${!isMobile && Colors.night};
  }

  @media ${laptop} {
    ${Grid.suffix(0.5, 6)};
    ${Grid.prefix(0.5, 6)};
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: none;

  @media ${laptop} {
    margin-right: 15px;
    display: block;

    svg {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
