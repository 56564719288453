import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconSearch, IconClose } from '../../../components/icons';

export const Search = ({ value, isOpen, setValue = () => {}, onOpen = () => {}, onClose = () => {} }) => {
  const input = useRef(null);

  const onChange = (event) => setValue(event.target.value);
  const onCloseSearch = () => {
    onClose();
    setValue('');
  };

  useEffect(() => {
    if (isOpen && input.current) {
      input.current.focus();
    }
  }, [isOpen]);

  return (
    <View>
      {!isOpen && <ButtonOpen onClick={onOpen}><IconSearch /></ButtonOpen>}

      {isOpen &&
        <SearchInputWrap>
          <Input placeholder={translations.t('cabinet.projects.search')} value={value} onChange={onChange} ref={input}/>
          <InputIcon><IconSearch /></InputIcon>
          <InputClose onClick={onCloseSearch}><IconClose /></InputClose>
        </SearchInputWrap>
      }
    </View>
  )
};

const View = styled.div``;
const ButtonOpen = styled.div`
  ${Mixins.transition('background-color, border-color')};    
  width: 40px;
  height: 40px;
  border: 1px solid ${Colors.light_gray};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  
  svg {
    ${Mixins.transition('fill')};    
  }
  
  &:hover {
    background-color: ${Colors.planoplan_blue};
    border-color: ${Colors.planoplan_blue};

    svg {
      fill: ${Colors.white};
    }
  }
`;
const SearchInputWrap = styled.div`
  position: relative;
`;
const InputIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  
  svg {
    ${Mixins.transition('fill')};    
  }
`;
const InputClose = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  
  svg {
    ${Mixins.transition('fill')};    
  }
  
  &:hover {
    svg {
      fill: ${Colors.planoplan_blue};
    }
  }
`;
const Input = styled.input`
  height: 40px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid ${Colors.light_gray};
  background-color: ${Colors.white};
  padding-left: 40px;
  font-size: 16px;
  
  &::placeholder {
    color: ${Colors.nobel};
    font-size: 16px;
  }
  
  &:focus {
    border-color: ${Colors.planoplan_blue};
    
    & + div svg {
      fill: ${Colors.planoplan_blue};
    }
  }
`;
