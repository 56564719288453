import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { rgba } from 'polished';
import { isMobile } from 'react-device-detect';
import { ILogo } from 'planoplan-ui-kit/core/icons/i-logo';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Preloader, PreloaderView } from 'planoplan-ui-kit/core/preloader';
import { ON_LOADING_OFF_SCREEN_ENTRY } from '@observer/constants';
import { CloseModal, useModals } from '@components';
import { APP_CLOSE, MODALS } from '../constants';
import * as effects from './modules/effects';
import * as getdata from './modules/getdata';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { rail_y, thumb_y } from './components/scroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Arrow } from './components/Arrow';

const observer = new window.POPObserver();

export const App = () => {
  const state = useSelector((state) => state);
  const { ver, slides, loading, settings } = state;
  const { processing } = loading;
  const dispatch = useDispatch();
  const [modals, openModal, closeAllModals] = useModals([MODALS.CLOSE]);

  const onClose = () => openModal(MODALS.CLOSE);
  const onCloseSubmit = () => effects.closeApp(settings.close);

  const slidesItems = slides.map((item, index) => (
    <ItemSlide key={index}>
      <img src={item.image} alt={item.caption} />
      <Caption>{item.caption}</Caption>
    </ItemSlide>
  ));

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <Arrow onClick={onClick} direction="next" />
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <Arrow onClick={onClick} direction="prev" />
    );
  }

  const settingsSlider = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <Dot key={i} />
    )
  };

  useEffect(() => {
    dispatch(getdata.getTranslations());
    dispatch(getdata.getContent());

    window.invokeAPI.registerDispatch('LAYOUT', {
      [APP_CLOSE]: () => onClose(),
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!processing) {
      observer.postMessage(ON_LOADING_OFF_SCREEN_ENTRY);
    }
  }, [processing]);

  return (
    <FullFixed>
      <CenterContent>
        <Container>
          <SidebarWrapper>
            <ScrollBar
              options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }}
              rail_y={rail_y}
              thumb_y={thumb_y}>
              <Sidebar>
                <Header>
                  <ILogo width="163" height="40" fillBrend={Colors.planoplan_blue} fillSeconds={Colors.coal} rebrand={true} locale={settings.locale} />
                  {ver && <Ver>v {ver}</Ver>}
                </Header>
                <Form id="form-entry" />
              </Sidebar>
            </ScrollBar>
          </SidebarWrapper>
          <Image>
            {processing ? (
              <Preloader width="60px" height="60px" fill={Colors.white_smoke} />
            ) : (
              <>
                <Slider {...settingsSlider}>
                  {slidesItems}
                </Slider>
              </>
            )}
            <Close onClick={onClose}>
              <IClose width="38" height="38" fill={rgba(Colors.white, 0.5)} />
            </Close>
          </Image>
        </Container>

        <CloseModal isOpen={modals[MODALS.CLOSE].isOpen} onClose={closeAllModals} onSubmit={onCloseSubmit} />
      </CenterContent>
    </FullFixed>
  );
};

const FullFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
  z-index: 999;
`;

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const Container = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  background-color: transparent;
  color: ${Colors.coal};
  width: 1068px;
  height: 648px;
  display: flex;
`;

const SidebarWrapper = styled.div`
  width: 420px;
  flex-shrink: 0;
  background-color: ${Colors.white};
`;

const Sidebar = styled.div`
  padding: 30px 38px 5px 38px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
`;

const Image = styled.div`
  position: relative;
  width: 648px;
  height: 100%;
  background-color: #0E0E0E;
  overflow: hidden;

  ${PreloaderView} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .slick-slider {
    width: 100%;
    display: flex;
    align-items: center;
    object-fit: cover;
    position: relative;

    .slick-list {
      width: 100%;
      height: 648px;
      display: block;
      overflow: hidden;
      object-fit: cover;
      .slick-track {
        display: flex;
      }
      img {
        width: 648px;
        height: 648px;
        display: block;
        object-fit: cover;
      }
    }

    .slick-dots {
      bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      
      li {
        width: 16px;
        height: 16px;
        margin: 0 7px;
        visibility: hidden;
        display: block;
      }

      .slick-active {
        margin: 1.5px 8px 5px 6px;
      }

      .slick-active div {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 50%;
        transition: background 0.3s, width 0.3s, height 0.3s;
      }
    }
  }
`;

const ItemSlide = styled.div`
  position: relative;
`;

const Dot = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  transition: background 0.3s, width 0.3s, height 0.3s;
  visibility: visible;
`;

const Close = styled.button`
  ${Mixins.resetButton()};
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    ${Mixins.transition('transform fill')};
    fill: ${rgba(Colors.white, 0.5)};
  }

  &:hover svg {
    transform: ${!isMobile && 'scale(0.9)'};
    fill: ${Colors.white};
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Ver = styled.div`
  font-size: 13px;
  line-height: 16px;
`;

const Form = styled.div`
  margin-top: 28px;
`;

const Caption = styled.div`
  color: ${Colors.coal};
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  bottom: 47px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 4px 7px;
  background-color: ${Colors.white};
  border-radius: 50px;
`;
