import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { translations } from '@libs';
import { rail_y, thumb_y } from '../../components/scroll';
import { Tariff } from './organism/tariff'
import { Card } from './organism/card'
import { Promo } from './organism/promo'
import { Analytics } from '../../modules/analytics';

export const PageSubscribe = () => {
  useEffect(() => {
    Analytics.viewSubscribe();
  }, []);

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <Content>
          <Title>{translations.t('cabinet.subscribe.page.title')}</Title>

          <Top>
            <Tariff />
            <Card />
          </Top>

          {/*<Promo />*/}
        </Content>
      </View>
    </ScrollBar>
  );
};

const View = styled.div``;
const Title = styled.div`
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
  color: ${Colors.main_dark};
  margin-bottom: 18px;
`;
const Content = styled.div`
  overflow: hidden;
  padding: 20px 0 20px 26px;
`;
const Top = styled.div`
  display: flex;
`;
