import { SET_CAPTION, SET_IMAGE, SET_LOADING_OFF, SET_LOADING_ON, SET_SLIDES } from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_IMAGE:
      return { ...state, image: action.payload };
    case SET_CAPTION:
      return { ...state, caption: action.payload };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
            },
          },
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
        },
      };
    };
    case SET_SLIDES:
      return {...state, slides: action.payload}
    default:
      return state;
  }
};
