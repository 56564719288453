import { translations } from '@libs';
import { GET_TRANSLATES,
  GET_PROJECTS,
  GET_EDUCATIONS,
  SET_PROJECTS,
  SET_EDUCATIONS,
  SET_LOADING_OFF,
  SET_LOADING_ON } from '../constants';
import { ApiCall } from './api-call';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale, [/^form_entry./, /^store./, /^quickstore./, /^pricing.shortcontent./, /^gallery./, /^projects./, /^assistant./]);
    } catch (error) {
      console.error(error);
      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: error.message },
      });
    }
  }
};

export const getEducations = () => async (dispatch, getState) => {
  const { loading } = getState();

  if (loading.error) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_EDUCATIONS } });

  try {
    const { data } = await ApiCall.getEducations({});

    dispatch({ type: SET_EDUCATIONS, payload: data.basic });
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_EDUCATIONS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: {name: GET_EDUCATIONS, error: error},
    });
  }
};

export const getProjects = () => async (dispatch, getState) => {
  const { loading } = getState();

  if (loading.error) {
    return;
  }

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PROJECTS } });

  try {
    const { data: { projects }} = await ApiCall.getProjects({});

    dispatch({ type: SET_PROJECTS, payload: Object.values(projects.items)});
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PROJECTS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PROJECTS, error: error },
    });
  }
};
