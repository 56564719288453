export class SessionStorage {
  static set(key, value) {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('sessionStorage Error ' + e);
    }
  }

  static get(key) {
    try {
      return JSON.parse(window.sessionStorage.getItem(key));
    } catch (e) {
      console.error('sessionStorage Error ' + e);
    }
  }

  static has(key) {
    return !!window.sessionStorage.getItem(key);
  }

  static remove(key) {
    window.sessionStorage.removeItem(key);
  }
}
