import React from 'react';
import styled from 'styled-components';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const Loader = ({ onClick = () => {} }) => (
  <View onClick={onClick}>
    <Preloader width="60px" height="60px" fill={Colors.planoplan_blue} />
  </View>
);

const View = styled.div`
  margin-top: 160px;
  display: flex;
  justify-content: center;
`;
