import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { deviceDetected } from 'planoplan-ui-kit/core/device-detected';
import { ON_SCROLL_LOCK_LOCK, ON_SCROLL_LOCK_UNLOCK } from '@observer/constants';

const observer = new window.POPObserver();

export const ScrollLock = {
  lock(root) {
    disableBodyScroll(root, { reserveScrollBarGap: !deviceDetected.isMobile });

    setTimeout(() => {
      const paddingRight = getComputedStyle(document.body).paddingRight;

      observer.postMessage(ON_SCROLL_LOCK_LOCK, {
        paddingRight,
      });
    });
  },

  unlock(root) {
    enableBodyScroll(root);

    observer.postMessage(ON_SCROLL_LOCK_UNLOCK);
  },
};
