import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Checkbox } from 'planoplan-ui-kit/core/checkbox';
import { Preloader } from 'planoplan-ui-kit/core/preloader';
import { translations } from '@libs';
import { notifications } from '@features/notifications';
import * as effects from './effects';
import { rail_y, thumb_y } from '../../components/scroll';
import { Input } from '../../components/Input';
import { Textarea } from '../../components/TextArea';
import { Avatar } from './organisms/avatar';
import { FileInput } from './organisms/file-input';

export const PageProfileTeam = () => {
  const dispatch = useDispatch();
  const {
    team,
    loading,
  } = useSelector((state) => state);
  const currentTeam = team.current;

  const [fields, setFields] = useState({
    caption: '',
    email: '',
    site: '',
    about: '',
    logo: '',
  });
  const [file, setFile] = useState([]);
  const [tempLogo, setTempLogo] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);

  const onAvatarSelect = (e) => {
    const { files, accept } = e.target;
    dispatch(effects.checkFileByFormat(files[0], accept));

    const reader = new FileReader();

    reader.onload = (e) => setTempLogo(e.target.result);
    reader.readAsDataURL(files[0]);

    setFile([files[0], files[0].name]);
    setRemoveLogo(false);
  };
  const onLogoDelete = (event) => {
    event.preventDefault();

    setTempLogo('DELETED');
    setFile([]);
    setRemoveLogo(true);
  };
  const onChangeField = ({ name, value }) => {
    setFields((prevState) => {
      return { ...prevState, [name]: value };
    });

    if (name === 'caption') {
      setDisabledButton(!Boolean(value));
    }
  };
  const onDataSave = (e) => {
    e.preventDefault();
    const { caption, email, about, site } = fields;
    const request = {
      caption,
      email,
      about,
      site,
      logo: file[0],
      team_id: team.id,
    };

    if (removeLogo) {
      request['remove_logo'] = 1;
      request['logo'] = '';
    }

    dispatch(effects.editTeam(request));
  };
  const onCopyTeamId = () => {
    copy(team.id);
    notifications.showSuccess(translations.t('cabinet.notification.copyUserId'));
  };

  useEffect(() => {
    if (currentTeam) {
      setFields({
        caption: currentTeam.caption,
        email: currentTeam.email,
        site: currentTeam.site,
        about: currentTeam.about,
        logo: currentTeam.logo,
      });

      setDisabledButton(!Boolean(currentTeam.caption));
    }
  }, [currentTeam]);

  if (loading && loading.processing) {
    return (
      <Loader>
        <Preloader width="80px" height="80px" fill={Colors.planoplan_blue} />
      </Loader>
    )
  }

  return (
    <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150 }} rail_y={rail_y} thumb_y={thumb_y}>
      <View>
        <Content>
          <Column>
            <ProfileForm>
              <Row>
                <AvatarContainer>
                  <Label>{translations.t('cabinet.profile.avatar')}</Label>
                  <FlexWrapper>
                    <Avatar onDelete={onLogoDelete} src={tempLogo || fields.logo} cover={true} />
                    <UserIdContainer>
                      <UserId>
                        Team ID:
                        <br />
                        {team.id}
                      </UserId>
                      <CopyBtn onClick={onCopyTeamId}>copy</CopyBtn>
                    </UserIdContainer>
                  </FlexWrapper>
                  <FileInput
                    type="file"
                    id="logo"
                    accept=".jpg, .jpeg, .png, .svg, .webp"
                    onChange={onAvatarSelect}
                    desc={translations.t('cabinet.avatar.recommended_size') + ' 400х400px'}
                  />
                </AvatarContainer>
              </Row>
              <Row>
                <Input onChange={onChangeField} id="caption" name="caption" value={fields.caption} isProfile={true}>
                  {translations.t('cabinet.profileTeam.name')}
                </Input>
              </Row>
              <Row>
                <Input onChange={onChangeField} id="email" name="email" value={fields.email} isProfile={true}>
                  {translations.t('cabinet.profileTeam.email')}
                </Input>
              </Row>
              <Row>
                <Input onChange={onChangeField} id="site" name="site" value={fields.site} isProfile={true}>
                  {translations.t('cabinet.profileTeam.site')}
                </Input>
              </Row>
              <Row>
                <Textarea
                  theme="primary"
                  name="about"
                  minRows={6}
                  maxRows={7}
                  onChange={onChangeField}
                  value={fields.about}>
                  {translations.t('cabinet.profileTeam.yourself')}
                </Textarea>
              </Row>
            </ProfileForm>
            <SaveButton>

              <Button theme="primary" size="M" onClick={onDataSave} disabled={disabledButton}>
                <span>{translations.t('cabinet.save_button')}</span>
              </Button>
            </SaveButton>
          </Column>
        </Content>
      </View>
    </ScrollBar>
  );
};

const View = styled.div`
  position: relative;
`;
const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.timberwolf25};
  opacity: 0.8;
  z-index: 3;
  display:flex; 
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  display: flex;
  overflow: hidden;
  padding: 20px;
`;
const Column = styled.div``;
const Row = styled.div`
  margin-bottom: 20px;
`;
const ProfileForm = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-width: 1px 1px 0 1px;
  border-radius: 5px 0 0 0;
  padding: 20px;
  width: 360px;
  height: 715px;
`;
const AvatarContainer = styled.div`
  width: 320px;
  height: 217px;
`;
const Label = styled.div`
  margin: 0 0 4px 4px;
`;
const FlexWrapper = styled.div`
  display: flex;
`;
const UserIdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 150px;
  background: ${Colors.timberwolf50};
  border-radius: 0 5px 5px 0;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: auto;
  text-align: center;
`;
const UserId = styled.div`
  margin-top: 14px;
`;
const CopyBtn = styled.div`
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  color: ${Colors.blueBell};
  cursor: pointer;
  margin: 8px 0 10px 0;

  &:hover {
    color: ${Colors.planoplan_blue};
  }
`;
const SaveButton = styled.div`
  border: 1px solid ${Colors.feldgrau25};
  border-radius: 0 0 5px 5px;
  padding: 20px;
  width: 360px;
  height: 80px;
`;
