import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { useSelector } from 'react-redux';
import { Info } from './organism/info';
import { Last } from './organism/last';

export const Slide = () => {
  const { current } = useSelector(state => state);
  const isLast = !!current.info;

  return (
    <View>
      {isLast ? <Last slide={current} /> : <Info slide={current} />}
    </View>
  );
};

const View = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
`;
