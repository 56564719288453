import { Colors } from 'planoplan-ui-kit/core/colors';
// events
export const ON_CHANGE_USER = 'ON_CHANGE_USER';
export const SHOW_BANNER = 'SHOW_BANNER';

export const TARIFFS = {
  proplus: 'PRO+',
  pro: 'BASE',
  businessvr: 'TEAM',
};

export const TARIFF_ALIAS = {
  START: 'start',
  PRO: 'pro',
  PROPLUS: 'proplus',
  BUSINESS: 'businessvr',
  EDUCATION: 'education',
  PROFESSIONAL: 'professional',
};

export const BACKGROUND_TARIFFS = {
  [TARIFF_ALIAS.START]: {
    background: Colors.planoplan_blue,
    color: Colors.planoplan_blue,
  },
  [TARIFF_ALIAS.PRO]: {
    background: '#FF894D',
    color: '#FF894D',
  },
  [TARIFF_ALIAS.PROPLUS]: {
    background: 'rgb(121, 47, 247)',
    color: '#792FF7',
  },
  [TARIFF_ALIAS.BUSINESS]: {
    background: '#C13CC1',
    color: '#C13CC1',
  },
};
