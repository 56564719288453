export const isJSON = (data) => {
  if (typeof data !== 'string') {
    return false;
  }
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};
