import { translations } from '@libs';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_PANORAMAS,
  GET_TRANSLATES,
  GET_PANORAMAS,
  SET_CURRENT_PANORAMA,
  SET_HOTSPOT_ICONS
} from '../constants';
import { getErrorMessages } from './error-messages';
import { ApiCall } from './api-call';
import { setStartStructure } from './effects';

export const getTranslations = () => async (dispatch, getState) => {
  const isConnect = translations.hasConnect();

  if (!isConnect) {
    const { locale } = getState().settings;

    try {
      await translations.load(locale);
    } catch (error) {
      console.error(error);

      dispatch({
        type: SET_LOADING_OFF,
        payload: { name: GET_TRANSLATES, error: getErrorMessages(locale, error.message) },
      });
    }
  }
};

export const getPanoramas = () => async (dispatch, getState) => {
  const { settings, loading } = getState();

  if (loading.error) {
    return;
  }

  const { locale, projectId } = settings;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_PANORAMAS } });

  try {
    const { data } = await ApiCall.getPanoramas({ id: projectId });
    const panoramas = (data?.data?.items || []).filter(item => !item.isPreview);

    if (!panoramas.length) {
      throw new Error('');
    }

    const panoramasWithParams = panoramas.map((panorama, index) => ({ ...panorama, index: index + 1 }));

    if (data.tourHotspotIcons) {
      dispatch({ type: SET_HOTSPOT_ICONS, payload: Object.values(data.tourHotspotIcons) });
    }

    dispatch({ type: SET_PANORAMAS, payload: panoramasWithParams });
    dispatch({ type: SET_CURRENT_PANORAMA, payload: panoramasWithParams[0] });

    dispatch(setStartStructure(panoramasWithParams));

    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_PANORAMAS } });
  } catch (error) {
    console.error(error);

    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_PANORAMAS, error: getErrorMessages(locale, error.message) },
    });
  }
};
