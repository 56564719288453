import { getSrcResize, sortBy, parseStringToObject } from '@libs';
import { STATUSES, RENDER_SIZES, Q_PLUS_CONDITION, TASK_STATUS } from '../constants';
import { translations } from '@libs';

export const normalizeData = (data) => {
  const filters = [{ value: 'all' }];
  const getImages = (items, projectId = null) => {
    const checkIsQPlus = (item) => {
      const height = item.size && item.size.height;

      if (!height) {
        return false;
      }

      return item.updateAt > (Q_PLUS_CONDITION.time / 1000) && height >= Q_PLUS_CONDITION.height && Boolean(item.editor.task_data);
    };
    const images = items.map((item, index) => {
      const image = {};
      const { roomCaption, roomArea, resolution, orientation } = parseTaskData(item.task_data, item.resolution);
      const normalizeRoomArea = roomArea ? Math.floor(Number(roomArea) * 10) / 10 : '';
      const value = getFilterItem(roomCaption, normalizeRoomArea);

      // устанавливаем фильтры иcходя из roomCaption и roomArea
      if (value && !filters.some((item) => item.value === value)) {
        filters.push({
          value,
          roomCaption,
          roomArea: normalizeRoomArea,
        });
      }

      // если original содержит xml, то считаем что это панорама
      if (item.type === 'panorama') {
        image.krpano_url = item.original.xml;
      } else if (item.type === 'tour') {
        image.tour = item.original;
      } else {
        image.original = item.original || item.preview ? `${item.original || item.preview}?${item.update_at}` : ''; // если нет original, берем preview за основу
      }

      const size = getSizeOriginal(resolution, orientation);

      image.id = item.id;
      image.view_id = index + 1;
      image.preview = item.preview ? `${item.preview}?${item.update_at}` : '';
      image.publishId = item.publish_id;
      image.roomCaption = roomCaption;
      image.roomArea = normalizeRoomArea;
      image.filter = value;
      image.caption = value;
      image.status = STATUSES[item.task_status];
      image.isComplete = item.task_status === TASK_STATUS.complete;
      image.isProgress = item.task_status === TASK_STATUS.waiting || item.task_status === TASK_STATUS.inprocess;
      image.isError = item.task_status === TASK_STATUS.cancel || item.task_status === TASK_STATUS.fail || item.task_status === TASK_STATUS.reject;
      image.isFilter = true;
      image.isChecked = false;
      image.isLoaded = false;
      image.isAvailableCheck = !!item.id && (image.isComplete || image.isError); // можно выбирать элемент если у него есть id
      image.isProhibitedDelete = false; // только когда открытие по урлу
      image.isVertical = orientation === 'vertical';
      image.editor = item; // данные в редактор передаем в оригинальном виде
      image.size = size;
      image.captionSize =
        size.width && size.height ? `${item.isPreview ? translations.t('gallery.preview') : RENDER_SIZES[size.width] || ''} ${size.width}x${size.height}px` : '';
      image.updateAt = item.update_at;
      image.projectId = projectId;
      image.isPreview = item.isPreview || false;
      image.isQPlus = checkIsQPlus(image);
      image.type = item.type;

      return image;
    });

    // waiting, inprocess, cancel, fail, reject, complete
    const order = [STATUSES[0], STATUSES[1]];
    const process_images = [];
    const other_images = [];

    images.forEach((image) => order.indexOf(image.status) !== -1 ? process_images.push(image) : other_images.push(image));

    other_images.sort((a, b) => b.updateAt - a.updateAt);
    process_images.sort((a, b) => order.indexOf(b.status) - order.indexOf(a.status));

    return {
      images: [...process_images, ...other_images],
      filters,
      projects: [],
    };
  };

  if (data.items) {
    return getImages(data.items);
  } else if (Array.isArray(data) && data.length) {
    /* Вывод галлереи для папки не реализован, поэтому вывожу только галлерею первого проекта */
    return {
      ...getImages(data[0] ? data[0].items : []),
      projects: data.map((project) => {
        return {
          id: project.projectId,
          name: project.projectName,
          ...getImages(project.items, project.projectId),
        }
      }),
    };
  } else {
    return {
      images: [],
      filters,
      projects: [],
    }
  }
};

// Парсим строку task_data в объект и конвертируем resolution
const parseTaskData = (task_data, resolution = '') => {
  const data = parseStringToObject(task_data, '=');
  const size = resolution.split('_');

  for (let prop in data) {
    if (prop.includes('resolution')) {
      data.resolution = data[prop].split('x');
    }
  }

  return {
    ...data,
    resolution: size.length === 2 ? size : data.resolution,
  };
};

// Устанавливаем поле фильтра исходя из полей roomCaption и roomArea
const getFilterItem = (roomCaption, roomArea) => {
  if (roomCaption && roomArea) {
    return `${roomCaption} ${roomArea}`;
  }

  if (roomCaption && !roomArea) {
    return roomCaption;
  }

  if (!roomCaption && !roomArea) {
    return '';
  }
};

// Устанавливаем размеры оригинального рендера
const getSizeOriginal = (resolution, orientation) => {
  const result = { width: '', height: '' };

  if (resolution) {
    const width = Number(resolution[0]);
    const height = Number(resolution[1]);

    if (orientation === 'vertical') {
      result.width = width < height ? width : height;
      result.height = width < height ? height : width;
    } else if (orientation === 'square') {
      result.width = height;
      result.height = height;
    } else {
      result.width = width > height ? width : height;
      result.height = width > height ? height : width;
    }
  }

  return result;
};

// Создание объекта для 1 изображения, если передана такая настройка
export const normalizeOneImage = ({ imageUrl, previewUrl, width, height }) => {
  const image = {
    original: imageUrl,
    preview: previewUrl ? previewUrl : getSrcResize(imageUrl, 117),
    editor: {
      original: imageUrl,
    },
    isAvailableCheck: true,
    isProhibitedDelete: true, // только когда открытие по урлу
    id: 1, // для активации кнопок зума
    view_id: 1, // для перебора в цикле
    isComplete: true,
    isFilter: true,
    isLoaded: false,
    isVertical: height >= width,
    caption: imageUrl.match(/(thumb[^.]*)/)[0],
    size: { width, height },
    captionSize: `${RENDER_SIZES[width] || ''} ${width}x${height}px`,
  };

  const filters = [{ value: 'all' }];

  return { image, filters };
};

// Создание объекта с данными о загруженных картинках (только для статуса 2 и не крпано)
export const normalizeLazyImages = (images) => {
  const object = {};

  images.forEach((item) => {
    if (!item.krpano_url && item.isComplete) {
      object[item.view_id] = {
        id: item.view_id,
        original: item.original,
        isLoaded: false,
      };
    }
  });

  return object;
};
