import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from "planoplan-ui-kit/core/mixins";

export const Quantity = ({ count, add, remove, onMouseEnter = () => {}, onMouseLeave = () => {} }) => {
  return (
    <View>
      <QuantitySquare onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={remove} disabled={count <= -1} data-test="quantity__remove" />
      <Count data-test="quantity__count">{count}</Count>
      <QuantitySquare onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={add} data-test="quantity__add" isAdd={true} />
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${Colors.solitude};
  border-radius: 4px;
  height: 48px;
  width: 151px;
  margin-left: 1px;
`;

const Count = styled.div`
  text-align: center;
  width: 54px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.night};
`;

const QuantitySquare = styled.button`
  ${Mixins.transition('background-color')};
  ${Mixins.resetButton()};
  cursor: pointer;
  background-color: ${Colors.solitude};
  width: 48px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  &:after,
  &:before {
    ${Mixins.transition('background-color')};
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    width: 12px;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ disabled }) => disabled ? Colors.light_gray : Colors.electric_blue};
  }
  
  &:before {
    ${({ isAdd }) => isAdd && 'transform: translate(-50%) rotate(90deg);'}
  }
  
  ${({ disabled }) => !disabled && `
    &:hover {
      background-color: ${Colors.planoplan_blue};
      
      &:after,
      &:before {
        background-color: ${Colors.white};
      }
    }
    
    &:active {
      background-color: ${Colors.electric_blue};
      
      &:after,
      &:before {
        background-color: ${Colors.white};
      }
    }
  `}
  
`;
