import { textHooks } from '@libs';
import { renderingAlias, widgetAlias } from '../constants';



export const normalizeRenderingGoods = (data) => data?.filter((item) => {
  const text = textHooks(item.caption);
  item.view_name = text.out_hooks;
  item.view_size = text.in_hooks;

  // Добавляет флаг, если товар не доступен пользователю
  if (item.price === null && item.in_sets.length < 1) {
    item.view_disabled = true;
  }

  return renderingAlias.includes(item.alias);
});

export const normalizeWidgetGoods = (data) => data?.filter((item) => {
  const text = textHooks(item.caption);
  item.view_name = text.out_hooks;
  item.view_size = text.in_hooks;

  // Добавляет флаг, если товар не доступен пользователю
  if (item.price === null && item.in_sets.length < 1) {
    item.view_disabled = true;
  }

  return widgetAlias.includes(item.alias);
});

export const getBreadcrumbs = (currentFolder, foldersTree) => {
  if (!currentFolder) {
    return [];
  }

  const foldersList = [];
  const breadcrumbs = [currentFolder];

  const getListFolders = (folders) => {
    folders.forEach((folder) => {
      foldersList.push(folder.node);

      if (folder.children && folder.children.length) {
        getListFolders(folder.children);
      }
    });
  };

  const getListBreadcrumbs = () => {
    const parentId = breadcrumbs[breadcrumbs.length - 1] && breadcrumbs[breadcrumbs.length - 1].parent_id;

    if (parentId) {
      const parent = foldersList.find(folder => folder.id === parentId);

      if (parent) {
        breadcrumbs.push(parent);

        if (parent.parent_id) {
          getListBreadcrumbs();
        }
      }
    }
  };

  getListFolders(foldersTree);
  getListBreadcrumbs(foldersList);

  return breadcrumbs.reverse();
};

export const normalizeHistoryPayment = (data = []) => {
  return data.map((item) => {
    return {
      ...item,
    };
  });
};

export const createRows = (data, columns) => {
  const rows = [];

  for (let [i, el] of data.entries()) {
    rows[i] = [];

    if (typeof el.isMultiSelect !== 'undefined') {
      rows[i].isMultiSelect = el.isMultiSelect;
    }

    for (let j of columns) {
      let render;
      const data = typeof el[j.accessor] === 'undefined' ? '' : el[j.accessor];

      if (typeof j.render === 'function') {
        render = j.render(el);
      } else {
        render = data;
      }

      rows[i].push({
        render: render,
        style: j.style,
        data: data,
      });
    }
  }

  return rows;
};

export const getRange = (pageRange, currentPage, pageCount) => {
  let start = currentPage - pageRange;
  let end = currentPage + pageRange;

  if (start < 1) {
    end -= start - 1;
  }

  if (end > pageCount) {
    start -= end - pageCount;
    end = pageCount;
  }

  if (start < 1) {
    start = 1;
  }

  return [start, end];
};

export const normalizeTeamMembers = (data = []) => {
  return data.map((user) => {
    const renders = [];
    const items = user.render_stat.items;

    for (let key in items) {
      if (Object.prototype.hasOwnProperty.call(items, key)) {
        renders.push({
          date: key,
          items: items[key]
        })
      }
    }

    return {
      ...user,
      render_stat: {
        ...user.render_stat,
        items: renders
      }
    }
  });
};
