import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Card } from './organism/card';
import { LIST, DETAIL } from '../../constants';
import { Detail } from './organism/detail';
import { StoreContext } from '../../modules/Store/Store';
import * as effects from '../../modules/effects';
import { getLayoutScreenCss } from '../../modules/styles';
import { PlayButton } from '../PlayButton';
import { Analytics } from '../../modules/analytics';
import { getAnalyticParamsRender, isInViewport } from '../../modules/helpers';

export const Products = ({ elements, content = {} }) => {
  const [state, dispatch] = useContext(StoreContext);
  const [wasVisibleProducts, setWasVisibleProducts] = useState([]);
  const [elRefs, setElRefs] = React.useState([]);
  const [hoverCountOptions, setHoverCountOptions] = useState({
    hover: false,
    event: 'add', // remove
    count: 0,
  });
  const { productScreen, settings, scrollY } = state;
  const { locale, team_id } = settings;

  const clearHoverCount = () => setHoverCountOptions({ hover: false, count: 0, event: '' });
  const setProductScreen = (id) => {
    // effects.setProductScreen(dispatch, state, id, DETAIL, screen)
  };
  const addToCart = (id, item) => {
    effects.addToCart(dispatch, state, id);

    Analytics.ecommerceAddCart([item.analyticParams]);
  };
  const addCountGood = (item) => {
    setHoverCountOptions({ ...hoverCountOptions, count: hoverCountOptions.count + 1, event: 'add' });
    effects.changeCountProduct(dispatch, state, item, 'add');
  };
  const removeCountGood = (item) => {
    setHoverCountOptions({ ...hoverCountOptions, count: hoverCountOptions.count + 1, event: 'remove' });
    effects.changeCountProduct(dispatch, state, item, 'remove', {
      hoverCount: hoverCountOptions.count + 1,
      afterRemoveFromCart: clearHoverCount,
    });
  };
  const setScreen = (screen = productScreen.screenBack) => effects.setScreen(dispatch, state, screen);
  const inCart = (id) => state.cart.some((item) => item.store_id === id);
  const onOpenPopupVideo = (src) => effects.setSrcVideoPopup(dispatch, src);
  const changePriceForSet = (id, price, index, options) => {
    const { autoSet } = options;

    effects.changePriceForSet(dispatch, state, id, price, index);

    if (!autoSet) {
      try {
        const element = elements.find(item => item.store_id === id);
        const analyticItem = getAnalyticParamsRender({ ...element, activeCount: index }, element.analyticParams.index - 1);

        Analytics.ecommerceView([analyticItem]);
        Analytics.ecommerceViewItem([analyticItem]);

        if (inCart(id)) {
          Analytics.ecommerceAddCart([analyticItem]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const countMouseEnter = () => {
    clearHoverCount();
  };
  const countMouseLeave = (item) => {
    const analyticParams = {
      ...item.analyticParams,
      quantity: hoverCountOptions.count,
    };
    setHoverCountOptions({ ...hoverCountOptions, hover: false });

    if (!hoverCountOptions.count) {
      return;
    }

    if (hoverCountOptions.event === 'add') {
      Analytics.ecommerceAddCart(analyticParams);
    } else if (hoverCountOptions.event === 'remove') {
      Analytics.ecommerceRemoveCart(analyticParams);
    }
  };

  const scrollPage = () => {
    try {
      const wasVisibleAlias = [...wasVisibleProducts];
      const container = document.getElementById('scroll-container');

      elRefs.forEach(({ current: el }) => {
        const button = el.querySelector('div[data-id]');

        if (button) {
          const store_id = button.dataset['id'];

          if (isInViewport(button, container, 50) && !wasVisibleAlias.includes(store_id)) {
            wasVisibleAlias.push(store_id);
          }
        }
      });

      const notVisible = wasVisibleAlias.filter((store_id) => !wasVisibleProducts.includes(store_id));

      if (notVisible.length) {
        const analyticItems = notVisible.map((store_id) => elements.find(item => item.store_id === store_id)?.analyticParams);

        Analytics.ecommerceViewItem(analyticItems);
      }

      setWasVisibleProducts(wasVisibleAlias);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(scrollPage, [scrollY]);
  useEffect(scrollPage, [elRefs]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(elements.length)
        .fill({})
        .map((_, i) => elRefs[i] || React.createRef()),
    );
  }, [elements.length]);

  return (
    <>
      {
        {
          [LIST]: (
            <List>
              <Top>
                <Description>
                  <Title>{content.title}</Title>
                  <Text>{content.text}</Text>
                </Description>

                {(content.link_text && content.link_education) &&
                  <Helpers>
                    <PlayButton text={content.link_text} onClick={() => onOpenPopupVideo(content.link_education)} />
                  </Helpers>
                }
              </Top>
              <Content>
                {elements.map((item, index) => (
                  <Card
                    ref={elRefs[index]}
                    key={item.store_id}
                    data={item}
                    setProductScreen={() => setProductScreen(item.store_id)}
                    addToCart={addToCart}
                    addCountGood={addCountGood}
                    removeCountGood={removeCountGood}
                    changePriceForSet={changePriceForSet}
                    setScreen={setScreen}
                    inCart={inCart}
                    countMouseEnter={countMouseEnter}
                    countMouseLeave={countMouseLeave}
                    team_id={team_id}
                    test={`card_products-${index}`}
                  />
                ))}
              </Content>
            </List>
          ),
          [DETAIL]: (
            <>
              <Detail
                addToCart={addToCart}
                addCountGood={addCountGood}
                removeCountGood={removeCountGood}
                setScreen={setScreen}
                inCart={inCart}
                productScreen={productScreen}
                locale={locale}
              />
            </>
          ),
        }[productScreen.screen]
      }
    </>
  );
};

const List = styled.div`
  ${getLayoutScreenCss(2, 1.5)};
`;
const Top = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 32px;
  width: 100%;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
const Description = styled.div`
  margin-right: 80px;
  width: 100%;
  max-width: 620px;
`;
const Helpers = styled.div`
  padding-right: 15px;
`;
const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 52px;
  line-height: 52px;
  color: ${Colors.night};
  margin-bottom:  15px;
  font-weight: 800;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;
