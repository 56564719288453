import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Input } from 'planoplan-ui-kit/core/input';
import { UrlButton } from '@components';
import { Link } from '@components/link';
import { translations, LocalStorage } from '@libs';
import { analytics } from '../../modules/analytics';
import * as actions from '../../modules/Store';
import { CHECKING_MODE, ERROR_TYPES } from '../../constants';
import { POP_USER_ID_FROM_TOKEN } from '@globalLocalStorage';
import { Field, Form, Header, Text, Image, Description, Error } from '../../components';
import { Resend } from './resend';

export const Check = ({
  isProcessing,
  isCanResendCode,
  checkingEmail,
  onCheckEmail,
  onResendCheckEmailCode,
  onConfirmRecoveryPassword,
  onResendRecoveryPasswordCode,
  onSetCanResendCode,
  onClearError = () => {},
  onSkipCheckEmail,
  checkingMode,
  settings,
  error = {},
}) => {
  const dispatch = useDispatch();
  const { isLogin, signUpMode, loginMode } = useSelector(state => state);
  const inputCode0 = useRef(null);
  const inputCode1 = useRef(null);
  const inputCode2 = useRef(null);
  const inputCode3 = useRef(null);
  const [field, setField] = useState(['','','','']);
  const [pasteVal, setPasteVal] = useState(false);

  const isConfirmEmailAfterSignUp = checkingMode === CHECKING_MODE.CONFIRM_EMAIL_AFTER_SIGNUP;
  const isConfirmEmailAfterLogin = checkingMode === CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN;
  const isConfirmEmailMode = isConfirmEmailAfterSignUp || isConfirmEmailAfterLogin;
  const isError = error.type === ERROR_TYPES.LOCAL;
  const checkTimerId = isConfirmEmailMode ? 'confirmResend' : 'recoveryResend';
  const { mode, locale } = settings;

  const onPaste = (event) => {
    const clipboardData = event.clipboardData || event.originalEvent.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');

    if (Number(pastedData) && pastedData.length >= 4) {
      setPasteVal(pastedData);
    }
  };

  const onChange = ({ value, error, name }) => {
    const index = Number(name.slice(-1));
    const val = value.replace(`${field[index]}`, '').trim();

    if (pasteVal && Number(pasteVal)) {
      setField(pasteVal.split('').slice(0, 4));
      setPasteVal('');
    }

    if (((!Number(val) && Number(val) !== 0) || val.length > 1) && val !== '') {
      return;
    }

    if (val) {
      switch (index) {
        case 0: inputCode1.current.focus(); break;
        case 1: inputCode2.current.focus(); break;
        case 2: inputCode3.current.focus(); break;
        case 3: inputCode3.current.blur(); break;
      }
    } else {
      switch (index) {
        case 1: inputCode0.current.focus(); break;
        case 2: inputCode1.current.focus(); break;
        case 3: inputCode2.current.focus(); break;
      }
    }

    const newValue = [...field];

    newValue[index] = val;
    setField(newValue);
  };

  const onKeyDown = (event) => {
    const index = Number(event.target.name.slice(-1));

    // Backspace
    if (event.keyCode === 8) {
      if (!event.target.value) {
        switch (index) {
          case 1: inputCode0.current.focus(); break;
          case 2: inputCode1.current.focus(); break;
          case 3: inputCode2.current.focus(); break;
        }

        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  const onSubmit = () => {
    dispatch(actions.clearError());

    const value = field.join('');

    if (isConfirmEmailMode) {
      onCheckEmail(value);
    } else {
      onConfirmRecoveryPassword(value);
    }
  };

  const onResend = () => {
    if (isConfirmEmailMode) {
      onResendCheckEmailCode();
    } else {
      onResendRecoveryPasswordCode();
    }
  };

  useEffect(() => {
    if (isConfirmEmailAfterLogin) {
      onResendCheckEmailCode();
    }

    const userIdFromToken = LocalStorage.get(POP_USER_ID_FROM_TOKEN);

    analytics.pushView(`Форма регистрации - почта из ${isLogin ? loginMode : signUpMode} - confirmation`, 'sign-up-conf', 'view', isLogin ? loginMode : signUpMode, userIdFromToken);
  }, []);

  useEffect(() => {
    if (field.join('').length === 4) {
      onSubmit();
    }
  }, [field]);

  useEffect(() => {
    if (isError) {
      setField(['','','','']);
    }
  }, [isError]);

  return (
    <>
      <Header title={translations.t('form_entry.check.header')} />
      <Image normal="form/check-your-email.jpg" retina="form/check-your-email@2x.jpg" alt="Check your email" />
      <Text>
        <span dangerouslySetInnerHTML={{ __html: translations.t('form_entry.check.send') }} />
        <Email>{checkingEmail}</Email>
        <span dangerouslySetInnerHTML={{ __html: translations.t('form_entry.check.code') }} />
        &nbsp;
        {translations.t('form_entry.check.enter')}
      </Text>

      <Form>
        <ViewCodeWrap>
          <ViewCode>
            <TitleCode>{translations.t('form_entry.check.title')}</TitleCode>

            <Field isError={isError}>
              <FieldCode>
                <CodeInput
                  ref={inputCode0}
                  name="code_0"
                  type="text"
                  value={field[0]}
                  autoFocus={'autoFocus'}
                  onChange={onChange}
                  onPaste={onPaste}
                  onKeyDown={onKeyDown}
                  required />
                <CodeInput
                  ref={inputCode1}
                  name="code_1"
                  type="text"
                  value={field[1]}
                  onChange={onChange}
                  onPaste={onPaste}
                  onKeyDown={onKeyDown}
                  required />
                <CodeInput
                  ref={inputCode2}
                  name="code_2"
                  type="text"
                  value={field[2]}
                  onChange={onChange}
                  onPaste={onPaste}
                  onKeyDown={onKeyDown}
                  required />
                <CodeInput
                  ref={inputCode3}
                  name="code_3"
                  type="text"
                  value={field[3]}
                  onChange={onChange}
                  onPaste={onPaste}
                  onKeyDown={onKeyDown}
                  required />
              </FieldCode>
            </Field>

            {isError && <Error>{error.message}</Error>}
          </ViewCode>
        </ViewCodeWrap>

        <Resend
          checkTimerId={checkTimerId}
          isCanResendCode={isCanResendCode}
          onResend={onResend}
          onCountDownStop={() => onSetCanResendCode(true)}
        />

        <Description>
          <span dangerouslySetInnerHTML={{ __html: translations.t('form_entry.check.received') }} />

          <Link fontSize="inherit" href={'mailto:support@planoplan.com'}> support@planoplan.com</Link>
        </Description>

        {isConfirmEmailMode && (
          <Skip>
            <Link fontSize="inherit" type="button" size="S" onClick={onSkipCheckEmail}>
              {translations.t('form_entry.check.skip')}
            </Link>
          </Skip>
        )}
      </Form>
    </>
  );
};

const Email = styled.b`
  word-wrap: break-word;
`;
const Skip = styled.div`
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
`;
const ViewCodeWrap = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
`;
const ViewCode = styled.div`
`;
const TitleCode = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.coal};
  margin-bottom: 4px;
  flex-grow: 1;
`;
const FieldCode = styled.div`
  display: flex;
  align-items: center;
  margin-right: -10px;
`;
const CodeInput = styled(Input)`
  width: 46px;
  font-size: 32px;
  color: ${Colors.coal};
  text-align: center;
  font-weight: 400;
  margin-right: 10px;
  padding: 0;
`;
