import React, { useContext } from 'react';
import styled from 'styled-components';
import { IClose } from 'planoplan-ui-kit/core/icons/i-close';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { isMobile } from 'react-device-detect';
import { StoreContext } from '../../modules/Store';
import * as effects from '../../modules/effects';

export const Close = ({ width, height }) => {
  const [state] = useContext(StoreContext);

  const onClick = () => {
    effects.closeStore(state);
  };

  return (
    <View onClick={onClick} data-test="button-close">
      <IClose width={width} height={height} fill={Colors.white} />
    </View>
  );
};

const View = styled.button`
  ${Mixins.resetButton()};

  svg {
    ${Mixins.transition('transform')};
  }

  &:hover svg {
    transform: ${!isMobile && 'scale(0.9)'};
  }
`;
