import { INTERFACE_INIT, LAYOUT_INIT, SET_ZOOM, TOKENS_UPDATE, PROJECT_WAS_OPENED, SHORTAGE_TEAM_LICENSE, LICENSE_UPDATE, LANGUAGE_CHANGE } from '@features/unity/constants';
import { STORE_OPEN } from '@features/store/constants';
import { QUICK_STORE_OPEN } from '@features/quick-store/constants';
import { GALLERY_OPEN, GALLERY_CLOSE } from '@features/gallery/constants';
import { DESIGN_PROJECT_OPEN, DESIGN_PROJECT_CLOSE } from '@features/design-project/constants';
import { LOADER_OPEN, LOADER_CLOSE, LOADER_READY } from '@features/loader/constants';
import { PROJECTS_OPEN, PROJECTS_CLOSE } from '@features/projects/constants';
import { IMAGE_EDITOR_OPEN, IMAGE_EDITOR_CLOSE } from '@features/image-editor/constants';
import { TOUR_EDITOR_OPEN, TOUR_EDITOR_CLOSE } from '@features/tour-editor/constants';
import { SHOW_BANNER } from '@features/banners/constants';
import { CABINET_OPEN, CABINET_CLOSE } from '@features/cabinet/constants';
import { ASSISTANT_OPEN, ASSISTANT_CLOSE } from '@features/assistant/constants';
import { GETTING_STARTED_OPEN, GETTING_STARTED_CLOSE } from '@features/getting-started/constants';

export const actions = {
  StoreOpen: {
    action: STORE_OPEN,
  },
  QuickStoreOpen: {
    action: QUICK_STORE_OPEN,
  },
  InterfaceInit: {
    action: INTERFACE_INIT,
  },
  LayoutInit: {
    action: LAYOUT_INIT,
  },
  GalleryOpen: {
    action: GALLERY_OPEN,
  },
  GalleryClose: {
    action: GALLERY_CLOSE,
  },
  DesignProjectOpen: {
    action: DESIGN_PROJECT_OPEN,
  },
  DesignProjectClose: {
    action: DESIGN_PROJECT_CLOSE,
  },
  TokensUpdate: {
    action: TOKENS_UPDATE,
  },
  LoaderOpen: {
    action: LOADER_OPEN,
  },
  LoaderClose: {
    action: LOADER_CLOSE,
  },
  LoaderReady: {
    action: LOADER_READY,
  },
  ProjectsOpen: {
    action: PROJECTS_OPEN,
  },
  ProjectsClose: {
    action: PROJECTS_CLOSE,
  },
  SetZoom: {
    action: SET_ZOOM,
  },
  ImageEditorOpen: {
    action: IMAGE_EDITOR_OPEN,
  },
  ImageEditorClose: {
    action: IMAGE_EDITOR_CLOSE,
  },
  TourEditorOpen: {
    action: TOUR_EDITOR_OPEN,
  },
  TourEditorClose: {
    action: TOUR_EDITOR_CLOSE,
  },
  ShowBanner: {
    action: SHOW_BANNER,
  },
  OpenCabinet: {
    action: CABINET_OPEN,
  },
  CloseCabinet: {
    action: CABINET_CLOSE,
  },
  OpenAssistant: {
    action: ASSISTANT_OPEN,
  },
  CloseAssistant: {
    action: ASSISTANT_CLOSE,
  },
  ProjectWasOpened: {
    action: PROJECT_WAS_OPENED,
  },
  shortageTeamLicense: {
    action: SHORTAGE_TEAM_LICENSE,
  },
  LicenseUpdate: {
    action: LICENSE_UPDATE,
  },
  LanguageChange: {
    action: LANGUAGE_CHANGE,
  },
  GettingStartedOpen: {
    action: GETTING_STARTED_OPEN,
  },
  GettingStartedClose: {
    action: GETTING_STARTED_CLOSE,
  },
};
