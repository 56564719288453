import React from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { Project } from './project';
import { Folder } from './folder';
import { ROW_TYPE } from '../constants';

export const ProjectCard = ({
  data = {},
  editListFolder = [],
  editListProject = [],
  currentHeaderMode = '',
  setFolderId = () => {},
  onOpenProject = () => {},
  openGallery = () => {},
  openLink = () => {}
}) => {
  const { row_type } = data;
  const editList = (row_type === ROW_TYPE.project ? editListProject : editListFolder).filter((item) => {
    return !((data.pinned_at && item.alias === 'pin') || (!data.pinned_at && item.alias === 'unpin'))
  });

  return (
    <View>
      {row_type === ROW_TYPE.project &&
        <Project
          project={data}
          editList={editList}
          currentHeaderMode={currentHeaderMode}
          onOpenProject={onOpenProject}
          openGallery={openGallery}
          openLink={openLink}/>}
      {row_type === ROW_TYPE.folder &&
        <Folder
          folder={data}
          editList={editList}
          setFolderId={setFolderId}
          currentHeaderMode={currentHeaderMode} />}
    </View>
  )
};

const View = styled.div`
  height: 100%;
  width: 100%;
`;
