import { MODE_ALIAS, UNITY, PROFILE_STATUS, ISDEVELOPMENT } from '@globalConstants';
import { POP_SCREEN_ENTRY_DOMAIN, POP_SCREEN_ENTRY_LOGIN } from '@globalLocalStorage';
import { MODALS } from '@features/unity/constants';
import { LocalStorage, getSubDomain, translations } from '@libs';
import { CHECKING_MODE, ENTRY_STEPS, ERROR_TYPES, LOGIN_MODE } from '../constants';
import { ApiCall } from './api-call';
import { analytics } from './analytics';
import {
  setInitAnalytics,
  setSelectedDomain,
  setStep,
  tokensReceived,
  successLogin,
  moveOnCheckingEmail,
  moveOnSurveyScreen,
  getUserSettings,
} from './effects';
import { vkCall } from './api-social';
import * as actions from './Store';
import { setSocialInfo } from './Store';

let socialData = {};
let sendOneReq = false;

// Установка доменов из массива
const setDomains = (data) => (dispatch) => {
  if (!data || data.length === 0) {
    return dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: translations.t('form_entry.wrong') }));
  }

  const domains = data.map((item) => {
    return {
      view_value: item.name,
      value: item.domain,
      render: item.name,
      host: item.host,
    };
  });

  // если есть домент в LS показываем его, иначе выбираем planoplan, если его нет показываем первый
  const getSelectedDomain = () => {
    const domainFromLS = LocalStorage.get(POP_SCREEN_ENTRY_DOMAIN);
    const isContainDomainFromLS = domainFromLS && domains.some((item) => item.value === domainFromLS.value);

    if (isContainDomainFromLS) {
      return domainFromLS;
    } else {
      const popDomain = domains.find((item) => item.value === 'planoplan');
      return popDomain ? popDomain : domains[0];
    }
  };

  const domain = getSelectedDomain();

  dispatch(actions.setDomains(domains));
  dispatch(setSelectedDomain(domain)); // устанавливаем выбранный домен по дефолт

  // если доменов больше 1 показываем шаг с выбором
  if (data.length > 1) {
    dispatch(setStep(ENTRY_STEPS.DOMAINS));
  }
};

// Получение списка доменов
export const getDomains = (username) => async (dispatch, getState) => {
  const state = getState();
  const { settings = {} } = state;
  const { mode } = settings;

  if (MODE_ALIAS[mode] !== UNITY) {
    const subDomain = getSubDomain();
    const domain = subDomain ? subDomain : 'planoplan';
    const domains = [{ domain }];

    dispatch(actions.setDomains(domains));
    dispatch(setSelectedDomain({ value: domain }));

    return;
  }

  dispatch(actions.startProcessing());

  try {
    const response = await ApiCall.getDomains({ username });
    const { data } = response;

    dispatch(setDomains(data));
  } catch (error) {
    dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error }));
    dispatch(setStep(ENTRY_STEPS.ERROR));
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Логин через почту
export const emailLogin = (username, password, { openModal, endAllSessions = false } = {}) => async (dispatch, getState) => {
  dispatch(actions.startProcessing());
  dispatch(actions.setLoginMode(LOGIN_MODE.EMAIL));

  LocalStorage.set(POP_SCREEN_ENTRY_LOGIN, username);

  const state = getState();
  const { selectedDomain = {} } = state;
  const body = { username, password };
  const domain = selectedDomain.value;

  try {
    const response = await ApiCall.getAuth({ body, domain, endAllSessions });
    const { data } = response;
    const { id } = data;

    const responseUser = await ApiCall.getUserProfile();
    const { data: userData } = responseUser;

    if (!data || !userData) {
      throw new Error('EmailLogin is not have login data');
    }

    const { status, surveyed } = userData;

    dispatch(actions.setProfile(userData));
    dispatch(getUserSettings());
    dispatch(actions.setIsLogin(true));
    dispatch(actions.setCheckingMode(CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN));
    dispatch(actions.setLoginMode(LOGIN_MODE.EMAIL));
    dispatch(tokensReceived({ ...data, domain }));

    if (status === PROFILE_STATUS.NOT_CONFIRM) {
      dispatch(moveOnCheckingEmail({ id, email: username }));
      return;
    }

    if (!Boolean(surveyed)) {
      dispatch(moveOnSurveyScreen(surveyed));
      return;
    }

    if (status === PROFILE_STATUS.CONFIRM) {
      await dispatch(setInitAnalytics(id));
      analytics.pushEvent('login', 'sent', 'email', userData.id);
      dispatch(successLogin());
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch(setStep(ENTRY_STEPS.LOGIN));
      dispatch(actions.setError({ type: ERROR_TYPES.LOCAL, error: translations.t('form_entry.wrong') }));
    }
    // Когда запрос вернулся со статусом 409, и axios сразу перекидывает на catch
    else if (error.response && error.response.data && error.response.data.error === 'This account is already used on another device') {
      openModal(MODALS.LOGOUT_ANOTHER_DEVICE);
    } else {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Логин через соцсети, сначала получение кодов от SDK
export const socialLogin = ({ openModal, endAllSessions = false }) => async (dispatch, getState) => {
  dispatch(actions.startProcessing());

  const { selectedDomain, loginMode, isLogin, settings } = getState();
  const domain = selectedDomain.value;
  const social = settings.social || {};

  try {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });

    if (sendOneReq) {
      window.unity_clientID = `${window.unity_clientID.slice(-3)}RIP`;
    }

    const response = await ApiCall.getSocialAuth({ code: socialData.code, domain, endAllSessions });

    sendOneReq = true;
    // Для теста
    /*if (!endAllSessions) {
      throw new Error('This account is already used on another device');
    }*/

    const { data } = response;

    const responseUser = await ApiCall.getUserProfile();
    const { data: userData } = responseUser;

    if (!data || !userData) {
      throw new Error('SocialLogin is not have data');
    }

    const { id, status, name, avatar, email, surveyed } = userData;

    dispatch(actions.setProfile(userData));
    dispatch(getUserSettings());
    dispatch(tokensReceived({ ...data, domain }));
    LocalStorage.set(POP_SCREEN_ENTRY_LOGIN, email);

    analytics.push({
      event: 'gaEvent',
      gaCategory: isLogin ? 'login' : 'sign-up',
      gaAction: 'sent',
      gaLabel: loginMode,
      clientID_web: `${social.gaId}`,
    }, userData);

    // если нет email, то считаем что это регистрация
    if (!email) {
      dispatch(setSocialInfo(name, avatar));
      dispatch(setStep(ENTRY_STEPS.SOCIAL));
      dispatch(actions.setFirstLogin(true));
      dispatch(actions.setSignUpMode(loginMode));
      dispatch(actions.setCheckingMode(CHECKING_MODE.CONFIRM_EMAIL_AFTER_SIGNUP));
      return;
    }

    dispatch(actions.setCheckingMode(CHECKING_MODE.CONFIRM_EMAIL_AFTER_LOGIN));

    if (email && status === PROFILE_STATUS.NOT_CONFIRM) {
      dispatch(moveOnCheckingEmail({ id, email }));
      return;
    }

    if (((email && status === PROFILE_STATUS.CONFIRM) || !Boolean(surveyed)) && !isLogin) {
      analytics.pushEvent('sign-up-conf', 'sent', loginMode);
    }

    if (!Boolean(surveyed)) {
      dispatch(moveOnSurveyScreen(surveyed));
      return;
    }

    if (email && status === PROFILE_STATUS.CONFIRM) {
      dispatch(successLogin());
    }
  } catch (error) {
    console.error(error);

    // Когда запрос вернулся со статусом 409, и axios сразу перекидывает на catch
    if (error.response && error.response.data && error.response.data.error === 'This account is already used on another device') {
      socialData.code = error.response?.data?.code;

      openModal(MODALS.LOGOUT_ANOTHER_DEVICE);
    }
    else {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Получение кода VK и установка доменов
// mode: (vkontakte - для стендалона, vkontakte_popup - для сайта)
export const getVKCode = ({ vkCode = '', mode = 'vkontakte' } = {}) => async (dispatch) => {
  dispatch(actions.startProcessing());
  dispatch(actions.setLoginMode(LOGIN_MODE.VK));

  // Данные передоваемые в шаг вывода ошибки, Для передачи email, social
  const errorData = {};

  try {
    let code = vkCode;

    if (!code) {
      if (ISDEVELOPMENT) {
        code = 123;
      } else {
        const auth = await vkCall();

        code = auth.code;
      }
    }

    const params = { code, mode };
    const response = await ApiCall.getSocialCode({ params });
    const { data, data: { error, login: isLogin } } = response;

    if (error) {
      errorData.email = data && data.email ? data.email : '';
      errorData.social = params.mode;

      throw new Error(error.message);
    }

    const { clients: domains, code: responseCode } = data;
    socialData = {
      code: responseCode,
      params
    };

    dispatch(actions.setIsLogin(isLogin));
    dispatch(setDomains(domains));
  } catch (error) {
    dispatch(actions.setLoginMode(LOGIN_MODE.EMAIL));

    if (error !== 'popup_closed_by_user') {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error, errorData }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};

// Получение кода Google и установка доменов
export const getGoogleCode = ({ code = '', profile } = {}) => async (dispatch) => {
  dispatch(actions.startProcessing());
  dispatch(actions.setLoginMode(LOGIN_MODE.GOOGLE));

  // Данные передоваемые в шаг вывода ошибки, Для передачи email, social
  const errorData = {};

  try {
    const params = { code, mode: 'google', ...profile };
    const response = await ApiCall.getSocialCode({ params });
    const { data, data: { error, login: isLogin } } = response;

    if (error) {
      errorData.email = data && data.email ? data.email : '';
      errorData.social = params.mode;

      throw new Error(error.message);
    }

    const { clients: domains, code: responseCode } = data;
    socialData = {
      code: responseCode,
      params
    };

    dispatch(actions.setIsLogin(isLogin));

    if (domains && Boolean(domains.length)) {
      dispatch(setDomains(domains));
    } else {
      throw new Error('Error domains length');
    }
  } catch (error) {
    dispatch(actions.setLoginMode(LOGIN_MODE.EMAIL));

    if (error.error !== 'popup_closed_by_user') {
      dispatch(actions.setError({ type: ERROR_TYPES.GLOBAL, step: ENTRY_STEPS.LOGIN, error, errorData }));
      dispatch(setStep(ENTRY_STEPS.ERROR));
    }
  } finally {
    dispatch(actions.endProcessing());
  }
};
