import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as effects from '../../modules/effects';

export const NumberList = ({ number, name = '', id = null, input = false, hideNumber = false }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(name);
  const [focus, setFocus] = useState(false);

  const focusOn = () => input && setFocus(true);
  const focusOff = () => setFocus(false);
  const onChange = ({ target }) => {
    setValue(target.value.substring(0, 40));
  };
  const onBlur = () => {
    dispatch(effects.updateDesignProjectImageName({ id, name: value }));
    focusOff();
  };


  return (
    <View>
      {!hideNumber && <Number focus={input && focus} onClick={focusOn}>{number}</Number> }

      {!focus && <Name input={input} onClick={focusOn}>{value}</Name>}

      {(input && focus) && <Input autoFocus={true} value={value} onChange={onChange} onBlur={onBlur} />}
    </View>
  );
};

const View = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 26px;
`;
const Number = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${Colors.night};
  color: ${Colors.white};
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${({ focus }) => focus ? '2px' : '6px'};
`;
const Name = styled.div`
  color: ${Colors.white};
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  cursor: ${({ input }) => input ? 'text' : 'default'};
  margin-top: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const Input = styled.input`
  border: none;
  outline: none;
  color: ${Colors.white};
  font-family: 'Roboto Condensed', 'MuseoCyrl', 'Museo', sans-serif;
  font-size: 14px;
  line-height: 14px;
  padding: 0 4px;
  height: 26px;
  border-radius: 4px;
  background-color: ${Colors.night};
  flex-grow: 1;
`;
