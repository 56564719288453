import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CardElement, IbanElement, IdealBankElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PAY_METHODS } from "@features/store/constants";
import { Indent } from "@features/store/modules/indent";
import { tablet_landscape } from "@features/store/modules/breakpoints";

export const PayElements = ({ card, setCard = () => {} }) => {
  const {
    settings: { zoom },
    selected: { paymentMethod }
  } = useSelector(state => state);
  const method = paymentMethod.alias;

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: 'black',
      },
      invalid: {
        color: 'red',
      },
      complete: {
        color: 'orange',
      },
    },
  };
  const ibanOptions = {
    supportedCountries: ['SEPA'],
  };
  const idealElementOptions = {
    style: {
      base: {
        'padding': '11px 16px',
        'border': 'none',
        'iconColor': '#4A4A4A',
        'color': '#4A4A4A',
        'fontSize': '16px',
        '::placeholder': {
          color: '#4A4A4A',
        },
        ':-webkit-autofill': {
          color: '#4A4A4A',
        },
      },
    },
  };

  const onChangeCard = (e) => {
    setCard({
      valid: e.complete,
      error: e.error && e.error.message ? e.error.message : false,
    });
  };

  return (
    <View>
      {(method === PAY_METHODS.SEPA_DEBIT || method === PAY_METHODS.CARD || method === PAY_METHODS.IDEAL) &&
        <Card method={method} zoom={zoom}>
          {method === PAY_METHODS.SEPA_DEBIT && <IbanElement onChange={onChangeCard} options={ibanOptions}/>}
          {method === PAY_METHODS.IDEAL && <IdealBankElement onChange={onChangeCard} options={idealElementOptions}/>}
          {method === PAY_METHODS.CARD && <CardElement onChange={onChangeCard} options={cardElementOptions}/>}

          {card.error && <CardElementError>{card.error}</CardElementError>}
        </Card>
      }
    </View>
  );
};

const View = styled.div``;
const Card = styled.div`
  ${Indent.margin_bottom(2)};
  height: 48px;
  background: #f5f7f6;
  display: flex;
  align-items: center;
  padding: 0 ${(props) => (props.method === PAY_METHODS.IDEAL ? '0' : '10')}px;
  border-radius: 4px;
  border: 1px solid #E5EAF1;
  position: relative;

  & > div {
    width: ${({ zoom }) => 100 / zoom}%;
    transform: scale(${({ zoom }) => zoom});
    transform-origin: top left;
  }

  @media ${tablet_landscape} {
    padding: 0 ${(props) => (props.method === PAY_METHODS.IDEAL ? '0' : '16')}px;
    margin-top: 23px;
  }
`;
const CardElementError = styled.div`
  color: #FF473F;
  font-size: 13px;
  line-height: 16px;
  margin-top: 2px;
  margin-left: 4px;
  font-weight: 300;
  position: absolute;
  top: 100%;
  left: 0;
`;
