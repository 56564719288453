import { createBreakpoints } from '@libs';

const points = [
  {
    mobile: 0,
  },
  {
    tablet_portrait: 600,
  },
  {
    tablet_landscape: 1024,
  },
  {
    laptop: 1280,
  },
  {
    laptop_wide: 1366,
  },
];

export const {
  mobile_only,
  tablet_portrait,
  tablet_portrait_only,
  tablet_landscape,
  laptop,
  laptop_wide,
} = createBreakpoints(points);
