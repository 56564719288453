import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CloseModal, useModals } from '@components';
import { APP_CLOSE, MODALS } from '../constants';
import * as effects from './modules/effects';

export const App = () => {
  const state = useSelector((state) => state);
  const [modals, openModal, closeAllModals] = useModals([MODALS.CLOSE]);
  const { settings } = state;

  const onClose = () => openModal(MODALS.CLOSE);
  const onCloseSubmit = () => effects.closeApp(settings.close);

  useEffect(() => {
    window.invokeAPI.registerDispatch('LAYOUT', {
      [APP_CLOSE]: () => onClose(),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <View>
      <TopPanel>
        <UserWidget id="user-widget" />
      </TopPanel>
      <CloseModal isOpen={modals[MODALS.CLOSE].isOpen} onClose={closeAllModals} onSubmit={onCloseSubmit} />
    </View>
  );
};

const View = styled.div``;

const TopPanel = styled.div`
  width: 100%;
`;

const UserWidget = styled.div`
  position: absolute;
  top: 5px;
  right: 45px;
`;
