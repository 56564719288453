import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { ListManager } from 'react-beautiful-dnd-grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import * as effects from '../../modules/effects';
import { TableItem } from '../TableItem';
import { points } from '../../modules/breakpoints';

const debounceFunc = debounce(350, (fun) => fun());

export const Content = () => {
  const dispatch = useDispatch();
  const container = useRef(null);
  const { design_project, settings: { zoom }} = useSelector((state) => state);
  const { additional } = design_project;
  const images = (design_project.images || []).map((image, key) => ({...image, index: key}));
  const [widthItem, setWidthItem] = useState(0);
  const [widthMargin, setWidthMargin] = useState(0);
  const [resizing, setResizing] = useState(false);
  const offsetCountPage = (additional.displayTitle && additional.displayList) ? 3 : (additional.displayTitle || additional.displayList) ? 2 : 1;

  const sortRawList = (list) => {
    return [...list].sort((first, second) => first.order - second.order);
  };
  const sortList = () => {
    dispatch(effects.updateDesignProjectImages(sortRawList(images)));
  };
  const reorderList = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }

    if (destinationIndex === 0) {
      images[sourceIndex].order = images[0].order - 1;
      sortList();

      return;
    }

    if (destinationIndex === images.length - 1) {
      images[sourceIndex].order = images[images.length - 1].order + 1;
      sortList();

      return;
    }

    if (destinationIndex < sourceIndex) {
      images[sourceIndex].order =
        (images[destinationIndex].order + images[destinationIndex - 1].order) / 2;
      sortList();

      return;
    }

    images[sourceIndex].order =
      (images[destinationIndex].order + images[destinationIndex + 1].order) / 2;
    sortList();
  };

  const reportWindowSize = () => {
    setResizing(true);

    const widthWindow = window.innerWidth;
    const width = container.current.offsetWidth;
    let columns = 36.4;

    if (widthWindow >= points.laptop_wide * zoom) {
      columns = 48.9;
    }

    if (widthWindow >= points.full_hd_second * zoom) {
      columns = 61.4;
    }

    setWidthItem(width * (11.466 / columns * 100) / 100);
    setWidthMargin(width * (1 / columns  * 100) / 100);

    debounceFunc(() => setResizing(false));
  };

  useEffect(() => {
    reportWindowSize();
  }, [container]);

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.removeEventListener('resize', reportWindowSize);
    }
  }, []);

  const setGridItems = () => {
    if (window.innerWidth >= points.full_hd_second * zoom) {
      return 5;
    }

    if (window.innerWidth >= points.laptop_wide * zoom) {
      return 4;
    }

    return 3
  };

  return (
    <View>
      <Wrapper>
        <Container ref={container}>
          {!resizing && (
            <ListManager
              items={images}
              direction="horizontal"
              maxItems={setGridItems()}
              render={(item) => <TableItem item={item} width={widthItem} margin={widthMargin} offsetCountPage={offsetCountPage} />}
              onDragEnd={reorderList}
            />
          )}
        </Container>
      </Wrapper>
    </View>
  );
};
const View = styled.div`
`;
const Wrapper = styled.div`
  padding-right: 20px;
`;
const Container = styled.div`
  overflow: hidden;
`;
