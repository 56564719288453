import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Button } from 'planoplan-ui-kit/core/button';
import { Select } from 'planoplan-ui-kit/core/select';
import { translations } from '@libs';
import { tablet_portrait_only, tablet_landscape, laptop } from '../../../modules/breakpoints';
import { Indent } from '../../../modules/indent';
import { getOptions, getCountriesOptions, pushCountryOption } from '../molecules/options';
import { FieldInput } from '../atom/input';
import * as effects from '../effects';
import {
  toRound,
  getViewPrice,
  getObjViewPrice,
  getAnalyticParamsTariff,
  getAnalyticParamsRender
} from '../../../modules/helpers';
import { StoreContext } from '../../../modules/Store/Store';
import { extraFields } from '../molecules/extra-fields';
import { CURRENCY } from '../../../constants';
import { Analytics } from "../../../modules/analytics";

export const Payment = ({ cart }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { bindingCards, paymentMethods, profile, profileGoods, sumWithPromo, settings, promo, countries } = state;
  const { promocode } = settings;
  const optionsData = getOptions(bindingCards, paymentMethods);
  const countriesData = countries ? getCountriesOptions(countries) : null;

  const getCurrentCountrySelect = () => {
    const {country} = profile;
    const current = countriesData.find((itemCountry) => itemCountry.value === country.IsoCode);

    if (current) {
      return current;
    }

    const {locale} = settings;
    const key = pushCountryOption(country, countriesData, locale);

    return countriesData[key];
  };

  const initFields = {
    receipt_email: { value: profile.email, error: '', needValid: true, isValid: !!profile.email, trigger: 0 },
    extra_data: { value: '', error: '', needValid: false, isValid: false },
    agreement: { value: true, error: '', needValid: true, isValid: true },
    promo: { value: promocode || promo },
  };

  const [selected, setSelected] = useState(optionsData[0]);
  const [fields, setFields] = useState(initFields);
  const [isValid, setIsValid] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(getCurrentCountrySelect());

  const sum = cart.reduce((acc, currentValue) => {
    return toRound(acc + currentValue.view_price, 2);
  }, 0);

  const total = sumWithPromo ? sumWithPromo : sum;
  const vat = selectedCountry && selectedCountry.vat && profileGoods.currency !== CURRENCY.RUB ? toRound(total * selectedCountry.vat / 100, 2) : 0;

  const onChange = ({ name, value, error, isValid }) => {
    // если клик по соглашению, триггерим инпут с e-mail
    if (name === 'agreement') {
      const trigger = fields.receipt_email.trigger + 1;

      setFields((prevState) => {
        return { ...prevState, receipt_email: { ...prevState.receipt_email, trigger } };
      });
    }

    setFields((prevState) => {
      return { ...prevState, [name]: { ...prevState[name], value, error, isValid } };
    });
  };

  const onBlur = ({ name, value, error, isValid }) => {
    setFields((prevState) => {
      return { ...prevState, [name]: { ...prevState[name], value, error, isValid } };
    });
  };

  const onPromoOk = () => {
    effects.getTotalPriceWithPromo(dispatch, state, { selected, fields, settings, cart });
  };

  const onKeyPress = ({ name, event }) => {
    if (name === 'promo' && event.key === 'Enter') {
      onPromoOk();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isValid) {
      return;
    }

    effects.getPay(dispatch, state, {
      selected,
      country: selectedCountry.value,
      fields,
      settings,
      cart,
      price: sum,
      vat,
      vatPercent: selectedCountry.vat,
      sumPromo: sumWithPromo ? sum - sumWithPromo : ''
    });

    const analyticItems = cart.map(item => {
      if (item.alias === 'tariff' || item.alias === 'businessvr') {
        return getAnalyticParamsTariff(item);
      }

      return getAnalyticParamsRender(item);
    });

    Analytics.ecommercePay(analyticItems);
  };

  useEffect(() => {
    const values = Object.values(fields)
      .filter((item) => item.needValid)
      .map((item) => !item.isValid);
    const isError = values.some((item) => item);

    return isError ? setIsValid(false) : setIsValid(true);
  }, [fields]);

  useEffect(() => {
    const optionDefault = optionsData.find((item) => item.isDefault) || optionsData[0];

    setSelected(optionDefault);
    // eslint-disable-next-line
  }, [bindingCards]);

  useEffect(() => {
    setFields((prevState) => {
      return { ...prevState, extra_data: { ...prevState.extra_data, needValid: !!extraFields[selected.value] } };
    });
  }, [selected]);

  useEffect(() => {
    effects.getTotalPriceWithPromo(dispatch, state, { selected, fields, settings, cart });
    // eslint-disable-next-line
  }, [sum]);

  return (
    <View>
      <Form onSubmit={onSubmit}>
        <SumMobile>
          {translations.t('store.cart.total')} {sum} {profileGoods.view_currency}
        </SumMobile>
        {(Boolean(countries) && profileGoods.currency !== CURRENCY.RUB) &&
          <Field>
            <MediaQuery query={tablet_landscape}>
              {(matches) => (
                  <Select
                      options={countriesData}
                      selected={selectedCountry}
                      onSelect={setSelectedCountry}
                      size={matches ? 'M' : 'S'}
                      theme="secondary">
                    {translations.t('store.cart.form.region')}
                  </Select>
              )}
            </MediaQuery>
          </Field>
        }

        <Field>
          <MediaQuery query={tablet_landscape}>
            {(matches) => (
              <Select
                options={optionsData}
                size={matches ? 'M' : 'S'}
                selected={selected}
                onSelect={setSelected}
                theme="secondary"
                test={{ selected: 'cart__pay-method-selected', options: 'cart__pay-method-options' }}>
                {translations.t('store.cart.form.pay_method')}
              </Select>
            )}
          </MediaQuery>
        </Field>
        <Field>
          <FieldInput
            type="email"
            name="receipt_email"
            value={fields.receipt_email.value}
            hasError={fields.receipt_email.error}
            title={translations.t('store.cart.form.email')}
            placeholder="user@planoplan.com"
            onChange={onChange}
            onBlur={onBlur}
            trigger={fields.receipt_email.trigger}
            validation={{
              regexp: RegExp('^[-._a-zA-Za-яA-я0-9]{2,}@(?:[a-zA-Za-яА-Я0-9][-a-z-A-Z-a-я-А-Я0-9]+\\.)+[a-za-я]{2,6}$'),
              errorValid: translations.t('store.cart.form.email_invalid'),
              errorEmpty: translations.t('store.cart.form.not_empty'),
            }}
            required
          />
        </Field>
        {extraFields[selected.value] && (
          <Field>
            <FieldInput
              type={extraFields[selected.value].type}
              name="extra_data"
              value={fields.extra_data.value}
              hasError={fields.extra_data.error}
              title={extraFields[selected.value].title}
              placeholder={extraFields[selected.value].placeholder}
              onChange={onChange}
              onBlur={onBlur}
              required
              validation={{
                regexp: extraFields[selected.value].regexp,
                errorValid: extraFields[selected.value].errorValid,
                errorEmpty: translations.t('store.cart.form.not_empty'),
              }}
            />
          </Field>
        )}
        <Field>
          <FieldInput
            type="text"
            name="promo"
            value={fields.promo.value}
            title={translations.t('store.cart.form.promo')}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
          <PromoButton>
            <Button theme="primary" size="S" type="button" onClick={onPromoOk} data-test="cart__button-promo-ok">
            {translations.t('store.promo.apply')}
            </Button>
          </PromoButton>
        </Field>

        <Bottom>
          <Sum data-test="cart__price-total">
            <SumText>{translations.t('store.cart.total')}</SumText>
            <SumPrice>{getObjViewPrice(profileGoods.view_currency, total + vat)}</SumPrice>
          </Sum>

          {(sumWithPromo || Boolean(vat)) && (
            <Discount>
              <DiscountItem data-test="cart__price-without-discount">
                <span>{translations.t('store.cart.price.without.discount')}</span>
                <span>{getViewPrice(profileGoods.view_currency, sum)}</span>
              </DiscountItem>
              {(sumWithPromo && sum - sumWithPromo > 0) && (
                <DiscountItem data-test="cart__price-discount">
                  <span>{translations.t('store.cart.discount')}</span>
                  <span>-{getViewPrice(profileGoods.view_currency, sum - sumWithPromo)}</span>
                </DiscountItem>
              )}

            </Discount>
          )}
          {Boolean(vat) &&
            <Vat data-test="cart__price-discount">
              <span>{translations.t('store.cart.vat')} ({selectedCountry.vat}%)</span>
              <span>{getViewPrice(profileGoods.view_currency, vat)}</span>
            </Vat>
          }

          <Sumbit>
            <Button type="submit" theme="primary" size="M" disabled={!isValid} data-test="cart__go-to-pay">
              {translations.t('store.cart.form.pay')}
            </Button>
          </Sumbit>
        </Bottom>
      </Form>
    </View>
  );
};

const View = styled.div`
  ${Grid.suffix(2, 32)};
  ${Grid.prefix(2, 32)};
  ${Indent.padding_top(1)};
  ${Indent.padding_bottom(1)};
  margin-top: 50px;
  background-color: ${Colors.white};
  color: ${Colors.coal};

  @media ${tablet_landscape} {
    ${Grid.column(12, 32)};
    ${Grid.suffix(1, 32)};
    ${Grid.prefix(1, 32)};
    margin-top: 0;
    height: 652px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: sticky;
    top: 0;
  }

  @media ${laptop} {
    ${Grid.column(9, 24)};
    ${Grid.suffix(1, 24)};
    ${Grid.prefix(1, 24)};
  }
`;

const Form = styled.form`
  @media ${tablet_portrait_only} {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${tablet_landscape} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Field = styled.div`
  ${Indent.margin_bottom(1)};
  position: relative;

  @media ${tablet_portrait_only} {
    ${Grid.column(13, 28)};
    ${Grid.post(2, 28)};

    &:nth-child(odd) {
      ${Grid.post(0)};
    }
  }

  input[name='promo'] {
    padding-right: 40px;
  }
`;

const PromoButton = styled.div`
  width: 83px;
  position: absolute;
  top: 19px;
  right: 0;

  @media ${tablet_landscape} {
    top: 30px;
    right: 7px;
  }
`;

const Bottom = styled.div`
  margin-top: auto;
  width: 100%;
`;

const Sum = styled.div`
  
  display: none;
  justify-content: space-between;
  align-items: flex-end;
  color: ${Colors.night};
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  @media ${tablet_landscape} {
    display: flex;
  }
`;

const SumText = styled.div`
  font-size: 24px;
  line-height: 24px;
`;

const SumPrice = styled.div`
  font-size: 40px;
  line-height: 40px;
  
  span {
    font-size: 24px;
    line-height: 24px;
  }
`;

const SumMobile = styled.div`
  width: 100%;
  font-size: 22px;
  line-height: 26px;
  margin-top: 8px;
  margin-bottom: 24px;

  @media ${tablet_landscape} {
    display: none;
  }
`;

const Agreement = styled.div`
  ${Indent.margin_top(1)};

  span {
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
  }

  a {
    display: inline-block;
  }
`;

const Sumbit = styled.div`
  ${Indent.margin_top(1)};
`;

const Discount = styled.div`
  margin-top: 18px;
`;

const DiscountItem = styled.div`
  margin-top: 5px;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.nobel};
  display: flex;
  justify-content: space-between;
`;

const Vat = styled(DiscountItem)``;
