import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { getMediaWithZoom, translations } from '@libs';
import * as effects from '../../modules/effects';
import { Checkbox } from '@components/Form/checkbox';
import { NumberList } from '../NumberList';
import { laptop_wide_blank, full_hd_second_blank } from '../../modules/breakpoints';

export const StructurePage = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { design_project, settings: { zoom } } = state;
  const { additional } = design_project;

  const onChangeCheckbox = (event) => {
    const target = event.target;
    const { additional } = design_project;

    dispatch(effects.updateDesignProjectAdditional({
      ...additional,
      [target.id]: target.checked
    }));
  };

  return (
    <View zoom={zoom}>
      <Container>
        <Skeleton>
          <LineTop>
            <Line w={45} h={10} />
          </LineTop>

          <SkeletonWrap w={86 / 200 * 100}>
            <Line w={60 / 86 * 100} h={4} />
            <Line w={86 / 86 * 100} h={4} />
            <Line w={51 / 86 * 100} h={4} />
            <Line w={64 / 86 * 100} h={4} />
            <Line w={37 / 86 * 100} h={4} />
          </SkeletonWrap>

          <SkeletonWrap w={74 / 200 * 100}>
            <Line w={74 / 74 * 100} h={4} />
            <Line w={60 / 74 * 100} h={4} />
            <Line w={64 / 74 * 100} h={4} />
            <Line w={51 / 74 * 100} h={4} />
            <Line w={64 / 74 * 100} h={4} />
          </SkeletonWrap>
        </Skeleton>

        <Bottom>
          <NumberList number={additional.displayTitle ? 2 : 1} name={translations.t('gallery.pdf.list_page.caption')} hideNumber={!additional.displayList} />

          <Checkbox id={'displayList'} label={translations.t('gallery.pdf.checkbox.export')} checked={additional.displayList} onChange={onChangeCheckbox} />
        </Bottom>
      </Container>
    </View>
  );
};

const View = styled.div`
  ${Grid.column(11.466, 36.4)};
  height: 100%;
  
  ${({ zoom }) => `
    @media ${getMediaWithZoom(laptop_wide_blank, zoom)}{
      ${Grid.column(11.466, 48.9)};
    }
    
    @media ${getMediaWithZoom(full_hd_second_blank, zoom)}{
      ${Grid.column(11.466, 61.4)};
    }
  `}
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.coal};
  padding: 26px 14px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Skeleton = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const SkeletonWrap = styled.div`
  width: ${({ w }) => `${w}%`};

  &:not(:last-child) {
    margin-right: 28px;
  }
`;
const LineTop = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
const Line = styled.div`
  width: ${({ w }) => `${w}%`};
  height: ${({ h }) => `${h}px`};
  background-color: ${Colors.nobel};
  margin-bottom: 10px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
