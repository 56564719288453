// Только для проектов. Ищет ${} из name и заменяет на параметр project. ID ${id} --> ID 1234
export const getCorrectEditList = (editList, project) => {
  return editList.map((item) => {
    const paths = item.name.match(/\$\{[a-z]+\}/);

    if (!paths) {
      return { ...item };
    }

    const key = paths[0].slice(2, -1);
    const value = project[key];

    return {
      ...item,
      name: item.name.replace(/\$\{[a-z]+\}/, value),
    }
  });
};
