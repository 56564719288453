import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconClose, IconHide, IconInfo, IconShow } from '../icons';

export const Header = ({
  title,
  isHidden = false,
  onHide = () => {},
  onShow = () => {},
  onClose = () => {}
}) => {
  return (
    <View>
      <Title>
        <IconInfo />
        <span>{isHidden ? title : translations.t('assistant.panelTitle')}</span>
      </Title>

      <Actions>
        {isHidden ?
          <Action onClick={onShow}><IconShow /></Action> :
          <Action onClick={onHide}><IconHide /></Action>
        }

        <Action onClick={onClose}><IconClose /></Action>
      </Actions>
    </View>
  )
};

const View = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
  color: ${Colors.coal};
  flex-shrink: 0;
  background-color: ${Colors.white};
  
  svg {
    display: block;
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  
  svg {
    display: block;     
    margin-right: 3px;
    margin-top: -1px;
    flex-shrink: 0;
  }
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
`;
const Action = styled.div`
  cursor: pointer;
  
  &:hover {
    svg {
      fill: ${Colors.night};
    }
  }
`;
