import { VIEW_MODE } from '../../constants';

export const initialState = {
  view: VIEW_MODE.CLOSE,
  goods: [],
  tariff: {},
  user: {
    user: {},
    team: {},
  },
  teams: [],
  userSettings: {},
  currentTeam: '',
  isProcessing: true,
  notifications: [],
  errors: {},
  settings: {},
};
