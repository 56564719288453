import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, IbanElement, IdealBankElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'planoplan-ui-kit/core/button';
import { notifications } from '@features/notifications';
import { translations } from '@libs';
import { PAY_METHODS, SCREENS } from '../../../constants';
import { getCurrentPrices } from '../../../modules/helpers';
import { DisplayPrice } from '../../../components/DisplayPrice';
import { StripeForm } from '../organism/form';
import { StripePricing } from '../organism/pricing';
import { PayElements } from '../organism/payElements';
import * as actions from '../../../modules/Store/action';

export const StripeContent = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { payData, profile, selected: { paymentMethod, country }} = useSelector(state => state);
  const [card, setCard] = useState({
    valid: !(paymentMethod.alias === PAY_METHODS.SEPA_DEBIT || paymentMethod.alias === PAY_METHODS.CARD || paymentMethod.alias === PAY_METHODS.IDEAL),
    error: '',
  });
  const [isLoad, setIsLoad] = useState(false);
  const [form, setForm] = useState({
    email: '',
    line1: '',
    line2: '',
    postal_code: '',
    city: '',
  });

  const { totalPrice } = useMemo(() => dispatch(getCurrentPrices()), []);

  const getResponsePay = async () => {
    const { client_secret, return_url } = payData;
    const method = paymentMethod.alias;
    const cardElement = elements.getElement(CardElement);
    const sepaElement = elements.getElement(IbanElement);
    const idealElement = elements.getElement(IdealBankElement);
    const billing_details = {
      name: profile.name,
      email: form.email,
      address: {
        country: country.value,
        city: form.city,
        line1: form.line1,
        line2: form.line2,
        postal_code: form.postal_code,
      },
    };

    if (method === PAY_METHODS.SEPA_DEBIT) {
      return await stripe.confirmSepaDebitPayment(client_secret, {
        payment_method: { sepa_debit: sepaElement, billing_details },
      });
    }

    if (method === PAY_METHODS.CARD) {
      return await stripe.confirmCardPayment(client_secret, {
        payment_method: { card: cardElement, billing_details },
      });
    }

    if (method === PAY_METHODS.ALIPAY) {
      return await stripe.confirmAlipayPayment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.BANCONTACT) {
      return await stripe.confirmBancontactPayment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.BANCONTACT) {
      return await stripe.confirmBancontactPayment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.EPS) {
      return await stripe.confirmEpsPayment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.GIROPAY) {
      return await stripe.confirmGiropayPayment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.IDEAL) {
      return await stripe.confirmIdealPayment(client_secret, {
        payment_method: { ideal: idealElement, billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.P24) {
      return await stripe.confirmP24Payment(client_secret, {
        payment_method: { billing_details },
        return_url,
      });
    }

    if (method === PAY_METHODS.SOFORT) {
      // error
      return await stripe.confirmSofortPayment(client_secret, {
        payment_method: {
          sofort: { country },
          billing_details,
        },
        return_url,
      });
    }

    return { error: { message: `Method pay "${method}" invalid` }};
  };
  const onPay = async () => {
    setIsLoad(true);

    const { error } = await getResponsePay();

    setIsLoad(false);

    if (error) {
      console.error(error);

      notifications.showError(translations.t(error.message));

      dispatch(actions.setScreen(SCREENS.error));
    } else {
      dispatch(actions.setScreen(SCREENS.pending));
    }
  };

  return (
    <Content>
      <StripeForm form={form} setForm={setForm} />

      <Main>
        <Top>
          <PayElements card={card} setCard={setCard} form={form} />
        </Top>
        <Bottom>
          <StripePricing />

          <Button size="M" theme="yellow_black" disabled={!card.valid || isLoad} onClick={onPay} >
            {translations.t('quickstore.stripe.pay')} <DisplayPrice>{totalPrice}</DisplayPrice>
          </Button>
        </Bottom>
      </Main>
    </Content>
  );
};

const Content = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;
const Main = styled.div`
  flex-grow: 1;
  max-width: 50%;
  padding: 10px 10px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Top = styled.div``;
const Bottom = styled.div``;
