import { translations } from '@libs';
import { ApiCall } from './api-call';
import { GET_CONTENT, GET_TRANSLATES, SET_LOADING_OFF, SET_LOADING_ON, SET_SLIDES, TRANSLATIONS_PATTERNS } from '../constants';

// Получение переводов (для всего юнити!!)
export const getTranslations = () => async (dispatch, getState) => {
  const { localeUi } = getState().settings;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_TRANSLATES } });

  try {
    await translations.load(localeUi, TRANSLATIONS_PATTERNS);
  } catch (error) {
    console.error(error);
  } finally {
    dispatch({
      type: SET_LOADING_OFF,
      payload: { name: GET_TRANSLATES },
    });
  }
};

// получение картинки и подписи
export const getContent = () => async (dispatch, getState) => {
  const { localeUi } = getState().settings;

  dispatch({ type: SET_LOADING_ON, payload: { name: GET_CONTENT } });

  try {
    const slides  = await loadStartScreen(localeUi);

    dispatch({ type: SET_SLIDES, payload: slides || [] });
  } catch (e) {
    dispatch({ type: SET_SLIDES, payload: []});
  } finally {
    dispatch({ type: SET_LOADING_OFF, payload: { name: GET_CONTENT } });
  }
};

// вспомогательная функция для загрузки контента
const loadStartScreen = async (locale) => {
  const response = await ApiCall.getStartScreen({ locale });
  const { data } = response;
  const slides = [];

  if (data.length) {
    data.forEach((slide) => {
      const order = slide.tags.find((tag) => tag.search(/^order_/) !== -1);

      if (order) {
        slides.push({
          ...slide,
          order: +order.replace('order_', ''),
        });
      }
    });
  }

  slides.sort((a, b) => a.order - b.order);

  return slides.slice(0, 4);
};
