import React from 'react';

export const IconSuccess = ({ width = 140, height = 140, fill = '#2F2F2F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="69" fill="#B6E481" stroke="#73D10C" strokeWidth="2"/>
      <path d="M34.5835 67.9165L57.5002 90.8332L105.417 42.9165" stroke="#2F2F2F" strokeWidth="4"/>
    </svg>
  );
};

