import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { URL_OPEN } from '@globalConstants';
import { url } from '@globalInvoke';
import { CART, TARIFF_ALIAS } from '../../constants';
import { getLayoutScreenCss } from '../../modules/styles';
import { sortAndFilterPricing, getProductTariff, isInViewport } from '../../modules/helpers';
import { tablet_landscape } from '../../modules/breakpoints';
import * as effects from '../../modules/effects';
import { StoreContext } from '../../modules/Store';
import { Analytics } from '../../modules/analytics';
import { TariffCard } from './organism/tariffCard';

export const Pricing = ({ pricing, profileGoods, team_id, descriptionTariffs }) => {
  const [wasVisibleTariffs, setWasVisibleTariffs] = useState([]);
  const [elRefs, setElRefs] = React.useState([]);
  const [state, dispatch] = useContext(StoreContext);
  const productTariff = getProductTariff(pricing);
  const filterPricing = sortAndFilterPricing(profileGoods, pricing, productTariff, descriptionTariffs);
  const { scrollY, settings: { mode, locale }} = state;

  const addToCart = (id, item) => {
    if (item.alias === TARIFF_ALIAS.BUSINESS) {
      window.invokeEditorAction({
        name: url[URL_OPEN].name,
        value: 'mailto:corporate@planoplan.com',
      });

      return;
    }

    effects.addToCart(dispatch, state, id);
    effects.setScreen(dispatch, state, CART);

    Analytics.clickSubscribe(mode, locale, profileGoods.tariff_alias, item.alias);
    Analytics.ecommerceAddCart([item.analyticParams]);
  };

  const scrollPage = () => {
    try {
      const wasVisibleAlias = [...wasVisibleTariffs];
      const container = document.getElementById('scroll-container');

      elRefs.forEach(({ current: el }) => {
        const button = el.querySelector('button[data-alias]');
        const alias = button ? button.dataset.alias : el.dataset.alias;

        if (isInViewport(button || el, container, 50) && !wasVisibleAlias.includes(alias)) {
          wasVisibleAlias.push(alias);
        }
      });

      const notVisible = wasVisibleAlias.filter((alias) => !wasVisibleTariffs.includes(alias));

      if (notVisible.length) {
        const analyticItems = notVisible.map((alias) => filterPricing.find(item => item.alias === alias)?.analyticParams);

        Analytics.ecommerceViewItem(analyticItems);
      }

      setWasVisibleTariffs(wasVisibleAlias);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(scrollPage, [scrollY]);
  useEffect(scrollPage, [elRefs]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(filterPricing.length)
        .fill({})
        .map((_, i) => elRefs[i] || React.createRef()),
    );
  }, [filterPricing.length]);

  useEffect(() => {
    const analyticItems = filterPricing.map(item => item.analyticParams);

    Analytics.viewPricing(mode, locale);
    Analytics.ecommerceView(analyticItems);
  }, []);

  return (
    <View>
      {filterPricing.map((item, i) => (
        <TariffCard
          ref={elRefs[i]}
          key={item.id}
          item={item}
          profileGoods={profileGoods}
          userTariff={profileGoods.tariff_alias}
          addToCart={addToCart} />
      ))}
    </View>
  );
};

const View = styled.div`
  ${getLayoutScreenCss(1, 1)};
  height: 100%;

  @media ${tablet_landscape} {
    align-items: flex-start;
  }

  &:after {
    content: "";
    height: 1px;
    width: 100%;
  }
`;
