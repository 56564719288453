import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_IMAGES,
  SET_PROJECTS,
  SET_FILTERS,
  SET_CURRENT_FILTER,
  SET_CURRENT_IMAGE,
  SET_CHECKED_IMAGES,
  SET_USER_SETTINGS,
  SET_LAZY_IMAGES,
  SET_CREATING_PDF,
  SET_HAS_PDF,
  SET_PROJECT_NAME,
  SET_PROFILE,
  SET_PROFILE_GOODS,
} from '../../constants';

const default_image = { editor: '', isAvailableCheck: false, view_id: null };

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_IMAGES:
      return { ...state, images: action.payload };
    case SET_PROJECTS:
      return { ...state, projects: action.payload };
    case SET_FILTERS:
      return { ...state, filters: action.payload };
    case SET_CURRENT_IMAGE:
      return { ...state, current_image: action.payload ? action.payload : default_image };
    case SET_LAZY_IMAGES:
      return { ...state, lazy_images: action.payload };
    case SET_CURRENT_FILTER:
      return { ...state, current_filter: action.payload };
    case SET_CHECKED_IMAGES:
      return { ...state, checked_images: action.payload };
    case SET_USER_SETTINGS:
      return { ...state, user_settings: action.payload };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
              text: action.payload.text || '',
            },
          },
          processing: true,
          error: '',
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }
    case SET_CREATING_PDF:
      return { ...state, creatingPdf: action.payload };
    case SET_HAS_PDF:
      return { ...state, has_pdf: action.payload };
    case SET_PROJECT_NAME:
      return { ...state, project_name: action.payload };
    case SET_PROFILE:
      return {
        ...state,
        profile: {...action.payload},
      };
    case SET_PROFILE_GOODS:
      return {
        ...state,
        profileGoods: {...action.payload},
      };
    default:
      return state;
  }
};
