import { BUTTONS } from '../constants';

export const mappedButtons = {
  [BUTTONS.IMAGE_EDITOR_CLOSE]: {
    name: 'ImageGalleryClose',
    value: {},
    type: 'object',
    alias: 'Close_ImageGallery',
  },
  [BUTTONS.IMAGE_EDITOR_SAVE]: {
    name: 'ImageGallerySave',
    value: {},
    type: 'object',
    alias: 'Save_ImageGallery',
  },
};
