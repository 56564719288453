import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { BeforeAfterSlider } from '../BeforeAfterSlider';
import { useSelector } from 'react-redux';
import { ALIAS_RESOLUTIONS, VIEW_ALIAS_RESOLUTIONS } from '../../constants';

export const InfoRender = () => {
  const { goods: { main: goods }, settings: { zoom }} = useSelector(state => state);
  const COMPARISON_RENDERS = {
    [ALIAS_RESOLUTIONS.PREVIEW]: 'https://uikit.planoplan.com/static/images/common/comparison_Preview.jpg',
    [ALIAS_RESOLUTIONS.HD]: 'https://uikit.planoplan.com/static/images/common/comparison_HD.jpg',
    [ALIAS_RESOLUTIONS.FULL_HD]: 'https://uikit.planoplan.com/static/images/common/comparison_FullHD.jpg',
    [ALIAS_RESOLUTIONS.QHD]: 'https://uikit.planoplan.com/static/images/common/comparison_QHD.jpg',
    [ALIAS_RESOLUTIONS['4K']]: 'https://uikit.planoplan.com/static/images/common/comparison_4K.jpg',
  };

  const alias = goods?.qualityAlias;
  const comparisonQualityAlias = goods?.comparisonQualityAlias;
  const beforeImage = COMPARISON_RENDERS[comparisonQualityAlias];
  const afterImage = COMPARISON_RENDERS[alias];

  const Before = () => (
    <Slide>
      <Img src={beforeImage} alt="Before" />
      <CaptionBefore>{VIEW_ALIAS_RESOLUTIONS[comparisonQualityAlias]}</CaptionBefore>
    </Slide>
  );

  const After = () => (
    <Slide>
      <Img src={afterImage} alt="After" />
      <CaptionAfter>{VIEW_ALIAS_RESOLUTIONS[alias]}</CaptionAfter>
    </Slide>
  );

  return (
    <View>
      <BeforeAfterSlider zoom={zoom} Before={Before} After={After} />
    </View>
  );
};

const View = styled.div`
  height: 100%;
`;
const Slide = styled.div`
  position: relative;
  height: 100%;
`;
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const Caption = styled.div`
  background-color: ${({ fill }) => fill};
  border-radius: 10px;
  padding: 5px 8px;  
  position: absolute;
  bottom: 20px;
  color: ${Colors.coal};
  font-size: 12px;
`;
const CaptionBefore = styled(Caption)`
  background-color: ${Colors.white};
  left: 20px;
`;
const CaptionAfter = styled(Caption)`
  background-color: ${Colors.dark_cream};
  right: 20px;
`;
