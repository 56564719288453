// https://gist.github.com/mkjiau/650013a99c341c9f23ca00ccb213db1c
import axios from 'axios';
import { isProdHost, isPlanoplanLocal, isEditorPage, getSubDomain, SessionStorage, Cookie, LocalStorage } from '@libs';
import { ISLOCAL, ISDEVELOPMENT, PHPSESSID, ISPRODUCTION, IS_TEST, TOKENS_UPDATE } from '@globalConstants';
import { POP_CURRENT_SUB_DOMAIN, POP_USER_ID_FROM_TOKEN } from '@globalLocalStorage';
import { POP_SESSION } from '@globalCookie';
import { NOT_TOKENS_FOR_REQUEST, ON_AUTH_REQUEST_ERROR_400 } from '@observer/constants';
import { actions } from '@globalInvoke';

const observer = new window.POPObserver();

let instance = null;

class Request {
  constructor() {
    if (!instance) {
      instance = this;
    }
    this.isAlreadyFetchingAccessToken = false;
    this.subscribers = [];

    return instance;
  }

  /**
   * Метод добавляет запрос в очередь
   * @param id - id запроса
   * @param callback - сам запрос
   * @private
   */
  _addSubscribe(id, callback) {
    this.subscribers.push({
      id,
      callback,
    });
  }

  /**
   * Метод удаляет запрос из очереди
   * @param id - id запроса
   * @private
   */
  _removeSubscribe(id) {
    this.subscribers = this.subscribers.filter((item) => item['id'] !== id);
  }

  /**
   * Метод вызывает запросы из очереди
   * @param access_token - новый access token
   * @private
   */
  _callSubscribers(access_token) {
    this.subscribers.map((request) => request.callback(access_token));
  }

  async getTokens(body, recaptcha = '', domain = 'planoplan', endAllSessions = false) {
    const { username, password } = body;
    const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
    const url = ISDEVELOPMENT ? '/json/form-entry/tokens.json' : `https://api.${host}/v2/auth/token/`;
    const method = ISDEVELOPMENT ? 'get' : 'post';

    const response = await axios({
      url,
      method,
      data: {
        username,
        password,
        grant_type: 'password',
        domain,
        scope: 'user',
        g_recaptcha_response: recaptcha,
        fingerprint:  window.unity_clientID,
        end_all_sessions: endAllSessions ? 1 : 0,
      },
      auth: {
        username: 'standalone',
        password: 'GibLxP9EHoOt',
      },
    });

    const { data } = response;

    if (data.error) {
      throw new Error(data.error);
    }

    this.setCookieTokens(data);
    LocalStorage.set(POP_USER_ID_FROM_TOKEN, data.id);

    return response;
  }

  async getSocialTokens(code, domain = 'planoplan', endAllSessions = false) {
    const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
    const url = ISDEVELOPMENT ? '/json/form-entry/tokens.json' : `https://api.${host}/v2/auth/token/`;
    const method = ISDEVELOPMENT ? 'get' : 'post';

    const response = await axios({
      url,
      method,
      data: {
        grant_type: 'authorization_code',
        scope: 'user',
        code,
        domain,
        fingerprint:  window.unity_clientID,
        end_all_sessions : endAllSessions ? 1 : 0,
      },
      auth: {
        username: 'standalone',
        password: 'GibLxP9EHoOt',
      },
    });
    const { data } = response;

    if (data.error) {
      throw new Error(data.error);
    }

    this.setCookieTokens(data);
    LocalStorage.set(POP_USER_ID_FROM_TOKEN, data.id);

    return response;
  }

  call_auth(type, urls, params = '', settings = {}) {
    const { withAlwaysTokens } = settings;

    const inNewSiteHost =
      (window.location.host.indexOf('pp.ksdev.ru') !== -1 ||
        window.location.host.indexOf('planoplan.com') !== -1) &&
      window.location.href.indexOf('cabinet') === -1 &&
      window.location.href.indexOf('projects') === -1;

    // всегда с токенами, пока только используется в форме авторизации
    if (withAlwaysTokens && !IS_TEST) {
      return this._withTokens(type, urls, params, settings);
    }

    if (isPlanoplanLocal) {
      return this._withTokens(type, urls, params, settings);
    }

    if (isEditorPage) {   // на странице редактора всегда токены
      const payload = Cookie.getJSON(POP_SESSION);

      if (payload) {
        return this._withTokens(type, urls, params, settings);
      } else {
        return this._withCookie(type, urls, params, settings);
      }
    }

    if (inNewSiteHost) {
      return this._withTokens(type, urls, params, settings);
    }

    return this._withCookie(type, urls, params, settings);
  }

  call_public(type, urls, params = {}, settings = {}) {
    const headers = {
      'X-Requested-With': 'XMLHttpRequest',
    };

    const { method, data, url, responseType } = this._setRequestParams(type, urls, params, settings);

    return axios({
      method,
      data,
      url,
      responseType,
      headers,
    });
  }

  _withCookie(type, urls, params = '', settings = {}) {
    const { method, data, url, responseType } = this._setRequestParams(type, urls, params, settings);

    if (ISLOCAL) {
      document.cookie = `PHPSESSID=${PHPSESSID}`;
    }

    return axios({
      method,
      data,
      url,
      responseType,
      withCredentials: ISLOCAL, // передает куки
    });
  }

  setCookieTokens({ access_token, refresh_token, expires_in, id = null }) {
    //id - опционально
    if (!access_token || !refresh_token || !expires_in) {
      throw new Error('Invalid token data');
    }

    const expireRatio = 0.9; // из-за разницы с серверным временем, считаем что токен закончится раньше
    const expire = Math.floor(Date.now() / 1000 + expires_in * expireRatio);
    const secure = ISPRODUCTION && !isPlanoplanLocal;
    const expires = 30; // days
    const value = JSON.stringify({ access_token, expire, refresh_token, id });
    Cookie.set(POP_SESSION, value, { expires, secure });
  }

  async _withTokens(type, urls, params = '', settings = {}) {
    const payload = Cookie.getJSON(POP_SESSION);

    if (!payload) {
      observer.postMessage(NOT_TOKENS_FOR_REQUEST);
      throw NOT_TOKENS_FOR_REQUEST;
    }

    const { access_token, refresh_token, expire } = payload;
    const id = Symbol('id');

    const retryOriginalRequest = new Promise((resolve) => {
      this._addSubscribe(id, (access_token) => resolve(request(access_token)));
    });

    const request = async (access_token) => {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${access_token}`,
      };

      const { method, data, url, responseType } = this._setRequestParams(type, urls, params, settings);

      try {
        const request = await axios({
          method,
          url,
          data,
          responseType,
          headers,
        });

        this._removeSubscribe(id);
        return request;
      } catch (error) {
        if (error.response && error.response.status === 400) {
          await this._refreshTokens(refresh_token);
          return retryOriginalRequest;
        }

        throw error;
      }
    };

    if (!this._checkValidToken(expire)) {
      await this._refreshTokens(refresh_token);

      return retryOriginalRequest;
    }

    return request(access_token);
  }

  async _refreshTokens(refresh_token) {
    if (this.isAlreadyFetchingAccessToken) {
      return;
    }

    this.isAlreadyFetchingAccessToken = true;

    const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
    const url = ISDEVELOPMENT ? '/json/form-entry/refresh-tokens.json' : `https://api.${host}/v2/auth/token/`;
    const method = ISDEVELOPMENT ? 'get' : 'post';

    try {
      const response = await axios({
        url,
        method,
        data: {
          refresh_token,
          grant_type: 'refresh_token',
          scope: 'user',
        },
        auth: {
          username: 'standalone',
          password: 'GibLxP9EHoOt',
        },
      });

      const { data } = response;
      const { access_token } = data;

      this._callSubscribers(access_token);
      this.setCookieTokens(data);
      this._invokeUpdateTokens(data);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        this._logout(error);
      }

      throw error;
    } finally {
      this.isAlreadyFetchingAccessToken = false;
    }
  }

  _logout() {
    observer.postMessage(ON_AUTH_REQUEST_ERROR_400);

    if (isEditorPage && window.invokeEditorAction) {
      window.invokeEditorAction({
        name: actions[ON_AUTH_REQUEST_ERROR_400].name,
        value: actions[ON_AUTH_REQUEST_ERROR_400].value,
      });
    }
  }

  _checkValidToken(expire) {
    return Number(expire) >= Math.floor(Date.now() / 1000);
  }

  _setRequestParams(type, urls, params, settings) {
    const { local, prod } = urls;
    const { responseType, useFormData = false, useJson = false, subDomain = '' } = settings;

    const setDataFormat = () => {
      if (params instanceof FormData) {
        params.append('fingerprint', window.unity_clientID);

        return params;
      }

      const sendParams = {
        ...params,
        fingerprint:  window.unity_clientID,
      };

      if (useFormData) {
        return setFormData(sendParams);
      } else if (useJson) {
        return JSON.stringify(sendParams);
      } else {
        return {
          fingerprint:  window.unity_clientID
        };
      }
    };

    const method = type === 'post' && ISDEVELOPMENT ? 'get' : type;
    const host = isProdHost ? 'planoplan.com' : 'pp.ksdev.ru';
    const subDomainHost =
      (isPlanoplanLocal || isEditorPage) && SessionStorage.has(POP_CURRENT_SUB_DOMAIN)
        ? SessionStorage.get(POP_CURRENT_SUB_DOMAIN)
        : getSubDomain();

    const withSubDomain = subDomain ? `${subDomain}.${host}` : host;
    const withSubDomainHost = subDomainHost ? `${subDomainHost}.${host}` : host;
    const full = subDomain ? withSubDomain : withSubDomainHost;

    const path = ISDEVELOPMENT ? local : ISLOCAL ? `/proxy${prod}` : `https://${full + prod}`;
    const url = useFormData || useJson ? path : path + setParams(params);
    const data = setDataFormat();

    return {
      method,
      data,
      url,
      responseType,
    };
  }

  _invokeUpdateTokens(tokens) {
    if (isEditorPage && window.invokeEditorAction) {
      window.invokeEditorAction({
        name: actions[TOKENS_UPDATE].name,
        value: tokens,
      });
    }
  }
}

export const request = new Request();

const setParams = (params) => {
  let paramsString = '';
  let isFirst = true;

  const createParams = (i, item) => {
    if (!(typeof item === 'string' || typeof item === 'number')) {
      return;
    }

    paramsString += isFirst ? '?' : '&';
    paramsString += i + '=' + item;

    isFirst = false;
  };

  for (let i in params) {
    if (typeof params[i] === 'object') {
      params[i].forEach((item) => {
        for (let j in item) {
          createParams(j, item[j]);
        }
      });
    } else {
      createParams(i, params[i]);
    }
  }

  return paramsString;
};

export const setFormData = (params) => {
  const formData = new FormData();

  for (const prop of Object.keys(params)) {
    formData.append(prop, params[prop]);
  }

  return formData;
};
