import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Goods, License, Profile } from '../../components';

export const User = ({ onChangeUser, onLogout }) => {
  const state = useSelector((state) => state);
  const { user, goods, tariff, teams, currentTeam, errors, settings } = state;
  const { mode, locale } = settings;

  const isTeam = !!currentTeam;

  return (
    <View>
      <Balance>
        <License tariff={tariff} user={user} errors={errors} mode={mode} locale={locale} isTeam={isTeam} />
        <Goods goods={goods} errors={errors} mode={mode} locale={locale} />
      </Balance>
      <Profile
        user={user}
        teams={teams}
        currentTeam={currentTeam}
        errors={errors}
        onClickButton={onChangeUser}
        onLogout={onLogout}
        mode={mode}
        locale={locale}
        isTeam={isTeam}
      />
    </View>
  );
};

const View = styled.div`
  position: absolute;
  right: 0;
  padding-top: 45px;
  display: flex;
  align-items: flex-start;
  color: ${Colors.coal};
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`;

const Balance = styled.div`
  margin-right: 5px;
`;
