import { BUTTONS, PAYMENT_SUCCESSFUL } from '../constants';

export const mappedButtons = {
  [BUTTONS.STORE_CLOSE]: {
    name: 'CloseStore',
    value: true,
    type: 'string',
    alias: 'Close_Store',
  },
  [PAYMENT_SUCCESSFUL]: {
    name: 'PaymentSuccessful',
    value: true,
    type: 'string',
    alias: 'Payment_Successful',
  },
};
