import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import * as actions from '../../modules/Store/action';
import { SCREENS } from '../../constants';
import { IconArrow } from '@icons';

export const Back = () => {
  const dispatch = useDispatch();
  const toMain = () => dispatch(actions.setScreen(SCREENS.main));

  return (
    <View onClick={toMain}>
      <IconArrow fill={Colors.dark_cream} />
    </View>
  );
};

const View = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  background-color: ${Colors.coal};
  border-radius: 50%;
  cursor: pointer;
`;
