import {
  SET_SLIDES,
  SET_CURRENT,
  SET_LOADING_ON,
  SET_LOADING_OFF,
} from '../../constants';

export const reducers = (state, action) => {
  switch (action.type) {
    case SET_SLIDES:
      return { ...state, slides: action.payload };
    case SET_CURRENT:
      return { ...state, current: action.payload };
    case SET_LOADING_ON:
      return {
        ...state,
        loading: {
          ...state.loading,
          getters: {
            ...state.loading.getters,
            [action.payload.name]: {
              processing: true,
              error: '',
            },
          },
          processing: true,
          error: '',
        },
      };
    case SET_LOADING_OFF: {
      const getters = {
        ...state.loading.getters,
        [action.payload.name]: {
          processing: false,
          error: action.payload.error || '',
        },
      };

      const values = Object.values(getters);
      const processing = values.some((item) => item.processing);
      const isError = values.find((item) => item.error);
      const error = isError ? isError.error : '';
      const firstLoading = state.loading.firstLoading && processing;

      return {
        ...state,
        loading: {
          ...state.loading,
          getters,
          processing,
          error,
          firstLoading,
        },
      };
    }
    default:
      return state;
  }
};
