// import from libs не работает

export const invokeEditorAction = ({ name, value }) => {
  const EditorInvokeFunction = window.EditorInvokeFunction;

  // для тестирования
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    window.POPInvokeEditorActionScope = {
      ...window.POPInvokeEditorActionScope,
      [name]: value || {},
    };
  }

  try {
    EditorInvokeFunction(name, value);

    if (!window.location.host.indexOf('planoplan.com') !== -1 && process.env.NODE_ENV !== 'test') {
      console.info('%cEDITOR INVOKE MESSAGE', 'color: #228B22', { [name]: value });
    }
  } catch (e) {
    console.error(e);
  }
};

const EditorInvokeFunction = (name, value, ...restProps) => {
  if (typeof window[value] === 'function') {
    window[value](restProps);
  }
};

window.EditorInvokeFunction = EditorInvokeFunction;
