const pushView = (locale, page, title, additional = {}) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push({
    event: 'Pageview',
    pagePath: `/#${locale}/${page}`,
    pageTitle: `${title} (standalone)`,
    ...additional,
  });
};
const pushEvent = (event, projectId) => {
  if (!window.dataLayer) {
    return;
  }

  window.dataLayer.push ({
    'event': 'ga4_Event',
    'evName': 'project_event',
    'evParam1': event,
    'evParam2': projectId
  });
};
const pushEcommerce = (event = '', items = [], additional = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: event,
    ecommerce: {
      items: items,
      ...additional,
    }
  });
};

export const Analytics = {
  openImageEditor: (locale, projectId, projectName, imageId, counRenders) => {
    pushView(locale, `projects/${projectId}/gallery/edit-${imageId}/`, `Редактор изображения ${imageId} - Галерея «${projectName}» - Мои проекты`, {
      v_render_count: counRenders
    });
  },
};
