import { getCurrency } from 'planoplan-ui-kit/core/helpers/get-currency';
import { createStringWithGet } from '@libs';
import { textHooks } from './helpers';
import { PACKAGES, RENDERS, PANORAMAS, WIDGET, CLOUD, BANNERS_TAGS } from '../constants';
import { ICheckMark } from 'planoplan-ui-kit/core/icons/i-check-mark';
import { IInfinity } from 'planoplan-ui-kit/core/icons/i-infinity';

const screenAlias = {
  package: PACKAGES,
  render: RENDERS,
  panorama: PANORAMAS,
  widget: WIDGET,
  diskspace: CLOUD,
};

export const normalizeProducts = (type = 'goods', currentData, state) => {
  const { profileGoods, pricing } = state;

  const nextTariffAlias = currentData.options['editor.nextTariff'] || '';
  const overData = pricing.find((item) => item.alias === nextTariffAlias);
  const overProducts = overData ? overData[type] : [];

  const currentProducts = currentData[type].filter((item) => item.price);

  const currency = getCurrency(profileGoods.currency);

  currentProducts.forEach((item) => {
    const overProduct = overProducts.find((overItem) => item.id === overItem.id);
    const defaultActiveCount = item.in_sets ? item.in_sets.length - 1 : '2';

    item.view_count = 1;
    item.view_price = item.price;
    item.view_type = type;
    item.view_currency = currency;
    item.activeCount = item.activeCount === 0 ? 0 : item.activeCount || defaultActiveCount;
    item.currentTariff = currentData.alias;

    if (overProduct) {
      item.over_price = overProduct.price;
      item.view_over_price = item.over_price;
      item.view_over_tariff = overData.caption;
      item.view_over_tariff_id = overData.id;
      item.view_over_tariff_alias = overData.alias;
    }

    // если у товара нет цены и он есть в сетах, то берем мин цену за 1 единицу из пакетов
    if (overProduct && !overProduct.price && overProduct.in_sets.length) {
      const val = overProduct.in_sets.map((item) => item.price / item.count);

      item.over_price = Math.min(...val);
      item.view_over_price = item.over_price;
    }

    normalizeProduct(item);
  });

  return currentProducts;
};

const normalizeProduct = (item) => {
  const text = textHooks(item.caption);
  const widgetAliases = [
    'widget_publish_plan',
    'widget_publish_top',
    'widget_publish_three_quarter',
    'widget_publish_rotate_360',
    'widget_publish_virtual_tour',
  ];
  if (item.view_type === 'goods') {
    item.view_alias = item.alias;
    item.view_screen = screenAlias[item.alias];
  }

  if (item.view_type === 'sets') {
    item.view_alias = 'package';
    item.view_screen = screenAlias['package'];
  }

  if (widgetAliases.includes(item.alias)) {
    item.view_alias = 'widget';
    item.view_screen = screenAlias['widget'];
  }

  item.view_name = text.out_hooks;
  item.view_size = text.in_hooks;
};

export const normalizeProfile = (data) => {
  const favorite_goods = data.tariff ? data.tariff.options['editor.favoriteGoods'] || [] : [];
  const disable_tabs = data.tariff ? data.tariff.options['editor.store.disableTabs'] || [] : [];

  return {
    currency: data.currency,
    view_currency: getCurrency(data.currency),
    tariff_is_expired: data.tariff.isExpired || false,
    tariff_alias: data.tariff.alias,
    tariff_is_trial: data.tariff.is_trial,
    favorite_goods: favorite_goods.length ? favorite_goods.replace(/\s/g, '').split(',') : [],
    disable_tabs: disable_tabs.length ? disable_tabs.replace(/\s/g, '').toUpperCase().split(',') : [],
  };
};

export const normalizeBanners = (data, team_id) => {
  const staticBanner = data.find((item) => item.tags.includes(BANNERS_TAGS.static));
  const rotation = data.filter((item) => item.tags.includes(BANNERS_TAGS.rotation)) || [];
  const rotationBanner = rotation[Math.floor(Math.random() * rotation.length)];
  const banners = [];

  [rotationBanner, staticBanner].forEach((item, index) => {
    if (!item) {
      return;
    }

    const isLink = item.url.includes('https://');
    // если это не ссылка, то осуществляем переход внутри магазина
    if (isLink) {
      item.url = createStringWithGet(item.url, { team_id });
    } else {
      item.store_id = item.url;
      delete item.url;
    }

    item.id = index;

    banners.push(item);
  });

  return banners;
};

export const normalizeProductsReview = (favoriteGoods, lastBuy, products) => {
  const availableFavoriteGoods = products.filter((item) => favoriteGoods.includes(item.store_id));
  const availableLastBuy = products.filter((item) => lastBuy.includes(item.store_id));
  const productsReview = [];
  const maxItems = 3;

  // Добавляем последние купленные, но не больше чем 3
  availableLastBuy.reverse().forEach((item, index) => {
    if (index < maxItems) {
      productsReview.push(item);
    }
  });

  // Если их меньше 3, то добавляем favoriteGoods из админки
  availableFavoriteGoods.forEach((item) => {
    if (productsReview.length < maxItems) {
      productsReview.unshift(item);
    }
  });

  return productsReview;
};

export const normalizeDescriptionTariffs = (data) => {
  try {
    const items = data.data.short_banners.items;

    for (const key in items) {
      if (Object.prototype.hasOwnProperty.call(items, key)) {
        items[key] = {
          id: items[key].id,
          alias: items[key].alias,
          ...items[key].form_data,
        };

        items[key].raw_description = items[key].raw_description.split('\n').map((item) => {
          const firstSymbol = item.slice(0, 1);
          let icon = '';
          let text = item;

          if (firstSymbol === '-') {
            icon = ICheckMark;
            text = item.slice(2);
          }

          if (firstSymbol === '*') {
            icon = IInfinity;
            text = item.slice(2);
          }

          return {
            icon,
            text,
          }
        });
      }
    }

    return items;
  } catch (error) {
    console.error(new Error(error));

    return {};
  }
};
export const normalizeDescriptionStore = (data) => {
  try {
    const items = data.data.store_content.items;

    for (const key in items) {
      if (Object.prototype.hasOwnProperty.call(items, key)) {
        items[key] = {
          id: items[key].id,
          alias: items[key].alias,
          ...items[key].form_data,
        };
      }
    }

    return items;
  } catch (error) {
    console.error(new Error(error));

    return {};
  }
};
