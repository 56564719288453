import React from 'react';
import styled from 'styled-components';
import { translations } from '@libs';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { rgba } from 'polished';
import { TextWithLinks } from '@components/TextWithLinks';
import { Close } from '../../components/Close';

export const ErrorScreen = () => {
  return (
    <View>
      <Container>
        <Controls>
          <Close />
        </Controls>

        <Content>
          <Title>{translations.t('gallery.error.title')}</Title>
          <TextWithLinks Tag={Text} text={translations.t('gallery.error.text')} />
        </Content>
      </Container>
    </View>
  );
};

const View = styled.div`
  font-family: 'MuseoCyrl', 'Museo', -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  height: 100%;
  padding: 60px 80px;
  min-height: 650px;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.night};
  box-shadow: 0 4px 10px ${rgba(Colors.black, 0.15)};
  position: relative;
  border-radius: 5px;
`;
const Content = styled.div`
  ${Grid.prefix(3, 32)};
  ${Grid.suffix(3, 32)};
  flex-grow: 1;
  color: ${Colors.light_gray};
  
  a {
    color: ${Colors.planoplan_blue};
    text-decoration: none;
  }
`;
const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  background-color: ${Colors.night};
  border-radius: 5px;
  z-index: 4;
  margin-left: 10px;
`;
const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 12px;
`;
const Text = styled.div`
  max-width: 900px;
  font-weight: 300;
`;

