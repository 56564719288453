import React from 'react';
import { ERROR, WARNING } from '../constants';
import { Link } from '@components/link';

// т.к запросы за переводами выполняются асинхронно
const errorMessages = {
  ru: {
    server: {
      type: ERROR,
      render: 'Произошла ошибка сервера',
    },
    login: {
      type: ERROR,
      render: (
        <>
          Пожалуйста, авторизируйтесь на сайте&nbsp;
          <Link fontSize="inherit" href="https://planoplan.com/ru/">
            https://planoplan.com
          </Link>
        </>
      ),
    },
    blocked: {
      type: WARNING,
      render: (
        <>
          Магазин заблокирован, продлите командный аккаунт <br /> или обратитесь в службу поддержки <br />
          <Link fontSize="inherit" href="mailto:support@planoplan.com">
            support@planoplan.com
          </Link>
        </>
      ),
    },
  },
  en: {
    server: {
      type: ERROR,
      render: 'Server error occurred',
    },
    login: {
      type: ERROR,
      render: (
        <>
          Please login at&nbsp;
          <Link fontSize="inherit" href="https://planoplan.com/en/">
            https://planoplan.com
          </Link>
        </>
      ),
    },
    blocked: {
      type: WARNING,
      render: (
        <>
          Store locked, renew team account <br /> or contact support
          <Link fontSize="inherit" href="mailto:support@planoplan.com">
            support@planoplan.com
          </Link>
        </>
      ),
    },
  },
};

export const getErrorMessage = (state, type) => {
  const { settings } = state;
  const { localeUi } = settings;

  return errorMessages[localeUi][type] || type;
};
