import React from 'react';
import styled, { keyframes } from 'styled-components';
import { tablet_landscape } from '../../modules/breakpoints';

export const Field = ({ isError, children }) => <View isError={isError}>{children}</View>;

const bounce = keyframes`
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

const View = styled.fieldset`
  margin: 0 0 12px 0;
  border: 0;
  padding: 0;
  animation-duration: 0.2s;
  animation-name: ${(p) => (p.isError ? bounce : null)};

  @media ${tablet_landscape} {
    margin: 0 0 20px 0;
  }
`;
