import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const SocialConfirmVk = ({ isOpen, onCancel = () => {}, onConfirm = () => {} }) => (
  <Modal isOpen={isOpen} onClose={onCancel}>
    <Header>{translations.t('users.profile.multiauth.vk.popup.title')}</Header>
    <Text dangerouslySetInnerHTML={{ __html: translations.t('users.profile.multiauth.vk.popup.body.confirm') }} />
    <Buttons>
      <Button theme="warning" size="M" onClick={onConfirm}>
        {translations.t('users.profile.multiauth.activate')}
      </Button>
    </Buttons>
  </Modal>
);

const Text = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  
  ul {
    margin-top: 10px;
    padding-left: 16px;
    
    li {
      margin-top: 5px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 160px;
  }
`;
