import React, { memo } from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { TextWithLinks } from '@components/TextWithLinks';

export const StatusLayout = memo(({ children, title = '', text = '' }) => {
  return (
    <View>
      <Content>
        <Icon>{children}</Icon>
        <Title>{title}</Title>
        <TextWithLinks tag={Text} text={text} />
      </Content>
    </View>
  );
});

const View = styled.div`
  width: 720px;
  height: 430px;
  background-color: ${Colors.night};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.coal};
`;
const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`;
const Icon = styled.div`
  margin-bottom: 30px;
`;
const Title = styled.div`
  color: ${Colors.light_gray};
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
`;
const Text = styled.div`
  color: ${Colors.light_gray};
  font-size: 12px;
  text-align: center;
  
  a {
    text-decoration: none;
    color: ${Colors.dark_cream};
  }
`;
