import {
  SET_SCREEN,
  SET_SELECTED,
  SET_APPLIED_PROMO,
  SET_PAY_DATA,
} from '../../constants';
import { getCurrentGoods } from '../helpers';

export const setScreen = (screen) => (dispatch, getState) => {
  dispatch({ type: SET_SCREEN, payload: screen });
};
export const setPeriod = (val) => (dispatch, getState) => {
  dispatch({ type: SET_SELECTED, payload: { period: val }});
};
export const setPack = (val) => (dispatch, getState) => {
  dispatch({ type: SET_SELECTED, payload: { pack: val } });
};
export const setCountry = (val) => (dispatch, getState) => {
  dispatch({ type: SET_SELECTED, payload: { country: val } });
};
export const setPromoCode = (val) => (dispatch, getState) => {
  dispatch({ type: SET_SELECTED, payload: { promoCode: val } });
};
export const setPaymentMethod = (val) => (dispatch, getState) => {
  dispatch({ type: SET_SELECTED, payload: { paymentMethod: val } });
};
export const setCurrentGoods = () => (dispatch, getState) => {
  const currentGoods = dispatch(getCurrentGoods());

  dispatch({ type: SET_SELECTED, payload: { currentGoods: currentGoods } });
};
export const setAppliedPromo = (promo) => (dispatch, getState) => {
  dispatch({ type: SET_APPLIED_PROMO, payload: promo });
};
export const setPayData = (data) => (dispatch, getState) => {
  dispatch({ type: SET_PAY_DATA, payload: data });
};
