import { ICardAlfa } from './alfa';
import { ICardAlipay } from './alipay';
import { ICardBancontact } from './bancontact';
import { ICardEps } from './eps';
import { ICardGiropay } from './giropay';
import { ICardIdeal } from './ideal';
import { ICardMaestro } from './maestro';
import { ICardMastercard } from './mastercard';
import { ICardMir } from './mir';
import { ICardNew } from './new';
import { ICardPaypal } from './paypal';
import { ICardPrzelewy } from './przelewy';
import { ICardQiwi } from './qiwi';
import { ICardSber } from './sber';
import { ICardSbp } from './sbp';
import { ICardSepa } from './sepa';
import { ICardSofort } from './sofort';
import { ICardUnion } from './union';
import { ICardVisa } from './visa';
import { ICardYoomoney } from './yoomoney';

export default {
  ICardAlfa,
  ICardAlipay,
  ICardBancontact,
  ICardEps,
  ICardGiropay,
  ICardIdeal,
  ICardMaestro,
  ICardMastercard,
  ICardMir,
  ICardNew,
  ICardPaypal,
  ICardPrzelewy,
  ICardQiwi,
  ICardSber,
  ICardSbp,
  ICardSepa,
  ICardSofort,
  ICardUnion,
  ICardVisa,
  ICardYoomoney,
}
