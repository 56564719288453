import React, { useState } from 'react';
import styled from 'styled-components';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { useSelector } from 'react-redux';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { IconNotification } from '../../../components/icons';
import { Notification as NotificationList } from '../../../components/Notification';

export const Notification = () => {
  const { notification } = useSelector(state => state);
  const [isOpen, seIsOpen] = useState(false);
  const count = notification.list.length;

  return (
    <View>
      <Button onClick={() => seIsOpen(true)}>
        <IconNotification />
        {Boolean(count) && <Count>{count}</Count>}
      </Button>

      <NotificationList isOpen={isOpen} onClose={() => seIsOpen(false)} />
    </View>
  )
};

const View = styled.div`
  position: relative;
`;
const Button = styled.div`
  ${Mixins.transition('background-color')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.light_gray};
  border-radius: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  
  svg {
    ${Mixins.transition('fill')};
  }
  
  &:hover {
    background-color: ${Colors.planoplan_blue};
    border-color: ${Colors.planoplan_blue};

    svg {
      fill: ${Colors.white};
    }
  }
`;
const Count = styled.div`
  position: absolute;
  top: -3px;
  right: -6px;
  padding: 3px 4px;
  border-radius: 50px;
  background-color: ${Colors.planoplan_blue};
  color: ${Colors.timberwolf25};
  font-size: 12px;
  line-height: 12px;
  min-width: 18px;
  text-align: center;
`;
