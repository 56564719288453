import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Grid } from 'planoplan-ui-kit/core/helpers/grid';
import { Button } from 'planoplan-ui-kit/core/button';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { translations } from '@libs';
import { TARIFF_ALIAS } from '../../../constants';
import { convertToDigit } from '../../../modules/helpers';
import { tablet_landscape, laptop } from '../../../modules/breakpoints';

export const TariffCard = React.forwardRef(({ item, userTariff, profileGoods, addToCart = () => {} }, ref) => {
  const getPriceLabel = () => {
    if (item.alias === TARIFF_ALIAS.START) {
      return '';
    }

    const prevText = translations.t(`store.common.${item.alias}.subscribe`);

    if (item.alias === TARIFF_ALIAS.BUSINESS) {
      return prevText;
    }

    return `${prevText} ${convertToDigit(item.product.price)} ${profileGoods.view_currency}`;
  };
  const priceLabel = getPriceLabel();
  const feature = translations.t(`store.common.${item.alias}.features`).trim();

  const buttonText = useMemo(() => {
    if (item.alias === TARIFF_ALIAS.BUSINESS) {
      return translations.t('store.pricing.leave_request');
    }

    return item.alias === userTariff ? translations.t('store.common.extend_subscribe') : translations.t('store.common.buy_subscribe');
  }, []);

  return (
    <View ref={ref} withBorder={item.alias === TARIFF_ALIAS.PROPLUS} data-alias={item.alias}>
      <Content>
        <Top>
          <Title alias={item.alias}>{item.caption}</Title>
          <Tags>
            {(item.alias === userTariff && userTariff !== TARIFF_ALIAS.BUSINESS) && <Tag mode="current">{translations.t('store.common.current')}</Tag>}
          </Tags>
        </Top>
        <Description dangerouslySetInnerHTML={{ __html: translations.t(`store.common.${item.alias}.description`) }} />
        <Price>
          {item.alias === TARIFF_ALIAS.BUSINESS && (
            <PriceRequest>{translations.t('store.pricing.on_request')}</PriceRequest>
          )}

          {item.alias !== TARIFF_ALIAS.BUSINESS && (
            <PriceTitle>
              {convertToDigit(item.product.monthPrice)}
              {typeof item.product.monthPrice === 'number' && <PricePost>{profileGoods.view_currency}/{translations.t('store.common.month')}</PricePost>}
            </PriceTitle>
          )}

          {item.alias !== TARIFF_ALIAS.BUSINESS && (
            <PriceLabel dangerouslySetInnerHTML={{ __html: priceLabel }} />
          )}
          {feature && <PriceList>{feature}</PriceList>}

          {(item.alias !== TARIFF_ALIAS.START) &&
            <DescriptionList isMarginTop={!Boolean(feature)}>
              {item.description.raw_description.map((description, index) => {
                const Icon = description.icon;

                return (
                  <DescriptionItem key={index}>
                    <DescriptionIcon>
                      <Icon width={24} height={24} fill={Colors.electric_blue} background={Colors.electric_blue} />
                    </DescriptionIcon>
                    {description.text}
                  </DescriptionItem>
                )
              })}
            </DescriptionList>
          }
        </Price>
      </Content>
      <Footer>
        {item.alias === TARIFF_ALIAS.BUSINESS && (
          <RequestText>{translations.t('store.pricing.team.request')}</RequestText>
        )}

        {(item.alias !== TARIFF_ALIAS.START) &&
          <Button theme="primary" size="M" onClick={() => addToCart(item.product.store_id, item)} data-alias={item.alias}>
            {buttonText}
          </Button>
        }
      </Footer>
    </View>
  );
});

const View = styled.div`
  ${Grid.row()};
  ${Grid.column(32, 32)};
  ${Grid.prefix(2, 32)};
  ${Grid.suffix(2, 32)};
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
  background-color: ${Colors.white};
  border-radius: 10px;
  margin-bottom: 18px;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ withBorder }) => withBorder ? `2px solid ${Colors.electric_blue}` : `1px solid ${Colors.whisper}`};

  @media ${tablet_landscape} {
    ${Grid.column(14.5, 30)};
    ${Grid.post(1, 30)};
    ${Grid.prefix(1, 30)};
    ${Grid.suffix(1, 30)};
    min-height: 100%;
    max-height: 600px;
    height: 100%;

    &:nth-child(2n) {
      ${Grid.post(0)};
    }
  }

  @media ${laptop} {
    ${Grid.column(10.75, 22)};
    ${Grid.post(0.5, 22)};
    ${Grid.prefix(1, 22)};
    ${Grid.suffix(1, 22)};

    &:nth-child(2n) {
      ${Grid.post(0, 22)};
    }
  }
`;
const Content = styled.div``;
const Footer = styled.div`
  margin-top: 18px;

  @media ${tablet_landscape} {
      margin-top: 0;
  }
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 10px;
`;
const Tags = styled.div`
  margin-top: 8px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const Tag = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.planoplan_blue};
  border: 1px solid ${Colors.planoplan_blue};
  padding: 4px 10px;
  border-radius: 20px;
  font-weight: 400;
  margin-bottom: 5px;
`;
const Title = styled.p`
  font-size: ${({alias}) => alias == 'start' ? '40px' : '52px'};
  line-height: 52px;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.night};
  font-weight: 700;
  white-space: nowrap;
`;
const Description = styled.div`
  width: 100%;
  min-height: 34px;
  margin-bottom: -7px;
  font-size: 14px;
  line-height: 17px;
`;
const Price = styled.div`
  color: ${Colors.night};
`;
const PriceTitle = styled.div`
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
`;
const PriceRequest = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  padding-top: 18px;
  padding-bottom: 19px;
  margin-bottom: 34px;
`;
const PricePost = styled.span`
  font-size: 24px;
  line-height: 29px;
  margin-left: 4px;
`;
const PriceLabel = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.nobel};
  margin-bottom: 10px;
`;
const PriceList = styled.div`
  font-size: 14px;
  height: 17px;
  color: ${Colors.night};
`;
const DescriptionList = styled.ul`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.night};
  font-family: 'Museo Sans Cyrl';
  list-style: none;
  margin: ${({ isMarginTop }) => isMarginTop ? '30px 0 0 0' : '10px 0 0 0'} ;
  padding: 0;
`;
const DescriptionItem = styled.li`
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.night};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;
const DescriptionIcon = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const RequestText = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: ${Colors.nobel};
  margin-bottom: 10px;
`;
