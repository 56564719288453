export const initialState = {
  alias: '',
  article: {},
  settings: {},
  loading: {
    getters: {},
    processing: true,
    error: '',
    firstLoading: true,
  },
};
