import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { Mixins } from 'planoplan-ui-kit/core/mixins';
import { ScrollBar } from 'planoplan-ui-kit/core/scroll-bar';
import { rail_y, thumb_y } from "../scroll";
import { TABS } from '../../constants';
import { Adjustment } from '../Adjustment';

export const Options = ({ tab }) => {
  return (
    <View>
      <ScrollBar options={{ wheelSpeed: 0.7, maxScrollbarLength: 150, minScrollbarLength: 40 }} rail_y={rail_y} thumb_y={thumb_y}>
        {tab === TABS.adjustment && <Adjustment />}
      </ScrollBar>
    </View>
  )
};

const View = styled.div`
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
`;
