import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { LogoStripe } from '../../icons/stripe';
import { StripeContent } from './organism/content';
import { Back } from '../../components/Back';


export const StripeScreen = memo(() => {
  const { payData, settings: { locale }} = useSelector(state => state);
  const stripePromise = loadStripe(payData.publicKey, { locale: locale });

  return (
    <View>
      <Back />

      <Elements stripe={stripePromise}>
        <StripeContent />
      </Elements>

      <Logo>
        <LogoStripe />
      </Logo>
    </View>
  );
});

const View = styled.div`
  width: 720px;
  height: 430px;
  background-color: ${Colors.night};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;
