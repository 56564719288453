import React from 'react';
import styled from 'styled-components';
import { Button } from 'planoplan-ui-kit/core/button';
import { Input } from 'planoplan-ui-kit/core/input';
import { Colors } from 'planoplan-ui-kit/core/colors';
import { translations } from '@libs';
import { Modal } from '../modal';
import { Header } from '../atoms';

export const ShortageTeamLicense = ({ isOpen, onCancel = () => {} }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Header>
        {translations.t('form_entry.team.noLicense')}
      </Header>
      <Content>
        {translations.t('form_entry.team.noLicense_2')}
      </Content>
      <Buttons>
        <Button theme="primary" size="M" onClick={onCancel}>
          {translations.t('modal.okay')}
        </Button>
      </Buttons>
    </Modal>
  )
};

const Content = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 160px;
  }
`;
