import React from 'react';

export const IconTeam = ({ width = 24, height = 24, fill = '#4F5B60' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path d="M15.013 17.66A3.985 3.985 0 0 1 14 15c0-1.327.646-2.503 1.641-3.23C15.018 10.743 13.643 10 12 10s-3.018.744-3.641 1.77A3.994 3.994 0 0 1 10 15a3.985 3.985 0 0 1-1.013 2.66C10.777 18.513 12 20.115 12 22h-2c0-1.602-1.748-3-4-3s-4 1.398-4 3H0c0-1.885 1.223-3.487 3.013-4.34a4 4 0 0 1 3.466-6.632c.515-1.007 1.413-1.834 2.534-2.368a4 4 0 1 1 5.975 0c1.12.534 2.018 1.361 2.533 2.368a4 4 0 0 1 3.466 6.632C22.777 18.513 24 20.115 24 22h-2c0-1.602-1.748-3-4-3s-4 1.398-4 3h-2c0-1.885 1.223-3.487 3.013-4.34zM12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-6 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm12 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
  </svg>
);




