import React from 'react';
import { translations } from '@libs';
import { Header, ProgressBar, Loader } from '../../components';
import { UPDATE_STATUSES } from '../../constants';

export const Update = ({ updateStatus = '', downloadedPercent = 0 }) => {
  const isInstalling = updateStatus === UPDATE_STATUSES.INSTALLING;

  return (
    <>
      <Header title={translations.t(isInstalling ? 'form_entry.update.installing' : 'form_entry.update.check')} />
      <ProgressBar isInstalling={isInstalling} width={downloadedPercent} />
      <Loader />
    </>
  );
};
