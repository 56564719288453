import React from 'react';
import styled from 'styled-components';
import { Colors } from 'planoplan-ui-kit/core/colors';

export const PanelText = ({ text = '', right = 0, isOpen = false }) => {
  return (
    <>
      {isOpen && <View right={right} dangerouslySetInnerHTML={{ __html: text }} />}
    </>
  )
};

const View = styled.div`
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: ${({ right }) => `${right}px`};
  z-index: 2;
  padding: 10px 20px;
  color: ${Colors.white};
  background-color: ${Colors.coal};
  font-size: 14px;
  line-height: 17px;
  width: 310px;
  border-radius: 5px 5px 0 5px;
  font-family: 'MuseoCyrl', 'Museo', sans-serif;
`;
